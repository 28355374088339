// @flow
import React from 'react';
import Box from '../../../common/components/Box';
import ComposedButton from '../../../common/components/ComposedButton';
import { connect } from 'react-redux';
import type { State } from '../../../common/types';
import Text from '../../../common/components/Text';
import EditAlcohol from './EditAlcohol';
import { clearAlcoholIds, editAlcoholRow } from '../../../common/admin/alcohol/actions';
import Blocker from '../../components/Blocker';
import api from '../../../common/lib/api';
import { initiateAdminTableReload, addAdminError, toggleAdminNotificationPopup } from '../../../common/admin/general/actions';
import Permission from '../../../common/permissions/Permission';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/alcohol';

class Sidebar extends React.PureComponent {
  state = {
    showBlocker: false
  };

  render() {
    const { selectedAlcoholsId, editRow, dispatch, intl } = this.props;
    const { showBlocker } = this.state;

    return (
      <Box height="100%" flexDirection="column">
        <Box paddingTop={0.5} paddingHorizontal={0.75} flexShrink={0} flexDirection="row">
          <Box flexGrow={1} flexShrink={0} flexBasis={1} marginHorizontal={0.25}>
            <Permission name="alcoholsale.change">
              <ComposedButton
                disabled={Object.keys(selectedAlcoholsId).length !== 1}
                caption={intl.formatMessage(messages.sidebarEdit)}
                backgroundColor="blue"
                shadowColor="darkBlue"
                icon="edit"
                iconSize={1.25}
                onPress={() => {
                  dispatch(editAlcoholRow(selectedAlcoholsId[Object.keys(selectedAlcoholsId)[0]]));
                }}
              />
            </Permission>
          </Box>
          <Box flexGrow={1} flexShrink={0} flexBasis={1} marginHorizontal={0.25}>
            <Permission name="alcoholsale.delete">
              <ComposedButton
                disabled={!Object.keys(selectedAlcoholsId).length}
                caption={intl.formatMessage(messages.sidebarDelete)}
                backgroundColor="buttonRedish"
                shadowColor="buttonRedishShadow"
                icon="trash"
                iconSize={1.25}
                onPress={() => {
                  this.setState({ showBlocker: true });
                }}
              />
            </Permission>
          </Box>
        </Box>
        <Box paddingTop={0.5} paddingHorizontal={0.75} flexDirection="row">
          <Box flexGrow={1} flexShrink={0} flexBasis={1} marginHorizontal={0.25}>
            <Permission name="alcoholsale.add">
              <ComposedButton
                caption={intl.formatMessage(messages.sidebarAdd)}
                backgroundColor="buttonTeal"
                shadowColor="buttonTealShadow"
                icon={<Text color="white" size={2}>+</Text>}
                onPress={() => {
                  dispatch(editAlcoholRow({ id: 'add' }));
                }}
              />
            </Permission>
          </Box>
        </Box>

        {editRow &&
          <EditAlcohol />}

        {showBlocker &&
          <Blocker
            actionConfirm={() => {
              const ids = Object.keys(selectedAlcoholsId);
              api.alcohol.deleteAlcohol(ids)
                .then(() => {
                  dispatch([
                    clearAlcoholIds(),
                    initiateAdminTableReload(),
                    toggleAdminNotificationPopup(intl.formatMessage(messages.sidebarNotificationDeleted))
                  ]);
                })
                .catch(e => {
                  console.error(intl.formatMessage(messages.deleteAlcoholError), e);
                  dispatch(addAdminError(e));
                });
              this.setState({ showBlocker: false });
            }}
            actionCancel={() => this.setState({ showBlocker: false })}
            textHeader={intl.formatMessage(messages.sidebarDeleteItems)}
            textDescription={intl.formatMessage(messages.sidebarConfirmMsgDeleteItems)}
            textConfirm={intl.formatMessage(messages.sidebarDeleteYes)}
            textCancel={intl.formatMessage(messages.sidebarDeleteCancel)}
          />}
      </Box>
    );
  }
}

export default compose(
  connect(
    (state: State) => ({
      selectedAlcoholsId: state.admin.alcohol.selectedAlcoholsId,
      editRow: state.admin.alcohol.editRow
    })
  ),
  injectIntl
)(Sidebar);
