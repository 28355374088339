// @flow
import { find, propEq } from 'rambda';
import * as actions from './actions';
import { findPriceLevelForTable } from '../tables/paramUtils';

export const priceLevelEpic = (action$: any, { getState }) =>
  action$
    .ofType('OPEN_TABLE_ACTIVATE')
    .map(({ payload: { id: openTableId } }) => {
      const {
        tables: {
          openTables,
          tableDefinitions
        },
        parameters: {
          parameters
        }
      } = getState();

      const openTable = find(propEq('id', openTableId), openTables);
      if (!openTable) return [];

      const tableDefinition = find(propEq('id', openTable.tableDefinitionId), tableDefinitions);
      if (!tableDefinition) return [];

      const priceLevel = findPriceLevelForTable(tableDefinition, parameters);

      return actions.setActivePriceLevel(priceLevel);
    });
