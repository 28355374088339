// @flow
import type { State } from '../../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'rambda';
import { AutoSizer, Column, defaultTableCellRenderer } from 'react-virtualized';
import TableSearch from '../../TableSearch';
import SimpleItemsTable, { withInfiniteLoader, withSelectableRows } from '../../Table';
import moment from 'moment';
import Box from '../../../../common/components/Box';
import Text from '../../../../common/components/Text';
import {
  toggleHistoryClosureId,
  toggleHistoryIsLoading, setHistoryPreviewData
} from '../../../../common/admin/closures/actions';
import api from '../../../../common/lib/api';
import { addAdminError, initiateAdminTableReload } from '../../../../common/admin/general/actions';
import debounce from 'debounce';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/closures';

const Table = compose(
  withInfiniteLoader({ rowDataKey: 'idriadok' }),
  withSelectableRows('idriadok'),
)(SimpleItemsTable);

const dateRangeOptions = {
  defaultFrom: moment().startOf('month'),
  defaultTo: moment().endOf('month'),
};

class HistoryList extends React.PureComponent {
  state = {
    dateFrom: dateRangeOptions.defaultFrom.format(),
    dateTo: dateRangeOptions.defaultTo.format(),
    filter: ''
  };

  debouncedReload = debounce(() => {
    const { dispatch } = this.props;
    return dispatch(initiateAdminTableReload());
  }, 200);

  filterData = (filter, key) => {
    if (key === 'submit') return;

    this.setState({ filter }, this.debouncedReload);
  };

  loadHistory = ({ loadAfterId }) => {
    const { dispatch } = this.props;
    const { filter, dateFrom, dateTo } = this.state;

    return api.closure.getClosures('', { dateFrom, dateTo, loadAfterId, closureId: filter })
      .then(({ body: { result: { closures } } }) => closures)
      .catch(e => {
        dispatch(addAdminError(e));
      });
  };

  loadDetail = ({ rowData: { idriadok } }) => {
    const { dispatch } = this.props;

    dispatch([
      toggleHistoryClosureId(idriadok),
      toggleHistoryIsLoading(true)
    ]);

    api.closure.getClosure(idriadok).then(({ body: { result: { closure: { data } } } }) => {
      dispatch([
        setHistoryPreviewData(data),
        toggleHistoryIsLoading(false)
      ]);
    }).catch(e => {
      dispatch([
        toggleHistoryIsLoading(false),
        addAdminError(e),
      ]);
    });
  };

  setDateRange = (from, to) => {
    const { dispatch } = this.props;
    dispatch(toggleHistoryClosureId(null));

    this.setState({ dateFrom: from.format(), dateTo: to.format() }, () => {
      dispatch(initiateAdminTableReload());
    });
  };

  render() {
    const { intl, selectedClosureId } = this.props;
    const selectedItems = selectedClosureId ? { [selectedClosureId]: true } : undefined;

    return (
      <Box
        width="100%"
        flex={1}
        backgroundColor="appBg"
        marginTop={1}
        paddingLeft={1}
        overflow="auto"
      >
        <TableSearch
          enableFilter
          enableDateRange
          enableCalendar={false}
          dateRangeOptions={dateRangeOptions}
          onDateRangeChange={this.setDateRange}
          onFilterChange={this.filterData}
          keyboardType="numeric"
        />

        <Box flexBasis="100%" backgroundColor="white" marginBottom={1} maxHeight="100vh" height="9999px">
          <AutoSizer>
            {({ height, width }) => (
              <Table
                width={width}
                height={height}
                onRowClick={this.loadDetail}
                headerClassName="offset-first"
                className="light"
                selectedItems={selectedItems}
                lazyFetch={this.loadHistory}
              >
                <Column
                  label={intl.formatMessage(messages.deadlineFromTo)}
                  dataKey="idriadok"
                  cellDataGetter={({ rowData }) =>
                    rowData.repUzaverka
                      ? `${rowData.repUzaverka[0].hlavicka.od} – ${rowData.repUzaverka[0].hlavicka.do}`
                      : rowData.uzaverka
                  }
                  width={width}
                  cellRenderer={props =>
                    <Text paddingLeft={0.5} color="inherit">
                      {defaultTableCellRenderer(props)}
                    </Text>
                  }
                />
              </Table>
            )}
          </AutoSizer>
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    selectedClosureId: state.admin.closures.history.selectedClosureId
  })),
  injectIntl
)(HistoryList);
