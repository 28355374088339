// @flow
import InactiveBg from '../../../components/InactiveBg';
import Popup from '../../../components/Popup';
import React from 'react';
import messages from '../../../../common/admin/messages/form';
import messagesValues from '../../../../common/admin/messages/values';
import { FormattedMessage } from 'react-intl';
import Heading from '../../../../common/components/Heading';
import Button from '../../../../common/components/Button';
import Radio from '../../../../common/components/Radio';
import Text from '../../../../common/components/Text';

export default class extends React.PureComponent {
  options = {
    logo: [1, 0],
    state: [1, 0],
  };

  onClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  onSelect = (value) => {
    const { type, onSelect } = this.props;

    onSelect(type, value);
    this.onClose();
  };

  render() {
    const { type, selected } = this.props;
    const options = this.options[type];

    return (
      <InactiveBg zIndex={3000} onClose={this.onClose}>
        <Popup onClose={this.onClose}>
          <Heading scale={2} color="white" minWidth={20}>
            <FormattedMessage {...messages[type]} />
          </Heading>

          {options.map(option => {
            const isActive = option === selected;
            return (
              <Button
                key={option}
                backgroundColor={isActive ? 'teal' : 'appBg'}
                outline
                color="white"
                marginBottom={0.5}
                paddingVertical={0.75}
                paddingHorizontal={2}
                onPress={() => this.onSelect(option)}
              >
                <Radio size={1} color="white" left={0.75} position="absolute"
                       backgroundColor={isActive ? 'teal' : 'appBg'} checked={isActive} />
                <Text color="white" bold>
                  <FormattedMessage {...messagesValues[`${type}_${option}`]} />
                </Text>
              </Button>
            );
          })}
        </Popup>
      </InactiveBg>
    );
  }
}
