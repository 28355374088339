// @flow
import * as actions from './actions';
import * as ganeralActions from '../general/actions';

export const cancelReceiptAndReturnToTableAfterSubtableIsAdded = (action$: any) =>
  action$
    .ofType('SUB_TABLE_UPDATE_COMMIT')
    .filter(({ payload: { flags = {} } }) => flags.cancelReceiptAndReturnToSubTable)
    .map((
      {
        payload: {
          subTable: { id },
          flags: {
            cancelReceiptAndReturnToSubTable: { receiptId, closureId }
          }
        }
      }) => [
        ganeralActions.toggleAdminLoadingScreen(true),
        actions.cancelReceiptAndReturnToTable(receiptId, closureId, id)
      ]
    );
