// @flow
import React from 'react';
import { connect } from 'react-redux';
import { State } from '../../types';
import { findParamValue } from '../../parameters/utils';
import { signOut } from '../../auth/actions';
import { replace } from 'connected-react-router';
import { toggleAllowSignOutOnInactivity } from '../../activity/actions';

const signOutOnInactivity = (Component) =>
  connect((state: State) => ({
    __dontPass: {
      parameters: state.parameters.parameters,
      lastActivity: state.activity.lastActivity
    },
    allowSignOutOnInactivity: state.activity.allowSignOutOnInactivity
  }))(class extends React.PureComponent {
    componentDidMount() {
      const { dispatch } = this.props;

      dispatch(toggleAllowSignOutOnInactivity(true));
      this._setInterval();
    }

    componentWillUpdate(nextProps: Readonly<P>): void {
      const { allowSignOutOnInactivity: thisAllowSignOutOnInactivity } = this.props;
      const { allowSignOutOnInactivity: nextAllowSignOutOnInactivity } = nextProps;

      if (thisAllowSignOutOnInactivity !== nextAllowSignOutOnInactivity && !nextAllowSignOutOnInactivity) {
        this._clearInterval();
      } else if (thisAllowSignOutOnInactivity !== nextAllowSignOutOnInactivity && nextAllowSignOutOnInactivity) {
        this._setInterval();
      }
    }

    componentWillUnmount() {
      this._clearInterval();
    }

    _setInterval = () => {
      const { __dontPass: { parameters, lastActivity } } = this.props;
      const delay = findParamValue('K32.sc_saver', parameters) * 1000;

      if (!delay || !lastActivity || this.interval) return;

      this.interval = setInterval(() => {
        const { dispatch, __dontPass: { lastActivity } } = this.props;

        const now = Date.now();

        if (now - lastActivity > delay) {
          dispatch(signOut());

          if (process.env.IS_REACT_NATIVE === '1') {
            // TODO redirect - not needed sofar
          } else {
            dispatch(replace('/'));
          }
        }
      }, 1000);
    };

    _clearInterval = () => {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    };

    render() {
      const { __dontPass, ...props } = this.props;

      return <Component {...props} />;
    }
  });

export default signOutOnInactivity;
