// @flow
import React from 'react';
import Calendar from 'rc-calendar';
import { compose, path } from 'rambda';
import { assocPath } from 'ramda';
import InactiveBg from '../../components/InactiveBg';
import Popup from '../../components/Popup';
import NumericButtons from '../../../common/components/Keyboard/popupWeightForm/Buttons';
import createNumericOperations from '../../../common/components/Keyboard/popupWeightForm/operations';
import Keyboard from '../../../common/components/Keyboard';
import TextInput from '../../../common/components/TextInput';
import Text from '../../../common/components/Text';
import Box from '../../../common/components/Box';
import { closestElementOfComponent } from '../../lib/dom';
import Button from '../../../common/components/Button';
import Icon from '../../../common/components/Icon';
import moment from 'moment';
import { connect } from 'react-redux';
import type { State } from '../../../common/types';
import { clearAlcoholIds, editAlcoholRow } from '../../../common/admin/alcohol/actions';
import api from '../../../common/lib/api';
import { initiateAdminTableReload, addAdminError, toggleAdminNotificationPopup } from '../../../common/admin/general/actions';
import { getEanProductInfo } from '../../../common/admin/alcohol/utils';
import { isFiltering } from '../../../common/order/utils';
import { myNameSelector } from '../../../common/auth/selectors';
import { excludedMetaKeys, replaceSKKeyCharsWithNums } from '../../../common/lib/utils';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/alcohol';

const CustomNumericButtons = props => <NumericButtons {...props} showDot={false} showBackspace />;

class EditAlcohol extends React.PureComponent {
  constructor(props) {
    super(props);
    const { ean, count, date } = props.editedRow;

    this.state = {
      focus: 'ean',
      eanFilterThreshold: 13,
      fields: {
        ean: ean || '',
        count: Number(count) || 1,
        date: date ? date.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      },
      defaultDate: date || moment(),
      serverProductName: null,
      serverProductUnit: null,
      valuesFromInputListener: ''
    };
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyboardPress);

    const { editedRow } = this.props;

    if (path('ean', editedRow)) {
      const { ean } = editedRow;

      this._findEanName(ean);
    }
  }

  // TODO react antipattern - will be moved to parent element or hoc
  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyboardPress);
  }

  _validateEnteredCode = code => {
    // FIXME - is this validation enough?
    if (code) {
      this.setState({ fields: { ...this.state.fields, ean: code } });
      this._findEanName(code);
      console.log(`Entered code: ${code}`);
    }
  };

  handleKeyboardPress = (event) => {
    const { isTextPopupOpen, isWeightFormOpen } = this.props;
    if (!isTextPopupOpen && !isWeightFormOpen) {
      if (event.keyCode === 13) {
        this._validateEnteredCode(this.state.valuesFromInputListener);
        this.setState({ valuesFromInputListener: '' });
      } else if (excludedMetaKeys(event)) {
        this.setState({ valuesFromInputListener: `${this.state.valuesFromInputListener}${replaceSKKeyCharsWithNums(event.key)}` });
      }
    }
  };

  _findEanName = (value, inputLength) => {
    const { dispatch, intl } = this.props;
    const { eanFilterThreshold } = this.state;

    if (isFiltering(value, eanFilterThreshold)) {
      this.setState(
        { serverProductName: intl.formatMessage(messages.finding) },
        () => api.ean.getEans()
          .then(({ body }) => {
            const fullProduct = getEanProductInfo(value, body);
            const productName = fullProduct ? fullProduct.name : '';
            const serverProductName = fullProduct
              ? productName.slice(0, -3).trim()
              : inputLength > 12
                ? intl.formatMessage(messages.eanNotFound)
                : null;
            const serverProductUnit = fullProduct
              ? productName.slice(productName.length - 3, productName.length).trim()
              : null;

            this.setState({ serverProductName, serverProductUnit });
          })
          .catch(e => {
            console.error(intl.formatMessage(messages.getEanError), e);
            dispatch(addAdminError(e));
          })
        );
    } else {
      this.setState({ serverProductName: null });
    }
  };

  NumericKeyboard = (dispatch, refFn, fields, type, editId, intl) => (
    <Box display="none">
      <Keyboard
        ref={refFn}
        firstZero
        createOperations={createNumericOperations}
        Buttons={CustomNumericButtons}
        onDisplayValue={value => {
          this.setState(
            { fields: { ...this.state.fields, [type]: this.state.focus === 'count' ? Number(value) : value } },
            () => {
              if (this.state.focus === 'ean') {
                this._findEanName(value, (this.state.fields.ean).length);
              }
            });
        }}
        onLastKey={key => {
          if (key === 'ok' && !!this.state.fields.ean && this.state.fields.count > 0) {
            dispatch(editAlcoholRow(null));

            if (editId === 'add') {
              api.alcohol.addAlcohol(this.state.fields)
                .then(({ body }) => {
                  console.log(body);
                  dispatch([
                    initiateAdminTableReload(),
                    toggleAdminNotificationPopup(intl.formatMessage(messages.itemAdded))
                  ]);
                })
                .catch(e => {
                  console.error(intl.formatMessage(messages.addAlcoholError), e);
                  dispatch(addAdminError(e));
                });
            } else {
              api.alcohol.editAlcohol(editId, this.state.fields)
                .then(({ body }) => {
                  console.log(body);
                  dispatch([
                    initiateAdminTableReload(),
                    clearAlcoholIds(),
                    toggleAdminNotificationPopup(intl.formatMessage(messages.itemEdited))
                  ]);
                })
                .catch(e => {
                  console.error(intl.formatMessage(messages.editAlcoholError), e);
                  dispatch(addAdminError(e));
                });
            }
          }
        }}
        defaultValue={String(fields[type])}
        marginTop={0.5}
      />
    </Box>
  );

  InputField = (refFn, keyboard, input, fields, type) => (
    <Box flexDirection="row" marginBottom={1.5} ref={refFn}>
      <TextInput
        flex={1}
        flexGrow={0}
        maxLength={1000}
        padding={1}
        paddingRight={2}
        width={12}
        scale={2}
        value={fields[type]}
        onFocus={() => {
          if (type === 'count') {
            this.setState({ focus: type, fields: assocPath(['count'], 0, this.state.fields) });
          } else {
            this.setState({ focus: type });
          }
        }}
        onBlur={e => {
          if (!closestElementOfComponent(e.relatedTarget, this[keyboard])
            && !closestElementOfComponent(e.relatedTarget, input)) {
            this.setState({ focus: null });
          }
        }}
        onKeyPress={({ key }) => {
          this[keyboard].sendKey(key);
        }}
        onKeyDown={({ keyCode }) => {
          if (keyCode === 8) { // backspace
            this[keyboard].sendKey('del');
          }
        }}
        onChange={() => {}}
      />
      <Button
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        paddingHorizontal={0.75}
        alignItems="center"
        onPress={() => {
          this.setState(
            { fields: { ...this.state.fields, [type]: this.state.focus === 'count' ? 0 : '' }, focus: type },
            () => {
              this[keyboard].sendKey('cancel');
              if (this.state.focus === 'ean') {
                this.setState({ serverProductName: null });
              }
            }
          );
        }}
      >
        <Icon color="black" name="delete" scale={2} />
      </Button>
    </Box>
  );

  toggleKeyboard = () => {
    const { dispatch } = this.props;
    dispatch(editAlcoholRow(null));
  };

  getDate = (value) => {
    const date = value.format('YYYY-MM-DD');
    this.setState({ fields: { ...this.state.fields, date } });
  };

  render() {
    const { dispatch, editedRow, intl } = this.props;
    const { focus, fields } = this.state;
    const editId = path('id', editedRow) ? editedRow.id : null;

    return (
      <InactiveBg onClose={this.toggleKeyboard}>
        <Popup onClose={this.toggleKeyboard}>
          <Box flexDirection="row" width={30} justifyContent="space-between">
            <Box flex={1} marginRight={1.5}>
              <Text bold marginBottom={0.25} color="white">
                {this.state.serverProductName
                  ? `EAN: ${this.state.serverProductName}`
                  : 'EAN'}
              </Text>
              {this.InputField(c => this.eanBlock = c, 'eanKeyboard', this.eanBlock, fields, 'ean')}

              <Text bold marginBottom={0.25} color="white">
                {this.state.serverProductUnit
                  ? `${intl.formatMessage(messages.count)} (${this.state.serverProductUnit})`
                  : intl.formatMessage(messages.count)}
              </Text>
              {this.InputField(c => this.countBlock = c, 'countKeyboard', this.countBlock, fields, 'count')}

              {focus === 'ean' && this.NumericKeyboard(dispatch, c => this.eanKeyboard = c, fields, 'ean', editId, intl)}
              {focus !== 'ean' && this.NumericKeyboard(dispatch, c => this.countKeyboard = c, fields, 'count', editId, intl)}
            </Box>


            <Box paddingBottom={2}>
              <Text bold marginBottom={0.25} color="white">Dátum</Text>
              <Calendar
                width={2}
                showDateInput={false}
                showToday={false}
                showOk={false}
                mode="date"
                defaultValue={this.state.defaultDate}
                onSelect={this.getDate}
              />
            </Box>
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    editedRow: state.admin.alcohol.editRow
  })),
  injectIntl
)(EditAlcohol);
