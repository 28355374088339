import { reject, pipe, map } from 'rambda';
import { dissoc } from 'ramda';

export const mockOffline = (offlineAction, { commit, rollback }) => {
  commit.forEach(future => {
    const commitAction = offlineAction.meta.offline.commit(future.response);
    future.test(commitAction);
  });

  rollback.forEach(future => {
    const rollbackAction = offlineAction.meta.offline.rollback(future.response);
    future.test(rollbackAction);
  });
};

export const undeleted = (list) => reject(item => item._delete, list);

export const delAllLocals = (list) => list
  ? map(
    pipe(
      dissoc('_old'),
      dissoc('_local'),
      dissoc('_delete'),
      dissoc('_isDeleted'),
    ),
    Array.isArray(list) ? list : [list]
  )
  : [];
