// @flow
import React from 'react';
import Box from '../../../../common/components/Box';
import Heading from '../../../../common/components/Heading';
import Button from '../../../../common/components/Button';
import ScrollView from '../../../../common/components/ScrollView';
import { isReactNative } from '../../../../common/app/detectPlatform';
import Spinner from '../../../../common/components/Spinner';
import Text from '../../../../common/components/Text';
import FidelioOptionType from '../../../../common/payment/prgDotazy/hores/FidelioOptionType';
import TextInput from '../../../../common/components/TextInput';
import PopupFidelioCodeInput from './PopupFidelioCodeInput';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/payment';

class FidelioOptions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      showCodeInputKeyboard: false
    };
  }

  render() {
    const {
      remotePayload: options = [],
      remoteError,
      remoteIsLoading,
      isLoading,
      onConfirm,
      intl
    } = this.props;

    const { code, showCodeInputKeyboard } = this.state;

    return (
      <Box
        width={isReactNative ? null : 20}
        maxHeight={isReactNative ? '100%' : '70vh'}
        marginHorizontal={-1}
        flex={1}
      >
        <Heading scale={2} color="white" marginHorizontal={1}>{intl.formatMessage(messages.paymentSelectOptions)}</Heading>

        {remoteError ? null : remoteIsLoading
          ? (
            <Box marginTop={4}>
              <Spinner color={isReactNative ? 'gray' : ''} size="large" />
            </Box>
          ) : (
            <Box>
              <TextInput
                placeholder={intl.formatMessage(messages.paymentNumber)}
                zIndex={10}
                color="white"
                backgroundColor="darkGray"
                borderColor="white"
                borderWidth={1}
                marginHorizontal={1}
                paddingVertical={0.5}
                paddingHorizontal={1}
                marginBottom={0.5}
                justifyContent="flex-start"
                disabled={isLoading}
                flexShrink={0}
                autoCapitalize="none"
                value={code}
                onFocus={() => this.setState({ showCodeInputKeyboard: true })}
                onChange={e => {
                  this.setState({ code: e.target.value });
                }}
              />

              {showCodeInputKeyboard
              && (
                <PopupFidelioCodeInput
                  title={intl.formatMessage(messages.paymentNumber)}
                  onClose={() => this.setState({ showCodeInputKeyboard: false })}
                  onSubmit={(value) => this.setState({ code: value, showCodeInputKeyboard: false })}
                  value={`${code}`}
                />
              )}

              <ScrollView>
                  <Button
                    key={1}
                    marginHorizontal={1}
                    backgroundColor="white"
                    paddingVertical={0.5}
                    paddingHorizontal={1}
                    marginBottom={0.5}
                    justifyContent="center"
                    disabled={isLoading || !code || code === ''}
                    flexShrink={0}
                    onPress={() => onConfirm(FidelioOptionType.ROOM, code)}
                  >
                    {isLoading
                      ? <Box marginRight={0.5}><Spinner size="small" /></Box>
                      : null
                    }
                    <Box height="100%">
                      <Text>{intl.formatMessage(messages.paymentRoomName)}</Text>
                    </Box>
                  </Button>
                <Button
                  key={2}
                  marginHorizontal={1}
                  backgroundColor="white"
                  paddingVertical={0.5}
                  paddingHorizontal={1}
                  marginBottom={0.5}
                  justifyContent="center"
                  disabled={isLoading || !code || code === ''}
                  flexShrink={0}
                  onPress={() => onConfirm(FidelioOptionType.CARD, code)}
                >
                  {isLoading
                    ? <Box marginRight={0.5}><Spinner size="small" /></Box>
                    : null
                  }
                  <Box height="100%">
                    <Text>{intl.formatMessage(messages.paymentGuestCardRead)}</Text>
                  </Box>
                </Button>
              </ScrollView>
            </Box>
          )
        }

        {remoteError ? <Text color="error" marginVertical={1}>{remoteError}</Text> : null}
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(FidelioOptions);
