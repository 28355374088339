// @flow
import React from 'react';
import InactiveBg from '../../components/InactiveBg';
import Popup from '../../components/Popup';
import Text from '../../../common/components/Text';
import Box from '../../../common/components/Box';
import { connect } from 'react-redux';
import { compose } from 'rambda';
import Button from '../../../common/components/Button';
import Heading from '../../../common/components/Heading';
import { injectIntl } from 'react-intl';
import messages from '../../../common/payment/messages/page';
import type { State } from '../../../common/types';
import { emptyArray } from '../../../common/lib/utils';
import Divider from '../../../common/components/Divider';


class NewCardPopup extends React.PureComponent {
  chooseCard = loyalmanCard => {
    const {
      dispatch, closePopup, addUserToPaymentTransaction, selectedOrderLines, paymentTransactionId
    } = this.props;

    addUserToPaymentTransaction(dispatch, selectedOrderLines, paymentTransactionId, loyalmanCard);
    closePopup();
  };

  InputField = (code, status) => (
    <Button
      key={code}
      backgroundColor="appBg"
      outline
      color="white"
      marginTop={1.5}
      paddingVertical={0.75}
      paddingHorizontal={2}
      onPress={() => this.chooseCard(code)}
      flexShrink={0}
      disabled={status !== 'active'}
    >
      <Text color="white" bold>
        {code}
      </Text>
    </Button>
  );

  render() {
    const { intl, closePopup, userCards } = this.props;

    return (
      <InactiveBg onClose={closePopup}>
        <Popup onClose={closePopup}>
          <Box flexDirection="row" width={22} justifyContent="space-between">
            <Box flex={1} maxWidth="100%">
              <Heading bold marginBottom={0.75} scale={3} color="white">{intl.formatMessage(messages.chooseCard)}</Heading>

              {userCards.map(card => this.InputField(card.code, card.status.toLowerCase()))}

              <Divider />
            </Box>
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    user: state.auth.user,
    error: state.orders.error || emptyArray,
    isNewCardPopupOpen: state.payment.isNewCardPopupOpen,
    paymentTransactionId: state.payment.paymentTransactionId,
    loyalmanConnection: state.loyalman.general,
    selectedOrderLines: state.loyalman.users.selectedOrderLines
  })),
  injectIntl
)(NewCardPopup);
