// @flow
import activity from './activity/reducer';
import admin from './admin/reducer';
import app from './app/reducer';
import intl from './intl/reducer';
import auth from './auth/reducer';
import serviceAreas from './serviceAreas/reducer';
import tables from './tables/reducer';
import orders from './order/reducer';
import payment from './payment/reducer';
import items from './items/reducer';
import categories from './categories/reducer';
import menuItems from './menuItems/reducer';
import localIds from './localIds/reducer';
import peripherals from './peripherals/reducer';
import parameters from './parameters/reducer';
import permissions from './permissions/reducer';
import employees from './employees/reducer';
import device from './device/reducer';
import loyalman from './loyalman/reducer';
import network from 'react-native-offline/src/reducer';
import { combineReducers } from 'redux';

const configureReducer = (platformReducers: Object, enhanceReducer: Function) => {
  const reducer = combineReducers({
    ...platformReducers,
    activity,
    admin,
    app,
    auth,
    categories,
    device,
    employees,
    intl,
    items,
    localIds,
    menuItems,
    network,
    orders,
    parameters,
    payment,
    peripherals,
    permissions,
    serviceAreas,
    tables,
    loyalman
  });

  return enhanceReducer
    ? enhanceReducer(reducer)
    : reducer;
};

export default configureReducer;
