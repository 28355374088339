// @flow
import type { State, Dispatch, Id, ActiveReceipt } from '../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'rambda';
import { replace } from 'connected-react-router';
import Box from '../../common/components/Box';
import Title from '../components/Title';
import TopBar from '../../common/components/TopBar';
import Text from '../../common/components/Text';
import Icon from '../components/Icon';
import Button from '../../common/components/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../common/messages/payment';
import PaymentSheet from '../../common/components/PaymentSheet';
import SubPaymentsDialogue from './SubPaymentsDialogue';
import EditPaymentRecord from './EditPaymentRecordPopup';
import NewCardPopup from './NewCardPopup';
import ChoosePrepaidItemsPopup from './ChoosePrepaidItemsPopup';
import {
  clearError as clearOrderError,
} from '../../common/order/actions';
import CustomDiscountPopup from './CustomDiscountPopup';
import Notification from '../components/Notification';
import PrgDotaz from '../../common/payment/prgDotazy/PrgDotaz';
import { clearAdminError } from '../../common/admin/general/actions';
import { getAllErrors } from '../../common/admin/receipts/selectors';
import { paymentOperationsName } from '../../common/lib/api/payment';
import { clearAll as clearOrderKeeper } from '../../common/lib/fetch/orderKeeper';
import { ApiError } from '../../common/lib/fetch/errors';
import TransactionErrorPromptWrapper from './TransactionErrorPromptWrapper';
import watchActivePaymentTransactions
  from '../../common/payment/hoc/watchActivePaymentTransactions';
import { processReceivedPaymentTransactionData, transactionAndOtherErrors } from '../../common/payment/utils';
import Sidebar from '../components/Sidebar';
import bindScreenLifecycleActions from '../components/hoc/bindScreenLifecycleActions';
import paymentsNavigatorActions from '../../common/payment/screenActions';
import {
  clearPaymentTransaction,
  setPrgFilterValue,
  toggleNewCardPopup,
  updatePaymentTransaction
} from '../../common/payment/actions';
import { toggleLoyalmanUsers } from '../../common/loyalman/users/actions';
import PopupTextForm from '../order/PopupTextForm';
import api from '../../common/lib/api';
import { excludedMetaKeys, replaceSKKeyCharsWithNums } from '../../common/lib/utils';
import RegisterParagonPopup from './RegisterParagonPopup';
import PopupUnfinishedBill from '../admin/receipts/PopupUnfinishedBill';
import ServiceCouponPopup from './ServiceCouponPopup';
import PrinterSelectPopup from './PrinterSelectPopup';
import SendReceiptByEmailPopup from './SendReceiptByEmailPopup';
import NumberOfCopiesPopup from './NumberOfCopiesPopup';

type TopBarContentProps = {
  redirectFunction: Function,
  disabled: boolean
};

const TopBarContent = ({ redirectFunction, disabled }: TopBarContentProps) => (
  <TopBar zIndex={1000}>
    <Button
      alignItems="center"
      justifyContent="center"
      onPress={redirectFunction}
      disabled={disabled}
    >
      <Button
        position="absolute"
        left={-2.5}
        alignItems="center"
        justifyContent="center"
        rounded
        size={2.2}
        overflow="hidden"
        backgroundColor="white"
      >
        <Icon name="close" scale={-1} color="black" justifyContent="center" alignItems="center" />
      </Button>
      <Text color="white" bold><FormattedMessage {...messages.closePayment} /></Text>
    </Button>
  </TopBar>
);

type PaymentPageProps = {|
  dispatch: Dispatch,
  intl: $IntlShape,
  selectedOrderLines: Id[],
  error: ApiError[],
  ongoingTransactionError: ApiError,
  prgDotaz: any,
  prgDotazPayment: any,
  formDisabled: boolean,
  activeReceipt: ActiveReceipt,
  parameters: any
|};

type Payment = {
  id: string,
  text: string,
  value: number
}

type PaymentPageState = {
  payments: Payment[],
  isContextMenuOpen: boolean,
};

/**
 * Page that handles Payment
 * @returns {React}
 */
class PaymentPage extends React.Component<PaymentPageProps, PaymentPageState> {
  state = {
    isContextMenuOpen: false,
    isCustomDiscountMenuOpen: false,
    discountTypeValue: 'relative',
    valuesFromInputListener: '',
    showRegisterParagonPopup: false,
    showPrinterSelectPopup: false,
    showNoOfCopiesPopup: false
  };

  toggleContextMenu = value => {
    const newVal = value === 'toggle' ? !this.state.isContextMenuOpen : value;
    this.setState({ isContextMenuOpen: newVal });
  };

  openLoyalMan = () => {
    const { dispatch, selectedOrderLines, paymentTransactionId } = this.props;

    dispatch(toggleLoyalmanUsers(true, selectedOrderLines, paymentTransactionId));
  };

  toggleParagonPopup = value => {
    this.setState({ showRegisterParagonPopup: value });
  };

  togglePrintersPopup = value => {
    this.setState({ showPrinterSelectPopup: value });
  };

  toggleNoOfCopiesPopup = value => {
    this.setState({ showNoOfCopiesPopup: value });
  };

  openCustomDiscount = value => {
    this.setState({
      isCustomDiscountMenuOpen: true,
      discountTypeValue: value || 'relative'
    });
  };

  closeCustomDiscount = () => {
    this.setState({
      isCustomDiscountMenuOpen: false,
      discountTypeValue: 'relative'
    });
  };

  redirectToOrder = () => {
    const { dispatch, paymentTransactionId } = this.props;
    dispatch([
      replace('/order'),
      clearPaymentTransaction(paymentTransactionId),
      toggleLoyalmanUsers(false)
    ]);
  };

  redirectToReceipts = () => {
    const { dispatch, paymentTransactionId } = this.props;
    dispatch([
      replace('/admin/receipts'),
      clearPaymentTransaction(paymentTransactionId),
      toggleLoyalmanUsers(false)
    ]);
  };

  clearError = () => {
    const { dispatch } = this.props;
    dispatch([
      clearOrderError(),
      clearAdminError()
    ]);
  };

  addLoyalManCard = code => {
    const { dispatch, paymentTransactionId } = this.props;
    console.log('keyboard code: ', code);

    api.payment.addCardDiscount(paymentTransactionId, code)
      .then(({ body }) => {
        dispatch(updatePaymentTransaction(processReceivedPaymentTransactionData(body, code)));
      })
      .catch(e => {
        console.log('some catch took place at card reading: ', e);
      });
  };

  handleKeyboardPress = event => {
    const {
      dispatch,
      editPaymentRecord,
      prgDotaz,
      isTextPopupOpen,
      ongoingTransactionError,
      isPaymentNotePopupOpen,
      isNewCardPopupOpen,
      isNewCardPopupFirstStep
    } = this.props;
    const { valuesFromInputListener } = this.state;

    if (!editPaymentRecord
      && !prgDotaz
      && !isTextPopupOpen
      && !ongoingTransactionError
      && !isPaymentNotePopupOpen
    ) {
      if (event.keyCode === 13) {
        if (valuesFromInputListener.length) {
          if (isNewCardPopupOpen) {
            if (isNewCardPopupFirstStep) {
              dispatch(toggleNewCardPopup(valuesFromInputListener));
              this.setState({ valuesFromInputListener: '' });
            }
          } else {
            this.addLoyalManCard(valuesFromInputListener);
            this.setState({ valuesFromInputListener: '' });
          }
        }
      } else if (excludedMetaKeys(event)) {
        if (!isNewCardPopupOpen || isNewCardPopupFirstStep) {
          this.setState({
            valuesFromInputListener: `${valuesFromInputListener}${replaceSKKeyCharsWithNums(event.key)}`
          });
        }
      }
    }
  };

  // TODO react antipattern - will be moved to parent element or hoc
  // - sofar I have found 2 good react libs (react-key-handler, react-keyboard-event-handler),
  // which where not enough for what we need
  // - react-keyboard-event-handler look awesome, but does not catch the events in some cases (i.e. just after opening popup)
  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyboardPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyboardPress);

    clearOrderKeeper(paymentOperationsName);
  }

  render() {
    const {
      dispatch, intl, error, ongoingTransactionError, prgDotaz, editPaymentRecord,
      prgDotazPayment, formDisabled, activeReceipt, isTextPopupOpen, prgFilterValue,
      isNewCardPopupOpen, isLoyalmanPrepaidItemsPopupOpen, paymentTransactionId,
      isUnfinishedBillPopupOpen, isServiceCouponPopupOpen, isSendReceiptByEmailPopupOpen, parameters
    } = this.props;
    const { showRegisterParagonPopup, showPrinterSelectPopup, showNoOfCopiesPopup } = this.state;

    return (
      <Box
        flex={1}
        height="100%"
        backgroundColor="appBg"
        id="paymentCanvas"
        onClick={() => this.toggleContextMenu(false)}
      >
        <Title message={intl.formatMessage(messages.title)} />

        <TopBarContent redirectFunction={() => activeReceipt ? this.redirectToReceipts() : this.redirectToOrder()} disabled={formDisabled} />

        <Box flexDirection="row" flex={1}>
          <Sidebar alignItems="center" flex={1} maxWidth={26} width="100%">
            <PaymentSheet
              isContextMenuOpen={this.state.isContextMenuOpen}
              toggleContextMenu={this.toggleContextMenu}
              openLoyalMan={this.openLoyalMan}
              openCustomDiscount={this.openCustomDiscount}
              isCustomDiscountMenuOpen={this.state.isCustomDiscountMenuOpen}
              toggleParagonPopup={this.toggleParagonPopup}
              togglePrintersPopup={this.togglePrintersPopup}
              toggleNoOfCopiesPopup={this.toggleNoOfCopiesPopup}
            />
          </Sidebar>

          <SubPaymentsDialogue
            isContextMenuOpen={this.state.isContextMenuOpen}
          />
        </Box>

        {this.state.isCustomDiscountMenuOpen &&
          <CustomDiscountPopup
            closeCustomDiscount={this.closeCustomDiscount}
            discountTypeValue={this.state.discountTypeValue}
          />
        }

        {editPaymentRecord &&
          <EditPaymentRecord />}

        {showRegisterParagonPopup &&
          <RegisterParagonPopup
            toggleParagonPopup={this.toggleParagonPopup}
            paymentTransactionId={paymentTransactionId}
          />}

        {isNewCardPopupOpen &&
          <NewCardPopup />
        }

        {isLoyalmanPrepaidItemsPopupOpen &&
          <ChoosePrepaidItemsPopup />
        }

        {error.length
          ? <Notification
              text={error}
              mode="bottom"
              color="white"
              backgroundColor="red"
              showClose
              onClose={this.clearError}
              customStyle={{ position: 'fixed', bottom: 0, width: '100%' }}
          />
        : null}
        {prgDotaz ?
          <PrgDotaz prgDotaz={prgDotaz} prgDotazPayment={prgDotazPayment} parameters={parameters} />
        : null}

        {isTextPopupOpen && prgDotaz &&
          <PopupTextForm
            filterValue={prgFilterValue}
            updateFilter={value => dispatch(setPrgFilterValue(value))}
          />}

        {isUnfinishedBillPopupOpen &&
          <PopupUnfinishedBill
            dispatch={dispatch}
            bill={isUnfinishedBillPopupOpen.bill}
            closureId={isUnfinishedBillPopupOpen.closure}
            fuciarkod={isUnfinishedBillPopupOpen.bill_id}
            name={intl.formatMessage(messages.paymentPaymentPageUnfinishedBill)}
          />}

        {showPrinterSelectPopup &&
          <PrinterSelectPopup
            availablePrinters={showPrinterSelectPopup}
            onClose={() => this.togglePrintersPopup(false)}
          />}

        {showNoOfCopiesPopup &&
          <NumberOfCopiesPopup
            onClose={() => this.toggleNoOfCopiesPopup(false)}
          />}

        {isServiceCouponPopupOpen &&
          <ServiceCouponPopup />}

        {isSendReceiptByEmailPopupOpen &&
          <SendReceiptByEmailPopup />}

        {ongoingTransactionError ?
          <TransactionErrorPromptWrapper error={ongoingTransactionError} />
        : null}
      </Box>
    );
  }
}

export default compose(
  bindScreenLifecycleActions(paymentsNavigatorActions),
  connect((state: State) => {
    const allErrors = getAllErrors(state);
    const [ongoingTransactionError, otherErrors] = transactionAndOtherErrors(allErrors);

    return {
      orderLines: state.orders.orderLines,
      selectedOrderLines: state.orders.selectedOrderLines,
      payments: state.payment.payments,
      paymentTransactionId: state.payment.paymentTransactionId,
      openTables: state.tables.openTables,
      activeOpenTableId: state.tables.active.openTableId,
      error: otherErrors,
      ongoingTransactionError,
      prgDotaz: state.payment.prgDotaz,
      prgDotazPayment: state.payment.prgDotazPayment,
      prgFilterValue: state.payment.prgFilterValue,
      isPaymentNotePopupOpen: state.payment.isNotePopupOpen,
      isTextPopupOpen: state.orders.active.isTextPopupOpen,
      formDisabled: state.payment.formDisabled,
      editPaymentRecord: state.payment.editPaymentRecord,
      isNewCardPopupOpen: state.payment.isNewCardPopupOpen,
      isNewCardPopupFirstStep: state.payment.isNewCardPopupFirstStep,
      isLoyalmanPrepaidItemsPopupOpen: state.payment.isLoyalmanPrepaidItemsPopupOpen,
      isServiceCouponPopupOpen: state.payment.isServiceCouponPopupOpen,
      activeReceipt: state.admin.receipts.active.receipt,
      isUnfinishedBillPopupOpen: state.admin.receipts.isUnfinishedBillPopupOpen,
      isSendReceiptByEmailPopupOpen: state.payment.isSendReceiptByEmailPopupOpen,
      parameters: state.parameters ? state.parameters.parameters : []
    };
  }),
  injectIntl,
  watchActivePaymentTransactions(({ props: { dispatch } }) => {
    dispatch(replace('/order'));
  })
)(PaymentPage);
