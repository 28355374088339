// @flow
import React from 'react';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';

type LabelProps = {
  color: string,
  backgroundColor: string,
  size: number,
  rounded?: boolean,
  children?: string
};

const Label = ({
  size = 1,
  color = 'black',
  rounded,
  children,
  ...restProps
}: LabelProps) => {
  const childrenIsString = typeof children === 'string' || typeof children === 'number';
  const textSizes = [0, 1, 1.25, 2, 2.8];

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      width={size}
      height={size}
      borderRadius="50%"
      overflow={rounded && 'hidden'}
      {...restProps}
    >
      {childrenIsString
        ?
        <Text
          align="center"
          size={textSizes[Math.round(size)]}
          color={color}
          width={size}
          condensed
          style={theme => ({
            letterSpacing: -1,
            lineHeight: theme.typography.lineHeight() * size,
          })}
        >
          {children}
        </Text>
        :
        children
      }
    </Box>
  );
};

export default Label;
