// @flow
import React from 'react';
import InactiveBg from '../components/InactiveBg';
import Popup from '../components/Popup';
import TransactionErrorPrompt, {
  newQuickPaymentTransaction
} from '../../common/payment/TransactionErrorPrompt';
import { connect } from 'react-redux';
import { ApiError } from '../../common/lib/fetch/errors';
import {
  clearError as clearOrderError, setQuickPaymentMediaId,
} from '../../common/order/actions';
import orderKeeper from '../../common/lib/fetch/orderKeeper';
import { getPrinterUrl, paymentOperationsName } from '../../common/lib/api/payment';
import { getAllOrderLinesIdInFocusSelector } from '../../common/order/selectors';

class TransactionErrorPromptWrapper extends React.PureComponent {
  reqOrderKeeper = orderKeeper(paymentOperationsName);

  cancel = () => {
    const { dispatch } = this.props;
    dispatch([
      setQuickPaymentMediaId(null),
      clearOrderError()
    ]);
  };

  newTransaction = (error: ApiError) => {
    const {
      dispatch,
      selectedOrderLines,
      paymentMediaId,
      sessionId,
      authDefaultPrinter,
      selectedPrinter,
      printers,
      parameters,
      paymentTypes,
      paymentMedia
    } = this.props;
    const selectedPrinterId = selectedPrinter && selectedPrinter.id;
    const socketSelectedPrinter = printers && authDefaultPrinter && printers.find(per => (per.prnNo === selectedPrinterId));
    const socketDefaultPrinter = printers && authDefaultPrinter && printers.find(per => (per.prnNo === authDefaultPrinter));

    const transactionPromise = newQuickPaymentTransaction(
      dispatch,
      selectedOrderLines,
      paymentMediaId,
      sessionId,
      getPrinterUrl(socketSelectedPrinter, socketDefaultPrinter),
      parameters,
      paymentTypes,
      paymentMedia
    )(error);

    return this.reqOrderKeeper(transactionPromise);
  };

  render() {
    return (
      <InactiveBg zIndex={2000}>
        <Popup showClose={false}>
          <TransactionErrorPrompt
            onCancel={this.cancel}
            onNewTransaction={this.newTransaction}
          />
        </Popup>
      </InactiveBg>
    );
  }
}

export default connect((state: State) => ({
  selectedOrderLines: getAllOrderLinesIdInFocusSelector(state),
  paymentMediaId: state.orders.quickPayment.paymentMediaId,
  sessionId: state.device.sessionId,
  authDefaultPrinter: state.auth.defaultPrinter,
  selectedPrinter: state.payment.selectedPrinter,
  printers: state.peripherals.printers,
  parameters: state.parameters.parameters,
  paymentMedia: state.payment.paymentMedia,
  paymentTypes: state.payment.paymentTypes,
}))(TransactionErrorPromptWrapper);
