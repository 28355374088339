// @flow
import type { Id, State, TableDefinition } from '../../common/types';
import React from 'react';
import { withContentRect } from 'react-measure';
import { connect } from 'react-redux';
import { compose } from 'rambda';
import TableArea, { canEditTable } from '../components/TableArea';
import Canvas from '../components/Canvas';
import Table from '../components/Table';
import { findUsedOpenTables } from '../../common/tables/utils';
import Box from '../../common/components/Box';
import type { DraggableTableItem } from '../components/Table';
import TablesController from './TablesController';
import { EditTableMenu, ClosedTableMenu, RelocatingTableMenu } from './ContextMenus';
import MapTablesCoords from '../../common/tables/MapTableCoords';
import Animations from '../configureAnimations';
import {
  undeletedOpenTablesSelector, undeletedTableDefinitionsInAreaSelector
} from '../../common/tables/selectors';
import checkPermission from '../../common/permissions/service';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/serviceAreas';

const TablesCanvas = () => (
  <Canvas bgType="grid" flex={1} width="100%" height="100%">
    <TableArea>
      <TablesMap />
    </TableArea>
  </Canvas>
);

class TablesMap_ extends React.PureComponent {
  props: {
    tablesInArea: TableDefinition[],
    activeAreaId: Id,
    isEditing: boolean,
    focusOnTable: Id,
    isDragging: boolean,
    draggingItem: DraggableTableItem,
    measureRef: any,
    contentRect: any
  };

  _roundToHundreds = value =>
    Math.floor(value / 100) * 100;

  _renderScaledTablesInArea = scaledTablesInArea => {
    const {
      openTables,
      isEditing,
      isRelocatingToTable,
      isCancelingReceiptAndReturningToTable
    } = this.props;

    return scaledTablesInArea.map((table: TableDefinition) => {
      const isReallyEditing = isEditing && canEditTable(table);

      const isLimit = table.limitDayId;

      return (
        <Table
          key={table.id}
          table={table}
          isEditing={isReallyEditing}
          animate={{
            animation: Animations.easeFadeIn,
            duration: 125,
            delay: Math.random() * 150,
            runOnMount: true
          }}
          ContextMenu={
            (isRelocatingToTable || isCancelingReceiptAndReturningToTable)
              ? RelocatingTableMenu
              : isLimit
                ? null
                : isReallyEditing
                  ? EditTableMenu
                  : findUsedOpenTables(table.id, openTables).length
                    ? ClosedTableMenu
                    : null
          }
          ContextMenuBoundaries={this}
        />
      );
    });
  };

  render() {
    const {
      tablesInArea,
      isEditing,
      contentRect,
      measureRef,
      intl
    } = this.props;

    const hasAccess = checkPermission('tabledefinition.read') && checkPermission('opentable.read');
    if (!hasAccess) {
      return (
        <div ref={measureRef} style={{ display: 'flex', width: '100%' }}>
          {intl.formatMessage(messages.serviceAreasTablesGridAccessDenied)}
        </div>
      );
    }

    const { bounds: { width, height } } = contentRect;

    return (
      <div ref={measureRef} style={{ display: 'flex', width: '100%', flex: 1 }}>
        <Box marginTop="20px" marginLeft="20px" flex={1}>
          <MapTablesCoords
            tables={tablesInArea}
            width={this._roundToHundreds(width) - 40}
            height={this._roundToHundreds(height) - 40}
          >
            {this._renderScaledTablesInArea}
          </MapTablesCoords>
          {isEditing && checkPermission('tabledefinition.add') && <TablesController />}
        </Box>
      </div>
    );
  }
}

const TablesMap = compose(
  withContentRect('bounds'),
  connect((state: State) => ({
    tablesInArea: undeletedTableDefinitionsInAreaSelector(state),
    openTables: undeletedOpenTablesSelector(state),
    isEditing: state.tables.editPosition,
    isRelocatingToTable: state.orders.active.isRelocatingToTable,
    isCancelingReceiptAndReturningToTable: state.admin.receipts.isCancelingReceiptAndReturningToTable
  })),
  injectIntl
)(TablesMap_);

export default TablesCanvas;
