// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  messagesKitchenSend: {
    defaultMessage: 'Správa do kuchyne odoslaná',
    id: 'messages.kitchenSend'
  },
  messagesKitchenSendError: {
    defaultMessage: 'Chyba pri posielaní správy do kuchyne: ',
    id: 'messages.kitchenSendError'
  },
  messagesKitchenCreate: {
    defaultMessage: 'Správa do kuchyne - zadanie správy.',
    id: 'messages.kitchenCreate'
  },
  messagesKitchenPrinter: {
    defaultMessage: 'Správa do kuchyne - výber tlačiarne',
    id: 'messages.kitchenPrinter'
  },
  messagesKitchenReminderSend: {
    defaultMessage: 'Pripomienka do kuchyne bola odoslaná',
    id: 'messages.kitchenReminderSend'
  },
  messagesKitchenReminderSendError: {
    defaultMessage: 'Chyba pri posielaní pripomienky do kuchyne: ',
    id: 'messages.kitchenReminderSendError'
  },
});
