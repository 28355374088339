// @flow
import React from 'react';
import { connect } from 'react-redux';
import Title from '../../components/Title';
import Box from '../../../common/components/Box';
import AlcoholList from './AlcoholList';
import titlesMessages from '../../../common/app/messages/titles';
import TopBar from '../TopBar';
import SidebarSpace from '../../components/Sidebar';
import Sidebar from './Sidebar';
import { clearAlcoholIds } from '../../../common/admin/alcohol/actions';
import AdminNotification from '../AdminNotification';

class AlcoholPage extends React.PureComponent {
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearAlcoholIds());
  }

  render() {
    return (
      <Box flex={1} height="100%" backgroundColor="appBg">
        <Title message={titlesMessages.admin} />

        <TopBar />

        <Box flexDirection="row" zIndex={1} flex={1} height="calc(100% - 36px)">
          <Box flex={1} id="alcohol" width="100%">
            <AlcoholList />

            <AdminNotification />
          </Box>

          <SidebarSpace>
            <Sidebar />
          </SidebarSpace>
        </Box>
      </Box>
    );
  }
}

export default connect()(AlcoholPage);
