// @flow
import React from 'react';
import Box from '../../common/components/Box';
import Button from '../../common/components/Button';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import InactiveBg from '../components/InactiveBg';
import Popup from '../components/Popup';

const Blocker = ({
  actionConfirm,
  actionCancel,
  textHeader,
  textDescription,
  textConfirm,
  textCancel
}) => (
  <InactiveBg zIndex={10}>
    <Popup showClose={false} style={{ minWidth: 600 }}>
      <Heading scale={4} color="white">{textHeader}</Heading>

      <Text color="white">{textDescription}</Text>
      <Box height={1} />

      <Box flexDirection="row">
        <Button color="white" outline backgroundColor="teal" onPress={actionConfirm} width="50%" padding={0.5}>{textConfirm}</Button>
        <Box width={1} />
        <Button color="white" outline onPress={actionCancel} width="50%" padding={0.5}>{textCancel}</Button>
      </Box>
    </Popup>
  </InactiveBg>
);

export default Blocker;
