// @flow
import React from 'react';
import Title from '../../../components/Title';
import Box from '../../../../common/components/Box';
import FiscalForm from './FiscalForm';
import titlesMessages from '../../../../common/app/messages/titles';
import TopBar from '../../TopBar';
import Heading from '../../../../common/components/Heading';
import AdminNotification from '../../AdminNotification';
import remoteLoad from '../../../../common/components/hoc/remoteLoad';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/fiscal';

class FiscalModulePage extends React.PureComponent {
  render() {
    const { intl } = this.props;
    return (
      <Box flex={1} height="100%" backgroundColor="appBg">
        <Title message={titlesMessages.admin} />

        <Box zIndex={10} flexShrink={0}>
          <TopBar />
        </Box>

        <Box
          zIndex={1}
          id="fiscal"
          minWidth="20rem"
          maxWidth="80%"
          width="100%"
          marginHorizontal="auto"
          marginVertical={2}
          padding={2}
          paddingBottom={0}
          backgroundColor="white"
          overflow="auto"
          height="calc(100% - 36px)"
        >
          <Heading scale={4} marginBottom={0.5} align="center">{intl.formatMessage(messages.fiscalModuleData)}</Heading>

          <FiscalForm />
        </Box>

        <AdminNotification position="absolute" bottom={0} left={0} right={0} />
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(FiscalModulePage);
