// @flow
import { find, propEq, map } from 'rambda';
import type { Parameter } from '../types';

export const serializeParameters = (parameters: Parameter[], rules) =>
  map((param: Parameter) => {
    const { key, value } = param;
    const typeToMap = rules[key];

    let newValue;

    switch (typeToMap) {
      case 'number':
        newValue = +value;
        break;

      case 'boolean':
        newValue = !(value === 'false' || value === '0' || !value);
        break;

      default:
        newValue = param.value;
    }

    return { ...param, value: newValue };
  }, parameters);

export const findParamValue = (name: string, parameters: Parameter[]) => {
  const found = find(propEq('key', name), parameters) || {};
  return found.value;
};

export const isSpartIncludedInParams = (chosenParam: string, currentSpart: string) =>
  (chosenParam || '').trim() !== ''
    ? (currentSpart || '').trim() !== ''
      ? chosenParam.includes(currentSpart)
      : false
    : false;
