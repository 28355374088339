// @flow
import React from 'react';
import Text from '../../common/components/Text';
import Box from '../../common/components/Box';

const overflow = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export default ({ label, value, children, labelWidth = '100%', valueWidth, ...restProps }) => (
  <Box flexDirection="row" overflow="hidden" {...restProps}>
    <Text flexBasis={labelWidth} overflow="hidden" paddingRight={value && 0.5} justifyContent="center">
      <span style={overflow}>{label}</span>
    </Text>

    {children && <Box flexBasis={valueWidth} overflow="hidden">{children}</Box>}

    {(value && !children) &&
    <Text flexBasis={valueWidth} bold overflow="hidden">
      <span style={overflow}>{value}</span>
    </Text>
    }
  </Box>
);
