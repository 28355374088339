// @flow
import React from 'react';
import Title from '../../../components/Title';
import Box from '../../../../common/components/Box';
import titlesMessages from '../../../../common/app/messages/titles';
import TopBar from '../../mealsCount/TopBar';
import Confirmation from './Confirmation';
import AdminNotification from '../../AdminNotification';

class BulkClosePage extends React.PureComponent {
  render() {
    return (
      <Box flex={1} height="100%" backgroundColor="appBg">
        <Title message={titlesMessages.admin} />

        <TopBar />

        <Box zIndex={1} id="bulkClosePage" flex={1} height="calc(100% - 36px)">
          <Confirmation />

          <AdminNotification hideNotificationOnUnmount={false} />
        </Box>

      </Box>
    );
  }
}

export default BulkClosePage;
