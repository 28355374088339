// @flow
import placeholderPrefixer from 'fela-plugin-placeholder-prefixer';
import validator from 'fela-plugin-validator';
import { createRenderer } from 'fela';
import fallbackValue from 'fela-plugin-fallback-value';
import prefixer from 'fela-plugin-prefixer';
import unit from 'fela-plugin-unit';
// import monolithic from 'fela-monolithic';

import 'react-spinner/react-spinner.css';
import '../common/themes/icomoon/style.css';
import 'react-virtualized/styles.css';
import './themes/react-virtualized.css';
import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';

import Roboto from '!base64-loader!../common/fonts/Roboto-Regular.ttf';
import RobotoBold from '!base64-loader!../common/fonts/Roboto-Bold.ttf';
import RobotoThin from '!base64-loader!../common/fonts/Roboto-Thin.ttf';
import RobotoCondensed from '!base64-loader!../common/fonts/Roboto-Condensed.ttf';
import RobotoBoldCondensed from '!base64-loader!../common/fonts/Roboto-BoldCondensed.ttf';
import Inconsolata from '!base64-loader!../common/fonts/Inconsolata-Regular.ttf';
import InconsolataBold from '!base64-loader!../common/fonts/Inconsolata-Bold.ttf';
import Icomoon from '!base64-loader!../common/themes/icomoon/fonts/icomoon.ttf';

const staticStyles = `
  ${/*
    Selected rules from necolas.github.io/normalize.css/5.0.0/normalize.css
    I removed obsolete and normalizing stuff because we need only fixes.
  */ ''}
  
  @font-face {
    font-fmaily: 'icomoon',
    src: url(data:font/truetype;charset=utf-8;base64,${Icomoon}) format('truetype');
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url(data:font/truetype;charset=utf-8;base64,${Roboto}) format('truetype');
  }
  
  @font-face {
    font-family: 'RobotoBold';
    src: url(data:font/truetype;charset=utf-8;base64,${RobotoBold}) format('truetype');
  }
  
  @font-face {
    font-family: 'RobotoThin';
    src: url(data:font/truetype;charset=utf-8;base64,${RobotoThin}) format('truetype');
  }
  
  @font-face {
    font-family: 'RobotoCondensed';
    src: url(data:font/truetype;charset=utf-8;base64,${RobotoCondensed}) format('truetype');
  }
  
  @font-face {
    font-family: 'RobotoBoldCondensed';
    src: url(data:font/truetype;charset=utf-8;base64,${RobotoBoldCondensed}) format('truetype');
  }
  
  @font-face {
    font-family: 'Inconsolata';
    src: url(data:font/truetype;charset=utf-8;base64,${Inconsolata}) format('truetype');
  }
  
  @font-face {
    font-family: 'InconsolataBold';
    src: url(data:font/truetype;charset=utf-8;base64,${InconsolataBold}) format('truetype');
  }
  
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    height: 100%;
  }
  body {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
  #app {
    height: 100%;
  }
  a {
    -webkit-text-decoration-skip: objects;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  textarea {
    overflow: auto;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  ${/*
    Selected rules from github.com/twbs/bootstrap/blob/v4-dev/scss/_reboot.scss
  */ ''}
  html {
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    user-select: none;
    -webkit-user-select: none;
  }
  html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  [tabindex="-1"]:focus {
    outline: none !important;
  }
  [role="button"] {
    cursor: pointer;
    outline: none;
  }
  a,
  area,
  button,
  [role="button"],
  input,
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }
  textarea {
    resize: none;
  }
  input[type="search"] {
    -webkit-appearance: none;
  }
  
  /* http://webkit-scroll-gen.sourceforge.net/ */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: none;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #ffffff;
  }
  ::-webkit-scrollbar-track {
    background: #666666;
    border: none;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  ::-webkit-scrollbar-track:active {
    background: #666666;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  
  .textfit {
    position: relative;
    flex-direction: column;
    display: flex;
  }
  
  .textfit.just-center {
    justify-content: center;
  }
  
  /* add more classnames for line-clamp */
  .textfit.no-2 div,
  .no-2 {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    hyphens: auto;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
  
  .react-spinner {
    width: 130px !important;
    height: 70px !important;
  }
  
  .react-spinner.dark .react-spinner_bar,
  .react-spinner.gray .react-spinner_bar {
    background-color: #2f2f35;
  }
  
  .react-spinner.teal .react-spinner_bar {
    background-color: #109b97;
  }
  
  .setup-spinner .react-spinner,
  .react-spinner.small {
    width: 30px !important;
    height: 10px !important;
  }
  
  .notification-spinner .react-spinner {
    width: 90px !important;
    height: 50px !important;
    top: 40px;
    margin-bottom: 30px;  
  }
  
  .rc-calendar {
    font-family: roboto;
    box-shadow: none;
    border-radius: 0;
    width: 300px;
    background: white;
  }

  .rc-calendar.rc-calendar-range {
    width: 100%;
  }
  
  .rc-calendar-range .rc-calendar-body {
    border-bottom: none;
  }
  
  .rc-calendar-range-middle {
    display: none;
  }
  
  .rc-calendar-body {
    height: 276px;
    padding: 0;
  }
  
  .rc-calendar-selected-day .rc-calendar-date {
    background: #109b97;
    color: white;
  }
 
  .rc-calendar-selected-date .rc-calendar-date {
    background: #47aeac;
  }
  
  .rc-calendar-selected-start-date:not(.rc-calendar-selected-end-date) .rc-calendar-date {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .rc-calendar-selected-end-date:not(.rc-calendar-selected-start-date) .rc-calendar-date {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .rc-calendar-selected-date .rc-calendar-date:hover {
    background: #47aeac;
  }
  
  .rc-calendar-today .rc-calendar-date {
    border-color: #087f5b;
  }
  
  .rc-calendar table {
    table-layout: fixed;
  }
  
  .rc-calendar-date {
    height: 36px;
    width: 36px;
    line-height: 36px;
  }
  
  .rc-calendar-date:hover {
    background: #47aeac44;
  }
  
  .rc-calendar-selected-day .rc-calendar-date:hover {
    background: #087f5b; 
  }
  
  .rc-calendar-prev-month-btn:hover,
  .rc-calendar-next-month-btn:hover, 
  .rc-calendar-prev-year-btn:hover, 
  .rc-calendar-next-year-btn:hover {
    color: #47aeac;
  }
  
  .rc-calendar-year-select:hover, 
  .rc-calendar-month-select:hover, 
  .rc-calendar-day-select:hover {
    cursor: default;
    color: #666;
  }
  
  .rc-time-picker-panel {
    font-family: roboto;
    z-index: 5000;
  }
  
  .rc-calendar-date-panel {
    padding: 16px;
  }
  
  li.rc-time-picker-panel-select-option-selected {
    font-family: robotoBold;
    font-weight: normal;
    background: #109b97;
    color: white;
  }
  
  .rc-time-picker-panel-select li.rc-time-picker-panel-select-option-selected:hover {
    background: #087f5b; 
  }
  
  .rc-time-picker-panel-select li:hover {
    background: #47aeac44;
  }
  
  .rc-time-picker-panel-input {
    font-family: robotoBold;
    font-size: 14px;
    text-align: center;
  }
  
  .rc-time-picker-panel-select {
    max-height: 215px;
  }
  
  .rc-time-picker-panel-inner {
    top: 3px;
    border-radius: 0;
  }
  
  .absolute {
    position: absolute;
  }
`;

const configureFela = () => {
  const plugins = [
    unit(),
    placeholderPrefixer(),
    prefixer(),
    fallbackValue()
  ];

  const enhancers = [];

  if (process.env.NODE_ENV === 'development') {
    plugins.unshift(validator({ logInvalid: true }));
    // enhancers.unshift(monolithic()); // too slow
  }

  const renderer = createRenderer({
    plugins,
    enhancers,
    devMode: process.env.NODE_ENV === 'development'
  });

  renderer.renderStatic(staticStyles);

  return renderer;
};

export default configureFela;
