// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import type { State } from '../types';
import { validPaymentMediaSelector } from '../payment/selectors';
import ComposedButton from './ComposedButton';
import Box from './Box';
import { map } from 'rambda';
import { formatPriceToFixed } from '../order/utils';
import {findParamValue} from '../parameters/utils';
import { getCurrencySymbol } from '../parameters/service';

class LoyalmanQuickPayments extends React.PureComponent {
  render() {
    const { formDisabled, onSelect, onLongPress, parameters, ...restProps } = this.props;
    let { wallets } = this.props;
    const paramIsHores = findParamValue('K32.is_hores', parameters);

    wallets = map(
      wallet => ({
        ...wallet,
        id: 17,
      }),
      wallets
    );

    return (
      <Box {...restProps}>
        {wallets.map(paymentMedium => {
          const balanceCurrencySymbol = paymentMedium.type.toLowerCase() === 'bonus' ? 'B' : getCurrencySymbol();
          return (
            <Box
              key={paymentMedium.wallet}
              flexGrow={1}
              flexShrink={0}
              paddingHorizontal={0.25}
              flexBasis={`${100 / wallets.length}%`}
              maxWidth={`${100 / wallets.length}%`}
            >
              <ComposedButton
                disabled={formDisabled || (paymentMedium.nazev === 'N_HOTEL' && !paramIsHores)}
                caption={`${paymentMedium.name} (max. ${formatPriceToFixed(Math.abs(paymentMedium.maxWithdrawal))} ${getCurrencySymbol()} z ${formatPriceToFixed(Math.abs(paymentMedium.balance))} ${balanceCurrencySymbol})`}
                backgroundColor="blue"
                shadowColor="darkBlue"
                icon="credit-card"
                onPress={() => onSelect(paymentMedium)}
                onLongPress={() => onLongPress(paymentMedium)}
              />
            </Box>
          );
        })}
      </Box>
    );
  }
}

export default connect(
  (state: State) => ({
    validPaymentMedia: validPaymentMediaSelector(state),
    formDisabled: state.payment.formDisabled,
    parameters: state.parameters.parameters,
    paymentTypes: state.payment.paymentTypes,
    wallets: state.payment.loyalman ? state.payment.loyalman.wallets : [],
  })
)(LoyalmanQuickPayments);
