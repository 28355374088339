// @flow
import type { State } from '../../../common/types';
import React from 'react';
import DefaultTopBar from '../../../common/components/TopBar';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import Button from '../../../common/components/Button';
import Icon from '../../../common/components/Icon';
import Text from '../../../common/components/Text';
import Time from '../../../common/components/Time';
import { signOut } from '../../../common/auth/actions';
import { clearSelectedOrderLines } from '../../../common/order/actions';
import { deactivateSubTable, deactivateOpenTable } from '../../../common/tables/actions';
import { deactivateReceipt } from '../../../common/admin/receipts/actions';
import RelocatingItems from '../../order/topbars/RelocatingItems';
import { myNameSelector } from '../../../common/auth/selectors';
import Box from '../../../common/components/Box';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/receipts';

export const ReceiptTopBar = ({ dispatch, closeTitle, userName }) => (
  <DefaultTopBar backgroundColor="lessDarkGray">
    <Time color="white" bold justifyContent="center" paddingLeft={1} />

    <Button
      flexDirection="row"
      alignItems="center"
      paddingLeft={2.5}
      zIndex={1}
      onPress={() =>
        dispatch([
          clearSelectedOrderLines(),
          deactivateSubTable(),
          deactivateOpenTable(),
          deactivateReceipt(),
          replace('/serviceAreas')
        ])
      }
    >
      <Icon
        position="absolute"
        left={0}
        name="close"
        color="black"
        padding={0.76}
        scale={-1}
        backgroundColor="white"
        borderRadius="50%"
      />
      <Text color="white" bold>{closeTitle}</Text>
    </Button>

    <Button flexDirection="row" onPress={() => dispatch(signOut())} paddingRight={1} alignItems="center">
      <Text bold color="white" flexDirection="row">
        <Icon name="user" color="white" bold marginRight={0.25} scale={1} />
        {userName}
      </Text>
    </Button>
  </DefaultTopBar>
);

class TopBar extends React.PureComponent {
  render() {
    const { dispatch, userName, isCancelingReceiptAndReturningToTable, intl } = this.props;

    return (
      <Box zIndex={10}>
        {isCancelingReceiptAndReturningToTable
          ? <RelocatingItems />
          : <ReceiptTopBar
            dispatch={dispatch}
            userName={userName}
            closeTitle={isCancelingReceiptAndReturningToTable
              ? intl.formatMessage(messages.receiptsCancelMove)
              : intl.formatMessage(messages.receiptsCancel)}
          />
        }
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    userName: myNameSelector(state),
    isCancelingReceiptAndReturningToTable: state.admin.receipts.isCancelingReceiptAndReturningToTable,
  })),
  injectIntl
)(TopBar);
