// @flow
import type { Action, MenuItemsState } from '../types';
import { buildMenuItemsPages, connectMenuItemsWithItems } from './utils';
import { propEq, find, append, dropLast, last } from 'rambda';
import * as utils from '../parameters/utils';
import { isReactNative } from '../app/detectPlatform';
import { clearFsStore } from '../lib/utils';

const initialState = {
  pages: [],
  activePageId: null,
  defaultPageId: null,
  lastPagesId: [],
};

const reducer = (state: MenuItemsState = initialState, action: Action): MenuItemsState => {
  switch (action.type) {
    case 'REHYDRATE_FS_COMMIT': {
      return {
        ...state,
        ...action.payload.menuItems
      };
    }
    case 'SYNC_SERVER_DATA_COMMIT': {
      const {
        payload: {
          CategoryContent: categoryContent = [],
          Category: categories,
          Item: items,
          Parameters: parameters = []
        }
      } = action;

      const builtPages = buildMenuItemsPages(categoryContent);
      const pages = builtPages.map(p => ({
        id: p.id,
        content: connectMenuItemsWithItems(p.content, items, categories)
      }));

      const parameterPageId = utils.findParamValue('K32.ini_page_n', parameters);
      let activePageId;

      if (parameterPageId) {
        activePageId = (find(propEq('id', parameterPageId), pages) || {}).id;
      }

      if (!activePageId) {
        activePageId = (pages[0] || {}).id;
      }

      if (isReactNative) {
        const Store = require('react-native-fs-store').default;

        clearFsStore('menuItems').then(() => {
          new Store('menuItems').setItem('menuItems', {
            pages,
            activePageId: state.activePageId || activePageId,
            defaultPageId: activePageId,
          });
        });
      }

      return {
        ...state,
        pages,
        activePageId: state.activePageId || activePageId,
        defaultPageId: activePageId,
      };
    }

    case 'MENU_ITEMS_ACTIVATE_PAGE': {
      const { id } = action.payload;

      if (id === state.activePageId) return state;

      const lastPagesId = state.defaultPageId === id
        ? []
        : last(state.lastPagesId) === id
          ? state.lastPagesId
          : append(state.activePageId, state.lastPagesId);

      return { ...state, activePageId: id, lastPagesId };
    }

    case 'MENU_ITEMS_ACTIVATE_LAST_PAGE': {
      const activePageId = last(state.lastPagesId);
      const lastPagesId = dropLast(1, state.lastPagesId);

      return { ...state, activePageId, lastPagesId };
    }

    case 'MENU_ITEMS_RESET': {
      return {
        ...state,
        activePageId: state.defaultPageId,
        lastPagesId: []
      };
    }

    default:
      return state;
  }
};

export default reducer;
