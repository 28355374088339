// @flow
import type { KeyboardButtonsProps } from '../index';
import React from 'react';
import Box from '../../Box';
import KbButton from '../CalculatorButton';
import { injectIntl } from 'react-intl';
import messages from '../messages/buttons';

class Buttons extends React.PureComponent<KeyboardButtonsProps> {
  render() {
    const { onPress, intl, disabled } = this.props;

    return (
      <Box>
        <Box flexDirection="row" flexShrink={0}>
          <KbButton char={7} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={8} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={9} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char="&divide;" onPress={onPress} sendChar="/" operator borderTop disabled={disabled} />
        </Box>
        <Box flexDirection="row" flexShrink={0}>
          <KbButton char={4} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={5} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={6} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char="&times;" onPress={onPress} borderTop sendChar="*" operator disabled={disabled} />
        </Box>
        <Box flexDirection="row" flexShrink={0}>
          <KbButton char={1} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={2} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={3} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char="&minus;" onPress={onPress} borderTop sendChar="-" operator disabled={disabled} />
        </Box>
        <Box flexDirection="row" flexShrink={0}>
          <KbButton char={0} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={intl.formatMessage(messages.dot)} onPress={onPress} borderRight borderTop sendChar="." disabled={disabled} />
          <KbButton char="=" onPress={onPress} borderRight borderTop operator disabled={disabled} />
          <KbButton char="+" onPress={onPress} borderTop operator disabled={disabled} />
        </Box>
      </Box>
    );
  }
}

export default injectIntl(Buttons);
