// @flow
import React from 'react';
import Box from '../Box';
import { clone } from 'ramda';
import { replaceSKKeyCharsWithNums } from '../../lib/utils';

export type Operations = {|
  sendKey: (store: any, key: any) => void
|}

export type KeyboardButtonsProps = {
  onPress: () => void,
  disabled: boolean,
  altPressed?: boolean
};

export type KeyboardDisplayProps = {
  value: string | number,
  onPress: () => void,
  disabled: boolean
}

export type KeyboardProps = {
  Buttons?: () => React.Component,
  Display?: () => React.Component,
  createOperations: () => Operations,
  onLastKey?: any => {},
  onDisplayValue?: any => {},
  onMemoryChange?: any => {},
  defaultValue?: string,
  maxValue?: number,
  disabled?: boolean,
  replaceSkCharsWithNums?: boolean,
  firstZero?: boolean,
}

class Keyboard extends React.PureComponent {
  props: KeyboardProps;

  store = {
    input: '',
    memory: [],
    lastKeys: [],
    reset: false,
    firstZero: false,

    isFirstZero() {
      return this.firstZero;
    },

    isReset() {
      return this.reset;
    },

    // TODO
    setReset(val) {
      this.reset = val;
    },

    getLastKey(num = 0) {
      return this.lastKeys.length ? this.lastKeys[this.lastKeys.length - num - 1] : '';
    },

    setLastKey(key) {
      this.reset = false;
      this.lastKeys.push(key);
    },

    curInput() {
      return this.input;
    },

    curInputFormatted() {
      return this.input;
    },

    curMemories() {
      return this.memory.filter((m) => m !== undefined);
    },

    saveMemory(input) {
      if (this.canSaveMemory) {
        this.memory.push(input);
      }
      this.canSaveMemory = false;
    },

    setNewInput(str) {
      const curInput = str;
      const oldInput = this.curInput();

      if (oldInput !== curInput) {
        this.input = curInput;
        this.canSaveMemory = true;
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = this.store;

    if (props.firstZero) {
      this.state.firstZero = props.firstZero;
    }

    if (props.defaultValue) {
      this.state.input = props.defaultValue;

      if (props.Display) {
        // otherwise developer controls value via API (bad idea)
        this.state.reset = true;
      }
    }

    this.operations = props.createOperations(this._commitState);
  }

  componentWillUpdate(nextProps, nextState) {
    const { onDisplayValue, onLastKey, onMemoryChange } = this.props;

    if (onDisplayValue && (this.state.curInputFormatted() !== nextState.curInputFormatted())) {
      onDisplayValue(nextState.curInputFormatted());
    }

    if (onLastKey && (this.state.lastKeys.length !== nextState.lastKeys.length)) {
      onLastKey(nextState.getLastKey(), nextState.curInputFormatted());
    }

    if (onMemoryChange && (this.state.memory.length !== nextState.memory.length)) {
      onMemoryChange(nextState.curMemories());
    }

    if (nextProps.defaultValue && this.props.defaultValue && nextProps.defaultValue !== this.props.defaultValue) {
      if (nextProps.Display) {
        this.state.reset = true;
      }
      this.operations = nextProps.createOperations(this._commitState);
    }
  }

  _commitState = newState => {
    // is already clone from sendKey
    this.setState(newState);
  };

  sendKey = key => {
    const { maxValue, replaceSkCharsWithNums = false } = this.props;
    let overflowingMaxValue = false;

    if (replaceSkCharsWithNums) {
      key = replaceSKKeyCharsWithNums(key);
    }

    if (maxValue) {
      overflowingMaxValue = Number(`${this.state.curInput()}${key}`) > maxValue;
    }

    if (overflowingMaxValue) {
      this.operations.sendKey(clone(this.state), 'setMax', maxValue);
    } else {
      this.operations.sendKey(clone(this.state), key);
    }
  };

  render() {
    const {
 createOperations, defaultValue, maxValue, onLastKey, onDisplayValue, onMemoryChange,
      disabled, altPressed = false, Buttons, Display, additionalButtons, replaceSkCharsWithNums,
      ...restProps
    } = this.props;

    return (
      <Box {...restProps} flexShrink={0}>
        <Box>
          {!!Display && (
            <Display
              value={this.state.curInputFormatted()}
              onPress={this.sendKey}
              disabled={disabled}
              lastKey={this.state.getLastKey()}
            />
          )}
        </Box>
        {!!Buttons && (
          <Box>
            <Buttons
              onPress={this.sendKey}
              disabled={disabled}
              altPressed={altPressed}
              additionalButtons={additionalButtons}
            />
          </Box>
        )}
      </Box>
    );
  }
}

export default Keyboard;
