// @flow
import React from 'react';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import ComposedButton from '../../common/components/ComposedButton';
import DiscountContextMenuWrapper from '../../common/payment/DiscountContextMenuWrapper';
import DiscountContextMenuContent from '../../common/payment/DiscountContextMenuContent';
import { isReactNative } from '../../common/app/detectPlatform';

type DiscountButtonProps = {
  caption: string,
  isContextMenuOpen: boolean,
}

class DiscountButton extends React.PureComponent<DiscountButtonProps> {
  render() {
    const {
      caption,
      isContextMenuOpen,
      onClick,
      openCustomDiscount,
      isCustomDiscountMenuOpen,
      marginBottom,
      disabled,
      icon = '%'
    } = this.props;

    return (
        <Box
          flexGrow={0}
          flexShrink={0}
          marginTop={0.5}
          marginBottom={marginBottom}
          alignItems="center"
          zIndex={1000}
          paddingHorizontal={isReactNative ? 1 : 0}
          width={isReactNative ? '62%' : '44%'}
        >
          <ComposedButton
            disabled={disabled}
            icon={<Text color="white" bold scale={2}>{icon}</Text>}
            caption={caption || 'Discount'}
            backgroundColor="darkGray"
            shadowColor="black"
            width="100%"
            onPress={(e) => {
              e.stopPropagation();
              onClick('toggle');
            }}
            opacity={1}
          />

          {!isReactNative && isContextMenuOpen &&
            <DiscountContextMenuWrapper target={this} backgroundColor="popupBg">
              <DiscountContextMenuContent
                openCustomDiscount={openCustomDiscount}
                isCustomDiscountMenuOpen={isCustomDiscountMenuOpen}
              />
            </DiscountContextMenuWrapper>}
        </Box>
    );
  }
}

export default DiscountButton;
