// @flow
import 'rxjs';
import { combineEpics } from 'redux-observable';
import * as authEpics from './auth/epics';
import * as syncEpics from './sync/epics';
import * as tablesEpics from './tables/epics';
import * as orderEpics from './order/epics';
import * as itemsEpics from './items/epics';
import * as receiptsEpics from './admin/receipts/epics';
import * as paymentEpics from './payment/epics';

const epics = [
  ...Object.values(authEpics),
  ...Object.values(syncEpics),
  ...Object.values(tablesEpics),
  ...Object.values(orderEpics),
  ...Object.values(itemsEpics),
  ...Object.values(receiptsEpics),
  ...Object.values(paymentEpics),
];

const configureEpics = (platformEpics, deps: Object) =>
  (action$: any, { getState }: any) =>
    combineEpics(...epics, ...platformEpics)(action$, { ...deps, getState });

export default configureEpics;
