// @flow
import {
 map, find, propEq, filter, reject, pathOr, contains
} from 'rambda';
import { isEmpty, isNil } from 'ramda';
import type { PaymentObject } from './actions';
import { conflictStatusCode } from './TransactionErrorPrompt';

export const parsePrgDotaz = (prgDotaz: string = '') => {
  // match name_whatever(int/float/true/false/string v uvodzovkach).
  const matches = /([^()]*)(\((.*)\))?/.exec(prgDotaz);

  if (!matches) return [];

  const name = matches[1].trim();
  const params = matches[3];

  if (!params) return [name];

  const explodedParams = params.split(',');

  const typedParams = map(param => {
    param = param.trim();

    const inQuotes = /("(.*)")|('(.*)')/.exec(param);
    if (inQuotes) {
      param = inQuotes[2] === undefined ? inQuotes[4] : inQuotes[2];
    }

    if (`${+param}` === param) {
      return +param;
    }

    const boolean = /(true\.?)|(false\.?)/i.exec(param);
    if (boolean) {
      return !!boolean[1];
    }

    return param;
  }, explodedParams);


  return [name, ...typedParams];
};

/**
 * @memberof common/payment/utils
 * @param {Object} paymentObject
 * @returns {Object}
 */
export const processReceivedPaymentTransactionData = (paymentObject: Object, cardCode: string, prepaidItemsChosen: boolean): PaymentObject => ({
  payments: paymentObject.payment.paymentRecords,
  appliedDiscounts: filter(
    ({ type }) => type === 'relative' || type === 'absolute',
    paymentObject.payment.operations || []
  ),
  appliedOperations: filter(
    ({ type }) => type === 'price_level',
    paymentObject.payment.operations || []
  ),
  loyalmanOperations: filter(
    ({ type }) => type === 'loyalman',
    paymentObject.payment.operations || []
  ),
  loyalmanPrepaidProducts: filter(
    ({ type }) => type === 'loyalman_prepaid_products',
    paymentObject.payment.operations || []
  ),
  availableDiscounts: paymentObject.available_discounts || [],
  availablePaymentMedia: paymentObject.available_payment_media || [],
  stats: {
    overallSum: paymentObject.payment.originalAmount,
    overallSumAfterDiscount: paymentObject.payment.totalAmount,
    leftToPay: paymentObject.payment.amountToPay,
    overallTip: paymentObject.payment.tip,
    overpay: paymentObject.payment.overpay,
    allowTip: paymentObject.payment.allowTip
  },
  paymentTransactionId: paymentObject.payment.id,
  loyalman: cardCode ? { ...paymentObject.loyalman, cardCode } : paymentObject.loyalman,
  availablePrinters: paymentObject.available_printers,
  defaultPrinter: paymentObject.default_printer,
  copies: paymentObject.copies
});

/**
 * @memberof common/payment/utils
 * @param {Object} paymentObject
 * @returns {[]}
 */
export const extractPaymentWarnings = (paymentObject: Object): PaymentObject =>
  pathOr([], ['warnings'], paymentObject);

/**
 * @memberof common/payment/utils
 * @param {Id} activeOpenTableId
 * @param {Object[]} openTables
 * @returns {boolean}
 */
export const checkIfIsBar = (activeOpenTableId, openTables) => {
  let isBar = false;
  const activeOpenTable = find(propEq('id', activeOpenTableId), openTables);

  if (activeOpenTable) {
    isBar = activeOpenTable.barCash || activeOpenTable.bar;
  }

  return isBar;
};

export const transactionAndOtherErrors = allErrors => {
  const ongoingTransactionError = find(propEq('status', conflictStatusCode), allErrors || []);
  const otherErrors = reject(propEq('status', conflictStatusCode), allErrors || []);
  return [ongoingTransactionError, otherErrors];
};


/**
 * @memberof common/payment/utils
 * @param {Id} cardId
 * @param {Object[]} existingCards
 * @returns {boolean}
 */
export const checkIfCardExists = (cardId, existingCards) => {
  const processedCards = pathOr([], 'cards.edges', existingCards);

  if (isEmpty(processedCards) || !cardId) return false;

  return contains(
      cardId,
      map(card => pathOr('', 'node.code', card), processedCards)
    );
};


/**
 * @memberof common/payment/utils
 * @param {Id} cardId
 * @param {Object[]} existingCards
 * @returns {boolean}
 */
export const checkIfCardIsInactive = (cardId, existingCards) => {
  const processedCards = pathOr([], 'cards.edges', existingCards);

  if (isEmpty(processedCards) || !cardId) return false;

  const fullCardInfo = find(propEq('code', cardId), map(card => pathOr('', 'node', card), processedCards));
  const cardStatus = pathOr('OTHER', 'status', fullCardInfo);

  return cardStatus === 'UNRESOLVED';
};

/**
 * @memberof common/payment/utils
 * @param {Object} props
 * @param {Object} nextProps
 * @returns {boolean}
 */
export const isLoyalmanPrepaidCase = (props, nextProps) => {
  const { loyalman } = props;
  const { loyalman: nextLoyalman, openingLoyalmanPrepaidItemsPopup } = nextProps;
  if (!nextLoyalman) return false;

  if (!nextLoyalman.id) return false;

  const oldId = pathOr(null, 'id', loyalman);

  if (oldId === nextLoyalman.id && !openingLoyalmanPrepaidItemsPopup) return false;

  const prepaidProductsPurchase = pathOr(null, 'loyalman.prepaid_product_purchase', nextProps);
  const prepaidProductsWithdrawal = pathOr(null, 'loyalman.prepaid_product_withdrawal', nextProps);

  const isLoyalmanPrepaidCase = (!isEmpty(prepaidProductsPurchase) && !isNil(prepaidProductsPurchase))
    || (!isEmpty(prepaidProductsWithdrawal) && !isNil(prepaidProductsWithdrawal));

  return isLoyalmanPrepaidCase;
};
