// @flow
import fetch from '../fetch';
import type { AdminAlcoholFields, Id } from '../../types';
import { apiEndpoint, getApiUrl } from './index';

export const getAlcohol = (
  pageSize: number,
  id: number
): Promise =>
  fetch(apiEndpoint('v1/alcohol'), {
    baseURL: getApiUrl(),
    params: {
      page_size: pageSize,
      id: id || ''
    }
  });

export const deleteAlcohol = (
  ids: number[]
): Promise =>
  fetch(apiEndpoint('v1/alcohol'), {
    method: 'DELETE',
    baseURL: getApiUrl(),
    params: {
      id: ids
    }
  });

// TODO - update sent parameters
export const addAlcohol = (
  alcoholFields: AdminAlcoholFields
): Promise =>
  fetch(apiEndpoint('v1/alcohol'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        ean: alcoholFields.ean,
        pocet: alcoholFields.count,
        datum: alcoholFields.date
      },
      name: '',
      id: '',
      session_id: '',
      device_id: ''
    }
  });

export const editAlcohol = (
  id: Id,
  alcoholFields: AdminAlcoholFields
): Promise =>
  fetch(apiEndpoint(`v1/alcohol/${id || ''}`), {
    method: 'PUT',
    baseURL: getApiUrl(),
    body: {
      params: {
        ean: alcoholFields.ean,
        pocet: alcoholFields.count,
        datum: alcoholFields.date
      },
      name: '',
      id: '',
      session_id: '',
      device_id: ''
    }
  });
