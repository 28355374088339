// @flow
import React from 'react';
import Box from '../../common/components/Box';
import theme from '../../common/themes/theme';

type GridProps = {
  display?: string,
  columns: number | string,
  row: number | string,
  areas?: string,
  columnGap?: number,
  rowGap?: number,
  justifyItems?: 'start' | 'end' | 'center' | 'stretch',
  alignItems?: 'start' | 'end' | 'center' | 'stretch',
  justifyContent?: 'start' | 'end' | 'center' | 'stretch'
    | 'space-around' | 'space-between' | 'space-evenly',
  alignContent?: 'start' | 'end' | 'center' | 'stretch'
    | 'space-around' | 'space-between' | 'space-evenly',
  autoColumns?: number | string,
  autoRows?: number | string,
  autoFlow?: 'row' | 'column' | 'row dense' | 'column dense',
  style?: any
};

const Grid = ({
  display = 'grid',
  columns,
  rows,
  areas,
  columnGap,
  rowGap,
  justifyItems,
  alignItems,
  justifyContent,
  alignContent,
  autoColumns,
  autoRows,
  autoFlow,
  style = {},
  children,
  ...restProps
}: GridProps) => {
  let buildStyle = {};
  const maybeRhythmProps = { columns, rows, columnGap, rowGap };
  Object.keys(maybeRhythmProps).forEach(prop => {
    let value = maybeRhythmProps[prop];

    if (typeof value === 'number') {
      buildStyle = { ...buildStyle, [prop]: theme.typography.lineHeight(0) * value };
    } else if (typeof value === 'string') {
      const values = value.split(' ');
      value = values.map(subVal =>
        typeof subVal === 'number'
          ? theme.typography.lineHeight(0) * subVal
          : subVal
      ).join(' ');
      buildStyle = { ...buildStyle, [prop]: value };
    } else if (Array.isArray(value)) {
      value = value.map(subVal =>
        typeof subVal === 'number'
          ? `${theme.typography.lineHeight(0) * subVal}px`
          : subVal
      ).join(' ');
      buildStyle = { ...buildStyle, [prop]: value };
    } else {
      buildStyle = { ...buildStyle, [prop]: value };
    }
  });

  return (
    <Box
      style={theme => ({
        display,
        gridTemplateColumns: buildStyle.columns,
        gridTemplateRows: buildStyle.rows,
        gridTemplateAreas: areas,
        gridColumnGap: buildStyle.columnGap,
        gridRowGap: buildStyle.rowGap,
        justifyItems,
        alignItems,
        justifyContent,
        alignContent,
        gridAutoColumns: autoColumns,
        gridAutoRows: autoRows,
        gridAutoFlow: autoFlow,
        ...(typeof style === 'function' ? style(theme) : style)
      })}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default Grid;
