// @flow
import React from 'react';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Popup from '../../components/Popup';
import Keyboard from '../../../common/components/Keyboard/customDiscount/Keyboard';
import TypewriterButtons from '../../../common/components/Keyboard/typewriter/Buttons';
import TwoDisplays from '../../../common/components/Keyboard/typewriter/TwoDisplays';
import createTypewriterOperations from '../../../common/components/Keyboard/typewriter/operations';
import api from '../../../common/lib/api';
import { addError } from '../../../common/order/actions';
import { paymentOperationsName } from '../../../common/lib/api/payment';
import orderKeeper from '../../../common/lib/fetch/orderKeeper';
import InactiveBg from '../../components/InactiveBg';
import { toggleUnfinishedBillPopup } from '../../../common/admin/receipts/actions';
import Button from '../../../common/components/Button';
import Heading from '../../../common/components/Heading';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/receipts';

class UnfinishedBillPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: '',
      okp: '',
      activeKeyboard: 1,
    };
  }

  reqOrderKeeper = orderKeeper(paymentOperationsName);

  _onClose = () => {
    const { dispatch } = this.props;
    dispatch(toggleUnfinishedBillPopup(false));
  };

  reprintBill = () => {
    const { dispatch, fuciarkod } = this.props;

    this.reqOrderKeeper(() =>
      api.payment.reprintBill(fuciarkod)
        .then(() => this._onClose())
        .catch(e => {
          this._onClose();
          dispatch(addError(e));
        }));
  };

  rollbackBill = () => {
    const { dispatch, bill, closureId, fuciarkod } = this.props;

    this.reqOrderKeeper(() =>
      api.payment.rollbackBill(bill, closureId, fuciarkod)
        .then(() => this._onClose())
        .catch(e => {
          this._onClose();
          dispatch(addError(e));
        }));
  };

  finishUnfinishedBill = () => {
    const { dispatch, bill, closureId, fuciarkod } = this.props;
    const { uid, okp } = this.state;

    this.reqOrderKeeper(() =>
      api.payment.finishUnfinishedBill(bill, closureId, fuciarkod, uid, okp)
        .then(() => this._onClose())
        .catch(e => {
          this._onClose();
          dispatch(addError(e));
        }));
  };

  render() {
    const { bill, closureId, name, intl } = this.props;
    const { activeKeyboard } = this.state;

    return (
      <InactiveBg onClose={this._onClose}>
        <Popup onClose={this._onClose}>
          <Heading scale={4} color="white" marginBottom={1}>{name || intl.formatMessage(messages.receiptsFinishBill)}</Heading>
          <Box marginBottom={0.5} flexDirection="row">
            <Text
              color="white"
              backgroundColor="fullTransparent"
              bold
              onClick={() => this.setState({ activeKeyboard: 1 })}
            >
              {intl.formatMessage(messages.receiptsUid)}
            </Text>
            <Text
              color="white"
              backgroundColor="fullTransparent"
              bold
              position="absolute"
              left={21.5}
              onClick={() => this.setState({ activeKeyboard: 2 })}
            >
              {intl.formatMessage(messages.receiptsOkp)}
            </Text>
          </Box>
          <Box alignItems="center">
            <Keyboard
              zIndex={activeKeyboard === 1 ? 2 : 1}
              style={theme => ({
                width: theme.typography.lineHeight(3) * 1.75 * 11 + theme.typography.lineHeight() * 7.5
              })}
              createOperations={createTypewriterOperations}
              Buttons={TypewriterButtons}
              Display={TwoDisplays}
              onLastKey={key => {
                if (key === 'send') {
                  this.finishUnfinishedBill(bill, closureId);
                }
              }}
              onDisplayValue={val => {
                this.setState({ uid: val[0], okp: val[1] });
              }}
            />
          </Box>

          <Box flexDirection="row" justifyContent="space-between">
            <Button
              onPress={this.reprintBill}
              width="49%"
              backgroundColor="appBg"
              outline
              color="white"
              marginBottom={0.5}
              paddingVertical={0.75}
              paddingHorizontal={1}
              flexShrink={0}
            >
              <Text color="white" bold>
                {intl.formatMessage(messages.receiptsPrintBillRepeat)}
              </Text>
            </Button>

            <Button
              onPress={this.rollbackBill}
              width="49%"
              backgroundColor="red"
              outline
              color="white"
              marginBottom={0.5}
              paddingVertical={0.75}
              paddingHorizontal={1}
              flexShrink={0}
            >
              <Text color="white" bold>
                {intl.formatMessage(messages.receiptsCancelPayment)}
              </Text>
            </Button>
          </Box>
        </Popup>
      </InactiveBg>);
  };
}

export default compose(
  connect(),
  injectIntl
)(UnfinishedBillPopup);
