// @flow
import type { State } from '../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import { myNameSelector } from '../../../common/auth/selectors';
import { ReceiptTopBar } from '../receipts/TopBar';
import Box from '../../../common/components/Box';
import {compose} from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/usage';

class TopBar extends React.PureComponent {
  render() {
    const { dispatch, userName, intl } = this.props;

    return (
      <Box zIndex={10}>
        <ReceiptTopBar
          dispatch={dispatch}
          userName={userName}
          closeTitle={intl.formatMessage(messages.usageCancel)}
        />
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    userName: myNameSelector(state),
    isRelocatingToTable: state.orders.active.isRelocatingToTable,
  })),
  injectIntl
)(TopBar);
