// @flow
import React from 'react';
import Popup from '../../components/Popup';
import InactiveBg from '../../components/InactiveBg';
import Heading from '../../../common/components/Heading';
import createFormOperations from '../../../common/components/Keyboard/itemsAvailability/operations';
import PopupWeightFormDisplay from '../../../common/components/Keyboard/popupWeightForm/Display';
import ItemsAvailabilityButtons from '../../../common/components/Keyboard/itemsAvailability/Buttons';
import Keyboard from '../../../common/components/Keyboard';
import { roundNumber } from '../../../common/lib/numbers';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/mealsCount';

const popupNumericDisplayWithUnit = (unit) => props =>
  <PopupWeightFormDisplay {...props} unit={unit} />;

class ChangeAvailability extends React.PureComponent {
  render() {
    const { availability, unit, onCancel, onSubmit, intl } = this.props;

    return (
      <InactiveBg onClose={onCancel}>
        <Popup onClose={onCancel}>
          <Heading scale={3} color="white">{intl.formatMessage(messages.mealsCountGoodsAvailability)}</Heading>

          <Keyboard
            style={theme => ({ width: theme.typography.lineHeight(5) * 1.75 * 3 })}
            createOperations={createFormOperations}
            Buttons={ItemsAvailabilityButtons}
            Display={popupNumericDisplayWithUnit(unit)}
            onLastKey={(key, availability) => {
              if (key === 'ok') {
                onSubmit(availability === '∞' ? Infinity : availability);
              }
            }}
            defaultValue={availability === Infinity ? '∞' : roundNumber(availability)}
          />
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(ChangeAvailability);
