// @flow
import React from 'react';
import InactiveBg from '../../../components/InactiveBg';
import Popup from '../../../components/Popup';
import NewPartner from './NewPartner';
import Partners from '../../../../common/payment/prgDotazy/Partners';
import api from '../../../../common/lib/api';
import Text from '../../../../common/components/Text';
import Spinner from 'react-spinner';
import Box from '../../../../common/components/Box';
import prgDotaz from '../../../../common/payment/hoc/prgDotaz';
import { paymentOperationsName } from '../../../../common/lib/api/payment';
import orderKeeper, { RequestOrderError } from '../../../../common/lib/fetch/orderKeeper';
import ApprovePayment from './ApprovePayment';

class DotazSt extends React.Component {
  state = {
    showNewPartner: false,
    showApproving: false,
    error: null,
    partnerId: null,
    isLoading: false
  };

  reqOrderKeeper = orderKeeper(paymentOperationsName);

  cancel = () => {
    const { cancelPayment } = this.props;
    cancelPayment();
  };

  goBack = () => {
    const { showNewPartner } = this.state;

    if (showNewPartner) {
      this.setState({
        showNewPartner: false
      });
    } else {
      this.setState({
        showApproving: false
      });
    }
  };

  newPartner = () => {
    this.setState({
      showNewPartner: true
    });
  };

  approvePartner = partnerId => {
    this.setState({ partnerId, showApproving: true });
  };

  selectPartner = approvedBy => {
    const { partnerId } = this.state;
    this.setState({ isLoading: true });

    const result = this.savePrgDotaz({ partnerId, approved_by: approvedBy });

    // result may be undefined if error occured
    if (result && result.then) {
      result.then(() => {
        this.setState({ isLoading: false });
      }).catch(() => {
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
    }

    return result;
  };

  saveNewPartner = (partner) => {
    this.setState({ isLoading: true, error: null });

    return this.reqOrderKeeper(() => api.payment.createPartner(partner))
      .then(({ body: { result: { partner: { idriadok: partnerId } } } }) => {
        this.setState({ showNewPartner: false });
        return this.savePrgDotaz({ partnerId });
      }).catch(e => {
        if (e instanceof RequestOrderError) throw e;

        this.setState({ isLoading: false, error: e.toString() });
      });
  };

  savePrgDotaz = prgData => {
    const { confirmPayment } = this.props;

    return confirmPayment(prgData);
  };

  render() {
    const {
     showNewPartner, showApproving, error, isLoading
    } = this.state;

    return (
      <InactiveBg zIndex={2000} onClose={this.cancel}>
        <Popup
          onClose={this.cancel}
          onBack={(showNewPartner || showApproving) ? this.goBack : null}
        >
          {isLoading && (
            <Box zIndex={10} position="static">
              <Spinner className="absolute" />
            </Box>
          )}

          <Box opacity={isLoading ? 0.2 : 1}>
            {showApproving && <ApprovePayment onFinish={this.selectPartner} disabled={isLoading} />}
            {!showApproving
              ? showNewPartner
                ? <NewPartner savePartner={this.saveNewPartner} />
                : (
                  <Partners
                    newPartner={this.newPartner}
                    selectPartner={this.approvePartner}
                    disabled={isLoading}
                  />
                )
              : null
            }
          </Box>

          {error && <Text color="error">{error}</Text>}
        </Popup>
      </InactiveBg>
    );
  }
}

export default prgDotaz(DotazSt);
