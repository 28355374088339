// @flow
import React from 'react';
import { ApolloConsumer } from 'react-apollo';

const withApolloConsumer = BaseComponent => props => {
  return (
    <ApolloConsumer>
      {client => (<BaseComponent {...props} client={client} />)}
    </ApolloConsumer>
  );
};

export default withApolloConsumer;
