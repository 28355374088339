// @flow
import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { compose, find, propEq } from 'rambda';
import Box from '../../common/components/Box';
import Icon from '../../common/components/Icon';
import Button from '../../common/components/Button';
import Text from '../../common/components/Text';
import Popup from '../components/Popup';
import Heading from '../../common/components/Heading';
import { toggleAdminMenu } from '../../common/tables/actions';
import Permission from '../../common/permissions/Permission';
import Version from '../components/Version';
import InactiveBg from '../components/InactiveBg';
import { toggleAllowSignOutOnInactivity, toggleFullScreen } from '../../common/activity/actions';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/common';
import { toggleLoyalmanUsers } from '../../common/loyalman/users/actions';

class AdminMenu extends React.PureComponent {
  state = {
    isSubMenu: false
  };

  setSubMenu = (is) => {
    this.setState({ isSubMenu: is });
  };

  followLink = (link) => {
    const { dispatch } = this.props;
    dispatch(toggleAdminMenu(false));
    dispatch(replace(link));
  };

  render() {
    const { dispatch, hideLoyalman, intl } = this.props;

    let adminMenuItems = [
      { id: 2, text: intl.formatMessage(messages.commonMenuCopyCancel), link: '/admin/receipts', permission: 'receipts.read' },
      { id: 4, text: intl.formatMessage(messages.commonMenuKitchenMessage), link: '/admin/message', permission: 'kitchen.message.add' },
      { id: 5, text: intl.formatMessage(messages.commonMenuCashClose), isGroup: true },
      { id: 7, text: intl.formatMessage(messages.commonMenuConsumptionView), link: '/admin/usage', permission: ['usage.read', 'actual.usage.read'], any: true },
      { id: 8, text: intl.formatMessage(messages.commonMenuAlcohol), link: '/admin/alcohol', permission: 'alcoholsale.read' },
      { id: 9, text: intl.formatMessage(messages.commonMenuFiscal), link: '/admin/fiscal', isGroup: true },
      { id: 11, text: intl.formatMessage(messages.commonMenuMealsCount), link: '/admin/mealsCount' },
      { id: 10, text: intl.formatMessage(messages.commonMenuPriceList), link: '/admin/priceList', permission: 'pricelist.change' },
      { id: 12, text: intl.formatMessage(messages.commonMenuFullScreen), action: toggleFullScreen },
      { id: 13, text: 'LoyalMAN', action: toggleLoyalmanUsers }
    ];

    if (hideLoyalman) {
      adminMenuItems = adminMenuItems.filter(item => item.id !== 13);
    }

    const adminSubMenuItems = [
      {
        menuItemId: 5,
        list: [
          {
            id: 1,
            text: intl.formatMessage(messages.commonClosuresIntermediate),
            link: '/admin/closures/intermediate',
            permission: 'closure.intermediate.add'
          },
          {
            id: 2,
            text: intl.formatMessage(messages.commonClosuresNew),
            link: '/admin/closures/new',
            permission: 'closure.add'
          },
          {
            id: 3,
            text: intl.formatMessage(messages.commonClosuresInterval),
            link: '/admin/closures/interval',
            permission: 'closure.interval.add'
          },
          {
            id: 7,
            text: intl.formatMessage(messages.commonClosuresSummary),
            link: '/admin/closures/summary',
            permission: 'closure.interval.add'
          },
          {
            id: 8,
            text: intl.formatMessage(messages.commonClosuresSummaryMultiple),
            link: '/admin/closures/summaryMultiple',
            permission: 'closure.interval.add'
          },
          {
            id: 4,
            text: intl.formatMessage(messages.commonClosuresHistory),
            link: '/admin/closures/history',
            permission: 'closure.history.read'
          },
          // {
          //   id: 5,
          //   text: 'Žurnál',
          //   link: '/admin/closures/journal',
          //   isDisabled: true
          // },
          {
            id: 6,
            text: intl.formatMessage(messages.commonClosuresBulkClose),
            link: '/admin/closures/bulkClose',
            permission: 'closures.bulk.add'
          },
        ]
      },
      {
        menuItemId: 9,
        list: [
          {
            id: 1,
            text: intl.formatMessage(messages.commonFiscalModule),
            link: '/admin/fiscal/module',
            permission: 'afs.read'
          },
          {
            id: 2,
            text: intl.formatMessage(messages.commonFiscalConfirm),
            link: '/admin/fiscal/confirm',
            permission: 'afs.read'
          },
          {
            id: 3,
            text: intl.formatMessage(messages.commonFiscalTime),
            link: '/admin/fiscal/time',
            permission: 'prndef.change'
          },
          {
            id: 4,
            text: intl.formatMessage(messages.commonFiscalReset),
            link: '/admin/fiscal/reset',
            permission: 'prndef.change'
          },
        ]
      }
    ];

    return (
      <InactiveBg>
        <Popup
          showClose
          backgroundColor="white"
          color="popupBg"
          onClose={() => dispatch([
            toggleAllowSignOutOnInactivity(true),
            toggleAdminMenu(false)]
          )}
          onCloseButtonShadow="none"
          onBack={this.state.isSubMenu ? () => this.setSubMenu(false) : null}
        >
          {!this.state.isSubMenu &&
            <Heading scale={3} color="popupBg">{intl.formatMessage(messages.commonCashierOptions)}</Heading>}

          {this.state.isSubMenu
            ? <Box>
                {find(propEq('menuItemId', this.state.isSubMenu), adminSubMenuItems).list.map((subItem) => (
                  <Permission name={subItem.permission} key={subItem.id}>
                    <Button
                      backgroundColor="white"
                      outline
                      color="popupBg"
                      width={15}
                      marginBottom={0.5}
                      paddingVertical={0.5}
                      onPress={() => subItem.link && this.followLink(subItem.link)}
                      disabled={subItem.isDisabled}
                    >
                      <Text color="popupBg" bold overflow="hidden" margin="auto">{subItem.text}</Text>
                    </Button>
                  </Permission>
                  )
                )}
              </Box>
            : <Box>
                {adminMenuItems.map((item, index) => (
                  <Permission name={item.permission} any={item.any} key={index}>
                    <Button
                      backgroundColor="white"
                      outline
                      color="popupBg"
                      width={15}
                      marginBottom={0.5}
                      paddingVertical={0.5}
                      onPress={() => item.isGroup
                        ? this.setSubMenu(item.id)
                        : item.link
                          ? this.followLink(item.link)
                          : item.action
                            ? dispatch([
                                toggleAdminMenu(false),
                                item.action()
                              ])
                            : console.log('clicked button - some link')}
                      disabled={item.isDisabled}
                    >
                      <Text color="popupBg" bold overflow="hidden" margin="auto">{item.text}</Text>
                      {item.isGroup &&
                        <Icon
                          name="navigation-left"
                          size={1}
                          color="popupBg"
                          justifyContent="center"
                          position="absolute"
                          right={0.5}
                          top={'50%'}
                          style={{ transform: 'rotate(180deg) translateY(50%)' }}
                        />}
                    </Button>
                  </Permission>
                ))}
              </Box>}
        </Popup>
        <Version />
      </InactiveBg>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(AdminMenu);
