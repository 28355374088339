// @flow
import type { State } from '../types';
import { findParamValue } from './utils';

let getState: () => State;

export const configureParametersService = _getState => {
  getState = _getState;
};

const checkParameter = (parameter: string) => {
  const { parameters } = getState().parameters;

  return findParamValue(parameter, parameters);
};

const getCurrencySymbol = () => {
  const { parameters } = getState().parameters;

  return findParamValue('K32.zkr_mena', parameters) || '€';
};

export { getCurrencySymbol };

export default checkParameter;
