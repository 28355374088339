// @flow
import React from 'react';
import Box from '../../../common/components/Box';
import ComposedButton from '../../../common/components/ComposedButton';
import { connect } from 'react-redux';
import { toggleFilter } from '../../../common/order/actions';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/mealsCount';

class Sidebar extends React.PureComponent {
  render() {
    const { dispatch, intl } = this.props;

    return (
      <Box height="100%" flexDirection="column">
        <Box paddingTop={0.5} paddingHorizontal={0.75} flexShrink={0} flexDirection="row">
          <Box flexGrow={1} flexShrink={0} flexBasis={1} marginHorizontal={0.25}>
            <ComposedButton
              caption={intl.formatMessage(messages.mealsCountFind)}
              backgroundColor="blue"
              shadowColor="darkBlue"
              icon="search"
              iconSize={1.25}
              onPress={() => {
                dispatch(toggleFilter(true));
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(Sidebar);
