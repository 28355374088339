// @flow
import type { Id, State } from '../types';
import { map } from 'rambda';

let globalStore;

export const injectStore = (store) => {
  globalStore = store;
};

export const lookUpId = (lookUp: Id | Id[]) => {
  if (typeof lookUp === 'number') {
    return lookUp;
  }

  if (!lookUp) {
    return lookUp;
  }

  const state: State = globalStore.getState();

  return Array.isArray(lookUp)
    ? map(id => lookUpId(state.localIds[id]) || id, lookUp)
    : lookUpId(state.localIds[lookUp]) || lookUp;
};
