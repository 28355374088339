// @flow
import type { Action, PermissionsState } from '../types';
import { dot } from 'dot-object';

const initialState = {
  permissions: {}
};

const reducer = (state: PermissionsState = initialState, action: Action): PermissionsState => {
  switch (action.type) {
    case 'BLITZ_SYNC_SERVER_DATA_COMMIT':
    case 'SYNC_SERVER_DATA_COMMIT': {
      const { payload: { Permissions } } = action;

      const permissions = dot(Permissions);

      return { ...state, permissions };
    }

    default:
      return state;
  }
};

export default reducer;
