// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { State } from '../../../common/types';
import Box from '../../../common/components/Box';
import { VelocityTransitionGroup } from 'velocity-react';
import Receipts from '../../../common/components/SheetRenderes/Receipts';
import AdminSheetRow from '../../../common/components/SheetRenderes/AdminSheetRow';
import Spinner from '../../../common/components/Spinner';
import ComposedButton from '../../../common/components/ComposedButton';
import api from '../../../common/lib/api';
import {compose, pluck} from 'rambda';
import {
  addAdminError,
  toggleAdminNotificationPopup
} from '../../../common/admin/general/actions';
import OrderLineSheetDecorations
  from '../../../common/components/OrderLineSheet/OrderLineSheetDecorations';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/usage';

class Sidebar extends React.PureComponent {
  state = { printing: false };

  print = () => {
    const {
      dispatch,
      filters: { showActualUsage, dateFrom, dateTo },
      selectedCategoriesId,
      categories,
      intl
    } = this.props;

    let selectedCategories = Object.keys(selectedCategoriesId || {});

    if (!selectedCategories.length) {
      selectedCategories = pluck('druh', categories);
    }

    this.setState({ printing: true });

    api.usage.getUsageItems(showActualUsage, dateFrom, dateTo, selectedCategories, true)
      .then(() => {
        this.setState({ printing: false });
        dispatch(toggleAdminNotificationPopup(intl.formatMessage(messages.usageSendPrintStatistics)));
      }).catch(e => {
        this.setState({ printing: false });
        dispatch(addAdminError(e));
      });
  };

  render() {
    const { printing } = this.state;
    const { items, isLoadingItems, isLoadingCategories, intl } = this.props;

    return (
      <Box backgroundColor="appBg" height="100%" flexDirection="column" justifyContent="space-between">
        <Box zIndex={1} flex={1} padding={1} paddingBottom={0.5}>
          <OrderLineSheetDecorations flex={1} marginBottom={1}>
            <SidebarRows items={items} />

            {isLoadingItems &&
            <Box position="absolute" top={0} left={0} right={0} bottom={0} backgroundColor="rgba(255,255,255,0.8)" zIndex={1}>
              <Spinner />
            </Box>
            }
          </OrderLineSheetDecorations>
        </Box>

        <Box
          paddingBottom={0.75}
          paddingHorizontal={1}
          flexShrink={0}
        >
          <ComposedButton
            disabled={isLoadingItems || isLoadingCategories || printing}
            caption={intl.formatMessage(messages.usagePrintStatistics)}
            backgroundColor="blue"
            shadowColor="darkBlue"
            icon="printer"
            flex={1}
            onClick={this.print}
          />
        </Box>
      </Box>
    );
  }
}

class SidebarRows extends React.PureComponent {
  render() {
    const { items } = this.props;

    const categoryNames = Object.keys(items);

    return (
      <Box zIndex={1} borderColor="lightGray" borderStyle="solid" overflow="auto">
        {categoryNames.map(category => (
          <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp" key={category}>
            <Receipts
              renderers={[
                ({ id, data }) => <AdminSheetRow key={id} {...data} />
              ]}
              activeReceipt={{ id: category, isDisabled: true }}
              receiptItems={items[category]}
              priceLevels={[]}
            />
          </VelocityTransitionGroup>
        ))}
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    items: state.admin.usage.items,
    filters: state.admin.usage.filters,
    selectedCategoriesId: state.admin.usage.selectedCategoriesId,
    categories: state.admin.usage.categories,
    isLoadingItems: state.admin.usage.isLoadingItems,
    isLoadingCategories: state.admin.usage.isLoadingCategories
  })),
  injectIntl
)(Sidebar);
