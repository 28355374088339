// @flow
import type { Parameter, TableDefinition } from '../types';
import { findParamValue } from '../parameters/utils';

export const findPriceLevelForTable =
(tableDefinition: TableDefinition, parameters: Parameter[]) => {
  const takePriceLevelFromTable = findParamValue('K32.is_stolchl', parameters);
  const priceLevelFromParams = findParamValue('K32.def_cenhla', parameters);

  const priceLevel = takePriceLevelFromTable
    ? tableDefinition.priceLevelId
    : priceLevelFromParams;

  return priceLevel || 1;
};
