// @flow
import type { Action, LoyalmanUserState } from '../../types';
import { isEmpty } from 'ramda';

const initialState = {
  url: null,
  token: null
};

const reducer = (state: LoyalmanUserState = initialState, action: Action) => {
  switch (action.type) {
    case 'SYNC_SERVER_DATA_COMMIT': {
      const { payload: { LoyalMan: loyalman = {} } } = action;
      return { ...state, url: loyalman.url, token: loyalman.token };
    }

    case 'BLITZ_SYNC_SERVER_DATA_COMMIT': {
      const { payload: { LoyalMan: loyalman = {} } } = action;

      return isEmpty(loyalman)
        ? state
        : { ...state, url: loyalman.url, token: loyalman.token };
    }

    default:
      return state;
  }
};

export default reducer;
