// @flow
import React from 'react';

let Sentry;

if (process.env.IS_REACT_NATIVE === '1') {
  Sentry = require('@sentry/react-native');
} else {
  Sentry = require('raven-js');
}

class ErrorCatcher extends React.PureComponent {
  componentDidCatch(error, errorInfo) {
    const { onError } = this.props;

    Sentry.captureException(error, { extra: errorInfo });

    if (typeof onError === 'function') {
      onError(error, errorInfo);
    }
  }

  render() {
    return this.props.children;
  }
}

export default ErrorCatcher;
