import hideVirtualKeyboard from 'hide-virtual-keyboard';
import MobileDetect from 'mobile-detect';

export default () => {
  const device = new MobileDetect(window.navigator.userAgent);

  if (device.mobile()) {
    document.addEventListener('focus', e => {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        hideVirtualKeyboard();
      }
    }, true);
  }

  if (process.env.NODE_ENV === 'production') {
    document.addEventListener('contextmenu', event => event.preventDefault());
  }
};
