// @flow
import type { Action, AdminGeneralState } from '../../types';
import { assocPath, isEmpty } from 'ramda';
import { concat, prepend } from 'rambda';

const initialState = {
  reloadAdminTable: 0,
  error: null,
  notification: false,
  loadingScreen: false
};

const reducer = (state: AdminGeneralState = initialState, action: Action) => {
  switch (action.type) {
    case 'ADMIN_INITIATE_ADMIN_TABLE_RELOAD': {
      const { reloadAdminTable } = state;
      // 0 -> 1 -> 0 -> 1 -> ...
      return assocPath(['reloadAdminTable'], reloadAdminTable ^ 1, state);
    }

    case 'ADMIN_ADD_ERROR': {
      const { error } = action.payload;
      const oldErrors = state.error || [];
      const allErrors = concat([error], oldErrors);

      return assocPath(['error'], isEmpty(allErrors) ? null : allErrors, state);
    }

    case 'ADMIN_CLEAR_ERROR': {
      return assocPath(['error'], null, state);
    }

    case 'ADMIN_TOGGLE_NOTIFICATION': {
      let { show } = action.payload;
      if (show) {
        const oldText = state.notification || [];
        show = prepend(show, oldText);
      }
      return assocPath(['notification'], show, state);
    }

    case 'ADMIN_TOGGLE_LOADING_SCREEN': {
      const { show } = action.payload;
      return assocPath(['loadingScreen'], show, state);
    }

    default:
      return state;
  }
};

export default reducer;
