// @flow
import React from 'react';
import { prgComponents } from './index';

export default class PrgDotaz extends React.PureComponent {
  render() {
    const {
      prgDotaz, prgDotazPayment, customPay, parameters
    } = this.props;
    let PrgDotaz;

    try {
      PrgDotaz = prgComponents(prgDotaz);
    } catch (e) {
      return null;
    }

    return <PrgDotaz prgDotaz={prgDotaz} prgDotazPayment={prgDotazPayment} customPay={customPay} parameters={parameters} />;
  }
}
