// @flow
import { map, compose, path, prop, reverse, sortBy, filter, pluck } from 'rambda';
import { groupBy, sum } from 'ramda';
import moment from 'moment';


/**
 * @memberof common/admin/receipts/utils
 * @param data
 */
export const countOfReceivedReceipts = (data: Object) =>
  path('result.Receipts.ZoznamSkUctov', data)
    ? (data.result.Receipts.ZoznamSkUctov).length
    : 0;

/**
 * @memberof common/admin/receipts/utils
 * @param data
 */
export const processReceivedReceipts = (data: Object) => {
  const receipts = path('result.Receipts.ZoznamSkUctov', data)
    ? reverse(sortBy(prop('uctDen'))(data.result.Receipts.ZoznamSkUctov))
    : [];

  return reverse(
    sortBy(
      prop('id'),
      map(
        receipt => ({
          id: receipt.ucet,
          apiId: receipt.id,
          table: receipt.stul,
          date: receipt.uDatetime ? moment(receipt.uDatetime, 'YYYYMMDD HH:mm:ss') : moment(),
          sum: +receipt.cenaPl,
          platby: receipt.platby,
          user: receipt.autor,
          closureId: receipt.cUzaverka,
          storno: receipt.storno,
          idLimit: receipt.idLimit,
          unfinished: receipt.nedokonceny,
          fuciarkod: (receipt.fuciarkod || '').trim()
        }),
        receipts
      )
    )
  );
};

/**
 * @memberof common/admin/receipts/utils
 * @param receipts,
 * @param filterValue
 */
export const filterAndGroupReceipts = (receipts: Object[], filterValue: string) => {
  // TODO - filter will be made by api
  const filteredReceipts = filter(
    item =>
      (`${item.id}`).toLowerCase().includes(filterValue.toLowerCase())
      || (`${item.date}`).toLowerCase().includes(filterValue.toLowerCase())
      || (`${item.table}`).toLowerCase().includes(filterValue.toLowerCase())
      || (`${item.sum}`).toLowerCase().includes(filterValue.toLowerCase())
      || item.user.toLowerCase().includes(filterValue.toLowerCase())
  )(receipts);

  // TODO - will be grouped by OurInfiniteTable
  const groupByDay = groupBy((orderLine) => (
    moment(orderLine.date).format('DD') ? moment(orderLine.date).format('DD') : 40
  ));

  return ({
    receipts: Object.values(
      compose(
        groupByDay,
        reverse,
        sortBy(prop('date'))
      )(filteredReceipts)
    ),
    countOfReceipts: filteredReceipts.length
  });
};

/**
 * @memberof common/admin/receipts/utils
 * @param data
 * @param receiptDate
 */
export const processReceivedReceiptItems = (data: Object, receiptDate: string) => {
  const receiptItems = path('result.ReceiptItems.SkUcet', data)
    ? reverse(sortBy(prop('poradi'))(data.result.ReceiptItems.SkUcet))
    : [];

  const processedReceiptItems = map(
    receiptItem => ({
      id: receiptItem.id,
      isSentToKitchen: true,
      itemId: receiptItem.id,
      paidQuantity: Number(receiptItem.mnozstvi),
      quantity: Number(receiptItem.mnozstvi),
      singlePrice: Number(receiptItem.prachy),
      receiptId: receiptItem.ucet,
      priceLevelId: Number((receiptItem.cenHlad).trim()),
      closureId: receiptItem.cUzaverka,
      menuName: (receiptItem.nazev).slice(0, -3).trim(),
      sortingId: receiptItem.poradi,
      foodCourse: 0,
      updatedAt: receiptDate,
      cancelQuantity: Number(receiptItem.storMno),
      idFmenu: receiptItem.idFmenu,
      pureIdFmenu: receiptItem.idFmenu.trim() ? (receiptItem.idFmenu).slice(0, (receiptItem.idFmenu).length - 1) : '',
      orderType: receiptItem.idFmenu.trim()
        ? (receiptItem.idFmenu).slice((receiptItem.idFmenu).length - 1, (receiptItem.idFmenu).length) === 'H'
          ? 10
          : 11
        : 0
    }),
    receiptItems
  );

  return filter(
    item => item.orderType !== 11,
    map(
      item => {
        if (item.orderType === 10) {
          const fastMenuSubItems = filter(el => el.idFmenu === `${item.pureIdFmenu}P`, processedReceiptItems);
          const fastMenuFullPrice = item.singlePrice + sum(pluck('singlePrice', fastMenuSubItems));
          return { ...item, fastMenuSubItems, fastMenuFullPrice };
        }
        return item;
      },
      processedReceiptItems
    )
  );
};

/**
 * @memberof common/admin/receipts/utils
 * @param date
 */
export const extractSelectedMonth = (date: Object) => ({
  month: moment(date).month(),
  year: moment(date).year(),
  name: moment(date).format('MMMM YYYY')
});
