// @flow
import React from 'react';
import { connect } from 'react-redux';
import { equals } from 'rambda';
import Box from '../../common/components/Box';
import Notification from '../components/Notification';
import { clearAdminError, toggleAdminNotificationPopup } from '../../common/admin/general/actions';
import type { State } from '../../common/types';

class AdminNotification extends React.PureComponent {
  constructor(props) {
    super(props);

    this.notificationTimeout = null;
  }

  componentWillMount() {
    const { dispatch, adminNotifications } = this.props;

    this._hideNotificationPopupAfterAWhile(dispatch, adminNotifications, 9000);
  }

  componentWillUpdate(nextprops) {
    const { dispatch, adminNotifications } = nextprops;

    if (!equals(this.props.adminNotifications, adminNotifications)) {
      this._hideNotificationPopupAfterAWhile(dispatch, adminNotifications);
    }
  }

  componentWillUnmount() {
    const { dispatch, clearError, hideNotificationOnUnmount = true } = this.props;
    const clearShownErrors = clearError || this._clearError;

    if (hideNotificationOnUnmount) {
      clearShownErrors();
      dispatch(toggleAdminNotificationPopup(false));
    }
  }

  _hideNotificationPopupAfterAWhile = (dispatch, adminNotifications, timePeriod = 7000) => {
    if (adminNotifications) {
      clearTimeout(this.notificationTimeout);
      this.notificationTimeout = setTimeout(
        () => dispatch(toggleAdminNotificationPopup(false)),
        timePeriod
      );
    }
  };

  _clearError = () => {
    const { dispatch } = this.props;

    dispatch(clearAdminError());
  };

  render() {
    const {
      target,
      adminErrors,
      adminNotifications,
      errors,
      clearError,
      dispatch,
      hideNotificationOnUnmount,
      ...restProps
    } = this.props;
    const errorsToShow = errors || adminErrors;

    return (
      <Box flexShrink={0} {...restProps}>
        {adminNotifications &&
          <Notification
            text={adminNotifications}
            mode="bottom"
            color="white"
            backgroundColor="darkGray"
          />}

        {errorsToShow &&
          <Notification
            text={errorsToShow}
            mode="bottom"
            color="white"
            backgroundColor="red"
            showClose
            onClose={clearError || this._clearError}
          />}
      </Box>
    );
  }
}


export default connect(
  (state: State) => ({
    adminNotifications: state.admin.general.notification,
    adminErrors: state.admin.general.error
  })
)(AdminNotification);
