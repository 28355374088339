// @flow

import type { Action, PeripheralsState } from '../types';
import { assocPath } from 'ramda';

const initialState = {
  actualWeight: 0,
  isWeightFormOpen: false,
  isWeightConnected: false,
  printers: []
};

const reducer = (state: PeripheralsState = initialState, action: Action): PeripheralsState => {
  switch (action.type) {
    case 'BLITZ_SYNC_SERVER_DATA_COMMIT':
    case 'SYNC_SERVER_DATA_COMMIT': {
      const {
        payload: {
          Printers: printers
        }
      } = action;

      return {
        ...state,
        printers
      };
    }

    case 'PERIPHERALS_WEIGHT_UPDATE': {
      const { actualWeight } = action.payload;
      return assocPath(['actualWeight'], actualWeight, state);
    }

    case 'PERIPHERALS_TOGGLE_WEIGHT_FORM': {
      const { item, selectAfter } = action.payload;
      const isWeightFormOpen = item
        ? { id: item.id, selectAfter }
        : false;
      return assocPath(['isWeightFormOpen'], isWeightFormOpen, state);
    }

    case 'PERIPHERALS_SET_WEIGHT_TO_CONNECTED': {
      const { is } = action.payload;
      return assocPath(['isWeightConnected'], is, state);
    }

    default:
      return state;
  }
};

export default reducer;
