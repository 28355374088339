// @flow
import React from 'react';
import { connect } from 'react-redux';
import { filter, pluck, reduce, last } from 'rambda';
import intersection from 'rambdax/modules/intersection';
import { isReactNative } from '../../app/detectPlatform';
import Box from '../Box';
import Button from '../Button';
import { toggleOrderLineSelect, togglePriceOrQuantityForm } from '../../order/actions';
import {
  commonDisplayQuantityForOrderLines,
  commonPriceLevelNameForOrderLines,
} from '../../order/utils';
import NavigationActions from 'react-navigation/src/NavigationActions';

const VelocityTransitionGroup = process.env.IS_REACT_NATIVE !== '1'
  ? require('velocity-react').VelocityTransitionGroup
  : null;


class OrderLineGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    const undeletedOrderLines = filter(orderLine => !orderLine._isDeleted, props.groupOrderLines || []);

    this.state = {
      opened: false,
      quantity: undeletedOrderLines.length || 1
    };
  }

  changeOpenedGroupState = () => {
    this.setState({ opened: !this.state.opened });
  };

  toggleOrderLineSelect = orderLines => {
    const { dispatch, selectedOrderLines } = this.props;
    const orderLinesIds = pluck('id', orderLines);

    if (this.isSelected(orderLines)) {
      const toggleOrderLines = intersection(orderLinesIds, selectedOrderLines);
      dispatch(toggleOrderLineSelect(toggleOrderLines, { selectFastFoodMenuSubItems: true }));
    } else {
      dispatch(toggleOrderLineSelect(orderLinesIds, { selectFastFoodMenuSubItems: true }));
    }
  };

  selectOrderLineSelectedCount = orderLine => {
    const { dispatch } = this.props;

    dispatch(isReactNative
      ? NavigationActions.navigate({
          key: 'PriceOrQuantityPage',
          routeName: 'PriceOrQuantityPage',
          params: {
            type: 'selectExactCount',
            uniqueOrderLine: orderLine
          }
        })
      : togglePriceOrQuantityForm({
          type: 'selectExactCount',
          uniqueOrderLine: orderLine
        }));
  };

  numberOfSelected = orderLines => {
    const { selectedOrderLines } = this.props;
    const selected = orderLines.filter(el => selectedOrderLines.indexOf(el.id) >= 0);
    return selected.length;
  };

  isSelected = orderLines => this.numberOfSelected(orderLines) > 0;

  render() {
    const {
      groupOrderLines,
      isRelocatingToFoodCourse,
      isAdmin,
      renderers,
    } = this.props;
    const actualRenderer = renderers[0];

    const nonGroupRowsRender = () => {
      if (this.state.opened && groupOrderLines.length > 1) {
        return (
          groupOrderLines.map((groupOrderLine) => (
            <Button
              key={groupOrderLine.id}
              onPress={() => this.toggleOrderLineSelect([groupOrderLine])}
            >
              {actualRenderer({
                data: {
                  selected: this.isSelected([groupOrderLine]),
                  isChild: true,
                  id: groupOrderLine.id,
                  confirmed: groupOrderLine.isSentToKitchen,
                  name: groupOrderLine.name,
                  price: groupOrderLine.actualPrice,
                  unitPrice: groupOrderLine.singlePrice,
                  unit: groupOrderLine.unit,
                  quantity: commonDisplayQuantityForOrderLines([groupOrderLine]),
                  count: 1,
                  updatedAt: groupOrderLine.updatedAt,
                  priceLevelName: groupOrderLine.priceLevelName,
                  orderText: groupOrderLine.orderText,
                  isAdmin,
                  isInPaymentTransaction: !!groupOrderLine.paymentId,
                  onCountPress: () => this.selectOrderLineSelectedCount(groupOrderLine)
                },
                renderers: renderers.slice(1)
              })}
            </Button>
          ))
        );
      }

      return null;
    };

    const price = reduce(
      (sum, { actualPrice }) => sum + actualPrice,
      0,
      groupOrderLines
    );

    const countOfUnconfirmedOrderLines = filter(gol => !gol.isSentToKitchen, groupOrderLines).length;

    return (
      <Box>
        <Button
          onPress={() => this.toggleOrderLineSelect(groupOrderLines)}
          onLongPress={() => groupOrderLines.length > 1
            ? this.changeOpenedGroupState()
            : this.toggleOrderLineSelect(groupOrderLines)}
        >
          {actualRenderer({
            data: {
              selected: this.isSelected(groupOrderLines),
              numberOfSelected: this.numberOfSelected(groupOrderLines),
              id: groupOrderLines[0].orderType === 10 ? groupOrderLines[0].id : last(groupOrderLines).id,
              confirmed: groupOrderLines[0].isSentToKitchen,
              name: groupOrderLines[0].name,
              price,
              quantity: commonDisplayQuantityForOrderLines(groupOrderLines),
              count: groupOrderLines.length,
              unconfirmedCount: countOfUnconfirmedOrderLines,
              unitPrice: groupOrderLines[0].singlePrice,
              unit: groupOrderLines[0].unit,
              updatedAt: groupOrderLines[0].updatedAt,
              priceLevelName: commonPriceLevelNameForOrderLines(groupOrderLines),
              isGroupOpened: this.state.opened && groupOrderLines.length > 1,
              orderText: groupOrderLines[0].orderText,
              isFastMenuHeader: groupOrderLines[0].orderType === 10,
              fastMenuSubItems: groupOrderLines[0].fastMenuSubItems,
              isAdmin,
              isInPaymentTransaction: !!groupOrderLines[0].paymentId,
              onCountPress: () => this.selectOrderLineSelectedCount(groupOrderLines[0])
            }
          })}
        </Button>

        {/*{isReactNative*/}
          {/*? nonGroupRowsRender()*/}
          {/*: <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">*/}
              {/*{nonGroupRowsRender()}*/}
            {/*</VelocityTransitionGroup>}*/}
        {nonGroupRowsRender()}

        {isRelocatingToFoodCourse
          ? <Box
            zIndex={3}
            height="100%"
            style={(theme) => ({
                position: 'absolute',
                width: '100%',
                top: 0,
                left: 0,
                right: 0,
                backgroundColor: theme.colors.inactiveItemsOverlay
              }
            )}
          />
          : null}
      </Box>
    );
  }
}

export default connect()(OrderLineGroup);
