// @flow
import InactiveBg from '../../../components/InactiveBg';
import Popup from '../../../components/Popup';
import React from 'react';
import Heading from '../../../../common/components/Heading';
import NumericButtons from '../../../../common/components/Keyboard/popupWeightForm/Buttons';
import createNumericOperations from '../../../../common/components/Keyboard/popupWeightForm/operations';
import TypewriterDisplay from '../../../../common/components/Keyboard/typewriter/OneLineDisplay';
import Keyboard from '../../../../common/components/Keyboard/index';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/fiscal';

class PopupVatEditor extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      percentageValue: props.defaultValue || 0
    }
  }

  onClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  onSubmit = () => {
    const { onSubmit } = this.props;

    onSubmit(this.state.percentageValue);
    this.onClose();
  };

  render() {
    const { defaultValue, intl } = this.props;

    return (
      <InactiveBg onClose={this.onClose}>
        <Popup onClose={this.onClose} width={15.25}>
          <Heading scale={2} color="white">{intl.formatMessage(messages.fiscalModuleEditDph)}</Heading>

          <Keyboard
            createOperations={createNumericOperations}
            Display={TypewriterDisplay}
            defaultValue={defaultValue}
            Buttons={NumericButtons}
            onLastKey={key => {
              if (key === 'ok') {
                this.onSubmit();
              }
            }}
            onDisplayValue={val => {
              this.setState({ percentageValue: val });
            }}
          />
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(PopupVatEditor);
