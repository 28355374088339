// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';

export const printKitchenMessage = (
  printerNumber: number,
  message: string,
): Promise =>
  fetch(apiEndpoint('v1/kitchen_message'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        prnNo: printerNumber,
        message
      },
      name: '',
      id: '',
      session_id: '',
      device_id: ''
    }
  });

export const kitchenReminder = (
  subTableIds: number[],
  courseId: string,
): Promise =>
  fetch(apiEndpoint('v1/kitchen_reminder'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        subtable_ids: subTableIds,
        course_id: courseId
      }
    }
  });
