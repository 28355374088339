// @flow
import React from 'react';
import Box from './Box';
import Button from './Button';
import Label from './Label';
import Text, { TextAdjustableSize } from './Text';
import Icon from './Icon';
import { formatPriceToFixed } from '../order/utils';
import theme from '../themes/theme';
import { isReactNative } from '../app/detectPlatform';
import { roundNumber } from '../lib/numbers';
import { getCurrencySymbol } from '../parameters/service';

type MenuItemCoreProps = {
  textColor: string,
  shadowColor: string,
  itemPureName: string,
  itemUnit: string,
  width: number | string,
  unitPrice?: number,
  itemCode?: string,
  availability?: number,
  icon?: string,
  backgroundColor?: number,
  onPress?: Function,
  onLongPress?: Function
};

const textFixedSizeStyle = {
  height: 2 * 0.9,
  scale: -1,
  overflow: 'hidden',
  justifyContent: 'flex-start',
  className: 'no-2'
};

const textAdjustableStyle = {
  ...textFixedSizeStyle,
  minTextSize: 10,
  maxTextSize: 14,
  lineHeight: 1.1,
  numberOfLines: 2,
  className: 'just-center'
};

export default class MenuItemCore extends React.PureComponent {
  props: MenuItemCoreProps;

  render() {
    const {
      textColor,
      shadowColor,
      itemPureName,
      itemUnit,
      width,
      icon,
      isWeighted,
      backgroundColor,
      description,
      unitPrice,
      itemCode,
      availability,
      isItem,
      onPress,
      onLongPress,
      adjustFontSize
    } = this.props;

    const TextComp = adjustFontSize === 'A' ? TextAdjustableSize : Text;

    let availabilityFormatted;
    if (isItem && availability !== undefined) {
      availabilityFormatted =
        `${availability === Infinity
          ? '∞'
          : isWeighted
            ? availability / 1000
            : roundNumber(availability)}` +
        `${availability !== Infinity && isWeighted ? 'kg' : ''}`;
    }

    return (
      <Button
        flexDirection="column"
        height={isReactNative ? `${theme.typography.lineHeight() * 3 + 3}px` /* for shadow */ : '100%'}
        width={isReactNative ? width : undefined}
        flex={isReactNative ? undefined : 1}
        maxWidth="100%"
        onPress={onPress}
        onLongPress={onLongPress}
      >
        <Box
          paddingHorizontal={isReactNative ? 0.5 : 0.25}
          flexDirection="row"
          alignItems="center"
          flexGrow={1}
          backgroundColor={backgroundColor}
        >
          {icon
            ? <Box marginRight={0.5}><Icon color="white" name={icon} scale={1} /></Box>
            : null}
          <Box overflow="hidden" width="100%">
            <TextComp
              bold
              color={textColor}
              // fontSize will be overriden if adjustFontSize === 'A'
              fontSize={isReactNative ? undefined : +adjustFontSize}
              {...(isReactNative
                ? { numberOfLines: 2 }
                : adjustFontSize === 'A'
                  ? textAdjustableStyle
                  : textFixedSizeStyle
              )}
            >
              {itemPureName}
            </TextComp>
            {(description || (unitPrice || unitPrice === 0) || itemCode)
              ? <Box
                flexDirection="row"
                justifyContent="flex-start"
              >
                {description &&
                <Text paddingRight={0.25} color={textColor} scale={-1}>{description}</Text>
                }
                {(unitPrice || unitPrice === 0) &&
                <Text paddingRight={0.25} bold color={textColor}
                      scale={-2}>{`${formatPriceToFixed(unitPrice)} ${getCurrencySymbol()}/${itemUnit}`}</Text>
                }
              </Box>
              : null}
          </Box>
          {isItem && availability !== undefined
            ? <Label
                position="absolute"
                bottom={0.25}
                right={0.25}
                rounded
                width="auto"
                size={1.25}
                minWidth={1.25}
                backgroundColor="white"
                borderRadius={theme.typography.lineHeight() * 1.25 * 0.5}
            >
              <Text
                align="center"
                condensed
                paddingHorizontal={0.25}
                style={{
                  letterSpacing: -1,
                  lineHeight: theme.typography.lineHeight(),
                }}
              >
                {availabilityFormatted}
              </Text>
            </Label>
            : null
          }
        </Box>
        <Box
          marginLeft="3px"
          marginRight="3px"
          height="3px"
          flexGrow={0}
          flexShrink={0}
          backgroundColor={shadowColor}
        />
      </Button>
    );
  }
}
