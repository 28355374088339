// @flow
import type { BoxProps } from './Box';
import type { Theme } from '../themes/types';
import Box from './Box';
import React from 'react';
import { isReactNative } from '../app/detectPlatform';
import PropTypes from 'prop-types';

// TODO: Implement auto jsx-a11y/img-has-alt.

type Size = {|
  height: number,
  width: number,
|};

type ImageProps = BoxProps & {
  size: Size,
  src: string | number, // number, because src={require('./foo.png')}
};

const Image = (
  {
    size,
    src,
    style = () => {},
    ...props
  }: ImageProps,
  {
    Image: PlatformImage,
  },
) => (
  <Box
    as={PlatformImage}
    {...{ [isReactNative ? 'source' : 'src']: src }}
    {...props}
    style={theme => ({ width: size.width, height: size.height, ...style(theme) })}
  />
);

Image.contextTypes = {
  Image: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
};

export default Image;
