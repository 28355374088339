// @flow
import type { TextProps } from './Text';
import React from 'react';
import color from 'color';
import Text, { computeTextStyle } from './Text';
import { isReactNative } from '../../common/app/detectPlatform';
import PropTypes from 'prop-types';
import theme from '../themes/theme';

// Universal text input component. By default, it looks like editable text.
// For underline or the other effects, make a new component. Check Field.

export type TextInputProps = TextProps & {
  disabled?: boolean,
  placeholderTextColor?: string,
};

const computePlaceholderColor = textColor =>
  color(textColor).fade(0.5).toString();

class TextInput extends React.PureComponent {
  props: TextInputProps;

  render() {
    const {
      TextInput: PlatformTextInput,
      TextInputMultiLine: PlatformTextInputMultiLine
    } = this.context;

    const [textStyle] = computeTextStyle(theme, this.props);

    const {
      disabled = false,
      padding = 0,
      placeholderTextColor = computePlaceholderColor(textStyle.color),
      style,
      multiLine,
      ...restProps
    } = this.props;

    const platformProps = isReactNative
      ? {
        editable: !disabled,
        underlineColorAndroid: 'transparent',
        placeholderTextColor,
      }
      : {
        disabled,
      };
    const platformStyle = isReactNative
      ? {}
      : {
        outline: 'none',
        '::placeholder': {
          color: placeholderTextColor,
        },
      };

    return (
      <Text
        as={multiLine ? PlatformTextInputMultiLine : PlatformTextInput}
        // React Native TextInput needs explicit height.
        // Browsers need explicit height for correct vertical align.
        padding={padding}
        {...disabled ? { opacity: theme.states.disabled.opacity } : null}
        {...platformProps}
        {...restProps}
        style={(theme, textStyle) => ({
          ...platformStyle,
          ...(typeof style === 'function' ? style(theme, textStyle) : style),
        })}
      />
    );
  }
}

TextInput.contextTypes = {
  TextInput: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  TextInputMultiLine: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired
};

export default TextInput;
