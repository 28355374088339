// @flow
import type { Id } from '../types';

export const activateArea = (id: Id) => ({
  type: 'SERVICE_AREAS_ACTIVATE',
  payload: { id },
});

export const activateIsLimit = (is: Id) => ({
  type: 'SERVICE_AREAS_SET_IS_LIMIT',
  payload: { is },
});

export const toggleTableList = (toggle: boolean) => ({
  type: 'SERVICE_AREAS_TOGGLE_TABLE_LIST',
  payload: toggle
});

export const saveScaleFactor = (scale: number) => ({
  type: 'SERVICE_AREAS_SAVE_SCALE_FACTOR',
  payload: {
    scale
  }
});

export const toggleOneTimeTableNamePopupOpen = (toggle) => ({
  type: 'SERVICE_TOGGLE_ONETIME_TABLE_NAME_POPUP',
  payload: { toggle }
});

export const selectServicePrinter = (printerId: string) => ({
  type: 'SERVICE_SELECT_PRINTER',
  payload: { printerId }
});
