// @flow
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import { ApiError } from './lib/fetch/errors';

const configureOffline = () => ({
  ...offlineConfig,
  retry: (action, retries: number) => {
    if (retries < 2) {
      return 1000; // 1 second
    } else if (retries < 8640) { // 12 hours
      return 5000; // every 5 seconds
    }

    return null;
  },
  effect: (callback: any): Promise => callback(),
  persist: false, // persist is already created. It will use default options
  discard: error => {
    if (error instanceof ApiError) {
      return error.status >= 400;
    }

    // some unknown error
    return true;
  }
});

export default configureOffline;
