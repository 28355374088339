// @flow
import React from 'react';
import Box from '../../../../common/components/Box';
import Button from '../../../../common/components/Button';
import Text from '../../../../common/components/Text';
import Keyboard from '../../../../common/components/Keyboard';
import NewClosureButtons from '../../../../common/components/Keyboard/newClosure/Buttons';
import newClosureOperations from '../../../../common/components/Keyboard/newClosure/operations';
import { connect } from 'react-redux';
import theme from '../../../themes/defaultTheme';
import api from '../../../../common/lib/api';
import Spinner from '../../../../common/components/Spinner';
import Closure from '../../../components/Closure';
import { BottomDecoration } from '../../../../common/components/PaymentSheet';
import { monospaceFamily } from '../../../../common/themes/typography';
import InactiveBg from '../../../components/InactiveBg';
import Popup from '../../../components/Popup';
import PrintersList from '../../../../common/components/PrintersList';
import { AutoSizer, Column, defaultTableCellRenderer } from 'react-virtualized';
import SimpleItemsTable, { withLoadingState } from '../../Table';
import TextInput from '../../../../common/components/TextInput';
import {
  addAdminError,
  toggleAdminNotificationPopup
} from '../../../../common/admin/general/actions';
import { replace } from 'connected-react-router';
import type { State } from '../../../../common/types';
import { findParamValue } from '../../../../common/parameters/utils';
import { compose, update } from 'rambda';
import { formatDirectPriceToFixed } from '../../../../common/order/utils';
import { replaceSKKeyCharsWithNums } from '../../../../common/lib/utils';
import { deposit, withdrawal } from '../../../../common/lib/api/payment';
import Blocker from '../../../components/Blocker';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/closures';
import { getCurrencySymbol } from '../../../../common/parameters/service';

const Table = compose(
  withLoadingState
)(SimpleItemsTable);

class Confirmation extends React.PureComponent {
  state = {
    error: null,
    loading: false,
    printing: false,
    done: false,
    preview: '',
    closureId: null,
    selectPrinter: false,
    loadingCashiersStatus: false,
    loadingCashiersFailed: false,
    cashiersStatus: [],
    containsNegativeStatus: false,
    focusedWithdrawalInput: -1,
    withdrawalOverflowingValues: [],
    blockerForDeposit: -1
  };

  // NOTE: removed 'error: null' in setState calls,
  //  because we dont want to del error on data reload after withdrawal fail
  fetchCashiersStatus = () => {
    this.setState({
      loadingCashiersStatus: true,
      containsNegativeStatus: false,
      loadingCashiersFailed: false
    });

    api.cashiers.getStatus()
      .then(({ body: { result: { cashiers } } }) => {
        let containsNegativeStatus = false;

        cashiers.forEach((cashier, index) => {
          cashiers[index].sum = (cashier.type_0 + cashier.type_1 + cashier.type_2).toFixed(2);
          containsNegativeStatus = cashiers[index].sum < 0 ? true : containsNegativeStatus;
          cashiers[index].sum_withdrawal = formatDirectPriceToFixed(cashiers[index].sum).toString();
          cashiers[index].firstEdit = true;
        });

        this.setState({
          loadingCashiersStatus: false,
          loadingCashiersFailed: false,
          cashiersStatus: cashiers,
          containsNegativeStatus
        });
      }).catch(e => {
        this.setState({
          error: e.toString(),
          loadingCashiersStatus: false,
          loadingCashiersFailed: true
        });
      });
  };

  componentDidMount() {
    const { doWithdrawal } = this.props;

    if (doWithdrawal) {
      this.fetchCashiersStatus();
    }
  }

  handleWithdrawalInputChange = (i, value) => {
    value = value.toString().replace('.', ',');
    value = replaceSKKeyCharsWithNums(value);

    const { cashiersStatus, withdrawalOverflowingValues } = this.state;
    const statusItem = cashiersStatus[i];

    statusItem.sum_withdrawal = value;

    if (withdrawalOverflowingValues.length) {
      const index = withdrawalOverflowingValues.indexOf(i);
      if (index !== -1) withdrawalOverflowingValues.splice(index, 1);
    }

    statusItem.firstEdit = false;

    this.setState({
      focusedWithdrawalInput: i,
      cashiersStatus: update(i, statusItem, cashiersStatus),
      withdrawalOverflowingValues
    });
  };

  handleWithdrawalKeyboardPress = (key) => {
    const { focusedWithdrawalInput, cashiersStatus } = this.state;

    if (focusedWithdrawalInput < 0 || focusedWithdrawalInput >= cashiersStatus.length) {
      return;
    }

    const { firstEdit, sum_withdrawal, sum } = cashiersStatus[focusedWithdrawalInput];

    if (sum < 0) {
      return;
    }

    const oldVal = firstEdit ? '' : sum_withdrawal;
    let newVal = '';

    if (key === 'del') {
      newVal = oldVal.slice(0, -1);
    } else {
      newVal = `${oldVal}${key}`;
    }

    this.handleWithdrawalInputChange(focusedWithdrawalInput, newVal);
  };

  setFocusedWithdrawalInput = (i) => {
    this.setState({ focusedWithdrawalInput: i });
  };

  parseWithdrawalNumberValue = (val) => Number(val.toString().replace(',', '.'));

  validateWithdrawalValues = () => {
    const { cashiersStatus } = this.state;
    const { intl } = this.props;

    const withdrawalOverflowingValues = [];

    cashiersStatus.forEach((statusItem, i) => {
      const parsed = this.parseWithdrawalNumberValue(statusItem.sum_withdrawal);
      if (parsed > statusItem.sum || parsed < 0) {
        withdrawalOverflowingValues.push(i);
      }
    });

    if (withdrawalOverflowingValues.length) {
      this.setState({
        error: intl.formatMessage(messages.closureNewOverflowError),
        loading: false,
        withdrawalOverflowingValues
      });
      return false;
    }

    return true;
  };

  confirm = () => {
    this.setState({ loading: true, error: null });
    const { authDefaultPrinter } = this.props;

    if (this.validateWithdrawalValues()) {
      const { cashiersStatus } = this.state;
      const { intl } = this.props;

      const withdrawalPromisesArray = [];

      cashiersStatus.forEach(statusItem => {
        const withdrawalValue = this.parseWithdrawalNumberValue(statusItem.sum_withdrawal);
        if (withdrawalValue > 0) {
          withdrawalPromisesArray.push(
            withdrawal(statusItem.payment_media_id, Math.abs(+withdrawalValue * 100), 'U', statusItem.prn_no || authDefaultPrinter)
          );
        }
      });

      Promise.all(withdrawalPromisesArray).then(values => {
        api.closure.getClosures('new', { isSummary: true })
          .then(({ body: { result: { preview: { closure: closureId, data: preview } } } }) => {
            this.setState({
              loading: false, done: true, preview, closureId
            });
          }).catch(e => {
            this.setState({ error: e.toString(), loading: false });
          });
      }).catch(e => {
        this.setState({ error: `${intl.formatMessage(messages.closureNewWithdrawalError)} ${e.toString()}`, loading: false });
        this.fetchCashiersStatus();
      });
    }
  };

  print = printer => {
    const { closureId } = this.state;
    const { dispatch, intl } = this.props;

    this.setState({ printing: true });

    return api.closure.getClosures('new', { printer, print: true, closureId })
      .then(() => {
        dispatch(toggleAdminNotificationPopup(intl.formatMessage(messages.closureSendToPrint)));
      }).catch(e => {
        dispatch(addAdminError(e));
      }).then(() => {
        this.setState({ printing: false, selectPrinter: false });
      });
  };

  selectPrinter = () => {
    this.setState({ selectPrinter: true });
  };

  closePrinterPopup = () => {
    this.setState({ selectPrinter: false });
  };

  cancel = () => {
    const { dispatch } = this.props;
    dispatch(replace('/serviceAreas'));
  };

  _hideBlocker = () => {
    this.setState({ blockerForDeposit: -1 });
  };

  render() {
    const {
      error,
      loading,
      done,
      preview,
      selectPrinter,
      printing,
      loadingCashiersStatus,
      loadingCashiersFailed,
      cashiersStatus,
      containsNegativeStatus,
      focusedWithdrawalInput,
      withdrawalOverflowingValues,
      blockerForDeposit
    } = this.state;
    const { doWithdrawal, printers, authDefaultPrinter, intl } = this.props;

    if (preview) {
      return (
        <Box flex={1} marginHorizontal="auto" marginVertical={1} height="calc(100% - 36px)">
          <Closure
            isLoading={false}
            data={preview}
            backgroundColor="white"
            paddingVertical={2}
            height="100%"
            overflow="auto"
          />

          <Button
            position="absolute"
            top={-1}
            left={`calc(50% + ${theme.typography.lineHeight(0, monospaceFamily) * 12}px)`}
            backgroundColor="teal"
            outline
            color="white"
            marginTop={1}
            marginLeft={1}
            paddingVertical={0.75}
            paddingHorizontal={2}
            onPress={() => this.print()}
            style={{ whiteSpace: 'nowrap' }}
          >
            {intl.formatMessage(messages.closurePrint)}
          </Button>

          <Button
            position="absolute"
            top={2.5}
            left={`calc(50% + ${theme.typography.lineHeight(0, monospaceFamily) * 12}px)`}
            backgroundColor="teal"
            outline
            color="white"
            marginTop={1}
            marginLeft={1}
            paddingVertical={0.75}
            paddingHorizontal={2}
            onPress={this.selectPrinter}
            style={{ whiteSpace: 'nowrap' }}
          >
            {intl.formatMessage(messages.closureChoosePrinter)}
          </Button>

          {BottomDecoration}

          {selectPrinter
          && (
          <InactiveBg onClose={this.closePrinterPopup}>
            <Popup onClose={this.closePrinterPopup}>
              <Box maxHeight={25} width={40}>
                {printing ? (
                  <React.Fragment>
                    <Text marginBottom={2} scale={3} align="center" color="white">
                      {intl.formatMessage(messages.closurePrintInProgress)}
                    </Text>
                    <Box paddingTop={3}>
                      <Spinner color="white" />
                    </Box>
                  </React.Fragment>
                ) : (
                  <PrintersList onSelect={this.print} />
                )}
              </Box>
            </Popup>
          </InactiveBg>
)
          }
        </Box>
      );
    }

    let inner;
    if (loading) {
      inner = (
        <React.Fragment>
          <Text marginBottom={2} scale={3} align="center">
            {intl.formatMessage(messages.closureCreatingNew)}
          </Text>
          <Box paddingTop={3}>
            <Spinner />
          </Box>
        </React.Fragment>
      );
    } else if (done) {
      inner = (
        <Text marginBottom={2} scale={3} align="center">{intl.formatMessage(messages.closureCreatedNew)}</Text>
      );
    } else {
      inner = (
        <React.Fragment>
          <Text marginBottom={1} scale={3} align="center">{intl.formatMessage(messages.closureConfirmNew)}</Text>

          {blockerForDeposit > -1 ? (
            <Blocker
              actionConfirm={() => {
                const statusItem = cashiersStatus[blockerForDeposit];
                deposit(statusItem.payment_media_id, Math.abs(+statusItem.sum * 100), undefined, statusItem.prn_no || authDefaultPrinter).then(() => {
                  this.fetchCashiersStatus();
                }).catch(e => {
                  this.setState({
                    error: `${intl.formatMessage(messages.closureDepositBlockError)} ${e.toString()}`
                  });
                  this.fetchCashiersStatus();
                });

                // So while waiting for withdrawal to finish,
                // user will see loading spinner on cashiers status table
                this.setState({ blockerForDeposit: -1, loadingCashiersStatus: true });
              }}
              actionCancel={() => this._hideBlocker()}
              textHeader={intl.formatMessage(messages.closureDepositHeader)}
              textDescription={intl.formatMessage(messages.closureDepositDescription, {
                name: cashiersStatus[blockerForDeposit].payment_media_name,
                sum: formatDirectPriceToFixed(-cashiersStatus[blockerForDeposit].sum).toString(),
                currency: getCurrencySymbol()
              })}
              textConfirm={intl.formatMessage(messages.closureDepositConfirm)}
              textCancel={intl.formatMessage(messages.closureDepositCancel)}
            />
)
            : null}

          {/* maxHeight="100vh" height="9999px" */}
          {doWithdrawal && (
            <Box flexBasis="100%" backgroundColor="white" marginBottom={1} marginRight={1} maxHeight="calc(100vh - 300px)" minHeight="250px" flexGrow="2" overflow="hidden">
              <AutoSizer style={{ flexGrow: 2 }}>
                {({ height, width }) => (
                  <Table
                    width={width}
                    height={height}
                    headerClassName="offset-first"
                    className="light"
                    items={cashiersStatus}
                    isLoading={loadingCashiersStatus}
                    onRowClick={({ index }) => this.setFocusedWithdrawalInput(index)}
                  >
                    <Column
                      label={intl.formatMessage(messages.closureDepositTableMediaName)}
                      dataKey="payment_media_name"
                      width={width * 0.15}
                      cellRenderer={props =>
                        <Text color="inherit">{defaultTableCellRenderer(props)}</Text>
                      }
                    />
                    <Column
                      label={intl.formatMessage(messages.closureDepositTableMediaPrn)}
                      dataKey="prn_no"
                      width={width * 0.15}
                      cellDataGetter={({ rowData: { prn_no } }) => {
                        const printer = printers && printers.find(printer => printer.prnNo === prn_no);
                        return printer ? printer.prnName : prn_no;
                      }}
                      cellRenderer={props =>
                        <Text color="inherit">{defaultTableCellRenderer(props)}</Text>
                      }
                    />
                    <Column
                      label={intl.formatMessage(messages.closureDepositTableSale)}
                      dataKey="type_0"
                      width={width * 0.10}
                      cellDataGetter={({ rowData: { type_0 } }) => `${formatDirectPriceToFixed(type_0)} ${getCurrencySymbol()}`}
                      cellRenderer={props =>
                        <Text color="inherit">{defaultTableCellRenderer(props)}</Text>
                      }
                    />
                    <Column
                      label={intl.formatMessage(messages.closureDepositTablePayment)}
                      dataKey="type_1"
                      width={width * 0.15}
                      cellDataGetter={({ rowData: { type_1 } }) => `${formatDirectPriceToFixed(type_1)} ${getCurrencySymbol()}`}
                      cellRenderer={props =>
                        <Text color="inherit">{defaultTableCellRenderer(props)}</Text>
                      }
                    />
                    <Column
                      label={intl.formatMessage(messages.closureDepositTableSummary)}
                      dataKey="type_2"
                      width={width * 0.15}
                      cellDataGetter={({ rowData: { type_2 } }) => `${formatDirectPriceToFixed(type_2)} ${getCurrencySymbol()}`}
                      cellRenderer={props =>
                        <Text color="inherit">{defaultTableCellRenderer(props)}</Text>
                      }
                    />
                    <Column
                      label={intl.formatMessage(messages.closureDepositTableOverall)}
                      dataKey="sum"
                      width={width * 0.15}
                      cellDataGetter={({ rowData: { sum } }) => `${formatDirectPriceToFixed(sum)} ${getCurrencySymbol()}`}
                      cellRenderer={props => <Text color="inherit">{defaultTableCellRenderer(props)}</Text>
                      }
                    />
                    <Column
                      label={intl.formatMessage(messages.closureDepositTableWithdrawal)}
                      dataKey="sum_withdrawal"
                      width={width * 0.15}
                      cellRenderer={props => {
                        if (props.rowData.sum < 0) {
                          return (
                            <Button
                              backgroundColor="teal"
                              paddingVertical={0.20}
                              width={4}
                              outline
                              color="buttonTealShadow"
                              onPress={() => this.setState({ blockerForDeposit: props.rowIndex })}
                            >
                              <Text color="white">{intl.formatMessage(messages.closureDepositTableDeposit)}</Text>
                            </Button>
                          );
                        }

                        return (
                          <TextInput
                            backgroundColor="adminPanelBg"
                            borderStyle="solid"
                            borderColor={props.rowIndex === focusedWithdrawalInput ? 'buttonTealShadow' : 'lessDarkGray'}
                            borderWidth={props.rowIndex === focusedWithdrawalInput ? 2 : 1}
                            color={withdrawalOverflowingValues.length && withdrawalOverflowingValues.indexOf(props.rowIndex) !== -1 ? 'red' : 'black'}
                            padding={0.25}
                            width={4}
                            value={defaultTableCellRenderer(props)}
                            onFocus={() => this.setFocusedWithdrawalInput(props.rowIndex)}
                            onChange={(event) =>
                              this.handleWithdrawalInputChange(props.rowIndex, event.target.value)}
                            disabled={false}
                          />
                        );
                      }}
                    />
                  </Table>
                )}
              </AutoSizer>
            </Box>
          )}

          <Box flexDirection="row" justifyContent="center" marginTop={1}>
            <Button
              outline
              color="buttonGray"
              paddingVertical={0.75}
              paddingHorizontal={2}
              marginRight={1.5}
              onPress={this.cancel}
            >
              <Text color="buttonGray" bold lineHeight={theme.typography.fontSize()}>
                {intl.formatMessage(messages.closureNewNo)}
              </Text>
            </Button>
            <Button
              backgroundColor="teal"
              outline
              color="buttonTealShadow"
              paddingVertical={0.75}
              paddingHorizontal={2}
              onPress={this.confirm}
              disabled={!!(loadingCashiersStatus || loadingCashiersFailed || containsNegativeStatus)}
            >
              <Text color="white" bold lineHeight={theme.typography.fontSize()}>
                {intl.formatMessage(messages.closureNewYes)}
              </Text>
            </Button>
          </Box>
        </React.Fragment>
      );
    }

    return (
      <Box
        borderRadius="normal"
        boxShadow="default"
        alignSelf="center"
        justifyContent="center"
        flexDirection="row"
        marginTop={4}
        padding={0}
        width={doWithdrawal ? 'calc(100% - 60px)' : 'auto'}
      >
        <Box
          backgroundColor="adminPanelBg"
          borderRadius="normal"
          width={doWithdrawal ? '100%' : 30}
          justifyContent="center"
          paddingHorizontal={3}
          paddingVertical={2}
        >
          {inner}

          {containsNegativeStatus
            && <Text color="error" marginTop={1} marginBottom={-1}>{intl.formatMessage(messages.closureNewValidationNegative)}</Text>
          }
          {error
            && <Text color="error" marginTop={1} marginBottom={-1}>{error}</Text>
          }
        </Box>

        {doWithdrawal && (
          <Box
            backgroundColor="lessDarkGray"
            borderRadius="normal"
            paddingHorizontal={2}
            paddingVertical={2}
            justifyContent="center"
            alignItems="center"
          >
            <Keyboard
              style={{
                borderWidth: '0px 1px 1px 1px',
                borderStyle: 'solid',
                borderColor: 'black'
              }}
              value={0}
              createOperations={newClosureOperations}
              Buttons={NewClosureButtons}
              onLastKey={(key) => this.handleWithdrawalKeyboardPress(key)}
              disabled={loading}
              flexShrink={0}
            />
          </Box>
        )}
      </Box>
    );
  }
}

export default compose(
  connect(
    (state: State) => ({
      parameters: state.parameters.parameters,
      authDefaultPrinter: state.auth.defaultPrinter,
      printers: state.peripherals.printers
    }),
    null,
    ({
       parameters,
       authDefaultPrinter,
       printers
     }, { dispatch }, ownProps) => {
      const doWithdrawal = findParamValue('K32.uzav_odvod', parameters) === '2';
      return {
        ...ownProps,
        dispatch,
        doWithdrawal,
        authDefaultPrinter,
        printers
      };
    }
  ),
  injectIntl
)(Confirmation);
