// @flow
import React from 'react';
import { connect } from 'react-redux';
import ComposedButton from '../../../../common/components/ComposedButton';
import Box from '../../../../common/components/Box';
import { BottomDecoration } from '../../../../common/components/PaymentSheet';
import Closure from '../../../components/Closure';
import api from '../../../../common/lib/api';
import {
  addAdminError,
  toggleAdminNotificationPopup
} from '../../../../common/admin/general/actions';
import Popup from '../../../components/Popup';
import InactiveBg from '../../../components/InactiveBg';
import PrintersList from '../../../../common/components/PrintersList';
import { setHistoryPreviewData, toggleHistoryIsLoading } from '../../../../common/admin/closures/actions';
import { compose } from 'rambda';
import type { State } from '../../../../common/types';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/closures';

class Sidebar extends React.PureComponent {
  state = {
    selectPrinter: false,
  };

  selectPrinter = () => {
    this.setState({ selectPrinter: true });
  };

  closePrinterPopup = () => {
    this.setState({ selectPrinter: false });
  };

  print = printer => {
    this.closePrinterPopup();
    const { dispatch, closureId, intl } = this.props;

    return api.closure.printClosure(closureId, printer)
      .then(() => {
        dispatch(toggleAdminNotificationPopup(intl.formatMessage(messages.closureToPrint)));
      }).catch(e => {
        dispatch(addAdminError(e));
      });
  };

  rebuildClosure = () => {
    const { dispatch, closureId } = this.props;

    dispatch(toggleHistoryIsLoading(true));

    api.closure.rebuildClosure(closureId).then(({ body: { result: { closure: { data } } } }) => {
      dispatch([
        setHistoryPreviewData(data),
        toggleHistoryIsLoading(false)
      ]);
    }).catch(e => {
      dispatch([
        toggleHistoryIsLoading(false),
        addAdminError(e),
      ]);
    });
  };

  copyOfFiscalClosure = () => {
    const { dispatch, closureId, intl } = this.props;

    return api.closure.printCopyOfFiscalClosure(closureId)
      .then(() => {
        dispatch(toggleAdminNotificationPopup(intl.formatMessage(messages.closureCopyToPrint)));
      }).catch(e => {
        dispatch(addAdminError(e));
      });
  };

  render() {
    const { closureData, closureId, isLoading, intl } = this.props;
    const { selectPrinter } = this.state;

    return (
      <Box backgroundColor="appBg" height="100%" flexDirection="column" justifyContent="space-between" padding={1} paddingTop={0} flexBasis={22.2} flexShrink={0}>
        <Box flex={1} marginHorizontal="auto" marginVertical={1}>
          <Closure
            data={closureData}
            backgroundColor="white"
            paddingVertical={2}
            overflow="auto"
            height={0}
            flex={1}
            isLoading={isLoading}
            isSelected={!!closureId}
          />
          {BottomDecoration}
        </Box>

        <Box flexDirection="row" flexShrink={0}>
          <ComposedButton
            caption={intl.formatMessage(messages.choosePrinter)}
            backgroundColor="blue"
            shadowColor="darkBlue"
            icon="printer"
            flexShrink={0}
            marginHorizontal={0.25}
            flexGrow={1}
            flexBasis={1}
            disabled={!closureId}
            onClick={this.selectPrinter}
          />

          <ComposedButton
            caption={intl.formatMessage(messages.print)}
            backgroundColor="blue"
            shadowColor="darkBlue"
            icon="printer"
            flexShrink={0}
            flexGrow={1}
            flexBasis={1}
            disabled={!closureId}
            marginHorizontal={0.25}
            onClick={() => this.print()}
          />
        </Box>

        <Box flexDirection="row" flexShrink={0}>
          <ComposedButton
            caption={intl.formatMessage(messages.regenerateClosure)}
            backgroundColor="blue"
            shadowColor="darkBlue"
            icon="list"
            flexShrink={0}
            marginHorizontal={0.25}
            flexGrow={1}
            flexBasis={1}
            disabled={!closureId}
            onClick={this.rebuildClosure}
          />
          <ComposedButton
            caption={intl.formatMessage(messages.copyClosure)}
            backgroundColor="blue"
            shadowColor="darkBlue"
            icon="split"
            flexShrink={0}
            marginHorizontal={0.25}
            flexGrow={1}
            flexBasis={1}
            disabled={!closureId}
            onClick={this.copyOfFiscalClosure}
          />
        </Box>

        {selectPrinter
          && (
          <InactiveBg onClose={this.closePrinterPopup}>
            <Popup onClose={this.closePrinterPopup}>
              <Box maxHeight={25} width={40}>
                <PrintersList onSelect={this.print} />
              </Box>
            </Popup>
          </InactiveBg>
          )
        }
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    closureData: state.admin.closures.history.closureData,
    isLoading: state.admin.closures.history.isLoading,
    closureId: state.admin.closures.history.selectedClosureId,
  })),
  injectIntl
)(Sidebar);
