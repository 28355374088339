// @flow
import React from 'react';
import { connect } from 'react-redux';
import { checkForUpdates } from '../lib/updates';
import { version } from '../../../package.json';
import { foundNewVersion } from './actions';
import type { State } from '../../common/types';
import Button from '../../common/components/Button';
import Text from '../../common/components/Text';
import Box from '../../common/components/Box';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/autoUpdates';

const intervalDuration = 1000 * 60; // every minute

class AutoUpdates extends React.PureComponent {
  componentDidMount() {
    this.checkUpdates();

    this.interval = setInterval(this.checkUpdates, intervalDuration);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  checkUpdates = () => {
    checkForUpdates(version).then(({ updateExists, latestVersion }) => {
      const { dispatch } = this.props;
      dispatch(foundNewVersion(updateExists ? latestVersion : null));
    }).catch((e) => {
      if (process.env.NODE_ENV === 'development') {
        console.error(e); // eslint-disable-line no-console
      }
    });
  };

  reloadApp = () => {
    window.location.href = `${window.location.origin}${window.location.pathname}`;
  };

  render() {
    const { newVersion, intl } = this.props;

    if (!newVersion) return null;

    return (
      <Box flexDirection="row" height="100%" alignItems="center">
        <Text color="white" paddingRight={0.25}>{intl.formatMessage(messages.autoUpdatesUpdateFound)}</Text>
        <Button color="white" bold onPress={this.reloadApp} height="100%" alignItems="center">{intl.formatMessage(messages.autoUpdatesUpdateNow)}</Button>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    newVersion: state.autoUpdates.newVersion
  })),
  injectIntl
)(AutoUpdates);
