// @flow
import InactiveBg from '../components/InactiveBg';
import Popup from '../components/Popup';
import React from 'react';
import Heading from '../../common/components/Heading';
import TypewriterDisplay from '../../common/components/Keyboard/typewriter/Display';
import createTypewriterOperations from '../../common/components/Keyboard/typewriter/operations';
import TypewriterButtons from '../../common/components/Keyboard/typewriter/Buttons';
import Keyboard from '../../common/components/Keyboard/index';

export default class extends React.PureComponent {
  render() {
    const { onClose, onSubmit, value, title } = this.props;

    return (
      <InactiveBg zIndex={3000} onClose={onClose}>
        <Popup onClose={onClose}>
          <Heading scale={2} color="white" minWidth={28}>{title}</Heading>

          <Keyboard
            createOperations={createTypewriterOperations}
            Buttons={TypewriterButtons}
            Display={TypewriterDisplay}
            onLastKey={(key, val) => {
              if (key === 'send') {
                onSubmit(val);
              }
            }}
            defaultValue={value}
          />
        </Popup>
      </InactiveBg>
    );
  }
}
