// @flow
import React from 'react';
import Box from '../../../common/components/Box';
import ComposedButton from '../../../common/components/ComposedButton';
import ReceiptItemsSheet from './ReceiptItemsSheet';
import { connect } from 'react-redux';
import type { State } from '../../../common/types';
import {compose, map, pathOr} from 'rambda';
import { isEmpty } from 'ramda';
import { redirectNavigation } from '../../../common/lib/utils';
import { activateArea } from '../../../common/serviceAreas/actions';
import api from '../../../common/lib/api';
import {
  toggleCancelingReceiptAndReturningToTable,
  toggleReceiptTipPopup,
  toggleUnfinishedBillPopup
} from '../../../common/admin/receipts/actions';
import {
  initiateAdminTableReload,
  toggleAdminNotificationPopup,
  addAdminError,
  toggleAdminLoadingScreen
} from '../../../common/admin/general/actions';
import Permission from '../../../common/permissions/Permission';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/receipts';


class Sidebar extends React.Component {
  state = {
    scrollContainerId: 'OrderLineSheetScrollContainer'
  };

  changePayments = () => {
    const { dispatch } = this.props;

    dispatch(redirectNavigation('/payment'));
  };

  openTipPopup = () => {
    const { dispatch } = this.props;

    dispatch(toggleReceiptTipPopup(true));
  };

  cancelReceipt = () => {
    const { dispatch, activeReceipt, intl } = this.props;

    dispatch(toggleAdminLoadingScreen(true));
    api.receipt.cancelReceipt(activeReceipt.id, activeReceipt.closureId)
      .then(() => {
        dispatch([
          toggleAdminNotificationPopup(intl.formatMessage(messages.receiptsStorno)),
          initiateAdminTableReload(),
          toggleAdminLoadingScreen(false)
        ]);
      })
      .catch(e => {
        dispatch([
          addAdminError(e),
          toggleAdminLoadingScreen(false)
        ]);
      });
  };

  cancelReceiptItems = () => {
    const { dispatch, activeReceipt, selectedReceiptItems, intl } = this.props;

    const apiReceiptItems = Object.values(
      map((value, key) => ({ id: +key, quantity: value.length }), selectedReceiptItems)
    );

    dispatch(toggleAdminLoadingScreen(true));
    api.receipt.cancelReceiptItem(activeReceipt.id, activeReceipt.closureId, apiReceiptItems)
      .then(() => {
        dispatch([
          toggleAdminNotificationPopup(intl.formatMessage(messages.receiptsItemStorno)),
          initiateAdminTableReload(),
          toggleAdminLoadingScreen(false)
        ]);
      })
      .catch(e => {
        dispatch([
          addAdminError(e),
          toggleAdminLoadingScreen(false)
        ]);
      });
  };

  cancelReceiptAndReturnToTable = () => {
    const { dispatch, activeReceipt } = this.props;

    dispatch([
        toggleCancelingReceiptAndReturningToTable({
          receiptId: activeReceipt.id, closureId: activeReceipt.closureId
        }),
        activateArea(null)
      ]
    );
  };

  printReceiptCopy = () => {
    const { dispatch, activeReceipt, intl } = this.props;

    dispatch(toggleAdminLoadingScreen(true));

    api.receipt.printReceipt(activeReceipt.id, activeReceipt.closureId)
      .then(() => {
        dispatch([
          toggleAdminNotificationPopup(intl.formatMessage(messages.receiptsSendPrinter)),
          toggleAdminLoadingScreen(false)
        ]);
      })
      .catch(e => {
        dispatch([
          addAdminError(e),
          toggleAdminLoadingScreen(false)
        ]);
      });
  };

  openUnfinishedBillPopup = () => {
    const { dispatch } = this.props;

    dispatch(toggleUnfinishedBillPopup(true));
  };

  render() {
    const { selectedReceiptItems, activeReceipt, intl } = this.props;
    const emptyReceiptItems = isEmpty(selectedReceiptItems);

    const isLimit = pathOr(0, 'idLimit', activeReceipt) > 0;
    const showButtonsForUnfinished = pathOr(0, 'unfinished', activeReceipt);

    return (
      <Box
        backgroundColor="appBg"
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
        paddingBottom={0.5}
      >

        <Box zIndex={1} flexShrink={0} flexGrow={1} paddingTop={1} paddingHorizontal={1}>
          <ReceiptItemsSheet />
        </Box>

        {showButtonsForUnfinished
          ? <Box
              paddingTop={0}
              paddingBottom={0.25}
              paddingHorizontal={0.75}
              zIndex={10}
              flexShrink={0}
              flexDirection="row"
            >
              <ComposedButton
                disabled={!activeReceipt || !emptyReceiptItems}
                width="100%"
                caption={intl.formatMessage(messages.receiptsFinish)}
                backgroundColor="buttonBlue"
                shadowColor="buttonBlueShadow"
                icon="list"
                onPress={this.openUnfinishedBillPopup}
              />
            </Box>
          : <Box>
              <Permission name="payment.cancel">
                <Box
                  paddingTop={0}
                  paddingBottom={0}
                  paddingHorizontal={0.75}
                  zIndex={10}
                  flexShrink={0}
                  flexDirection="row"
                >
                  <Box
                    flexGrow={1}
                    flexShrink={0}
                    flexBasis={1}
                    marginHorizontal={0.25}
                  >
                    <ComposedButton
                      disabled={!activeReceipt || (isLimit && !emptyReceiptItems)}
                      caption={emptyReceiptItems ? intl.formatMessage(messages.receiptsCancelReceipt)
                        : intl.formatMessage(messages.receiptsCancelChosen)}
                      backgroundColor="buttonRedish"
                      shadowColor="buttonRedishShadow"
                      icon="trash"
                      onClick={() => emptyReceiptItems
                        ? this.cancelReceipt()
                        : this.cancelReceiptItems()
                      }
                    />
                  </Box>
                  <Box
                    flexGrow={1}
                    flexShrink={0}
                    flexBasis={1}
                    marginHorizontal={0.25}
                  >
                    <ComposedButton
                      disabled={!activeReceipt || !emptyReceiptItems}
                      caption={intl.formatMessage(messages.receiptsCancelMoveTable)}
                      backgroundColor="buttonRedish"
                      shadowColor="buttonRedishShadow"
                      icon="one-stand-table"
                      onPress={this.cancelReceiptAndReturnToTable}
                    />
                  </Box>
                </Box>
              </Permission>

              <Box
                paddingTop={0}
                paddingBottom={0.25}
                paddingHorizontal={0.75}
                zIndex={10}
                flexShrink={0}
                flexDirection="row"
              >
                <Box
                  flexGrow={1}
                  flexShrink={0}
                  flexBasis={1}
                  marginHorizontal={0.25}
                >
                  <ComposedButton
                    disabled={!activeReceipt}
                    caption={intl.formatMessage(messages.receiptsPrintCopy)}
                    backgroundColor="blue"
                    shadowColor="darkBlue"
                    icon="printer"
                    onClick={this.printReceiptCopy}
                  />
                </Box>
                <Permission name="payment.add">
                  <Box
                    flexGrow={1}
                    flexShrink={0}
                    flexBasis={1}
                    marginHorizontal={0.25}
                  >
                    <ComposedButton
                      disabled={!activeReceipt}
                      caption={intl.formatMessage(messages.receiptsPaymentChange)}
                      backgroundColor="blue"
                      shadowColor="darkBlue"
                      icon="pay"
                      onClick={this.changePayments}
                    />
                  </Box>
                </Permission>
                <Box
                  flexGrow={1}
                  flexShrink={0}
                  flexBasis={1}
                  marginHorizontal={0.25}
                >
                  <ComposedButton
                    disabled={!activeReceipt}
                    caption={intl.formatMessage(messages.receiptsAddTip)}
                    backgroundColor="blue"
                    shadowColor="darkBlue"
                    icon="pay"
                    onClick={this.openTipPopup}
                  />
                </Box>
              </Box>
            </Box>}
      </Box>
    );
  }
}

export default compose(
  connect(
    (state: State) => ({
      selectedReceiptItems: state.admin.receipts.selectedReceiptItems,
      activeOpenTableId: state.tables.active.openTableId,
      activeReceipt: state.admin.receipts.active.receipt,
      openTables: state.tables.openTables
    })
  ),
  injectIntl
)(Sidebar);
