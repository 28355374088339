// @flow
import type { State } from '../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import {compose, append, reverse, path, filter, pathOr, last, sort} from 'rambda';
import { isEmpty } from 'ramda';
import Box from '../../common/components/Box';
import Button from '../../common/components/Button';
import Text from '../../common/components/Text';
import Icon from '../../common/components/Icon';
import Divider from '../../common/components/Divider';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/payment';
import ContextMenuButton from '../../common/components/ContextMenuButton';
import { formatPriceNoIntl } from '../../common/order/utils';
import {
  showPrgDotaz, toggleDisableForm, toggleNotePopupOpen,
  toggleServiceCouponPopup, updatePaymentTransaction
} from '../../common/payment/actions';
import Keyboard from '../../common/components/Keyboard/calculator/Keyboard';
import CalculatorButtons from '../../common/components/Keyboard/calculator/Buttons';
import CalculatorDisplay from '../../common/components/Keyboard/calculator/Display';
import createCalculatorOperations, { withDefaultInputValue } from '../../common/components/Keyboard/calculator/operations';
import { parsePrgDotaz, processReceivedPaymentTransactionData } from '../../common/payment/utils';
import { findParamValue } from '../../common/parameters/utils';
import { prgDotazIsValid } from '../../common/payment/prgDotazy';
import { validPaymentMediaSelector } from '../../common/payment/selectors';
import api from '../../common/lib/api';
import { addError } from '../../common/order/actions';
import { paymentOperationsName } from '../../common/lib/api/payment';
import orderKeeper, { RequestOrderError } from '../../common/lib/fetch/orderKeeper';
import QuickPayments from '../../common/components/QuickPayments';
import LoyalmanQuickPayments from '../../common/components/LoyalmanQuickPayments';
import ScrollView from '../../common/components/ScrollView';
import PaymentNotePopup from './PaymentNotePopup';
import { getCurrencySymbol } from '../../common/parameters/service';

class SubPaymentsDialogue extends React.Component {
  constructor(props) {
    super(props);
    const { paymentStats: { leftToPay } } = props;

    this.state = {
      calculatorValue: leftToPay / 100,
      subtotals: [],
      addNoteStep: false,
      addNoteStepPayment: null
    };

    this.reqOrderKeeper = orderKeeper(paymentOperationsName);
  }

  togglePaymentNote = (toggle, completePaymentMedia = null) => {
    const { dispatch } = this.props;
    this.setState({
      addNoteStep: toggle,
      addNoteStepPayment: completePaymentMedia
    });
    dispatch(toggleNotePopupOpen(toggle));
  };

  addPayment = (completePaymentMedia, additionalData = {}) => {
    const { dispatch, paymentTransactionId } = this.props;
    const amount = this.state.calculatorValue * 100;

    const prgDotazParsed = parsePrgDotaz(completePaymentMedia.prg_dotaz);

    const serviceCoupon = pathOr('', 'prg_dotaz', completePaymentMedia).includes('dotaz_jup');

    if (serviceCoupon) {
     const prgDotaz = pathOr('', 'prg_dotaz', completePaymentMedia) !== '';
     if (prgDotaz) {
        dispatch(toggleServiceCouponPopup({
          paymentTransactionId,
          paymentMediaId: completePaymentMedia.id,
          amount,
          additionalData
        }));
        return;
     }
    }

    if (prgDotazIsValid(prgDotazParsed)) {
      dispatch(showPrgDotaz(prgDotazParsed, { ...completePaymentMedia, amount, additionalData }));
    } else {
      dispatch(toggleDisableForm(true));
      this.reqOrderKeeper(() =>
          api.payment.addPaymentRecord(
            paymentTransactionId,
            completePaymentMedia.id,
            amount,
            additionalData,
            completePaymentMedia.wallet || ''
          )
        ).then(({ body }) => {
          if (path(['payment', 'id'], body)) {
            dispatch([
              updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
              toggleDisableForm(false)
            ]);
          }
        }).catch(e => {
          if (e instanceof RequestOrderError) return;

          dispatch([
            addError(e),
            toggleDisableForm(false)
          ]);
        });
    }
  };

  componentWillUpdate(nextProps) {
    const { paymentStats: { leftToPay } } = this.props;
    const { paymentStats: { leftToPay: nextLeftToPay } } = nextProps;

    if (leftToPay !== nextLeftToPay) {
      // const leftToPay = nextLeftToPay > 0 ? nextLeftToPay : 0;
      const leftToPay = nextLeftToPay;
      if (this.Keyboard) {
        this.Keyboard.cancelCurrentInput(formatPriceNoIntl(leftToPay));
      }
      this.setState({ calculatorValue: leftToPay / 100 });
    }
  }

  appendFromMemory(value) {
    // eslint-disable-next-line no-eval
    this.setState({ calculatorValue: eval(this.Keyboard.appendToCurrentInput(value)) });
  }

  render() {
    const {
      intl,
      parameters,
      paymentStats: {
        leftToPay
      },
      formDisabled
    } = this.props;

    const { addNoteStep, addNoteStepPayment } = this.state;

    let { validPaymentMedia } = this.props;

    const paramIsPaymentDivisible = findParamValue('K32.is_platdel', parameters);
    const isLoyalmanPaymentMedium = !isEmpty(filter(media => media.nazev === 'K_LOYAL', validPaymentMedia));
    validPaymentMedia = filter(media => media.nazev !== 'K_LOYAL', validPaymentMedia);

    return (
      <Box
        backgroundColor="appBg"
        minHeight="100%"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        paddingTop={2}
        flex={2}
        flexBasis="100%"
        marginRight={2}
        minWidth={34}
        overflow="hidden"
      >
        <Box
          backgroundColor="darkGray"
          height="84vh"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          color="white"
          width="100%"
          boxShadow="default"
        >
          {paramIsPaymentDivisible &&
            <Box
              backgroundColor="lessDarkGray"
              height="84vh"
              padding={2}
              width={16}
              flexShrink={0}
            >
              <Keyboard
                ref={keyboard => { this.Keyboard = keyboard; }}
                style={theme => ({
                  width: theme.typography.lineHeight(5) * 1.75 * 4,
                  transformOrigin: '0 0',
                  transform: 'scale(0.75)',
                })}
                createOperations={withDefaultInputValue(createCalculatorOperations)}
                Buttons={CalculatorButtons}
                Display={CalculatorDisplay}
                onLastKey={key => {
                  if (key === '=') {
                    this.setState({ subtotals: append(this.state.calculatorValue, this.state.subtotals) });
                  }
                }}
                onDisplayValue={val =>
                  // eslint-disable-next-line no-eval
                  this.setState({ calculatorValue: Math.round((eval(val.slice(-1) === ' ' ? val.slice(0, -3) : val) + Number.EPSILON) * 100) / 100 })
                }
                defaultValue={formatPriceNoIntl(leftToPay)}
                flexShrink={0}
                disabled={formDisabled}
              />

              <Box
                zIndex={1}
                position="absolute"
                top={2.7}
                left={13.4}
                style={{ transform: 'rotate(180deg)' }}
              >
                <Icon name="navigation-left" color="blue" scale={5} />
              </Box>

              <Text
                scale={4}
                bold
                marginTop={2}
                color="white"
                paddingBottom={0.5}
                borderBottomColor="gray"
                borderBottomWidth={1}
                borderStyle="dashed"
                flexShrink={0}
              >
                {intl.formatMessage(messages.subtotal)}
              </Text>
              <ScrollView>
                <Box marginTop={1}>
                  {reverse(this.state.subtotals).map((subtotal, index) => (
                    <Button
                      key={index}
                      color="white"
                      onPress={() => this.appendFromMemory(subtotal)}
                      disabled={formDisabled}
                      justifyContent="flex-start"
                    >
                      {`${subtotal.toFixed(2).replace(/\./g, intl.formatMessage(messages.dot))} ${getCurrencySymbol()}`}
                    </Button>)
                  )}
                </Box>
              </ScrollView>
            </Box>
          }

          {addNoteStep && (
            <PaymentNotePopup
              onSubmit={(notes) => {
                this.addPayment(addNoteStepPayment, { notes });
                this.togglePaymentNote(false);
              }}
              onClose={() => this.togglePaymentNote(false)}
            />
          )}

          <Box
            width="100%"
            height="84vh"
            overflow="hidden"
            padding={2}
          >
            {isLoyalmanPaymentMedium &&
              <LoyalmanQuickPayments
                onSelect={this.addPayment}
                onLongPress={(paymentMedia) => this.togglePaymentNote(true, paymentMedia)}
                flexDirection="row"
                justifyContent="space-between"
                paddingBottom={1}
                flexShrink={0}
                marginHorizontal={-0.25}
              />}

            {isLoyalmanPaymentMedium &&
              <Divider color="lessDarkGray" height="2px" />}

            <QuickPayments
              onSelect={this.addPayment}
              onLongPress={(paymentMedia) => this.togglePaymentNote(true, paymentMedia)}
              flexDirection="row"
              justifyContent="space-between"
              paddingBottom={1}
              flexShrink={0}
              marginHorizontal={-0.25}
            />

            <Divider color="lessDarkGray" height="2px" />

            <ScrollView>
              <Box
                width="100%"
                marginTop={1}
                color="white"
                flexDirection="row"
                justifyContent="flex-start"
                flexWrap="wrap"
                paddingBottom={2}
              >
                {validPaymentMedia && sort((a, b) => (a.sortOrder - b.sortOrder), validPaymentMedia).map(paymentMedia =>
                  <ContextMenuButton
                    key={paymentMedia.idriadok}
                    text={paymentMedia.name}
                    onPress={() => this.addPayment(paymentMedia)}
                    onLongPress={() => this.togglePaymentNote(true, paymentMedia)}
                    icon="price-level"
                    width={window.innerWidth < 1270 ? '100%' : '40%'}
                    minWidth="160px"
                    marginRight="10%"
                    paddingRight={0.25}
                    disabled={formDisabled}
                  />
                )}
              </Box>
            </ScrollView>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    user: state.auth.user,
    parameters: state.parameters.parameters,
    paymentTransactionId: state.payment.paymentTransactionId,
    paymentStats: state.payment.stats,
    validPaymentMedia: validPaymentMediaSelector(state),
    formDisabled: state.payment.formDisabled
  })),
  injectIntl,
)(SubPaymentsDialogue);
