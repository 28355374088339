// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Platba',
    id: 'payment.page.title'
  },
  closePayment: {
    defaultMessage: 'Zavrieť platbu',
    id: 'payment.page.closePayment'
  },
  overallSum: {
    defaultMessage: 'Celková suma',
    id: 'payment.page.overallSum'
  },
  overallSumAfterDiscount: {
    defaultMessage: 'Suma po zľave',
    id: 'payment.page.overallSumAfterDiscount'
  },
  tip: {
    defaultMessage: 'TIP',
    id: 'payment.page.tip'
  },
  leftToPay: {
    defaultMessage: 'Zostáva doplatiť',
    id: 'payment.page.leftToPay'
  },
  leftToPayTip: {
    defaultMessage: 'Preplatok (TIP)',
    id: 'payment.page.leftToPayTip'
  },
  leftToPaySurplus: {
    defaultMessage: 'Preplatok',
    id: 'payment.page.leftToPaySurplus'
  },
  leftToPayExact: {
    defaultMessage: 'Zaplatené presne',
    id: 'payment.page.leftToPayExact'
  },
  setDiscount: {
    defaultMessage: 'Zadať zľavu',
    id: 'payment.page.setDiscount'
  },
  loyalMAN: {
    defaultMessage: 'LoyalMAN',
    id: 'payment.page.loyalMAN'
  },
  subtotal: {
    defaultMessage: 'Medzivýsledky',
    id: 'payment.page.subtotal'
  },
  cash: {
    defaultMessage: 'V hotovosti',
    id: 'payment.page.cash'
  },
  bankCard: {
    defaultMessage: 'Banková karta',
    id: 'payment.page.bankCard'
  },
  mealVouchers: {
    defaultMessage: 'Stravné lístky',
    id: 'payment.page.mealVouchers'
  },
  backToTable: {
    defaultMessage: 'Naspäť na stôl',
    id: 'payment.page.backToTable'
  },
  backToPayment: {
    defaultMessage: 'Naspäť na platbu',
    id: 'payment.page.backToPayment'
  },
  finishAndPrintReceipt: {
    defaultMessage: 'Uzavrieť účet a tlačiť blok',
    id: 'payment.page.finishAndPrintReceipt'
  },
  createNewUser: {
    defaultMessage: 'Pridať používateľa',
    id: 'payment.page.createNewUser'
  },
  assignToUser: {
    defaultMessage: 'Priradiť k používateľovi ',
    id: 'payment.page.assignToUser'
  },
  printPrereceipt: {
    defaultMessage: 'Tlačiť predúčet',
    id: 'payment.page.printPrereceipt'
  },
  dot: {
    defaultMessage: ',',
    id: 'payment.page.dot'
  },
  overPayment: {
    defaultMessage: 'preplatok',
    id: 'payment.page.overPayment'
  },
  giveTip: {
    defaultMessage: 'Zadať TIP',
    id: 'payment.page.giveTip'
  },
  descShort: {
    defaultMessage: 'Pozn.',
    id: 'payment.page.descShort'
  },
  closeDiscountMenu: {
    defaultMessage: 'Zavrieť výber zľavy',
    id: 'payment.page.closeDiscountMenu'
  },
  relativeDiscount: {
    defaultMessage: 'Percentuálna',
    id: 'payment.page.relativeDiscount'
  },
  absoluteDiscount: {
    defaultMessage: 'Absolútna',
    id: 'payment.page.absoluteDiscount'
  },
  discount: {
    defaultMessage: 'Zľava',
    id: 'payment.page.discount'
  },
  discountReason: {
    defaultMessage: 'Dôvod zľavy',
    id: 'payment.page.discountReason'
  },
  relative: {
    defaultMessage: '%',
    id: 'payment.page.relative'
  },
  absolute: {
    defaultMessage: 'EUR',
    id: 'payment.page.absolute'
  },
  pay: {
    defaultMessage: 'Platiť',
    id: 'payment.page.pay'
  },
  remaind: {
    defaultMessage: 'zostatok',
    id: 'payment.page.remaind'
  },

  hjmeno: {
    defaultMessage: 'Firma',
    id: 'payment.page.company'
  },
  adresa1: {
    defaultMessage: 'Ulica',
    id: 'payment.page.address1'
  },
  adresa2: {
    defaultMessage: 'Mesto, PSČ',
    id: 'payment.page.address2'
  },
  adresa3: {
    defaultMessage: 'Krajina',
    id: 'payment.page.address3'
  },
  ico: {
    defaultMessage: 'IČO',
    id: 'payment.page.ico'
  },
  dic: {
    defaultMessage: 'DIČ',
    id: 'payment.page.dic'
  },
  icdph: {
    defaultMessage: 'IČ DPH',
    id: 'payment.page.icdph'
  },
  schvalil: {
    defaultMessage: 'Schválil',
    id: 'payment.page.schvalil'
  },
  txt: {
    defaultMessage: 'Poznámka',
    id: 'payment.page.txt'
  },
  name: {
    defaultMessage: 'Meno',
    id: 'payment.page.name'
  },
  registerNewUser: {
    defaultMessage: 'Registrovať nového používateľa',
    id: 'payment.page.registerNewUser'
  },
  registerSeparately: {
    defaultMessage: 'Zákazník sa sám registruje cez web',
    id: 'payment.page.registerSeparately'
  },
  createNewCard: {
    defaultMessage: 'Vytvoriť novú kartu',
    id: 'payment.page.createNewCard'
  },
  newCardPackages: {
    defaultMessage: 'Balíčky pre novú kartu',
    id: 'payment.page.newCardPackages'
  },
  cardExpiration: {
    defaultMessage: 'Expirácia karty',
    id: 'payment.page.cardExpiration'
  },
  cardCode: {
    defaultMessage: 'ID karty',
    id: 'payment.page.cardCode'
  },
  touchTheCardReader: {
    defaultMessage: 'Priložte kartu k čítačke',
    id: 'payment.page.touchTheCardReader'
  },
  cardNotRegistered: {
    defaultMessage: 'Zadaná karta nie je registrovaná',
    id: 'payment.page.cardNotRegistered'
  },
  cardNotInactive: {
    defaultMessage: 'Zadaná karta je už obsadená',
    id: 'payment.page.cardNotInactive'
  },
  enterCardManualy: {
    defaultMessage: 'Zadať ID manuálne',
    id: 'payment.page.enterCardManualy'
  },
  newCustomer: {
    defaultMessage: 'Nový zákazník',
    id: 'payment.page.newCustomer'
  },
  chooseCard: {
    defaultMessage: 'Vyberte kartu',
    id: 'payment.page.chooseCard'
  },
  registerParagon: {
    defaultMessage: 'Registrovať paragon',
    id: 'payment.page.registerParagon'
  },
  printer: {
    defaultMessage: 'Tlačiareň',
    id: 'payment.page.printer'
  },
  copies: {
    defaultMessage: 'Kópií',
    id: 'payment.page.copies'
  },
  sendReceiptByEmail: {
    defaultMessage: 'Poslať bloček mailom',
    id: 'payment.page.sendReceiptByEmail'
  },
});
