// @flow
import type { Action } from '../../types';
import { assocPath } from 'ramda';

const initialState = {
  history: {
    closureData: '',
    selectedClosureId: null,
    isLoading: false
  },
  journal: {
    list: [
      { id: 1, date: '2018-02-12' },
      { id: 2, date: '2018-02-13' },
      { id: 3, date: '2018-02-14' },
      { id: 4, date: '2018-02-15' },
    ],
    journalData: '',
    selectedJournalId: null,
    isLoading: false
  }
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'ADMIN_CLOSURES_HISTORY_IS_LOADING': {
      const { is } = action.payload;
      return assocPath(['history', 'isLoading'], !!is, state);
    }

    case 'ADMIN_CLOSURES_HISTORY_SET_PREVIEW_DATA': {
      const { data } = action.payload;
      return assocPath(['history', 'closureData'], data, state);
    }

    case 'ADMIN_CLOSURES_HISTORY_TOGGLE': {
      const { id } = action.payload;
      const { history: { selectedClosureId } } = state;
      const newValue = (selectedClosureId === id) ? null : id;

      return assocPath(['history', 'selectedClosureId'], newValue, state);
    }

    case 'ADMIN_CLOSURES_JOURNAL_IS_LOADING': {
      const { is } = action.payload;
      return assocPath(['journal', 'isLoading'], !!is, state);
    }

    case 'ADMIN_CLOSURES_JOURNAL_SET_PREVIEW_DATA': {
      const { data } = action.payload;
      return assocPath(['journal', 'closureData'], data, state);
    }

    case 'ADMIN_CLOSURES_JOURNAL_TOGGLE': {
      const { id } = action.payload;
      const { journal: { selectedJournalId } } = state;
      const newValue = (selectedJournalId === id) ? null : id;

      return assocPath(['journal', 'selectedJournalId'], newValue, state);
    }

    default:
      return state;
  }
};

export default reducer;
