// @flow
import type { Reducer, Action } from '../../types';
import { propEq, find, map, filter, reject, concat,
  pipe, findIndex, contains, omit } from 'rambda';
import { mergeWithKey, differenceWith } from 'ramda';
import moment from 'moment';

/*
  Actions matrix:

    client            server fresh      server old      action
    ----------------------------------------------------------
1   exists sent       exists            -               merge
2   exists sent       -                 exists          nothing
3   exists sent       not exists        -               user action (delete or recreate)
4   exists sent       -                 not exists      nothing
5   exists + dirty    exists            -               merge pristine only
6   exists + dirty    -                 exists          nothing
7   exists + dirty    not exists        -               user action (delete or recreate)
8   exists + dirty    -                 not exists      nothing
9   not exists        exists            -               add
10  not exists        -                 exists          add
11  not exists        not exists        -               nothing                 (invalid)
12  not exists        -                 not exists      nothing                 (invalid)
13  new unsent        exists            -               user action
                                                           (overwrite or merge pristine only)
14  new unsent        -                 exists          nothing
15  new unsent        not exists        -               nothing
16  new unsent        -                 not exists      nothing
*/

type UserEditableStrategyOptions = {
  idKey: string,
  clientTimestampKey: string,
  serverTimestampKey: string,
  serverGlobalTimestamp?: any
}

const mergeWithDirty = (itemWithDirty, serverItem) => {
  if (Array.isArray(itemWithDirty._dirty) && itemWithDirty._dirty.length) {
    const merged = mergeWithKey((prop, fromItemWithDirty, fromItem) => {
      const index = findIndex(propEq('prop', prop), itemWithDirty._dirty || []);

      if (index === -1) {
        return fromItem;
      }

      itemWithDirty._dirty[index].oldValue = fromItem;
      return fromItemWithDirty;
    }, itemWithDirty, serverItem);

    const copy = { ...merged };
    delete copy._local;

    return copy;
  }

  const ignoreLocals = (x, y) => x.startsWith('_') ? true : x === y;
  const deleteKeys = differenceWith(
    ignoreLocals, Object.keys(itemWithDirty), Object.keys(serverItem)
  );
  const merged = { ...itemWithDirty, ...serverItem };

  return omit(deleteKeys, merged);
};

export const mergeWithUserEditableStrategy =
(clientItems, serverItems, config: UserEditableStrategyOptions = {}) => {
  const {
    idKey = 'id',
    clientTimestampKey = 'updatedAt',
    serverTimestampKey = 'updatedAt',
    serverGlobalTimestamp = Date.now()
  } = config;

  const mapNewPayloadItemsFromServerItems = map(serverItem => {
    const clientExistingItem = find(propEq(idKey, serverItem[idKey]), clientItems);

    const paymentId = serverItem ? serverItem.paymentId : null;

    if (clientExistingItem) {
      const serverTime = typeof serverItem[serverTimestampKey] === 'number'
        ? serverItem[serverTimestampKey]
        : Date.parse(serverItem[serverTimestampKey]);

      const clientTime = typeof clientExistingItem[clientTimestampKey] === 'number'
        ? clientExistingItem[clientTimestampKey]
        : Date.parse(clientExistingItem[clientTimestampKey]);

      const serverIsNewer = (serverTime | 0) >= (clientTime | 0);

      const item = serverIsNewer
        ? mergeWithDirty(clientExistingItem, serverItem) // 1, 5
        : clientExistingItem; // 2, 6

      return { ...item, paymentId};
    }

    return serverItem; // 9, 10, 13
  });

  const filterEmpty = filter(item => !!item);

  const itemsFromServer = pipe(
    mapNewPayloadItemsFromServerItems,
    filterEmpty
  )(serverItems);

  const itemsOnlyInClient = filter(
    item => !find(propEq(idKey, item[idKey]), serverItems),
    clientItems);

  const mapUserActionsFromState = map(clientItem => {
    if (clientItem._local) {
      return null; // 14, 15, 16
    }

    const serverTime = typeof serverGlobalTimestamp === 'number'
      ? serverGlobalTimestamp
      : Date.parse(serverGlobalTimestamp);

    const clientTime = typeof clientItem[clientTimestampKey] === 'number'
      ? clientItem[clientTimestampKey]
      : Date.parse(clientItem[clientTimestampKey]);

    const serverIsNewer = (serverTime | 0) >= (clientTime | 0);

    if (serverIsNewer) {
      return clientItem[idKey]; // 3, 7 delete from client
    }

    return null;
  });

  // delete from items
  const userActionsFromState = pipe(mapUserActionsFromState, filterEmpty)(itemsOnlyInClient);

  const removeWithId = (ids: [], items: []) => reject(
    item => contains(item[idKey], ids),
    items);

  const newActionPayloadItems = concat(
    itemsFromServer,
    itemsOnlyInClient
  );

  return removeWithId(userActionsFromState, newActionPayloadItems);
};

export const userEditableReducerStrategy = (reducer: Reducer, config: Object) =>
  (state: Object, action: Action) => {
    if (action.type.endsWith('_SYNC') || action.type === 'SYNC_SERVER_DATA_COMMIT') {
      const {
        itemsKey = 'items',
        serverTimestampKey = 'updatedAt',
      } = config;

      const stateItems = state[itemsKey];

      const payloads = Array.isArray(action.payload[itemsKey])
        ? action.payload[itemsKey]
        : [action.payload[itemsKey]];

      const newItems =
        mergeWithUserEditableStrategy(stateItems, payloads, {
          ...config,
          serverGlobalTimestamp: action.meta ? moment(action.meta[serverTimestampKey]) : moment()
        });

      const payload = {
        ...action.payload,
        [itemsKey]: newItems,
        // [userActionsKey]: userActions
      };

      return reducer(state, { ...action, payload });
    }

    return reducer(state, action);
  };
