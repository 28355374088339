// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { State } from '../../../common/types';

const bindScreenLifecycleActions = ({ onVisit, onLeave }) => BaseComponent => {
  class BindScreenLifecycleActions extends React.PureComponent {
    componentWillMount() {
      const { dispatch, isLoyalManOn } = this.props;

      // TODO - veeeeery ugly solution - will be fixed
      if (onVisit && !isLoyalManOn) {
        dispatch(onVisit);
      }
    }

    componentWillUnmount() {
      const { dispatch, isLoyalManOn } = this.props;

      // TODO - veeeeery ugly solution - will be fixed - still does not work the isLoyalManOn does not refresh
      if (onLeave && !isLoyalManOn) {
        dispatch(onLeave);
      }
    }

    render() {
      const { dispatch, isLoyalManOn, ...rest } = this.props;

      return <BaseComponent {...rest} />;
    }
  }

  return connect((state: State) => ({
      // TODO - veeeeery ugly solution - will be fixed
      isLoyalManOn: state.loyalman.users.isLoyalManOn
  }))(BindScreenLifecycleActions);
};

export default bindScreenLifecycleActions;
