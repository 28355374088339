// @flow
import React from 'react';


class PrimaryRenderer extends React.PureComponent {
  render() {
    const { data, renderers } = this.props;
    const actualRenderer = renderers[0];

    return actualRenderer({ data, renderers: renderers.slice(1) });
  }
}

export default PrimaryRenderer;
