// @flow
import React from 'react';
import Box from '../../../../common/components/Box';
import Button from '../../../../common/components/Button';
import Text from '../../../../common/components/Text';
import theme from '../../../themes/defaultTheme';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import 'rc-calendar/assets/index.css';
import '../../../themes/rangeCalendar.css';
import skCalendar from 'rc-calendar/lib/locale/sk_SK';
import moment from 'moment';
import Spinner from '../../../../common/components/Spinner';
import api from '../../../../common/lib/api';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { findParamValue } from '../../../../common/parameters/utils';
import type { State } from '../../../../common/types';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import ClosurePrintPreview from '../ClosurePrintPreview';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/closures';

const timePickerElement = (
  <TimePickerPanel
    defaultValue={[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]}
  />
);

class Confirmation extends React.PureComponent {
  state = {
    dateFrom: moment().subtract(1, 'months').startOf('month'),
    dateTo: moment().subtract(1, 'months').endOf('month'),
    preview: undefined,
    loading: false,
    error: null,
    done: false,
  };

  confirm = () => {
    this.setState({ loading: true, error: false });

    const { dateFrom, dateTo } = this.state;
    const { paramIsEkasa } = this.props;

    if (paramIsEkasa) {
      api.closure.getClosures('interval', {
        dateFrom: dateFrom.format('YYYY-MM-DD[T]HH:mm:ss'),
        dateTo: dateTo.format('YYYY-MM-DD[T]HH:mm:ss')
      }).then(({ body: { result: { preview: { data: preview } } } }) => {
        this.setState({ loading: false, done: true, preview });
      }).catch(e => {
        this.setState({ error: e.toString(), loading: false });
      });
    } else {
      api.closure.getClosures('interval', {
        dateFrom: dateFrom.format('YYYY-MM-DD[T]HH:mm:ss'),
        dateTo: dateTo.format('YYYY-MM-DD[T]HH:mm:ss')
      }).then(() => {
        this.setState({ loading: false, done: true });
        setTimeout(this.cancel, 3000);
      }).catch(e => {
        this.setState({ error: e.toString(), loading: false });
      });
    }
  };

  cancel = () => {
    if (!this.unmounted) {
      const { dispatch } = this.props;
      dispatch(replace('/serviceAreas'));
    }
  };

  setRange = ([dateFrom, dateTo]) => {
    this.setState({ dateFrom, dateTo });
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const { error, dateFrom, dateTo, loading, done, preview } = this.state;
    const { intl } = this.props;

    if (preview) {
      return (
        <ClosurePrintPreview
          dateFrom={dateFrom}
          dateTo={dateTo}
          closurePreview={preview}
          closureBased={false}
        />
      );
    }

    const dateFromFormatted = dateFrom.format('DD.MM.YYYY HH:mm', { trim: false });
    const dateToFormatted = dateTo.format('DD.MM.YYYY HH:mm', { trim: false });

    let inner;
    if (loading) {
      inner = [
        <Text key={1} marginBottom={2} scale={3} align="center">{intl.formatMessage(messages.creatingIntervalClosure)}</Text>,
        <Box key={2} paddingTop={3}>
          <Spinner />
        </Box>
      ];
    } else if (done) {
        inner = [
          <Text key={1} marginBottom={2} scale={3} align="center">{intl.formatMessage(messages.createdIntervalClosure)}</Text>
        ];
    } else {
      inner = [
        <Text key={1} marginBottom={1.5} scale={3} align="center">{intl.formatMessage(messages.intervalClosure)}</Text>,

        <Box key={2} flexDirection="row">
          <Text width="50%" align="center">{intl.formatMessage(messages.intervalFrom)} {dateFromFormatted}</Text>
          <Text width="50%" align="center">{intl.formatMessage(messages.intervalTo)} {dateToFormatted}</Text>
        </Box>,

        <RangeCalendar
          key={3}
          locale={skCalendar}
          showDateInput={false}
          showToday={false}
          showOk={false}
          onSelect={this.setRange}
          selectedValue={[dateFrom, dateTo]}
          timePicker={timePickerElement}
        />,

        <Box marginTop={2} key={4}>
          <Button
            backgroundColor="teal"
            outline
            color="buttonTealShadow"
            paddingVertical={0.75}
            paddingHorizontal={2}
            alignSelf="center"
            onPress={this.confirm}
          >
            <Text color="white" bold lineHeight={theme.typography.fontSize()}>{intl.formatMessage(messages.confirmIntervalClosure)}</Text>
          </Button>
        </Box>
      ];
    }

    return (
      <Box
        backgroundColor="adminPanelBg"
        borderRadius="normal"
        boxShadow="default"
        alignSelf="center"
        marginTop={4}
        paddingHorizontal={3}
        paddingVertical={2}
        width={36}
      >
        {inner}

        {error
        && <Text color="error" marginTop={1} marginBottom={-1}>{error}</Text>
        }
      </Box>
    );
  }
}

export default compose(
  connect(
    (state: State) => ({
      parameters: state.parameters.parameters,
    }),
    null,
    ({
       parameters
     }, { dispatch }, ownProps) => {
      const paramIsEkasa = findParamValue('K32.ekasa', parameters);
      return {
        ...ownProps,
        dispatch,
        paramIsEkasa,
      };
    }
  ),
  injectIntl
)(Confirmation);
