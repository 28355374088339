// @flow
import { Observable } from 'rxjs';

import type { Device } from './index';

type HidConfig = {
  path: string,
  usbAccess: any
}

const scanCodes = {
  0x1e: 1,
  0x1f: 2,
  0x20: 3,
  0x21: 4,
  0x22: 5,
  0x23: 6,
  0x24: 7,
  0x25: 8,
  0x26: 9,
  0x27: 0,
  0x58: '\n'
};

const devicesFactory = (hidConfig: HidConfig): Device => {
  // eslint-disable-next-line func-names
  const DevicePrototype = function ({ path, usbAccess }) {
    if (!usbAccess) usbAccess = require('node-hid');

    let openedDevice;
    let bufferObservable;

    this.exists = () => {
      const exists = !!usbAccess.devices().find(device => device.path === path);
      return Promise.resolve(exists);
    };

    this.isOpened = () =>
      !!openedDevice;

    this.open = () => {
      if (openedDevice) return Promise.resolve(this);

      try {
        openedDevice = new usbAccess.HID(path);
        return Promise.resolve(this);
      } catch (e) {
        return Promise.reject(e);
      }
    };

    this.close = () => {
      if (openedDevice) {
        openedDevice.close();
        openedDevice = null;
      }

      return Promise.resolve(this);
    };

    this.pause = () => {
      if (!openedDevice) throw new Error('Device is not opened');

      openedDevice.pause();
      return Promise.resolve(true);
    };

    this.resume = () => {
      if (!openedDevice) throw new Error('Device is not opened');

      openedDevice.resume();
      return Promise.resolve(true);
    };

    this.getObserver = () => {
      if (!openedDevice) throw new Error('Device is not opened');

      if (bufferObservable) return bufferObservable;

      let currentString = '';

      bufferObservable = Observable.create(observer => {
        openedDevice.on('data', buffer => {
          // TODO move to protocol
          const char = scanCodes[buffer[2]];
          if (char !== undefined) {
            if (char === '\n') {
              observer.next(currentString);
              currentString = '';
            } else {
              currentString += char;
            }
          }
        });

        openedDevice.on('error', error => {
          observer.error(error);
        });
      });

      return bufferObservable;
    };

    this.write = () =>
      Promise.resolve(true);

    this.writeLine = () =>
      Promise.resolve(true);
  };

  return new DevicePrototype(hidConfig);
};

export default devicesFactory;
