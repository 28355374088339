// @flow
import React from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { connectionChange } from 'react-native-offline/src/actionCreators';
import reactConnectionStore from 'react-native-offline/src/reactConnectionStore';
import checkInternetAccess from 'react-native-offline/src/checkInternetAccess';
import {
  setupConnectivityCheckInterval,
  clearConnectivityCheckInterval,
} from 'react-native-offline/src/checkConnectivityInterval';
import invariant from 'invariant';

// REMINDER: keep compatible with react-native-offline hoc

type Arguments = {
  withRedux?: boolean,
  timeout?: number,
  pingServerUrl?: string,
  pingServerMethod?: string,
  withExtraHeadRequest?: boolean,
  checkConnectionInterval?: number,
};

type State = {
  isConnected: boolean,
};

const networkConnectivityProvider = (
  {
    withRedux = false,
    timeout = 3000,
    pingServerUrl = 'https://google.com',
    pingServerMethod = 'HEAD',
    withExtraHeadRequest = true,
    checkConnectionInterval = 0,
  }: Arguments = {},
) => WrappedComponent => {
  invariant(typeof withRedux === 'boolean',
    'you should pass a boolean as withRedux parameter');
  invariant(typeof timeout === 'number',
    'you should pass a number as timeout parameter');
  invariant(typeof pingServerUrl === 'string',
    'you should pass a string as pingServerUrl parameter');

  class EnhancedComponent extends React.Component<void, State> {
    static displayName = `withNetworkConnectivity(${WrappedComponent.displayName})`;

    static contextTypes = {
      store: PropTypes.shape({
        dispatch: PropTypes.func,
      }),
    };

    state = {
      isConnected: window.navigator.onLine
    };

    componentDidMount() {
      window.addEventListener('offline', withExtraHeadRequest
        ? this.handleNetInfoChange
        : this.handleConnectivityChange);

      window.addEventListener('online', withExtraHeadRequest
        ? this.handleNetInfoChange
        : this.handleConnectivityChange);

      setupConnectivityCheckInterval(
        this.checkInternet,
        checkConnectionInterval,
      );
    }

    componentWillUnmount() {
      window.removeEventListener('offline', withExtraHeadRequest
        ? this.handleNetInfoChange
        : this.handleConnectivityChange);

      window.removeEventListener('online', withExtraHeadRequest
        ? this.handleNetInfoChange
        : this.handleConnectivityChange);

      clearConnectivityCheckInterval();
    }

    handleNetInfoChange = () => {
      const isConnected = window.navigator.onLine;
      if (!isConnected) {
        this.handleConnectivityChange();
      } else {
        this.checkInternet();
      }
    };

    checkInternet = () => {
      if (!window.navigator.onLine) return;

      checkInternetAccess({
        method: pingServerMethod,
        timeout,
        url: pingServerUrl,
      }).then((hasInternetAccess: boolean) => {
        this.handleConnectivityChange(hasInternetAccess);
      });
    };

    handleConnectivityChange = (isConnected = window.navigator.onLine) => {
      const { store } = this.context;
      reactConnectionStore.setConnection(isConnected);

      // Top most component, syncing with store
      if (withRedux) {
        if (isConnected !== store.getState().network.isConnected) {
          store.dispatch(connectionChange(isConnected));
        }
      } else {
        // Standard HOC, passing connectivity as props
        this.setState({ isConnected });
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          isConnected={withRedux ? undefined : this.state.isConnected}
        />
      );
    }
  }
  return hoistStatics(EnhancedComponent, WrappedComponent);
};

// for compatibility with react-native-offline this is HOC
export default networkConnectivityProvider;
