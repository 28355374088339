// @flex
import { Observable } from 'rxjs';
import usbHid from './usbHid';
import serial from './serial';
// import configuration from '../../common/useConfiguration';
import { propEq, find } from 'rambda';

export type Device = {|
  exists: () => Promise<boolean | Error>,
  open: () => Promise<Device | Error>,
  close: () => Promise<Device | Error>,
  getObserver: () => Observable<string>,
  pause: () => Promise<boolean | Error>,
  resume: () => Promise<boolean | Error>,
  write: (any) => Promise<boolean | Error>,
  writeLine: (string) => Promise<boolean | Error>,
  isOpened: () => boolean
|}

const getPeripheral = (name: string, config: Object): Device => {
  const deviceConfig = find(propEq('name', name), config.peripherals);
  if (!deviceConfig) return null;

  switch (deviceConfig.interface) {
    case 'usb-hid':
      return usbHid(deviceConfig);
    case 'serial':
      return serial(deviceConfig);
    default:
      return null;
  }
};

const pollPeripheral = (name: string, config): Observable => {
  const observable = Observable.create(observer => {
    const knownPeripheral = getPeripheral(name, config);

    if (!knownPeripheral) return;

    // TODO make more observable-idiomatic
    setInterval(() => {
      knownPeripheral.exists().then(does => {
        if (does && !knownPeripheral.isOpened()) {
          observer.next(knownPeripheral);
        }
      });
    }, config.pollingInterval || 2000);
  });

  return observable;
};

const configurePeripherals = (config) => ({
  getPeripheral: (name) => getPeripheral(name, config),
  pollPeripheral: (name) => pollPeripheral(name, config)
});

export default configurePeripherals;

// API example:
/*
import configurePeripherals from '../peripherals';
import type Device from '../peripherals';

const myPeripherals = {
  peripherals: [
    {
      name: 'card-reader',
      interface: 'usb-hid',
      path: 'IOService:/AppleACPIPlatformExpert/PCI0@0/AppleACPIPCI/XHC1@14/XHC1@14000000/' +
'HS01@14100000/EWORK-ERFID10A-608@14100000/IOUSBHostInterface@0/IOUSBHostHIDDevice@14100000,0'
    }
  ]
};

configurePeripherals(myPeripherals)
  .pollPeripheral('card-reader')
  .subscribe((device: Device) => {
    device.open().then(() => {
      device.observer.subscribe(data => {
        console.log('got this:', data);
      }, e => {
        device.close();
        console.error(e);
      });
    }).catch(e => console.error('cannot open device', e));
});

 */
