// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  yes: {
    defaultMessage: 'Áno',
    id: 'closures.yes'
  },
  no: {
    defaultMessage: 'Nie',
    id: 'closures.no'
  },
  confirmTable: {
    defaultMessage: 'Úspešné uzatvorenie stola, nepotvrdené položky ponechané.',
    id: 'closures.confirmTable'
  },
  closeTable: {
    defaultMessage: 'Úspešné uzatvorenie stola',
    id: 'closures.closeTable'
  },
  closeAllClosuresTitle: {
    defaultMessage: 'Naozaj chcete zatvoriť všetky účty?',
    id: 'closures.closeAllClosuresTitle'
  },
  closeAllClosuresInfo: {
    defaultMessage: 'Všetky otvorené účty budú zaplatené vybraným spôsobom platby bez zľavy',
    id: 'closures.closeAllClosuresInfo'
  },
  closeAllTablesInfo: {
    defaultMessage: 'Zavrieť aj nepotvrdené (čierne) stoly, aj stoly s nepotvrdenými objednávkami (nepotvrdené položky budú vymazané).',
    id: 'closures.closeAllTablesInfo'
  },
  deadlineFromTo: {
    defaultMessage: 'Uzávierka od – do',
    id: 'closures.history.deadlineFromTo'
  },
  closureToPrint: {
    defaultMessage: 'Uzávierka bola odoslaná na tlač',
    id: 'closures.history.closureToPrint'
  },
  closureCopyToPrint: {
    defaultMessage: 'Kópia fiškálnej uzávierky bola odoslaná na tlač',
    id: 'closures.history.closureCopyToPrint'
  },
  choosePrinter: {
    defaultMessage: 'Vybrať tlačiareň',
    id: 'closures.history.choosePrinter'
  },
  print: {
    defaultMessage: 'Vytlačiť',
    id: 'closures.history.print'
  },
  regenerateClosure: {
    defaultMessage: 'Pregenerovať uzávierku',
    id: 'closures.history.regenerateClosure'
  },
  copyClosure: {
    defaultMessage: 'Kópia fiškál. uzávierky',
    id: 'closures.history.copyClosure'
  },
  closureToPrintInter: {
    defaultMessage: 'Uzávierka bola odoslaná na tlač',
    id: 'closures.intermediate.closureToPrintInter'
  },
  confirmAndPrint: {
    defaultMessage: 'Potvrdiť a vytlačiť',
    id: 'closures.intermediate.confirmAndPrint'
  },
  confirmAndChoosePrinter: {
    defaultMessage: 'Potvrdiť a vybrať tlačiareň',
    id: 'closures.intermediate.confirmAndChoosePrinter'
  },
  creatingIntervalClosure: {
    defaultMessage: 'Vytvára sa intervalová uzávierka',
    id: 'closures.interval.creatingIntervalClosure'
  },
  createdIntervalClosure: {
    defaultMessage: 'Uzávierka bola vytvorená',
    id: 'closures.interval.createdIntervalClosure'
  },
  intervalClosure: {
    defaultMessage: 'Intervalová uzávierka',
    id: 'closures.interval.intervalClosure'
  },
  intervalFrom: {
    defaultMessage: 'Od:',
    id: 'closures.interval.intervalFrom'
  },
  intervalTo: {
    defaultMessage: 'Do:',
    id: 'closures.interval.intervalTo'
  },
  confirmIntervalClosure: {
    defaultMessage: 'Potvrdiť intervalovú uzávierku',
    id: 'closures.interval.confirmIntervalClosure'
  },
  journalPrint: {
    defaultMessage: 'Vytlačiť',
    id: 'closures.journal.journalPrint'
  },
  closureNewOverflowError: {
    defaultMessage: 'Hodnota výberu nesmie prevyšovať súčet hodnôt platobnej metódy, alebo dosahovať zápornú hodnotu.',
    id: 'closures.new.overFlorError'
  },
  closureNewWithdrawalError: {
    defaultMessage: 'Nastala chyba pri realizovaní výberov:',
    id: 'closures.new.withdrawalError'
  },
  closureSendToPrint: {
    defaultMessage: 'Uzávierka bola odoslaná na tlač',
    id: 'closures.new.closureSendToPrint'
  },
  closurePrint: {
    defaultMessage: 'Vytlačiť',
    id: 'closures.new.closurePrint'
  },
  closureChoosePrinter: {
    defaultMessage: 'Vybrať tlačiareň',
    id: 'closures.new.closureChoosePrinter'
  },
  closurePrintInProgress: {
    defaultMessage: 'Prebieha tlač',
    id: 'closures.new.closurePrintInProgress'
  },
  closureCreatingNew: {
    defaultMessage: 'Vytvára sa nová uzávierka',
    id: 'closures.new.closureCreatingNew'
  },
  closureCreatedNew: {
    defaultMessage: 'Uzávierka bola vytvorená',
    id: 'closures.new.closureCreatedNew'
  },
  closureConfirmNew: {
    defaultMessage: 'Naozaj chcete spraviť novú uzávierku?',
    id: 'closures.new.closureConfirmNew'
  },
  closureDepositBlockError: {
    defaultMessage: 'Nastala chyba pri realizovaní vkladu:',
    id: 'closures.new.closureDepositBlockError'
  },
  closureDepositHeader: {
    defaultMessage: 'Vykonať vklad pre platobnú metódu',
    id: 'closures.new.closureDepositHeader'
  },
  closureDepositDescription: {
    defaultMessage: 'Pre platobnú metódu {name} bude vykonaný vklad v hodnote {sum} {currency}. Chcete pokračovať?',
    id: 'closures.new.closureDepositDescription'
  },
  closureDepositConfirm: {
    defaultMessage: 'Áno',
    id: 'closures.new.closureDepositConfirm'
  },
  closureDepositCancel: {
    defaultMessage: 'Zrušiť',
    id: 'closures.new.closureDepositCancel'
  },
  closureDepositTableMediaName: {
    defaultMessage: 'Metóda',
    id: 'closures.new.table.mediaName'
  },
  closureDepositTableMediaPrn: {
    defaultMessage: 'Tlačiareň',
    id: 'closures.new.table.prn'
  },
  closureDepositTableSale: {
    defaultMessage: 'Tržba',
    id: 'closures.new.table.sale'
  },
  closureDepositTablePayment: {
    defaultMessage: 'Úhrada pohľadávky',
    id: 'closures.new.table.payment'
  },
  closureDepositTableSummary: {
    defaultMessage: 'Sumár vkladov/výberov',
    id: 'closures.new.table.summary'
  },
  closureDepositTableOverall: {
    defaultMessage: 'Spolu',
    id: 'closures.new.table.overall'
  },
  closureDepositTableWithdrawal: {
    defaultMessage: 'Výber',
    id: 'closures.new.table.withdrawal'
  },
  closureDepositTableDeposit: {
    defaultMessage: 'Vklad',
    id: 'closures.new.table.deposit'
  },
  closureNewYes: {
    defaultMessage: 'Áno',
    id: 'closures.new.yes'
  },
  closureNewNo: {
    defaultMessage: 'Nie',
    id: 'closures.new.no'
  },
  closureNewValidationNegative: {
    defaultMessage: 'Súčet hodnôt platobnej metódy nesmie dosahovať zápornú hodnotu.',
    id: 'closures.new.validation.negative'
  },
  closureSummaryCreate: {
    defaultMessage: 'Vytvára sa sumárna uzávierka',
    id: 'closures.summary.create'
  },
  closureSummaryCreated: {
    defaultMessage: 'Uzávierka bola vytvorená',
    id: 'closures.summary.created'
  },
  closureSummaryTitle: {
    defaultMessage: 'Sumárna uzávierka',
    id: 'closures.summary.title'
  },
  closureSummaryFrom: {
    defaultMessage: 'Od:',
    id: 'closures.summary.from'
  },
  closureSummaryTo: {
    defaultMessage: 'Do:',
    id: 'closures.summary.to'
  },
  closureSummaryConfirm: {
    defaultMessage: 'Potvrdiť sumárnu uzávierku',
    id: 'closures.summary.confirm'
  },
  closureSummaryMultipleCreating: {
    defaultMessage: 'Vytvára sa uzávierka',
    id: 'closures.summaryMultiple.creating'
  },
  closureSummaryMultipleCreated: {
    defaultMessage: 'Uzávierka bola vytvorená',
    id: 'closures.summaryMultiple.created'
  },
  closureSummaryMultipleCashiers: {
    defaultMessage: 'Sumárna uzávierka za viac kás',
    id: 'closures.summaryMultiple.cashiers'
  },
  closureSummaryMultipleCashiersTitle: {
    defaultMessage: 'Kasy',
    id: 'closures.summaryMultiple.cashiersTitle'
  },
  closureSummaryMultipleCashiersNotFound: {
    defaultMessage: 'Nenašli sa vyhovujúce kasy',
    id: 'closures.summaryMultiple.cashiersNotFound'
  },
  closureSummaryMultipleClosuresTitle: {
    defaultMessage: 'Uzávierky',
    id: 'closures.summaryMultiple.ClosuresTitle'
  },
  closureSummaryMultipleClosuresNotFound: {
    defaultMessage: 'Nenašli sa vyhovujúce uzávierky',
    id: 'closures.summaryMultiple.closuresNotFound'
  },
  closureSummaryMultipleChooseContinue: {
    defaultMessage: 'Pokračovať na výber uzávierok',
    id: 'closures.summaryMultiple.chooseContinue'
  },
  closureSummaryMultipleConfirmClosure: {
    defaultMessage: 'Potvrdiť uzávierku',
    id: 'closures.summaryMultiple.confirmClosure'
  },
  closureSummaryMultipleBackToCashiers: {
    defaultMessage: 'Späť na výber kás',
    id: 'closures.summaryMultiple.backToCashiers'
  },
  closurePrintPreviewSend: {
    defaultMessage: 'Uzávierka bola odoslaná na tlač',
    id: 'closures.printPreview.send'
  },
  closurePrintPreviewPrint: {
    defaultMessage: 'Vytlačiť',
    id: 'closures.printPreview.print'
  },
  closurePrintPreviewChoosePrinter: {
    defaultMessage: 'Vybrať tlačiareň',
    id: 'closures.printPreview.choosePrinter'
  },
  closurePrintPreviewPrinting: {
    defaultMessage: 'Prebieha tlač',
    id: 'closures.printPreview.printing'
  },
});
