// @flow
import React from 'react';
import Box from '../../common/components/Box';
import Arrow from '../../common/components/Arrow';
import { findDOMNode } from 'react-dom';
import onScroll from '../lib/onScroll';

type ContextMenuProps = {
  belongsTo: React.Element<*>,
  boundaries?: React.Element<*>,
  scrollSelector?: string,
  side: string,
  children?: any,
  marginHorizontal?: number,
  marginVertical?: number,
  backgroundColor?: string,
  onPress?: Function,
  isOrderSheetContextMenu?: boolean
}

class ContextMenu extends React.Component {
  props: ContextMenuProps;

  constructor(props) {
    super(props);

    this.state = {
      enabled: false,
    };

    this.observer = new MutationObserver(() => {
      this._computeDimensions();

      if (!props.scrollSelector) {
        this.scrollIsSetup = true;
        return;
      }

      if (!this.scrollIsSetup) {
        const scrollEl = document.querySelector(props.scrollSelector);

        if (!scrollEl) return;

        onScroll(scrollEl, () => {
          this._computeDimensions();
        });

        this.scrollIsSetup = true;
      }
    });

    this.observer.observe(document.body, {
      childList: true, subtree: true
    });
  }

  componentDidMount() {
    this._computeDimensions();
  }

  componentWillUnmount() {
    this._stopObserving();
  }

  _computeDimensions() {
    const { belongsTo, boundaries, side = 'right', isOrderSheetContextMenu } = this.props;

    const belongsToEl = findDOMNode(belongsTo);

    if (!belongsToEl) {
      this.setState({
        enabled: false
      });

      return;
    }

    const { left, width, height } = belongsToEl.getBoundingClientRect();
    const myselfEl = findDOMNode(this.wrappedChildrenComp);

    if (!myselfEl) {
      this.setState({
        enabled: true,
      });

      return;
    }

    const { height: myselfHeight, width: myselfWidth } = myselfEl.getBoundingClientRect();

    const offset = this._getOffset();
    let top = (height * 0.5) - (myselfHeight / 2) + offset;

    let { marginHorizontal = 20 } = this.props;
    let newSide;

    if (isOrderSheetContextMenu) {
      top *= 0.45;
      marginHorizontal = 5;
    }

    if (boundaries) {
      const boundariesEl = findDOMNode(boundaries);
      const { width: boundariesWidth, y } = boundariesEl.getBoundingClientRect();

      // Detect if contextmenu has enough space on that side
      if (side === 'right') {
        newSide = left + width + marginHorizontal + myselfWidth <= boundariesWidth ? 'right' : 'left';
      }

      if (side === 'left' || newSide === 'left') {
        // if it doesnt fit on newSide left, then keep it on the right
        newSide = left - myselfWidth - marginHorizontal >= 0 ? 'left' : 'right';
      }
    }

    this.setState({
      position: 'absolute',
      top,
      left: newSide === 'right' ? width + marginHorizontal : -myselfWidth - marginHorizontal,
      enabled: true,
      side: newSide
    });
  }

  _getOffset() {
    const scrollEl = document.querySelector(this.props.scrollSelector);

    if (!scrollEl) return 0;

    const belongsToEl = findDOMNode(this.props.belongsTo);
    const { height: belongsToHeight } = belongsToEl.getBoundingClientRect();

    const myselfEl = findDOMNode(this.wrappedChildrenComp).parentElement;
    const { height: myselfHeight } = myselfEl.getBoundingClientRect();

    const myselfTop = belongsToEl.offsetTop - ((myselfHeight - belongsToHeight) / 2);

    const myselfBottom = belongsToEl.offsetTop + belongsToHeight
      + ((myselfHeight - belongsToHeight) / 2);

    const { marginVertical = 20 } = this.props;

    const topOffset = scrollEl.scrollTop - myselfTop + marginVertical;
    const bottomOffset = scrollEl.scrollTop + scrollEl.offsetHeight - myselfBottom
      - marginVertical;

    if (topOffset > 0) {
      return topOffset;
    } else if (bottomOffset < 0) {
      return bottomOffset;
    }

    return 0;
  }

  _stopObserving() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    const { children, backgroundColor = 'popupBg', onPress } = this.props;
    const { enabled, position, top, left, side } = this.state;

    if (!enabled) return null;

    return (
      <Box
        position={position || 'absolute'}
        left={(left && left !== 'auto') ? `${left}px` : 'auto'}
        style={{ transform: `translateY(${top}px)` }}
        onClick={onPress} // only in browser: div knows onClick
      >
        <WrappedChildren // this is due to ref. It doesnt work if passed to Box (has to be class)
          ref={c => { this.wrappedChildrenComp = c; }}
          paddingVertical={0.75}
          paddingHorizontal={1}
          backgroundColor={backgroundColor}
          boxShadow="default"
          borderRadius="normal"
          borderColor={backgroundColor}
          borderWidth={1}
          borderStyle="solid"
        >
          {children}
        </WrappedChildren>
        <Arrow
          orientation={side === 'left' ? 'to-right' : 'to-left'}
          backgroundColor={backgroundColor}
        />
      </Box>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function,react/no-multi-comp
class WrappedChildren extends React.Component {
  render() {
    return <Box {...this.props}>{this.props.children}</Box>;
  }
}

export default ContextMenu;
