// @flow
import Box from '../../common/components/Box';
import React from 'react';

type CanvasProps = {
  bgType: string,
  style?: Function,
  children: any
};

const Canvas = ({ bgType, children, style, ...props }: CanvasProps) => (
  <Box
    {...props}
    style={theme => {
      const bgStyle = {};
      const gridSize = theme.grid.size;
      const gridBigSize = theme.grid.bigSize;

      if (bgType === 'solid') {
        bgStyle.backgroundColor = theme.colors.appBg;
      } else if (bgType === 'grid') {
        bgStyle.backgroundColor = theme.colors.gridBg;
        bgStyle.backgroundImage = `
          linear-gradient(${theme.colors.gridThick} 1px, transparent 1px),
          linear-gradient(90deg, ${theme.colors.gridThick} 1px, transparent 1px),
          linear-gradient(${theme.colors.grid} 1px, transparent 1px),
          linear-gradient(90deg, ${theme.colors.grid} 1px, transparent 1px)`;
        bgStyle.backgroundSize = `
          ${gridBigSize} ${gridBigSize},
          ${gridBigSize} ${gridBigSize},
          ${gridSize} ${gridSize},
          ${gridSize} ${gridSize}`;

        bgStyle.backgroundPosition = '-1px -1px, -1px -1px, -1px -1px, -1px -1px';
      }

      if (style) {
        return { ...style(theme), ...bgStyle };
      }

      return bgStyle;
    }}
  >
    {children}
  </Box>
);

export default Canvas;
