// @flow
import { defineMessages } from 'react-intl';

// deprecated
export default defineMessages({
  chooseSubTable: {
    defaultMessage: 'Vyberte hosťa, na ktorého chcete presunúť položky',
    id: 'order.notifications.chooseSubTable'
  },
  chooseFoodCourse: {
    defaultMessage: 'Vyberte chod, na ktorý chcete presunúť položky',
    id: 'order.notifications.chooseFoodCourse'
  },
  chooseTable: {
    defaultMessage: 'Vyberte stôl, na ktorý chcete presunúť položky',
    id: 'order.notifications.chooseTable'
  },
});
