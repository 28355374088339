// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  serviceAreas: {
    id: 'app.titles.serviceAreas',
    defaultMessage: 'Miestnosti'
  },
  order: {
    id: 'app.titles.order',
    defaultMessage: 'Objednávka'
  },
  setup: {
    id: 'app.titles.setup',
    defaultMessage: 'Nastavenia zariadenia'
  },
  admin: {
    id: 'app.titles.admin',
    defaultMessage: 'Admin'
  }
});
