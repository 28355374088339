// @flow
import { reduce, append, find, propEq, reject, prop } from 'rambda';
import { unionWith, eqBy } from 'ramda';
import NavigationActions from 'react-navigation/src/NavigationActions';
import StackActions from 'react-navigation/src/routers/StackActions';

// useful when connecting stuff like {bla: state.bla || []). Perf.
export const emptyArray = [];

export const createDirtyList = (object: Object, dirtyProps: string[], saveOldValues = true) =>
  reduce((acc, prop) => {
    if (!(prop in object)) {
      return acc;
    }

    return saveOldValues
      ? append({ prop, oldValue: object[prop] }, acc)
      : append({ prop }, acc);
  }, [], dirtyProps);

export const getValueFromDirtyList = (prop: string, dirtyList: Object[]) => {
  const dirtyItem = find(propEq('prop', prop), dirtyList || []);
  return dirtyItem ? dirtyItem.oldValue : undefined;
};

export const removeFromDirtyList = (prop: string | string[], dirtyList: Object[]) => {
  const props = Array.isArray(prop) ? prop : [prop];

  return reduce(
    (acc, prop) => reject(propEq('prop', prop), acc),
    dirtyList,
    props
  );
};

// list1 overrides list2
export const mergeDirtyList = (list1: [], list2: []) =>
  unionWith(eqBy(prop('prop')), list2 || [], list1 || []);

export const dirtyListHasProp = (prop: string, dirtyList: Object[]) =>
  Array.isArray(dirtyList)
    ? !!find(item => (item.prop === prop) && ('oldValue' in item), dirtyList)
    : false;

export const redirectNavigation = (location, redirectForward) => {
  if (process.env.IS_REACT_NATIVE === '1') {
    return redirectForward
      ? NavigationActions.navigate({ routeName: location, key: location })
      : StackActions.reset({
          index: 0,
          key: null,
          actions: [
            NavigationActions.navigate({ routeName: location, key: location })
          ]
        });
      // eslint-disable-next-line no-else-return
  } else {
    const { replace } = require('connected-react-router');
    return replace(location);
  }
};

// exclude most of "meta" keys
export const excludedMetaKeys = event =>
  event.key !== 'Shift'
  && event.key !== 'Alt'
  && event.key !== 'Meta'
  && event.key !== 'Ctrl'
  && event.key !== 'CapsLock'
  && event.key !== 'ArrowLeft'
  && event.key !== 'ArrowRight'
  && event.key !== 'ArrowUp'
  && event.key !== 'ArrowDown'
  && event.key !== 'PageUp'
  && event.key !== 'PageDown'
  && event.key !== 'Delete'
  && event.key !== 'Backspace'
  && event.key !== 'Control'
  && event.key !== 'Tab';

export const replaceSKKeyCharsWithNums = text => {
  const mapObj = {
    'é': '0',
    '+': '1',
    'ľ': '2',
    'ě': '2',
    'š': '3',
    'č': '4',
    'ř': '5',
    'ť': '5',
    'ž': '6',
    'ý': '7',
    'á': '8',
    'í': '9'
  };

  const regString = Object.keys(mapObj).map(key => `[${key}]`).join('|');
  const reg = new RegExp(regString, 'gi');
  return text.replace(reg, matched => mapObj[matched]);
};

const errUtil = (error, msg) => {
  error.message = `${msg} ${error.message}`;
  return error;
};

export const clearFsStore = async (storeName) => {
  const RNFS = require('react-native-fs');
  const fileName = `${RNFS.DocumentDirectoryPath}/${storeName}.rnjs`;

  try {
    const file = await RNFS.exists(fileName);
    if (file) {
      await RNFS.unlink(fileName);
    }
  } catch (error) {
    throw errUtil(error, 'error in clearing storage');
  }
};

export const sortByStringParameter = (sortingArray, parameterName) =>
  sortingArray.sort((a, b) => {
    const nameA = a[parameterName] && a[parameterName].toUpperCase();
    const nameB = b[parameterName] && b[parameterName].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
