// @flow
import type { OpenTable, State } from '../../../common/types';
import React from 'react';
import { compose, find, propEq, filter, pluck } from 'rambda';
import moment from 'moment';
import Box from '../../../common/components/Box';
import Icon from '../../../common/components/Icon';
import Text from '../../../common/components/Text';
import { connect } from 'react-redux';
import {FormattedTime, injectIntl} from 'react-intl';
import Button from '../../../common/components/Button';
import { displayEllipsis } from '../../themes/utils';
import {
  activeOpenTableSelector,
  activeTableNameSelector,
  subTablesForActiveOpenTableSelector
} from '../../../common/tables/selectors';
import Blocker from '../../components/Blocker';
import { replace } from 'connected-react-router';
import { activateArea } from '../../../common/serviceAreas/actions';
import exitOrderPage from '../../../common/order/hoc/exitOrderPage';
import guard from '../../../common/components/hoc/guard';
import invariant from 'invariant';
import {
  deleteAllSelectedOrderLines,
  getAllOrderLinesOfOpenTable
} from '../../../common/order/utils';
import { undeletedOrderLinesSelector } from '../../../common/order/selectors';
import messages from '../../../common/messages/order';

const padZero = (num) => (num < 10) ? `0${num}` : num;

class Generic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      duration: this._computeDuration(),
      showBlocker: false,
      showLocalOrderlinesBlocker: false
    };
  }

  _computeDuration() {
    const { activeOpenTableId, openTables } = this.props;
    const openTable: OpenTable = find(propEq('id', activeOpenTableId), openTables);

    if (!openTable) return undefined;

    const duration = Math.max(0, Date.now() - +moment(openTable.openedTime));

    return duration;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const duration = this._computeDuration();
      this.setState({ duration });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  _goToServiceArea = (serviceAreaId) => {
    const { dispatch } = this.props;

    dispatch([
      replace('/serviceAreas'),
      activateArea(serviceAreaId),
    ]);
  };

  _hideBlocker = () => {
    this.setState({ showBlocker: false });
  };

  _onExitPage = () => {
    const { onExitPage, openTable } = this.props;
    const { showBlocker, showLocalOrderlinesBlocker } = this.state;

    onExitPage({
      redirect: () => this._goToServiceArea(openTable.serviceAreaId),
      promptExit: () => this.setState({ showBlocker: true }),
      promptExit2: () => this.setState({ showLocalOrderlinesBlocker: true }),
    }, showBlocker, showLocalOrderlinesBlocker);
  };

  _deleteLocals = () => {
    const { dispatch, orderLines, subTables, activeOpenTableId } = this.props;

    const localOrdelineIds = pluck('id',
      filter(
        ol => ol._local && !ol.isSentToKitchen,
        getAllOrderLinesOfOpenTable(subTables, orderLines, activeOpenTableId)
      ) || []);

    // dispatch(deleteOrderLinesCommit(localOrdelineIds));
    deleteAllSelectedOrderLines(dispatch, orderLines, localOrdelineIds);
    setTimeout(
      () => this._onExitPage(),
      30
    );
  };

  render() {
    const { duration, showBlocker, showLocalOrderlinesBlocker } = this.state;
    const { tableName, intl } = this.props;
    const momentDuration = moment.duration(duration);
    const hours = momentDuration.hours();
    const minutes = momentDuration.minutes();
    const seconds = momentDuration.seconds();

    return (
      <Box width="100%" backgroundColor="lessDarkGray" paddingVertical={0.5}>
        <Box paddingHorizontal={1} flexDirection="row" justifyContent="space-between">
          {showBlocker &&
            <Blocker
              actionConfirm={this._onExitPage}
              actionCancel={this._hideBlocker}
              textHeader={intl.formatMessage(messages.orderGenericCancelOrderHeader)}
              textDescription={intl.formatMessage(messages.orderGenericCancelOrderDescription)}
              textConfirm={intl.formatMessage(messages.orderGenericCancelOrderConfirm)}
              textCancel={intl.formatMessage(messages.orderGenericCancelOrderCancel)}
            />}
          {showLocalOrderlinesBlocker &&
            <Blocker
              actionConfirm={this._deleteLocals}
              actionCancel={this._onExitPage}
              textHeader={intl.formatMessage(messages.orderGenericLocalItemsRemoveHeader)}
              textDescription={intl.formatMessage(messages.orderGenericLocalItemsRemoveDescription)}
              textConfirm={intl.formatMessage(messages.orderGenericLocalItemsRemoveConfirm)}
              textCancel={intl.formatMessage(messages.orderGenericLocalItemsRemoveCancel)}
            />}

          <Box alignItems="center" flexDirection="row">
            <Icon name="clock" color="white" bold scale={1} />
            <Text color="white" bold marginLeft={0.25} flexDirection="row">
              {hours}:{padZero(minutes)}:{padZero(seconds)}
            </Text>
          </Box>

          <Button flexDirection="row" onPress={this._onExitPage} maxWidth="33%" justifyContent="flex-start">
            <Box borderRadius="50%" position="absolute" top={-0.6} width={2.2} height={2.2} backgroundColor="white" overflow="hidden" alignItems="center" justifyContent="center">
              <Icon name="close" size={1} color="black" justifyContent="center" />
            </Box>
            <Text bold marginLeft={2.5} color="white" style={displayEllipsis}>{`${intl.formatMessage(messages.orderGenericCloseTable)} ${tableName.replace('\n', ' ')}`}</Text>
          </Button>

          <FormattedTime
            hour="numeric"
            minute="numeric"
            value={new Date()}
          >
            {time => <Text color="white" bold alignItems="center">{time}</Text>}
          </FormattedTime>
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    activeOpenTableId: state.tables.active.openTableId,
    openTables: state.tables.openTables,
    priceLevels: state.items.priceLevels,
    tableName: activeTableNameSelector(state),
    openTable: activeOpenTableSelector(state),
    subTables: subTablesForActiveOpenTableSelector(state),
    orderLines: undeletedOrderLinesSelector(state),
  })),
  exitOrderPage,
  guard(({ openTable }) =>
    invariant(openTable, 'missing openTable in Generic topbar')),
  injectIntl
)(Generic);
