// @flow

const onScroll = (el, cb) => {
  let ticking;

  el.addEventListener('scroll', e => {
    if (!ticking) {
      // scroll event on every frame per second
      window.requestAnimationFrame(() => {
        cb(e);
        ticking = false;
      });
    }

    ticking = true;
  });
};

export default onScroll;
