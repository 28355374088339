// @flow
import React from 'react';
import Box from '../../../common/components/Box';
import ComposedButton from '../../../common/components/ComposedButton';
import UsersSheet from './UsersSheet';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import type { State } from '../../../common/types';
import api from '../../../common/lib/api';
import Text from '../../../common/components/Text';
import {
  preopeningPrepaidItemsPopup,
  toggleNewCardPopup,
  updatePaymentTransaction
} from '../../../common/payment/actions';
import { processReceivedPaymentTransactionData } from '../../../common/payment/utils';
import { toggleOrderLineSelect, addError } from '../../../common/order/actions';
import ChooseCardPopup from './ChooseCardPopup';
import { isEmpty } from 'ramda';
import { compose, pathOr } from 'rambda';
import { findParamValue } from '../../../common/parameters/utils';
import { injectIntl } from 'react-intl';
import loyalmanMessages from '../../../common/messages/loyalman';


class Sidebar extends React.Component {
  state = {
    scrollContainerId: 'OrderLineSheetScrollContainer',
    userCards: false
  };

  addCardPopup = () => {
    const { dispatch, selectedOrderLines, activeLoyalmanUser } = this.props;

    dispatch([
      toggleNewCardPopup(activeLoyalmanUser),
      toggleOrderLineSelect(selectedOrderLines, { absoluteForceSelect: true }),
      replace('/payment')
    ]);
  };

  togglePopup = open => {
    this.setState({ userCards: open });
  };

  selectUser = () => {
    const { dispatch, paymentTransactionId, activeLoyalmanUser, selectedOrderLines } = this.props;

    if (!isEmpty(pathOr([], 'cards', activeLoyalmanUser))) {
      const userCards = activeLoyalmanUser.cards;

      if (userCards.length === 1) {
        const loyalmanFirstCard = activeLoyalmanUser.cards[0];
        const loyalmanCard = loyalmanFirstCard.code;

        this.addUserToPaymentTransaction(dispatch, selectedOrderLines, paymentTransactionId, loyalmanCard);
      } else if (userCards.length > 1) {
        this.togglePopup(userCards);
      }
    }
  };

  addUserToPaymentTransaction = (dispatch, selectedOrderLines, paymentTransactionId, loyalmanCard) => {
    api.payment.addCardDiscount(paymentTransactionId, loyalmanCard)
      .then(({ body }) => {
        dispatch([
          updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
          toggleOrderLineSelect(selectedOrderLines, { absoluteForceSelect: true }),
          preopeningPrepaidItemsPopup(),
          replace('/payment')
        ]);
      })
      .catch(e => {
        console.log('Card Discount - some catch took place: ', e);
        dispatch(addError(e));
      });
  };

  render() {
    const { activeLoyalmanUser, parameters, selectedOrderLines, parentFilter, intl } = this.props;
    const { userCards } = this.state;
    const loyalmanFullUsersInfo = findParamValue('K32.loyalman_full_users_info', parameters) || false;

    const activeUserCards = pathOr([], 'cards', activeLoyalmanUser);
    const userHasActiveCard = !isEmpty(activeUserCards) && activeUserCards.some(e => e.status.toLowerCase() === 'active');

    return (
      <Box
        backgroundColor="appBg"
        height={(loyalmanFullUsersInfo || activeLoyalmanUser) ? '100%' : '300px'}
        flexDirection="column"
        justifyContent="space-between"
        paddingBottom={0.5}
      >

        {(selectedOrderLines && userCards) &&
          <ChooseCardPopup
            closePopup={() => this.togglePopup(false)}
            userCards={userCards}
            addUserToPaymentTransaction={this.addUserToPaymentTransaction}
          />}

        <Box
          zIndex={1}
          flexShrink={0}
          flexGrow={selectedOrderLines ? 0 : 0.8 }
          paddingTop={1}
          paddingHorizontal={1}
          height={selectedOrderLines ? '70vh' : '80vh' }
        >
          <UsersSheet parentFilter={parentFilter} />
        </Box>

        {(selectedOrderLines && (!loyalmanFullUsersInfo ? activeLoyalmanUser : true)) &&
          <Box
            paddingTop={0}
            paddingBottom={0.25}
            paddingHorizontal={0.75}
            zIndex={10}
            flexShrink={0}
            flexDirection="row"
          >
            <Box
              flexGrow={1}
              flexShrink={0}
              flexBasis={1}
              marginHorizontal={0.25}
            >
              <ComposedButton
                disabled={!activeLoyalmanUser || activeLoyalmanUser.status.toLowerCase() !== 'active' || !userHasActiveCard}
                caption={intl.formatMessage(loyalmanMessages.loyalmanConfirmUser)}
                backgroundColor="blue"
                shadowColor="darkBlue"
                icon="pay"
                onClick={this.selectUser}
              />
            </Box>
          </Box>}

        {(selectedOrderLines && (!loyalmanFullUsersInfo ? activeLoyalmanUser : true)) &&
          <Box
            paddingTop={0}
            paddingBottom={0.25}
            paddingHorizontal={0.75}
            zIndex={10}
            flexShrink={0}
            flexDirection="row"
          >
            <Box
              flexGrow={1}
              flexShrink={0}
              flexBasis={1}
              marginHorizontal={0.25}
            >
              <ComposedButton
                disabled={!activeLoyalmanUser || activeLoyalmanUser.status.toLowerCase() !== 'active'}
                caption={intl.formatMessage(loyalmanMessages.loyalmanGetCard)}
                backgroundColor="darkGray"
                shadowColor="black"
                icon={<Text color="white" bold scale={4}>+</Text>}
                onClick={this.addCardPopup}
              />
            </Box>
          </Box>}
      </Box>
    );
  }
}

export default compose(
  connect(
    (state: State) => ({
      parameters: state.parameters.parameters,
      selectedReceiptItems: state.admin.receipts.selectedReceiptItems,
      activeOpenTableId: state.tables.active.openTableId,
      activeReceipt: state.admin.receipts.active.receipt,
      openTables: state.tables.openTables,
      activeLoyalmanUser: state.loyalman.users.active.user,
      paymentTransactionId: state.loyalman.users.paymentTransactionId,
      isLoyalManOn: state.loyalman.users.isLoyalManOn,
      selectedOrderLines: state.loyalman.users.selectedOrderLines
    })
  ),
  injectIntl
)(Sidebar);
