// @flow
import type { Action, Discount, Id, Payment } from '../types';

export type PaymentObject = {
  payments: Payment[],
  appliedDiscounts: Discount[],
  appliedOperations: Object[],
  availableDiscounts: Id[],
  availablePaymentMedia: Id[],
  stats: {
    overallSum: number,
    overallSumAfterDiscount: number,
    leftToPay: number,
    overallTip: number,
    overpay: number,
    allowTip: boolean
  },
  paymentTransactionId: Id,
  operations: Object[],
  loyalman: Object,
  paymentTransactionId: Id,
  availablePrinters: Object[],
  defaultPrinter: Object,
  copies: number
};

export const updatePaymentTransaction = (paymentObject: PaymentObject): Action => ({
  type: 'PAYMENT_TRANSACTION_UPDATE',
  payload: paymentObject
});

export const clearPaymentTransaction = (paymentTransactionId: Id) => ({
  type: 'PAYMENT_TRANSACTION_CLEAR',
  payload: { paymentTransactionId } // need for epic
});

export const toggleDisableForm = (disable: boolean) => ({
  type: 'PAYMENT_TOGGLE_DISABLE_FORM',
  payload: { disable }
});

export const toggleNewCardPopup = (open: boolean) => ({
  type: 'PAYMENT_TOGGLE_NEW_CARD_FORM',
  payload: { open }
});

export const toggleNewCardPopupFirstStep = (is: boolean) => ({
  type: 'PAYMENT_TOGGLE_NEW_CARD_FORM_FIRST_STEP',
  payload: { is }
});

export const togglePrepaidItemsPopup = (open: boolean) => ({
  type: 'PAYMENT_TOGGLE_CHOOSE_PREPAID_ITEMS_FORM',
  payload: { open }
});

export const preopeningPrepaidItemsPopup = () => ({
  type: 'PAYMENT_PREOPENING_PREPAID_ITEMS_FORM'
});

export const togglePaymentRecordForm = (payment: boolean) => ({
  type: 'PAYMENT_TOGGLE_PAYMENT_RECORD_FORM',
  payload: { payment }
});

export const showPrgDotaz = (prgDotaz, payment) => ({
  type: 'PAYMENT_SHOW_PRG_DOTAZ',
  payload: { prgDotaz, payment }
});

export const hidePrgDotaz = () => ({
  type: 'PAYMENT_HIDE_PRG_DOTAZ'
});

export const toggleNotePopupOpen = (toggle) => ({
  type: 'PAYMENT_TOGGLE_NOTE_POPUP',
  payload: { toggle }
});

export const setPrgFilterValue = (value: string) => ({
  type: 'PAYMENT_SET_PRG_FILTER_VALUE',
  payload: {
    value
  }
});

export const toggleServiceCouponPopup = (is: boolean | Object) => ({
  type: 'PAYMENT_SERVICE_COUPON_POPUP',
  payload: { is }
});

export const toggleSendReceiptByEmailPopup = (is: boolean | Object) => ({
  type: 'PAYMENT_SEND_RECEIPT_BY_EMAIL_POPUP',
  payload: { is }
});

export const selectPrinter = (printer: Object) => ({
  type: 'PAYMENT_SELECT_PRINTER',
  payload: { printer }
});

export const setNumberOfCopies = (number: Object) => ({
  type: 'PAYMENT_SET_NUMBER_OF_COPIES',
  payload: { number }
});

export const toggleTerminalPaymentLoading = (terminal: boolean, onTerminalMessage: any) => ({
  type: 'ORDER_TERMINAL_PAYMENT_TOGGLE_LOADING',
  payload: { terminal, onTerminalMessage}
});

export const terminalPaymentSocketMessageLoading = (message: string) => ({
  type: 'TERMINAL_PAYMENT_SOCKET_MESSAGE_LOADING',
  payload: { terminalMessage: message }
});
