// @flow
import React from 'react';

const remoteLoad = (endpoint: () => Promise) => BaseComponent => class extends React.PureComponent {
  state = {
    payload: undefined,
    error: '',
    isLoading: true
  };

  componentDidMount() {
    endpoint().then(({ body }) => {
      this.setState({ payload: body, isLoading: false });
    }).catch(error => {
      this.setState({ error, isLoading: false });
    });
  }

  render() {
    const { payload, error, isLoading } = this.state;

    return (
      <BaseComponent
        {...this.props}
        remotePayload={payload}
        remoteError={error.toString()}
        remoteIsLoading={isLoading}
      />
    );
  }
};

export const remoteLoadFromProps = (endPoint: () => Promise, propsToParams: () => []) => BaseComponent =>
  class extends React.PureComponent {
    constructor(props) {
      super(props);

      const params = propsToParams(props) || [];
      this.RemoteLoadComponent = remoteLoad(() => endPoint(...params))(BaseComponent);
    }

    render() {
      const RemoteLoadComponent = this.RemoteLoadComponent;
      return <RemoteLoadComponent {...this.props} />;
    }
  };

export default remoteLoad;
