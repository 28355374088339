// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';
import type { Id } from '../../types';

export const addOpenTable = (openTable: Object): Promise =>
  fetch(apiEndpoint('v1/OpenTable'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        table: openTable,
        numberOfCovers: 1,
      },
      name: 'OpenTable',
      id: '',
      session_id: '',
      device_id: ''
    }
  });

export const deleteOpenTable = (id: Id): Promise =>
  fetch(apiEndpoint(`v1/DeleteTable/${id}`), {
    method: 'DELETE',
    baseURL: getApiUrl(),
  });
