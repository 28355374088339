// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';

export const sync = (): Promise =>
  fetch(apiEndpoint('v1/sync'), {
    baseURL: getApiUrl()
  });

export const blitzSync = (): Promise =>
  fetch(apiEndpoint('v1/sdata'), {
    baseURL: getApiUrl(),
  });
