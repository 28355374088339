// @flow
import type {Device} from '../index';

const protocolFactory = (device: Device) => {
  const ProtocolPrototype = function (device: Device) {
    const weightRegexp = /ST,.{2},(?<amount>[0-9. -]{8})kg/;

    let isWaitingForResponse = false;
    let currentString = '';
    let responseBuffer = '';

    this.prepareWeight = () => {
      if (!isWaitingForResponse) {
        isWaitingForResponse = true;
      }
    };

    this.setResponse = (buffer: Buffer) => {
      const res = buffer.toString('ascii');
      responseBuffer += res;
      const matches = responseBuffer.match(weightRegexp);

      if (matches && matches.groups.amount !== undefined) {
        currentString = matches.groups.amount.replace(/\s/g, '');
        responseBuffer = '';
      } else if (responseBuffer.length > 100) {
        responseBuffer = '';
      }
    };

    this.getLastWeight = () => {
      if (!currentString) return null;
      return parseFloat(currentString);
    };
  };

  return new ProtocolPrototype(device);
};

export default protocolFactory;
