// let storage;

// if (process.env.IS_ELECTRON === '1') {
//   const NodeStorage = require('redux-persist-storage-node').default;
//   todo when electron app is bundled, is this path ok? I guess not.
  // storage = new NodeStorage(require('path').resolve('./store.json'));
// } else {
const storage = require('localforage');
// }

export default () => storage;
