// @flow
import React from 'react';
import { isReactNative } from '../../app/detectPlatform';
import Box from '../Box';

const TopDecoration = () => (
  <Box
    paddingHorizontal={1.25}
    backgroundColor="white"
    flexDirection="row"
    justifyContent="space-between"
    height={1.5}
    zIndex={10}
    flexShrink={0}
  >
    {[...Array(14)].map((x, i) =>
      <Box
        key={i}
        backgroundColor="#929292"
        height={0.5}
        width={0.5}
        marginTop={0.5}
      >
        <Box
          backgroundColor="darkGray"
          height={2 / 3}
          width={1 / 6}
          marginTop={-0.5}
          marginLeft={1 / 6}
        />
      </Box>
    )}
  </Box>
);

class OrderLineSheetDecorations extends React.PureComponent {
  render() {
    const { children, ...restProps } = this.props;
    return (
      <Box
        backgroundColor="lighterGray"
        boxShadow={isReactNative ? undefined : 'orderLineSheet'}
        marginBottom={isReactNative ? 0 : '8px'}
        height={isReactNative ? '100%' : 0}
        {...restProps}
      >
        {isReactNative ? null : <TopDecoration />}
        {children}
      </Box>
    );
  }
}

export default OrderLineSheetDecorations;
