// @flow
import type { State } from '../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import Title from '../components/Title';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Icon from '../../common/components/Icon';
import Popup from '../components/Popup';
import PopupWeightForm from './PopupWeightForm';
import PopupPriceOrQuantityForm from './PopupPriceOrQuantityForm';
import Sidebar from './Sidebar';
import MenuItemsList from './MenuItemsList';
import titlesMessages from '../../common/app/messages/titles';
import OrdersTopBar from './TopBar';
import SidebarSpace from '../components/Sidebar';
import Tables from '../components/Tables';
import Notification from '../components/Notification';
import NotificationPlace from './NotificationPlace';
import { clearError, setFilterValue } from '../../common/order/actions';
import { setPrgFilterValue } from '../../common/payment/actions';
import { injectParametersToPeripherals, startWeighting } from '../peripherals/service';
import { isElectron } from '../../common/app/detectPlatform';
import { findParamValue } from '../../common/parameters/utils';
import { setWeightToConnected } from '../../common/peripherals/actions';
import PopupFastFoodMenuForm from './PopupFastFoodMenuForm';
import AvailabilityBlocker from './AvailabilityBlocker';
import PopupTextForm from './PopupTextForm';
import theme from '../themes/defaultTheme';
import { getAllErrors } from '../../common/admin/receipts/selectors';
import { transactionAndOtherErrors } from '../../common/payment/utils';
import TransactionErrorPromptWrapper from './TransactionErrorPromptWrapper';
import { processError as processOrderError } from '../../common/order/utils';
import { map, compose, filter } from 'rambda';
import PrgDotazWrapper from './PrgDotazWrapper';
import bindScreenLifecycleActions from '../components/hoc/bindScreenLifecycleActions';
import ordersNavigatorActions from '../../common/order/screenActions';
import ScrollButtons from './ScrollButtons';
import PopupUnfinishedBill from '../admin/receipts/PopupUnfinishedBill';
import messages from '../../common/messages/order';
import { FormattedMessage, injectIntl } from 'react-intl';
import AdminNotification from '../admin/AdminNotification';

const AddingItemCountPopup = ({ count, zIndex }) => (
  <Box
    backgroundColor="inactiveBg"
    zIndex={zIndex}
    style={theme => ({
      position: 'absolute',
      width: '100vw',
      top: -theme.typography.lineHeight() * 2,
      bottom: 0,
      left: 0,
    })}
    onMouseDown={(e) => e.stopPropagation()}
  >
    <Popup
      showClose={false}
      backgroundColor="white"
      color="popupBg"
      width={9}
    >
      <Text bold color="popupBg" alignItems="center"><FormattedMessage {...messages.orderPageCount} /></Text>
      <Text bold color="popupBg" alignItems="center" scale={10} paddingTop={0.5}>{count}</Text>
      <Box position="absolute" bottom={-6} left={0} width={9} alignItems="center">
        <Icon name="swipe-right" size={5} color="white" justifyContent="center" />
      </Box>
    </Popup>
  </Box>);

class OrderPage extends React.PureComponent {
  componentDidMount() {
    const { dispatch, isWeightConnected, parameters } = this.props;
    const vahProtocol = findParamValue('K32.vah_protocol', parameters);

    const peripheralConfig = {
      stopBit: findParamValue('K32.vah_stopb', parameters),
      dataBits: findParamValue('K32.vah_datb', parameters),
      parity: findParamValue('K32.vah_parita', parameters),
      path: findParamValue('K32.vah_comm', parameters) || 'COM1',
      baudRate: findParamValue('K32.vah_baud', parameters) || 9600
    };

    if (isElectron && !isWeightConnected) {
      dispatch(setWeightToConnected());
      injectParametersToPeripherals(vahProtocol, peripheralConfig);
      startWeighting(vahProtocol); // TODO do not start if already started
    }
  }

  clearError = () => {
    const { dispatch } = this.props;
    dispatch(clearError());
  };

  // TODO - just temporary solution
  // fixme - because when you came from loyalman to paymant - payment trans. conflict occu
  _tempRemovePaymentConflict = errors => Array.isArray(errors)
    ? filter(er => er.message !== 'Orderline conflicts in payment', errors)
    : errors
      ? errors.message === 'Orderline conflicts in payment'
        ? null
        : errors
      : errors;

  render() {
    const {
      dispatch,
      isRelocatingToTable,
      addingItemCount,
      isWeightFormOpen,
      isFastFoodMenuFormOpen,
      isPriceOrQuantityFormOpen,
      availabilityBlocker,
      error,
      isTextPopupOpen,
      prgDotaz,
      prgFilterValue,
      filterValue,
      orderLines,
      items,
      showScrollButtons,
      isUnfinishedBillPopupOpen,
      parameters,
      intl
    } = this.props;
    const { ongoingTransactionError } = this.props;

    let errors = map(error => processOrderError(error, orderLines, items), error);

    errors = this._tempRemovePaymentConflict(errors);
    // ongoingTransactionError = this._tempRemovePaymentConflict(ongoingTransactionError);

    return (
      <Box flex={1} height="100%">
        <Title message={titlesMessages.order} />

        <Box zIndex={theme.zIndex.topbarLayer}>
          <OrdersTopBar />
        </Box>

        <Box flexDirection="row" zIndex={1} flex={1} height="calc(100% - 36px)">
          <Box flex={1} id="orderCanvas" width="100%">
            {isRelocatingToTable
              ? <Tables />
              : <MenuItemsList />
            }

            <NotificationPlace target="#orderCanvas" />

            {errors.length ?
              <Notification
                text={errors}
                mode="bottom"
                color="white"
                backgroundColor="red"
                showClose
                onClose={this.clearError}
              /> : null}

            {showScrollButtons &&
              <Box position="absolute" bottom={1} left={1}>
                <ScrollButtons containerId="MenuItemsListScrollContainer" vertical />
              </Box>}
          </Box>

          <SidebarSpace>
            <Sidebar />
          </SidebarSpace>
        </Box>

        {addingItemCount > 0 &&
          <AddingItemCountPopup count={addingItemCount} zIndex={theme.zIndex.swiping} />}

        <Box zIndex={theme.zIndex.popupLayer}>
          {isFastFoodMenuFormOpen &&
            <PopupFastFoodMenuForm
              fastFoodMenu={isFastFoodMenuFormOpen}
            />}

          {isPriceOrQuantityFormOpen &&
            <PopupPriceOrQuantityForm />}

          {isWeightFormOpen &&
            <PopupWeightForm />}

          {availabilityBlocker &&
            <AvailabilityBlocker />}

          {isTextPopupOpen && !prgDotaz &&
            <PopupTextForm
              filterValue={filterValue}
              updateFilter={value => dispatch(setFilterValue(value))}
            />}

          {ongoingTransactionError &&
            <TransactionErrorPromptWrapper error={ongoingTransactionError} />
          }
        </Box>

        <PrgDotazWrapper parameters={parameters} />


        {isUnfinishedBillPopupOpen &&
          <PopupUnfinishedBill
            dispatch={dispatch}
            bill={isUnfinishedBillPopupOpen.bill}
            closureId={isUnfinishedBillPopupOpen.closure}
            fuciarkod={isUnfinishedBillPopupOpen.bill_id}
            name={intl.formatMessage(messages.orderPageError)}
          />}

        {isTextPopupOpen && prgDotaz &&
          <PopupTextForm
            filterValue={prgFilterValue}
            updateFilter={value => dispatch(setPrgFilterValue(value))}
          />}
        <AdminNotification />
      </Box>
    );
  }
}

export default compose(
  bindScreenLifecycleActions(ordersNavigatorActions),
  connect((state: State) => {
    const allErrors = getAllErrors(state);
    const [ongoingTransactionError, otherErrors] = transactionAndOtherErrors(allErrors);

    return ({
      isRelocatingToTable: state.orders.active.isRelocatingToTable,
      addingItemCount: state.orders.active.addingItemCount,
      showScrollButtons: state.orders.active.showScrollButtons,
      isWeightFormOpen: state.peripherals.isWeightFormOpen,
      isWeightConnected: state.peripherals.isWeightConnected,
      isFastFoodMenuFormOpen: state.orders.active.isFastFoodMenuFormOpen,
      isPriceOrQuantityFormOpen: state.orders.active.isPriceOrQuantityFormOpen,
      availabilityBlocker: state.orders.availabilityBlocker.show,
      parameters: state.parameters.parameters,
      error: otherErrors,
      ongoingTransactionError: state.loyalman.users.isLoyalManOn ? undefined : ongoingTransactionError, // TODO - just temp, will be removed - fixme - because when you came from loyalman to paymant - payment trans. conflict occured
      prgDotaz: state.payment.prgDotaz,
      filterValue: state.orders.filterValue,
      prgFilterValue: state.payment.prgFilterValue,
      isTextPopupOpen: state.orders.active.isTextPopupOpen,
      orderLines: state.orders.orderLines,
      items: state.items.items,
      isUnfinishedBillPopupOpen: state.admin.receipts.isUnfinishedBillPopupOpen
    });
  }),
  injectIntl
)(OrderPage);
