// @flow
import tinycolor from 'tinycolor2';

const clamp = (n) => {
  if (n < 0) return 0;

  if (n > 255) return 255;

  return Math.floor(n);
};

const rgb2yuv = (rgb) => {
  const y = clamp(rgb.r * 0.29900 + rgb.g * 0.587 + rgb.b * 0.114);
  const u = clamp(rgb.r * -0.16874 + rgb.g * -0.33126 + rgb.b * 0.50000 + 128);
  const v = clamp(rgb.r * 0.50000 + rgb.g * -0.41869 + rgb.b * -0.08131 + 128);

  return { y, u, v };
};

const yuv2rgb = (yuv) => {
  const y = yuv.y;
  const u = yuv.u;
  const v = yuv.v;
  const r = clamp(y + (v - 128) * 1.40200);
  const g = clamp(y + (u - 128) * -0.34414 + (v - 128) * -0.71414);
  const b = clamp(y + (u - 128) * 1.77200);

  return { r, g, b };
};

export const invertToReadableColor = (color) => {
  const rgb = tinycolor(color).toRgb();
  const yuv = rgb2yuv(rgb);
  const factor = 280;
  const threshold = 100;
  yuv.y = clamp(yuv.y + (yuv.y > threshold ? -factor : factor));

  return tinycolor(yuv2rgb(yuv));
};

export const darkenColor = (color) =>
  tinycolor(color).desaturate(40).darken(30);

export const removeAlpha = (color) => tinycolor(color).setAlpha(0);
