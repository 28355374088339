// @flow
import type { Action, Id, DateString } from '../../types';
import api from '../../lib/api';
import { addAdminError, toggleAdminNotificationPopup, toggleAdminLoadingScreen } from '../general/actions';
import { completeOffline } from '../../network/actions';

export const activateReceipt = (receiptId: Id, closureId: Id, date: DateString, selectedMonth, showClosedReceipts, idLimit, unfinished, fuciarkod): Action => ({
  type: 'ADMIN_RECEIPT_ACTIVATE_RECEIPT',
  payload: {
    receiptId,
    closureId,
    date,
    selectedMonth,
    showClosedReceipts,
    idLimit,
    unfinished,
    fuciarkod
  }
});

export const deactivateReceipt = (): Action => ({
  type: 'ADMIN_RECEIPT_DEACTIVATE_RECEIPT',
  payload: {}
});

export const storeReceiptSum = (sum: number): Action => ({
  type: 'ADMIN_RECEIPT_STORE_RECEIPT_SUM',
  payload: { sum }
});

export const toggleReceiptItemSelect = (id: Id, subId: Id) => ({
  type: 'ADMIN_RECEIPT_SELECT_ITEMS',
  payload: { id, subId },
});

export const clearSelectedReceiptItems = (): Action => ({
  type: 'ADMIN_RECEIPT_CLEAR_SELECTED_ITEMS',
  payload: {}
});

export const toggleCancelingReceiptAndReturningToTable = (is: boolean | Object) => ({
  type: 'ADMIN_RECEIPT_IS_CANCELING_RECEIPT_AND_RETURNING_TO_TABLE',
  payload: { is }
});

export const toggleReceiptTipPopup = (is: boolean | Object) => ({
  type: 'ADMIN_RECEIPT_TOGGLE_TIP_POPUP',
  payload: { is }
});

export const toggleUnfinishedBillPopup = (is: boolean | Object) => ({
  type: 'ADMIN_RECEIPT_UNFINISHED_BILL_POPUP',
  payload: { is }
});

export const cancelReceiptAndReturnToTable = (receiptId, closureId, id) =>
  ({
    type: 'ADMIN_CANCEL_RECEIPT_AND_RETURN_TO_TABLE',
    meta: {
      offline: {
        effect: () => api.receipt.cancelReceiptAndReturnToTable(receiptId, closureId, id),
        commit: () => [
          completeOffline(),
          toggleAdminNotificationPopup('Položka účtenky stornovaná a navrátená na stôl'),
          toggleCancelingReceiptAndReturningToTable(false),
          toggleAdminLoadingScreen(false)
        ],
        rollback: ({ payload }) => [
          completeOffline(),
          toggleCancelingReceiptAndReturningToTable(false),
          addAdminError(payload),
          toggleAdminLoadingScreen(false)
        ]
      }
    }
  });
