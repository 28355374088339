// @flow
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../common/components/Box';
import Popup from '../../components/Popup';
import InactiveBg from '../../components/InactiveBg';
import Heading from '../../../common/components/Heading';
import { selectPrinter } from '../../../common/payment/actions';
import { isReactNative } from '../../../common/app/detectPlatform';
import ScrollView from '../../../common/components/ScrollView';
import Button from '../../../common/components/Button';
import Text from '../../../common/components/Text';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/payment';
import {selectServicePrinter} from '../../../common/serviceAreas/actions';


class PrinterSelectPopup extends React.Component {
  selectPrinter = (id) => {
    const { dispatch, onClose } = this.props;

    dispatch(selectServicePrinter(id));
    onClose();
  };

  render() {
    const { availablePrinters = [], onClose, intl } = this.props;
    return (
      <InactiveBg onClose={onClose}>
        <Popup onClose={onClose}>
          <Heading scale={4} color="white" marginBottom={1}>{intl.formatMessage(messages.paymentPrinterSelect)}</Heading>
          <Box
            width={isReactNative ? null : 20}
            maxHeight={isReactNative ? '100%' : '70vh'}
            marginHorizontal={-1}
            flex={1}
          >
            <Box flex={1}>
              <ScrollView maxHeight={isReactNative ? '100%' : '70vh'}>
                {availablePrinters && availablePrinters.map(({ prnNo: id = '', prnName: name = '' }) => (
                  <Button
                    key={id}
                    marginHorizontal={1}
                    backgroundColor="white"
                    paddingVertical={0.5}
                    paddingHorizontal={1}
                    marginBottom={0.5}
                    justifyContent="flex-start"
                    flexShrink={0}
                    onPress={() => this.selectPrinter(id)}
                  >
                    <Box height="100%">
                      <Text>
                        {(name || '').trim()}
                      </Text>
                    </Box>
                  </Button>
                ))}
              </ScrollView>
            </Box>
          </Box>
        </Popup>
      </InactiveBg>);
  }
}

export default compose(
  connect(),
  injectIntl
)(PrinterSelectPopup);
