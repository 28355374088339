// @flow

export const prgDotazIsValid = (prgDotaz) => {
  if (!Array.isArray(prgDotaz)) return false;

  return [
    'dotaz_st',
    'dotaz_re',
    'dotaz_ho',
  ].includes(prgDotaz[0]);
};

export const prgComponents = (prgDotaz) => {
  switch (prgDotaz[0]) {
    case 'dotaz_st':
      return process.env.IS_REACT_NATIVE === '1'
        ? require('../../../native/payment/prgDotazy/dotazSt').default
        : require('../../../browser/payment/prgDotazy/dotazSt').default;
    case 'dotaz_re':
      return process.env.IS_REACT_NATIVE === '1'
        ? require('../../../native/payment/prgDotazy/dotazRe').default
        : require('../../../browser/payment/prgDotazy/dotazRe').default;
    case 'dotaz_ho':
      return process.env.IS_REACT_NATIVE === '1'
        ? require('../../../native/payment/prgDotazy/dotazHo').default
        : require('../../../browser/payment/prgDotazy/dotazHo').default;
    default:
      throw Error('cannot find prg component');
  }
};
