// @flow
import { createSelector } from 'reselect';
import type { FoodCourse, Id, OpenTable, State, SubTable, TableDefinition, User } from '../types';
import { undeleted } from '../lib/locals';
import { propEq, filter, find, concat, sortBy, prop } from 'rambda';
import {
  findUsedOpenTables,
  sortedTablesWithOpenTables,
  tablesInServiceArea,
  tablesWithoutOpenTables
} from './utils';
import { activeServiceAreaIdSelector } from '../serviceAreas/selectors';
import { meSelector } from '../auth/selectors';

const openTablesSelector = (state: State) => state.tables.openTables;

export const undeletedOpenTablesSelector = createSelector(
  openTablesSelector,
  (openTables: OpenTable[]) => undeleted(openTables)
);

const subTablesSelector = (state: State) => state.tables.subTables;

export const undeletedSubTablesSelector = createSelector(
  subTablesSelector,
  (subTables: SubTable[]) => sortBy(prop('sortOrder'), undeleted(subTables))
);

const tablesDefinitionsSelector = (state: State) => state.tables.tableDefinitions;

export const undeletedTableDefinitionsSelector = createSelector(
  tablesDefinitionsSelector,
  (tableDefinitions: TableDefinition[]) => undeleted(tableDefinitions)
);

export const undeletedTableDefinitionsInAreaSelector = createSelector(
  undeletedTableDefinitionsSelector,
  activeServiceAreaIdSelector,
  (tableDefinitions: TableDefinition[], activeAreaId: Id) =>
    tablesInServiceArea(tableDefinitions, activeAreaId)
);

export const activeOpenTableIdSelector = (state: State) => state.tables.active.openTableId;

export const activeOpenTableSelector = createSelector(
  activeOpenTableIdSelector,
  undeletedOpenTablesSelector,
  (openTableId: Id, openTables: OpenTable[]) => find(propEq('id', openTableId), openTables)
);

export const activeSubTableIdSelector = (state: State) => state.tables.active.subTableId;

export const subTablesForActiveOpenTableSelector = createSelector(
  activeOpenTableIdSelector,
  undeletedSubTablesSelector,
  (openTableId: Id, subTables: SubTable[]) => filter(propEq('openTableId', openTableId), subTables)
);

const foodCoursesSelector = (state: State) => state.tables.foodCourses;

export const foodCoursesForActiveOpenTableSelector = createSelector(
  activeOpenTableIdSelector,
  foodCoursesSelector,
  (openTableId: Id, foodCourses: FoodCourse[]) =>
    concat(
      [{ id: 0, openTableId }],
      filter(propEq('openTableId', openTableId), foodCourses)
    )
);

  export const activeTableDefinitionSelector = createSelector(
  activeOpenTableSelector,
  undeletedTableDefinitionsSelector,
  (openTable: OpenTable, tables: TableDefinition[]) => openTable
    ? find(propEq('id', openTable.tableDefinitionId), tables)
    : null
);

export const activeTableNameSelector = createSelector(
  activeTableDefinitionSelector,
  activeOpenTableIdSelector,
  undeletedOpenTablesSelector,
  (activeTable: TableDefinition, activeOpenTableId: Id, openTables: OpenTable[]) => {
    if (!activeTable || !activeOpenTableId) return '';

    const assocOpenTables = findUsedOpenTables(activeTable.id, openTables);
    if (!assocOpenTables.length) {
      return '';
    }

    const tableName = activeOpenTableId === assocOpenTables[0].id
      ? activeTable.name
      : `${activeTable.name} (2)`;

    return tableName;
  }
);

export const tablesInActiveServiceAreaSelector = createSelector(
  undeletedTableDefinitionsSelector,
  activeServiceAreaIdSelector,
  undeletedOpenTablesSelector,
  meSelector,
  (tables: TableDefinition[], serviceAreaId: Id, openTables: OpenTable[], user: User) => {
    tables = tablesInServiceArea(tables, serviceAreaId);
    return [
      ...sortedTablesWithOpenTables(tables, openTables, user),
      ...tablesWithoutOpenTables(tables, openTables)
    ];
  }
);

export const sortedTablesWithOpenTablesSelector = createSelector(
  undeletedTableDefinitionsSelector,
  undeletedOpenTablesSelector,
  meSelector,
  (tables: TableDefinition[], openTables: OpenTable[], user: User) =>
    sortedTablesWithOpenTables(tables, openTables, user)
);
