// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import Root from './app/Root';
import configureStore from '../common/configureStore';
import configureStorage from '../common/configureStorage';
import configureStorageEngine from './configureStorageEngine';
import uuid from 'uuid';
import { persistStore } from 'redux-persist';
import redirectAfterSignIn from './auth/redirectAfterSignIn';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import history from './createHistory';
import { configureApi } from '../common/lib/api';
import moment from 'moment';
import 'moment/locale/sk';
import autoUpdatesReducer from './autoUpdates/reducer';
import waiterKeyMiddleware from './auth/waiterKeyMiddleware';
// import { whyDidYouUpdate } from 'why-did-you-update';

// if (process.env.NODE_ENV === 'development') {
//   whyDidYouUpdate(React, {
//     exclude: /^StaticContainer/,
//     groupByComponent: true,
//     collapseComponentGroups: true
//   });
// }

// only public part, will be replaced in app/common
moment.locale('sk');

const middlewares = process.env.IS_ELECTRON === '1'
  ? [routerMiddleware(history), redirectAfterSignIn, waiterKeyMiddleware]
  : [routerMiddleware(history), redirectAfterSignIn];

const store = configureStore({
  initialState: {},
  platformDeps: { uuid },
  platformEpics: [],
  enhanceRootReducer: connectRouter(history),
  platformReducers: { autoUpdates: autoUpdatesReducer },
  platformMiddleware: middlewares,
  platformStoreEnhancers: [],
});

if (process.env.IS_ELECTRON === '1') {
  require('./listenOnMainProcess').default(store);
}

const appElement = document.getElementById('app');

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component store={store} />
    </AppContainer>,
    appElement
  );
};

const onRehydrate = () => {
  store.dispatch({ type: 'APP_STARTED' });
  configureApi(store.getState().device.apiUrl);

  render(Root);

  if (module.hot) {
    module.hot.accept('./app/Root.js', () => {
      const NextRoot = require('./app/Root.js').default;

      render(NextRoot);
    });
  }
};

persistStore(
  store,
  {
    ...configureStorage(process.env.APP_NAME),
    storage: configureStorageEngine()
  },
  onRehydrate,
);
