// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';
import type { Id } from '../../types';

export const getClosures = (
  type,
  { dateFrom, dateTo, loadAfterId, printer, print, isSummary, closureId, closureBased } = {}
): Promise =>
  fetch(apiEndpoint(`v1/closures/${closureId ? `${closureId}/` : ''}`), {
    baseURL: getApiUrl(),
    params: {
      type,
      date_from: dateFrom,
      date_to: dateTo,
      id: loadAfterId,
      printer,
      print: print ? 1 : undefined,
      closure_based: closureBased ? 1 : undefined,
      is_summary: isSummary ? 1 : undefined
    }
  });

export const getClosure = (id: Id): Promise =>
  fetch(apiEndpoint(`v1/closures/${id}/`), {
    baseURL: getApiUrl(),
  });

export const printClosure = (id: Id, printerId: Id): Promise =>
  fetch(apiEndpoint(`v1/closures/${id}/`), {
    baseURL: getApiUrl(),
    params: {
      printer: printerId,
      print: 1
    }
  });

export const rebuildClosure = (id: Id): Promise =>
  fetch(apiEndpoint(`v1/closures/${id}/`), {
    baseURL: getApiUrl(),
    params: {
      regenerate: 1
    }
  });

export const printCopyOfFiscalClosure = (id: Id): Promise =>
  fetch(apiEndpoint(`v1/closures/${id}/`), {
    baseURL: getApiUrl(),
    params: {
      fiscal: 1
    }
  });
