// @flow
import React from 'react';
import InactiveBg from '../../../../browser/components/InactiveBg';
import Popup from '../../../../browser/components/Popup';
import NewPartner from '../dotazSt/NewPartner';
import AskName from './AskName';
import api from '../../../../common/lib/api';
import Box from '../../../../common/components/Box';
import Spinner from 'react-spinner';
import Text from '../../../../common/components/Text';
import Partners from '../../../../common/payment/prgDotazy/Partners';
import prgDotaz from '../../../../common/payment/hoc/prgDotaz';
import { paymentOperationsName } from '../../../../common/lib/api/payment';
import orderKeeper, { RequestOrderError } from '../../../../common/lib/fetch/orderKeeper';

class DotazRe extends React.Component {
  state = {
    showNewPartner: false,
    error: null,
    isLoading: false
  };

  reqOrderKeeper = orderKeeper(paymentOperationsName);

  componentDidMount() {
    const { prgDotaz: [, , , name, loan] } = this.props;

    if (!name && !loan) {
      this.savePrgDotaz();
    }
  }

  cancel = () => {
    const { cancelPayment } = this.props;
    cancelPayment();
  };

  goBack = () => {
    this.setState({
      showNewPartner: false
    });
  };

  newPartner = () => {
    this.setState({
      showNewPartner: true
    });
  };

  selectPartner = partnerId =>
    this.savePrgDotaz({ partnerId }).catch(e => {
      if (e instanceof RequestOrderError) return;

      throw e;
    });

  saveNewPartner = (partner) => {
    this.setState({ isLoading: true, error: null });

    return this.reqOrderKeeper(() => api.payment.createPartner(partner))
      .then(({ body: { result: { partner: { idriadok: partnerId } } } }) => {
        this.setState({ showNewPartner: false });
        return this.savePrgDotaz({ partnerId });
      }).catch(e => {
        if (e instanceof RequestOrderError) throw e;

        this.setState({ isLoading: false, error: e.toString() });
      });
  };

  saveName = ({ name }) => {
    this.savePrgDotaz({ name });
  };

  savePrgDotaz = prgData => {
    const { confirmPayment } = this.props;

    return confirmPayment(prgData);
  };

  render() {
    const { showNewPartner, error, isLoading } = this.state;
    const { prgDotaz: [, , , name, loan] } = this.props;

    return (
      <InactiveBg zIndex={2000} onClose={this.cancel}>
        <Popup onClose={this.cancel} onBack={showNewPartner ? this.goBack : null}>
          {isLoading ? (
            <Box zIndex={10} position="static" minHeight={8} minWidth={8}>
              <Spinner className="absolute" />
            </Box>
          ) : null}

          <Box opacity={isLoading ? 0.2 : 1}>
            {loan ? (
              showNewPartner
                ? <NewPartner savePartner={this.saveNewPartner} />
                : <Partners
                    newPartner={this.newPartner}
                    selectPartner={this.selectPartner}
                    disabled={isLoading} />
            ) : null}

            {(!loan && name) ? <AskName saveName={this.saveName} /> : null}
          </Box>

          {error ? <Text color="error">{error}</Text> : null}
        </Popup>
      </InactiveBg>
    );
  }
}

export default prgDotaz(DotazRe);
