// @flow
import type { Action, Id } from '../../types';

export const toggleAlcoholId = (data: Object): Action => ({
  type: 'ADMIN_ALCOHOL_TOGGLE_ID',
  payload: {
    data
  }
});

export const clearAlcoholIds = (): Action => ({
  type: 'ADMIN_ALCOHOL_CLEAR_IDS',
  payload: {}
});

export const editAlcoholRow = (data: Object): Action => ({
  type: 'ADMIN_ALCOHOL_EDIT',
  payload: {
    data
  }
});
