// @flow
import type { State } from '../../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import Title from '../../../components/Title';
import Box from '../../../../common/components/Box';
import Sidebar from './Sidebar';
import titlesMessages from '../../../../common/app/messages/titles';
import TopBar from '../../mealsCount/TopBar';
import JournalList from './JournalList';

class CloseHistoryPage extends React.PureComponent {
  render() {
    return (
      <Box flex={1} height="100%" backgroundColor="appBg">
        <Title message={titlesMessages.admin} />

        <TopBar />

        <Box flexDirection="row" zIndex={1} flex={1} height="calc(100% - 36px)">
          <Box flex={1} width="100%">
            <JournalList />
          </Box>

          <Sidebar />
        </Box>
      </Box>
    );
  }
}


export default connect(
  (state: State) => ({
  })
)(CloseHistoryPage);
