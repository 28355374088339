// @flow
import React from 'react';
import Box from '../Box';
import Text from '../Text';
import { VelocityTransitionGroup } from 'velocity-react';
import OrderLineHeading from './OrderLineHeading';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import componentsMessages from '../../messages/components';

class Receipts extends React.PureComponent {
  render() {
    const {
      activeReceipt,
      receiptItems,
      priceLevels,
      renderers,
      intl
    } = this.props;
    const actualRenderer = renderers[0];

    const SheetHeading = (
      <Box>
        <OrderLineHeading
          name={activeReceipt ? `# ${activeReceipt.id}` : ''}
          quantity={receiptItems.length}
        />
      </Box>
    );

    const NoItems = (
      <Box
        height={1.5}
        backgroundColor="white"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Text alignItems="center" justifyContent="center" paddingTop={0.25}>
          {intl.formatMessage(componentsMessages.sheetRenderersNoItems)}
        </Text>
      </Box>
    );

    return (
      <Box flexShrink={0}>
        <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
          {SheetHeading}
          {receiptItems.length
            ? receiptItems.map(receiptItem => (
              actualRenderer({ id: receiptItem.id, data: { receiptItem, priceLevels } })
            ))
            : NoItems}
        </VelocityTransitionGroup>
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(Receipts);
