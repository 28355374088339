// @flow
import { replace } from 'connected-react-router';
import { isEmpty } from 'ramda';


export const redirectToLoyalman = (action$, { getState }) =>
  action$
    .ofType('LOYALMAN_USERS_TOGGLE')
    .filter(({ payload: { enableLoyalMan } }) => enableLoyalMan)
    .mergeMap(() => [replace('/loyalman')]);
