// @flow
import React from 'react';
import { findDOMNode } from 'react-dom';
import Box from '../../common/components/Box';

type InactiveBgProps = {
  onClose?: Function,
  children?: any,
  backgroundColor?: string
}

class InactiveBg extends React.PureComponent<InactiveBgProps> {
  render() {
    const { children, onClose, backgroundColor = 'inactiveBg', ...restProps } = this.props;

    return (
      <Box
        position="fixed"
        backgroundColor={backgroundColor}
        onClick={this._askToClose} // onClick is hack and I know it 🧠
        top={0}
        left={0}
        bottom={0}
        right={0}
        zIndex={3000}
        {...restProps}
      >
        {children}
      </Box>
    );
  }

  _askToClose = (e) => {
    const clickOnBg = findDOMNode(e.target) === findDOMNode(this);

    if (!clickOnBg) return;

    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };
}

export default InactiveBg;
