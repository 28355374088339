// @flow
import type { Action, Id } from '../../types';

export const activateLoyalmanUser = (user: Object): Action => ({
  type: 'LOYALMAN_USERS_ACTIVATE_USER',
  payload: {
    user
  }
});

export const toggleLoyalmanUsers = (enableLoyalMan: boolean = true, selectedOrderLines: number[] = null, paymentTransactionId: Id = null, closeRedirectsToOrder: boolean = false, showKeyboard: boolean = false): Action => ({
  type: 'LOYALMAN_USERS_TOGGLE',
  payload: {
    enableLoyalMan,
    selectedOrderLines,
    paymentTransactionId,
    closeRedirectsToOrder,
    showKeyboard
  }
});
