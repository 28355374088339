// @flow
import type { State, ServiceArea } from '../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import { sum } from 'ramda';
import { compose, filter, find, pluck } from 'rambda';
import { replace as navigateTo } from 'connected-react-router';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Radio from '../../common/components/Radio';
import ComposedButton from '../../common/components/ComposedButton';
import {
  activateArea,
  toggleTableList,
  activateIsLimit,
  toggleOneTimeTableNamePopupOpen
} from '../../common/serviceAreas/actions';
import {
  activateOpenTable, addTableDefinition, focusOnTable,
  toggleTablesPositionEditing, toggleAdminMenu
} from '../../common/tables/actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../common/serviceAreas/messages/options';
import serviceMessages from '../../common/messages/serviceAreas';
import editablesMessages from '../../common/serviceAreas/messages/editables';
import { findParamValue } from '../../common/parameters/utils';
import { findOpenTableBar } from '../../common/tables/utils';
import { undeletedOpenTablesSelector } from '../../common/tables/selectors';
import moment from 'moment';
import DepositAndWithdrawal from './DepositAndWithdrawal';
import Permission from '../../common/permissions/Permission';
import ScrollView from '../../common/components/ScrollView';
import { toggleAllowSignOutOnInactivity } from '../../common/activity/actions';
import api from '../../common/lib/api';
import { addError } from '../../common/order/actions';
import InactiveBgWithTextSpinner from '../components/InactiveBgWithTextSpinner';
import OneTimeTableNamePopup from './popups/OneTimeTableNamePopup';
import { leftJoin } from '../../common/lib/moreRambda';
import VirtualAreasSelectPopup from './popups/VirtualAreasSelectPopup';

class AreaButton extends React.PureComponent {
  _onPress = () => {
    const { dispatch, area: { id, isLimit } } = this.props;

    dispatch([
      toggleTablesPositionEditing(false),
      toggleTableList(false),
      focusOnTable(null),
      activateArea(id),
      activateIsLimit(isLimit)
    ]);
  };

  render() {
    const { area: { id, isVirtual, isLimit, name }, activeAreaId, showTablesList } = this.props;

    const isActive = (id === activeAreaId) && !showTablesList;

    return (
      <Button
        key={id}
        backgroundColor={isActive ? 'teal' : 'appBg'}
        outline
        color="white"
        marginBottom={0.5}
        paddingVertical={0.75}
        paddingHorizontal={2}
        onPress={this._onPress}
        flexShrink={0}
      >
        <Radio size={1} color="white" left={0.75} position="absolute" backgroundColor={isActive ? 'teal' : 'appBg'} checked={isActive} />
        <Text color="white" bold>
          {isVirtual && !isLimit ? <FormattedMessage {...messages.noMap} /> : name}
        </Text>
      </Button>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
class Options_ extends React.PureComponent {
  constructor(props) {
    super(props);
    const { printers } = this.props;

    this.state = {
      depositOrWithdrawal: '',
      showBillsToSendButton: sum(pluck('billsToSend', printers)) > 0,
      showLoading: false,
      addOneTimeTableName: false,
      virtualAreas: [],
      openVirtualAreasModalData: undefined
    };
  }

  toggleServiceAreasDataPopup = data => {
    this.setState({ openVirtualAreasModalData: data });
  };

  setVirtualAreas = virtualAreas => {
    this.setState({ virtualAreas });
  };

  componentDidMount() {
    const { serviceAreas, dispatch, intl } = this.props;

    if (serviceAreas === null || serviceAreas.length === 0) {
      dispatch(addError(intl.formatMessage(messages.serviceAreasNoRoomsError)));
    }

    const filteredVirtualAreas = filter((area: ServiceArea) => area.isVirtual && !area.isLimit, serviceAreas);
    this.setVirtualAreas(filteredVirtualAreas);
  }

  toggleOneTimeTableName = toggle => {
    const { dispatch } = this.props;
    this.setState({
      addOneTimeTableName: toggle
    });
    dispatch(toggleOneTimeTableNamePopupOpen(toggle));
  };

  render() {
    const {
      activeAreaId,
      serviceAreas,
      isPositionEditing,
      openTables,
      showTablesList,
      parameters,
      intl,
      dispatch,
      activeIsLimit,
      hasAnyValidPaymentMedia
    } = this.props;
    const { depositOrWithdrawal, showBillsToSendButton, showLoading, addOneTimeTableName, virtualAreas, openVirtualAreasModalData } = this.state;
    const createNewTable = (name: string, barType?: string, virtualAreas) => {
      let virtualArea = find((area: ServiceArea) => area.id === activeAreaId, virtualAreas);
      if (!virtualArea) {
        if (!barType) {
          virtualArea = virtualAreas !== null && virtualAreas.length === 1 && virtualAreas[0];
          if (!virtualArea) {
            this.toggleServiceAreasDataPopup({ name, barType });
            return;
          }
        } else {
          virtualArea = find((area: ServiceArea) => area.isVirtual && !area.isLimit, serviceAreas);
          if (!virtualArea) return;
        }
      }

      if (isPositionEditing || !virtualArea) return;

      const barSettings =
        (barType === 'barCash' && { barCash: true }) ||
        (barType === 'bar' && { bar: true }) ||
        {};

      const existingOpenTable = (barType === 'barCash' || barType === 'bar')
        ? findOpenTableBar(openTables, barType)
        : null;

      let action;
      if (existingOpenTable) {
        action = activateOpenTable(existingOpenTable.id);
      } else {
        action = addTableDefinition({
          name,
          serviceAreaId: virtualArea.id,
          geometry: {
            x: 0, y: 0, width: 100, height: 100
          }
        }, { willOpen: true, openTableAttr: barSettings, activate: true });
      }

      dispatch([
        navigateTo('/order'),
        action,
      ]);
    };

    const cancelDepositAndWithdrawal = () => {
      this.setState({ depositOrWithdrawal: '' });
      dispatch(toggleAllowSignOutOnInactivity(true));
    };

    const suflikIsEnabled = findParamValue('K32.sup_op', parameters);
    const parameterSendUnsentBillsButton = findParamValue('K32.ekasa', parameters);

    return (
      <Box display="flex" flexDirection="column">
        {openVirtualAreasModalData && (
          <VirtualAreasSelectPopup
            virtualAreas={virtualAreas}
            setVirtualArea={va => {
              createNewTable(openVirtualAreasModalData.name, openVirtualAreasModalData.barType, [va]);
            }}
            onClose={() => this.toggleServiceAreasDataPopup(undefined)}
          />
        )}

        {addOneTimeTableName && (
          <OneTimeTableNamePopup
            title={intl.formatMessage(serviceMessages.serviceAreasOneTimeTableName)}
            onSubmit={(name) => {
              createNewTable(name, null, virtualAreas);
              this.toggleOneTimeTableName(false);
            }}
            onClose={() => this.toggleOneTimeTableName(false)}
          />
        )}

        <Box flexDirection="row" marginBottom={0.5} marginHorizontal={-0.25} justifyContent="space-between">
          {parameterSendUnsentBillsButton && showBillsToSendButton &&
          <ComposedButton
            caption={<FormattedMessage {...messages.sendUnsent} />}
            backgroundColor="buttonOrange"
            shadowColor="buttonOrangeShadow"
            marginHorizontal={0.25}
            width="100%"
            icon="meal-voucher"
            disabled={isPositionEditing}
            opacity={isPositionEditing ? 0.2 : 1}
            onPress={() => {
              this.setState({ showLoading: true });
              api.receipt.sendUnsendReceipts()
                .then(() => {
                  this.setState({ showLoading: false, showBillsToSendButton: false });
                })
                .catch(e => {
                  this.setState({ showLoading: false });
                  dispatch(addError(e));
                });
            }}
          />}
        </Box>

        <Box flexDirection="row" marginBottom={0.5} marginHorizontal={-0.25} justifyContent="space-between">
          <Permission name="opentable.barincash.add">
            <ComposedButton
              caption={<FormattedMessage {...messages.barInCash} />}
              backgroundColor="buttonTeal"
              shadowColor="buttonTealShadow"
              marginHorizontal={0.25}
              width="44%"
              icon="bar-cash"
              disabled={isPositionEditing}
              opacity={isPositionEditing ? 0.2 : 1}
              onPress={() => createNewTable(intl.formatMessage(messages.barInCash), 'barCash', virtualAreas)}
            />
          </Permission>
          <Permission name="opentable.bar.add">
            <ComposedButton
              caption={<FormattedMessage {...messages.bar} />}
              backgroundColor="buttonTeal"
              shadowColor="buttonTealShadow"
              marginHorizontal={0.25}
              width="44%"
              icon="bar-cash"
              disabled={isPositionEditing}
              opacity={isPositionEditing ? 0.2 : 1}
              onPress={() => createNewTable(intl.formatMessage(messages.bar), 'bar', virtualAreas)}
            />
          </Permission>
        </Box>

        <Box flexDirection="row" marginBottom={0.5} marginHorizontal={-0.25} justifyContent="space-between">
          <Permission name="opentable.temp.add">
            <ComposedButton
              caption={<FormattedMessage {...messages.oneTimeTable} />}
              backgroundColor="buttonTeal"
              shadowColor="buttonTealShadow"
              marginHorizontal={0.25}
              width="44%"
              icon="one-stand-table"
              disabled={isPositionEditing}
              opacity={isPositionEditing ? 0.2 : 1}
              onPress={() => {
                const time = moment().format('LT');
                return createNewTable(`${intl.formatMessage(messages.oneTimeTable)} ${time}`, null, virtualAreas);
              }}
              onLongPress={() => this.toggleOneTimeTableName(true)}
            />
          </Permission>
          <ComposedButton
            caption={<FormattedMessage {...messages.suflik} />}
            backgroundColor="buttonBlue"
            shadowColor="buttonBlueShadow"
            marginHorizontal={0.25}
            width="44%"
            icon="suflik"
            disabled={isPositionEditing || !suflikIsEnabled}
            opacity={(isPositionEditing || !suflikIsEnabled) ? 0.2 : 1}
            onPress={() => {
            this.setState({ showLoading: true });
            api.receipt.openDrawer()
              .then(() => {
                this.setState({ showLoading: false, showBillsToSendButton: false });
              })
              .catch(e => {
                this.setState({ showLoading: false });
                dispatch(addError(e));
              });
        }} />

        </Box>

        {hasAnyValidPaymentMedia && (
          <Box flexDirection="row" marginBottom={0.5} marginHorizontal={-0.25} justifyContent="space-between">
            <Permission name="deposit.add">
              <ComposedButton
                caption={<FormattedMessage {...messages.deposit} />}
                backgroundColor="buttonBlue"
                shadowColor="buttonBlueShadow"
                marginHorizontal={0.25}
                width="44%"
                icon="deposit"
                disabled={isPositionEditing}
                opacity={isPositionEditing ? 0.2 : 1}
                onPress={() => {
                  dispatch(toggleAllowSignOutOnInactivity(false));
                  this.setState({ depositOrWithdrawal: 'deposit' });
                }}
              />
            </Permission>
            <Permission name="withdrawal.add">
              <ComposedButton
                caption={<FormattedMessage {...messages.withdrawal} />}
                backgroundColor="buttonBlue"
                shadowColor="buttonBlueShadow"
                marginHorizontal={0.25}
                width="44%"
                icon="withdrawal"
                disabled={isPositionEditing}
                opacity={isPositionEditing ? 0.2 : 1}
                onPress={() => {
                  dispatch(toggleAllowSignOutOnInactivity(false));
                  this.setState({ depositOrWithdrawal: 'withdrawal' });
                }}
              />
            </Permission>
          </Box>
        )}

        <Box flexDirection="row" marginHorizontal={-0.25} justifyContent="space-between">
          {!showTablesList &&
            <ComposedButton
              caption={<FormattedMessage {...messages[isPositionEditing ? 'editDone' : 'edit']} />}
              backgroundColor="buttonGray"
              shadowColor="buttonGrayShadow"
              marginHorizontal={0.25}
              disabled={activeIsLimit}
              width="44%"
              icon={isPositionEditing ? 'check' : 'edit'}
              onPress={() => dispatch([
                toggleAllowSignOutOnInactivity(isPositionEditing),
                toggleTablesPositionEditing(!isPositionEditing)
              ])} />
          }
          <ComposedButton
            caption={<FormattedMessage {...messages.options} />}
            backgroundColor="buttonGray"
            shadowColor="buttonGrayShadow"
            marginHorizontal={0.25}
            width="44%"
            icon="hamburger"
            disabled={isPositionEditing}
            opacity={isPositionEditing ? 0.2 : 1}
            onPress={() => dispatch([
              toggleAllowSignOutOnInactivity(false),
              toggleAdminMenu(true)
            ])} />
        </Box>

        {depositOrWithdrawal &&
        <DepositAndWithdrawal
          depositOrWithdrawal={depositOrWithdrawal}
          cancel={cancelDepositAndWithdrawal}
        />}

        {showLoading
          && <InactiveBgWithTextSpinner text={intl.formatMessage(editablesMessages.operationInProgress)} />}
      </Box>
    );
  }
}

const Options = compose(
  connect((state: State) => ({
    showTablesList: state.serviceAreas.showTablesList,
    openTables: undeletedOpenTablesSelector(state),
    parameters: state.parameters.parameters,
    activeAreaId: state.serviceAreas.activeAreaId,
    serviceAreas: state.serviceAreas.serviceAreas,
    activeIsLimit: state.serviceAreas.activeIsLimit,
    printers: state.peripherals.printers
  })),
  injectIntl
)(Options_);

const Sidebar = ({ dispatch, activeAreaId, areas, showTablesList, isPositionEditing, paymentMedia, paymentTypes, intl }) => {

  const hasAnyValidPaymentMedia = () => {
    if (!paymentMedia || !paymentTypes) {
      return false;
    }

    const validPaymentTypes = filter(({ cisfplat = ''}) => {
      return cisfplat !== '0';
    }, paymentTypes);

    const validPaymentMedia = leftJoin(
      ({ idriadok }, { druhyPlId }) => idriadok === druhyPlId,
      validPaymentTypes,
      paymentMedia
    );

    return validPaymentMedia && validPaymentMedia.length > 0;
  };

  return (
    <ScrollView overflowX="hidden">
      <Box flex={1} flexDirection="column" padding={1} backgroundColor="appBg" height="auto" paddingBottom="50px">
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          flexShrink={0}
        >
          <Heading scale={4} color="white"><FormattedMessage {...messages.areas} /></Heading>
          <Button
            color={showTablesList ? 'aquamarine' : 'white'}
            onPress={() => {
              dispatch(toggleTableList(true));
            }}
          >
            {intl.formatMessage(messages.openTables)}
          </Button>
        </Box>

        <Box display="flex" flexDirection="column">
          {areas.map(area => (
            <AreaButton
              key={area.id}
              area={area}
              activeAreaId={activeAreaId}
              showTablesList={showTablesList}
              dispatch={dispatch}
            />
          ))}
        </Box>

        <Box display="flex" flexShrink={0} flexDirection="column">
          <Heading scale={4} color="white" marginBottom={0.5}
                   marginTop={1}><FormattedMessage {...messages.options} /></Heading>
          <Options serviceAreas={areas} isPositionEditing={isPositionEditing} hasAnyValidPaymentMedia={hasAnyValidPaymentMedia()} />
        </Box>
      </Box>
    </ScrollView>
  );
}

export default compose(
  connect((state: State) => ({
    showTablesList: state.serviceAreas.showTablesList,
    areas: state.serviceAreas.serviceAreas,
    activeAreaId: state.serviceAreas.activeAreaId,
    isPositionEditing: state.tables.editPosition,
    paymentMedia: state.payment.paymentMedia,
    paymentTypes: state.payment.paymentTypes
  })),
  injectIntl
)(Sidebar);
