// @flow
import type { KeyboardButtonsProps } from '../index';
import React from 'react';
import Box from '../../Box';
import KbButton from '../StandardButton';
import Icon from '../../../../browser/components/Icon';

class Buttons extends React.PureComponent {
  props: KeyboardButtonsProps;

  render() {
    const { onPress, disabled } = this.props;

    return (
      <Box>
        <Box flexDirection="row">
          <KbButton char={7} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={8} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={9} onPress={onPress} borderTop disabled={disabled} />
        </Box>
        <Box flexDirection="row">
          <KbButton char={4} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={5} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={6} onPress={onPress} borderTop disabled={disabled} />
        </Box>
        <Box flexDirection="row">
          <KbButton char={1} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={2} onPress={onPress} borderRight borderTop disabled={disabled} />
          <KbButton char={3} onPress={onPress} borderTop disabled={disabled} />
        </Box>
        <Box flexDirection="row">
          <KbButton char={0} onPress={onPress} borderRight borderTop size={2} disabled={disabled} />
          <KbButton char="unlock" onPress={onPress} borderTop primary disabled={disabled}>
            <Icon name="unlock" scale={4} color="white" />
          </KbButton>
        </Box>
      </Box>
    );
  }
}

export default Buttons;
