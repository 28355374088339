// @flow
import type { KeyboardDisplayProps } from '../index';
import React from 'react';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../../../browser/components/Icon';
import TextInput from '../../TextInput';
import theme from '../../../themes/theme';
import Text from '../../Text';

class Display extends React.PureComponent {
  props: KeyboardDisplayProps;

  _onInputKeyPress = ({ key, charCode }) => {
    const { onPress } = this.props;

    onPress(charCode === 13 ? 'submit' : key);
  };

  _onInputKeyDown = ({ keyCode }) => {
    const { onPress } = this.props;

    if (keyCode === 8) { // backspace
      onPress('backspace');
    }
  };

  _onCancelPress = () => {
    const { onPress } = this.props;

    onPress('cancel');

    if (this.input) {
      this.input.focus();
    }
  };

  render() {
    const { value, disabled, unit } = this.props;

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor="teal"
        borderStyle="solid"
        borderColor="teal"
        height={`${theme.typography.lineHeight(5) * 1.75}px`}
        width={`${theme.typography.lineHeight(5) * 1.75 * 3}px`}
        paddingRight={0.5}
      >
        <Button
          onPress={this._onCancelPress}
          paddingHorizontal={`${theme.baseSize * 1.5}px`}
          disabled={disabled}
          opacity={1}
        >
          <Icon
            name="delete"
            color="textInverse"
            justifyContent="center"
            scale={2}
            style={theme => ({ ':active': { color: theme.colors.textInverseActive } })}
          />
        </Button>
        <TextInput
          nativeRef={c => { this.input = c; }}
          autoFocus
          backgroundColor="teal"
          flexGrow={1}
          bold
          color="white"
          scale={5}
          justifyContent="center"
          height="100%"
          overflow="auto"
          whiteSpace="nowrap"
          value={value}
          align="right"
          onKeyPress={this._onInputKeyPress}
          onKeyDown={this._onInputKeyDown}
          disabled={disabled}
          paddingRight={`${theme.baseSize * 0.5}px`}
        />
        <Text
          flexDirection="row"
          alignItems="baseline"
          color="textInverse"
          style={theme => ({
            lineHeight: theme.typography.lineHeight(5) * 2
          })}
        >
          {unit}
        </Text>
      </Box>
    );
  }
}

export default Display;
