// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, pathOr, filter, repeat, map, flatten, append } from 'rambda';
import type { State } from '../../common/types';
import Box from '../../common/components/Box';
import MenuItem from '../../common/components/MenuItem';
import { findMaxRow } from '../../common/menuItems/utils';
import {
  clearSelectedOrderLines, toggleFilter,
  toggleTextInput, addOrderLine, toggleFastFoodMenuForm
} from '../../common/order/actions';
import { computeItemPrice, isFiltering } from '../../common/order/utils';
import { toggleWeightForm } from '../../common/peripherals/actions';
import EasyGrid from '../components/EasyGrid';
import MeasureBounds from '../components/MeasureBounds';
import { findParamValue } from '../../common/parameters/utils';
import theme from '../../common/themes/theme';
import { displayMenuItemsSelector } from '../../common/items/selectors';
import invariant from 'invariant';
import guard from '../../common/components/hoc/guard';
import { activePageSelector } from '../../common/menuItems/selectors';
import { getItemAvailability } from '../../common/items/utils';
import checkPermission from '../../common/permissions/service';
import ScrollView from '../../common/components/ScrollView';
import { excludedMetaKeys, replaceSKKeyCharsWithNums } from '../../common/lib/utils';
import uuid from 'uuid';

class MenuItemsList extends React.Component {
  state = {
    valuesFromInputListener: ''
  };

  _openFilter = () => {
    const { dispatch } = this.props;
    dispatch(toggleFilter(true));
    dispatch(toggleTextInput('filter'));
  };

  _validateEnteredCode = code => {
    const {
      dispatch,
      items,
      parameters,
      itemPrices,
      activePriceLevelId,
      activeSubTableId,
      activeFoodCourse
    } = this.props;

    const codeTrimmed = code ? code.trim() : code;

    if (codeTrimmed.length < 3) {
      this._openFilter();
      return;
    }

    const vahIdent = findParamValue('K32.vah_ident', parameters);
    const vahLnPlu = findParamValue('K32.vah_lnplu', parameters);
    const vahLnVah = findParamValue('K32.vah_lnvah', parameters);
    const menuSpartParam = findParamValue('K32.menu_spart', parameters);

    if (!vahIdent || !vahLnPlu || !vahLnVah) {
      this._openFilter();
      return;
    }

    if (!checkPermission('orderline.add')) return;

    let processedCode = +codeTrimmed;

    let weight = 1;
    const identCodes = vahIdent.split(',');

    if (identCodes.includes(codeTrimmed.substring(0, 2)) && codeTrimmed.length >= (2 + vahLnPlu + vahLnVah) && codeTrimmed.length < 14) {
      processedCode = Number(codeTrimmed.substring(2, 2 + vahLnPlu));
      weight = Number(codeTrimmed.substring(8, 7 + vahLnVah));
    }

    const checkIfPluOrBarcodesMatch = item => {
      if (pathOr([], 'barCodes', item).length) {
        const foundBarcodes = filter(
          barcode => {
            if (+barcode.code === processedCode) {
              item.addingCount = +(barcode.quantity || 1);
              return true;
            }
            return false;
          },
          item.barCodes
        );

        if (foundBarcodes.length) return true;
      }

      return item.plu === processedCode;
    };

    const foundItems = filter(
      item => checkIfPluOrBarcodesMatch(item),
      items
    );

    if (foundItems.length) {
      let dispatchLoad = [];

      map(foundItem => {
          if (foundItem.isWeighted && weight === 1) {
            dispatchLoad = append(
              toggleWeightForm({ id: foundItem.id }),
              dispatchLoad
            );
          } else if (foundItem.spart === 'FST' || foundItem.spart === menuSpartParam) {
            dispatchLoad = append(
              toggleFastFoodMenuForm({ id: foundItem.id, count: foundItem.addingCount || 1 }),
              dispatchLoad
            );
          } else {
            const singlePrice = computeItemPrice(itemPrices, foundItem.id, activePriceLevelId);
            const customGroupHash = foundItem.addingCount > 1 && uuid.v4();
            dispatchLoad = append(
              repeat(
                addOrderLine({
                  itemId: foundItem.id,
                  singlePrice,
                  subTableId: activeSubTableId,
                  foodCourse: activeFoodCourse || 0,
                  priceLevelId: activePriceLevelId,
                  quantity: weight,
                  unit: foundItem.unit,
                  hasCustomPrice: foundItem.hasCustomPrice,
                  needsInvoiceNo: foundItem.needsInvoiceNo,
                  spart: foundItem.spart,
                  isWeighted: foundItem.isWeighted,
                  customGroupHash
                }),
                foundItem.addingCount || 1
              ),
              dispatchLoad
            );
          }
        },
        foundItems);

      dispatch(flatten(dispatchLoad));
    } else {
      this._openFilter();
    }
  };

  handleKeyboardPress = event => {
    const { isTextPopupOpen, isWeightFormOpen, prgDotaz, isFastFoodMenuFormOpen, isPriceOrQuantityFormOpen } = this.props;

    if (!isTextPopupOpen && !isWeightFormOpen && !prgDotaz && !isFastFoodMenuFormOpen && !isPriceOrQuantityFormOpen) {
      if (event.keyCode === 13) {
        this._validateEnteredCode(this.state.valuesFromInputListener);
        this.setState({ valuesFromInputListener: '' });
      } else if (excludedMetaKeys(event)) {
        this.setState({
          valuesFromInputListener: `${this.state.valuesFromInputListener}${replaceSKKeyCharsWithNums(event.key)}`
        });
      }
    }
  };

  // TODO react antipattern - will be moved to parent element or hoc
  // - sofar I have found 2 good react libs (react-key-handler, react-keyboard-event-handler),
  // which where not enough for what we need
  // - react-keyboard-event-handler look awesome, but does not catch the events in some cases (i.e. just after opening popup)
  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyboardPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyboardPress);
  }

  render() {
    const {
      dispatch,
      displayItems,
      filterValue,
      activePriceLevelId,
      activeSubTableId,
      activeOpenTableId,
      activeFoodCourse,
      itemsAvailability,
      itemsAvailabilityDiff,
      parameters
    } = this.props;

    const isFilteringActive = isFiltering(filterValue);

    // FIXME: this fails if the CategoryContent has no .geometry.row (which is optional on backend)
    const rowsCount = isFilteringActive
      ? Math.ceil(displayItems.length / 7)
      : findMaxRow(displayItems) || 1;

    const columnsCount = isFilteringActive ? 21 : 24;

    const adjustFontSize = findParamValue('K32.foodie_font', parameters) || 'A';

    return (
      <Box
        width="100%"
        flex={1}
        backgroundColor="appBg"
        onMouseDown={() => dispatch(clearSelectedOrderLines())}
        overflow="auto"
      >
        <ScrollView id="MenuItemsListScrollContainer">
          <MeasureBounds>
            {({ width }) => {
              if (!width) return null;

              const padding = 1;
              const rowGap = 0.5;
              const paddingPx = theme.typography.lineHeight() * padding;
              const rowGapPx = theme.typography.lineHeight() * rowGap;

              const columnWidthBase = ((width - 2 * paddingPx) - ((columnsCount - 1) * rowGapPx))
                / columnsCount;

              return (
                <EasyGrid
                  padding={padding}
                  rowsCount={rowsCount}
                  columnsCount={columnsCount}
                  rowGap={rowGap}
                  columnGap={0.5}
                  overflow="auto"
                  columnStart={(menuItem, i) => isFilteringActive
                    ? 1 + 3 * (i % 7)
                    : menuItem.geometry.column
                  }
                  columnEnd={(menuItem, i) => isFilteringActive
                    ? 1 + 3 * (i % 7) + 3
                    : menuItem.geometry.column + menuItem.geometry.size
                  }
                  rowStart={(menuItem, i) => isFilteringActive
                    ? 1 + Math.floor(i / 7)
                    : menuItem.geometry.row
                  }
                  items={displayItems}
                  cellRenderer={menuItem => {
                    const isItem = isFilteringActive ? true : menuItem.isItem;
                    const name = isFilteringActive
                      ? menuItem.menuName
                      : menuItem.isItem
                        ? menuItem.link.menuName
                        : menuItem.link.name;

                    return (
                      <MenuItem
                        name={name}
                        isItem={isItem}
                        unit={pathOr(menuItem.unit, ['link', 'unit'], menuItem)}
                        id={menuItem.id}
                        spart={isFilteringActive ? menuItem.spart : menuItem.link.spart}
                        hasCustomPrice={menuItem.isItem
                          ? pathOr(menuItem.hasCustomPrice, ['link', 'hasCustomPrice'], menuItem)
                          : false}
                        isWeighted={menuItem.isItem
                          ? pathOr(menuItem.isWeighted, ['link', 'isWeighted'], menuItem)
                          : false}
                        needsInvoiceNo={menuItem.isItem
                          ? pathOr(menuItem.needsInvoiceNo, ['link', 'needsInvoiceNo'], menuItem)
                          : false}
                        itemCode={isFilteringActive ? menuItem.plu : menuItem.link.plu}
                        width={isFilteringActive
                          ? ((columnWidthBase * 3 + (2 * rowGapPx)) / theme.typography.lineHeight())
                          : ((columnWidthBase * menuItem.geometry.size + ((menuItem.geometry.size - 1) * rowGapPx)) / theme.typography.lineHeight())}
                        unitPrice={isItem && menuItem.itemPrice}
                        availability={getItemAvailability(menuItem.id, itemsAvailability,
                          itemsAvailabilityDiff)}
                        priceLevelId={activePriceLevelId}
                        backgroundColor={isFilteringActive ? '#FFFFFF' : menuItem.geometry.color}
                        activeSubTableId={activeSubTableId}
                        activeOpenTableId={activeOpenTableId}
                        activeFoodCourse={activeFoodCourse}
                        messages={pathOr(menuItem.messages, ['link', 'messages'], menuItem)}
                        mustEnterText={pathOr(menuItem.mustEnterText, ['link', 'mustEnterText'], menuItem)}
                        adjustFontSize={adjustFontSize}
                      />
                    );
                  }}
                />
              );
            }}
          </MeasureBounds>
        </ScrollView>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    displayItems: displayMenuItemsSelector(state),
    activePage: activePageSelector(state),
    items: state.items.items,
    itemPrices: state.items.itemPrices,
    activeSubTableId: state.tables.active.subTableId,
    activePriceLevelId: state.items.activePriceLevelId,
    filterValue: state.orders.filterValue,
    isWeightFormOpen: state.peripherals.isWeightFormOpen,
    isWeightConnected: state.peripherals.isWeightConnected,
    isTextPopupOpen: state.orders.active.isTextPopupOpen,
    isFastFoodMenuFormOpen: state.orders.active.isFastFoodMenuFormOpen,
    isPriceOrQuantityFormOpen: state.orders.active.isPriceOrQuantityFormOpen,
    prgDotaz: state.payment.prgDotaz,
    parameters: state.parameters.parameters,
    activeFoodCourse: state.tables.active.foodCourse,
    itemsAvailability: state.items.availability,
    itemsAvailabilityDiff: state.items.availabilityLocalDiff
  })),
  guard(({ activePage }) => {
    invariant(activePage, 'invalid active page');
  })
)(MenuItemsList);
