// @flow
import React from 'react';
import { compose } from 'rambda';
import Box from '../../../components/Box';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import ScrollView from '../../../components/ScrollView';
import { remoteLoadFromProps } from '../../../components/hoc/remoteLoad';
import api from '../../../lib/api/index';
import { isReactNative } from '../../../app/detectPlatform';
import Spinner from '../../../components/Spinner';
import Text from '../../../components/Text';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import paymentMessages from '../../../messages/payment';

class FidelioRooms extends React.PureComponent {
  render() {
    const {
      remoteError,
      remoteIsLoading,
      onConfirm,
      hasFilter,
      // filterValue = '',
      hotelId,
      intl
    } = this.props;
    const {
      remotePayload: rooms = []
    } = this.props;

    return (
      <Box
        width={isReactNative ? null : 20}
        maxHeight={isReactNative ? '100%' : '70vh'}
        marginHorizontal={-1}
        flex={1}
      >
        <Heading scale={2} color="white" marginHorizontal={1}>{intl.formatMessage(paymentMessages.paymentHoresChooseRoom)}</Heading>

        {hasFilter ? hasFilter(hotelId) : null}

        {remoteError ? null : remoteIsLoading
          ? (
            <Box marginTop={4}>
              <Spinner color={isReactNative ? 'gray' : ''} size="large" />
            </Box>
          ) : (
            <Box>
              <ScrollView maxHeight={isReactNative ? '100%' : '50vh'}>
                {rooms.map(({
                  id,
                  guest_name: guest,
                  room_code: roomCode
                }) => (
                  <Button
                    key={id}
                    marginHorizontal={1}
                    backgroundColor="white"
                    paddingVertical={0.5}
                    paddingHorizontal={1}
                    marginBottom={0.5}
                    justifyContent="flex-start"
                    flexShrink={0}
                    onPress={() => onConfirm(id, guest, roomCode)}
                  >
                    <Box height="100%">
                      <Text>{guest}</Text>
                      <Text scale={-2}>{`${intl.formatMessage(paymentMessages.paymentHoresRoomNumber)}: ${roomCode}`}</Text>
                    </Box>
                  </Button>
                ))}
              </ScrollView>
            </Box>
          )
        }

        {remoteError ? <Text color="error" marginVertical={1}>{remoteError}</Text> : null}
      </Box>
    );
  }
}

export default compose(
  remoteLoadFromProps(
    api.hores.checkFidelioCard,
    ({ hotelId, code }) => [hotelId, code]
  ),
  connect(),
  injectIntl
)(FidelioRooms);
