// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';
import type { Id } from '../../types';

export const getHotels = (): Promise =>
  fetch(apiEndpoint('v1/hores/hotels'), {
    baseURL: getApiUrl(),
  });

export const getRooms = (hotelId: Id): Promise =>
  fetch(apiEndpoint('v1/hores/rooms'), {
    baseURL: getApiUrl(),
    params: {
      hotel_id: hotelId
    }
  });

export const getGuests = (hotelId: Id, roomId: Id): Promise =>
  fetch(apiEndpoint('v1/hores/guests'), {
    baseURL: getApiUrl(),
    params: {
      hotel_id: hotelId,
      room_id: roomId
    }
  });

export const checkCard = (hotelId: Id, cardId: Id): Promise =>
  fetch(apiEndpoint('v1/hores/check_card'), {
    baseURL: getApiUrl(),
    params: {
      hotel_id: hotelId,
      card_id: cardId
    }
  });

export const getFidelioGuests = (hotelId: Id, code: Id): Promise =>
  fetch(apiEndpoint('v1/fidelio/guests'), {
    baseURL: getApiUrl(),
    params: {
      room_code: code
    }
  });

export const checkFidelioCard = (hotelId: Id, code: Id): Promise =>
  fetch(apiEndpoint('v1/fidelio/check_card'), {
    baseURL: getApiUrl(),
    params: {
      card_code: code
    }
  });
