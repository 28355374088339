// @flow
import type { TableDefinition, State } from '../../common/types';
import React from 'react';
import Popup from '../components/Popup';
import InactiveBg from '../components/InactiveBg';
import { connect } from 'react-redux';
import { editTable, updateTableDefinition } from '../../common/tables/actions';
import Keyboard from '../../common/components/Keyboard/index';
import TypewriterButtons from '../../common/components/Keyboard/typewriter/Buttons';
import TypewriterDisplay from '../../common/components/Keyboard/typewriter/Display';
import createTypewriterOperations from '../../common/components/Keyboard/typewriter/operations';
import Heading from '../../common/components/Heading';
import { FormattedMessage } from 'react-intl';
import messages from '../../common/serviceAreas/messages/editables';
import { find, propEq } from 'rambda';

class RenameTable extends React.PureComponent {
  _cancel = () => {
    const { dispatch } = this.props;
    dispatch(editTable(null));
  };

  render() {
    const { editTableId, tables, dispatch } = this.props;

    const table: TableDefinition = find(propEq('id', editTableId), tables);

    if (!table) return null;

    return (
      <InactiveBg zIndex={10} onClose={this._cancel}>
        <Popup onClose={this._cancel}>
          <Heading scale={4} color="white">
            <FormattedMessage {...messages.tableName} />
          </Heading>

          <Keyboard
            createOperations={createTypewriterOperations}
            Buttons={TypewriterButtons}
            Display={TypewriterDisplay}
            onLastKey={(key, name) => {
              if (key === 'send') {
                dispatch([
                  editTable(null),
                  updateTableDefinition(editTableId, { name })
                ]);
              }
            }}
            defaultValue={table.name}
          />
        </Popup>
      </InactiveBg>
    );
  }
}

export default connect((state: State) => ({
  editTableId: state.tables.editTable,
  tables: state.tables.tableDefinitions
}))(RenameTable);
