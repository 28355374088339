// @flow
import type { State } from '../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, pathOr } from 'rambda';
import Box from '../../../common/components/Box';
import theme from '../../../common/themes/theme';
import MeasureBounds from '../../components/MeasureBounds';
import EasyGrid from '../../components/EasyGrid';
import { isFiltering } from '../../../common/order/utils';
import { findMaxRow } from '../../../common/menuItems/utils';
import { displayMenuItemsSelector } from '../../../common/items/selectors';
import { darkenColor, invertToReadableColor } from '../../../common/lib/colors';
import MenuItem from './MenuItem';
import { activePageSelector } from '../../../common/menuItems/selectors';
import guard from '../../../common/components/hoc/guard';
import invariant from 'invariant';
import { getItemAvailability } from '../../../common/items/utils';

class MealsGrid extends React.PureComponent {
  render() {
    const {
      filterValue,
      displayItems,
      activePriceLevelId,
      itemsAvailability,
      itemsAvailabilityDiff
    } = this.props;

    let rowsCount = 1;
    const isFilteringActive = isFiltering(filterValue);

    if (isFilteringActive) {
      rowsCount = displayItems.length || 1;
    } else {
      rowsCount = findMaxRow(displayItems) || 1;
    }

    const columnsCount = 24;

    return (
      <Box width="100%" flex={1} overflow="auto" id="MealsCountScrollContainer">
        <MeasureBounds>
          {({ width }) => {
            if (!width) return null;

            const padding = 1;
            const rowGap = 0.5;
            const paddingPx = theme.typography.lineHeight() * padding;
            const rowGapPx = theme.typography.lineHeight() * rowGap;

            const columnWidthBase = ((width - 2 * paddingPx) - ((columnsCount - 1) * rowGapPx))
              / columnsCount;

            return (
              <EasyGrid
                padding={padding}
                rowsCount={rowsCount}
                columnsCount={columnsCount}
                rowGap={rowGap}
                columnGap={0.5}
                overflow="auto"
                columnStart={(menuItem, i) => isFilteringActive
                  ? 1 + 3 * (i % 7)
                  : menuItem.geometry.column
                }
                columnEnd={(menuItem, i) => isFilteringActive
                  ? 1 + 3 * (i % 7) + 3
                  : menuItem.geometry.column + menuItem.geometry.size
                }
                rowStart={(menuItem, i) => isFilteringActive
                  ? Math.round(i / 7)
                  : menuItem.geometry.row
                }
                items={displayItems}
                cellRenderer={menuItem => {
                  const itemPureName = isFilteringActive
                    ? menuItem.menuName
                    : menuItem.isItem
                      ? menuItem.link.menuName
                      : menuItem.link.name;

                  const itemUnit = isFilteringActive
                    ? menuItem.unit
                    : menuItem.link.unit;

                  const backgroundColor = isFilteringActive ? '#FFFFFF' : menuItem.geometry.color;
                  const textColor = invertToReadableColor(backgroundColor).toHexString();
                  const shadowColor = darkenColor(backgroundColor).toHexString();

                  const availability = getItemAvailability(menuItem.id, itemsAvailability,
                    itemsAvailabilityDiff);

                  return (
                    <MenuItem
                      id={menuItem.id}
                      isItem={menuItem.isItem}
                      textColor={textColor}
                      shadowColor={shadowColor}
                      itemPureName={itemPureName}
                      itemUnit={itemUnit}
                      width={isFilteringActive
                        ? ((columnWidthBase * 3 + (2 * rowGapPx)) / theme.typography.lineHeight())
                        : ((columnWidthBase * menuItem.geometry.size + ((menuItem.geometry.size - 1) * rowGapPx)) / theme.typography.lineHeight())}
                      spart={isFilteringActive ? menuItem.spart : menuItem.link.spart}
                      isWeighted={menuItem.isItem
                        ? pathOr(menuItem.isWeighted, ['link', 'isWeighted'], menuItem)
                        : false}
                      backgroundColor={backgroundColor}
                      unitPrice={menuItem.isItem && menuItem.itemPrice}
                      itemCode={isFilteringActive ? menuItem.plu : menuItem.link.plu}
                      priceLevelId={activePriceLevelId}
                      availability={availability}
                    />
                  );
                }}
              />
            );
          }}
        </MeasureBounds>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    displayItems: displayMenuItemsSelector(state),
    activePage: activePageSelector(state),
    activePriceLevelId: state.items.activePriceLevelId,
    filterValue: state.orders.filterValue,
    itemsAvailability: state.items.availability,
    itemsAvailabilityDiff: state.items.availabilityLocalDiff
  })),
  guard(({ activePage }) => {
    invariant(activePage, 'invalid active page');
  })
)(MealsGrid);
