// @flow
import type { Action, Id } from '../../types';

export const resetUsage = (): Action => ({
  type: 'ADMIN_USAGE_RESET'
});

export const clearSelectedCategories = (): Action => ({
  type: 'ADMIN_USAGE_CLEAR_SELECTED_CATEGORIES',
});

export const toggleSelectedCategoryId = (id: Id): Action => ({
  type: 'ADMIN_USAGE_TOGGLE_SELECTED_CATEGORY_ID',
  payload: {
    id
  }
});

export const setCategories = (categories): Action => ({
  type: 'ADMIN_USAGE_SET_CATEGORIES',
  payload: {
    categories
  }
});

export const setItems = (items): Action => ({
  type: 'ADMIN_USAGE_SET_ITEMS',
  payload: {
    items
  }
});

export const setFilters = filters => ({
  type: 'ADMIN_USAGE_SET_FILTERS',
  payload: {
    filters
  }
});

export const isLoadingCategories = (is: boolean): Action => ({
  type: 'ADMIN_USAGE_LOADING_CATEGORIES',
  payload: {
    loading: is
  }
});

export const isLoadingItems = (is: boolean): Action => ({
  type: 'ADMIN_USAGE_LOADING_ITEMS',
  payload: {
    loading: is
  }
});
