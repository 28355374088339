// @flow
import React from 'react';
import Text from './Text';
import { formatPriceToFixed } from '../order/utils';
import Box from './Box';
import { getCurrencySymbol } from '../parameters/service';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from '../messages/components';

class OrderLineDetail extends React.PureComponent {
  render() {
    const {
      time,
      priceLevel,
      quantity,
      isFastMenuHeader,
      unit,
      unitPrice,
      color,
      intl
    } = this.props;

    const leftPart = [
      time ? `${intl.formatMessage(messages.orderLineDetailTime)} ${time}` : '',
      priceLevel || '',
      quantity || '',
      isFastMenuHeader ? ` - ${intl.formatMessage(messages.orderLineDetailBase)}` : ''
    ].filter(x => x).join(', ');

    const rightPart = `${isFastMenuHeader ? '' : unit} za ${unitPrice ? formatPriceToFixed(unitPrice) : 0} ${getCurrencySymbol()}`;

    return (
      <Box flexDirection="row" justifyContent="space-between">
        <Text scale={-1} lineHeight={20} color={color}>{leftPart}</Text>
        <Text scale={-1} lineHeight={20} color={color}>{rightPart}</Text>
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(OrderLineDetail);
