// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  logo: {
    defaultMessage: 'Tlačiť logo',
    id: 'admin.form.logo'
  },
  state: {
    defaultMessage: 'Stav kasy',
    id: 'admin.form.state'
  },
});
