// @flow
import type {
  OpenTable, State, SubTable, Id, OrderLine,
  TableDefinition, FoodCourse, AllParameters, Action, User
} from '../types';
import {
  filter, propEq, reduce, map, sort,
  find, findIndex, update, prop, pluck, contains, last, times, sortBy, pipe, uniqWith, reject,
  groupBy, pathOr
} from 'rambda';
import {
  mergeDeepRight, findLast, eqProps, maxBy, sum, descend
} from 'ramda';
import {
  calculateOrderLinePriceWithParams,
  getAllOrderLinesOfOpenTable,
  mapOrderlinesIdToOrderlines,
  mapOrderLinesToOpenTables
} from '../order/utils';
import {
  clearSelectedOrderLines,
  relocateToSubTable,
  relocateToSubTableCommit,
  relocatingOrderLinesIdsWaitedForCommit,
  relocatingPreviousOpenTableIds,
  toggleRelocatingToTable,
  updateOrderLinesLocal
} from '../order/actions';
import { addOpenTable, addSubTable } from './actions';

export const computePriceForOpenTable =
(openTableId: Id, subTables: SubTable[], orderLines: OrderLine[], allParameters: AllParameters): number => {

  const orderLinesForOpenTable = getAllOrderLinesOfOpenTable(subTables, orderLines, openTableId);

  const prices = map(
    (orderLine: OrderLine) =>
      calculateOrderLinePriceWithParams(orderLine, allParameters.parameters),
    orderLinesForOpenTable
  );

  return sum(prices);
};

export const tablesInServiceArea =
(tables: TableDefinition[], serviceAreaId: Id): TableDefinition[] =>
  filter(propEq('serviceAreaId', serviceAreaId), tables);

export const sortedTablesWithOpenTables =
(tables: TableDefinition[], openTables: OpenTable[], user: User): TableDefinition[] => {
  const splitByEmployee = groupBy(({ openTable: { employeeId } }) =>
    employeeId === pathOr('000000', 'id', user) ? 'a' : 'b');

  const byTime = descend(x => x.openTable.openedTime);

  const filteredTables = pipe(
    map((table: TableDefinition) => {
      const openTable = find(propEq('tableDefinitionId', table.id), openTables);
      return ({ ...table, openTable });
    }),
    filter(prop('openTable')),
    splitByEmployee,
    ({ a, b }) => [...(a ? sort(byTime, a) : []), ...(b ? sort(byTime, b) : [])]
    // map(dissoc('openTable'))
  )(tables);

  return filteredTables;
};

export const tablesWithoutOpenTables = (tables: TableDefinition[], openTables: OpenTable[]) =>
  reject(
    (table: TableDefinition) => find(propEq('tableDefinitionId', table.id), openTables),
    tables
  );

export const updateTablesGeometry =
(tables: TableDefinition[], updateTable: TableDefinition): TableDefinition[] => {
  const { id, geometry } = updateTable;

  const mergeTableGeo = {
    id,
    geometry
  };

  const mergedTable = mergeDeepRight(
    find(eqProps('id', mergeTableGeo), tables),
    mergeTableGeo
  );

  const mergedTableIndex = findIndex(eqProps('id', mergedTable), tables);
  const updatedTables = update(mergedTableIndex, mergedTable, tables);

  return updatedTables;
};

export const maxTableNumber = (tables: TableDefinition[]): number =>
  reduce(maxBy(prop('number')), { number: 0 }, tables).number;

export const hasOpenTableOrderLine = (openTable: OpenTable, orderLines: OrderLine[],
                                      allSubTables: SubTable[]): boolean => {
  const openTablesWithOrderLines = mapOrderLinesToOpenTables(orderLines, allSubTables, [openTable]);
  const ids = pluck('id', openTablesWithOrderLines);

  return contains(openTable.id, ids);
};

export const belongsOpenTableToEmployee = (openTable: OpenTable, employeeId: Id) =>
  !openTable.employeeId || (+openTable.employeeId === +employeeId);

export const belongsOpenTableWithFirstOrderLineToEmployee = (openTable: OpenTable, firstOrderLineEmployeeId: Id, employeeId: Id) =>
  !openTable.employeeId || (+firstOrderLineEmployeeId === +employeeId);

export const findEmployeeFromLastCreatedOrderLine = (orderLines: OrderLine[], openTable: OpenTable, allSubTables: SubTable[]) => {
  if (!openTable || !allSubTables) {
    return undefined;
  }
  const subTablesIdsByTable = pluck('id', allSubTables.filter(st => st.openTableId === openTable.id));

  const orderLinesWithEmployee = orderLines.filter(o => subTablesIdsByTable.includes(o.subTableId) && o.employeeId);
  return orderLinesWithEmployee && orderLinesWithEmployee.length ? last(sortBy(prop('createdAt'), orderLinesWithEmployee)).employeeId : undefined;
};

export const findEmployeeFromFirstCreatedOrderLine = (orderLines: OrderLine[], openTable: OpenTable, allSubTables: SubTable[]) => {
  if (!openTable || !allSubTables) {
    return undefined;
  }
  const subTablesIdsByTable = pluck('id', allSubTables.filter(st => st.openTableId === openTable.id));

  const orderLinesWithEmployee = orderLines.filter(o => subTablesIdsByTable.includes(o.subTableId) && o.employeeId);
  return orderLinesWithEmployee && orderLinesWithEmployee.length ? sortBy(prop('createdAt'), orderLinesWithEmployee)[0].employeeId : undefined;
};

export const findUsedOpenTables = (tableDefinitionId: Id, openTables: OpenTable[]): OpenTable =>
  filter(propEq('tableDefinitionId', tableDefinitionId), openTables);

export const isTableSplitted = (tables: OpenTable[], tableDefinitionId): boolean =>
  findUsedOpenTables(tableDefinitionId, tables).length >= 2;

export const getFoodCourseIncrement = (openTableId: Id, state: State): number => {
  const foodCourses = findLast(propEq('openTableId', openTableId), state.tables.foodCourses);
  return foodCourses
    ? foodCourses.id + 1
    : 1;
};

export const extractFoodCourses = (orderLines: OrderLine[], subTables: SubTable[]): FoodCourse[] => {
  const shrunkSubTables = uniqWith(
    (x, y) => (x.id === y.id) && (x.openTableId === y.openTableId),
    subTables
  );

  const shrunkOrderLines = uniqWith(
    (x, y) => (x.foodCourse === y.foodCourse) && (x.subTableId === y.subTableId),
    orderLines
  );

  const extractedFoodCourses = pipe(
    filter(({ foodCourse }) => foodCourse > 0),
    map(({ foodCourse, subTableId }) => {
      const accordingSubtable = find(propEq('id', subTableId), shrunkSubTables);
      return {
        id: foodCourse || 0,
        openTableId: accordingSubtable ? accordingSubtable.openTableId : null
      };
    })
  )(shrunkOrderLines);

  const groupByOpenTableId = groupBy(st => st.openTableId);

  const groupedFoodCourses = Object.values(
    groupByOpenTableId(uniqWith(
      (x, y) => (x.id === y.id) && (x.openTableId === y.openTableId),
      extractedFoodCourses
    ))
  );

  const constructedFoodCourses = [];
  const sortAsc = (a, b) => (a.id - b.id);

  groupedFoodCourses.forEach(gfc => {
    const lastInGroup = last(sort(sortAsc, gfc));
    let newId = 1;
    times(
      () => {
        constructedFoodCourses.push({ id: newId, openTableId: lastInGroup.openTableId });
        newId++;
      },
      lastInGroup.id
    );
  });

  return sortBy(prop('id'), constructedFoodCourses);
};

export const generateOpenTableName = (table: TableDefinition, openTables: OpenTable[]) => {
  const openTablesForTable = filter(propEq('tableDefinitionId', table.id), openTables);

  let order = '';

  if (!openTablesForTable.length) {
    order = '1';
  } else if (openTablesForTable[0].name.endsWith('1')) {
    order = '2';
  } else {
    order = '1';
  }

  const name = `${table.id}-${order}`;

  return name;
};

export const subTablesCountForOpenTable = (openTableId: Id, allSubTables: SubTable[]) => {
  const subTables = filter(propEq('openTableId', openTableId), allSubTables);
  return subTables.length;
};

export const foodCoursesCountForOpenTable = (openTableId: Id, allFoodCourses: FoodCourse[]) => {
  const foodCourses = filter(propEq('openTableId', openTableId), allFoodCourses);
  return foodCourses.length;
};

export const findOpenTableBar = (openTables: OpenTable[], barType?: string) => {
  if (barType === 'bar' || barType === 'barCash') {
    return find(openTable => openTable[barType], openTables);
  }

  return find(openTable => openTable.barCash || openTable.bar, openTables);
};

const getUpdatingOrderLines = (orderLines, allSubTables, subTableId, openTableId, table) => {
  if (orderLines) {
    let openTableIdValue;
    if (subTableId && allSubTables) {
      const subTable = allSubTables.find(s => s.id === subTableId);
      openTableIdValue = subTable ? subTable.openTableId : undefined;
    } else if (openTableId) {
      openTableIdValue = openTableId;
    }


    return orderLines.map(o => ({
      id: o.id,
      tableDefinitionId: table.id,
      serviceAreaId: table.serviceAreaId,
      openTableId: openTableIdValue
    }));
  }
  return undefined;
};

export const startRelocatingOrderLinesToOpenTableOrSubTable = (
  openTableId: Id = null,
  subTableId: Id = null,
  selectedOrderLines: OrderLine[],
  table: TableDefinition,
  allOrderLines: OrderLine[],
  allOpenTables: OpenTable[],
  allSubTables: SubTable[]
): Action[] => {
  const actions = [
    toggleRelocatingToTable(false),
    clearSelectedOrderLines()
  ];

  const matchingOrderLines = mapOrderlinesIdToOrderlines(selectedOrderLines, allOrderLines);
  const previousSubTableIds = pluck('subTableId', matchingOrderLines);

  if (subTableId) {
    const committedOrderLines = filter(orderLine => !orderLine._local, matchingOrderLines);
    const localOrderLines = filter(orderLine => orderLine._local, matchingOrderLines);

    const previousOpenTableIds = allSubTables ? allSubTables.filter(t => previousSubTableIds.includes(t.id))
      .map(t => t.openTableId) : [];

    if (committedOrderLines.length) {
      actions.push(relocateToSubTable(pluck('id', committedOrderLines), subTableId));
      const updatingOrderLines = getUpdatingOrderLines(committedOrderLines, allSubTables, subTableId, openTableId, table);
      if (updatingOrderLines) {
        actions.push(updateOrderLinesLocal(updatingOrderLines));
      }
    }

    if (localOrderLines.length) {
      const localOrderLinesIds = pluck('id', localOrderLines);
      actions.push(relocateToSubTableCommit(localOrderLinesIds, subTableId));
      actions.push(relocatingOrderLinesIdsWaitedForCommit(localOrderLinesIds, subTableId));
      const updatingOrderLines = getUpdatingOrderLines(localOrderLines, allSubTables, subTableId, openTableId, table);
      if (updatingOrderLines) {
        actions.push(updateOrderLinesLocal(updatingOrderLines));
      }
    }

    if (previousOpenTableIds.length){
      actions.push(relocatingPreviousOpenTableIds(previousOpenTableIds));
    }
  } else {
    const flags = {
      // noSubtableActivation: true,
      relocateOrderLinesIds: pluck('id', matchingOrderLines),
    };

    if (openTableId) {
      actions.push(addSubTable(openTableId, flags));
    } else {
      actions.push(addOpenTable({
        tableDefinitionId: table.id,
        serviceAreaId: table.serviceAreaId,
        name: generateOpenTableName(table, allOpenTables)
      }, flags));
    }

    const updatingOrderLines = getUpdatingOrderLines(matchingOrderLines, allSubTables, subTableId, openTableId, table);
    if (updatingOrderLines) {
      actions.push(updateOrderLinesLocal(updatingOrderLines));
    }
  }

  return actions;
};

export const startCancelingReceiptAndReturningToSubTable = (
  openTableId: Id = null,
  subTableId: Id = null,
  table: TableDefinition,
  allOpenTables: OpenTable[],
  isCancelingReceiptAndReturningToTable
): Action[] => {
  const actions = [
    toggleRelocatingToTable(false),
    clearSelectedOrderLines()
  ];

  const flags = {
    cancelReceiptAndReturnToSubTable: isCancelingReceiptAndReturningToTable
  };

  if (openTableId) {
    actions.push(addSubTable(openTableId, flags));
  } else {
    actions.push(addOpenTable({
      tableDefinitionId: table.id,
      serviceAreaId: table.serviceAreaId,
      name: generateOpenTableName(table, allOpenTables)
    }, flags));
  }

  return actions;
};

export const findAllCommitedOpenTableIds = (
  openTables: OpenTable[],
  subTables: SubTable[],
  orderLines: OrderLine[],
  alsoUnconfirmed = false
) => {
  let commitedOpenTables;

  if (alsoUnconfirmed) {
    commitedOpenTables = filter(
      (openTable: OpenTable) => !openTable._local,
      openTables
    );
  } else {
    const confirmedOrderLines = reject(ol => !ol.isSentToKitchen, orderLines);

    commitedOpenTables = filter(
      (openTable: OpenTable) =>
        !openTable._local && hasOpenTableOrderLine(openTable, confirmedOrderLines, subTables),
      openTables
    );
  }

  return pluck('id', commitedOpenTables);
};
