// @flow
import checkPermission from '../../common/permissions/service';
import React from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';

class AllowedRoute extends React.PureComponent {
  render() {
    const { permission, redirect, component: Component, ...restProps } = this.props;

    const canAccess = checkPermission(permission);

    return (
      <Route
        {...restProps}
        render={props => (
          canAccess
            ? <Component {...props} />
            : <Redirect to={redirect} />
        )}
      />
    );
  }
}

export default AllowedRoute;
