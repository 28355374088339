// @flow
import type { Operations } from '../index';

const operations: Operations = (saveChanges) => ({
  sendKey(store, key) {
    switch (key) {
      case 'del':
        store.setNewInput(`${store.curInput().substring(0, store.curInput().length - 1)}`);
        break;

      case 'enter':
        // store.setNewInput(`${store.curInput()}\n`);
        break;

      case 'cancel':
        store.setNewInput('');
        break;

      case 'send':
        break;

      default:
        // fixme - not a very nice solution :)
        if (store.curInput() === '') {
          const newKey = key.substring(0, 2) === '; '
            ? key.substring(2)
            : key;
          store.setNewInput(`${store.curInput()}${newKey}`);
        } else {
          store.setNewInput(`${store.curInput()}${key}`);
        }
    }

    store.setLastKey(`${key}`);
    saveChanges(store);
  }
});

export default operations;
