// @flow
import React from 'react';
import { animateScroll } from 'react-scroll';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import Box from '../../common/components/Box';

const ScrollButtons = ({ containerId, vertical }) => (
  <Box flexDirection={vertical ? 'column' : 'row'}>
    <Button
      rounded
      size={2}
      lineHeight="normal"
      overflow="hidden"
      alignItems="center"
      justifyContent="center"
      marginRight={0.5}
      marginBottom={vertical ? 1 : 0}
      onPress={() => animateScroll.scrollMore(-250, {
          containerId,
          duration: 400
        })
      }
    >
      <Icon color="white" name="arrow-up" scale={-1} />
    </Button>
    <Button
      rounded
      size={2}
      lineHeight="normal"
      alignSelf="flex-start"
      overflow="hidden"
      alignItems="center"
      justifyContent="center"
      onPress={() => animateScroll.scrollMore(250, {
          containerId,
          duration: 400
        })
      }
    >
      <Icon color="white" name="arrow-down" scale={-1} />
    </Button>
  </Box>
);

export default ScrollButtons;
