// @flow
import React from 'react';
import { assocPath } from 'ramda';
import { connect } from 'react-redux';
import InactiveBg from '../components/InactiveBg';
import Popup from '../components/Popup';
import NumericButtons from '../../common/components/Keyboard/popupWeightForm/Buttons';
import createNumericOperations from '../../common/components/Keyboard/popupWeightForm/operations';
import Keyboard from '../../common/components/Keyboard';
import TextInput from '../../common/components/TextInput';
import Text from '../../common/components/Text';
import Box from '../../common/components/Box';
import { closestElementOfComponent } from '../lib/dom';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import type { State } from '../../common/types';
import api from '../../common/lib/api';
import { addAdminError } from '../../common/admin/general/actions';
import { togglePaymentRecordForm, updatePaymentTransaction } from '../../common/payment/actions';
import { processReceivedPaymentTransactionData } from '../../common/payment/utils';
import { formatDirectPriceToFixed } from '../../common/order/utils';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/payment';


class EditPaymentRecord extends React.PureComponent {
  constructor(props) {
    super(props);

    const { amount = 0, tip = 0 } = props.paymentRecord;

    this.preFocusField = props.paymentRecord.preFocusField || 'price';

    this.state = {
      focus: props.paymentRecord.preFocusField || 'price',
      fields: {
        price: Number(amount) / 100,
        tip: Number(tip) / 100
      }
    };
  }

  NumericKeyboard = (dispatch, refFn, fields, type, paymentRecord) => (
    <Box display="none">
      <Keyboard
        ref={refFn}
        createOperations={createNumericOperations}
        Buttons={NumericButtons}
        onDisplayValue={value => {
          this.setState({ fields: { ...this.state.fields, [type]: Number(value) } });
        }}
        onLastKey={key => {
          if (key === 'ok') {
            api.payment.editPaymentRecord(
              paymentRecord.paymentTransactionId,
              paymentRecord.id,
              (fields.price * 100) || 0,
              (fields.tip * 100) || 0,
              paymentRecord.wallet
            )
              .then(({ body }) => {
                dispatch([
                  updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
                  togglePaymentRecordForm(null)
                ]);
              })
              .catch(e => {
                const { intl } = this.props;
                console.error(intl.formatMessage(messages.paymentEditPaymentRecordError), e);
                dispatch(addAdminError(e));
              });
          }
        }}
        marginTop={0.5}
      />
    </Box>
  );

  InputField = (refFn, keyboard, input, fields, type) => {
    // console.log(this.state.focus, type, this.preFocusField);

    return (
      <Box flexDirection="row" marginBottom={1.5} ref={refFn}>
        <TextInput
          flex={1}
          flexGrow={0}
          maxLength={1000}
          padding={1}
          paddingRight={2}
          width={12}
          scale={2}
          autoFocus={type === this.preFocusField && type === this.state.focus}
          value={formatDirectPriceToFixed(fields[type])}
          onFocus={() => {
            if (!this[keyboard]) return;

            this.setState(
              { focus: type, fields: assocPath([type], 0, this.state.fields) },
              () => this[keyboard].sendKey('cancel')
            );
          }}
          onBlur={e => {
            if (!closestElementOfComponent(e.relatedTarget, this[keyboard])
              && !closestElementOfComponent(e.relatedTarget, input)) {
              this.setState({ focus: null });
            }
          }}
          onKeyPress={({ key }) => {
            this[keyboard].sendKey(key);
          }}
          onKeyDown={({ keyCode }) => {
            if (keyCode === 8) { // backspace
              this[keyboard].sendKey('del');
            }
          }}
          onChange={() => {
          }}
        />
        <Button
          position="absolute"
          top={0}
          bottom={0}
          right={0}
          paddingHorizontal={0.75}
          alignItems="center"
          onPress={() => {
            this.setState(
              { fields: { ...this.state.fields, [type]: 0 }, focus: type },
              () => this[keyboard].sendKey('cancel')
            );
          }}
        >
          <Icon color="black" name="delete" scale={2}/>
        </Button>
      </Box>
    );
  };

  toggleKeyboard = () => {
    const { dispatch } = this.props;

    dispatch(togglePaymentRecordForm(null));
  };

  render() {
    const { dispatch, paymentRecord, intl } = this.props;
    const { focus, fields } = this.state;

    return (
      <InactiveBg onClose={this.toggleKeyboard}>
        <Popup onClose={this.toggleKeyboard}>
          <Box flexDirection="row" width={12} justifyContent="space-between">
            <Box flex={1} marginRight={1.5}>
              <Text bold marginBottom={0.25} color="white">
                {`${intl.formatMessage(messages.paymentEditPaymentRecordPrice)}:`}
              </Text>
              {this.InputField(c => this.priceBlock = c, 'priceKeyboard', this.priceBlock, fields, 'price')}

              {paymentRecord.allowTip &&
                <React.Fragment>
                  <Text bold marginBottom={0.25} color="white">
                    {`${intl.formatMessage(messages.paymentEditPaymentRecordTip)}:`}
                  </Text>
                  {this.InputField(c => this.tipBlock = c, 'tipKeyboard', this.tipBlock, fields, 'tip')}
                </React.Fragment>}

              {focus === 'price' && this.NumericKeyboard(dispatch, c => this.priceKeyboard = c, fields, 'price', paymentRecord)}
              {focus !== 'price' && this.NumericKeyboard(dispatch, c => this.tipKeyboard = c, fields, 'tip', paymentRecord)}
            </Box>
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    paymentRecord: state.payment.editPaymentRecord
  })),
  injectIntl
)(EditPaymentRecord);
