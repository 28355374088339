// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';
import type { Id } from '../../types';

export const createSubTable = (
  openTableId: Id
): Promise =>
  fetch(apiEndpoint('v1/CreateSubTable'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      name: 'CreateSubTable',
      params: {
        openTableId
        // name: 'Host 19a'
      },
      id: '',
      session_id: '',
      device_id: ''
    }
  });

export const deleteSubTable = (
  subTableId: Id
): Promise =>
  fetch(apiEndpoint(`v1/DeleteSubTable/${subTableId}`), {
    method: 'DELETE',
    baseURL: getApiUrl(),
  });
