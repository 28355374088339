// @flow
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../common/components/Box';
import Popup from '../components/Popup';
import Keyboard from '../../common/components/Keyboard';
import TypewriterButtons from '../../common/components/Keyboard/typewriter/Buttons';
import Display from '../../common/components/Keyboard/typewriter/OneLineDisplay';
import createTypewriterOperations from '../../common/components/Keyboard/typewriter/operations';
import api from '../../common/lib/api';
import { addError } from '../../common/order/actions';
import { paymentOperationsName } from '../../common/lib/api/payment';
import orderKeeper, { RequestOrderError } from '../../common/lib/fetch/orderKeeper';
import InactiveBg from '../components/InactiveBg';
import Heading from '../../common/components/Heading';
import {
  toggleServiceCouponPopup,
  updatePaymentTransaction
} from '../../common/payment/actions';
import {compose, path} from 'rambda';
import { processReceivedPaymentTransactionData } from '../../common/payment/utils';
import type { State } from '../../common/types';
import {injectIntl} from 'react-intl';
import messages from '../../common/messages/payment';


class ServiceCouponPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: ''
    };
  }

  reqOrderKeeper = orderKeeper(paymentOperationsName);

  _onClose = () => {
    const { dispatch } = this.props;
    dispatch(toggleServiceCouponPopup(false));
  };

  addPayment = () => {
    const { dispatch, isServiceCouponPopupOpen: { paymentTransactionId, paymentMediaId, amount, additionalData } } = this.props;
    const { couponCode } = this.state;

    this.reqOrderKeeper(() =>
      api.payment.addPaymentRecord(paymentTransactionId, paymentMediaId, amount, { ...additionalData, coupon_code: couponCode })
    ).then(({ body }) => {
      if (path(['payment', 'id'], body)) {
        dispatch([
          updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
          toggleServiceCouponPopup(false)
        ]);
      }
    }).catch(e => {
      if (e instanceof RequestOrderError) return;

      dispatch([
        addError(e),
        toggleServiceCouponPopup(false)
      ]);
    });
  };

  render() {
    const { intl } = this.props;

    return (
      <InactiveBg onClose={this._onClose}>
        <Popup onClose={this._onClose}>
          <Heading scale={4} color="white" marginBottom={1}>{intl.formatMessage(messages.paymentServiceCouponCode)}</Heading>
          <Box alignItems="center">
            <Keyboard
              zIndex={2}
              style={theme => ({
                width: theme.typography.lineHeight(3) * 1.75 * 11 + theme.typography.lineHeight() * 7.5
              })}
              createOperations={createTypewriterOperations}
              Buttons={TypewriterButtons}
              Display={Display}
              onLastKey={key => {
                if (key === 'send') {
                  this.addPayment();
                }
              }}
              onDisplayValue={val => {
                this.setState({ couponCode: val });
              }}
            />
          </Box>
        </Popup>
      </InactiveBg>);
  };
}

export default compose(
  connect(
    (state: State) => ({
      isServiceCouponPopupOpen: state.payment.isServiceCouponPopupOpen,
    })
  ),
  injectIntl
)(ServiceCouponPopup);
