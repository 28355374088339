// @flow
import type {
  Discount, Id, PaymentMedia, PaymentType, State
} from '../types';
import { createSelector } from 'reselect';
import { filter, contains } from 'rambda';
import { leftJoin } from '../lib/moreRambda';
import messages from '../messages/common';

const paymentMediaSelector = (state: State) => state.payment.paymentMedia;

const availablePaymentMediaSelector = (state: State) => state.payment.availablePaymentMedia;

const paymentTypesSelector = (state: State) => state.payment.paymentTypes;

const knownDiscountsSelector = (state: State) => state.payment.knownDiscounts;

const availableDiscountsSelector = (state: State) => state.payment.availableDiscounts;

export const pendingNetworkOperation = (state: State) => ({
  isActive: state.payment.formDisabled || state.orders.quickPayment.active,
  text: state.intl.useIntl.formatMessage(messages.pendingPayment)
});

export const pendingTerminalOperation = (state: State) => ({
    isActive: state.payment.terminal || false,
    text: state.payment.terminalMessage
  });

export const validPaymentMediaSelector = createSelector(
  paymentMediaSelector,
  availablePaymentMediaSelector,
  paymentTypesSelector,
  (knownPaymentMedia: PaymentMedia[], availablePaymentMedia: Id[], paymentTypes: PaymentType[]) => {
    const completePaymentMedia = leftJoin(
      ({ druhyPlId }, { idriadok }) => druhyPlId === idriadok,
      knownPaymentMedia,
      paymentTypes
    );

    return filter(({ id }) => contains(id, availablePaymentMedia), completePaymentMedia);
  }
);

export const validDiscountsSelector = createSelector(
  knownDiscountsSelector,
  availableDiscountsSelector,
  (knownDiscounts: Discount[], availableDiscounts: Id[]) =>
    filter(({ idriadok }) => contains(idriadok, availableDiscounts), knownDiscounts)
);
