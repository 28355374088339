// @flow
import type { Action, Deps, Id, OrderLine, DateString } from '../types';
import api from '../lib/api';
import { pluck, map, find, propEq, path, filter, pathOr } from 'rambda';
import {clone, isEmpty} from 'ramda';
import {
  pickOrderLineApiData,
  constructCombinedNote,
  computeItemPrice,
  confirmUpdatedOrderLines,
  addTablesDefinitionsToOrderLines,
  isInOrderLines,
  isValidSubTableId,
  isValidTableDefinitionId,
  isHashValue, getAllOrderLinesOfOpenTable, getAllOrderLinesOfOpenTableWithLookUpId
} from './utils';
import moment from 'moment';
import { ApiError } from '../lib/fetch/errors';
import { roundNumber } from '../lib/numbers';
import { activeOpenTableIdSelector, subTablesForActiveOpenTableSelector } from '../tables/selectors';
import { completeOffline } from '../network/actions';
import { addSubTable, deleteOpenTable } from '../tables/actions';
import uuid from 'uuid';

export const toggleFilter = (toggle: boolean) => ({
  type: 'ORDER_FILTER_TOGGLE',
  payload: {
    toggle
  }
});

export const toggleMenuScrollButtons = (show: boolean) => ({
  type: 'ORDER_MENU_SCROLL_BUTTONS_TOGGLE',
  payload: {
    show
  }
});

export const setFilterValue = (value: string) => ({
  type: 'ORDER_SET_FILTER_VALUE',
  payload: {
    value
  }
});

export const addError = (error: Error) => ({
  type: 'ORDER_ADD_ERROR',
  payload: { error }
});

export const clearError = () => ({
  type: 'ORDER_CLEAR_ERROR',
  payload: {}
});

export const addNoteLocal = (preparedOrderLines: Object[], updatedAt: DateString) => ({
  type: 'ORDER_LINE_ADD_NOTE',
  payload: { preparedOrderLines, updatedAt }
});

export const addNoteRollback = (ids: Id[], error: ApiError) => ({
  type: 'ORDER_LINE_ADD_NOTE_ROLLBACK',
  payload: { ids, error }
});
// TODO - make universal/generic change method using addItems
export const addNote = (ids: Id[], note: string, usedOldOrderText: boolean) =>
  ({ lookUpId, getState }) => {
    const stateOrderLines = getState().orders.orderLines;
    const preparedOrderLines = ids
      .filter(id => find(propEq('id', lookUpId(id)))(stateOrderLines))
      .map(id => {
        const orderLine = find(propEq('id', lookUpId(id)))(stateOrderLines);
        return ({
          id: lookUpId(id),
          orderText: constructCombinedNote(lookUpId(id), note, usedOldOrderText, stateOrderLines),
          _local: orderLine._local
        });
      });
    const updatedAt = moment().toJSON();

    const preparedOrderLineIds = pluck('id', preparedOrderLines);
    const confirmedPreparedOrderLines = preparedOrderLines.filter(pol => !pol._local);

    return !confirmedPreparedOrderLines.length
      ? addNoteLocal(preparedOrderLines, updatedAt)
      : ({
        ...addNoteLocal(preparedOrderLines, updatedAt),
        meta: {
          offline: {
            effect: () => {
              const orderLines = confirmedPreparedOrderLines.map(pol => {
                const orderLine = find(propEq('id', pol.id))(stateOrderLines);
                return {
                  id: orderLine._local ? undefined : lookUpId(orderLine.id), // create or update
                  itemId: orderLine.itemId,
                  subTableId: lookUpId(orderLine.subTableId),
                  data: { orderText: pol.orderText, updatedAt }
                };
              });

              return api.item.addItems(
                addTablesDefinitionsToOrderLines(
                  orderLines,
                  getState().tables.subTables,
                  getState().tables.openTables
                )
              );
            },
            commit: response => [
              completeOffline(),
              confirmUpdatedOrderLines(undefined, ['orderText', 'updatedAt'])(response)
            ],
            rollback: ({ payload }) => [
              completeOffline(),
              addNoteRollback(preparedOrderLineIds, payload)
            ]
          }
        }
      });
  };

export const relocateToFoodCourseLocal = (ids: Id[], foodCourse: Id, updatedAt: DateString) => ({
  type: 'ORDER_LINE_RELOCATE_TO_FOOD_COURSE',
  payload: { ids, foodCourse, updatedAt },
});

export const relocateToFoodCourseRollback = (ids: Id[], error: ApiError) => ({
  type: 'ORDER_LINE_RELOCATE_TO_FOOD_COURSE_ROLLBACK',
  payload: { ids, error }
});

// TODO - make universal/generic change method using addItems
export const relocateToFoodCourse = (ids: Id[], foodCourse: Id) =>
  ({ lookUpId, getState }) => {
    const stateOrderLines = getState().orders.orderLines;
    const preparedOrderLines = ids
      .filter(id => find(propEq('id', lookUpId(id)))(stateOrderLines))
      .map(id => {
        const orderLine = find(propEq('id', lookUpId(id)))(stateOrderLines);
        return ({
          id: lookUpId(id),
          foodCourse,
          _local: orderLine._local
        });
      });
    const updatedAt = moment().toJSON();

    const preparedOrderLineIds = pluck('id', preparedOrderLines);
    const confirmedPreparedOrderLines = preparedOrderLines.filter(pol => !pol._local);

    return !confirmedPreparedOrderLines.length
      ? relocateToFoodCourseLocal(ids, foodCourse, updatedAt)
      : ({
        ...relocateToFoodCourseLocal(ids, foodCourse, updatedAt),
        meta: {
          offline: {
            effect: () => {
              const orderLines = confirmedPreparedOrderLines.map(pol => {
                const orderLine = find(propEq('id', pol.id))(stateOrderLines);
                return ({
                  id: orderLine._local ? undefined : lookUpId(orderLine.id), // create or update
                  itemId: orderLine.itemId,
                  subTableId: lookUpId(orderLine.subTableId),
                  data: { foodCourse, updatedAt }
                });
              });

              return api.item.addItems(
                addTablesDefinitionsToOrderLines(
                  orderLines,
                  getState().tables.subTables,
                  getState().tables.openTables
                )
              );
            },
            commit: response => [
              completeOffline(),
              confirmUpdatedOrderLines(undefined, ['foodCourse', 'updatedAt'])(response)
            ],
            rollback: ({ payload }) => [
              completeOffline(),
              relocateToFoodCourseRollback(preparedOrderLineIds, payload)
            ]
          }
        }
      });
  };

export const changePriceLevelOfOrderLineLocal = (preparedOrderLines: Object[], priceLevelId: Id, updatedAt: DateString) => ({
  type: 'ORDER_LINE_CHANGE_PRICE_LEVEL',
  payload: { preparedOrderLines, priceLevelId, updatedAt },
});

export const changePriceLevelOfOrderLineRollback = (ids: Id[], error: ApiError) => ({
  type: 'ORDER_LINE_CHANGE_PRICE_LEVEL_ROLLBACK',
  payload: { ids, error }
});

// TODO - make universal/generic change method using addItems
export const changePriceLevelOfOrderLine = (orderLineIds: Id[], priceLevelId: Id) =>
  ({ lookUpId, getState }) => {
    const {
      orders: { orderLines: stateOrderLines },
      items: { itemPrices: stateItemPrices },
    } = getState();

    const preparedOrderLines = orderLineIds
      .filter(id => {
        const orderLine = find(propEq('id', lookUpId(id)), stateOrderLines);
        if (orderLine) {
          return orderLine.orderType !== 11; // ignore items in fastmenu
        }

        return orderLine;
      })
      .map(id => {
        const orderLine = find(propEq('id', lookUpId(id)))(stateOrderLines);

        return {
          id: lookUpId(id),
          singlePrice: roundNumber(computeItemPrice(stateItemPrices, orderLine.itemId,
            priceLevelId)),
          _local: orderLine._local
        };
      });
    const updatedAt = moment().toJSON();

    const preparedOrderLineIds = pluck('id', preparedOrderLines);
    const confirmedPreparedOrderLines = preparedOrderLines.filter(pol => !pol._local);

    return !confirmedPreparedOrderLines.length
      ? changePriceLevelOfOrderLineLocal(preparedOrderLines, priceLevelId, updatedAt)
      : ({
          ...changePriceLevelOfOrderLineLocal(preparedOrderLines, priceLevelId, updatedAt),
          meta: {
            offline: {
              effect: () => {
                const orderLines = confirmedPreparedOrderLines.map(pol => {
                  const orderLine = find(propEq('id', pol.id))(stateOrderLines);
                  return ({
                    id: orderLine._local ? undefined : lookUpId(orderLine.id), // create or update
                    itemId: orderLine.itemId,
                    subTableId: lookUpId(orderLine.subTableId),
                    data: { priceLevelId, updatedAt }
                  });
                });

                return api.item.addItems(
                  addTablesDefinitionsToOrderLines(
                    orderLines,
                    getState().tables.subTables,
                    getState().tables.openTables
                  )
                );
              },
              commit: response => [
                completeOffline(),
                confirmUpdatedOrderLines(undefined, ['priceLevelId', 'singlePrice', 'updatedAt'])(response)
              ],
              rollback: ({ payload }) => [
                completeOffline(),
                changePriceLevelOfOrderLineRollback(preparedOrderLineIds, payload)
              ]
            }
          }
        });
  };

export const changePortionLocal =
(preparedOrderLines: Object[], portion: number, updatedAt: DateString) => ({
  type: 'ORDER_LINE_CHANGE_PORTION',
  payload: {
    preparedOrderLines,
    portion,
    updatedAt,
  },
});

export const changePortionRollback =
(ids: Id[], error: ApiError, desiredPortion: number) => ({
  type: 'ORDER_LINE_CHANGE_PORTION_ROLLBACK',
  payload: {
    ids,
    error,
    // for epics only:
    desiredPortion
  }
});

// TODO - make universal/generic change method using addItems
export const changePortion = (orderLinesIds: Id[], portion: number) =>
  ({ lookUpId, getState }) => {
    const stateOrderLines = getState().orders.orderLines;
    const preparedOrderLines = orderLinesIds
      .filter(id => find(propEq('id', lookUpId(id)), stateOrderLines))
      .map(id => {
        const orderLine = find(propEq('id', lookUpId(id)), stateOrderLines);
        return {
          ...orderLine,
          id: lookUpId(id),
          oldPortion: orderLine.portion, // hackish
          portion,
          _local: orderLine._local
        };
      });
    const updatedAt = moment().toJSON();

    const preparedOrderLineIds = pluck('id', preparedOrderLines);
    const confirmedPreparedOrderLines = preparedOrderLines.filter(pol => !pol._local);

    return !confirmedPreparedOrderLines.length
      ? changePortionLocal(preparedOrderLines, portion, updatedAt)
      : ({
          ...changePortionLocal(preparedOrderLines, portion, updatedAt),
          meta: {
            offline: {
              effect: () => {
                const orderLines = confirmedPreparedOrderLines.map(pol => {
                  const orderLine = find(propEq('id', pol.id))(stateOrderLines);
                  return ({
                    id: orderLine._local ? undefined : lookUpId(orderLine.id), // create or update
                    itemId: orderLine.itemId,
                    subTableId: lookUpId(orderLine.subTableId),
                    data: { portion, updatedAt }
                  });
                });

                return api.item.addItems(
                  addTablesDefinitionsToOrderLines(
                    orderLines,
                    getState().tables.subTables,
                    getState().tables.openTables
                  )
                );
              },
              commit: response => [
                completeOffline(),
                confirmUpdatedOrderLines({ oldOrderLines: preparedOrderLines }, ['portion', 'updatedAt'])(response)
              ],
              rollback: ({ payload }) => [
                completeOffline(),
                changePortionRollback(preparedOrderLineIds, payload, portion)
              ]
            }
          }
        });
  };

export const toggleOrderLineSelect = (ids: Id[], flags = {}) => ({ lookUpId }) => ({
  type: 'ORDER_LINE_SELECT',
  payload: { ids: map(lookUpId, ids), flags },
});

export const clearSelectedOrderLines = (): Action => ({
  type: 'ORDER_LINES_CLEAR_SELECTED',
  payload: {}
});

export const addOrderLine = (orderLine: OrderLine = {}, flags = {}) =>
  (deps: Deps): Action => {
    orderLine = {
      ...{
        unit: '',
        orderType: 0,
        portion: 1,
        foodCourse: 0,
        orderText: '',
        priceFactor: 1,
        quantity: 1,
        isOnHold: false,
        allowCancel: true,
        sortOrder: 1,
        createdAt: moment().toJSON(),
        updatedAt: moment().toJSON(),
        isSentToKitchen: true,
        hasCustomPrice: false
      },
      ...filter(param => !!param, orderLine)
    };

    const { getUid, getState } = deps;
    const { orderType, linkedToId, quantity } = orderLine;
    let { subTableId } = orderLine;

    const uid = orderType === 10 && linkedToId
      ? linkedToId
      : getUid();

    if (!subTableId || subTableId === 'all') {
      subTableId = path([0, 'id'], subTablesForActiveOpenTableSelector(getState()));
    }

    const payloadOrderLine = {
      ...orderLine,
      id: uid,
      subTableId,
      linkedToId: orderType === 11 ? linkedToId : undefined,
      paidQuantity: quantity,
      customGroupHash: orderLine.customGroupHash || uuid.v4()
    };

    if (!subTableId) {
      const openTableId = activeOpenTableIdSelector(getState());
      if (openTableId) {
        return addSubTable(openTableId, {
          orderLinesToBeAdded: [{ ...payloadOrderLine, openTableId }]
        })(deps);
      }
    }

    const orderLineWithTableDef = addTablesDefinitionsToOrderLines(
      [payloadOrderLine],
      getState().tables.subTables,
      getState().tables.openTables
    );

    return ({
      type: 'ORDER_LINE_ADD',
      payload: {
        orderLine: orderLineWithTableDef[0],
        flags
      }
    });
  };

export const deleteOrderLinesLocal = (ids: Id[], oldOrderLines) => ({
  type: 'ORDER_LINES_DELETE',
  payload: { ids, oldOrderLines }
});

export const deleteOrderLinesCommit = (ids: Id[], flags, oldOrderLines) => ({
  type: 'ORDER_LINES_DELETE_COMMIT',
  payload: { ids, flags, oldOrderLines }
});

export const deleteOrderLinesRollback = (ids: Id[], error: ApiError) => ({
  type: 'ORDER_LINES_DELETE_ROLLBACK',
  payload: { ids, error }
});

export const deleteOrderLines = (ids: Id[], flags) =>
  ({ lookUpId, getState }) => {
    const stateOrderLines = getState().orders.orderLines;

    const preparedOrderLines = ids
      .filter(id => find(propEq('id', lookUpId(id)))(stateOrderLines))
      .map(id => {
        const orderLine = find(propEq('id', lookUpId(id)))(stateOrderLines);
        return ({
          id: lookUpId(id),
          _local: orderLine._local
        });
      });

    const preparedOrderLineIds = pluck('id', preparedOrderLines);
    const confirmedPreparedOrderLineIds = pluck('id', preparedOrderLines.filter(pol => !pol._local));

    return !confirmedPreparedOrderLineIds.length
      ? deleteOrderLinesLocal(preparedOrderLineIds, stateOrderLines)
      : {
        ...deleteOrderLinesLocal(preparedOrderLineIds, stateOrderLines),
        meta: {
          offline: {
            effect: () => api.item.deleteItems(confirmedPreparedOrderLineIds),
            commit: ({ payload: { body: { result: { deletedItems: deletedOrderLines, refusedItems: refusedOrderLinesId } } } }) => [
              completeOffline(),
              deleteOrderLinesCommit(deletedOrderLines, { ...flags, revertLocalAvailability: true }, stateOrderLines),
              deleteOrderLinesRollback(refusedOrderLinesId, undefined)
            ],
            rollback: ({ payload }) => {
              if (typeof payload.message !== 'string' || typeof payload.status !== 'number') { // if it is not global server error (like 500)
                const { deletedItems: deletedOrderLines, refusedItems: refusedOrderLines } = payload.body.result;
                return [
                  completeOffline(),
                  deleteOrderLinesCommit(deletedOrderLines, { ...flags, revertLocalAvailability: true }, stateOrderLines),
                  deleteOrderLinesRollback(refusedOrderLines, payload)
                ]
              } else {
               return [
                 completeOffline(),
                 deleteOrderLinesRollback(preparedOrderLineIds, payload)
               ]
              }
            }
          }
        }
      };
  };

export const toggleRelocatingToFoodCourse = (is: boolean) => ({
  type: 'ORDER_LINE_IS_RELOCATING_TO_FOOD_COURSE',
  payload: { is }
});

export const toggleRelocatingToSubTable = (is: boolean) => ({
  type: 'ORDER_LINE_IS_RELOCATING_TO_SUB_TABLE',
  payload: { is }
});

export const toggleDeletingSubTable = (id: Id) => ({
  type: 'ORDER_LINE_IS_DELETING_SUB_TABLE',
  payload: { id }
});

export const toggleRelocatingToTable = (is: boolean) => ({
  type: 'ORDER_LINE_IS_RELOCATING_TO_TABLE',
  payload: { is }
});

export const changeAddingItemCount = (count: number) => ({
  type: 'ORDER_LINE_CHANGE_ADDING_ITEM_COUNT',
  payload: { count }
});

export const toggleIsChangingPriceLevel = (is: boolean) => ({
  type: 'ORDER_LINE_IS_CHANGING_PRICE_LEVEL',
  payload: { is }
});

export const toggleIsChangingPortion = (is: boolean) => ({
  type: 'ORDER_LINE_IS_CHANGING_PORTION',
  payload: { is }
});

export const relocateToSubTableLocal = (orderLineIds: Id[], subTableId: Id, updatedAt: DateString): Action => ({
  type: 'ORDER_LINE_RELOCATE_TO_SUB_TABLE',
  payload: { orderLineIds, subTableId, updatedAt },
});

export const relocateToSubTableCommit = (orderLineIds: Id[], subTableId: Id, updatedAt: DateString, flags): Action => ({
  type: 'ORDER_LINE_RELOCATE_TO_SUB_TABLE_COMMIT',
  payload: { orderLineIds, subTableId, updatedAt, flags }
});

export const relocatingPreviousOpenTableIds = (previousOpenTableIds: Id[]): Action => ({
  type: 'RELOCATING_PREVIOUS_OPEN_TABLE_IDS',
  payload: {
    previousOpenTableIds
  },
  meta: {
    offline: {
      effect: () => {
        return Promise.resolve({ body: { result: {} } });
      },
      commit: () => [completeOffline()],
      rollback: () => [completeOffline()]
    }
  }
});

export const relocatingOrderLinesIdsWaitedForCommit = (orderLinesIds: Id[], subTableId: Id) =>
  ({ getState }) =>  ({
  type: 'RELOCATING_ORDER_LINES_IDS',
  payload: {
    orderLinesIds,
    subTableId
  },
  meta: {
    offline: {
      // eslint-disable-next-line consistent-return
      effect: () => {
        const orderLines = pathOr([], 'orders.orderLines', getState());
        if (!orderLines.filter(o => orderLinesIds.includes(o.id)).find(o => o._local)) {
          return Promise.resolve({ body: { result: {} } });
        }
      },
      commit: () => [completeOffline()],
      rollback: () => [completeOffline()]
    }
  }
});

export const relocateToSubTableRollback = (orderLineIds: Id[], subTableId: Id, error: ApiError): Action => ({
  type: 'ORDER_LINE_RELOCATE_TO_SUB_TABLE_ROLLBACK',
  payload: { orderLineIds, subTableId, error }
});

export const relocateToSubTable = (orderLineIds: Id[], subTableId: Id) =>
  ({ lookUpId, getState }) => {
    const stateOrderLines = clone(pathOr([], 'orders.orderLines', getState()));
    const stateSubTables = clone(pathOr([], 'tables.subTables', getState()));
    const stateOpenTables = clone(pathOr([], 'tables.openTables', getState()));
    const stateServiceAreas = clone(pathOr([], 'serviceAreas.serviceAreas', getState()));

    const filteredStatedOrderLinesIds = orderLineIds.map(id => (lookUpId(id)));
    const filteredStatedOrderLines = stateOrderLines.filter(orderline => filteredStatedOrderLinesIds.includes(lookUpId(orderline.id)));
    const previousSubTableIds = pluck('subTableId', filteredStatedOrderLines);

    const preparedOrderLines = orderLineIds
      .filter(id => find(propEq('id', lookUpId(id)))(stateOrderLines))
      .map(id => {
        const orderLine = find(propEq('id', lookUpId(id)))(stateOrderLines);
        return ({
          id: lookUpId(id),
          _local: orderLine ? orderLine._local : true
        });
      });

    const updatedAt = moment().toJSON();

    const preparedOrderLineIds = pluck('id', preparedOrderLines);

    const confirmedPreparedOrderLineIds = pluck('id', preparedOrderLines.filter(pol => !pol._local));

    const rollbackSubTableId = filteredStatedOrderLines && filteredStatedOrderLines.length > 0 ?
      filteredStatedOrderLines[0].subTableId : subTableId;

    return !confirmedPreparedOrderLineIds.length
      ? relocateToSubTableCommit(preparedOrderLineIds, lookUpId(subTableId), updatedAt)
      : ({
          ...relocateToSubTableLocal(preparedOrderLineIds, lookUpId(subTableId), updatedAt),
          meta: {
            offline: {
              effect: () => api.item.moveItems(
                confirmedPreparedOrderLineIds,
                lookUpId(subTableId)
              ),
              commit: ({ payload: { body: { result: { movedItems, refusedItems } } } }) => {
                const actions = [
                  completeOffline(),
                  relocateToSubTableCommit(pluck('orderLineId', movedItems), lookUpId(subTableId), updatedAt),
                  relocateToSubTableRollback(pluck('orderLineId', refusedItems), lookUpId(rollbackSubTableId), undefined) // TODO add partial errors from api (not created by client yet)
                ];

                if (previousSubTableIds && previousSubTableIds.length > 0) {
                  const previousOpenTableIds = [];
                  const previousSubTables = pathOr([], 'tables.subTables', getState()).filter(t => previousSubTableIds.includes(t.id));
                  previousSubTables.forEach(t => {
                    if (!previousOpenTableIds.includes(t.openTableId)) {
                      previousOpenTableIds.push(t.openTableId);
                    }
                  });

                  const movedSubTableIds = pluck('defSubTableId', movedItems);

                  previousOpenTableIds.forEach(id => {
                    const allSubTablesInOpenTable = pluck('subTableId', getAllOrderLinesOfOpenTableWithLookUpId(stateSubTables, stateOrderLines, lookUpId(id)));
                    if (allSubTablesInOpenTable.length === 0 || JSON.stringify(movedSubTableIds) === JSON.stringify(allSubTablesInOpenTable)) {
                      const previousOpenTable = stateOpenTables.find(t => t.id === id);
                      if (previousOpenTable) {
                        const previousServiceArea = stateServiceAreas.find(s => s.id === previousOpenTable.serviceAreaId);
                        if (previousServiceArea && previousServiceArea.isVirtual && !previousServiceArea.isLimit) {
                          actions.push(deleteOpenTable(previousOpenTable.id, previousOpenTable.tableDefinitionId));
                        } else {
                          actions.push(deleteOpenTable(id));
                        }
                      }
                    }

                  });
                }

                return actions;
              },
              rollback: ({ payload }) => {
                if (typeof payload.message !== 'string' || typeof payload.status !== 'number') { // if it is not global server error (like 500)
                  const { movedItems, refusedItems } = payload.body.result;
                  return [
                    completeOffline(),
                    relocateToSubTableCommit(pluck('orderLineId', movedItems), lookUpId(subTableId), updatedAt),
                    relocateToSubTableRollback(pluck('orderLineId', refusedItems), lookUpId(rollbackSubTableId), payload)
                  ];
                } else {
                  return [
                    completeOffline(),
                    relocateToSubTableRollback(preparedOrderLineIds, lookUpId(rollbackSubTableId), payload)
                  ];
                }
              }
            }
          }
        });
  };

export const toggleTextInput = (open: boolean | string | Object) => ({
  type: 'ORDER_TEXT_INPUT_TOGGLE',
  payload: { open }
});

export const setActiveRoomsFilterListener = (isActive: boolean) => ({
  type: 'SET_ACTIVE_ROOMS_FILTER_LISTENER',
  payload: { isActive }
});

export const confirmOrderLineCommit =
(orderLinesIds: Id[], orderLines: OrderLine[], changedProps: string[], flags = {}) => ({
  type: 'ORDER_LINES_CONFIRM_COMMIT',
  payload: { orderLinesIds, orderLines, changedProps, flags }
});

export const confirmOrderLineRollback = (orderLinesIds: Id[], error: ApiError) => ({
  type: 'ORDER_LINES_CONFIRM_ROLLBACK',
  payload: { orderLinesIds, error }
});

export const toggleQuickPaymentLoading = (is: boolean) => ({
  type: 'ORDER_QUICK_PAYMENT_TOGGLE_LOADING',
  payload: { is }
});

export const toggleTerminalPaymentLoading = (terminal: boolean, onTerminalMessage: any) => ({
  type: 'ORDER_TERMINAL_PAYMENT_TOGGLE_LOADING',
  payload: { terminal, onTerminalMessage }
});

export const resetState = () => ({
  type: 'ORDER_RESET',
});

export const softResetState = () => ({
  type: 'ORDER_SOFT_RESET',
});

export const paySelectedOrderLinesRollback = (error: ApiError) => ({
  type: 'ORDER_LINES_PAY_ROLLBACK',
  payload: {
    error
  }
});

export const updateOrderLinesLocal = (orderLines: OrderLine[], flags = {}): Action => ({
  type: 'UPDATE_ORDER_LINES_LOCAL',
  payload: {
    orderLines,
    flags
  }
});

export const confirmOrderLine = (orderLinesToBeConfirmed: OrderLine[], flags = {}) =>
({ lookUpId, getState, dispatch }) => {
  const orderLinesIdsToBeConfirmed = pluck('id', orderLinesToBeConfirmed);

  return {
    type: 'ORDER_LINES_CONFIRM',
    payload: {
      orderLinesToBeConfirmed: lookUpId(orderLinesIdsToBeConfirmed),
      orderLinesUpdateSource: orderLinesToBeConfirmed,
      flags
    },
    meta: {
      offline: {
        effect: () => {
          const stateOrderLines = getState().orders.orderLines;
          const stateSubTables = getState().tables.subTables;
          const stateTableDefinitions = pathOr([], 'tables.tableDefinitions', getState());

          const orderLines = orderLinesToBeConfirmed.filter((orderLine: OrderLine) => !orderLine._local
            || (isInOrderLines(orderLine.id, stateOrderLines)
              && (!!orderLine.tableDefinitionId || isValidTableDefinitionId(orderLine.tableDefinitionId, stateTableDefinitions))
              && isValidSubTableId(orderLine.subTableId, stateSubTables)
              && !isHashValue(lookUpId(orderLine.subTableId)))).map((orderLine: OrderLine) => ({
            id: orderLine._local ? undefined : lookUpId(orderLine.id), // create or update
            clientId: orderLine.id,
            itemId: orderLine.itemId,
            subTableId: lookUpId(orderLine.subTableId),
            data: pickOrderLineApiData(orderLine),
          }));

          if (!orderLines || orderLines.length === 0) {
            return Promise.resolve({ body: { result: {} } });
          }

          return api.item.addItems(
            addTablesDefinitionsToOrderLines(
              orderLines,
              getState().tables.subTables,
              getState().tables.openTables
            )
          ).catch(e => {
            const actions = [];

            if (e.status !== 422) { // is handled in order/epics - order lines with invalid table definition
              actions.push(confirmOrderLineRollback(orderLinesIdsToBeConfirmed, e));
            }

            actions.push(toggleQuickPaymentLoading(false));
            actions.push(toggleTerminalPaymentLoading(false));

            dispatch(actions);
            // dispatch([
            //   confirmOrderLineRollback(orderLinesIdsToBeConfirmed, e),
            //   toggleQuickPaymentLoading(false),
            //   toggleTerminalPaymentLoading(false)
            // ]);
          });
        },
        commit: response => {
          const actions = [
            completeOffline(),
            confirmUpdatedOrderLines(
              flags,
              [],
              pathOr([], 'offline.outbox', getState()).length === 0
            )(response)
            // confirmUpdatedOrderLines(flags, orderLinesToBeConfirmed)(response)
          ];

          const savedOrderLinesClientIds = pathOr([], 'payload.body.result.orderLinesSaved', response).map(o => o.clientId);

          const outbox = pathOr([], 'offline.outbox', getState());
          const relocatingOrderLinesIdsInOutbox = outbox.filter(o => o.type === 'RELOCATING_ORDER_LINES_IDS');
          if (relocatingOrderLinesIdsInOutbox && relocatingOrderLinesIdsInOutbox.length > 0) {
            relocatingOrderLinesIdsInOutbox.forEach(relocation => {
              const orderLinesIds = pathOr([], 'payload.orderLinesIds', relocation);
              const subTableId = pathOr(undefined, 'payload.subTableId', relocation);
              if (JSON.stringify(savedOrderLinesClientIds) === JSON.stringify(orderLinesIds)) {
                actions.push(relocateToSubTable(orderLinesIds, subTableId));
              }
            });
          }

          return actions;
        },
        rollback: ({ payload }) => [
          completeOffline(),
          confirmOrderLineRollback(orderLinesIdsToBeConfirmed, payload)
        ]
      }
    }
  };
};

type itemForFastMenu = {
  id: Id,
  count: number
};

export const toggleFastFoodMenuForm = (item: itemForFastMenu) => ({
  type: 'ORDER_FAST_FOOD_MENU_FORM_TOGGLE',
  payload: {
    item
  }
});

export const togglePriceOrQuantityForm = (item: Object | boolean) => ({
  type: 'ORDER_PRICE_OR_QUANTITY_FORM_TOGGLE',
  payload: {
    item
  }
});

export const toggleSubTableMenu = (is: boolean) => ({
  type: 'ORDER_SUB_TABLE_MENU_OPEN_TOGGLE',
  payload: { is }
});

export const showAvailabilityBlocker = (wantedCount: number, orderLine, dispatchLoad, unit = 'ks') => ({
  type: 'ORDER_SHOW_AVAILABILITY_BLOCKER',
  payload: {
    wantedCount,
    orderLineParams: orderLine,
    dispatchLoad,
    unit,
  }
});

export const hideAvailabilityBlocker = () => ({
  type: 'ORDER_HIDE_AVAILABILITY_BLOCKER',
});

export const setQuickPaymentMediaId = (paymentMediaId: Id) => ({
  type: 'ORDER_QUICK_PAYMENT_MEDIA_ID',
  payload: { paymentMediaId }
});

export const terminalPaymentSocketMessageLoading = (message: string) => ({
  type: 'TERMINAL_PAYMENT_SOCKET_MESSAGE_LOADING',
  payload: { terminalMessage: message }
});

export const clearOrderLinesDirtyList = (outbox) => ({
    type: 'CLEAR_ORDER_LINES_DIRTY_LIST',
    payload: { outbox }
  });
