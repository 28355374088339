// @flow
import React from 'react';
import Grid from './Grid';
import GridItem from './GridItem';
import { repeat } from 'rambda';

const noop = () => undefined;

export default class extends React.PureComponent {
  render() {
    const {
      rowsCount,
      columnsCount,
      items,
      rowStart = noop,
      rowEnd = noop,
      columnStart = noop,
      columnEnd = noop,
      cellRenderer,
      ...restProps
    } = this.props;

    return (
      <Grid
        rows={repeat(3, rowsCount)}
        columns={repeat('1fr', columnsCount).join(' ')}
        {...restProps}
      >
        {items.map((item, i) => (
          <GridItem
            key={`${item.id}-${i}`}
            columnStart={columnStart(item, i)}
            columnEnd={columnEnd(item, i)}
            rowStart={rowStart(item, i)}
            rowEnd={rowEnd(item, i)}
          >
            {cellRenderer(item)}
          </GridItem>
        ))}
      </Grid>
    );
  };
}
