// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  autoUpdatesUpdateFound: {
    defaultMessage: '⚠️ Bola nájdená aktualizácia.',
    id: 'autoUpdates.updateFound'
  },
  autoUpdatesUpdateNow: {
    defaultMessage: 'Aktualizovať',
    id: 'autoUpdates.updateNow'
  },
  autoUpdatesInstallAndRestart: {
    defaultMessage: 'Nainštalovať a reštartovať',
    id: 'autoUpdates.installRestart'
  },
});
