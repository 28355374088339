// @flow
import React from 'react';
import { withContentRect } from 'react-measure';
import { compose } from 'rambda';
import saveMenuScrollableInfo from './hoc/saveMenuScrollableInfo';
import { toggleMenuScrollButtons } from '../../common/order/actions';

class MeasureBounds extends React.Component {
  render() {
    const {
      measureRef, contentRect, children, measure, ...restProps
    } = this.props;

    const { bounds } = contentRect;

    return (
      <div ref={measureRef} {...restProps}>
        {children(bounds)}
      </div>
    );
  }
}

export default compose(
  withContentRect('bounds'),
  saveMenuScrollableInfo(toggleMenuScrollButtons)
)(MeasureBounds);
