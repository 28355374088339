// @flow
import Keyboard from '../index';

class CalculatorKeyboard extends Keyboard {
  appendToCurrentInput(input: string): string {
    if (!input) return '';

    let curInput = this.state.curInput();

    if (this.state.getLastKey() === '=' || curInput.slice(-1) !== ' ') {
      curInput = '';
    }

    this.state.setNewInput(`${curInput}${input}`);
    this.state.setLastKey(input[input.length - 1]);
    this._commitState(this.state);

    return this.state.curInput();
  }

  cancelCurrentInput(val) {
    this.state.setNewInput(val || '0');
    this.state.setLastKey(val || 'c');
    this._commitState(this.state);
  }
}

export default CalculatorKeyboard;
