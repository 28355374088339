// @flow
import type { KeyboardDisplayProps } from '../index';
import React from 'react';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../Icon';
import { injectIntl } from 'react-intl';
import messages from '../messages/buttons';
import { isReactNative } from '../../../app/detectPlatform';
import TextInput from '../../TextInput';
import Text from '../../Text';

let Keyboard;
if (process.env.IS_REACT_NATIVE === '1') {
  Keyboard = require('react-native').Keyboard;
}

class TextWrapper extends React.PureComponent {
  componentDidUpdate() {
    if (!isReactNative) {
      this.overflowable.scrollLeft = this.overflowable.querySelector('input').clientWidth;
    }
  }

  render() {
    const { children } = this.props;

    return isReactNative
      ? <Box flexDirection="row" alignItems="center" flex={1}>{children}</Box>
      : <div style={{ display: 'flex', width: '100%', overflow: 'auto', height: '100%' }} ref={c => { this.overflowable = c; }}>{children}</div>;
  }
}

const formatValue = (intl, value) => {
  if (!value.length) return '0';

  const replaced = value
    .replace(/\./g, intl.formatMessage(messages.dot))
    .replace(/\//g, '÷')
    .replace(/\*/g, '×')
    .replace(/-/g, '−'); // replace hyphen with minus

  return replaced;
};

class Display extends React.PureComponent<KeyboardDisplayProps> {

  onCancel = () => {
    const { onPress } = this.props;
    onPress('c');

    if (this.input) {
      this.input.focus();
    }
  };

  render() {
    const { value, onPress, intl, disabled, unit } = this.props;

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor={isReactNative ? 'darkGray' : 'blue'}
        style={theme => isReactNative
          ? ({
            height: theme.typography.lineHeight(5) * 2,
            width: '75%'
          })
          : ({
            height: theme.typography.lineHeight(5) * 1.75
          })
        }
      >
        <Button
          onPress={this.onCancel}
          alignItems="center"
          paddingHorizontal={0.75}
          disabled={disabled}
        >
          <Icon color="white" name="delete" scale={2} />
        </Button>
        <TextWrapper>
          <TextInput
            nativeRef={c => { this.input = c; }}
            backgroundColor={isReactNative ? 'darkGray' : 'blue'}
            flexGrow={1}
            bold
            align="right"
            color="white"
            scale={5}
            justifyContent="center"
            {...(isReactNative
                ? { numberOfLines: 1, ellipsizeMode: 'head' }
                : { height: '100%', overflow: 'auto', style: () => ({ whiteSpace: 'nowrap' }) }
            )}
            value={formatValue(intl, value)}
            onKeyPress={({ key, charCode }) => onPress(charCode === 13 ? '=' : key)}
            onKeyDown={({ keyCode }) => {
              if (keyCode === 8) { // backspace
                onPress('c');
              }
            }}
            onChange={() => {
            }}
            onFocus={isReactNative ? Keyboard.dismiss : null}
            disabled={disabled}
            autoFocus
          />
        </TextWrapper>
        <Box width={3} alignItems="center">
          {unit &&
            <Text
              flexDirection="row"
              alignItems="baseline"
              color="textInverse"
              style={theme => ({
                lineHeight: theme.typography.lineHeight(5) * 2
              })}
            >
              {unit}
            </Text>}
        </Box>
      </Box>
    );
  }
}

export default injectIntl(Display);
