// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'rambda';
import type { State } from '../../../common/types';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Icon from '../../../common/components/Icon';
import { clearSelectedOrderLines } from '../../../common/order/actions';
import { activateReceipt, deactivateReceipt } from '../../../common/admin/receipts/actions';
import { initiateAdminTableReload, addAdminError } from '../../../common/admin/general/actions';
import { processReceivedReceipts, extractSelectedMonth } from '../../../common/admin/receipts/utils';
import moment from 'moment';
import Button from '../../../common/components/Button';
import LoadingScreen from '../../../common/components/LoadingScreen';
import api from '../../../common/lib/api';
import { getActiveMonthSelector, getActiveReceiptIdSelector, getShowClosedReceiptsSelector } from '../../../common/admin/receipts/selectors';
import TableSearch from '../TableSearch';
import { AutoSizer, Column,
  defaultTableCellRenderer, defaultTableHeaderRenderer } from 'react-virtualized';
import SimpleItemsTable, { withGroups, withInfiniteLoader, withSelectableRows } from '../Table';
import debounce from 'debounce';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../common/messages/receipts';
import {
  activeOpenTableSelector,
  activeTableNameSelector,
  subTablesForActiveOpenTableSelector
} from '../../../common/tables/selectors';
import {undeletedOrderLinesSelector} from '../../../common/order/selectors';

const inlineBlock = {
  display: 'inline-block',
  lineHeight: 'normal'
};

const Table = compose(
  withInfiniteLoader({
    minimumBatchSize: 40,
    rowDataKey: 'apiId',
  }),
  withGroups({
    isGroupRow: (rowIndex, items) =>
      !rowIndex ||
      (moment(items[rowIndex].date).format('L') !== moment(items[rowIndex - 1].date).format('L')),
    groupHeaderDataGetter: ({ rowData: { date }}) => {
      return date ? moment(date).format('L') : <FormattedMessage {...messages.receiptsNoData} />;
    }
  }),
  withSelectableRows(),
  injectIntl
)(SimpleItemsTable);

class Receipts extends React.PureComponent {
  debouncedReload = debounce(() => {
    const { dispatch } = this.props;
    return dispatch(initiateAdminTableReload());
  }, 200);

  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      selectedMonth: props.activeMonth,
      filter: '',
      showClosedReceipts: props.showClosedReceipts
    };
  }

  loadReceipts = ({ loadAfterId }) => {
    const { dispatch } = this.props;
    const { filter } = this.state;

    const firstDayOfMonth = moment()
      .date(1).month(this.state.selectedMonth.month).year(this.state.selectedMonth.year)
      .format('YYYY-MM-DD');
    const lastDayOfMonth = moment()
      .date(1).month(this.state.selectedMonth.month).year(this.state.selectedMonth.year)
      .endOf('month')
      .format('YYYY-MM-DD');

    return this.state.showClosedReceipts
      ? api.receipt.getReceipts({
          fromDay: firstDayOfMonth,
          toDay: lastDayOfMonth,
          afterId: loadAfterId,
          receiptId: filter,
      }).then(({ body }) => processReceivedReceipts(body))
        .catch(e => {
            dispatch(addAdminError(e));
          })
      : api.receipt.getUnclosedReceipts({ afterId: loadAfterId, receiptId: filter })
        .then(({ body }) => processReceivedReceipts(body))
        .catch(e => {
          dispatch(addAdminError(e));
        });
  };

  _setActiveReceipt = (receipt) => {
    const { dispatch } = this.props;
    const { showClosedReceipts } = this.state;

    if (receipt) {
      dispatch(
        activateReceipt(
          receipt.id,
          receipt.closureId,
          receipt.date.format('DD.MM.YYYY LT'),
          extractSelectedMonth(receipt.date),
          showClosedReceipts,
          receipt.idLimit,
          receipt.unfinished,
          receipt.fuciarkod
        )
      );
    }
  };

  _setSelectedMonth = (selectedMonth) => {
    const { dispatch } = this.props;

    this.setState({ selectedMonth });
    dispatch(initiateAdminTableReload());
  };

  filterData = (filter, key) => {
    if (key === 'submit') return;

    this.setState({ filter }, this.debouncedReload);
  };

  _showClosedReceipts = (closed) => {
    const { dispatch } = this.props;

    this.setState({ showClosedReceipts: closed });
    dispatch([
      deactivateReceipt(),
      initiateAdminTableReload()
    ]);
  };

  render() {
    const { dispatch, activeReceiptId, activeMonth, intl } = this.props;
    const { showClosedReceipts } = this.state;

    const selectedTableRow = {};
    if (activeReceiptId) {
      selectedTableRow[activeReceiptId] = true;
    }

    const switchUnclosedReceipts = (
      <Box marginLeft={3} flexDirection="row" alignItems="flex-start">
        <Button
          onPress={() => this._showClosedReceipts(true)}
        >
          <Text color={showClosedReceipts ? 'white' : 'lightGray'} bold={showClosedReceipts}>
            {intl.formatMessage(messages.receiptsPrevious)}
          </Text>
        </Button>
        <Text color="white" marginLeft={0.5} marginRight={0.5}>/</Text>
        <Button
          onPress={() => this._showClosedReceipts(false)}
        >
          <Text color={showClosedReceipts ? 'lightGray' : 'white'} bold={!showClosedReceipts}>
            {intl.formatMessage(messages.receiptsActual)}
          </Text>
        </Button>
      </Box>
    );

    return (
      <Box
        width="100%"
        flex={1}
        backgroundColor="appBg"
        onMouseDown={() => dispatch(clearSelectedOrderLines())}
        paddingTop={1}
        paddingLeft={1}
        overflow="auto"
        overflowX="hidden"
      >
        <TableSearch
          enableFilter
          onDateChange={this._setSelectedMonth}
          selectedMonth={activeMonth}
          customFilter={switchUnclosedReceipts}
          enableCalendar={this.state.showClosedReceipts}
          onFilterChange={this.filterData}
          keyboardType="numeric"
        />

        <Box flexBasis="100%" backgroundColor="white" marginBottom={1} maxHeight="100vh" height="9999px">
          <AutoSizer>
            {({ height, width }) => (
              <Table
                width={width}
                height={height}
                selectedItems={selectedTableRow}
                onRowClick={({ rowData: { id, closureId, date, idLimit, unfinished, fuciarkod } }) =>
                  this._setActiveReceipt({ id, closureId, date, idLimit, unfinished, fuciarkod })
                }
                headerClassName="offset-first"
                className="light"
                lazyFetch={this.loadReceipts}
              >
                <Column
                  label={intl.formatMessage(messages.receiptsTableId)}
                  dataKey="id"
                  width={width * 0.15}
                  minWidth={100}
                  cellDataGetter={({ rowData: { id } }) => `# ${id}`}
                  cellRenderer={props =>
                    <Box paddingLeft={1} flexDirection="row">
                      <Box
                        backgroundColor={props.rowData.storno
                          ? (props.rowData.unfinished ? 'black' : 'red')
                          : (props.rowData.unfinished ? 'gray' : 'blue')}
                        width={0.55}
                        height={0.55}
                        marginRight={0.3}
                        marginTop="auto"
                        marginBottom="auto"
                        borderRadius="50%"
                        borderColor="white"
                        borderStyle="solid"
                        borderWidth={1}
                      />
                      <Text color="inherit">
                        {defaultTableCellRenderer(props)}
                      </Text>
                    </Box>
                  }
                />
                <Column
                  label={intl.formatMessage(messages.receiptsTableDate)}
                  dataKey="date"
                  width={width * 0.2}
                  cellDataGetter={({ rowData: { date } }) => moment(date).format('DD.MM.YYYY LT')}
                  cellRenderer={props =>
                    <Text paddingRight={1} color="inherit">
                      {defaultTableCellRenderer(props)}
                    </Text>
                  }
                />
                <Column
                  label={intl.formatMessage(messages.receiptsTableTable)}
                  dataKey="table"
                  width={width * 0.1}
                  cellRenderer={props =>
                    <Text paddingRight={1} color="inherit" flexDirection="row" justifyContent="flex-start" alignItems="center">
                      <Icon name="one-stand-table" marginRight={0.4} />
                      {defaultTableCellRenderer(props)}
                    </Text>
                  }
                />
                <Column
                  label={intl.formatMessage(messages.receiptsTablePayments)}
                  dataKey="platby"
                  width={width * 0.4}
                  cellRenderer={props =>
                    <Text align="right" paddingRight={0.5} color="inherit" flexDirection="row">
                      <Icon name="pay" marginRight={0.4} />
                      {defaultTableCellRenderer(props)}
                    </Text>
                  }
                  headerRenderer={props =>
                    <Text
                      align="right"
                      paddingRight={0.5}
                      style={inlineBlock}
                    >
                      {defaultTableHeaderRenderer(props)}
                    </Text>
                  }
                />
                <Column
                  label={intl.formatMessage(messages.receiptsTableUser)}
                  dataKey="user"
                  width={width * 0.15}
                  cellRenderer={props =>
                    <Text align="right" paddingRight={0.5} color="inherit" flexDirection="row">
                      <Icon name="user" marginRight={0.4} />
                      {defaultTableCellRenderer(props)}
                    </Text>
                  }
                />

              </Table>
            )}
          </AutoSizer>
        </Box>

        {this.state.showLoading &&
          <LoadingScreen backgroundColor="transparent" width="100%" height="80%" left={1} />}

      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    activeReceiptId: getActiveReceiptIdSelector(state),
    activeReceipt: state.admin.receipts.active.receipt,
    activeMonth: getActiveMonthSelector(state),
    showClosedReceipts: getShowClosedReceiptsSelector(state),
    parameters: state.parameters.parameters,
    reloadAdminTable: state.admin.general.reloadAdminTable
  })),
  injectIntl
)(Receipts);
