// @flow
import React from 'react';
import { replace } from 'connected-react-router';
import ContextMenu from '../components/ContextMenu';
import Box from '../../common/components/Box';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import Text from '../../common/components/Text';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../common/tables/messages/contextMenus';
import servicesMessages from '../../common/messages/serviceAreas';
import { connect } from 'react-redux';
import {
  editTable,
  focusOnTable,
  addOpenTable,
  activateOpenTable, deleteOpenTable
} from '../../common/tables/actions';
import {
  generateOpenTableName, isTableSplitted,
  startRelocatingOrderLinesToOpenTableOrSubTable,
  startCancelingReceiptAndReturningToSubTable,
  hasOpenTableOrderLine
} from '../../common/tables/utils';
import { filter, propEq, find, pluck, compose } from 'rambda';
import type { State, SubTable } from '../../common/types';
import Divider from '../../common/components/Divider';
import {
  clearSelectedOrderLines,
  toggleOrderLineSelect,
  toggleRelocatingToTable
} from '../../common/order/actions';
import {
  confirmOrder,
  formatSubTableName,
  getAllOrderLinesOfOpenTable,
} from '../../common/order/utils';
import {
  undeletedOpenTablesSelector,
  undeletedSubTablesSelector
} from '../../common/tables/selectors';
import { undeletedOrderLinesSelector } from '../../common/order/selectors';
import { toggleCancelingReceiptAndReturningToTable } from '../../common/admin/receipts/actions';
import { toggleAdminNotificationPopup, addAdminError, toggleAdminLoadingScreen } from '../../common/admin/general/actions';
import api from '../../common/lib/api';
import checkPermission from '../../common/permissions/service';
import { findParamValue } from '../../common/parameters/utils';
import commonMessages from '../../common/messages/common';

export const ClosedTableMenu = connect((state: State) => ({
  openTables: state.tables.openTables,
  selectedOrderLines: state.orders.selectedOrderLines,
  subTables: undeletedSubTablesSelector(state),
  orderLines: undeletedOrderLinesSelector(state),
  activeSubTableId: state.tables.active.subTableId,
  focusOnOpenTableId: state.tables.focusOnOpenTable,
}))(({
  openTables,
  focusOnOpenTableId,
  table,
  dispatch,
  selectedOrderLines,
  subTables,
  orderLines,
  activeOpenTableId,
  activeSubTableId,
  ...restProps
}) => {
  const isSplitted = isTableSplitted(openTables, table.id);

  const focusOnOpenTable = focusOnOpenTableId ? find(propEq('id', focusOnOpenTableId), openTables) : null;

  return (
    <ContextMenu {...restProps}>
      {focusOnOpenTableId &&
        <Button
          justifyContent="flex-start"
          marginBottom={0.5}
          onPress={() => {
            dispatch([
              replace('/payment'),
              activateOpenTable(focusOnOpenTableId, { activateSubTable: 'all' }),
              confirmOrder(
                focusOnOpenTableId,
                orderLines,
                subTables
              ),
              toggleOrderLineSelect(
                pluck('id', getAllOrderLinesOfOpenTable(subTables, orderLines, focusOnOpenTableId))
              ),
            ]);
          }}
        >
          <Box
            borderStyle="solid"
            borderWidth={2}
            borderColor="white"
            width={2}
            height={2}
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
          >
            <Icon color="white" name="pay" scale={2} />
          </Box>
          <Text color="white" justifyContent="center" marginLeft={0.5} bold>
            <FormattedMessage {...messages.pay} />
          </Text>
        </Button>}
      {!isSplitted && checkPermission('opentable.split.add', 'opentable.add') &&
      <Button
        justifyContent="flex-start"
        marginBottom={0.5}
        onPress={() => {
          dispatch([
            replace('/order'),
            addOpenTable({
              tableDefinitionId: table.id,
              serviceAreaId: table.serviceAreaId,
              name: generateOpenTableName(table, openTables)
            }, { activate: true }),
          ]);
        }}
      >
        <Box
          borderStyle="solid"
          borderWidth={2}
          borderColor="white"
          width={2}
          height={2}
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
        >
          <Icon color="white" name="one-stand-table" scale={2} />
        </Box>
        <Text color="white" justifyContent="center" marginLeft={0.5} bold>
          <FormattedMessage {...messages.split} />
        </Text>
      </Button>
      }
      {focusOnOpenTable && !hasOpenTableOrderLine(focusOnOpenTable, orderLines, subTables) && checkPermission('opentable.delete') && (
        <Button
          justifyContent="flex-start"
          onPress={() => dispatch(deleteOpenTable(focusOnOpenTableId))}
          marginBottom={0.5}
        >
          <Box
            borderStyle="solid"
            borderWidth={2}
            borderColor="white"
            width={2}
            height={2}
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
          >
            <Icon color="white" name="close" scale={0} />
          </Box>
          <Text color="white" justifyContent="center" marginLeft={0.5} bold>
            <FormattedMessage {...messages.close} />
          </Text>
        </Button>
      )}
      <Button
        justifyContent="flex-start"
        onPress={() => {
          dispatch([
            editTable(table.id, 'name'),
            focusOnTable(null)
          ]);
        }}
      >
        <Box
          borderStyle="solid"
          borderWidth={2}
          borderColor="white"
          width={2}
          height={2}
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
        >
          <Icon color="white" name="edit" scale={2} />
        </Box>
        <Text color="white" justifyContent="center" marginLeft={0.5} bold>
          <FormattedMessage {...messages.edit} />
        </Text>
      </Button>
    </ContextMenu>
  );
});

export const EditTableMenu = connect((state: State) => ({
  openTables: state.tables.openTables,
  parameters: state.parameters.parameters
}))(({ table, parameters, openTables, dispatch, ...restProps }) => {
  const currentOpenTable = find(propEq('tableDefinitionId', table.id), openTables);
  const isBar = currentOpenTable
    ? currentOpenTable.bar || currentOpenTable.barCash
    : false;

  const takePriceLevelFromTable = findParamValue('K32.is_stolchl', parameters);

  return (
    <ContextMenu {...restProps}>
      <Button
        justifyContent="flex-start"
        onPress={() => {
          dispatch([
            editTable(table.id, 'name'),
            focusOnTable(null)
          ]);
        }}
        marginBottom={0.5}
      >
        <Box
          borderStyle="solid"
          borderWidth={2}
          borderColor="white"
          width={2}
          height={2}
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
        >
          <Icon color="white" name="edit" scale={2} />
        </Box>
        <Text color="white" justifyContent="center" marginLeft={0.5} bold>
          <FormattedMessage {...messages.edit} />
        </Text>
      </Button>
      {!isBar && takePriceLevelFromTable && (
        <Button
          justifyContent="flex-start"
          onPress={() => {
            dispatch([
              editTable(table.id, 'priceLevel'),
              focusOnTable(null)
            ]);
          }}
        >
          <Box
            borderStyle="solid"
            borderWidth={2}
            borderColor="white"
            width={2}
            height={2}
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
          >
            <Icon color="white" name="price-level" scale={2} />
          </Box>
          <Text color="white" justifyContent="center" marginLeft={0.5} bold>
            <FormattedMessage {...messages.changePriceLevel} />
          </Text>
        </Button>
      )}
    </ContextMenu>
  );
});

export const RelocatingTableMenu = compose(connect((state: State) => ({
  subTables: undeletedSubTablesSelector(state),
  openTables: undeletedOpenTablesSelector(state),
  focusedOpenTableId: state.tables.focusOnOpenTable,
  orderLines: undeletedOrderLinesSelector(state),
  selectedOrderLines: state.orders.selectedOrderLines,
  activeOpenTableId: state.tables.active.openTableId,
  activeSubTableId: state.tables.active.subTableId,
  isCancelingReceiptAndReturningToTable: state.admin.receipts.isCancelingReceiptAndReturningToTable
})), injectIntl)(({
  dispatch,
  table,
  focusedOpenTableId,
  activeOpenTableId,
  activeSubTableId,
  subTables,
  openTables,
  orderLines,
  selectedOrderLines,
  isCancelingReceiptAndReturningToTable,
  intl,
  ...restProps
}) => {
  const subTablesInOpenTable = filter(propEq('openTableId', focusedOpenTableId), subTables);
  const subTablesCount = subTablesInOpenTable.length;

  return (
    <ContextMenu {...restProps}>
      {subTablesInOpenTable.map((subTable: SubTable, i) => {
        const isLast = i + 1 === subTablesCount;
        const processedSubTableName = formatSubTableName(subTable.name, intl.formatMessage(commonMessages.orderGuest));
        const processedSubTablePureName = processedSubTableName.split('.')[0];

        return (
          <Button
            key={subTable.id}
            justifyContent="flex-start"
            marginBottom={isLast ? 0 : 0.5}
            onPress={() => {
              dispatch(focusOnTable(null));

              if (isCancelingReceiptAndReturningToTable) {
                const { receiptId, closureId } = isCancelingReceiptAndReturningToTable;
                api.receipt.cancelReceiptAndReturnToTable(receiptId, closureId, subTable.id)
                  .then(() => {
                    dispatch([
                      toggleAdminNotificationPopup(intl.formatMessage(servicesMessages.serviceAreasReverted)),
                      toggleAdminLoadingScreen(false)
                    ]);
                  })
                  .catch(e => {
                    console.error(intl.formatMessage(servicesMessages.serviceAreasRevertError), e);
                    dispatch([
                      addAdminError(e),
                      toggleAdminLoadingScreen(false)
                    ]);
                  });
                dispatch([
                  toggleAdminLoadingScreen(true),
                  toggleCancelingReceiptAndReturningToTable(false)
                ]);
              } else {
                if (subTable.id === activeSubTableId) {
                  dispatch([
                    toggleRelocatingToTable(false),
                    clearSelectedOrderLines()
                  ]);
                  return;
                }

                const actions = startRelocatingOrderLinesToOpenTableOrSubTable(null, subTable.id,
                  selectedOrderLines, table, orderLines, openTables, subTables);

                dispatch(actions);
              }
            }}
          >
            <Text
              borderStyle="solid"
              borderWidth={2}
              borderColor="white"
              width={2}
              height={2}
              borderRadius="50%"
              alignItems="center"
              justifyContent="center"
              color="white"
              bold
            >
              {processedSubTablePureName}
            </Text>
            <Text color="white" justifyContent="center" marginLeft={0.5} bold>{processedSubTableName}</Text>
          </Button>
        );
      })}

      {!!subTablesCount && <Divider />}

      <Button
        justifyContent="flex-start"
        onPress={() => {
          dispatch(focusOnTable(null));

          const actions = isCancelingReceiptAndReturningToTable
            ? startCancelingReceiptAndReturningToSubTable(focusedOpenTableId, null,
              table, openTables, isCancelingReceiptAndReturningToTable)
            : startRelocatingOrderLinesToOpenTableOrSubTable(focusedOpenTableId, null,
              selectedOrderLines, table, orderLines, openTables, subTables);

          dispatch(actions);
        }}
      >
        <Text
          borderStyle="solid"
          borderWidth={2}
          borderColor="white"
          width={2}
          height={2}
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
          color="white"
          bold
          scale={1}
        >+</Text>
        <Text color="white" justifyContent="center" marginLeft={0.5} bold>
          <FormattedMessage {...messages.newSubtable} />
        </Text>
      </Button>
    </ContextMenu>
  );
});
