// @flow

export const prepareParams = (url: string, params: Object = {}): Promise => {
  const preparedParams = {
    ...params,
    data: params.body ? JSON.stringify(params.body) : undefined,
    // onUploadProgress: e => onProgressRequest(e, requestId, url),
    // onDownloadProgress: e => onProgressResponse(e, requestId, url),
  };

  preparedParams.credentials = 'same-origin';

  return Promise.resolve({ url, params: preparedParams });
};
