// @flow
import { map, pluck, path } from 'rambda';
import { zip } from 'ramda';

export const ApiError =
  function (status: number | number[], message: string | string[], body: any) {
    this.name = 'ApiError';
    this.status = status;
    this.message = message;
    this.body = body;

    if (body) {
      if (body.errorCode) {
        this.status = body.errorCode;
      }
      if (body.errorMessage) {
        this.message = body.errorMessage;
      }
      if (body.additionalData) {
        this.additionalData = body.additionalData;
      }
    }

    const formatMessage = (status, message) => {
      if (typeof message === 'string') {
        return message;
      }

      return 'Unknown error';
    };

    this.toString = () => {
      if (this.status === 0) {
        return this.message;
      }

      if (Array.isArray(this.status) && Array.isArray(this.message)) {
        const strings = map(([status, message]) => formatMessage(status, message),
          zip(this.status, this.message));

        return strings.join('; ');
      }

      return formatMessage(this.status, this.message);
    };
  };

ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.constructor = ApiError;

export const handlePosError = (posError, body) => {
  if (Array.isArray(posError)) {
    const codes = pluck('errorCode', posError);
    const messages = pluck('errorMessage', posError);

    return new ApiError(codes, messages, body);
  }

  return new ApiError(posError.errorCode, posError.errorMessage, body);
};

export const findError = response => {
  const { data: body, headers } = response;

  if (body) {
    if (body.errorMessage) {
      throw new ApiError(body.errorCode, body.errorMessage, body);
    }

    if (path(['result', 'errorMessage'], body)) {
      throw new ApiError(body.result.errorCode, body.result.errorMessage, body);
    }
  }

  return { body, headers };
};

export const transformError = err => {
  if (err instanceof ApiError) {
    return err;
  }

  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return new ApiError(err.response.status, err.response.statusText, err.response.data);
  }

  if (err.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest
    if (!err.request.status) {
      return new ApiError(err.request.status, 'Nepodarilo sa spojiť so serverom', err.request.responseText);
    }

    return new ApiError(err.request.status, err.request.statusText, err.request.responseText);
  }

  // Something happened in setting up the request that triggered an Error
  return new ApiError();
};
