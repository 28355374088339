// @flow
/* eslint-disable import/no-mutable-exports */

let platformIcon;

if (process.env.IS_REACT_NATIVE === '1') {
  platformIcon = require('../../native/components/Icon').default;
} else {
  platformIcon = require('../../browser/components/Icon').default;
}

export default platformIcon;
