import React from 'react';
import Heading from '../../../../common/components/Heading';
import Box from '../../../../common/components/Box';
import TypewriterButtons from '../../../../common/components/Keyboard/typewriter/Buttons';
import TypewriterDisplay from '../../../../common/components/Keyboard/typewriter/OneLineDisplay';
import createOperations from '../../../../common/components/Keyboard/typewriter/operations';
import Keyboard from '../../../../common/components/Keyboard';
import {compose} from 'rambda';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import messages from '../../../../common/messages/payment';

class ApprovePayment extends React.PureComponent {
  render() {
    const { disabled, onFinish, intl } = this.props;

    return (
      <Box>
        <Heading scale={2} color="white">{intl.formatMessage(messages.paymentPgrDotazyPaymentApproved)}</Heading>

        <Keyboard
          createOperations={createOperations}
          Buttons={TypewriterButtons}
          Display={TypewriterDisplay}
          onLastKey={(key, value) => {
            if (key === 'send') {
              onFinish(value);
            }
          }}
          disabled={disabled}
        />

      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(ApprovePayment);
