// @flow
import { prepareParams } from './params';
import { prepareHeaders } from './headers';
import { sendRequest } from './dispatcher';
import { findError, transformError } from './errors';

export const actionWithServerDate = (action) => (headers: Object) => {
  if (typeof action === 'function') {
    return (deps) => {
      const actionObject = action(deps);
      return ({
        ...actionObject,
        meta: {
          ...actionObject.meta,
          serverDate: headers ? headers.date : undefined
        }
      });
    };
  }

  return ({
    ...action,
    meta: {
      ...action.meta,
      serverDate: headers ? headers.date : undefined
    }
  });
};

let configuration = {};

const fetch = (url: String, params: Object): Promise =>
  prepareParams(url, params).then(({ url, params }) => {
    if (params && params.headers) return { url, params };

    return prepareHeaders(url, params);
  }).then(({ url, params }) =>
    sendRequest(url, params)
  ).then(response =>
    findError(response)
  ).then(response => { // eslint-disable-line newline-per-chained-call
    if (typeof configuration.onSuccess === 'function') {
      configuration.onSuccess(response);
    }
    return response;
  }).catch(error => { // eslint-disable-line newline-per-chained-call
    error = transformError(error);

    if (typeof configuration.onError === 'function') {
      configuration.onError(error);
    }

    throw error;
  });

export const configure = (onSuccess, onError) => {
  configuration = { onSuccess, onError };
};


export default fetch;
