// @flow
import {map, filter, pluck, find, propEq, pipe, identity, flatten, path, concat, pathOr, groupBy} from 'rambda';
import * as actions from './actions';
import * as paymentActions from '../payment/actions';
import { toggleUnfinishedBillPopup } from '../admin/receipts/actions';
import { lookUpId } from '../localIds/lookUpService';
import { redirectNavigation } from '../lib/utils';
import { incrementItemAvailability, updateItemAvailabilityLocalDiff } from '../items/actions';
import api from '../lib/api';
import { getAllOrderLinesIdInFocusSelector } from './selectors';
import { activeOpenTableIdSelector } from '../tables/selectors';
import moment from 'moment';
import { mergeItemsAndQuantity } from '../items/utils';
import NavigationActions from 'react-navigation/src/NavigationActions';
import { findParamValue } from '../parameters/utils';
import { createTerminalLoadingSocket, getPrinterUrl } from '../lib/api/payment';
import type { OrderLine } from '../types';
import { isHashValue, isValidOpenTableId, isValidSubTableId, isValidTableDefinitionId } from './utils';
import {
  addOpenTable,
  addSubTable,
  addTableDefinition
} from '../tables/actions';
import { generateOpenTableName } from '../tables/utils';
import {
  deleteOrderLinesLocal
} from './actions';
import messages from '../messages/components';

export const relocateOrderLinesAfterSubTableIsCommitted = (action$: any, { getState, lookUpId }) =>
  action$
    .ofType('SUB_TABLE_UPDATE_COMMIT')
    .filter(({ payload: { flags = {} } }) => flags.relocateOrderLinesIds)
    .map(({ payload: { subTable: { id }, flags: { relocateOrderLinesIds } } }) => {
      const ids = relocateOrderLinesIds ? relocateOrderLinesIds.map(id => (lookUpId(id))) : [];
      const orderLines = pathOr([], 'orders.orderLines', getState()).filter(o => ids.includes(o.id));

      const committedOrderLines = filter(orderLine => !orderLine._local, orderLines);
      const localOrderLines = filter(orderLine => orderLine._local, orderLines);
      const committedIds = committedOrderLines && committedOrderLines.map(o => lookUpId(o.id));
      const localIds = localOrderLines && localOrderLines.map(o => lookUpId(o.id));

      const actionsList = [];

      if (committedIds && committedIds.length > 0) {
        actionsList.push(actions.relocateToSubTable(committedIds, lookUpId(id)));
      }
      if (localIds && localIds.length > 0) {
        actionsList.push(actions.relocateToSubTableCommit(localIds, lookUpId(id)));
      }

      return actionsList;
    });

export const relocateOrderLinesAfterSubTableIsAdded = (action$: any, { getState, lookUpId }) =>
  action$
    .ofType('SUB_TABLE_ADD')
    .filter(({ payload: { flags = {} } }) => flags.relocateOrderLinesIds)
    .map(({ payload: { subTable: { id }, flags: { relocateOrderLinesIds } } }) => {
      const ids = relocateOrderLinesIds.map(id => (lookUpId(id)));
      const orderLines = pathOr([], 'orders.orderLines', getState()).filter(o => ids.includes(o.id));
      const previousSubTableIds = pluck('subTableId', orderLines);
      const previousOpenTableIds = []
      const previousSubTables = pathOr([], 'tables.subTables', getState()).filter(t => previousSubTableIds.includes(t.id));
      previousSubTables.forEach(t => {
        if (!previousOpenTableIds.includes(t.openTableId)) {
          previousOpenTableIds.push(t.openTableId);
        }
      });
      const localOrderLines = filter(orderLine => orderLine._local, orderLines);
      const localIds = localOrderLines && localOrderLines.map(o => lookUpId(o.id));

      const actionsList = [];
      // offline needs to be for delete table termination in offline mode
      if (localIds && localIds.length > 0) {
        actionsList.push(actions.relocateToSubTableCommit(localIds, lookUpId(id)));
        actionsList.push(actions.relocatingPreviousOpenTableIds(previousOpenTableIds));
      }

      return actionsList;
    });

export const quickPayOrderAfterOrderIsConfirmedCommit = (action$: any, { getState, lookUpId }) =>
  action$
    .ofType('ORDER_LINES_CONFIRM_COMMIT')
    .filter(({ payload: { flags = {} } }) => flags.payAfterConfirmWith)
    .switchMap(({ payload: { flags = {} } }) => {
      const state = getState();
      const orderLinesForPayment = getAllOrderLinesIdInFocusSelector(state);
      const activeOpenTableId = activeOpenTableIdSelector(state);
      const prgDotaz = flags.payAfterConfirmPrgData;
      const additionalData = flags.additionalData;
      const { parameters } = state.parameters;
      const paramIsEkasa = findParamValue('K32.ekasa', parameters);
      const isTerminalPay = pathOr(false, 'payment.terminal', state);

      const authDefaultPrinter = state.auth.defaultPrinter;
      const printers = state.peripherals.printers;

      const selectedPrinter = pathOr({}, 'payment.selectedPrinter', state).id;

      let terminalSocket = null;
      if (isTerminalPay) {
        const onMessage = message => {
          if (state.payment.onTerminalMessage) {
            state.payment.onTerminalMessage(message);
          }
        };

        const socketDefaultPrinter = printers && authDefaultPrinter && printers.find(per => (per.prnNo === authDefaultPrinter));
        const socketSelectedPrinter = printers && selectedPrinter && printers.find(per => (per.prnNo === selectedPrinter));

        terminalSocket = createTerminalLoadingSocket(state.device.sessionId, getPrinterUrl(socketSelectedPrinter, socketDefaultPrinter), onMessage);
      }

      const closeTerminalSocket = () => {
        if (terminalSocket) {
          terminalSocket.close();
        }
      };

      return api.payment.quickPayment(
        orderLinesForPayment,
        flags.payAfterConfirmWith,
        undefined,
        prgDotaz,
        additionalData,
        selectedPrinter
      ).then(() => {
        closeTerminalSocket();
        return [
          actions.toggleQuickPaymentLoading(false),
          actions.toggleTerminalPaymentLoading(false),
          actions.setQuickPaymentMediaId(null),
          actions.deleteOrderLinesCommit(
            lookUpId(orderLinesForPayment),
            {
              deleteOpenTableId: !flags.isBar ? activeOpenTableId : null,
              redirect: flags.redirect
            }
          ),
          paymentActions.hidePrgDotaz()
        ];
      }).catch(error => {
        closeTerminalSocket();
        return (error.additionalData && paramIsEkasa)
          ? [
            actions.toggleQuickPaymentLoading(false),
            actions.toggleTerminalPaymentLoading(false),
            actions.paySelectedOrderLinesRollback(error),
            paymentActions.hidePrgDotaz(),
            process.env.IS_REACT_NATIVE === '1'
              ? NavigationActions.navigate({
                routeName: 'UnfinishedBillPage',
                key: 'UnfinishedBillPage',
                params: error.additionalData
              })
              : toggleUnfinishedBillPopup(error.additionalData)]
          : [
            actions.toggleQuickPaymentLoading(false),
            actions.toggleTerminalPaymentLoading(false),
            actions.paySelectedOrderLinesRollback(error),
            paymentActions.hidePrgDotaz()
          ];
      });
    });

export const redirectAfterConfirmCommitWithoutPay = (action$: any) =>
  action$
    .ofType('ORDER_LINES_CONFIRM_COMMIT')
    .filter(({ payload: { flags = {} } }) =>
      flags.redirect && !flags.payAfterConfirmWith)
    .map(({ payload: { flags = {} } }) =>
      flags.orderLineIdsInFocus && flags.orderLineIdsInFocus.length
        ? [
            actions.toggleOrderLineSelect(flags.orderLineIdsInFocus),
            redirectNavigation(flags.redirect, flags.redirectForward)
          ] : [
            redirectNavigation(flags.redirect, flags.redirectForward),
            ...(flags.clearSelected ? [actions.clearSelectedOrderLines()] : [])
          ]
    );

export const redirectAfterConfirmWithoutPay = (action$: any) =>
  action$
    .ofType('ORDER_LINES_CONFIRM')
    .filter(({ payload: { flags = {} } }) =>
      flags.redirectOffline && !flags.payAfterConfirmWith)
    .map(({ payload: { flags = {} } }) =>
      flags.orderLineIdsInFocus && flags.orderLineIdsInFocus.length
        ? [
          actions.toggleOrderLineSelect(flags.orderLineIdsInFocus),
          redirectNavigation(flags.redirectOffline, flags.redirectForward)
        ] : [
          redirectNavigation(flags.redirectOffline, flags.redirectForward),
          ...(flags.clearSelected ? [actions.clearSelectedOrderLines()] : [])
        ]
    );

export const changeItemAvailabilityLocalDiffAfterOrderLineAddLocally = (action$: any) =>
  action$
    .ofType('ORDER_LINE_ADD')
    .map(({ payload: { orderLine: { itemId, quantity, portion = 1 } } }) =>
      updateItemAvailabilityLocalDiff({ [itemId]: -quantity / portion })
    );

export const changeItemAvailabilityLocalDiffAfterOrderLineDeletedLocally =
(action$: any, { getState }) =>
  action$
    .ofType('ORDER_LINES_DELETE')
    .map(({ payload: { ids, oldOrderLines: orderLines } }) => {
      const {
        items: {
          availability
        }
      } = getState();

      const foundOrderLines = pipe(
        map(id => find(propEq('id', lookUpId(id)), orderLines)),
        filter(orderLine => {
          if (!orderLine) return false;

          const resetAt = path([orderLine.itemId, 'reset_at'], availability);
          if (!resetAt) return true;

          return moment(resetAt) < moment(orderLine.createdAt);
        })
      )(ids);

      const itemLocalAvailabilityDiff = map(
        ({ itemId, quantity = 1, portion = 1 }) => ({ itemId, quantity: quantity / portion }),
        foundOrderLines
      );

      return updateItemAvailabilityLocalDiff(mergeItemsAndQuantity(itemLocalAvailabilityDiff));
    });

export const changeItemAvailabilityLocalDiffAfterOrderLineRollback =
  (action$: any, { getState }) =>
    action$
      .ofType('ORDER_LINES_DELETE_ROLLBACK')
      .map(({ payload: { ids } }) => {
        const {
          orders: {
            orderLines
          },
          items: {
            availability
          }
        } = getState();

        const foundOrderLines = pipe(
          map(id => find(propEq('id', lookUpId(id)), orderLines)),
          filter(orderLine => {
            if (!orderLine) return false;

            const resetAt = path([orderLine.itemId, 'reset_at'], availability);
            if (!resetAt) return true;

            return moment(resetAt) < moment(orderLine.createdAt);
          })
        )(ids);

        const itemLocalAvailabilityDiff = map(
          ({ itemId, quantity = 1, portion = 1 }) => ({ itemId, quantity: -quantity / portion }),
          foundOrderLines
        );

        return updateItemAvailabilityLocalDiff(mergeItemsAndQuantity(itemLocalAvailabilityDiff));
      });

export const changeItemAvailabilityAfterOrderLineDeleteCommit = (action$, { getState }) =>
  action$
    .ofType('ORDER_LINES_DELETE_COMMIT')
    .map(({ payload: { ids, oldOrderLines, flags: { revertLocalAvailability } = {} } }) => {
      if (!oldOrderLines) return [];

      const {
        items: {
          availability
        }
      } = getState();

      const foundOrderLines = pipe(
        map(id => find(propEq('id', lookUpId(id)), oldOrderLines)),
        filter(orderLine => {
          if (!orderLine) return false;

          const resetAt = path([orderLine.itemId, 'reset_at'], availability);
          if (!resetAt) return true;

          return moment(resetAt) < moment(orderLine.createdAt);
        })
      )(ids);

      const itemsAvailabilityDiff = map(
        ({ itemId, quantity = 1, portion = 1 }) => ({ itemId, quantity: quantity / portion }),
        foundOrderLines
      );

      const itemsAvailabilityDiffMerged = mergeItemsAndQuantity(itemsAvailabilityDiff);
      // revert local diff made by epic after ORDER_LINES_DELETE
      const itemLocalAvailabilityDiffMerged = revertLocalAvailability
        ? mergeItemsAndQuantity(map(
            ({ itemId, quantity = 1, portion = 1 }) => ({ itemId, quantity: -quantity / portion }),
            foundOrderLines
          ))
        : [];

      return concat(
        [incrementItemAvailability(itemsAvailabilityDiffMerged)],
        revertLocalAvailability
          ? [updateItemAvailabilityLocalDiff(itemLocalAvailabilityDiffMerged)]
          : []
      );
    });

export const updateItemAvailabilityAfterOrderLineChangePortion = (action$: any, { getState }) =>
  action$
    .ofType('ORDER_LINE_CHANGE_PORTION', 'ORDER_LINE_CHANGE_PORTION_ROLLBACK')
    .map(({ type, payload }) => {
      const { orders: { orderLines }, items: { availability } } = getState();

      let orderLinesIds;
      let newPortion;
      let foundOrderLines;

      if (type === 'ORDER_LINE_CHANGE_PORTION') {
        newPortion = payload.portion;
        foundOrderLines = payload.preparedOrderLines;
      } else {
        orderLinesIds = payload.ids;
        newPortion = payload.desiredPortion;
        foundOrderLines = pipe(
          map(id => find(propEq('id', lookUpId(id)), orderLines)),
          filter(identity)
        )(orderLinesIds);
      }

      foundOrderLines = filter(({ itemId, createdAt }) => {
        const resetAt = path([itemId, 'reset_at'], availability);
        if (!resetAt) return true;

        return moment(resetAt) < moment(createdAt);
      }, foundOrderLines);

      const diffs = map(({ itemId, oldPortion = 1, quantity = 1 }) => {
        const oldValue = quantity / oldPortion;
        const newValue = quantity / newPortion;

        const quantityDiff = (type === 'ORDER_LINE_CHANGE_PORTION' ? 1 : -1) *
          (oldValue - newValue);

        return { itemId, quantity: quantityDiff };
      }, foundOrderLines);

      return updateItemAvailabilityLocalDiff(mergeItemsAndQuantity(diffs));
    });

export const changeItemAvailabilityAfterOrderLineConfirmCommit =
(action$, { getState }) =>
  action$
    .ofType('ORDER_LINES_CONFIRM_COMMIT')
    .filter(({ payload: { changedProps } }) => !changedProps || changedProps.length === 0)
    .map(({ payload: { orderLinesIds, flags: { oldOrderLines } = {} } }) => {
      const { orders: { orderLines }, items: { availability } } = getState();

      const foundOrderLines = pipe(
        map(id => find(propEq('id', lookUpId(id)), orderLines)),
        filter(orderLine => {
          if (!orderLine) return false;

          const resetAt = path([orderLine.itemId, 'reset_at'], availability);
          if (!resetAt) return true;

          return moment(resetAt) < moment(orderLine.createdAt);
        })
      )(orderLinesIds);

      const changes = map(({ id, itemId, quantity = 1, portion: newPortion, createdAt }) => {
          const oldOrderLine = oldOrderLines
            ? find(propEq('id', lookUpId(id)), oldOrderLines)
            : null;
          const oldQuantity = oldOrderLine ? oldOrderLine.quantity : 1;
          const oldPortion = oldOrderLine ? oldOrderLine.oldPortion : undefined;

          let oldValue = (oldPortion === undefined)
            ? 0 : (oldQuantity || 1) / oldPortion;

          const newValue = quantity / newPortion;

          const resetAt = path([itemId, 'reset_at'], availability);
          if (resetAt && moment(resetAt) >= moment(createdAt)) {
            oldValue = 0; // old value was reseted
          }

          return [
            { itemId, quantity: oldValue - newValue },
            { itemId, quantity: -1 * (oldValue - newValue) }
          ];
        },
        foundOrderLines
      );

      return [
        incrementItemAvailability(mergeItemsAndQuantity(pluck(0, changes))),
        updateItemAvailabilityLocalDiff(mergeItemsAndQuantity(pluck(1, changes)))
      ];
    });

export const selectOrderLineAfterAddedByPlus = (action$: any) =>
  action$
    .ofType('ORDER_LINE_ADD')
    .filter(({ payload: { flags = {} } }) => flags.selectAfterAddition)
    .map(({ payload: { orderLine: { id } } }) =>
      actions.toggleOrderLineSelect([id])
    );

export const selectFastFoodSubItemsOfParentOrderLineAfterOrderLineSelect =
(action$: any, { getState }) =>
  action$
    .ofType('ORDER_LINE_SELECT')
    .filter(({ payload: { flags = {} } }) => flags.selectFastFoodMenuSubItems)
    .map(({ payload: { ids } }) => {
      const { orders: { orderLines } } = getState();

      const _fastFoodSubItemsIds = map(
        id => {
          const selectedOrderLine = find(propEq('id', id), orderLines);

          if (!selectedOrderLine) return [];
          if (selectedOrderLine.orderType !== 10) return [];

          const fastFoodSubItemIds = pluck(
            'id',
            filter(propEq('linkedToId', id), orderLines)
          );

          return fastFoodSubItemIds;
        },
        ids
      );

      const fastFoodSubItemsIds = flatten(_fastFoodSubItemsIds);

      if (!fastFoodSubItemsIds.length) return [];

      return actions.toggleOrderLineSelect(fastFoodSubItemsIds);
    });

export const closePaymentTransactionViaApiAfterClear = (action$, { getState }) =>
  action$
    .ofType('PAYMENT_TRANSACTION_CLEAR')
    .filter(({ payload: { paymentTransactionId } }) => paymentTransactionId)
    .mergeMap(({ payload: { paymentTransactionId } }) =>
      api.payment.closePayTransaction(paymentTransactionId).then(() => {
        const { orders: { orderLines } } = getState();
        const affectedOrderLines = filter(propEq('paymentId', paymentTransactionId), orderLines);
        const ids = pluck('id', affectedOrderLines);
        // const updatedAt = moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.sss');
        const modifiedOrderlines = map(orderLine => ({ ...orderLine, paymentId: null }),
          affectedOrderLines);

        return actions.confirmOrderLineCommit(ids, modifiedOrderlines, ['paymentId']);
        // return actions.confirmOrderLineCommit(ids, modifiedOrderlines, ['paymentId', 'updatedAt']);
      }).catch(e =>
        actions.addError(e)
      )
    );

export const clearOrderLinesDirtyList = (action$: any, { getState }) =>
  action$
    .ofType('BLITZ_SYNC_SERVER_DATA_COMMIT')
    .map(() => {
        return actions.clearOrderLinesDirtyList(pathOr([], 'offline.outbox', getState()));
      }
    );

export const repairNotValidLocalOrderLines = (action$: any, { getState }) =>
  action$
    .ofType('BLITZ_SYNC_SERVER_DATA_COMMIT')
    .map(() => {
      const actionsList = [];
      const outbox = pathOr([], 'offline.outbox', getState());

      if (outbox.length === 0 ) {
        const groupByTableDefinition = groupBy(orderLine => orderLine.tableDefinitionId);

        const orderLines = pathOr([], 'orders.orderLines', getState());
        const openTables = pathOr([], 'tables.openTables', getState());
        const subTables = pathOr([], 'tables.subTables', getState());
        const tableDefinitions = pathOr([], 'tables.tableDefinitions', getState());

        const localOrderLines = orderLines.filter((orderLine: OrderLine) =>
          typeof orderLine.id === 'string' && orderLine._local);

        const localOrderLinesWithNotValidTableDef = localOrderLines.filter((orderLine: OrderLine) =>
          !isValidTableDefinitionId(orderLine.tableDefinitionId, tableDefinitions));

        if (localOrderLinesWithNotValidTableDef.length > 0) {
          const orderLinesByTableDef = groupByTableDefinition(localOrderLinesWithNotValidTableDef);

          Object.keys(orderLinesByTableDef).forEach(tableDefinitionIdStr => {
            const tableDefinitionId = isHashValue(tableDefinitionIdStr)
              ? tableDefinitionIdStr : lookUpId(parseInt(tableDefinitionIdStr, 10));
            const serviceAreaId = lookUpId(orderLinesByTableDef[tableDefinitionId][0].serviceAreaId);
            actionsList.push(
              addTableDefinition({
                  name: `${getState().intl.useIntl.formatMessage(messages.oneTimeTable)} ${moment().format('LT')}`,//TODO
                  serviceAreaId
                },
                {
                  willOpen: false,
                  activate: false,
                  orderLinesForConfirm: orderLinesByTableDef[tableDefinitionId]
                })
              );
          });
        }

        const localOrderLinesWithNotValidOpenTable = localOrderLines.filter((orderLine: OrderLine) =>
          isValidTableDefinitionId(orderLine.tableDefinitionId, tableDefinitions)
          && !isValidOpenTableId(orderLine.openTableId, openTables));

        if (localOrderLinesWithNotValidOpenTable.length > 0) {
          const orderLinesByTableDef = groupByTableDefinition(localOrderLinesWithNotValidOpenTable);
          Object.keys(orderLinesByTableDef).forEach(tableDefinitionIdStr => {
            const tableDefinitionId = isHashValue(tableDefinitionIdStr)
              ? tableDefinitionIdStr : lookUpId(parseInt(tableDefinitionIdStr, 10));
            const tableDefinition = tableDefinitions.find(td => td.id === tableDefinitionId);
            const serviceAreaId = tableDefinition ? tableDefinition.serviceAreaId : lookUpId(orderLinesByTableDef[tableDefinitionId][0].serviceAreaId);
            actionsList.push(addOpenTable({
              tableDefinitionId,
              serviceAreaId,
              name: generateOpenTableName({ id: tableDefinitionId }, openTables)
            }, {
              orderLinesForConfirm: orderLinesByTableDef[tableDefinitionId]
            }));
          });
        }

        const localOrderLinesWithNotValidSubTable = localOrderLines.filter((orderLine: OrderLine) =>
          isValidTableDefinitionId(orderLine.tableDefinitionId, tableDefinitions)
          && isValidOpenTableId(orderLine.openTableId, openTables)
          && !isValidSubTableId(orderLine.subTableId, subTables));

        if (localOrderLinesWithNotValidSubTable.length > 0) {
          const orderLinesByTableDef = groupByTableDefinition(localOrderLinesWithNotValidSubTable);
          Object.keys(orderLinesByTableDef).forEach(tableDefinitionIdStr => {
            const tableDefinitionId = isHashValue(tableDefinitionIdStr)
              ? tableDefinitionIdStr : lookUpId(parseInt(tableDefinitionIdStr, 10));
            const openTableId = lookUpId(orderLinesByTableDef[tableDefinitionId][0].openTableId);
            actionsList.push(addSubTable(openTableId, {
              orderLinesForConfirm: orderLinesByTableDef[tableDefinitionId]
            }));
          });
        }
      }

      return actionsList;
      });

export const repairNotValidCommittedOrderLines = (action$: any, { getState }) =>
  action$
    .ofType('BLITZ_SYNC_SERVER_DATA_COMMIT')
    .map(() => {
      const actionsList = [];
      const outbox = pathOr([], 'offline.outbox', getState());

      if (outbox.length === 0 ) {
        const groupByTableDefinition = groupBy(orderLine => orderLine.tableDefinitionId);

        const orderLines = pathOr([], 'orders.orderLines', getState());
        const openTables = pathOr([], 'tables.openTables', getState());
        const subTables = pathOr([], 'tables.subTables', getState());
        const tableDefinitions = pathOr([], 'tables.tableDefinitions', getState());

        const orderLinesWithNotValidOpenSubTable = orderLines.filter((orderLine: OrderLine) =>
          !orderLine._local
          && !isValidOpenTableId(orderLine.openTableId, openTables)
          && !isValidSubTableId(orderLine.subTableId, subTables)
          && orderLine.isSentToKitchen);

        if (orderLinesWithNotValidOpenSubTable.length > 0) {
          const orderLinesByTableDef = groupByTableDefinition(orderLinesWithNotValidOpenSubTable);
          Object.keys(orderLinesByTableDef).forEach(tableDefinitionIdStr => {
            const tableDefinitionId = isHashValue(tableDefinitionIdStr)
              ? tableDefinitionIdStr : lookUpId(parseInt(tableDefinitionIdStr, 10));

            const tableDefValid = !!tableDefinitions.find(def => def.id === tableDefinitionId);
            const serviceAreaId = lookUpId(orderLinesByTableDef[tableDefinitionId][0].serviceAreaId);

            if (tableDefValid) {
              if (tableDefinitionId && serviceAreaId) {
                actionsList.push(addOpenTable({
                  tableDefinitionId,
                  serviceAreaId,
                  name: generateOpenTableName({ id: tableDefinitionId }, openTables)
                }, {
                  orderLinesForConfirm: orderLinesByTableDef[tableDefinitionId]
                }));
              }
            } else {
              actionsList.push(
                addTableDefinition({
                    name: `${getState().intl.useIntl.formatMessage(messages.oneTimeTable)} ${moment().format('LT')}`,
                    serviceAreaId
                  },
                  {
                    willOpen: false,
                    activate: false,
                    orderLinesForConfirm: orderLinesByTableDef[tableDefinitionId]
                  })
              );
            }
          });
        }
      }

      return actionsList;
    });

// export const commitSubTablesWithCommittedOpenTable = (action$: any, { getState }) =>
//   action$
//     .ofType('BLITZ_SYNC_SERVER_DATA_COMMIT')
//     .map(() => {
//         const actionsList = [];
//       const outbox = pathOr([], 'offline.outbox', getState());
//
//       if (outbox.length === 0) {
//         const localOrderLines = pathOr([], 'orders.orderLines', getState()).filter(orderLine => orderLine._local);
//         const localSubTables = pathOr([], 'tables.subTables', getState()).filter(subTable => subTable._local);
//
//           localSubTables.forEach(subTable => {
//             if (!Number.isInteger(subTable.id) && Number.isInteger(subTable.openTableId)) {
//               actionsList.push(...[
//                 deleteSubTableCommit(subTable.id),
//                 addSubTable(subTable.openTableId, {
//                   orderLinesForConfirm: localOrderLines.filter(orderLine => orderLine.subTableId === subTable.id && orderLine.isSentToKitchen)
//                 })
//               ]);
//             }
//           });
//         }
//
//         return actionsList;
//       }
//     );

export const removeNotExistedCommittedOrderLines = (action$: any, { getState }) =>
  action$
    .ofType('BLITZ_SYNC_SERVER_DATA_COMMIT')
    .map(({ payload: { OrderLine: serverOrderLines = [] } }) => {
        const actionsList = [];
        const outbox = pathOr([], 'offline.outbox', getState());

        if (outbox.length === 0) {
          const clientCommittedOrderLines = pathOr([], 'orders.orderLines', getState()).filter(orderLine =>
            !orderLine._local && !orderLine._isDeleted && !isHashValue(orderLine.id));

          const removingOrderLines = clientCommittedOrderLines.filter(clOrl => !serverOrderLines.find(srOrl => clOrl.id === srOrl.id));
          if (removingOrderLines && removingOrderLines.length > 0) {
            const stateOrderLines = pathOr([], 'orders.orderLines', getState());
            const removingOrderLineIds = [];

            removingOrderLines.forEach(orl => {
              removingOrderLineIds.push(orl.id);
            });

            actionsList.push(deleteOrderLinesLocal(removingOrderLineIds, stateOrderLines));
          }
        }

        return actionsList;
      }
    );
