// @flow
import React from 'react';
import Box from '../Box';
import Text from '../Text';
import Button from '../Button';
import type { State } from '../../types';
import PrimaryRenderer from '../SheetRenderes/PrimaryRenderer';
import OrderLineFoodCourse from '../SheetRenderes/OrderLineFoodCourse';
import OrderLineGroup from '../SheetRenderes/OrderLineGroup';
import OrderLineRow from '../SheetRenderes/OrderLineRow';
import { isReactNative } from '../../app/detectPlatform';
import { propEq, find, compose } from 'rambda';
import { connect } from 'react-redux';
import { activateFoodCourse } from '../../tables/actions';
import theme from '../../themes/theme';
import { foodCoursesForActiveOpenTableSelector } from '../../tables/selectors';
import { sortedOrderLinesSelector, foodCoursesOrderLinesSelector } from '../../order/selectors';
import Permission from '../../permissions/Permission';
import scrollToFoodCourse from './scrollToFoodCourse';
import OrderLineSheetDecorations from './OrderLineSheetDecorations';
import { injectIntl } from 'react-intl';
import componentsMessages from '../../messages/components';
import scrollToFoodItem from './scrollToFoodItem';

class OrderLineSheet extends React.PureComponent {
  componentDidMount() {
    const { dispatch, foodCourses, activeFoodCourse } = this.props;

    const activeFoodCourseFromAnotherTable = activeFoodCourse &&
      find(propEq('id', activeFoodCourse), foodCourses);

    if (!activeFoodCourse || activeFoodCourseFromAnotherTable) {
      dispatch(activateFoodCourse(0));
    }
  }

  render() {
    const {
      selectedOrderLines,
      activeOpenTableId,
      isLimit,
      isRelocatingToFoodCourse,
      activeFoodCourse,
      isSubTableMenuOpen,
      isRelocatingToTable,
      isRelocatingToSubTable,
      scrollContainerId = 'OrderLineSheetScrollContainer',
      addFoodCourse,
      fullOrderLinesData,
      onKitchenReminderNativePress,
      intl
    } = this.props;

    return (
      <OrderLineSheetDecorations flex={1} backgroundColor="white">
        <Box id={scrollContainerId} overflow={isReactNative ? undefined : 'auto'} flex={1}>
          {fullOrderLinesData.map((foodCourse) => (
            <PrimaryRenderer
              key={foodCourse.id}
              data={{
                foodCourse,
                isRelocatingToFoodCourse,
                isRelocatingToTable,
                activeFoodCourse,
                selectedOrderLines,
                isSubTableMenuOpen,
                onKitchenReminderNativePress
              }}
              renderers={[
                ({ renderers, id, data }) =>
                  <OrderLineFoodCourse renderers={renderers} key={id} {...data} />,
                ({ renderers, id, data }) =>
                  <OrderLineGroup renderers={renderers} key={id} {...data} />,
                ({ id, data }) =>
                  <OrderLineRow key={id} {...data} />
              ]}
            />
          ))}
        </Box>

        {!isLimit &&
          <Permission name="orderline.foodcourse.add">
            <Button
              alignItems="center"
              paddingHorizontal={1}
              onPress={() => addFoodCourse(activeOpenTableId)}
              bold
              justifyContent="center"
              disabled={isRelocatingToSubTable}
              height={2}
              borderTopWidth={1}
              borderStyle="solid"
              borderColor="lightGray"
              flexShrink={0}
              backgroundColor="orderLineBg"
            >
              <Box
                color="darkGray"
                width={1}
                height={1}
                alignItems="center"
                justifyContent="center"
                borderWidth={1}
                borderStyle="solid"
                borderColor="darkGray"
                marginRight={0.5}
                overflow="hidden"
                borderRadius={theme.typography.lineHeight()}
              >
                <Text alignItems="center" justifyContent="center">+</Text>
              </Box>
              <Text bold>{intl.formatMessage(componentsMessages.orderLineSheetAdd)}</Text>
            </Button>
          </Permission>}

        {isSubTableMenuOpen
          ? <Box
            zIndex={3}
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            backgroundColor="inactiveItemsOverlay"
          />
          : null}
      </OrderLineSheetDecorations>
    );
  }
}

const ConnectedOrderLineSheet = compose(
  connect((state: State) => ({
    orderLines: sortedOrderLinesSelector(state),
    fullOrderLinesData: foodCoursesOrderLinesSelector(state),
    selectedOrderLines: state.orders.selectedOrderLines,
    isRelocatingToFoodCourse: state.orders.active.isRelocatingToFoodCourse,
    isRelocatingToTable: state.orders.active.isRelocatingToTable,
    isRelocatingToSubTable: state.orders.active.isRelocatingToSubTable,
    foodCourses: foodCoursesForActiveOpenTableSelector(state),
    activeOpenTableId: state.tables.active.openTableId,
    activeFoodCourse: state.tables.active.foodCourse,
    isSubTableMenuOpen: state.orders.active.isSubTableMenuOpen,
    isLimit: state.serviceAreas.activeIsLimit
  })),
  injectIntl
)(OrderLineSheet);

export default isReactNative
  ? ConnectedOrderLineSheet
  : scrollToFoodItem(ConnectedOrderLineSheet);
  // : scrollToFoodCourse(ConnectedOrderLineSheet);
