// @flow
import React from 'react';
import Text from '../Text';
import Button from '../Button';
import { isReactNative } from '../../app/detectPlatform';
import theme from '../../themes/theme';

const primaryFelaStyle = {
  ':active': {
    backgroundColor: `${theme.colors.buttonTealActive} !important`
  }
};

const operatorFelaStyle = {
  ':active': {
    backgroundColor: `${theme.colors.keyboardButtonHighlightActive} !important`
  }
};

const defaultFelaStyle = {
  ':active': {
    backgroundColor: `${theme.colors.keyboardButtonActive} !important`
  }
};

class StandardButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this._onPress = this._onPress.bind(this);
  }

  _onPress() {
    const { onPress, char, sendChar } = this.props;

    onPress(sendChar === undefined ? char : sendChar);
  }

  render() {
    const {
      char,
      borderTop,
      borderBottom,
      borderLeft,
      borderRight,
      size = 1,
      operator = false,
      primary = false,
      disabled,
      children
    } = this.props;

    return (
      <Button
        backgroundColor={(operator && 'keyboardButtonHighlight') || (primary && 'buttonTeal') || 'keyboardButton'}
        borderColor="keyboardButtonBorder"
        borderStyle="solid"
        borderTopWidth={borderTop ? 1 : 0}
        borderBottomWidth={borderBottom ? 1 : 0}
        borderLeftWidth={borderLeft ? 1 : 0}
        borderRightWidth={borderRight ? 1 : 0}
        alignItems="center"
        margin={0}
        flexShrink={0}
        paddingHorizontal={0}
        width={`${theme.typography.lineHeight(5) * 1.75 * size}px`}
        height={`${theme.typography.lineHeight(5) * 1.75}px`}
        felaStyle={(isReactNative || disabled)
          ? null
          : ((primary && primaryFelaStyle) || (operator && operatorFelaStyle) || defaultFelaStyle)}
        onPress={this._onPress}
        disabled={disabled}
        opacity={1}
      >
        {children ||
        <Text scale={5} thin justifyContent="center" color={(operator || primary) ? 'white' : 'black'}>{char}</Text>
        }
      </Button>
    );
  }
}

export default StandardButton;
