// @flow
import type { Operations } from '../index';
import escapeRegexp from 'escape-string-regexp';

const operations: Operations = (saveChanges) => ({
  sendKey(store, key): void {
    switch (key) {
      case 1: case '1':
      case 2: case '2':
      case 3: case '3':
      case 4: case '4':
      case 5: case '5':
      case 6: case '6':
      case 7: case '7':
      case 8: case '8':
      case 9: case '9':
      case 0: case '0': {
        const curInput = store.curInput();

        if (!/\..{2}/.test(escapeRegexp(curInput))) {
          if (!curInput || curInput === '0' || store.isReset()) {
            store.setNewInput(`${key}`);
          } else {
            store.setNewInput(`${curInput}${key}`);
          }
          store.setLastKey(`${key}`);
        }
        break;
      }

      case '.': {
        const lastGroup = store.curInput().split(' ').pop();
        if (lastGroup.includes(key)) {
          return;
        }

        if (store.isReset() || !['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(store.getLastKey())) {
          key = '0.';
        }

        if (store.isReset() || store.getLastKey() === 'cancel' || store.getLastKey() === 'submit') {
          store.setNewInput(key);
        } else {
          store.setNewInput(`${store.curInput()}${key}`);
        }
        store.setLastKey(`${key}`);
        break;
      }

      case 'backspace': {
        const curInput = store.curInput();
        if (!curInput.length) return;

        store.setNewInput(curInput.slice(0, -1));
        store.setLastKey(store.getLastKey(1));

        break;
      }

      case 'submit': {
        store.setLastKey(key);

        break;
      }

      case 'cancel': {
        store.setNewInput('');
        store.setLastKey(key);

        break;
      }

      default:
    }

    saveChanges(store);
  }
});

export default operations;
