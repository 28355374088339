// @flow

const initialState = {
  newVersion: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTO_UPDATES': {
      const { payload: { newVersion } } = action;
      return { newVersion };
    }

    default:
      return state;
  }
};

export default reducer;
