// @flow
import { find, map, curry, filter } from 'rambda';

export const leftJoin = curry((predicate, list1, list2) =>
  map(item1 => ({
    ...item1,
    ...find(item2 => predicate(item1, item2), list2)
  }), list1)
);

export const innerJoin = curry((predicate, list1, list2) => {
  // this is very much ineffective
  const newList1 = filter(item1 => find(item2 => predicate(item1, item2), list2), list1);
  const newList2 = filter(item2 => find(item1 => predicate(item1, item2), list1), list2);

  return leftJoin(predicate, newList1, newList2);
});
