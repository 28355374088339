// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';
import type { Id } from '../../types';

export const addItems = (
  orderLinesToBeConfirmed: OrderLineToBeConfirmed[]
): Promise =>
  fetch(apiEndpoint('v1/AddItems'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      name: 'AddItems',
      params: orderLinesToBeConfirmed,
      id: '',
      session_id: '',
      device_id: ''
    }
  });

export const deleteItems = (
  ids: Id[]
): Promise =>
  fetch(apiEndpoint('v1/VoidItems'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      name: 'VoidItems',
      params: {
        orderLineId: ids
      },
      id: '',
      session_id: '',
      device_id: ''
    }
  });

export const moveItems = (
  orderLineIds: Id[],
  subTableId: Id,
): Promise =>
  fetch(apiEndpoint('v1/MoveItems'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      name: 'MoveItems',
      params: {
        subTableId,
        orderLineId: orderLineIds
      },
      id: '',
      session_id: '',
      device_id: ''
    }
  });
