// @flow
import React from 'react';
import Text from '../../common/components/Text';

const Icon = ({ name, bold, ...restProps }) => (
  <Text {...restProps}>
    <span className={`icon icon-${name}`} style={{ fontWeight: bold ? 'bold' : 'normal' }} />
  </Text>
);

export default Icon;
