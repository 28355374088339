// @flow
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../common/components/Box';
import { clearSelectedOrderLines, toggleRelocatingToTable } from '../../../common/order/actions';
import { toggleCancelingReceiptAndReturningToTable } from '../../../common/admin/receipts/actions';
import Button from '../../../common/components/Button';
import Icon from '../../../common/components/Icon';
import Text from '../../../common/components/Text';
import Time from '../../../common/components/Time';
import Popup from '../../components/Popup';
import type { ServiceArea } from '../../../common/types';
import Radio from '../../../common/components/Radio';
import Heading from '../../../common/components/Heading';
import { activateArea } from '../../../common/serviceAreas/actions';
import { FormattedMessage } from 'react-intl';
import messages from '../../../common/serviceAreas/messages/options';
import orderMessages from '../../../common/messages/order';
import { focusOnTable } from '../../../common/tables/actions';
import DefaultTopBar from '../../../common/components/TopBar';

class RelocatingItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectArea: false
    };
  }

  render() {
    const { selectArea } = this.state;
    const { dispatch, areas, activeAreaId } = this.props;

    return (
      <Box width="100%">
        <DefaultTopBar backgroundColor="lessDarkGray">
          <Button flexDirection="row" alignItems="center" paddingLeft={1} onPress={() => this.setState({ selectArea: true })}>
            <Icon name="mini-tables" color="white" scale={2} />
            <Text color="white" bold marginLeft={0.5}><FormattedMessage {...orderMessages.orderRelocatingRooms} /></Text>
          </Button>
          <Button
            flexDirection="row"
            alignItems="center"
            paddingLeft={2.5}
            zIndex={1}
            onPress={() => {
              dispatch([
                toggleRelocatingToTable(false),
                toggleCancelingReceiptAndReturningToTable(false),
                clearSelectedOrderLines(),
                focusOnTable(null)
              ]);
            }}
          >
            <Icon position="absolute" left={0} name="close" color="black" padding={0.75} scale={-1} backgroundColor="white" borderRadius="50%" />
            <Text color="white" bold><FormattedMessage {...orderMessages.orderRelocatingCancel} /></Text>
          </Button>
          <Time color="white" bold paddingRight={1} justifyContent="center" />
        </DefaultTopBar>

        {selectArea &&
          <Box
            backgroundColor="inactiveBg"
            zIndex={1000}
            position="absolute"
            top={0}
            left={0}
            width="100vw"
            height="100vh"
            onMouseDown={(e) => e.stopPropagation()}
          >
            <Popup showClose onClose={() => this.setState({ selectArea: false })} style={{ width: '20rem' }}>
              <Heading scale={3} color="white"><FormattedMessage {...orderMessages.orderRelocatingChooseRoom} /></Heading>

              {areas.map(({ name, isVirtual, id, isLimit }, i): ServiceArea => {
                const isActive = id === activeAreaId;
                const isLast = i === areas.length - 1;

                return (
                  <Button
                    key={id}
                    backgroundColor={isActive ? 'teal' : 'appBg'}
                    outline
                    color="white"
                    marginBottom={isLast ? 0 : 0.5}
                    paddingVertical={0.5}
                    onPress={() => {
                      dispatch(activateArea(id));
                      this.setState({ selectArea: false });
                    }}
                  >
                    <Radio
                      size={1}
                      color="white"
                      position="absolute"
                      left={0.75}
                      backgroundColor={isActive ? 'teal' : 'appBg'}
                      checked={isActive}
                    />
                    <Text color="white" bold overflow="hidden">
                      {isVirtual && !isLimit ? <FormattedMessage {...messages.noMap} /> : name}
                    </Text>
                  </Button>
                );
              })}

            </Popup>
          </Box>
        }
      </Box>
    );
  }
}

export default connect((state: State) => ({
  areas: state.serviceAreas.serviceAreas,
  activeAreaId: state.serviceAreas.activeAreaId,
  showTablesList: state.serviceAreas.showTablesList
}))(RelocatingItems);
