// @flow
import React from 'react';
import { connect } from 'react-redux';
import InactiveBg from '../../components/InactiveBg';
import Popup from '../../components/Popup';
import Box from '../../../common/components/Box';
import { toggleReceiptTipPopup } from '../../../common/admin/receipts/actions';
import Heading from '../../../common/components/Heading';
import Spinner from '../../../common/components/Spinner';
import api from '../../../common/lib/api';
import {
  addAdminError,
  initiateAdminTableReload,
  toggleAdminNotificationPopup
} from '../../../common/admin/general/actions';
import type { State } from '../../../common/types';
import { path, compose } from 'rambda';
import { injectIntl } from 'react-intl';
import Button from '../../../common/components/Button';
import Text from '../../../common/components/Text';
import Keyboard from '../../../common/components/Keyboard';
import PopupWeightFormButtons from '../../../common/components/Keyboard/popupWeightForm/Buttons';
import PopupWeightFormDisplay from '../../../common/components/Keyboard/popupWeightForm/Display';
import createFormOperations from '../../../common/components/Keyboard/popupWeightForm/operations';
import receiptsMessages from '../../../common/messages/receipts';
import { formatDirectPriceToFixed } from '../../../common/order/utils';
import { getCurrencySymbol } from '../../../common/parameters/service';

const popupNumericDisplayWithUnit = (unit, forceValue, DisplayComponent) => props =>
  <DisplayComponent {...props} value={forceValue} unit={unit} />;

const popupNumericButtonsWithoutDot = (hasDot, ButtonsComponent) => props =>
  <ButtonsComponent {...props} showDot={hasDot} />;

class PopupTip extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      payments: null,
      paymentId: null,
      addTipForm: false,
      lastDisplayValue: ''
    };

    this.loadPayments();
  }

  _onClose = () => {
    const { dispatch } = this.props;
    dispatch(toggleReceiptTipPopup(false));
  };

  openTipForm = (paymentId) => {
    this.setState({ addTipForm: true, paymentId });
  };

  backFromTipForm = () => {
    this.setState({ addTipForm: false, paymentId: null });
  };

  loadPayments = () => {
    const { dispatch, activeReceipt } = this.props;

    return api.receipt.getPayments(activeReceipt.id, activeReceipt.closureId)
      .then(({ body }) => path(['result', 'payments'], body)
        ? this.setState({ payments: body.result.payments })
        : null)
      .catch(e => {
        dispatch(addAdminError(e));
      });
  };

  onSubmit = () => {
    const { dispatch, intl } = this.props;
    const { lastDisplayValue, paymentId } = this.state;

    return api.receipt.setTip(paymentId, +lastDisplayValue)
      .then(() => {
        this._onClose();
        dispatch([
          toggleAdminNotificationPopup(intl.formatMessage(receiptsMessages.receiptsTipAdded)),
          initiateAdminTableReload(),
        ]);
      })
      .catch(e => {
        this._onClose();
        dispatch(addAdminError(e));
      });
  };

  render() {
    const { intl } = this.props;
    const { payments, addTipForm, lastDisplayValue } = this.state;

    return (
      <InactiveBg onClose={this._onClose}>
        <Popup onClose={this._onClose} onBack={addTipForm ? this.backFromTipForm : null}>
          <Box alignItems="center">
            <Box
              width={20}
              maxHeight="70vh"
              marginHorizontal={-1}
              flex={1}
            >
              <Heading scale={2} color="white" marginHorizontal={1}>
                {addTipForm
                ? intl.formatMessage(receiptsMessages.receiptsEnterTip)
                : intl.formatMessage(receiptsMessages.receiptsChoosePayment)}
              </Heading>

              {addTipForm
                ? <Keyboard
                    style={theme => ({ width: theme.typography.lineHeight(5) * 1.75 * 3 })}
                    createOperations={createFormOperations}
                    Buttons={popupNumericButtonsWithoutDot(
                      true,
                      PopupWeightFormButtons
                    )}
                    Display={popupNumericDisplayWithUnit(
                      `${getCurrencySymbol()}`,
                      `${lastDisplayValue === '' ? 0 : lastDisplayValue}`,
                      PopupWeightFormDisplay
                    )}
                    onLastKey={key => {
                      if (key === 'ok') {
                        this.onSubmit();
                      }
                    }}
                    onDisplayValue={val => {
                      this.setState({ lastDisplayValue: val });
                    }}
                    defaultValue="0"
                    marginTop={1}
                    marginHorizontal="auto"
                  />
                : payments
                  ? <Box marginTop={0.5}>
                      {payments.map(payment =>
                        <Button
                          key={payment.id}
                          disabled={!payment.allow_tip}
                          marginHorizontal={1}
                          backgroundColor="white"
                          paddingVertical={0.5}
                          paddingHorizontal={1}
                          marginBottom={0.5}
                          justifyContent="flex-start"
                          flexShrink={0}
                          onPress={() => this.openTipForm(payment.id)}
                        >
                          <Box height="100%">
                            <Text>
                              {`${payment.name} - ${formatDirectPriceToFixed(payment.price)} ${payment.currency}`}
                            </Text>
                            <Text scale={-2}>{`${intl.formatMessage(receiptsMessages.receiptsTip)}: ${payment.tip}`}</Text>
                          </Box>
                        </Button>
                      )}
                    </Box>
                  : <Box marginTop={4}>
                      <Spinner color="" size="large" />
                    </Box>}
            </Box>
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect(
    (state: State) => ({
      activeReceipt: state.admin.receipts.active.receipt,
    })
  ),
  injectIntl
)(PopupTip);
