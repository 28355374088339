// @flow
import { createSelector } from 'reselect';
import type { State } from '../../types';
import { concat } from 'rambda';
import { isEmpty } from 'ramda';
import moment from 'moment';
import { emptyArray } from '../../lib/utils';

const activeReceipt = (state: State) => state.admin.receipts.active.receipt;

export const getActiveReceiptIdSelector = createSelector(
  activeReceipt,
  (receipt) => receipt ? receipt.id : undefined
);

export const getActiveMonthSelector = createSelector(
  activeReceipt,
  (receipt) => receipt
    ? receipt.selectedMonth
    : {
        month: moment().month(),
        year: moment().year(),
        name: moment().format('MMMM YYYY')
      }
);

export const getShowClosedReceiptsSelector = createSelector(
  activeReceipt,
  (receipt) => receipt
    ? receipt.showClosedReceipts
    : false
);

const errorsFromOrder = (state: State) => state.orders.error;
const errorsFromAdminReceipts = (state: State) => state.admin.general.error;

export const getAllErrors = createSelector(
  errorsFromOrder,
  errorsFromAdminReceipts,
  (errorsFromOrder, errorsFromAdminReceipts) => {
    const fromOrder = errorsFromOrder || emptyArray;
    const fromReceipts = errorsFromAdminReceipts || emptyArray;
    const allErrors = concat(fromOrder, fromReceipts);

    return isEmpty(allErrors) ? null : allErrors;
  }
);
