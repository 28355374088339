// @flow
import type { TableDefinition, State } from '../../common/types';
import React from 'react';
import { find, propEq } from 'rambda';
import Popup from '../components/Popup';
import InactiveBg from '../components/InactiveBg';
import { connect } from 'react-redux';
import { editTable, updateTableDefinition } from '../../common/tables/actions';
import Heading from '../../common/components/Heading';
import { FormattedMessage } from 'react-intl';
import messages from '../../common/messages/order';
import Button from '../../common/components/Button';
import Text from '../../common/components/Text';
import Radio from '../../common/components/Radio';
import { findPriceLevelForTable } from '../../common/tables/paramUtils';
import ScrollView from '../../common/components/ScrollView';
import Box from '../../common/components/Box';

class RenameTable extends React.PureComponent {
  _cancel = () => {
    const { dispatch } = this.props;
    dispatch(editTable(null));
  };

  render() {
    const { editTableId, tables, priceLevels, parameters, dispatch } = this.props;

    const table: TableDefinition = find(propEq('id', editTableId), tables);

    if (!table) return null;

    const tablePriceLevelId = findPriceLevelForTable(table, parameters);

    return (
      <InactiveBg zIndex={10} onClose={this._cancel}>
        <Popup onClose={this._cancel}>
          <Heading scale={3} color="white"><FormattedMessage {...messages.choosePriceLevel} /></Heading>

          <Box maxHeight={20}>
            <ScrollView>
              {priceLevels.map(({ name, id }) => {
                const isActive = id === tablePriceLevelId;

                return (
                  <Button
                    key={id}
                    backgroundColor="teal"
                    outline
                    color="white"
                    marginBottom={0.5}
                    paddingVertical={0.5}
                    paddingHorizontal={2}
                    flexShrink={0}
                    onPress={() => {
                      dispatch([
                        editTable(null),
                        updateTableDefinition(editTableId, { priceLevelId: id })
                      ]);
                    }}
                  >
                    <Radio
                      size={1}
                      color="white"
                      left={0.75}
                      position="absolute"
                      backgroundColor="teal"
                      checked={isActive}
                    />
                      <Text color="white" bold overflow="hidden">{name}</Text>
                  </Button>
                );
              })}
            </ScrollView>
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default connect((state: State) => ({
  editTableId: state.tables.editTable,
  tables: state.tables.tableDefinitions,
  priceLevels: state.items.priceLevels,
  parameters: state.parameters.parameters
}))(RenameTable);
