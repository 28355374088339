// @flow
import React from 'react';

// eslint-disable-next-line import/no-mutable-exports
let SpinnerImpl;

if (process.env.IS_REACT_NATIVE === '1') {
  SpinnerImpl = require('react-native').ActivityIndicator;
} else {
  const Spinner = require('react-spinner');

  // by default dark on RN
  SpinnerImpl = ({ color = 'gray', size, ...props }) =>
    <Spinner className={`${color} ${size}`} {...props} />;
}


export default SpinnerImpl;
