// @flow
import React from 'react';
import Box from '../Box';
import Button from '../Button';
import Text from '../Text';
import Icon from '../Icon';
import checkPermission from '../../permissions/service';
import theme from '../../themes/theme';
import { formatSubTableName } from '../../order/utils';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import commonMessages from '../../messages/common';

type SubTableMenuButtonProps = {
  Number: number,
  Text: Object,
  isToBeDeleted?: boolean,
  FirstFunction: any,
  SecondFunction?: any,
}

const SubTableMenuButton =
({
  id,
  FirstFunction,
  SecondFunction,
  disabled,
  Text: text,
  isToBeDeleted,
  onlyOne,
  Number,
  intl
}: SubTableMenuButtonProps) => (
  <Box width="100%" flexDirection="row" justifyContent="center" flexShrink={0}>
    <Button
      flexDirection="row"
      alignItems="center"
      onPress={() => FirstFunction(id)}
      disabled={disabled}
      height={3.25}
    >
      <Box
        marginHorizontal={0.75}
        width={text === 'all' ? 0 : 2}
        height={2}
        alignItems="center"
        justifyContent="center"
        borderWidth={text === 'all' ? 0 : 2}
        borderStyle="solid"
        borderColor={isToBeDeleted ? 'red' : 'white'}
        style={theme => ({ borderRadius: theme.typography.lineHeight() })}
      >
        <Text color={isToBeDeleted ? 'red' : 'white'} bold scale={2}>{text === 'all' ? null : Number}</Text>
      </Box>
      <Text color={isToBeDeleted ? 'red' : 'white'} bold marginRight={1}>
        {text === 'all'
          ? intl.formatMessage(commonMessages.showAll)
          : formatSubTableName(text, intl.formatMessage(commonMessages.orderGuest))}
      </Text>
    </Button>
    {SecondFunction && text !== 'all' && !onlyOne && checkPermission('subtable.delete')
      ? <Button
          width={3.5}
          borderTopLeftRadius={3}
          borderTopRightRadius={3}
          height={3.25}
          alignItems="center"
          onPress={() => SecondFunction(id)}
          disabled={disabled}
        >
          <Icon color={isToBeDeleted ? theme.colors.red : 'white'} name="delete" scale={1} />
        </Button>
      : null}
  </Box>
);

export default compose(
  connect(),
  injectIntl
)(SubTableMenuButton);
