// @flow
import type { Id } from '../types';

export const activateCategory = (id: Id, parentId?: Id) => ({
  type: 'MENU_ITEMS_ACTIVATE_PAGE',
  payload: { id, parentId }
});

export const activateLastPage = () => ({
  type: 'MENU_ITEMS_ACTIVATE_LAST_PAGE',
});

export const resetState = () => ({
  type: 'MENU_ITEMS_RESET',
});
