// @flow
import type { Color, Theme } from '../themes/types';
import React from 'react';
import { isReactNative } from '../../common/app/detectPlatform';
import PropTypes from 'prop-types';
import { reject } from 'rambda';
import theme from '../themes/theme';

// Universal styled Box component. The same API for browsers and React Native.
// Some props are ommited or limited or set to match React Native behaviour.
//  - default display is flex
//  - default position is relative
//  - default flexDirection is column
// We can use style prop for platform specific styling.
//  style={theme => ({
//    // Set borderTopWidth to 1 to componsate padding.
//    borderTopWidth: StyleSheet.hairlineWidth,
//  })}

export type BoxProps = {
  theme?: any,
  // sitr.us/2017/01/03/flow-cookbook-react.html
  as?: () => React.Element<*>,
  // Low level deliberately not typed.
  style?: (theme: Theme, style: Object) => Object,
  felaStyle?: (theme: Theme, style: Object) => Object,
  // Maybe rhythm props.
  margin?: number | string,
  marginHorizontal?: number | string,
  marginVertical?: number | string,
  marginBottom?: number | string,
  marginLeft?: number | string,
  marginRight?: number | string,
  marginTop?: number | string,
  padding?: number | string,
  paddingHorizontal?: number | string,
  paddingVertical?: number | string,
  paddingBottom?: number | string,
  paddingLeft?: number | string,
  paddingRight?: number | string,
  paddingTop?: number | string,
  height?: number | string,
  maxHeight?: number | string,
  maxWidth?: number | string,
  minHeight?: number | string,
  minWidth?: number | string,
  width?: number | string,
  bottom?: number | string,
  left?: number | string,
  right?: number | string,
  top?: number | string,
  flex?: number,
  background?: Color, // browser only
  backgroundColor?: Color,
  // Border props.
  borderBottomColor?: Color,
  borderBottomLeftRadius?: number,
  borderBottomRightRadius?: number,
  borderBottomWidth?: number,
  borderColor?: Color,
  borderLeftColor?: Color,
  borderLeftWidth?: number,
  borderRadius?: number | string,
  borderRightColor?: Color,
  borderRightWidth?: number,
  borderStyle?: 'solid' | 'dotted' | 'dashed',
  borderTopColor?: Color,
  borderTopLeftRadius?: number,
  borderTopRightRadius?: number,
  borderTopWidth?: number,
  borderWidth?: number,
  // Just value props.
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline',
  alignSelf?:
    | 'auto'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'stretch'
    | 'baseline',
  flexBasis?: number | string,
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse',
  flexGrow?: number,
  flexShrink?: number,
  flexWrap?: 'wrap' | 'nowrap',
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around',
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around',
  opacity?: number,
  overflow?: 'visible' | 'hidden' | 'scroll',
  overflowX?: 'visible' | 'hidden' | 'scroll', // browser only?
  overflowY?: 'visible' | 'hidden' | 'scroll', // browser only?
  position?: 'absolute' | 'relative',
  whiteSpace?: string,
  zIndex?: number,
};

type BoxContext = {
  View: () => React.Element<*>,
  renderer: any, // TODO: Type it.
};

const isPx = (str) =>
  typeof str === 'string' && str.length >= 3 && str.substring(str.length - 2) === 'px';

const computeBoxStyle = (
  {
    // Maybe rhythm props.
    margin,
    marginVertical = margin,
    marginHorizontal = margin,
    marginTop = marginVertical,
    marginBottom = marginVertical,
    marginLeft = marginHorizontal,
    marginRight = marginHorizontal,
    padding,
    paddingVertical = padding,
    paddingHorizontal = padding,
    paddingTop = paddingVertical,
    paddingBottom = paddingVertical,
    paddingLeft = paddingHorizontal,
    paddingRight = paddingHorizontal,
    height,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    width,
    bottom,
    left,
    right,
    top,

    flex,
    background, // browser only
    backgroundColor,

    // Border props.
    borderColor = 'gray',
    // We can't use borderColor as default because some component in React Native,
    // for example Image, doesn't support that.
    borderBottomColor,
    borderLeftColor,
    borderRightColor,
    borderTopColor,
    borderRadius,
    borderBottomLeftRadius = borderRadius,
    borderBottomRightRadius = borderRadius,
    borderTopLeftRadius = borderRadius,
    borderTopRightRadius = borderRadius,
    borderWidth = 0, // Enfore React Native behaviour. It also makes more sense.
    borderBottomWidth = borderWidth,
    borderLeftWidth = borderWidth,
    borderRightWidth = borderWidth,
    borderTopWidth = borderWidth,
    borderStyle,
    boxShadow,

    // Just value props.
    alignContent,
    alignItems,
    alignSelf,
    flexBasis,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    justifyContent,
    opacity,
    // order, not supported in RN
    overflow,
    overflowX,
    overflowY,
    pointerEvents,
    position,
    textOverflow,
    whiteSpace,
    zIndex,

    ...props
  },
) => {
  let style = isReactNative
    ? {}
    : {
      // Enforce React Native behaviour for browsers.
      position: 'relative',
      flexDirection: 'column',
      display: 'flex',
    };

  const maybeRhythmProps = {
    flexBasis,
    bottom,
    height,
    left,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    right,
    top,
    width,
  };

  Object.keys(maybeRhythmProps)
    .filter(prop => maybeRhythmProps[prop] !== undefined)
    .forEach(prop => {
      let value = maybeRhythmProps[prop];

      if (typeof value === 'number') {
        // if you want to change scale, do it via style callback
        style[prop] = theme.typography.lineHeight(0) * value;
      } else if (typeof value === 'string') {
        // don't assume maybe rhythm if value is with units
        if (isPx(value)) {
          value = value.substring(0, value.length - 2);
          style[prop] = +value;
        } else if (value === '0') {
          style[prop] = +value;
        } else {
          style[prop] = value;
        }
      } else {
        style = { ...style, [prop]: value };
      }
    });

  // Enforce React Native flex behaviour. Can be overridden.
  if (typeof flex === 'number') {
    if (isReactNative) {
      style.flex = 1;
    } else {
      style.flexGrow = flex;

      if (style.flexBasis === undefined) {
        style.flexBasis = 'auto';
      }

      if (style.flexShrink === undefined) {
        style.flexShrink = 1;
      }
    }
  }

  // so that Android displays border
  if (isReactNative && borderWidth) {
    style.borderWidth = borderWidth;
  }

  const colorProps = {
    background,
    backgroundColor,
    // Do not sort, borderColor shorthand must be set first.
    borderColor,
    borderBottomColor,
    borderLeftColor,
    borderRightColor,
    borderTopColor,
  };

  Object.keys(colorProps)
    .filter(prop => colorProps[prop] !== undefined)
    .forEach(prop => {
      const value = colorProps[prop];
      const color = theme.colors[value];
      style[prop] = (color === undefined) ? value : color;
    });

  const borderRadiusProps = {
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
  };

  Object.keys(borderRadiusProps)
    .filter(prop => borderRadiusProps[prop] !== undefined)
    .forEach(prop => {
      const value = borderRadiusProps[prop];
      style[prop] = theme.borderRadius[value] || value;
    });

  const boxShadowProps = {
    boxShadow
  };

  Object.keys(boxShadowProps)
    .filter(prop => boxShadowProps[prop] !== undefined)
    .forEach(prop => {
      const value = boxShadowProps[prop];
      style[prop] = theme.boxShadow[value] || value;
    });

  // Just value props.
  const justValueProps = {
    alignContent,
    alignItems,
    alignSelf,
    borderStyle,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    justifyContent,
    opacity,
    overflow,
    overflowX,
    overflowY,
    pointerEvents,
    position,
    textOverflow,
    whiteSpace,
    zIndex,
  };

  Object.keys(justValueProps)
    .filter(prop => {
      const value = justValueProps[prop];
      const isDefined = typeof value === 'number' || value;
      return isDefined;
    })
    .forEach(prop => {
      const value = justValueProps[prop];
      style[prop] = value;
    });

  if (isReactNative) {
    delete style.pointerEvents;
  }

  const numericWithUnitProps = {
    borderBottomWidth,
    borderLeftWidth,
    borderRightWidth,
    borderTopWidth,
  };

  Object.keys(numericWithUnitProps)
    .filter(prop => {
      const value = numericWithUnitProps[prop];
      const isDefined = typeof value === 'number' || value;
      return isDefined;
    })
    .forEach(prop => {
      let value = numericWithUnitProps[prop];

      if (isPx(value)) {
        value = value.substring(0, value.length - 2);
        style[prop] = +value;
      } else if (value === '0') {
        style[prop] = +value;
      } else {
        style[prop] = value;
      }
    });

  return [style, props];
};

class Box extends React.PureComponent {
  render() {
    const {
      as,
      style,
      felaStyle,
      ...props
    }: BoxProps = this.props;

    const {
      // Note no $Exact<BoxProps>. It's up to the rendered component.
      View,
      renderer,
    }: BoxContext = this.context;

    const Component = as || View;
    const [boxStyle, restProps] = computeBoxStyle(props);

    let combinedStyles = {
      ...boxStyle,
      ...(typeof style === 'function' ? style(theme, boxStyle) : style),
      ...(typeof felaStyle === 'function' ? felaStyle(theme, boxStyle) : felaStyle)
    };

    if (!isReactNative) {
      combinedStyles = reject(val => Number.isNaN(val), combinedStyles);
    }

    const renderedStyle = renderer.renderRule(() => combinedStyles);

    const styles = {
      style: isReactNative ? renderedStyle : undefined,
      className: isReactNative
        ? undefined
        : `${renderedStyle} ${props.className || ''}`
    };

    return React.createElement(Component, { ...restProps, ...styles });
  }
}

Box.contextTypes = {
  View: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  renderer: PropTypes.object,
};

export default Box;
