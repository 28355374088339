// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { State, TableDefinition } from '../types';
import { scaleTables } from '../serviceAreas/tableCoordinates';
import { saveScaleFactor } from '../serviceAreas/actions';

class MapTablesCoords_ extends React.Component {
  props: {
    tables: TableDefinition[],
    width: number,
    height: number,
    currentScale: number,
    minTableSize?: number,
    children: Function,
    dispatch: Function
  };

  constructor(props) {
    super(props);
    this.state = {
      tables: null,
      dimensions: {},
      scaledTables: null
    };
  }

  componentWillMount() {
    this._setScaledTables(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this._setScaledTables(nextProps);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props !== nextProps || this.state.tables !== nextState.tables);
  }

  _setScaledTables(props: typeof props) {
    const { tables, width, height, dispatch, currentScale, minTableSize } = props;

    if (!width || !height) return;

    const {
      tables: scaledTables,
      scale,
      dimensions
    } = scaleTables(tables, width, height, minTableSize);

    this.setState({
      scaledTables,
      dimensions,
      tables
    });

    if (scale !== currentScale) {
      dispatch(saveScaleFactor(scale));
    }
  }

  render() {
    const { children } = this.props;
    const { scaledTables, dimensions } = this.state;

    if (!scaledTables || !children) return null;

    return children(scaledTables, dimensions);
  }
}

const MapTablesCoords = connect((state: State) => ({
  currentScale: state.serviceAreas.scale
}))(MapTablesCoords_);

export default MapTablesCoords;
