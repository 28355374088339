// @flow
import type { KeyboardDisplayProps } from '../index';
import React from 'react';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../Icon';
import TextInput from '../../TextInput';

class OneLineDisplay extends React.PureComponent {
  props: KeyboardDisplayProps;

  voidFunction = () => {};

  onKeyPress = ({ key, charCode }) => {
    if (charCode === 13) return; // enter

    const { onPress } = this.props;
    onPress(key);
  };

  onKeyDown = ({ keyCode }) => {
    const { onPress } = this.props;

    if (keyCode === 8) { // backspace
      onPress('del');
    } else if (keyCode === 13) { // enter
      onPress('send');
    }
  };

  pressCancel = () => {
    const { onPress } = this.props;
    onPress('cancel');

    if (this.input) {
      this.input.focus();
    }
  };

  render() {
    const { value } = this.props;

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor="teal"
        borderStyle="solid"
        borderColor="teal"
        flexShrink={0}
      >
        <TextInput
          nativeRef={c => { this.input = c; }}
          autoFocus
          value={value}
          backgroundColor="teal"
          border="none"
          outline="none"
          color="white"
          bold
          width="100%"
          padding={1}
          scale={2}
          onKeyPress={this.onKeyPress}
          onKeyDown={this.onKeyDown}
          onChange={this.voidFunction}
        />
        <Button
          onPress={this.pressCancel}
          alignItems="center"
          marginHorizontal={1}
        >
          <Icon color="white" name="delete" scale={2} />
        </Button>
      </Box>
    );
  }
}

export default OneLineDisplay;
