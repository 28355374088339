// @flow
import React from 'react';
import { animateScroll } from 'react-scroll/modules';
import { equals, find, last, propEq } from 'rambda';
import { getAllOrderLinesInFocus } from '../../order/utils';
import connect from 'react-redux/es/connect/connect';
import type { State } from '../../types';
import { undeletedSubTablesSelector } from '../../tables/selectors';
import { foodCoursesOrderLinesSelector, getAllFocusedOrderLinesSelector } from '../../order/selectors';

export default BaseComponent => connect((state: State) => ({
  _selectedOrderLines: state.orders.selectedOrderLines,
  _subTables: undeletedSubTablesSelector(state),
  _orderLinesSorted: getAllFocusedOrderLinesSelector(state),
  _fullOrderLinesData: foodCoursesOrderLinesSelector(state),
  _activeOpenTableId: state.tables.active.openTableId,
  _activeSubTableId: state.tables.active.subTableId,
}))(class scrollToFoodItem extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const scrollToFoodItem = (foodItemId) => {
      const { _fullOrderLinesData } = this.props;
      const activeFoodItemElement = document.getElementById(`food-item-${foodItemId}`);
      const sheetOrderLines = [];
      _fullOrderLinesData.forEach(foodCourse => {
        if (foodCourse.foodCourseOrderLines) {
          foodCourse.foodCourseOrderLines.forEach(groupedOrderLines => {
            if (groupedOrderLines) {
              sheetOrderLines.push(last(groupedOrderLines));
            }
          });
        }
      });

      const firstSheetFoodItemElement = sheetOrderLines.length > 1 ? document.getElementById(`food-item-${sheetOrderLines[0].id}`) : undefined;

      const activeFoodItemElementDistance = activeFoodItemElement
        ? activeFoodItemElement.getBoundingClientRect().top
        : 0;
      const firstFoodItemDistance = firstSheetFoodItemElement
        ? firstSheetFoodItemElement.getBoundingClientRect().top
        : 0;

      const correctionSizer = 15;

      const scrollDistance = activeFoodItemElementDistance - firstFoodItemDistance - correctionSizer;

      if (activeFoodItemElement) {
        setTimeout(() =>
            animateScroll.scrollTo(scrollDistance, {
              containerId: 'OrderLineSheetScrollContainer',
              duration: 400,
              smooth: true
            }),
          500
        );
      }
    };

    if (prevProps._selectedOrderLines.length && !this.props._selectedOrderLines.length) {
      const prevLastSelected = find(propEq('id', last(prevProps._selectedOrderLines)),
        prevProps._orderLinesSorted);
      const lastSelected = find(propEq('id', last(prevProps._selectedOrderLines)),
        this.props._orderLinesSorted);
      const areSame = equals(prevLastSelected, lastSelected);

      if (!areSame) {
        const lastOrderLine = this.props._orderLinesSorted && last(this.props._orderLinesSorted);

        if (lastSelected || lastOrderLine) {
          scrollToFoodItem(lastSelected ? lastSelected.id : lastOrderLine.id);
        }
      }
    } else {
      const {
        _subTables,
        _orderLinesSorted,
        _activeOpenTableId,
        _activeSubTableId
      } = this.props;

      const prevOrderLines = getAllOrderLinesInFocus(prevProps._subTables,
        prevProps._orderLinesSorted, _activeOpenTableId, _activeSubTableId);
      const thisOrderLines = getAllOrderLinesInFocus(_subTables, _orderLinesSorted,
        _activeOpenTableId, _activeSubTableId);

      if (prevOrderLines.length !== thisOrderLines.length && thisOrderLines.length) {
        const lastItem = last(thisOrderLines);

        if (lastItem.linkedToId) {
          const parent = thisOrderLines.find(o => o.id === lastItem.linkedToId);
          scrollToFoodItem(parent.id);
        } else {
          scrollToFoodItem(lastItem.id);
        }
      }
    }
  }

  render() {
    const {
      _selectedOrderLines,
      _subTables,
      _activeOpenTableId,
      _activeSubTableId,
      dispatch,
      ...props
    } = this.props;

    return <BaseComponent {...props} />;
  }
});
