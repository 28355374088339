// @flow
import type { Action, ParametersState } from '../types';
import { isReactNative } from '../app/detectPlatform';
import { clearFsStore } from '../lib/utils';

const initialState = {
  parameters: []
};

const reducer = (state: ParametersState = initialState, action: Action): ParametersState => {
  switch (action.type) {
    case 'REHYDRATE_FS_COMMIT': {
      return {
        ...state,
        ...action.payload.parameters
      };
    }
    case 'SYNC_SERVER_DATA_COMMIT': {
      const { payload: { Parameters } } = action;

      if (isReactNative) {
        const Store = require('react-native-fs-store').default;

        clearFsStore('parameters').then(() => {
          new Store('parameters').setItem('parameters', {
            parameters: Parameters
          });
        });
      }

      return { ...state, parameters: Parameters };
    }

    default:
      return state;
  }
};

export default reducer;
