// @flow
import React from 'react';
import checkPermission, { checkIfAnyPermission } from './service';

type PermissionProps = {
  name: string | string[],
  children: any,
  any?: boolean // if multiple permissions, at least one permission is true
};

class Permission extends React.PureComponent<PermissionProps> {
  render() {
    const { name, children, any } = this.props;

    // ignore if name is empty
    if (!name) return children;

    const permission = typeof name === 'string' ? [name] : name;

    const canAccess = any
      ? checkIfAnyPermission(...permission)
      : checkPermission(...permission);

    return canAccess ? children : null;
  }
}

export default Permission;
