// @flow
import { replace } from 'connected-react-router';
import { animationDuration as delay } from './LoginForm';

let willNeedRedirect = true;

const redirectAfterSignIn = store => next => action => {
  next(action);

  // TODO: maybe some browser-specific epic instead?

  if (action.type === 'APP_STARTED' || action.type === 'SIGN_IN_DONE') {
    willNeedRedirect = true;
  }

  if (willNeedRedirect && (
    action.type === 'BLITZ_SYNC_SERVER_DATA_COMMIT' || action.type === 'SYNC_SERVER_DATA_COMMIT'
  )) {
    willNeedRedirect = false;

    setTimeout(() => {
      store.dispatch(replace('/serviceAreas'));
    }, delay);
  }
};

export default redirectAfterSignIn;
