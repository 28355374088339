// @flow
import type { State } from '../../../common/types';
import React from 'react';
import DefaultTopBar from '../../../common/components/TopBar';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import Button from '../../../common/components/Button';
import Icon from '../../../common/components/Icon';
import Text from '../../../common/components/Text';
import Time from '../../../common/components/Time';
import { signOut } from '../../../common/auth/actions';
import { toggleOrderLineSelect } from '../../../common/order/actions';
import { myNameSelector } from '../../../common/auth/selectors';
import Box from '../../../common/components/Box';
import { toggleLoyalmanUsers } from '../../../common/loyalman/users/actions';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import loyalmanMessages from '../../../common/messages/loyalman';

export const ReceiptTopBar = ({ dispatch, closeTitle, userName, selectedOrderLines, closeRedirectsToOrder }) => (
  <DefaultTopBar backgroundColor="lessDarkGray">
    <Time color="white" bold justifyContent="center" paddingLeft={1} />

    <Button
      flexDirection="row"
      alignItems="center"
      paddingLeft={2.5}
      zIndex={1}
      onPress={() => {
          if (selectedOrderLines) {
            dispatch([
              toggleOrderLineSelect(selectedOrderLines, { absoluteForceSelect: true }),
              replace('/payment')
            ]);
          } else {
            dispatch([
              toggleLoyalmanUsers(false), // TODO: This needs to be executed before replace, because bindScreenLifecycleActions
              closeRedirectsToOrder ? replace('/order') : replace('/serviceAreas')
            ]);
          }
        }
      }
    >
      <Icon
        position="absolute"
        left={0}
        name="close"
        color="black"
        padding={0.76}
        scale={-1}
        backgroundColor="white"
        borderRadius="50%"
      />
      <Text color="white" bold>{closeTitle}</Text>
    </Button>

    <Button flexDirection="row" onPress={() => dispatch(signOut())} paddingRight={1} alignItems="center">
      <Text bold color="white" flexDirection="row">
        <Icon name="user" color="white" bold marginRight={0.25} scale={1} />
        {userName}
      </Text>
    </Button>
  </DefaultTopBar>
);

class TopBar extends React.PureComponent {
  render() {
    const { dispatch, userName, selectedOrderLines, closeRedirectsToOrder, intl } = this.props;

    return (
      <Box zIndex={10}>
        <ReceiptTopBar
          dispatch={dispatch}
          userName={userName}
          selectedOrderLines={selectedOrderLines}
          closeRedirectsToOrder={closeRedirectsToOrder}
          closeTitle={intl.formatMessage(loyalmanMessages.loyalmanCancel)}
        />
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    userName: myNameSelector(state),
    isCancelingReceiptAndReturningToTable: state.admin.receipts.isCancelingReceiptAndReturningToTable,
    selectedOrderLines: state.loyalman.users.selectedOrderLines,
    closeRedirectsToOrder: state.loyalman.users.closeRedirectsToOrder
  })),
  injectIntl
)(TopBar);
