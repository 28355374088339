// @flow
import React from 'react';
import Box from '../../common/components/Box';
import theme from '../../common/themes/theme';

const Sidebar = ({ children, ...restProps }) => (
  <Box
    zIndex={1}
    minWidth={theme.sidebar.minWidth}
    maxWidth={theme.sidebar.maxWidth}
    width={theme.sidebar.width}
    {...restProps}
  >
    {children}
  </Box>
);

export default Sidebar;
