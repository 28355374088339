// @flow
import type { Action } from '../../types';
import { assocPath, dissocPath } from 'ramda';

const initialState = {
  selectedCategoriesId: {},
  categories: [],
  items: {},
  isLoadingCategories: false,
  isLoadingItems: false,
  filters: {}
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'ADMIN_USAGE_CLEAR_SELECTED_CATEGORIES': {
      return { ...state, selectedCategoriesId: {} };
    }

    case 'ADMIN_USAGE_TOGGLE_SELECTED_CATEGORY_ID': {
      const id = `${action.payload.id}`; // dissocPath doesnt play well with num
      const is = !state.selectedCategoriesId[id];

      return is
        ? assocPath(['selectedCategoriesId', id], true, state)
        : dissocPath(['selectedCategoriesId', id], state);
    }

    case 'ADMIN_USAGE_SET_CATEGORIES': {
      const { categories } = action.payload;
      return { ...state, categories };
    }

    case 'ADMIN_USAGE_SET_ITEMS': {
      const { items } = action.payload;
      return { ...state, items };
    }

    case 'ADMIN_USAGE_SET_FILTERS': {
      const { filters } = action.payload;
      return { ...state, filters };
    }

    case 'ADMIN_USAGE_RESET': {
      return initialState;
    }

    case 'ADMIN_USAGE_LOADING_CATEGORIES': {
      const { loading } = action.payload;
      return { ...state, isLoadingCategories: loading };
    }

    case 'ADMIN_USAGE_LOADING_ITEMS': {
      const { loading } = action.payload;
      return { ...state, isLoadingItems: loading };
    }

    default:
      return state;
  }
};

export default reducer;
