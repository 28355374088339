// @flow
if (process.env.ENV === 'production') {
  const Raven = require('raven-js');

  const ravenOptions = {
    release: process.env.APP_VERSION,
    tags: {
      git_commit: process.env.COMMIT
    }
  };

  if (process.env.IS_ELECTRON === '1') {
    Raven.config('https://25e2f1076c4f42c481743849bf838eec@sentry.io/260846', ravenOptions).install();
  } else {
    Raven.config('https://d06cfb08422f441aa6f8dc03914f2f0d@sentry.io/260854', ravenOptions).install();
  }

  window.addEventListener('unhandledrejection', (event) => {
    Raven.captureException(event.reason);
  });
}

if (process.env.IS_ELECTRON !== '1') {
  if (window.location.hash) {
    window.location.href = '/';
  }
}

require('@babel/polyfill');

const { addLocaleData } = require('react-intl');
const intlReducer = require('../common/intl/reducer').default();

intlReducer.locales.forEach(locale => {
  // eslint-disable-next-line import/no-dynamic-require
  const file = require(`react-intl/locale-data/${locale}`);
  addLocaleData(file);
});

if (process.env.IS_ELECTRON === '1') {
  // keep it before ./main
  require('./configureElectron');
}

require('./configureTablets').default();

require('./main');
