// @flow
import React from 'react';
import Box from '../../../../common/components/Box';
import Button from '../../../../common/components/Button';
import Text from '../../../../common/components/Text';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import theme from '../../../themes/defaultTheme';
import { compose, filter, pluck } from 'rambda';
import { isEmpty, difference } from 'ramda';
import { leftJoin } from '../../../../common/lib/moreRambda';
import type { State } from '../../../../common/types';
import ContextMenuButton from '../../../../common/components/ContextMenuButton';
import Radio from '../../../../common/components/Radio';
import escapeRegexp from 'escape-string-regexp';
import { deleteOpenTable } from '../../../../common/tables/actions';
import { findAllCommitedOpenTableIds } from '../../../../common/tables/utils';
import api from '../../../../common/lib/api';
import { addAdminError, toggleAdminNotificationPopup } from '../../../../common/admin/general/actions';
import {
  deleteOrderLinesCommit,
  toggleQuickPaymentLoading
} from '../../../../common/order/actions';
import { getAllOrderLinesOfOpenTable } from '../../../../common/order/utils';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/closures';


class Confirmation extends React.PureComponent {
  constructor(props) {
    super(props);

    const { paymentMedia, paymentTypes } = props;

    const validPaymentTypes = filter(({ nazev = '', nazevKlic = '' }) => {
      const regex = /hoto/i;
      return regex.test(escapeRegexp(nazev)) || regex.test(escapeRegexp(nazevKlic));
    }, paymentTypes);

    const validPaymentMedia = leftJoin(
      ({ idriadok }, { druhyPlId }) => idriadok === druhyPlId,
      validPaymentTypes,
      paymentMedia
    );

    this.state = {
      error: null,
      paymentMedia: validPaymentMedia,
      paymentMediaId: validPaymentMedia[0].id,
      closeUnconfirmed: false,
      openTablesToBeDeleted: [],
      orderLinesToLeave: []
    };
  }

  _afterPay = (dispatch, subTables, orderLines, commitedOpenTableIds, index, tableOrderLines = [], error = false, emptyOrder = false, leaveOpenTable = false) => {
    const { intl } = this.props;
    if (emptyOrder) {
      dispatch(toggleQuickPaymentLoading(false));
    } else if (!error) {
      if (leaveOpenTable) {
        dispatch([
          toggleQuickPaymentLoading(false),
          toggleAdminNotificationPopup(intl.formatMessage(messages.confirmTable)),
          deleteOrderLinesCommit(tableOrderLines)
        ]);
      } else {
        dispatch([
          toggleQuickPaymentLoading(false),
          toggleAdminNotificationPopup(intl.formatMessage(messages.closeTable)),
          deleteOrderLinesCommit(tableOrderLines),
          deleteOpenTable(commitedOpenTableIds[index]),
        ]);
      }
    } else {
      dispatch([
        toggleQuickPaymentLoading(false),
        addAdminError(error)
      ]);
    }

    if (index + 1 < commitedOpenTableIds.length) {
      this.quickPay(subTables, orderLines, commitedOpenTableIds, index + 1);
    } else {
      dispatch([
        replace('/serviceAreas')
      ]);
    }
  };

  quickPay = (subTables, orderLines, commitedOpenTableIds, index) => {
    const { dispatch } = this.props;
    const { paymentMediaId, closeUnconfirmed } = this.state;

    dispatch(toggleQuickPaymentLoading(true));

    const orderLinesOfOpenTable = getAllOrderLinesOfOpenTable(subTables, orderLines, commitedOpenTableIds[index]);
    const orderLineIdsOfOpenTable = pluck('id', orderLinesOfOpenTable);
    const uncomittedOrderLines = filter(ol => !ol.isSentToKitchen, orderLinesOfOpenTable);
    const uncomittedOrderLineIds = pluck('id', uncomittedOrderLines);
    const comittedOrderLineIds = difference(orderLineIdsOfOpenTable, uncomittedOrderLineIds);

    if (isEmpty(orderLinesOfOpenTable)) {
      this._afterPay(dispatch, subTables, orderLines, commitedOpenTableIds, index, [], false, true);
    } else if (uncomittedOrderLines.length === orderLinesOfOpenTable.length) {
      this._afterPay(dispatch, subTables, orderLines, commitedOpenTableIds, index, uncomittedOrderLineIds, false, false);
    } else {
      api.payment.quickPayment([], paymentMediaId, commitedOpenTableIds[index])
        .then(() => {
          this._afterPay(
            dispatch,
            subTables,
            orderLines,
            commitedOpenTableIds,
            index,
            closeUnconfirmed ? orderLineIdsOfOpenTable : comittedOrderLineIds,
            false,
            false,
            uncomittedOrderLines.length && !closeUnconfirmed
          );
        })
        .catch(error => {
          this._afterPay(dispatch, subTables, orderLines, commitedOpenTableIds, index, [], error);
        });
    }
  };

  payAndCloseCloseAllOpenOrders = () => {
    const { openTables, subTables, orderLines } = this.props;
    const { closeUnconfirmed } = this.state;

    const foundOpenTables = findAllCommitedOpenTableIds(openTables, subTables, orderLines, closeUnconfirmed);

    this.quickPay(subTables, orderLines, foundOpenTables, 0);
  };

  render() {
    const { dispatch, intl } = this.props;
    const { error, paymentMediaId, paymentMedia, closeUnconfirmed } = this.state;

    return (
      <Box
        backgroundColor="adminPanelBg"
        borderRadius="normal"
        boxShadow="default"
        width={28}
        alignSelf="center"
        marginTop={4}
        marginBottom="auto"
        paddingHorizontal={3}
        paddingVertical={2}
      >
        <Text scale={3} marginBottom={0.5} align="center">{intl.formatMessage(messages.closeAllClosuresTitle)}</Text>
        <Text marginBottom={1} align="center">{intl.formatMessage(messages.closeAllClosuresInfo)}</Text>

        <Box
          flexDirection="row"
          flexWrap="wrap"
          marginBottom={paymentMedia.length > 2 ? 1.5 : 0.5}
          overflow="auto"
          maxHeight={11}
        >
          {paymentMedia.map(paymentMedia =>
            <ContextMenuButton
              key={paymentMedia.id}
              text={paymentMedia.name}
              onPress={() => this.setState({ paymentMediaId: paymentMedia.id })}
              icon="price-level"
              width="40%"
              marginRight="10%"
              color={paymentMedia.id === paymentMediaId ? 'teal' : 'black'}
            />
          )}
        </Box>

        <Button
          marginBottom={1}
          paddingVertical={0.5}
          onPress={() => this.setState({ closeUnconfirmed: !closeUnconfirmed })}
        >
          <Radio size={1} marginRight={0.5} backgroundColor="adminPanelBg" checked={closeUnconfirmed} />
          <Text>{intl.formatMessage(messages.closeAllTablesInfo)}</Text>
        </Button>

        <Box flexDirection="row" justifyContent="center">
          <Button
            outline
            color="buttonGray"
            paddingVertical={0.75}
            paddingHorizontal={2}
            marginRight={1.5}
            onPress={() => dispatch(replace('/serviceAreas'))}
          >
            <Text color="buttonGray" bold lineHeight={theme.typography.fontSize()}>{intl.formatMessage(messages.no)}</Text>
          </Button>
          <Button
            backgroundColor="teal"
            outline
            color="buttonTealShadow"
            paddingVertical={0.75}
            paddingHorizontal={2}
            onPress={this.payAndCloseCloseAllOpenOrders}
          >
            <Text color="white" bold lineHeight={theme.typography.fontSize()}>{intl.formatMessage(messages.yes)}</Text>
          </Button>
        </Box>

        {error
        && <Text color="error" marginTop={1} marginBottom={-1}>{error}</Text>
        }
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    paymentMedia: state.payment.paymentMedia,
    paymentTypes: state.payment.paymentTypes,
    orderLines: state.orders.orderLines,
    subTables: state.tables.subTables,
    openTables: state.tables.openTables
  })),
  injectIntl
)(Confirmation);
