// @flow
import { defineMessages } from 'react-intl';

//deprecated
export default defineMessages({
  percentageDiscount: {
    defaultMessage: 'Percentuálna',
    id: 'payment.contextMenus.percentageDiscount'
  },
  absoluteDiscount: {
    defaultMessage: 'Absolútna',
    id: 'payment.contextMenus.absoluteDiscount'
  },
  roomService: {
    defaultMessage: 'Prirážka room service',
    id: 'payment.contextMenus.roomService'
  },
  discount5Percent: {
    defaultMessage: 'Zľava 5%',
    id: 'payment.contextMenus.discount5Percent'
  },
  discount10Percent: {
    defaultMessage: 'Zľava 10%',
    id: 'payment.contextMenus.discount10Percent'
  },
  discount15Percent: {
    defaultMessage: 'Zľava 15%',
    id: 'payment.contextMenus.discount15Percent'
  },
  discount20Percent: {
    defaultMessage: 'Zľava 20%',
    id: 'payment.contextMenus.discount20Percent'
  },
  discount25Percent: {
    defaultMessage: 'Zľava 25%',
    id: 'payment.contextMenus.discount25Percent'
  }
});
