// @flow
import type { Id } from '../types';

export const addLocalId = (pairs: Object<Id, Id>, idName?) => ({
  type: 'LOCAL_IDS_ADD',
  payload: {
    pairs,
    idName
  }
});
