// @flow
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../common/components/Box';
import Popup from '../../components/Popup';
import InactiveBg from '../../components/InactiveBg';
import Heading from '../../../common/components/Heading';
import { isReactNative } from '../../../common/app/detectPlatform';
import ScrollView from '../../../common/components/ScrollView';
import Button from '../../../common/components/Button';
import Text from '../../../common/components/Text';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/serviceAreas';

class VirtualAreasSelectPopup extends React.Component {
  render() {
    const { virtualAreas = [], setVirtualArea, onClose, intl } = this.props;

    return (
      <InactiveBg onClose={onClose}>
        <Popup onClose={onClose}>
          <Heading scale={4} color="white" marginBottom={1}>
            {intl.formatMessage(messages.selectServiceArea)}
          </Heading>
          <Box
            width={isReactNative ? null : 20}
            maxHeight={isReactNative ? '100%' : '70vh'}
            flex={1}
          >
            <Box flex={1}>
              <ScrollView maxHeight={isReactNative ? '100%' : '70vh'}>
                {virtualAreas.map(va => {
                  return (
                    <Button
                      key={va.id}
                      backgroundColor="appBg"
                      outline
                      color="white"
                      marginBottom={0.5}
                      paddingVertical={0.75}
                      paddingHorizontal={2}
                      onPress={() => setVirtualArea(va)}
                      flexShrink={0}
                    >
                      <Text color="white" bold>
                        {(va.name || '').trim()}
                      </Text>
                    </Button>
                  );
                })}
              </ScrollView>
            </Box>
          </Box>
        </Popup>
      </InactiveBg>);
  }
}

export default compose(
  connect(),
  injectIntl
)(VirtualAreasSelectPopup);
