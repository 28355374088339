// @flow
import React from 'react';
import ContextMenu from '../../browser/components/ContextMenu';
import Box from '../../common/components/Box';
import { isReactNative } from '../../common/app/detectPlatform';


const DiscountContextMenu = ({ target, backgroundColor, children }) => isReactNative
  ? (<Box marginTop={2} alignItems="center">
      {children}
    </Box>)
  : (<ContextMenu backgroundColor={backgroundColor} belongsTo={target} side="left" boundaries={target}>
      {children}
    </ContextMenu>);

export default DiscountContextMenu;
