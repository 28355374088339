// @flow

import React from 'react';
import Button from './Button';
import Box from './Box';
import connect from 'react-redux/es/connect/connect';
import ScrollView from './ScrollView';

class PrintersList extends React.PureComponent {
  render() {
    const { printers, onSelect } = this.props;

    return (
      <ScrollView>
        <Box
          width="100%"
          color="white"
          flexDirection="row"
          justifyContent="flex-start"
          flexWrap="wrap"
          overflow="auto"
          paddingBottom={2}
          paddingLeft={1}
          paddingRight={0.5}
        >
          {printers.map(printer => (
            <Button
              key={printer.prnNo}
              backgroundColor="appBg"
              outline
              color="white"
              marginBottom={0.5}
              marginRight={0.5}
              paddingVertical={0.75}
              paddingHorizontal={2}
              onPress={() => onSelect(printer.prnNo)}
              width="32%"
              alignSelf="flex-start"
            >
              {printer.prnName}
            </Button>
          ))}
        </Box>
      </ScrollView>
    );
  }
}

export default connect((state: State) => ({
  printers: state.peripherals.printers
}))(PrintersList);
