// @flow
import React from 'react';
import { connect } from 'react-redux';
import Popup from '../../components/Popup';
import Heading from '../../../common/components/Heading';
import Button from '../../../common/components/Button';
import Text from '../../../common/components/Text';
import api from '../../../common/lib/api';
import { replace } from 'connected-react-router';
import { priceListUpdated } from '../../../common/admin/priceList/actions';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/priceList';

class PriceBox extends React.PureComponent {
  state = {
    loading: false,
    done: false,
    error: ''
  };

  start = () => {
    this.setState({ loading: true, error: '' });
    const { intl } = this.props;

    api.priceList.updatePriceList().then(({ body: { result: { PriceList } } }) => {
      if (PriceList === 1) {
        this.setState({ done: true, loading: false });
        const { dispatch } = this.props;
        dispatch(priceListUpdated());

        setTimeout(() => {
          if (!this._isUnmounted) {
            dispatch(replace('/serviceAreas'));
          }
        }, 2000);
      } else {
        this.setState({ error: intl.formatMessage(messages.priceListUnknownError), loading: false });
      }
    }).catch(e => {
      this.setState({ error: e.toString(), loading: false });
    });
  };

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  render() {
    const { loading, error, done } = this.state;
    const { intl } = this.props;

    return (
      <Popup width={20} showClose={false} backgroundColor="adminPanelBg">
        <Heading scale={2} marginBottom={2}>{intl.formatMessage(messages.priceListUpdateHeader)}</Heading>

        {done
          ? <Text color="teal" bold>{intl.formatMessage(messages.priceListUpdatedInfo)}</Text>
          : loading
            ? <Text>{intl.formatMessage(messages.priceListUpdatingInfo)}</Text>
            : (
              <Button
                onPress={this.start}
                bold
                outline
                paddingVertical={0.5}
                color="black"
              >
                {intl.formatMessage(messages.priceListUpdateNow)}
              </Button>
              )
        }

        {!!error
          && <Text color="error">{error}</Text>
        }
      </Popup>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(PriceBox);
