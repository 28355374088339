// @flow
import React from 'react';
import InactiveBg from '../components/InactiveBg';
import Popup from '../components/Popup';
import TransactionErrorPrompt, {
  newPaymentTransaction
} from '../../common/payment/TransactionErrorPrompt';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { getAllOrderLinesIdInFocusSelector } from '../../common/order/selectors';
import orderKeeper from '../../common/lib/fetch/orderKeeper';
import { paymentOperationsName } from '../../common/lib/api/payment';

class TransactionErrorPromptWrapper extends React.PureComponent {
  reqOrderKeeper = orderKeeper(paymentOperationsName);

  cancel = () => {
    const { dispatch } = this.props;
    dispatch(replace('/order'));
  };

  onNewTransaction = error => {
    const { dispatch, selectedOrderLines } = this.props;
    const transactionPromise = newPaymentTransaction(dispatch, selectedOrderLines)(error);

    return this.reqOrderKeeper(transactionPromise);
  };

  render() {
    return (
      <InactiveBg zIndex={2000}>
        <Popup showClose={false}>
          <TransactionErrorPrompt
            onCancel={this.cancel}
            onNewTransaction={this.onNewTransaction}
          />
        </Popup>
      </InactiveBg>
    );
  }
}

export default connect((state: State) => ({
  selectedOrderLines: getAllOrderLinesIdInFocusSelector(state)
}))(TransactionErrorPromptWrapper);
