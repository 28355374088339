// @flow
import React from 'react';
import { connect } from 'react-redux';
import Title from '../../components/Title';
import Box from '../../../common/components/Box';
import CategoriesList from './CategoriesList';
import titlesMessages from '../../../common/app/messages/titles';
import TopBar from './TopBar';
import SidebarSpace from '../../components/Sidebar';
import Sidebar from './Sidebar';
import AdminNotification from '../AdminNotification';
import { resetUsage } from '../../../common/admin/usage/actions';

class UsagePage extends React.PureComponent {
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetUsage());
  }

  render() {
    return (
      <Box flex={1} height="100%" backgroundColor="appBg">
        <Title message={titlesMessages.admin} />

        <TopBar />

        <Box flexDirection="row" zIndex={1} flex={1} height="calc(100% - 36px)">
          <Box flex={1} id="usage" width="100%">
            <CategoriesList />

            <AdminNotification />
          </Box>

          <SidebarSpace>
            <Sidebar />
          </SidebarSpace>
        </Box>
      </Box>
    );
  }
}

export default connect()(UsagePage);
