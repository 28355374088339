// @flow
import React from 'react';
import Box from '../../common/components/Box';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import TextInput from '../../common/components/TextInput';
import Text from '../../common/components/Text';
import MonthPicker from '../components/MonthPicker';
import { connect } from 'react-redux';
import TypewriterDisplay from '../../common/components/Keyboard/typewriter/OneLineDisplay';
import NumericDisplay from '../../common/components/Keyboard/deposit/Display';
import TypewriterButtons from '../../common/components/Keyboard/typewriter/Buttons';
import NumericButtons from '../../common/components/Keyboard/deposit/Buttons';
import createTypewriterOperations from '../../common/components/Keyboard/typewriter/operations';
import createNumericOperations from '../../common/components/Keyboard/deposit/operations';
import InactiveBg from '../components/InactiveBg';
import Popup from '../components/Popup';
import Keyboard from '../../common/components/Keyboard';
import moment from 'moment/moment';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import skCalendar from 'rc-calendar/lib/locale/sk_SK';

class TableSearch extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      dateRangeOptions: {
        defaultFrom: selectedDateFrom,
        defaultTo: selectedDateTo
      } = {},
      selectedMonth: {
        month: defaultMonth = moment().month(),
        year: defaultYear = moment().year(),
        name: defaultMontName = moment().format('MMMM YYYY')
      } = {},
      showKeyboard = false
    } = props;

    this.state = {
      showKeyboard,
      showCalendar: false,
      showDateRange: false,
      filterValue: '',
      selectedMonth: {
        month: defaultMonth,
        year: defaultYear,
        name: defaultMontName
      },
      selectedDateFrom,
      selectedDateTo,
    };
  }

  toggleKeyboard = () => {
    this.setState({ showKeyboard: !this.state.showKeyboard });
  };

  toggleCalendar = () => {
    this.setState({ showCalendar: !this.state.showCalendar });
  };

  toggleDateRange = () => {
    this.setState({ showDateRange: !this.state.showDateRange });
  };

  setMonth = (selectedMonth) => {
    const { onDateChange } = this.props;

    this.setState({ selectedMonth });
    onDateChange(selectedMonth);
  };

  setRange = ([selectedDateFrom, selectedDateTo]) => {
    const { onDateRangeChange } = this.props;

    this.setState({ selectedDateFrom, selectedDateTo, showDateRange: false });

    if (onDateRangeChange) {
      onDateRangeChange(selectedDateFrom, selectedDateTo);
    }
  };

  setFilter = (value, key) => {
    const { onFilterChange } = this.props;

    this.setState({ filterValue: value });
    if (onFilterChange) {
      onFilterChange(value, key);
    }
  };

  clearFilter = () => {
    const { onFilterChange } = this.props;

    this.setState({ filterValue: '' });
    if (onFilterChange) {
      onFilterChange('');
    }
  };

  render() {
    const {
      showKeyboard,
      showCalendar,
      showDateRange,
      filterValue
    } = this.state;

    const {
      enableFilter = false,
      enableCalendar = true,
      enableDateRange = false,
      numberOfRecords,
      customFilter,
      keyboardType,
      replaceSkCharsWithNums = false
    } = this.props;

    return (
      <Box paddingVertical={0.5} paddingHorizontal={1} width="100%" flexDirection="row" alignItems="center" backgroundColor="lessDarkGray" flexShrink={0}>
        {enableFilter ?
          <React.Fragment>
            <Button onPress={this.toggleKeyboard} flexDirection="row" alignItems="center" flex={1} justifyContent="flex-start">
              <Icon name="search" color="white" marginRight={0.5} />
              {/* // TODO - show number of found items after filter is applied */}
              {/* <Text color="white" marginLeft={0.25} marginRight={0.25}> */}
                {/* {`(${55})`} // count variable */}
              {/* </Text> */}
              <TextInput
                height={1}
                color="white"
                backgroundColor="lessDarkGray"
                value={filterValue}
                flex={1}
              />
            </Button>
            <Button onPress={this.clearFilter} alignItems="center">
              <Icon color="white" name="delete" scale={1} />
            </Button>
          </React.Fragment>
          : <Box flex={1} />
        }

        {customFilter}

        {enableCalendar &&
          <Box width={10} flexDirection="row" justifyContent="flex-end" alignItems="center">
            {numberOfRecords !== undefined &&
              <Text color="white" bold marginRight={0.5}>{`(${numberOfRecords})`}</Text>
            }
            <Button justifyContent="flex-end" alignItems="center" onPress={this.toggleCalendar}>
              <Icon color="white" name="calendar" marginRight={0.5} />
              <Text color="white" bold>
                {this.state.selectedMonth.name}
              </Text>
            </Button>

            {showCalendar &&
              <Box position="absolute" top={2} right={-0.5} zIndex={1}>
                <MonthPicker
                  closeMonthPicker={this.toggleCalendar}
                  setMonth={this.setMonth}
                  selectedYear={this.state.selectedMonth.year}
                  selectedMonth={this.state.selectedMonth.month}
                />
              </Box>
            }
          </Box>
        }

        {enableDateRange &&
          <Box width={13}>
            <Button justifyContent="flex-end" alignItems="center" marginLeft="auto" onPress={this.toggleDateRange}>
              <Icon color="white" name="calendar" marginRight={0.5} />
              <Text color="white" bold>
                {this.state.selectedDateFrom.format('L')} – {this.state.selectedDateTo.format('L')}
              </Text>
            </Button>

            {showDateRange &&
            <Box position="absolute" top={2} left={-19} right={-0.5} zIndex={1} boxShadow="tiny">
              <RangeCalendar
                locale={skCalendar}
                showDateInput={false}
                showToday={false}
                onSelect={this.setRange}
                selectedValue={[this.state.selectedDateFrom, this.state.selectedDateTo]}
              />
            </Box>}
          </Box>
        }

        {showKeyboard &&
          <InactiveBg onClose={this.toggleKeyboard}>
            <Popup onClose={this.toggleKeyboard}>
              <Keyboard
                createOperations={keyboardType === 'numeric'
                  ? createNumericOperations : createTypewriterOperations}
                Buttons={keyboardType === 'numeric' ? NumericButtons : TypewriterButtons}
                Display={keyboardType === 'numeric' ? NumericDisplay : TypewriterDisplay}
                replaceSkCharsWithNums={replaceSkCharsWithNums}
                onLastKey={(key, filterValue) => {
                  if (key === 'send' || key === 'submit') {
                    this.toggleKeyboard();
                  }
                  this.setFilter(filterValue, key);
                }}
                // do not set defaultValue, otherwise user will rewrite input on each keystroke
              />
            </Popup>
          </InactiveBg>
        }
      </Box>
    );
  }
}

export default connect()(TableSearch);
