// @flow
import type { TextProps } from './Text';
import Text from './Text';
import React from 'react';
import theme from '../themes/theme';

const Heading = (props: TextProps) => {
  const {
    bold = true,
    marginBottom = theme.heading.marginBottom,
    ...restProps
  } = props;
  return (
    <Text
      bold={bold}
      marginBottom={marginBottom}
      {...restProps}
    />
  );
};

export default Heading;
