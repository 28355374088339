// @flow
import type { Device } from './index';
import { Observable } from 'rxjs';
import { propEq, find } from 'rambda';

type ProtocolConfig = {
  protocol: any,
  stopBit: number,
  parity: string,
  dataBits: number
}

type Protocols = {
  'nci.pos': ProtocolConfig,
  '4.2.25': ProtocolConfig,
  'cas': ProtocolConfig,
  'tscale': ProtocolConfig
}

type SerialConfig = {
  path: string,
  baudRate: number,
  protocols: Protocols
}

const devicesFactory = (serialConfig: SerialConfig): Device => {
  // eslint-disable-next-line func-names
  const DevicePrototype = function ({ path, serialPortAccess }) {
    if (!serialPortAccess) serialPortAccess = require('serialport');

    let openedDevice;
    let bufferObservable;

    this.exists = () =>
      serialPortAccess.list().then(list => !!find(propEq('comName', path), list));

    this.isOpened = () =>
      !!openedDevice;

    this.open = (protocolName: string) => {
      if (openedDevice) return Promise.resolve(this);

      let device;
      const protocol = serialConfig.protocols[protocolName];

      try {
        // eslint-disable-next-line new-cap
        device = new serialPortAccess(path, {
          baudRate: protocol.baudRate,
          autoOpen: false,
          dataBits: protocol.dataBits,
          parity: protocol.parity
        });
      } catch (e) {
        return Promise.reject(e);
      }

      return new Promise((resolve, reject) => {
        device.open(error => {
          if (error) {
            reject(error);
          } else {
            openedDevice = device;

            openedDevice.on('close', () => {
              openedDevice = null;
            });

            resolve(this);
          }
        });
      });
    };

    this.close = () => {
      if (openedDevice) {
        openedDevice.close(error => {
          openedDevice = null;

          return error ? Promise.reject(error) : Promise.resolve(this);
        });
      }

      return Promise.resolve(this);
    };

    this.pause = () => {
      if (!openedDevice) return Promise.reject(new Error('Device is not opened'));

      openedDevice.pause();
      return Promise.resolve(true);
    };

    this.resume = () => {
      if (!openedDevice) return Promise.reject(new Error('Device is not opened'));

      openedDevice.resume();
      return Promise.resolve(true);
    };

    this.write = (chr: number) =>
      new Promise((resolve, reject) => {
        if (!openedDevice) {
          reject(new Error('Device is not opened'));
          return;
        }

        openedDevice.write(String.fromCharCode(chr), error => {
          if (error) {
            reject(error);
          } else {
            resolve(true);
          }
        });
      });

    this.writeLine = (cmd: string) =>
      new Promise((resolve, reject) => {
        if (!openedDevice) {
          reject(new Error('Device is not opened'));
          return;
        }

        openedDevice.write(cmd, error => {
          if (error) {
            reject(error);
          } else {
            resolve(true);
          }
        });
      });
    this.getObserver = () => {
      if (!openedDevice) throw new Error('Device is not opened');

      if (bufferObservable) return bufferObservable;

      bufferObservable = Observable.create(observer => {
        openedDevice.on('data', buffer => {
          observer.next(buffer);
        });

        openedDevice.on('error', error => {
          observer.error(error);
        });
      });

      return bufferObservable;
    };
  };

  return new DevicePrototype(serialConfig);
};

export default devicesFactory;
