// @flow
import React from 'react';
import Box from '../../../../common/components/Box';
import Button from '../../../../common/components/Button';
import Text from '../../../../common/components/Text';
import theme from '../../../themes/defaultTheme';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import skCalendar from 'rc-calendar/lib/locale/sk_SK';
import moment from 'moment';
import Spinner from '../../../../common/components/Spinner';
import api from '../../../../common/lib/api';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { findParamValue } from '../../../../common/parameters/utils';
import type { State } from '../../../../common/types';
import Radio from '../../../../common/components/Radio';
import { update } from 'ramda';
import ClosurePrintPreview from '../ClosurePrintPreview';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/closures';
import { compose } from 'rambda';

class Confirmation extends React.PureComponent {
  state = {
    dateFrom: moment().subtract(1, 'months').startOf('month'),
    dateTo: moment().subtract(1, 'months').endOf('month'),
    preview: undefined,
    step: 1, // 1 = cashiers; 2 = closures
    loadingCashiers: false,
    loading: false,
    error: null,
    done: false,
    cashiers: [],
    selectedClosures: {}
  };

  cancel = () => {
    if (!this.unmounted) {
      const { dispatch } = this.props;
      dispatch(replace('/serviceAreas'));
    }
  };

  fetchCashiers = () => {
    const { dateFrom, dateTo } = this.state;

    this.setState({ loadingCashiers: true, error: false });

    api.cashiers.getCashiersClosuresForRange(dateFrom.format('YYYY-MM-DD[T]HH:mm:ss'), dateTo.format('YYYY-MM-DD[T]HH:mm:ss'))
      .then(({ body: { result: { cashiers } } }) => {
        this.setState({ loadingCashiers: false, cashiers });
      }).catch(e => {
      this.setState({ error: e.toString(), loadingCashiers: false });
    });
  };

  toggleCashierSelection = (i, selected) => {
    const { cashiers } = this.state;
    const selectedCashier = cashiers[i];

    selectedCashier.closures = selectedCashier.closures.map(closure => ({
      ...closure,
      _selected: false
    }));
    selectedCashier._selected = !selected;

    this.setState({
      cashiers: update(i, selectedCashier, cashiers)
    });
  };

  toggleClosureSelection = (cashierId, i, selected) => {
    const { cashiers } = this.state;

    const cashierIndex = cashiers.findIndex(x => x.id_kas === cashierId);
    const belongingCashier = cashiers[cashierIndex];

    belongingCashier.closures[i]._selected = !selected;

    this.setState({
      cashiers: update(cashierIndex, belongingCashier, cashiers)
    });
  };

  setRange = ([dateFrom, dateTo]) => {
    this.setState({ dateFrom, dateTo }, () => {
      this.fetchCashiers();
    });
  };

  componentDidMount() {
    this.fetchCashiers();
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  openClosuresSelection = (open = true) => {
    this.setState({ step: open ? 2 : 1 });
  };

  confirm = () => {
    this.setState({ loading: true, error: false, selectedClosures: {} });

    const { cashiers } = this.state;
    const { paramIsEkasa } = this.props;

    let i = 1;
    const selectedClosures = {};
    cashiers.forEach(cashier => {
      if (cashier._selected) {
        cashier.closures.forEach(closure => {
          if (closure._selected) {
            selectedClosures[i++] = { c_uzaverka: closure.id };
          }
        });
      }
    });

    if (paramIsEkasa) {
      api.cashiers.multiClosure(selectedClosures)
        .then(({ body: { result: { preview: { data: preview } } } }) => {
          this.setState({ loading: false, done: true, preview, selectedClosures });
        }).catch(e => {
          this.setState({ error: e.toString(), loading: false });
        });
    } else {
      api.cashiers.multiClosure(selectedClosures)
        .then(() => {
          this.setState({ loading: false, done: true, selectedClosures });
          setTimeout(this.cancel, 3000);
        }).catch(e => {
          this.setState({ error: e.toString(), loading: false });
        });
    }
  };

  render() {
    // eslint-disable-next-line max-len
    const { error, dateFrom, dateTo, loading, loadingCashiers, done, preview, step, cashiers, selectedClosures } = this.state;
    const { intl } = this.props;

    if (preview) {
      return (
        <ClosurePrintPreview
          dateFrom={dateFrom}
          dateTo={dateTo}
          closurePreview={preview}
          multipleCashiers={true}
          selectedClosures={selectedClosures}
        />
      );
    }

    const dateFromFormatted = dateFrom.format('DD.MM.YYYY', { trim: false });
    const dateToFormatted = dateTo.format('DD.MM.YYYY', { trim: false });

    const filteredCashiers = step === 2
      ? cashiers.filter(cashier => cashier._selected)
      : null;

    if (loading || done) {
      let inner;
      if (loading) {
        inner = [
          <Text key={1} marginBottom={2} scale={3} align="center">{intl.formatMessage(messages.closureSummaryMultipleCreating)}</Text>,
          <Box key={2} paddingTop={3}>
            <Spinner />
          </Box>
        ];
      } else if (done) {
        inner = [
          <Text key={1} marginBottom={2} scale={3} align="center">{intl.formatMessage(messages.closureSummaryMultipleCreated)}</Text>
        ];
      }

      return (
        <Box
          backgroundColor="adminPanelBg"
          borderRadius="normal"
          boxShadow="default"
          alignSelf="center"
          marginTop={4}
          paddingHorizontal={3}
          paddingVertical={2}
          width={36}
        >
          {inner}

          {error
          && <Text color="error" marginTop={1} marginBottom={-1}>{error}</Text>
          }
        </Box>
      );
    }

    return (
      <Box
        backgroundColor="adminPanelBg"
        borderRadius="normal"
        boxShadow="default"
        alignSelf="center"
        marginTop={4}
        paddingHorizontal={3}
        paddingVertical={2}
        width={step === 1 ? 60 : 40}
      >
        <Text key={1} marginBottom={1.5} scale={3} align="center">{intl.formatMessage(messages.closureSummaryMultipleCashiers)}</Text>

        {step === 1
        && <Box key={2} flexDirection="row">
            <Box width={60}>
              <Box flexDirection="row">
                <Text width="50%" align="center">{`${intl.formatMessage(messages.closureSummaryFrom)} ${dateFromFormatted}`}</Text>
                <Text width="50%" align="center">{`${intl.formatMessage(messages.closureSummaryTo)} ${dateToFormatted}`}</Text>
              </Box>
              <RangeCalendar
                key={3}
                locale={skCalendar}
                showDateInput={false}
                showToday={false}
                onSelect={this.setRange}
                selectedValue={[dateFrom, dateTo]}
              />
            </Box>
            <Box paddingHorizontal={0.5} paddingVertical={0} width={40} marginTop={0} marginLeft={1}>
              <Text align="center">{intl.formatMessage(messages.closureSummaryMultipleCashiersTitle)}</Text>
              {loadingCashiers
              ? <Box height="100%" overflow="auto">
                  <Box key={2} marginTop={2} paddingTop={5}>
                    <Spinner />
                  </Box>
                </Box>
              : <Box height="37vh" overflow="auto">
                  {cashiers.length
                    ? cashiers.map(({ _selected, name }, i) => (
                        <Button
                          key={i}
                          backgroundColor="white"
                          outline
                          color="orderLineBg"
                          marginTop={0.25}
                          marginBottom={0.25}
                          paddingVertical={0.75}
                          paddingHorizontal={2}
                          onPress={() => this.toggleCashierSelection(i, _selected)}
                          flexShrink={0}
                        >
                          <Radio size={1} color="appBg" left={0.75} position="absolute" backgroundColor="white" checked={_selected}/>
                          <Text color="appBg" bold>
                            {name}
                          </Text>
                        </Button>
                      ))
                    : <Text align="center" marginTop={1} color="appBg">{intl.formatMessage(messages.closureSummaryMultipleCashiersNotFound)}</Text>
                  }
                </Box>
              }
            </Box>
          </Box>
        }

        {step === 2
        && <Box paddingHorizontal={0.5} paddingVertical={0} marginTop={0} marginHorizontal="auto" width="60%">
            <Text align="center">{intl.formatMessage(messages.closureSummaryMultipleClosuresTitle)}</Text>
            <Box height="45vh" overflow="auto" marginTop={0.5}>
              {filteredCashiers.length
                ? filteredCashiers.map(cashier => (
                  <React.Fragment key={cashier.id_kas}>
                    {
                      cashier.closures.map(({ _selected, id, name }, i) => (
                        <Button
                          key={id}
                          backgroundColor="white"
                          outline
                          color="orderLineBg"
                          marginTop={0.25}
                          marginBottom={0.25}
                          paddingVertical={0.75}
                          paddingHorizontal={2}
                          flexShrink={0}
                          onPress={() => this.toggleClosureSelection(cashier.id_kas, i, _selected)}
                        >
                          <Radio size={1} color="appBg" left={1} top={1.2} position="absolute" backgroundColor="white" checked={_selected}/>
                          <Box>
                            <Text color="appBg" align="center" bold>
                              {name}
                            </Text>
                            <Text align="center" size={0.8}>{cashier.name}</Text>
                          </Box>
                        </Button>
                      ))
                    }
                  </React.Fragment>
                ))
                : <Text align="center" marginTop={1} color="appBg">{intl.formatMessage(messages.closureSummaryMultipleClosuresNotFound)}</Text>
                }
            </Box>
          </Box>
        }

        {step === 1
        ? <Box marginTop={2} key={4}>
            <Button
              backgroundColor="teal"
              outline
              color="buttonTealShadow"
              paddingVertical={0.75}
              paddingHorizontal={2}
              alignSelf="center"
              onPress={this.openClosuresSelection}
            >
              <Text color="white" bold lineHeight={theme.typography.fontSize()}>{intl.formatMessage(messages.closureSummaryMultipleChooseContinue)}</Text>
            </Button>
          </Box>
        : <Box marginTop={2} key={4}>
          <Button
              backgroundColor="teal"
              outline
              color="buttonTealShadow"
              paddingVertical={0.75}
              paddingHorizontal={2}
              alignSelf="center"
              onPress={() => this.confirm()}
            >
              <Text color="white" bold lineHeight={theme.typography.fontSize()}>{intl.formatMessage(messages.closureSummaryMultipleConfirmClosure)}</Text>
            </Button>
            <Button
              backgroundColor="teal"
              outline
              color="buttonTealShadow"
              paddingVertical={0.75}
              paddingHorizontal={2}
              alignSelf="center"
              onPress={() => this.openClosuresSelection(false)}
              marginTop={1}
            >
              <Text color="white" bold lineHeight={theme.typography.fontSize()}>{intl.formatMessage(messages.closureSummaryMultipleBackToCashiers)}</Text>
            </Button>
        </Box>}

        {error
          && <Text color="error" marginTop={1} marginBottom={-1}>{error}</Text>
        }
      </Box>
    );
  }
}

export default compose(
  connect(
    (state: State) => ({
      parameters: state.parameters.parameters,
    }),
    null,
    ({
       parameters
     }, { dispatch }, ownProps) => {
      const paramIsEkasa = findParamValue('K32.ekasa', parameters);
      return {
        ...ownProps,
        dispatch,
        paramIsEkasa,
      };
    }
  ),
  injectIntl
)(Confirmation);
