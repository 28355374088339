// @flow

import { createSelector } from 'reselect';
import { find, propEq } from 'rambda';

const activePageIdSelector = (state: State) => state.menuItems.activePageId;

const menuItemPagesSelector = (state: State) => state.menuItems.pages;

export const activePageSelector = createSelector(
  activePageIdSelector,
  menuItemPagesSelector,
  (activePageId, menuItemPages) =>
    find(propEq('id', activePageId), menuItemPages)
);

