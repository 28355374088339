// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  closureChooseLeft: {
    defaultMessage: 'Vyberte uzávierku zo zoznamu vľavo',
    id: 'components.closure.chooseLeft'
  },
  closureNoData: {
    defaultMessage: 'Uzávierka neobsahuje žiadne údaje',
    id: 'components.closure.noDate'
  },
  tableAreaNewTable: {
    defaultMessage: 'Nový stôl',
    id: 'components.tableArea.newTable'
  },
  sheetRenderersFor: {
    defaultMessage: 'za',
    id: 'components.sheetRenderers.for'
  },
  sheetRenderersNoItems: {
    defaultMessage: 'Žiadne položky',
    id: 'components.sheetRenderers.noItems'
  },
  paymentSheetDiscount: {
    defaultMessage: 'Zľava',
    id: 'components.paymentSheet.discount'
  },
  paymentSheetPrepaid: {
    defaultMessage: 'Predplatené prod.',
    id: 'components.paymentSheet.prepaid'
  },
  paymentSheetCreditIncr: {
    defaultMessage: 'Navýšenie kreditu',
    id: 'components.paymentSheet.creditIncr'
  },
  paymentSheetBonusIncr: {
    defaultMessage: 'Navýšenie bonusu',
    id: 'components.paymentSheet.bonusIncr'
  },
  paymentSheetTip: {
    defaultMessage: 'Zadať TIP',
    id: 'components.paymentSheet.tip'
  },
  paymentSheetTipSimple: {
    defaultMessage: 'tip',
    id: 'components.paymentSheet.tipSimple'
  },
  paymentSheetOverpay: {
    defaultMessage: 'preplatok',
    id: 'components.paymentSheet.overpay'
  },
  paymentSheetDesc: {
    defaultMessage: 'Pozn.',
    id: 'components.paymentSheet.desc'
  },
  orderLineSheetAdd: {
    defaultMessage: 'Pridať chod.',
    id: 'components.orderLineSheet.add'
  },
  orderLineDetailTime: {
    defaultMessage: 'čas',
    id: 'components.orderLineDetailTime.time'
  },
  orderLineDetailBase: {
    defaultMessage: 'základ',
    id: 'components.orderLineDetailTime.base'
  },
  oneTimeTable: {
    defaultMessage: 'Jednoraz. stôl',
    id: 'components.oneTimeTable'
  }
});
