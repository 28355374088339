// @flow
import React from 'react';
import Box from './Box';
import Button from './Button';
import Icon from './Icon';
import Text from './Text';
import { isReactNative } from '../app/detectPlatform';
import { displayEllipsis } from '../../browser/themes/utils';

type ContextMenuButtonProps = {
  text: Object,
  onPress: any,
  onLongPress: any,
  icon: string,
  iconBackground?: string,
}

const ContextMenuButton = ({
  marginBottom = 0.5,
  onPress,
  onLongPress,
  width,
  icon,
  iconBackground = 'fullTransparent',
  text,
  color = 'white',
  ...restProps
}: ContextMenuButtonProps) => (
  <Button
    justifyContent="flex-start"
    alignItems="center"
    marginBottom={marginBottom}
    onPress={onPress}
    onLongPress={onLongPress}
    width={width || '100%'}
    {...restProps}
  >
    {icon
      ? <Box
          borderStyle="solid"
          borderWidth={2}
          borderColor={color}
          backgroundColor={iconBackground}
          width={2}
          height={2}
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
          style={theme => ({ borderRadius: theme.typography.lineHeight() })}
        >
          {(typeof icon === 'string')
            ? <Icon name={icon} color={color} scale={2} />
            : icon}
        </Box>
      : null}
    <Text color={color} justifyContent="center" marginLeft={0.5} bold style={isReactNative ? {} : displayEllipsis}>
      {text}
    </Text>
  </Button>
);

export default ContextMenuButton;
