// @flow
import type { Action, Id } from '../types';


export const updateActualWeight = (actualWeight: number): Action => ({
  type: 'PERIPHERALS_WEIGHT_UPDATE',
  payload: {
    actualWeight
  }
});

type itemForWighting = {
  id: Id,
  // count: number
};

export const toggleWeightForm = (item: boolean | itemForWighting, selectAfter: boolean): Action => ({
  type: 'PERIPHERALS_TOGGLE_WEIGHT_FORM',
  payload: {
    item,
    selectAfter
  }
});

export const setWeightToConnected = (): Action => ({
  type: 'PERIPHERALS_SET_WEIGHT_TO_CONNECTED',
  payload: {
    is: true
  }
});
