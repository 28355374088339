// @flow
import type { State } from '../../common/types';
import defaultTheme from '../themes/defaultTheme';
import Helmet from 'react-helmet';
import React from 'react';
import common from '../../common/app/common';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { activityRegister, changeFullScreen } from '../../common/activity/actions';
import { withRouter } from 'react-router';
import ConnectivityIndicator from '../components/ConnectivityIndicator';
import ErrorCatcher from '../../common/components/ErrorCatcher';
import Fullscreen from 'react-full-screen';
import { injectIntl } from 'react-intl';
import { setIntl } from '../../common/intl/actions';

type AppProps = {
  children: any,
  currentLocale: string,
  dispatch: Function
};

const styleRoot = {
  position: 'relative',
  flexDirection: 'column',
  display: 'flex',
  height: '100vh'
};

const style = {
  position: 'relative',
  flexDirection: 'column',
  display: 'flex',
  height: '100%',
  overflow: 'hidden'
};

class App extends React.PureComponent<AppProps> {
  componentWillMount() {
    const { dispatch, intl } = this.props;
    dispatch(setIntl(intl));
  }

  render() {
    const { children, currentLocale, isFullScreen, activityRegister, changeFullScreen, dispatch } = this.props;

    return (
      <ErrorCatcher>
        <Fullscreen
          enabled={isFullScreen}
          onChange={isFullScreen => dispatch(changeFullScreen(isFullScreen))}
        >
          <div style={styleRoot} onClick={activityRegister}>
            <Helmet htmlAttributes={{ lang: currentLocale }}>
              <meta name="viewport" content="width=device-width, maximum-scale=1" />
            </Helmet>
            <ConnectivityIndicator />
            <div style={style}>
              {children}
            </div>
          </div>
        </Fullscreen>
      </ErrorCatcher>
    );
  }
}

export default compose(
  withRouter,
  common({ theme: defaultTheme }),
  connect((state: State) => ({
    currentLocale: state.intl.currentLocale,
    isFullScreen: state.app.isFullScreen
  }), { activityRegister, changeFullScreen }),
  injectIntl,
)(App);
