// @flow
import type { Theme } from '../../common/themes/types';
import typography, { defaultFontFamily, monospaceFamily } from '../../common/themes/typography';

const lineHeight = 18;
const lineHeightMonospace = 16;
const fontSize = 14;
const fontSizeMonospace = 15;
const scale = 1.333;

const theme: Theme = {
  typography: typography({
    lineHeights: {
      default: {
        [-2]: lineHeight * 0.75,
        [-1]: lineHeight * 0.875,
        0: lineHeight,
        1: lineHeight * 1.25,
        2: lineHeight * 1.5,
        3: lineHeight * 1.75,
        4: lineHeight * 2,
        5: lineHeight * 2.25,
        6: lineHeight * 2.5,
        7: lineHeight * 2.75,
        8: lineHeight * 3,
        9: lineHeight * 3.25,
        10: lineHeight * 3.5,
      },
      monospace: {
        [-2]: lineHeightMonospace * 0.75,
        [-1]: lineHeightMonospace * 0.875,
        0: lineHeightMonospace,
        1: lineHeightMonospace * 1.25,
        2: lineHeightMonospace * 1.5,
        3: lineHeightMonospace * 1.625,
        4: lineHeightMonospace * 1.75,
        5: lineHeightMonospace * 2,
        6: lineHeightMonospace * 2.25,
        7: lineHeightMonospace * 2.5,
        8: lineHeightMonospace * 2.625,
        9: lineHeightMonospace * 2.75,
        10: lineHeightMonospace * 3,
      }
    },
    fontSizes: {
      default: {
        [-3]: fontSize * scale ** -1.2,
        [-2]: fontSize * scale ** -1,
        [-1]: fontSize * scale ** -0.5,
        0: fontSize * scale ** 0,
        1: fontSize * scale ** 0.5,
        2: fontSize * scale ** 1,
        3: fontSize * scale ** 1.5,
        4: fontSize * scale ** 2,
        5: fontSize * scale ** 2.5,
        6: fontSize * scale ** 3,
        7: fontSize * scale ** 3.5,
        8: fontSize * scale ** 4,
        9: fontSize * scale ** 4.5,
        10: fontSize * scale ** 5
      },
      monospace: {
        [-3]: fontSizeMonospace * scale ** -0.85,
        [-2]: fontSizeMonospace * scale ** 0.75,
        [-1]: fontSizeMonospace * scale ** -0.5,
        0: fontSizeMonospace * scale ** 0,
        1: fontSizeMonospace * scale ** 0.5,
        2: fontSizeMonospace * scale ** 1,
        3: fontSizeMonospace * scale ** 1.5,
        4: fontSizeMonospace * scale ** 2,
        5: fontSizeMonospace * scale ** 2.5,
        6: fontSizeMonospace * scale ** 3,
        7: fontSizeMonospace * scale ** 3.5,
        8: fontSizeMonospace * scale ** 4,
        9: fontSizeMonospace * scale ** 4.5,
        10: fontSizeMonospace * scale ** 5,
      }
    },
    letterSpacings: {
      default: {
        [-2]: 0.3,
        [-1]: 0,
        0: 0,
        1: 0,
        2: -0.1,
        3: -0.3,
        4: -0.5,
        5: -0.5,
        6: -0.5,
        7: -0.5,
        8: -0.5,
        9: -0.5,
        10: -0.5,
      },
    }
  }),
  colors: {
    primary: '#228ae6',
    success: '#51cf66',
    warning: '#fd7e14',
    danger: '#fa5252',
    orange: '#ffc078',
    green: '#82c91e',
    darkBlue: '#1b6ec2',
    darkTeal: '#087f5b',

    white: '#ffffff',
    black: '#000000',
    blue: '#479ccf',
    lightBlue: '#25aad6',
    red: '#eb5959',
    teal: '#109b97',
    tealV2: '#47aeac',
    lightTeal: '#5a9b9a',
    aquamarine: '#bdf8f0',
    tealSemiTransparent: 'rgba(16, 155, 151, 0.9)',
    tealAlmostTransparent: 'rgba(16, 155, 151, 0.2)',
    fullTransparent: 'rgba(0, 0, 0, 0)',

    error: '#eb5959',

    gray: '#adb5bd',
    darkGray: '#252a2e',
    darkGraySemiTransparent: 'rgba(37, 42, 46, 0.9)',
    lessDarkGray: '#2e3439',
    lightGray: '#dee2e6',
    lighterGray: '#f8f9fa',

    textNormal: '#bababa',
    textInverse: '#ffffff',
    textHeading: '#838387',
    textUnderstated: '#e4e4e4',

    headerBackground: '#2f2f35',

    buttonGray: '#34343b',
    buttonGrayV2: '#5d5d62',
    buttonGrayShadow: '#2e2e33',
    buttonGraySemiTransparent: 'rgba(52, 52, 59, 0.9)',
    buttonRoundedGray: '#2f2f35',
    lessDarkGrayTransparent: 'rgba(46, 52, 57, 0.9)',
    buttonBlue: '#479ccf',
    buttonBlueShadow: '#3b7093',
    buttonRoundedBlue: '#3d8ebf',
    buttonTeal: '#109b97',
    buttonTealShadow: '#1a7d7b',
    buttonRoundedTeal: '#0b8a86',

    orderLineBg: '#f9f9f9',
    orderLineBgShadow1: '#b9b9ba',
    orderLineBgShadow2: '#8e8e8f',
    orderLineCountLabel: '#e2e2e2',
    orderLineBorder: '#f5f5f5',

    orderLineLabel: '#4a4a4a',
    orderLineLabelSelected: '#ffffff',
    orderLineLabelConfirmed: '#109b97',
    orderLineLabelDeleted: '#d06060',

    orderLineRowBg: '#ffffff',
    orderLineRowSelectedBg: '#34343b',
    orderLineRowSelectedConfirmedBg: '#109b97',

    orderLineHeading: '#f9f9f9',
    orderLineHeadingBorder: '#f5f5f5',
    orderLineHeadingSelected: '#ddf5f4',
    orderLineHeadingBorderSelected: '#d9eae9',

    modal: '#34343b',
    modalConfirmedSelected: '#109b97',

    label: '#ffffff',

    menuItemBlue: '#479ccf',
    menuItemBlueShadow: '#407293',
    menuItemRed: '#cf4761',
    menuItemRedShadow: '#9b3d52',
    menuItemOlive: '#9ab04b',
    menuItemOliveShadow: '#758349',
    menuItemViolet: '#a96cd3',
    menuItemVioletShadow: '#80579f',
    menuItemYellow: '#cfb447',
    menuItemYellowShadow: '#958443',
    menuItemBrown: '#d3986c',
    menuItemBrownShadow: '#a07659',
    menuItemGray: '#959595',
    menuItemGrayShadow: '#707073',

    keyboardButton: '#f9f9f9',
    keyboardButtonHighlight: '#bcbfc3',
    keyboardButtonBorder: '#34343b',
    keyboardButtonShadow: '#898b8d',

    divider: '#49494f',

    popupBg: '#2f2f35',

    tableShadow: '#2f2f35',

    inactiveBg: 'rgba(84, 84, 93, 0.6)',
    inactiveItemsOverlay: 'rgba(255, 255, 255, 0.8)',

    appBg: '#505058',
    gridBg: '#5e5e67',

    iconGray: '#838387',

    grid: 'rgba(75, 75, 84, 0.9)',
    gridThick: 'rgba(75, 75, 84, 0.7)'
  },
  baseSize: 10,
  grid: {
    size: '5px',
    bigSize: '25px'
  },
  states: {
    active: {
      darken: 0.2,
      opacity: 0.7,
    },
    disabled: {
      opacity: 0.5,
    },
  },
  container: {
    maxWidths: {
      small: 540,
      medium: 720,
      big: 960,
      bigger: 1140,
    },
    width: '60%'
  },
  text: {
    bold: 600,
    fontFamily: defaultFontFamily,
    fontFamilyMono: monospaceFamily
  },
  block: {
    marginBottom: 1,
    maxWidth: 21,
  },
  button: {
  },
  heading: {
    fontFamily: defaultFontFamily,
    marginBottom: 1,
  },
  paragraph: {
    marginBottom: 1,
  },
  borderRadius: {
    normal: 2
  },
  boxShadow: {
    default: '0 3px 36px #2f2f35'
  }
};

export default theme;
