// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'rambda';
import Box from '../../common/components/Box';
import Title from '../components/Title';
import TopBar from '../../common/components/TopBar';
import Time from '../../common/components/Time';
import LoginForm from './LoginForm';
import Link from '../components/Link';
import Icon from '../../common/components/Icon';
import type { State } from '../../common/types';
import bindScreenLifecycleActions from '../components/hoc/bindScreenLifecycleActions';
import authNavigatorActions from '../../common/auth/screenActions';
import AutoUpdates from '../autoUpdates/AutoUpdates';
import Version from '../components/Version';

/**
 * Login page, which shows up when nobody is loged-in
 * @returns {React}
 */
const LoginPage = ({ hideSettings }) => (
  <Box>
    <Title message="Login" />

    <TopBar>
      <Box paddingLeft={1} justifyContent="center">
        <AutoUpdates />
      </Box>
      <Box position="absolute" justifyContent="center" height="100%" left="50%" style={{ transform: 'translateX(-50%)' }}>
        <Time bold color="white" />
      </Box>
      {hideSettings
        ? <Box />
        : (<Link to="/setup" justifyContent="center" paddingRight={1}>
            <Icon name="settings" color="white" />
           </Link>
        )
      }
    </TopBar>

    <LoginForm />

    <Version />
  </Box>
);

export default compose(
  bindScreenLifecycleActions(authNavigatorActions),
  connect((state: State) => ({
    hideSettings: state.auth.formDisabled || state.auth.user
  }))
)(LoginPage);
