// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  areas: {
    defaultMessage: 'Miestnosti',
    id: 'serviceAreas.options.areas'
  },
  options: {
    defaultMessage: 'Možnosti',
    id: 'serviceAreas.options.options'
  },
  noMap: {
    defaultMessage: 'Jednorázové stoly',
    id: 'serviceAreas.options.noMap'
  },
  noServiceArea: {
    defaultMessage: 'Neznáma miestnosť',
    id: 'serviceAreas.options.noServiceArea'
  },
  bar: {
    defaultMessage: 'Bar',
    id: 'serviceAreas.options.bar'
  },
  barInCash: {
    defaultMessage: 'Bar v hotovosti',
    id: 'serviceAreas.options.barInCash'
  },
  oneTimeTable: {
    defaultMessage: 'Jednoraz. stôl',
    id: 'serviceAreas.options.oneTimeTable'
  },
  suflik: {
    defaultMessage: 'Šuflík',
    id: 'serviceAreas.options.suflik'
  },
  deposit: {
    defaultMessage: 'Vklad',
    id: 'serviceAreas.options.deposit'
  },
  withdrawal: {
    defaultMessage: 'Výber',
    id: 'serviceAreas.options.withdrawal'
  },
  edit: {
    defaultMessage: 'Editovať',
    id: 'serviceAreas.options.edit'
  },
  editDone: {
    defaultMessage: 'Editácia',
    id: 'serviceAreas.options.editDone'
  },
  sendUnsent: {
    defaultMessage: 'Poslať bločky',
    id: 'serviceAreas.options.sendUnsent'
  },
  openTables: {
    defaultMessage: 'Otvorené stoly',
    id: 'serviceAreas.options.openTables'
  }
});
