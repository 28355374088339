// @flow
import { createSelector } from 'reselect';
import type {
  FoodCourse,
  Id,
  OrderLine,
  SLimit,
  ItemMessage,
  State,
  SubTable,
  PriceLevel,
  AllParameters, TableDefinition
} from '../types';
import { propEq, filter, sort, values, compose, any, map, find, pluck } from 'rambda';
import { comparator, groupBy, isEmpty, sum } from 'ramda';
import {
  activeSubTableIdSelector,
  subTablesForActiveOpenTableSelector,
  foodCoursesForActiveOpenTableSelector,
  undeletedSubTablesSelector,
  activeOpenTableIdSelector,
  activeTableDefinitionSelector
} from '../tables/selectors';
import moment from 'moment';
import {
  calculateOrderLinePriceWithParams,
  extractTotalUniqueOrderLines,
  formatPortion,
  formatQuantity,
  getAllOrderLinesInFocus,
  getPriceLevelName
} from './utils';
import messages from '../messages/common';

export const pendingOrderConfirmation = (state: State) => ({
  isActive: state.orders.active.isConfirming,
  text: state.intl.useIntl.formatMessage(messages.confirmingItems),
});

const byColorAndUpdatedAtDesc = (a, b) => {
  const colorDiff = a.isSentToKitchen - b.isSentToKitchen;

  if (colorDiff) return colorDiff;

  return b.updatedAt - a.updatedAt;
};

export const byColorAndUpdatedAtAsc = (a, b) => {
  return a.updatedAt - b.updatedAt;
};

const byNameAsc = comparator((a, b) => (a.name < b.name));

const filterBySubtableId = (activeSubTableId, subTables) =>
  activeSubTableId === 'all'
    ? filter(orderLine => any(propEq('id', orderLine.subTableId), subTables))
    : filter(propEq('subTableId', activeSubTableId));

export const filterValueSelector = (state: State) => state.orders.filterValue;

const orderLinesSelector = (state: State) => state.orders.orderLines;

const selectedOrderLinesSelector = (state: State) => state.orders.selectedOrderLines;
const itemMessagesSelector = (state: State) => state.items.messages;

export const undeletedOrderLinesSelector = createSelector(
  orderLinesSelector,
  (orderLines: OrderLine[]) => filter(orderline => !orderline._isDeleted, orderLines)
);

export const sortedOrderLinesSelector = createSelector(
  activeSubTableIdSelector,
  subTablesForActiveOpenTableSelector,
  undeletedOrderLinesSelector,
  (activeSubTableId: Id, subTables: SubTable[], orderLines: OrderLine[]) => compose(
    sort(byColorAndUpdatedAtAsc),
    filterBySubtableId(activeSubTableId, subTables),
    values,
  )(orderLines)
);

const itemsSelector = (state: State) => state.items.items;
const priceLevelsSelector = (state: State) => state.items.priceLevels;
const allParametersSelector = (state: State) => state.parameters;

export const orderLinesForDisplaySelector = createSelector(
  sortedOrderLinesSelector,
  itemsSelector,
  priceLevelsSelector,
  allParametersSelector,
  (
    orderLines: OrderLine[],
    items: Item[],
    priceLevels: PriceLevel[],
    allParameters: AllParameters[]
  ) => map(
    ol => {
      let name = ol.name || '';

      if (!name.length) {
        const menuItem = find(propEq('id', ol.itemId), items);
        name = menuItem ? menuItem.menuName : 'NEEVIDOVANÉ';
      }
      name = name.trim();

      const unit = ol.unit || '';

      const priceLevelName = getPriceLevelName(ol.priceLevelId, priceLevels);

      let actualPrice = ol._local
        ? (ol.singlePrice && ol.quantity)
          ? calculateOrderLinePriceWithParams(
              { singlePrice: ol.singlePrice, quantity: ol.quantity, itemId: ol.itemId, portion: ol.portion },
              allParameters.parameters
            )
          : 0
        : ol.finalPrice || 0;

      const isFastMenuHeader = ol.orderType === 10;
      const fastMenuSubItems = isFastMenuHeader &&
        map(
          fmi => {
            const relatedItem = find(propEq('id', fmi.itemId), items);
            return relatedItem
              ? ({
                ...fmi,
                actualPrice: fmi._local ? fmi.singlePrice : fmi.finalPrice,
                menuName: relatedItem.menuName,
                receiptName: relatedItem.receiptName,
                unitName: relatedItem.unit,
                orderType: 11
              })
              : fmi;
          }, orderLines.filter(gol => gol.linkedToId === ol.id));

      if (isFastMenuHeader) {
        actualPrice += sum(pluck('actualPrice', fastMenuSubItems));
      }

      return {
        ...ol,
        name,
        unit,
        priceLevelName,
        actualPrice,
        fastMenuSubItems,
        displayQuantity: ol.quantity === 1
          ? formatPortion(ol.portion)
          : `${formatQuantity(ol.quantity / 1000)}`
      };
    },
    orderLines
  )
);

const activeIsLimitSelector = (state: State) => state.serviceAreas.activeIsLimit;
const sLimitsListSelector = (state: State) => state.tables.sLimits;

export const foodCoursesOrderLinesSelector = createSelector(
  foodCoursesForActiveOpenTableSelector,
  orderLinesForDisplaySelector,
  activeIsLimitSelector,
  sLimitsListSelector,
  activeTableDefinitionSelector,
  (foodCourses: FoodCourse[], orderLines: OrderLine[], activeIsLimit: boolean, sLimits: SLimit[], activeTable: TableDefinition = {}) => {
    const groupByFoodCourse = groupBy((orderLine) => (
      orderLine.foodCourse ? orderLine.foodCourse : 0
    ));

    const groupByGroupingId = groupBy((orderLine) => (
      orderLine.groupingId ? orderLine.groupingId : 'otherGroupingId'
    ));

    const orderLinesGroupedByFoodCourse = groupByFoodCourse(orderLines);

    const { limitId, limitDayId } = activeTable || {};
    const { openTableId } = isEmpty(foodCourses) ? null : foodCourses[0];

    const buildIsLimitFoodCourses = () => {
      let fCIndex = 0;
      const isLimitFoodCourses = map(
        row => {
          const newRow = { ...row, id: fCIndex };
          fCIndex++;
          return newRow;
        },
        sort(
          (a, b) => (a.idRov - b.idRov),
          filter(sLimit => sLimit.idDen === limitDayId, sLimits)
        )
      );

      return map(
        ilfc => {
          const normalFC = find(propEq('id', ilfc.id), foodCourses);

          return normalFC
            ? { ...normalFC, name: (ilfc.chod).trim() }
            : {
              id: ilfc.id,
              name: (ilfc.chod).trim(),
              openTableId
            };
        },
        isLimitFoodCourses
      );
    };

    const updatedFoodCourses = (activeIsLimit && limitDayId)
      ? buildIsLimitFoodCourses()
      : foodCourses;

    const notMatchingCourseIds = [];
    Object.keys(orderLinesGroupedByFoodCourse).forEach(orderLineFcId => {
      if (!Object.keys(updatedFoodCourses).includes(orderLineFcId)) {
        notMatchingCourseIds.push(orderLineFcId);
      }
    });

    notMatchingCourseIds.forEach(fcId => {
      orderLinesGroupedByFoodCourse['0'] = orderLinesGroupedByFoodCourse['0']
        ? [...orderLinesGroupedByFoodCourse['0'], ...orderLinesGroupedByFoodCourse[fcId]] : [...orderLinesGroupedByFoodCourse[fcId]];
    });

    return map(
      fc => {
        const foodCourseOrderLinesGroupedByItemId =
          orderLinesGroupedByFoodCourse[fc.id] === undefined
            ? {}
            : groupByGroupingId(
              sort(
                byColorAndUpdatedAtAsc,
                map(
                  item => ({
                    ...item,
                    updatedAt: +moment(item.updatedAt),
                    groupingId: item.isWeighted
                      ? `weightedItem-${item.id}`
                      : item.orderType === 10
                        ? `fastMenuItem-${item.id}`
                        : item._local === true
                          ? `local-${item.itemId}-${item.priceLevelId}-${item.portion}-${item.actualPrice}`
                          : `${item.itemId}-${item.priceLevelId}-${item.portion}-${item.actualPrice}`,
                    name: item.name
                  }),
                  filter(
                    item => item.orderType !== 11,
                    orderLinesGroupedByFoodCourse[fc.id]
                  )
                )
              )
            );

        const foodCourseOrderLines = map(
          group => foodCourseOrderLinesGroupedByItemId[group.groupingId],
          sort(
            activeIsLimit ? byNameAsc : byColorAndUpdatedAtAsc,
            map(
              group => ({ groupingId: group[0].groupingId, updatedAt: group[0].updatedAt, name: group[0].name }),
              Object.values(foodCourseOrderLinesGroupedByItemId)
            )
          )
        );

        return { ...fc, foodCourseOrderLines };
      },
      updatedFoodCourses
    );
  }
);

export const getAllOrderLinesIdInFocusSelector: Id = createSelector(
  selectedOrderLinesSelector,
  undeletedSubTablesSelector,
  orderLinesSelector,
  activeOpenTableIdSelector,
  activeSubTableIdSelector,
  (selectedOrderLines: Id[],
   subTables: SubTable[],
   orderLines: OrderLine[],
   activeOpenTableId: Id,
   activeSubTableId: Id
  ) =>
    isEmpty(selectedOrderLines)
      ? pluck('id', getAllOrderLinesInFocus(subTables, orderLines, activeOpenTableId, activeSubTableId))
      : selectedOrderLines
);

export const getAllFocusedOrderLinesSelector: Id = createSelector(
  undeletedSubTablesSelector,
  undeletedOrderLinesSelector,
  activeOpenTableIdSelector,
  activeSubTableIdSelector,
  (
   subTables: SubTable[],
   orderLines: OrderLine[],
   activeOpenTableId: Id,
   activeSubTableId: Id
  ) => getAllOrderLinesInFocus(subTables, orderLines, activeOpenTableId, activeSubTableId)
);

export const getOrderLineMessages: Id = createSelector(
  selectedOrderLinesSelector,
  orderLinesSelector,
  itemsSelector,
  itemMessagesSelector,
  (selectedOrderLines: Id[],
   orderLines: OrderLine[],
   items: Item[],
   messages: ItemMessage[]
  ) => {
    const totalUniqueOrderLines = extractTotalUniqueOrderLines(orderLines, selectedOrderLines);
    let uniqItem = totalUniqueOrderLines.length === 1
      ? find(propEq('id', totalUniqueOrderLines[0].itemId), items)
      : null;

    // FST item
    if (!uniqItem && totalUniqueOrderLines.length > 1) {
      uniqItem = find(propEq('spart', 'FST'), totalUniqueOrderLines);
    }

    return uniqItem
      ? isEmpty(uniqItem.messages)
        ? messages || []
        : uniqItem.messages
      : messages || [];
  }
);
