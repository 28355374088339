// @flow
import type { Action, OrdersState } from '../types';
import {
  find,
  append,
  reject,
  propEq,
  map,
  pluck,
  findIndex,
  update,
  flatten,
  sort,
  equals,
  filter
} from 'rambda';
import { assocPath, clone, dissoc, reduceRight, assoc, symmetricDifference, difference } from 'ramda';
import {
  updateOrderLineOnParameter,
  rejectMultipleOrderLines,
  checkOrderLineIfWeighted,
  checkOrderLineIfFiskalSpPohladavka
} from './utils';
import { mergeWithUserEditableStrategy } from '../lib/mergeStrategist/userEditable';
import {
  createDirtyList, dirtyListHasProp, getValueFromDirtyList, mergeDirtyList,
  removeFromDirtyList
} from '../lib/utils';
import { lookUpId } from '../localIds/lookUpService';
import { findParamValue } from '../parameters/utils';

const concatErrors = (error, stateErrors) =>
  [...flatten([error || []]), ...stateErrors || []];

const initialState = {
  active: {
    isTextPopupOpen: false,
    isNumericPopupOpen: false,
    isFastFoodMenuFormOpen: false,
    isPriceOrQuantityFormOpen: false,
    isSubTableMenuOpen: false,
    isRelocatingToFoodCourse: false,
    isRelocatingToSubTable: false, // is defining if the process of relocating takes place AND may be also id of deleted subTable from which we want to migrate its items
    isRelocatingToTable: false,
    isDeletingSubTable: null,
    isChangingPriceLevel: false,
    isChangingPortion: false,
    addingItemCount: 0,
    isConfirming: false,
    showScrollButtons: false,
    activeRoomsFilterListener: true
  },
  availabilityBlocker: {
    show: false,
    wantedCount: 0,
    orderLineParams: null,
    dispatchLoad: null,
    unit: ''
  },
  selectedOrderLines: [],
  orderLines: [],
  isFilterOpened: false,
  filterValue: '',
  error: null,
  quickPayment: {
    paymentMediaId: null,
    active: false
  }
};

const orderLineDirtyProps = [
  'subTableId',
  'foodCourse',
  'itemId',
  'linkedToId',
  'name',
  'orderText',
  'portion',
  'priceFactor',
  'priceLevelId',
  'quantity',
  'singlePrice',
  'updatedAt',
];

const reducer = (state: OrdersState = initialState, action: Action): OrdersState => {
  switch (action.type) {
    case 'BLITZ_SYNC_SERVER_DATA_COMMIT':
    case 'SYNC_SERVER_DATA_COMMIT': {
      const {
        payload: { OrderLine, Parameters },
        meta: { serverDate: serverGlobalTimestamp } = {}
      } = action;
      const paramValueForIsWeighted = findParamValue('K32.vah_sp_l', Parameters);
      const paramValueForFiskalSpPohladavka = findParamValue('K32.fiskal_sp_pohladavka', Parameters);

      const fixedOrderLines = map(orderLine => ({
        paymentId: null,
        ...checkOrderLineIfFiskalSpPohladavka(
          checkOrderLineIfWeighted(orderLine, paramValueForIsWeighted),
          paramValueForFiskalSpPohladavka
        ),
        isSentToKitchen: true
      }), OrderLine);

      const orderLines = mergeWithUserEditableStrategy(
        state.orderLines,
        fixedOrderLines, {
          serverGlobalTimestamp
        }
      );

      return { ...state, orderLines };
    }

    case 'ORDER_FILTER_TOGGLE': {
      const isFilterOpened = !!action.payload.toggle;
      return { ...state, isFilterOpened };
    }

    case 'ORDER_MENU_SCROLL_BUTTONS_TOGGLE': {
      const { show } = action.payload;
      return assocPath(['active', 'showScrollButtons'], show, state);
    }

    case 'ORDER_SET_FILTER_VALUE': {
      const filterValue = action.payload.value;
      return { ...state, filterValue };
    }

    case 'ORDER_LINE_ADD': {
      const { orderLine } = action.payload;
      const orderLines = append({
        ...orderLine,
        _local: true,
        isSentToKitchen: false,
        _dirty: createDirtyList(orderLine, orderLineDirtyProps, false)
      }, state.orderLines);
      return { ...state, orderLines };
    }

    case 'ORDER_LINE_SELECT': {
      const { ids, flags = {} } = action.payload;
      const diff = (a, b) => (a - b);

      const selectedOrderLines = flags.absoluteForceSelect
        ? ids
        : flags.forceSelect
          ? equals(sort(diff, state.selectedOrderLines), sort(diff, ids))
            ? []
            : ids
          : flags.forceDeselect
            ? difference(state.selectedOrderLines, ids)
            : symmetricDifference(state.selectedOrderLines, ids);

      return { ...state, selectedOrderLines };
    }

    case 'ORDER_LINES_CLEAR_SELECTED': {
      if (!state.selectedOrderLines.length) return state;

      return { ...state, selectedOrderLines: [] };
    }

    case 'ORDER_LINE_ADD_NOTE': {
      const { preparedOrderLines = [], updatedAt } = action.payload;

      const orderLines = reduceRight((orderLine, updatedOrderLines) => {
        const foundIndex = findIndex(propEq('id', orderLine.id))(updatedOrderLines);

        if (foundIndex === -1) return updatedOrderLines;

        const oldOrderLine = updatedOrderLines[foundIndex];

        return update(
          foundIndex,
          {
            ...oldOrderLine,
            updatedAt,
            orderText: orderLine.orderText,
            _dirty: mergeDirtyList(
              oldOrderLine._dirty,
              createDirtyList(
                { orderText:
                    dirtyListHasProp('orderText', oldOrderLine._dirty)
                      ? getValueFromDirtyList('orderText', oldOrderLine._dirty)
                      : oldOrderLine.orderText,
                  updatedAt:
                    dirtyListHasProp('updatedAt', oldOrderLine._dirty)
                      ? getValueFromDirtyList('updatedAt', oldOrderLine._dirty)
                      : oldOrderLine.updatedAt,
                },
                orderLineDirtyProps
              )
            )
          },
          updatedOrderLines
        );
      }, state.orderLines, preparedOrderLines);

      return { ...state, orderLines };
    }

    case 'ORDER_LINE_ADD_NOTE_ROLLBACK': {
      const { ids, error } = action.payload;

      const orderLines = reduceRight((id, updatedOrderLines) => {
        const foundIndex = findIndex(propEq('id', id))(updatedOrderLines);

        if (foundIndex === -1) return updatedOrderLines;

        const orderLine = updatedOrderLines[foundIndex];

        return update(
          foundIndex,
          {
            ...orderLine,
            orderText: getValueFromDirtyList('orderText', orderLine._dirty),
            updatedAt: getValueFromDirtyList('updatedAt', orderLine._dirty),
            _dirty: removeFromDirtyList(['orderText', 'updatedAt'], orderLine._dirty)
          },
          updatedOrderLines
        );
      }, state.orderLines, ids);

      return {
        ...state,
        orderLines,
        error: concatErrors(error, state.error)
      };
    }

    case 'ORDER_LINE_CHANGE_PRICE_LEVEL': {
      const { preparedOrderLines = [], priceLevelId, updatedAt } = action.payload;

      const orderLines = reduceRight((orderLine, updatedOrderLines) => {
        const foundIndex = findIndex(propEq('id', orderLine.id))(updatedOrderLines);
        if (foundIndex === -1) return updatedOrderLines;
        const oldOrderLine = updatedOrderLines[foundIndex];

        return update(
          foundIndex,
          {
            ...oldOrderLine,
            updatedAt,
            priceLevelId,
            singlePrice: orderLine.singlePrice,
            _dirty: mergeDirtyList(
              oldOrderLine._dirty,
              createDirtyList(
              {
                singlePrice: dirtyListHasProp('singlePrice', oldOrderLine._dirty)
                  ? getValueFromDirtyList('singlePrice', oldOrderLine._dirty)
                  : oldOrderLine.singlePrice,
                priceLevelId:
                  dirtyListHasProp('priceLevelId', oldOrderLine._dirty)
                    ? getValueFromDirtyList('priceLevelId', oldOrderLine._dirty)
                    : oldOrderLine.priceLevelId,
                updatedAt:
                  dirtyListHasProp('updatedAt', oldOrderLine._dirty)
                    ? getValueFromDirtyList('updatedAt', oldOrderLine._dirty)
                    : oldOrderLine.updatedAt,
              },
              orderLineDirtyProps
              )
            )
            // _dirty: uniq(append('priceLevelId', pol._dirty)),
            // _old: clone(orderLines[foundIndex])
          },
          updatedOrderLines
        );
      }, state.orderLines, preparedOrderLines);

      return { ...state, orderLines };
    }

    case 'ORDER_LINE_CHANGE_PRICE_LEVEL_ROLLBACK': {
      const { ids, error } = action.payload;

      const orderLines = reduceRight((id, updatedOrderLines) => {
        const foundIndex = findIndex(propEq('id', id))(updatedOrderLines);
        if (foundIndex === -1) return updatedOrderLines;
        const orderLine = updatedOrderLines[foundIndex];

        return update(
          foundIndex,
          {
            ...orderLine,
            priceLevelId: getValueFromDirtyList('priceLevelId', orderLine._dirty),
            singlePrice: getValueFromDirtyList('singlePrice', orderLine._dirty),
            updatedAt: getValueFromDirtyList('updatedAt', orderLine._dirty),
            _dirty: removeFromDirtyList(['priceLevelId', 'singlePrice', 'updatedAt'], orderLine._dirty)
          },
          updatedOrderLines
        );
      }, state.orderLines, ids);

      return {
        ...state,
        orderLines,
        error: concatErrors(error, state.error)
      };
    }

    case 'ORDER_LINE_CHANGE_PORTION': {
      const { preparedOrderLines = [], portion, updatedAt } = action.payload;
      let orderLines = clone(state.orderLines);

      pluck('id', preparedOrderLines).forEach(orderLineId => {
          const orderLine = find(propEq('id', orderLineId))(state.orderLines);
          if (!orderLine) return;

          orderLines = updateOrderLineOnParameter(orderLines, orderLineId, {
            updatedAt,
            portion,
            _dirty: mergeDirtyList(
              orderLine._dirty,
              createDirtyList(
                {
                  portion: dirtyListHasProp('portion', orderLine._dirty)
                    ? getValueFromDirtyList('portion', orderLine._dirty)
                    : orderLine.portion,
                  updatedAt: dirtyListHasProp('updatedAt', orderLine._dirty)
                    ? getValueFromDirtyList('updatedAt', orderLine._dirty)
                    : orderLine.updatedAt,
                },
                orderLineDirtyProps
              )
            )
          });
        }
      );

      return { ...state, orderLines };
    }

    case 'ORDER_LINE_CHANGE_PORTION_ROLLBACK': {
      const { ids, error } = action.payload;
      let orderLines = clone(state.orderLines);

      ids.forEach(orderLineId => {
        const orderLine = find(propEq('id', orderLineId), state.orderLines);
        if (!orderLine) return;

        orderLines = updateOrderLineOnParameter(
          orderLines,
          orderLineId,
          {
            updatedAt: getValueFromDirtyList('updatedAt', orderLine._dirty),
            portion: getValueFromDirtyList('portion', orderLine._dirty),
            _dirty: removeFromDirtyList(['portion', 'updatedAt'], orderLine._dirty)
          }
        );
      });

      return {
        ...state,
        orderLines,
        error: concatErrors(error, state.error)
      };
    }

    case 'ORDER_LINES_DELETE': {
      const { ids } = action.payload;
      const updateOrderlines = map(id => {
        const existingOrderline = find(propEq('id', id), state.orderLines);

        return existingOrderline
          ? assoc('_isDeleted', true, existingOrderline)
          : null;
      }, ids);

      const orderLinesWithMarked = reduceRight((orderLine, updatedOrderLines) => {
        if (orderLine) {
          const orderLineIndex = findIndex(propEq('id', orderLine.id), updatedOrderLines);
          return update(orderLineIndex, orderLine, updatedOrderLines);
        }

        return updatedOrderLines;
      }, state.orderLines, updateOrderlines);

      const orderLines = reject(orderLine => orderLine._local && orderLine._isDeleted,
        orderLinesWithMarked);

      return { ...state, orderLines };
    }

    case 'ORDER_LINES_DELETE_COMMIT': {
      const { ids } = action.payload;
      const orderLines = rejectMultipleOrderLines(state.orderLines, ids);
      return { ...state, orderLines };
    }

    case 'ORDER_LINES_DELETE_ROLLBACK': {
      const { ids, error } = action.payload;

      const updateOrderlines = map(id => {
        const existingOrderline = find(propEq('id', id), state.orderLines);

        return existingOrderline
          ? dissoc('_isDeleted', existingOrderline)
          : null;
      }, ids);

      const orderLines = reduceRight((orderLine, updatedOrderLines) => {
          if (orderLine) {
            const orderLineIndex = findIndex(propEq('id', orderLine.id), updatedOrderLines);
            return update(orderLineIndex, orderLine, updatedOrderLines);
          }

          return updatedOrderLines;
        }
        , state.orderLines, updateOrderlines);

      return {
        ...state,
        orderLines,
        error: concatErrors(error, state.error)
      };
    }

    case 'ORDER_LINES_PAY_ROLLBACK':
    case 'ORDER_ADD_ERROR': {
      const { error } = action.payload;

      return {
        ...state,
        error: concatErrors(error, state.error)
      };
    }

    case 'ORDER_CLEAR_ERROR': {
      return assocPath(['error'], null, state);
    }

    case 'ORDER_TEXT_INPUT_TOGGLE': {
      const { open } = action.payload;
      return assocPath(['active', 'isTextPopupOpen'], open, state);
    }

    case 'SET_ACTIVE_ROOMS_FILTER_LISTENER': {
      const { isActive } = action.payload;
      return assocPath(['active', 'activeRoomsFilterListener'], isActive, state);
    }

    case 'ORDER_FAST_FOOD_MENU_FORM_TOGGLE': {
      const { item } = action.payload;
      const isFastFoodMenuFormOpen = item
        ? { id: item.id, count: Math.round(Number(item.count) + Number.EPSILON) }
        : false;
      return assocPath(['active', 'isFastFoodMenuFormOpen'], isFastFoodMenuFormOpen, state);
    }

    case 'ORDER_PRICE_OR_QUANTITY_FORM_TOGGLE': {
      const { item } = action.payload;
      return assocPath(['active', 'isPriceOrQuantityFormOpen'], item, state);
    }

    case 'ORDER_SUB_TABLE_MENU_OPEN_TOGGLE': {
      const { is } = action.payload;
      return assocPath(['active', 'isSubTableMenuOpen'], is, state);
    }

    case 'ORDER_LINE_IS_RELOCATING_TO_FOOD_COURSE': {
      const { is } = action.payload;
      return assocPath(['active', 'isRelocatingToFoodCourse'], is, state);
    }

    case 'ORDER_LINE_IS_RELOCATING_TO_SUB_TABLE': {
      const { is } = action.payload;
      return assocPath(['active', 'isRelocatingToSubTable'], is, state);
    }

    case 'ORDER_LINE_IS_DELETING_SUB_TABLE': {
      const { id } = action.payload;
      return assocPath(['active', 'isDeletingSubTable'], id, state);
    }

    case 'ORDER_LINE_IS_RELOCATING_TO_TABLE': {
      const { is } = action.payload;
      return assocPath(['active', 'isRelocatingToTable'], is, state);
    }

    case 'ORDER_LINE_CHANGE_ADDING_ITEM_COUNT': {
      const { count } = action.payload;
      return assocPath(['active', 'addingItemCount'], count, state);
    }

    case 'ORDER_LINE_RELOCATE_TO_FOOD_COURSE': {
      const { ids, foodCourse, updatedAt } = action.payload;
      let orderLines = clone(state.orderLines);

      ids.forEach(orderLineId => {
          const orderLine = find(propEq('id', orderLineId))(state.orderLines);
          if (!orderLine) return;

          orderLines = updateOrderLineOnParameter(orderLines, orderLineId, {
            foodCourse,
            updatedAt,
            _dirty: mergeDirtyList(
              orderLine._dirty,
              createDirtyList(
                {
                  foodCourse: dirtyListHasProp('foodCourse', orderLine._dirty)
                    ? getValueFromDirtyList('foodCourse', orderLine._dirty)
                    : orderLine.foodCourse,
                  updatedAt: dirtyListHasProp('updatedAt', orderLine._dirty)
                    ? getValueFromDirtyList('updatedAt', orderLine._dirty)
                    : orderLine.updatedAt,
                },
                orderLineDirtyProps
              )
            )
          });
        }
      );

      return { ...state, orderLines };
    }

    case 'ORDER_LINE_RELOCATE_TO_FOOD_COURSE_ROLLBACK': {
      const { ids, error } = action.payload;
      let orderLines = clone(state.orderLines);

      ids.forEach(orderLineId => {
        const orderLine = find(propEq('id', orderLineId), state.orderLines);
        if (!orderLine) return;

        orderLines = updateOrderLineOnParameter(
          orderLines,
          orderLineId,
          {
            foodCourse: getValueFromDirtyList('foodCourse', orderLine._dirty),
            updatedAt: getValueFromDirtyList('updatedAt', orderLine._dirty),
            _dirty: removeFromDirtyList(['foodCourse', 'updatedAt'], orderLine._dirty)
            // _old: undefined
          }
        );
      });

      return {
        ...state,
        orderLines,
        error: concatErrors(error, state.error)
      };
    }

    case 'ORDER_LINE_IS_CHANGING_PRICE_LEVEL': {
      const { is } = action.payload;
      return assocPath(['active', 'isChangingPriceLevel'], is, state);
    }

    case 'ORDER_LINE_IS_CHANGING_PORTION': {
      const { is } = action.payload;
      return assocPath(['active', 'isChangingPortion'], is, state);
    }

    case 'ORDER_LINE_RELOCATE_TO_SUB_TABLE': {
      const { orderLineIds, subTableId, updatedAt } = action.payload;
      let orderLines = clone(state.orderLines);

      orderLineIds.forEach(orderLineId => {
        const orderLine = find(propEq('id', orderLineId), state.orderLines);
        if (!orderLine) return;

        orderLines = updateOrderLineOnParameter(
          orderLines,
          orderLineId,
          {
            subTableId,
            updatedAt,
            _dirty: mergeDirtyList(
              orderLine._dirty,
              createDirtyList({
                subTableId: dirtyListHasProp('subTableId', orderLine._dirty)
                  ? getValueFromDirtyList('subTableId', orderLine._dirty)
                  : orderLine.subTableId,
                updatedAt: dirtyListHasProp('updatedAt', orderLine._dirty)
                  ? getValueFromDirtyList('updatedAt', orderLine._dirty)
                  : orderLine.updatedAt,
                },
                orderLineDirtyProps
              )
            )
            // _old: clone(orderLine)
          }
        );
      });

      return { ...state, orderLines };
    }

    case 'ORDER_LINE_RELOCATE_TO_SUB_TABLE_COMMIT': {
      const { orderLineIds, subTableId, updatedAt } = action.payload;
      let orderLines = clone(state.orderLines);

      orderLineIds.forEach(orderLineId => {
        const orderLine = find(propEq('id', orderLineId), state.orderLines);
        if (!orderLine) return;

        orderLines = updateOrderLineOnParameter(orderLines, orderLineId, {
          subTableId,
          updatedAt,
          _dirty: removeFromDirtyList(['subTableId', 'updatedAt'], orderLine._dirty)
        });
      });

      return { ...state, orderLines };
    }

    case 'ORDER_LINE_RELOCATE_TO_SUB_TABLE_ROLLBACK': {
      const { orderLineIds, error } = action.payload;
      let orderLines = clone(state.orderLines);

      orderLineIds.forEach(orderLineId => {
        const orderLine = find(propEq('id', orderLineId), state.orderLines);
        if (!orderLine) return;

        orderLines = updateOrderLineOnParameter(
          orderLines,
          orderLineId,
          {
            subTableId: getValueFromDirtyList('subTableId', orderLine._dirty),
            updatedAt: getValueFromDirtyList('updatedAt', orderLine._dirty),
            _dirty: removeFromDirtyList(['subTableId', 'updatedAt'], orderLine._dirty)
            // _old: undefined
          }
        );
      });

      return {
        ...state,
        orderLines,
        error: concatErrors(error, state.error)
      };
    }

    case 'UPDATE_ORDER_LINES_LOCAL': {
      const { orderLines } = action.payload;

      const updatingOrderLineIds = pluck('id', orderLines);
      const stateOrderLines = clone(state.orderLines);

      const otherOrderLines = filter(o => !updatingOrderLineIds.includes(o.id), stateOrderLines);
      const updatingOrderLines = filter(o => updatingOrderLineIds.includes(o.id), stateOrderLines);

      if (!updatingOrderLines) return state;

      const mergedOrderLines = updatingOrderLines.map(orderLine => ({
        ...orderLine,
        ...orderLines.find(o => o.id === orderLine.id)
      }));

      return { ...state, orderLines: [...otherOrderLines, ...mergedOrderLines] };
    }

    case 'ORDER_LINES_CONFIRM': {
      const { orderLinesToBeConfirmed, orderLinesUpdateSource } = action.payload;
      const orderLines = reduceRight(
        (id, updatedOrderLines) => {
          const foundIndex = findIndex(propEq('id', id))(updatedOrderLines);
          if (foundIndex === -1) return updatedOrderLines;
          const oldOrderLine = updatedOrderLines[foundIndex];

          return updateOrderLineOnParameter(
            updatedOrderLines,
            id,
            {
              _local: true,
              isSentToKitchen: true,
              _dirty: mergeDirtyList(
                oldOrderLine._dirty,
                createDirtyList(
                  { linkedToId:
                      dirtyListHasProp('linkedToId', oldOrderLine._dirty)
                        ? getValueFromDirtyList('linkedToId', oldOrderLine._dirty)
                        : oldOrderLine.linkedToId,
                  },
                  orderLineDirtyProps
                )
              )
            }
          );
        },
        state.orderLines,
        orderLinesToBeConfirmed
      );

      const active = { ...state.active, isConfirming: true };

      if (orderLinesUpdateSource && orderLines) {
        orderLinesUpdateSource.forEach(source => {
          const orderLine = orderLines.find(orderLine => orderLine.id === source.id);
          if (orderLine) {
            orderLine.tableDefinitionId = source.tableDefinitionId;
            orderLine.subTableId = source.subTableId;
          }
        });
      }

      return { ...state, orderLines, active };
    }

    case 'ORDER_LINES_CONFIRM_COMMIT': {
      const { orderLinesIds, orderLines, changedProps = [] } = action.payload;

      const newOrderLines = reduceRight(
        (payloadOrderLine, updatedOrderLines) => {
          const foundIndex = findIndex(propEq('id', payloadOrderLine.id))(updatedOrderLines);
          if (foundIndex === -1) return updatedOrderLines;

          const orderLine = updatedOrderLines[foundIndex];

          return updateOrderLineOnParameter(updatedOrderLines, payloadOrderLine.id, {
            ...payloadOrderLine,
            _local: false,
            isSentToKitchen: true, // override server
            linkedToId:
              dirtyListHasProp('linkedToId', orderLine._dirty)
                ? lookUpId(getValueFromDirtyList('linkedToId', orderLine._dirty))
                : lookUpId(orderLine.linkedToId),
            _dirty: removeFromDirtyList(['linkedToId', ...changedProps], orderLine._dirty)
          });
        },
        state.orderLines,
        orderLines
      );

      const active = { ...state.active, isConfirming: false };

      return { ...state, orderLines: newOrderLines, active };
    }

    case 'ORDER_LINES_CONFIRM_ROLLBACK': {
      const { orderLinesIds, error } = action.payload;

      const orderLines = reduceRight(
        (id, updatedOrderLines) => {
          const foundIndex = findIndex(propEq('id', id))(updatedOrderLines);
          if (foundIndex === -1) return updatedOrderLines;
          const orderLine = updatedOrderLines[foundIndex];

          return updateOrderLineOnParameter(updatedOrderLines, id, {
            _local: true,
            isSentToKitchen: false,
            _dirty: removeFromDirtyList('linkedToId', orderLine._dirty)
          });
        },
        state.orderLines,
        orderLinesIds
      );

      const active = { ...state.active, isConfirming: false };

      return {
        ...state,
        orderLines,
        error: concatErrors(error, state.error),
        active
      };
    }

    case 'LOCAL_IDS_ADD': {
      const { pairs, idName } = action.payload;

      if (idName === 'subTableId') {
        const orderLines = map(orderLine =>
            pairs[orderLine.subTableId]
              ? ({ ...orderLine, subTableId: pairs[orderLine.subTableId] })
              : orderLine,
           state.orderLines);

        return { ...state, orderLines };
      } else if (idName === 'orderLineId') {
        const orderLines = map(
          orderLine => pairs[orderLine.id] ? { ...orderLine, id: pairs[orderLine.id] } : orderLine,
          state.orderLines
        );

        const selectedOrderLines = map(
          orderLineId => pairs[orderLineId] ? pairs[orderLineId] : orderLineId,
          state.selectedOrderLines
        );

        return { ...state, orderLines, selectedOrderLines };
      } else if (idName === 'openTableId') {
        const orderLines = map(orderLine =>
            pairs[orderLine.openTableId]
              ? ({ ...orderLine, openTableId: pairs[orderLine.openTableId] })
              : orderLine,
          state.orderLines);

        return { ...state, orderLines };
      } else if (idName === 'tableDefinitionId') {
        const orderLines = map(orderLine =>
            pairs[orderLine.tableDefinitionId]
              ? ({ ...orderLine, tableDefinitionId: pairs[orderLine.tableDefinitionId] })
              : orderLine,
          state.orderLines);

        return { ...state, orderLines };
      }

      return state;
    }

    case 'ORDER_SHOW_AVAILABILITY_BLOCKER': {
      const {
        wantedCount,
        orderLineParams,
        dispatchLoad,
        unit
      } = action.payload;

      return {
        ...state,
        availabilityBlocker: {
          show: true,
          wantedCount,
          unit,
          orderLineParams,
          dispatchLoad
        }
      };
    }

    case 'ORDER_HIDE_AVAILABILITY_BLOCKER': {
      return {
        ...state,
        availabilityBlocker: {
          show: false
        }
      };
    }

    case 'ORDER_QUICK_PAYMENT_MEDIA_ID': {
      const { paymentMediaId } = action.payload;
      return assocPath(['quickPayment', 'paymentMediaId'], paymentMediaId, state);
    }

    case 'ORDER_QUICK_PAYMENT_MEDIA': {
      const { paymentMediaId, additionalData } = action.payload;
      return assocPath(['quickPayment', 'additionalData'], additionalData, assocPath(['quickPayment', 'paymentMediaId'], paymentMediaId, state));
    }

    case 'ORDER_QUICK_PAYMENT_TOGGLE_LOADING': {
      const { is: active } = action.payload;
      return assocPath(['quickPayment', 'active'], active, state);
    }

    // case 'ORDER_TERMINAL_PAYMENT_TOGGLE_LOADING': {
    //   const { terminal } = action.payload;
    //   return assocPath(['quickPayment', 'terminal'], terminal, state);
    // }
    //
    // case 'TERMINAL_PAYMENT_SOCKET_MESSAGE_LOADING': {
    //   const { terminalMessage } = action.payload;
    //   return assocPath(['quickPayment', 'terminalMessage'], terminalMessage, state);
    // }

    case 'ORDER_RESET': {
      return {
        ...initialState,
        orderLines: state.orderLines
      };
    }

    case 'ORDER_SOFT_RESET': {
      return {
        ...initialState,
        orderLines: state.orderLines,
        error: state.error
      };
    }

    case 'CLEAR_ORDER_LINES_DIRTY_LIST': {
      const { orderLines } = state;
      const { outbox } = action.payload;
      // if ((!outbox || !outbox.includes(['ORDER_LINES_CONFIRM', 'ORDER_LINES_CONFIRM_COMMIT'])) && orderLines && orderLines.length > 0) {
      if ((!outbox || outbox.length === 0) && orderLines && orderLines.length > 0) {
        orderLines.filter(orderLine => orderLine._dirty).forEach(itemWithDirty => {
          if (Array.isArray(itemWithDirty._dirty) && itemWithDirty._dirty.length && itemWithDirty.isSentToKitchen && !itemWithDirty._local) {
            itemWithDirty._dirty = [];
            // delete itemWithDirty._dirty;
          }
        });
      }

      return { ...state, orderLines };
    }

    default:
      return state;
  }
};

export default reducer;
