// @flow
import type { Operations } from '../index';

const operations: Operations = (saveChanges) => ({
  sendKey(store, key) {
    switch (key) {
      case 'cancel': {
        store.setLastKey(key);
        store.setNewInput('');
        break;
      }

      case 'unlock': {
        store.setLastKey(key);
        break;
      }

      case 'backspace': {
        const curInput = store.curInput();
        if (!curInput.length) return;

        store.setNewInput(curInput.slice(0, -1));
        store.setLastKey(store.getLastKey(1));

        break;
      }

      default:
        store.setNewInput(`${store.curInput()}${key}`);
        store.setLastKey(`${key}`);
    }

    saveChanges(store);
  }
});

export default operations;
