// @flow
import type { Action, LoyalmanUserState } from '../../types';
import { assocPath } from 'ramda';

const initialState = {
  active: {
    user: null,
  },
  isLoyalManOn: false,
  selectedOrderLines: null,
  paymentTransactionId: null,
  closeRedirectsToOrder: false,
  showKeyboard: false
};

const reducer = (state: LoyalmanUserState = initialState, action: Action) => {
  switch (action.type) {
    case 'LOYALMAN_USERS_ACTIVATE_USER': {
      const { user } = action.payload;
      return assocPath(['active', 'user'], user, state);
    }

    case 'LOYALMAN_USERS_TOGGLE': {
      const {
        enableLoyalMan,
        selectedOrderLines,
        paymentTransactionId,
        closeRedirectsToOrder,
        showKeyboard
      } = action.payload;

      return {
        ...state,
        isLoyalManOn: enableLoyalMan,
        selectedOrderLines,
        paymentTransactionId,
        closeRedirectsToOrder,
        showKeyboard
      };
    }

    default:
      return state;
  }
};

export default reducer;
