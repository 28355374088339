// @flow
import type { Action, TablesState } from '../types';
import {
  append, reject, propEq, filter, sortBy, prop, pathOr,
  contains, pluck, map, find, update, findIndex, pipe, concat, uniqWith
} from 'rambda';
import { assocPath, eqProps, dissoc, assoc, clone } from 'ramda';
import { extractFoodCourses } from './utils';
import { mergeWithReadonlyStrategy } from '../lib/mergeStrategist/readonly';
import { delAllLocals } from '../lib/locals';
import { isReactNative } from '../app/detectPlatform';
import { clearFsStore } from '../lib/utils';
import { lookUpId } from '../localIds/lookUpService';
import { isHashValue } from '../order/utils';


const initialState = {
  active: {
    openTableId: null,
    subTableId: null,
    foodCourse: 0
  },
  openTables: [],
  subTables: [],
  foodCourses: [],
  tableDefinitions: [],
  editPosition: false,
  focusOnTable: null,
  focusOnOpenTable: null,
  editTable: null,
  editTableDetail: null,
  isAdminMenuOpen: false,
  sLimits: []
};

const reducer = (state: TablesState = initialState, action: Action): TablesState => {
  switch (action.type) {
    case 'REHYDRATE_FS_COMMIT': {
      return {
        ...state,
        tableDefinitions: action.payload.tableDefinitions
      };
    }
    case 'BLITZ_SYNC_SERVER_DATA_COMMIT':
    case 'SYNC_SERVER_DATA_COMMIT': {
      const {
        payload: {
          OpenTable = [],
          SubTable = [],
          TableDefinition = [],
          OrderLine: orderLines = [],
          SLimits = []
        },
        meta: { serverDate: serverGlobalTimestamp } = {}
      } = action;

      const { active = {}, focusOnTable, editTable } = state;

      const committedTableDefinitions = filter(tableDefinition => !tableDefinition._local,
        state.tableDefinitions);
      const uncommittedTableDefinitions = filter(tableDefinition => tableDefinition._local,
        state.tableDefinitions);

      const mergedTableDefinitions = mergeWithReadonlyStrategy(committedTableDefinitions,
        TableDefinition, { serverGlobalTimestamp }
      );

      const tableDefinitions = concat(
        delAllLocals(mergedTableDefinitions),
        action.type === 'SYNC_SERVER_DATA_COMMIT' ? [] : uncommittedTableDefinitions
      );

      const commitedOpenTables = filter(openTable => !openTable._local, state.openTables);
      const uncommitedOpenTables = filter(openTable => openTable._local, state.openTables);
      const clientTables = commitedOpenTables.filter(cot => !!OpenTable.find(sot => sot.id === lookUpId(cot.id) && !isHashValue(lookUpId(cot.id))));
      const mergedOpenTables = mergeWithReadonlyStrategy(clientTables, OpenTable, {
        idKey: 'name',
        serverGlobalTimestamp
      });
      const openTables = concat(
        delAllLocals(mergedOpenTables),
        action.type === 'SYNC_SERVER_DATA_COMMIT' ? [] : uncommitedOpenTables
      );

      const commitedSubTables = filter(subTable => !subTable._local, state.subTables);
      const uncommitedSubTables = filter(subTable => subTable._local, state.subTables);

      const clientSubTables = commitedSubTables.filter(cst => !!SubTable.find(sst => sst.id === lookUpId(cst.id) && !isHashValue(lookUpId(cst.id))));
      const mergedSubTables = mergeWithReadonlyStrategy(clientSubTables, SubTable, {
        serverGlobalTimestamp
      });
      const subTables = concat(
        delAllLocals(mergedSubTables),
        action.type === 'SYNC_SERVER_DATA_COMMIT' ? [] : uncommitedSubTables
      );

      const foodCourses = extractFoodCourses(orderLines, subTables);

      const validFocusOnTable = contains(focusOnTable, pluck('id', tableDefinitions)) ? focusOnTable : null;
      const validEditTable = contains(editTable, pluck('id', tableDefinitions)) ? editTable : null;

      const updatableFoodCourses = action.type === 'SYNC_SERVER_DATA_COMMIT'
        ? foodCourses
        : uniqWith(
          (x, y) => (x.id === y.id) && (x.openTableId === y.openTableId),
          [...state.foodCourses, ...foodCourses]
        );

      const foundOpenTable = find(ot => ot.id === active.openTableId, openTables);
      const activeOpenTableId = foundOpenTable ? active.openTableId : null;
      const activeSubTableId = contains(active.subTableId, pluck('id', subTables)) ? active.subTableId : (active.subTableId === 'all' ? 'all' : null);

      const tableDefinitionsFixed = map((table: TableDefinition) => ({
        geometry: { x: 0, y: 0, width: 100, height: 100 }, ...table
      }), tableDefinitions);

      const checkIfLimit = () => {
        if (!foundOpenTable) return false;

        const foundTableDefinition = find(
          td => td.id === pathOr(false, 'tableDefinitionId', foundOpenTable),
          tableDefinitionsFixed
        );

        return pathOr(false, 'limitId', foundTableDefinition);
      };


      const activeFoodCourse = contains(active.foodCourse, pluck('id', filter(fc => fc.openTableId === activeOpenTableId, updatableFoodCourses)))
        ? active.foodCourse
        : checkIfLimit()
          ? active.foodCourse
          : 0;

      if (isReactNative && action.type === 'SYNC_SERVER_DATA_COMMIT') {
        const Store = require('react-native-fs-store').default;

        clearFsStore('tables').then(() => {
          new Store('tables').setItem('tableDefinitions', tableDefinitionsFixed);
        });
      }

      return {
        ...state,
        openTables,
        subTables,
        tableDefinitions: tableDefinitionsFixed,
        foodCourses: updatableFoodCourses,
        focusOnTable: validFocusOnTable,
        editTable: validEditTable,
        active: {
          openTableId: activeOpenTableId,
          subTableId: activeSubTableId,
          foodCourse: activeFoodCourse
        },
        sLimits: SLimits
      };
    }

    case 'TABLES_FOCUS': {
      const { tableId, openTableId } = action.payload;
      return { ...state, focusOnTable: tableId, focusOnOpenTable: openTableId };
    }

    case 'TABLES_EDIT_DEFINITION': {
      const { id, detail } = action.payload;
      return { ...state, editTable: id, editTableDetail: detail };
    }

    case 'TABLE_DEFINITION_DELETE': {
      const { id } = action.payload;
      const existingTable = find(propEq('id', id), state.tableDefinitions);

      if (!existingTable) return state;

      const table = assoc('_delete', true, existingTable);

      const tableIndex = findIndex(propEq('id', id), state.tableDefinitions);
      const tableDefinitions = update(tableIndex, table, state.tableDefinitions);

      return { ...state, tableDefinitions };
    }

    case 'TABLE_DEFINITION_DELETE_COMMIT': {
      const { id } = action.payload;
      const tableDefinitions = reject(propEq('id', id), state.tableDefinitions);
      return { ...state, tableDefinitions };
    }

    case 'TABLE_DEFINITION_DELETE_ROLLBACK': {
      const { id } = action.payload;
      const existingTable = find(propEq('id', id), state.tableDefinitions);

      if (!existingTable) return state;

      const table = dissoc('_delete', existingTable);

      const tableIndex = findIndex(propEq('id', id), state.tableDefinitions);
      const tableDefinitions = update(tableIndex, table, state.tableDefinitions);

      return { ...state, tableDefinitions };
    }

    case 'TABLE_DEFINITION_UPDATE': {
      const { id, tableDefinition } = action.payload;
      const existingTable = find(propEq('id', id), state.tableDefinitions);

      if (!existingTable) return state;

      const updatedTable = assoc('_old', clone(existingTable))(
        { ...existingTable, ...tableDefinition });

      const tableIndex = findIndex(propEq('id', id), state.tableDefinitions);
      const tableDefinitions = update(tableIndex, updatedTable, state.tableDefinitions);

      return { ...state, tableDefinitions };
    }

    case 'TABLE_DEFINITION_UPDATE_COMMIT': {
      const { id, tableDefinition } = action.payload;
      const existingTableDefinition = find(propEq('id', id), state.tableDefinitions);

      if (!existingTableDefinition) return state;

      const mergedTableDefinition = pipe(
        dissoc('_local'),
        dissoc('_old')
      )({ ...existingTableDefinition, ...tableDefinition });

      const tableIndex = findIndex(propEq('id', id), state.tableDefinitions);
      const tableDefinitions = update(tableIndex, mergedTableDefinition, state.tableDefinitions);

      return { ...state, tableDefinitions };
    }

    case 'TABLE_DEFINITION_UPDATE_ROLLBACK': {
      const { id } = action.payload;
      const existingTable = find(propEq('id', id), state.tableDefinitions);

      if (!existingTable) return state;

      const tableIndex = findIndex(propEq('id', id), state.tableDefinitions);
      const tableDefinitions = update(
        tableIndex,
        existingTable._old || existingTable,
        state.tableDefinitions
      );

      return { ...state, tableDefinitions };
    }

    case 'TABLES_TOGGLE_POSITION_EDITING': {
      const toggle = !!action.payload;
      return { ...state, editPosition: toggle };
    }

    case 'TABLE_DEFINITION_ADD': {
      const { tableDefinition } = action.payload;
      const localTableDefinition = assoc('_local', true, tableDefinition);
      const tableDefinitions = append(localTableDefinition, state.tableDefinitions);
      return { ...state, tableDefinitions };
    }

    case 'TABLE_DEFINITION_ADD_ROLLBACK': {
      const { id } = action.payload;
      const tableDefinitions = reject(propEq('id', id), state.tableDefinitions);
      return { ...state, tableDefinitions };
    }

    case 'FOOD_COURSE_ADD': {
      const { foodCourse } = action.payload;
      const foodCourses = append(foodCourse, state.foodCourses);
      return { ...state, foodCourses };
    }

    case 'FOOD_COURSE_INJECT': {
      const { foodCoursesIds, openTableId } = action.payload;
      let foodCourses = clone(state.foodCourses);
      foodCoursesIds.forEach(foodCourseId => {
          foodCourses = append({ id: foodCourseId, openTableId }, foodCourses);
        }
      );
      foodCourses = sortBy(prop('id'))(foodCourses);
      return { ...state, foodCourses };
    }

    case 'FOOD_COURSE_DELETE': {
      const { id } = action.payload;
      const foodCourses = reject(propEq('id', id), state.foodCourses);
      return { ...state, foodCourses };
    }

    case 'FOOD_COURSE_ACTIVATE': {
      const { foodCourse } = action.payload;
      return assocPath(['active', 'foodCourse'], foodCourse, state);
    }

    case 'SUB_TABLE_ADD': {
      const { subTable } = action.payload;
      const localSubTable = assoc('_local', true, subTable);
      const subTables = append(localSubTable, state.subTables);

      return { ...state, subTables };
    }

    case 'SUB_TABLE_UPDATE_COMMIT': {
      const { id, subTable } = action.payload;
      const existingSubTable = find(propEq('id', id), state.subTables);

      if (!existingSubTable) return state;

      const mergedSubTable = dissoc('_local', { ...existingSubTable, ...subTable });

      const subTableIndex = findIndex(propEq('id', id), state.subTables);
      const subTables = update(subTableIndex, mergedSubTable, state.subTables);

      return { ...state, subTables };
    }

    case 'SUB_TABLE_ADD_COMMIT': {
      const { subTable } = action.payload;
      const subTables = append(subTable, state.subTables);

      return { ...state, subTables };
    }

    case 'SUB_TABLE_ADD_ROLLBACK': {
      const { id } = action.payload;
      const subTables = reject(propEq('id', id), state.subTables);
      return { ...state, subTables };
    }

    case 'SUB_TABLE_DELETE': {
      const { id } = action.payload;
      const existingSubTable = find(propEq('id', id), state.subTables);

      if (!existingSubTable) return state;

      const subTable = assoc('_delete', true, existingSubTable);

      const subTableIndex = findIndex(propEq('id', id), state.subTables);
      const subTables = update(subTableIndex, subTable, state.subTables);

      return { ...state, subTables };
    }

    case 'SUB_TABLE_DELETE_COMMIT': {
      const { id } = action.payload;
      const subTables = reject(propEq('id', id), state.subTables);
      return { ...state, subTables };
    }

    case 'SUB_TABLE_DELETE_ROLLBACK': {
      const { id } = action.payload;
      const existingSubTable = find(propEq('id', id), state.subTables);

      if (!existingSubTable) return state;

      const subTable = dissoc('_delete', existingSubTable);

      const subTableIndex = findIndex(propEq('id', id), state.subTables);
      const subTables = update(subTableIndex, subTable, state.subTables);

      return { ...state, subTables };
    }

    case 'SUB_TABLE_ACTIVATE': {
      const { subTableId } = action.payload;
      return assocPath(['active', 'subTableId'], subTableId, state);
    }

    case 'SUB_TABLE_DEACTIVATE': {
      return assocPath(['active', 'subTableId'], null, state);
    }

    case 'OPEN_TABLE_ADD': {
      const { openTable } = action.payload;
      const localOpenTable = assoc('_local', true, openTable);

      const openTableIndex = findIndex(propEq('name', openTable.name), state.openTables);

      const existingOpenTable = state.openTables[openTableIndex];
      const mergedOpenTable = { ...existingOpenTable, ...localOpenTable, _delete: false };

      const openTables = openTableIndex === -1
        ? append(localOpenTable, state.openTables)
        : update(openTableIndex, mergedOpenTable, state.openTables);

      return { ...state, openTables };
    }

    case 'OPEN_TABLE_UPDATE_COMMIT': {
      const { id, openTable } = action.payload;
      const existingOpenTable = find(propEq('id', id), state.openTables);

      if (!existingOpenTable) return state;

      const mergedOpenTable = dissoc('_local', { ...existingOpenTable, ...openTable });

      const openTableIndex = findIndex(propEq('id', id), state.openTables);
      const openTables = update(openTableIndex, mergedOpenTable, state.openTables);

      return { ...state, openTables };
    }

    case 'OPEN_TABLE_ADD_ROLLBACK': {
      const { id } = action.payload;

      const openTables = reject(propEq('id', id), state.openTables);

      return { ...state, openTables };
    }

    case 'OPEN_TABLE_DELETE': {
      const { id } = action.payload;
      const existingOpenTable = find(propEq('id', id), state.openTables);

      if (!existingOpenTable) return state;

      const openTable = assoc('_delete', true, existingOpenTable);

      const openTableIndex = findIndex(eqProps('id', openTable), state.openTables);
      const openTables = update(openTableIndex, openTable, state.openTables);

      return { ...state, openTables };
    }

    case 'OPEN_TABLE_DELETE_COMMIT': {
      const { id } = action.payload;
      const openTables = reject(propEq('id', id), state.openTables);
      return { ...state, openTables };
    }

    case 'OPEN_TABLE_COMPLETE_DELETE_LOCAL': {
      const { id } = action.payload;

      const { tableDefinitions, openTables, subTables } = state;

      const openTable = find(propEq('id', id), openTables);

      if (!openTable) return state;

      const filteredTableDefinitions = tableDefinitions.filter(d => d.id !== openTable.tableDefinitionId);
      const filteredOpenTables = openTables.filter(o => o.id !== id);
      const filteredSubTables = subTables.filter(o => o.openTableId !== id);

      return {
        ...state,
        tableDefinitions: filteredTableDefinitions,
        openTables: filteredOpenTables,
        subTables: filteredSubTables
      };
    }

    case 'OPEN_TABLE_DELETE_ROLLBACK': {
      const { id } = action.payload;
      const existingOpenTable = find(propEq('id', id), state.openTables);

      if (!existingOpenTable) return state;

      const openTable = dissoc('_delete', existingOpenTable);

      const openTableIndex = findIndex(eqProps('id', openTable), state.openTables);
      const openTables = update(openTableIndex, openTable, state.openTables);

      return { ...state, openTables };
    }

    case 'OPEN_TABLE_ACTIVATE': {
      const { id } = action.payload;
      return assocPath(['active', 'openTableId'], id, state);
    }

    case 'ADMIN_MENU_TOGGLE': {
      const { open } = action.payload;
      return assocPath(['isAdminMenuOpen'], open, state);
    }

    case 'OPEN_TABLE_DEACTIVATE': {
      return assocPath(['active', 'openTableId'], null, state);
    }

    case 'OPEN_TABLE_PUT_OPENED_TIME': {
      const { id, openedTime } = action.payload;
      const openTables = [...state.openTables];
      const selectedOpenTable = openTables.filter(ot => ot.id === id);
      selectedOpenTable[0].openedTime = openedTime;

      return { ...state, openTables };
    }

    case 'LOCAL_IDS_ADD': {
      const { pairs, idName } = action.payload;

      if (idName === 'tableDefinitionId') {
        const openTables = map(openTable =>
          pairs[openTable.tableDefinitionId]
            ? ({ ...openTable, tableDefinitionId: pairs[openTable.tableDefinitionId] })
            : openTable
          , state.openTables);

        let { focusOnTable } = state;
        if (pairs[focusOnTable]) {
          focusOnTable = pairs[focusOnTable];
        }

        return { ...state, openTables, focusOnTable };
      } else if (idName === 'openTableId') {
        const subTables = map(subTable =>
            pairs[subTable.openTableId]
              ? ({ ...subTable, openTableId: pairs[subTable.openTableId] })
              : subTable
          , state.subTables);

        const foodCourses = map(foodCourse =>
            pairs[foodCourse.openTableId]
              ? ({ ...foodCourse, openTableId: pairs[foodCourse.openTableId] })
              : foodCourse
          , state.foodCourses);

        let { focusOnOpenTable, active } = state;
        if (pairs[focusOnOpenTable]) {
          focusOnOpenTable = pairs[focusOnOpenTable];
        }

        if (pairs[active.openTableId]) {
          active = { ...active, openTableId: pairs[active.openTableId] };
        }

        return { ...state, subTables, foodCourses, focusOnOpenTable, active };
      } else if (idName === 'subTableId') {
        let { active } = state;
        if (pairs[active.subTableId]) {
          active = { ...active, subTableId: pairs[active.subTableId] };
        }

        return { ...state, active };
      }

      return state;
    }

    case 'TABLES_RESET': {
      return {
        ...initialState,
        openTables: state.openTables,
        subTables: state.subTables,
        foodCourses: state.foodCourses,
        tableDefinitions: state.tableDefinitions,
        sLimits: state.sLimits
      };
    }

    default:
      return state;
  }
};

export default reducer;
