// @flow
import type { KeyboardButtonsProps } from '../index';
import React from 'react';
import Box from '../../Box';
import KbButton from '../TypewriterButton';
import Icon from '../../Icon';
import theme from '../../../themes/theme';

class Buttons extends React.Component {
  props: KeyboardButtonsProps & { noEnter: boolean };

  constructor(props) {
    super(props);

    this.state = {
      shift: false,
      numerical: props.altPressed || false
    };
  }

  _pressShift = () => {
    this.setState({
      shift: !this.state.shift,
      numerical: false
    });
  };

  _pressNumerical = () => {
    this.setState({
      numerical: !this.state.numerical,
      shift: false
    });
  };

  render() {
    const {
      onPress,
      disabled,
      noEnter,
      additionalButtons = () => null
    } = this.props;
    const { shift, numerical } = this.state;

    return (
      <Box>
        <Box flexDirection="row" justifyContent="flex-end" marginTop={0.75} flexShrink={0}>
          <KbButton char={shift ? 'Q' : numerical ? '1' : 'q'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'W' : numerical ? '2' : 'w'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'E' : numerical ? '3' : 'e'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'R' : numerical ? '4' : 'r'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'T' : numerical ? '5' : 't'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'Y' : numerical ? '6' : 'y'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'U' : numerical ? '7' : 'u'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'I' : numerical ? '8' : 'i'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'O' : numerical ? '9' : 'o'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'P' : numerical ? '0' : 'p'} onPress={onPress} disabled={disabled} />
          <KbButton char="del" onPress={onPress} operator last disabled={disabled}>
            <Icon name="key-delete" scale={4} color="white" justifyContent="center" />
          </KbButton>
        </Box>
        <Box flexDirection="row" marginLeft={1.5} marginTop={0.75} justifyContent="flex-end" flexShrink={0}>
          <KbButton char={shift ? 'A' : numerical ? '-' : 'a'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'S' : numerical ? '/' : 's'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'D' : numerical ? ':' : 'd'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'F' : numerical ? ';' : 'f'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'G' : numerical ? '(' : 'g'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'H' : numerical ? ')' : 'h'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'J' : numerical ? '€' : 'j'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'K' : numerical ? '&' : 'k'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'L' : numerical ? '@' : 'l'} onPress={onPress} disabled={disabled} last={noEnter} />
          {noEnter ? <Box width={theme.typography.lineHeight(3) * 1.75 * 1.5} /> :
            <KbButton
              char="enter"
              onPress={onPress}
              operator
              last
              size={2}
              paddingTop={0.25}
              disabled={disabled}>
                <Icon name="key-enter" scale={4} color="white" justifyContent="center" />
            </KbButton>
          }
        </Box>
        <Box flexDirection="row" justifyContent="flex-end" marginTop={0.75} flexShrink={0}>
          <KbButton onPress={this._pressShift} backgroundColor={shift ? 'keyboardButton' : 'keyboardButtonHighlight'} disabled={disabled}>
            <Icon name="key-shift" scale={4} color={shift ? 'keyboardButtonHighlight' : 'white'} justifyContent="center" />
          </KbButton>
          <KbButton char={shift ? 'Z' : numerical ? '.' : 'z'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'X' : numerical ? ',' : 'x'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'C' : numerical ? '?' : 'c'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'V' : numerical ? '!' : 'v'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'B' : numerical ? '+' : 'b'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'N' : numerical ? '-' : 'n'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? 'M' : numerical ? '*' : 'm'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? '.' : numerical ? '<' : '.'} onPress={onPress} disabled={disabled} />
          <KbButton char={shift ? ',' : numerical ? '>' : ','} onPress={onPress} disabled={disabled} />
          <KbButton onPress={this._pressShift} backgroundColor={shift ? 'keyboardButton' : 'keyboardButtonHighlight'} last disabled={disabled}>
            <Icon name="key-shift" scale={4} color={shift ? 'keyboardButtonHighlight' : 'white'} justifyContent="center" />
          </KbButton>
        </Box>
        <Box flexDirection="row" justifyContent="flex-end" marginVertical={0.75} flexShrink={0}>
          <KbButton char={numerical ? 'Abc' : '.?123'} onPress={this._pressNumerical} operator size={2} disabled={disabled} />
          <KbButton char=" " onPress={onPress} flexGrow={1} disabled={disabled} />
          <KbButton char="OK" sendChar="send" onPress={onPress} primary last size={2} disabled={disabled} />
        </Box>
        {additionalButtons(this.props)}
      </Box>
    );
  }
}

export class NoEnterButtons extends React.PureComponent {
  render() {
    return <Buttons {...this.props} noEnter />;
  }
}

export default Buttons;
