// @flow
import React from 'react';
import { contains } from 'rambda';
import { connect } from 'react-redux';
import { addError } from '../../order/actions';
import type { State } from '../../types';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import paymentMessages from '../../messages/payment';

export default onRedirect => BaseComponent => compose(connect(
  (state: State) => ({
    _paymentTransactions: state.payment.paymentTransactions,
    _paymentTransactionId: state.payment.paymentTransactionId
  })
), injectIntl)(class extends React.PureComponent {
  componentWillReceiveProps({ _paymentTransactionId, _paymentTransactions }) {
    const { intl } = this.props;
    if (_paymentTransactionId && (this.props._paymentTransactions !== _paymentTransactions)) {
      if (!contains(_paymentTransactionId, _paymentTransactions)) {
        const { dispatch } = this.props;
        const error = new Error(intl.formatMessage(paymentMessages.paymentTransactionAborted));

        dispatch(addError(error));
        onRedirect(this);
      }
    }
  }

  render() {
    return <BaseComponent {...this.props} />;
  }
});
