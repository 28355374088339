// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  commonMenuCopyCancel: {
    defaultMessage: 'Kópie a storná účtov',
    id: 'common.menu.copyCancel'
  },
  commonMenuCook: {
    defaultMessage: 'Cook',
    id: 'common.menu.cook'
  },
  commonMenuKitchenMessage: {
    defaultMessage: 'Správa do kuchyne',
    id: 'common.menu.kitchenMessage'
  },
  commonMenuCashClose: {
    defaultMessage: 'Uzávierka pokladne',
    id: 'common.menu.cashClose'
  },
  commonMenuConsumptionView: {
    defaultMessage: 'Prezeranie spotreby',
    id: 'common.menu.consumptionView'
  },
  commonMenuAlcohol: {
    defaultMessage: 'Predaj alkoholu',
    id: 'common.menu.alcohol'
  },
  commonMenuFiscal: {
    defaultMessage: 'Fiškál',
    id: 'common.menu.fiscal'
  },
  commonMenuMealsCount: {
    defaultMessage: 'Počet porcií',
    id: 'common.menu.mealsCount'
  },
  commonMenuPriceList: {
    defaultMessage: 'Aktualizácia cenníka',
    id: 'common.menu.priceList'
  },
  commonMenuFullScreen: {
    defaultMessage: 'Prepnúť režim na celú obrazovku',
    id: 'common.menu.fullScreen'
  },
  commonClosuresIntermediate: {
    defaultMessage: 'Medziuzávierka',
    id: 'common.closures.intermediate'
  },
  commonClosuresNew: {
    defaultMessage: 'Nová uzávierka',
    id: 'common.closures.new'
  },
  commonClosuresInterval: {
    defaultMessage: 'Intervalová uzávierka',
    id: 'common.closures.interval'
  },
  commonClosuresSummary: {
    defaultMessage: 'Sumárna uzávierka',
    id: 'common.closures.summary'
  },
  commonClosuresSummaryMultiple: {
    defaultMessage: 'Sumárna uzávierka za viac kás',
    id: 'common.closures.summaryMultiple'
  },
  commonClosuresHistory: {
    defaultMessage: 'História uzávierok',
    id: 'common.closures.history'
  },
  commonClosuresBulkClose: {
    defaultMessage: 'Hromadné uzavretie účtov',
    id: 'common.closures.bulkClose'
  },
  commonCookService: {
    defaultMessage: 'Stoly',
    id: 'common.cook.service'
  },
  commonCookOrders: {
    defaultMessage: 'Objednávky',
    id: 'common.cook.orders'
  },
  commonCookOrdersList: {
    defaultMessage: 'Objednávky - zoznam',
    id: 'common.cook.ordersList'
  },
  commonFiscalModule: {
    defaultMessage: 'Fiškálny modul',
    id: 'common.fiscal.module'
  },
  commonFiscalConfirm: {
    defaultMessage: 'Potvrdiť výzvu',
    id: 'common.fiscal.confirm'
  },
  commonFiscalTime: {
    defaultMessage: 'Nastaviť čas',
    id: 'common.fiscal.time'
  },
  commonFiscalReset: {
    defaultMessage: 'Resetovať fiškál',
    id: 'common.fiscal.reset'
  },
  commonCashierOptions: {
    defaultMessage: 'Možnosti pokladne',
    id: 'common.cashier.options'
  },
  commonEmptyTable: {
    defaultMessage: 'Žiadne položky',
    id: 'common.table.empty'
  },
  commonLoadingTable: {
    defaultMessage: 'Načítavajú sa údaje…',
    id: 'common.table.loading'
  },
  commonTopBarClose: {
    defaultMessage: 'Zatvoriť',
    id: 'common.topBar.close'
  },
  commonApplicationServerNotAvailable: {
    defaultMessage: 'Aplikačný server je nedostupný',
    id: 'common.network.applicationServerNotAvailable'
  },
  commonApplicationServerSyncAvailable: {
    defaultMessage: 'Sú dostupné nové dáta',
    id: 'common.network.applicationSyncAvailable'
  },
  commonNoItems: {
    defaultMessage: 'Žiadne položky',
    id: 'common.noItems'
  },
  orderGuest: {
    defaultMessage: 'hosť',
    id: 'common.order.guest'
  },
  allGuests: {
    defaultMessage: 'Všetci hostia',
    id: 'common.order.allGuests'
  },
  showAll: {
    defaultMessage: 'Zobraziť všetkých',
    id: 'common.order.showAll'
  },
  addMenu: {
    defaultMessage: 'Pridať chod',
    id: 'common.order.addMenu'
  },
  confirmingItems: {
    defaultMessage: 'Potvrdzujú sa položky',
    id: 'common.order.confirmingItems'
  },
  pendingPayment: {
    defaultMessage: 'Prebieha platobná operácia',
    id: 'common.order.pendingPayment'
  },
  foodCourse: {
    defaultMessage: 'chod',
    id: 'common.foodCourse'
  },
  kitchenReminderHeader: {
    defaultMessage: 'Pripomienka do kuchyne',
    id: 'common.kitchenReminderHeader'
  },
  kitchenReminderText: {
    defaultMessage: 'Naozaj si želáte poslať pripomienku do kuchyne',
    id: 'common.kitchenReminderText'
  },
  kitchenReminderYes: {
    defaultMessage: 'Áno',
    id: 'common.yes'
  },
  kitchenReminderNo: {
    defaultMessage: 'Nie',
    id: 'common.no'
  }
});
