// @flow
import React from 'react';
import { VelocityComponent } from 'velocity-react';
import Text from '../../common/components/Text';
import { connect } from 'react-redux';
import { networkNotificationsSelector } from '../../common/network/selectors';
import {
 compose, filter, path, pathOr
} from 'rambda';
import theme from '../themes/defaultTheme';
import InactiveBgWithTextSpinner from './InactiveBgWithTextSpinner';
import type { State } from '../../common/types';
import { injectIntl } from 'react-intl';
import Box from '../../common/components/Box';

const isTerminalPay = notificationsObj => pathOr(false, 'pendingTerminalOperation.isActive', notificationsObj);

class ConnectivityIndicator extends React.PureComponent {
  render() {
    const { notificationsObj } = this.props;

    let terminalMessage;

    if (isTerminalPay(notificationsObj)) {
      terminalMessage = pathOr(undefined, 'pendingTerminalOperation.text', notificationsObj);
    }

    let notifications = filter(
      ({ isActive }, key) => isActive && (key !== 'disconnected' && key !== 'syncAvailable'), notificationsObj
);
    notifications = Object.values(notifications);

    const disconnectActive = path(['disconnected', 'isActive'], notificationsObj);
    const syncAvailableActive = path(['syncAvailable', 'isActive'], notificationsObj);

    return (
      <React.Fragment>
        <Box style={{ flexDirection: 'column' }}>
        <VelocityComponent
          duration={500}
          delay={1000}
          animation={{
            height: disconnectActive
              ? theme.typography.lineHeight() * 2
              : 0
          }}
        >
          <Text
            overflow="hidden"
            backgroundColor="error"
            color="white"
            align="center"
            justifyContent="center"
            paddingHorizontal={1}
            height={disconnectActive ? 'auto' : 0}
          >
            {path(['disconnected', 'text'], notificationsObj)}
          </Text>
        </VelocityComponent>
        <VelocityComponent
          duration={500}
          delay={1000}
          animation={{
            height: syncAvailableActive
              ? theme.typography.lineHeight() * 2
              : 0
          }}
        >
          <Text
            overflow="hidden"
            backgroundColor={theme.colors.orange}
            color={theme.colors.black}
            align="center"
            justifyContent="center"
            paddingHorizontal={1}
            height={disconnectActive ? 'auto' : 0}
          >
            {path(['syncAvailable', 'text'], notificationsObj)}
          </Text>
        </VelocityComponent>
        {notifications.length
          ? <InactiveBgWithTextSpinner text={isTerminalPay(notificationsObj) && terminalMessage ? terminalMessage : notifications[0].text} />
          : null}
        </Box>
      </React.Fragment>
    );
  }
}

export default compose(
  connect((state: State) => ({
    notificationsObj: networkNotificationsSelector(state)
  })),
  injectIntl
)(ConnectivityIndicator);
