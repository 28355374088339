// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  pay: {
    defaultMessage: 'Zaplatiť',
    id: 'tables.contextMenus.pay'
  },
  join: {
    defaultMessage: 'Spojiť podstoly',
    id: 'tables.contextMenus.join'
  },
  split: {
    defaultMessage: 'Vytvoriť podstôl',
    id: 'tables.contextMenus.split'
  },
  edit: {
    defaultMessage: 'Premenovať',
    id: 'tables.contextMenus.edit'
  },
  changePriceLevel: {
    defaultMessage: 'Zmeniť cenovú hladinu',
    id: 'tables.contextMenus.changePriceLevel'
  },
  newSubtable: {
    defaultMessage: 'Nový hosť',
    id: 'tables.contextMenus.newSubtable'
  },
  cancel: {
    defaultMessage: 'Zrušiť',
    id: 'tables.contextMenus.cancel'
  },
  close: {
    defaultMessage: 'Zavrieť stôl',
    id: 'tables.contextMenus.close'
  }
});
