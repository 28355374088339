// @flow
import React from 'react';
import titlesMessages from '../../common/app/messages/titles';
import Box from '../../common/components/Box';
import Title from '../components/Title';
import Form from './Form';

class DeviceSetupPage extends React.PureComponent {
  render() {
    return (
      <Box backgroundColor="appBg" height="100vh" alignItems="center" justifyContent="center">
        <Title message={titlesMessages.setup} />

        <Form />
      </Box>
    );
  }
}

export default DeviceSetupPage;
