// @flow
import React from 'react';
import theme from '../../common/themes/theme';
import OneLineDisplay from '../../common/components/Keyboard/typewriter/OneLineDisplay';
import OneLineAuthDisplay from '../../common/components/Keyboard/typewriter/OneLineAuthDisplay';
import createTypewriterOperations from '../../common/components/Keyboard/typewriter/operations';
import TypewriterButtons from '../../common/components/Keyboard/typewriter/Buttons';
import { setupDevice } from '../../common/device/actions';
import { equals } from 'rambda';
import Heading from '../../common/components/Heading';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import TextInput from '../../common/components/TextInput';
import Button from '../../common/components/Button';
import InactiveBg from '../components/InactiveBg';
import Popup from '../components/Popup';
import Keyboard from '../../common/components/Keyboard';
import type { State } from '../../common/types';
import { connect } from 'react-redux';
import messages from '../../common/messages/device';
import { FormattedMessage } from 'react-intl';
import { tryApiSettings } from '../../common/device/utils';
import Spinner from 'react-spinner';
import { replace } from 'connected-react-router';

class Form extends React.Component {
  voidFunction = () => {};

  constructor(props) {
    super(props);

    this._focus = this._focus.bind(this);
    this._close = this._close.bind(this);
    this._submit = this._submit.bind(this);

    this.state = {
      focus: null,
      attempt: 0,
      form: { ...props.device }
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props !== nextProps) || !equals(this.state, nextState);
  }

  _focus(field) {
    const fieldValue = field === 'machinePassword'
      ? ''
      : this.state.form[field];

    this.setState({
      focus: field,
      form: {
        ...this.state.form,
        [field]: fieldValue
      }
    });
  }

  _close() {
    this.setState({
      focus: null,
    });
  }

  _submit() {
    const { dispatch, sessionId } = this.props;

    this.setState({ waiting: true, error: false, attempt: this.state.attempt + 1 }, () => {
      const attempt = this.state.attempt;

      tryApiSettings(this.state.form, sessionId).then(() => {
        if (this.state.attempt !== attempt) return;

        dispatch(setupDevice(this.state.form));
        dispatch(replace('/'));
      }).catch(() => {
        if (this.state.attempt !== attempt) return;

        this.setState({
          error: true,
          waiting: false
        });
      });
    });
  }

  render() {
    const {
      focus,
      form: {
        apiUrl,
        machineId,
        machinePassword
      },
      error,
      waiting
    } = this.state;

    return (
      <Box width={30}>
        <Heading scale={4} color="white" align="center" marginBottom={1}><FormattedMessage {...messages.deviceFormSetup} /></Heading>

        <Text bold scale={1} marginBottom={0.25} color="white">
          <FormattedMessage {...messages.apiUrl} />
        </Text>
        <TextInput
          maxLength={1000}
          padding={1}
          scale={2}
          color="white"
          backgroundColor="lessDarkGray"
          value={apiUrl}
          marginBottom={1.5}
          onClick={(e) => {
            e.target.blur();
            this._focus('apiUrl');
          }}
          onChange={this.voidFunction}
        />

        <Text bold scale={1} marginBottom={0.25} color="white">
          <FormattedMessage {...messages.machineId} />
        </Text>
        <TextInput
          maxLength={1000}
          padding={1}
          scale={2}
          color="white"
          backgroundColor="lessDarkGray"
          value={machineId}
          marginBottom={1.5}
          onClick={(e) => {
            e.target.blur();
            this._focus('machineId');
          }}
          onChange={this.voidFunction}
        />

        <Text bold scale={1} marginBottom={0.25} color="white">
          <FormattedMessage {...messages.machinePassword} />
        </Text>
        <TextInput
          maxLength={1000}
          padding={1}
          scale={2}
          color="white"
          backgroundColor="lessDarkGray"
          value={machinePassword}
          type="password"
          marginBottom={1.5}
          onClick={(e) => {
            e.target.blur();
            this._focus('machinePassword');
          }}
          onChange={this.voidFunction}
        />

        <Button color="white" outline padding={0.75} marginBottom={1} onPress={this._submit}>
          {waiting &&
          <div className="setup-spinner"><Spinner /></div>
          }
          <Text color="white" scale={3} fontFamily="RobotoBold"><FormattedMessage {...messages.deviceFormContinue} /></Text>
        </Button>

        {error &&
        <Text color="error"><FormattedMessage {...messages.error} /></Text>
        }

        {focus &&
        <InactiveBg zIndex={10} onClose={() => {}}>
          <Popup showClose={false}>
            <Heading scale={2} color="white">
              <FormattedMessage {...messages[this.state.focus]} />
            </Heading>

            <Keyboard
              width={`${theme.typography.lineHeight(3) * 1.75 * 11
                + theme.typography.lineHeight() * 7.5}px`}
              createOperations={createTypewriterOperations}
              Buttons={TypewriterButtons}
              Display={this.state.focus === 'machinePassword' ? OneLineAuthDisplay : OneLineDisplay}
              onLastKey={key => {
                if (key === 'send') {
                  this._close();
                }
              }}
              onDisplayValue={lastDisplayValue => {
                this.setState({
                  form: {
                    ...this.state.form,
                    [this.state.focus]: lastDisplayValue
                  }
                });
              }}
              defaultValue={this.state.form[this.state.focus]}
            />
          </Popup>
        </InactiveBg>
        }
      </Box>
    );
  }
}

export default connect((state: State) => ({
  device: state.device,
  sessionId: state.device.sessionId
}))(Form);
