// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import type { State } from '../types';
import { validPaymentMediaSelector } from '../payment/selectors';
import ComposedButton from './ComposedButton';
import Box from './Box';
import { getQuickPayments } from '../order/utils';
import {findParamValue} from '../parameters/utils';

class QuickPayments extends React.PureComponent {
  render() {
    const { quickPayments, formDisabled, parameters, onSelect, onLongPress, isConnected, ...restProps } = this.props;
    const paramIsHores = findParamValue('K32.is_hores', parameters);

    return (
      <Box {...restProps}>
        {quickPayments.map(paymentMedium => (
          <Box
            key={paymentMedium.idriadok || paymentMedium.id}
            flexGrow={1}
            flexShrink={0}
            paddingHorizontal={0.25}
            flexBasis={`${100 / quickPayments.length}%`}
            maxWidth={`${100 / quickPayments.length}%`}
          >
            <ComposedButton
              disabled={formDisabled || (paymentMedium.nazev === 'N_HOTEL' && !paramIsHores) || !isConnected}
              caption={paymentMedium.name}
              backgroundColor="blue"
              shadowColor="darkBlue"
              icon="credit-card"
              onPress={() => onSelect(paymentMedium)}
              onLongPress={() => onLongPress(paymentMedium)}
            />
          </Box>
        ))}
      </Box>
    );
  }
}

export default connect(
  (state: State) => ({
    validPaymentMedia: validPaymentMediaSelector(state),
    formDisabled: state.payment.formDisabled,
    parameters: state.parameters.parameters,
    paymentTypes: state.payment.paymentTypes,
    isConnected: state.network.isConnected
  }),
  null,
  ({ validPaymentMedia, formDisabled, parameters, paymentTypes, isConnected }, _, ownProps) => (
    {
      quickPayments: getQuickPayments(parameters, paymentTypes, validPaymentMedia),
      formDisabled,
      parameters,
      isConnected,
      ...ownProps
    }
  )
)(QuickPayments);
