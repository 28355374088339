// @flow
import React from 'react';
import Title from '../../components/Title';
import Box from '../../../common/components/Box';
import MealsGrid from './MealsGrid';
import titlesMessages from '../../../common/app/messages/titles';
import TopBar from './TopBar';
import SidebarSpace from '../../components/Sidebar';
import Sidebar from './Sidebar';
import AdminNotification from '../AdminNotification';
import theme from '../../themes/defaultTheme';
import { setFilterValue } from '../../../common/order/actions';
import type { State } from '../../../common/types';
import { connect } from 'react-redux';
import PopupKeyboard from './PopupKeyboard';
import bindScreenLifecycleActions from '../../components/hoc/bindScreenLifecycleActions';
import ordersNavigatorActions from '../../../common/order/screenActions';
import { compose } from 'rambda';
import ScrollButtons from '../../order/ScrollButtons';

class MealsCountPage extends React.PureComponent {
  render() {
    const { isFilterOpened, filterValue, dispatch, showScrollButtons } = this.props;

    return (
      <Box flex={1} height="100%" backgroundColor="appBg">
        <Title message={titlesMessages.admin} />

        <TopBar />

        <Box flexDirection="row" zIndex={1} flex={1} height="calc(100% - 36px)">
          <Box flex={1} width="100%">
            <MealsGrid />

            <AdminNotification />

            {showScrollButtons &&
              <Box position="absolute" bottom={1} left={1}>
                <ScrollButtons containerId="MealsCountScrollContainer" vertical />
              </Box>}
          </Box>

          <SidebarSpace>
            <Sidebar />
          </SidebarSpace>
        </Box>

        <Box zIndex={theme.zIndex.popupLayer}>
          {isFilterOpened &&
            <PopupKeyboard
              filterValue={filterValue}
              updateFilter={value => dispatch(setFilterValue(value))}
            />}
        </Box>
      </Box>
    );
  }
}

export default compose(
  bindScreenLifecycleActions(ordersNavigatorActions),
  connect((state: State) => ({
    isFilterOpened: state.orders.isFilterOpened,
    filterValue: state.orders.filterValue,
    showScrollButtons: state.orders.active.showScrollButtons
  }))
)(MealsCountPage);
