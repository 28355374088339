// @flow
import React from 'react';
import Title from '../../../components/Title';
import Box from '../../../../common/components/Box';
import titlesMessages from '../../../../common/app/messages/titles';
import TopBar from '../../mealsCount/TopBar';
import Confirmation from './Confirmation';
import AdminNotification from '../../AdminNotification';

export default class extends React.PureComponent {
  render() {
    return (
      <Box flex={1} height="100%" backgroundColor="appBg">
        <Title message={titlesMessages.admin} />

        <TopBar />

        <Box flex={1} height="calc(100% - 36px)">
          <Confirmation />
        </Box>

        <AdminNotification position="absolute" bottom={0} left={0} right={0} />
      </Box>
    );
  }
}

