// @flow
import type { Device } from '../index';

const protocolFactory = (device: Device) => {
  const ProtocolPrototype = function (device: Device) {
    const chr = String.fromCharCode;
    const weightRegexp = new RegExp('[S][-F ]([0-9 ]{2}[.][0-9]{3})kg');

    let isWaitingForResponse = false;
    let currentString = '';
    let responseBuffer = '';

    this.prepareWeight = () => {
      if (!isWaitingForResponse) {
        isWaitingForResponse = true;
        device.writeLine(chr(0x05));
      }
    };

    this.asciiToHex = (str) => {
      const arr1 = [];
      for (let n = 0, l = str.length; n < l; n++) {
        const hex = Number(str.charCodeAt(n)).toString(16);
        arr1.push(hex);
        arr1.push(' ');
      }
      return arr1.join('');
    };

    this.setResponse = (buffer: Buffer) => {
      const res = buffer.toString('ascii');

      if (res[0] === chr(0x06)) {
        device.writeLine(chr(0x11));
      }

      const matches = weightRegexp.exec(responseBuffer);

      if (matches && matches.length === 2) {
        currentString = responseBuffer;
        responseBuffer = '';

        isWaitingForResponse = false;
        return;
      }

      device.writeLine(chr(0x05));
      currentString = '';

      if (responseBuffer.length > 50) {
        responseBuffer = '';
      } else {
        responseBuffer += res;
      }
    };

    this.getLastWeight = () => {
      if (!currentString) return null;

      const matches = weightRegexp.exec(currentString);

      if (!matches) return null;

      const weight = parseFloat(matches[1]);

      return +weight;
    };
  };

  return new ProtocolPrototype(device);
};

export default protocolFactory;
