// @flow
/* eslint-disable react/jsx-one-expression-per-line, react/jsx-closing-tag-location, object-curly-newline */
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../common/components/Box';
import Closure from '../../components/Closure';
import theme from '../../themes/defaultTheme';
import { monospaceFamily } from '../../../common/themes/typography';
import Button from '../../../common/components/Button';
import api from '../../../common/lib/api';
import { addAdminError, toggleAdminNotificationPopup } from '../../../common/admin/general/actions';
import { BottomDecoration } from '../../../common/components/PaymentSheet';
import InactiveBg from '../../components/InactiveBg';
import Popup from '../../components/Popup';
import Text from '../../../common/components/Text';
import Spinner from '../../../common/components/Spinner';
import PrintersList from '../../../common/components/PrintersList';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/closures';
import { compose } from 'rambda';

type ClosurePrintPreviewProps = {
  closurePreview: string,
  dateFrom: any,
  dateTo: any,
  closureBased: boolean,
  multipleCashiers: boolean,
  selectedClosures: any
}

class ClosurePrintPreview extends React.PureComponent {
  props: ClosurePrintPreviewProps;

  state = {
    selectPrinter: false,
    printing: false
  };

  print = printer => {
    const { dispatch, dateFrom, dateTo, closureBased, multipleCashiers, selectedClosures, intl } = this.props;

    this.setState({ printing: true });

    if (multipleCashiers) {
      return api.cashiers.multiClosure(selectedClosures, { print: true, printer })
        .then(() => {
          dispatch(toggleAdminNotificationPopup(intl.formatMessage(messages.closurePrintPreviewSend)));
        }).catch(e => {
          dispatch(addAdminError(e));
        }).then(() => {
          this.setState({ printing: false, selectPrinter: false });
        });
    }

    const dateFromFormatted = dateFrom.format(closureBased ? 'YYYY-MM-DD' : 'YYYY-MM-DD[T]HH:mm:ss');
    const dateToFormatted = dateTo.format(closureBased ? 'YYYY-MM-DD' : 'YYYY-MM-DD[T]HH:mm:ss');

    return api.closure.getClosures('interval', {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
      closureBased,
      printer,
      print: true
    }).then(() => {
      dispatch(toggleAdminNotificationPopup(intl.formatMessage(messages.closurePrintPreviewSend)));
    }).catch(e => {
      dispatch(addAdminError(e));
    }).then(() => {
      this.setState({ printing: false, selectPrinter: false });
    });
  };

  selectPrinter = () => {
    this.setState({ selectPrinter: true });
  };

  closePrinterPopup = () => {
    this.setState({ selectPrinter: false });
  };

  render() {
    const { selectPrinter, printing } = this.state;
    const { closurePreview, intl } = this.props;

    return (
      <Box flex={1} marginHorizontal="auto" marginVertical={1} height="calc(100% - 36px)">
        <Closure
          isLoading={false}
          data={closurePreview}
          backgroundColor="white"
          paddingVertical={2}
          height="100%"
          overflow="auto"
        />

        <Button
          position="absolute"
          top={-1}
          left={`calc(50% + ${theme.typography.lineHeight(0, monospaceFamily) * 12}px)`}
          backgroundColor="teal"
          outline
          color="white"
          marginTop={1}
          marginLeft={1}
          paddingVertical={0.75}
          paddingHorizontal={2}
          onPress={() => this.print()}
          style={{ whiteSpace: 'nowrap' }}
        >{intl.formatMessage(messages.closurePrintPreviewPrint)}</Button>

        <Button
          position="absolute"
          top={2.5}
          left={`calc(50% + ${theme.typography.lineHeight(0, monospaceFamily) * 12}px)`}
          backgroundColor="teal"
          outline
          color="white"
          marginTop={1}
          marginLeft={1}
          paddingVertical={0.75}
          paddingHorizontal={2}
          onPress={this.selectPrinter}
          style={{ whiteSpace: 'nowrap' }}
        >{intl.formatMessage(messages.closurePrintPreviewChoosePrinter)}</Button>

        {BottomDecoration}

        {selectPrinter
        && <InactiveBg onClose={this.closePrinterPopup}>
            <Popup onClose={this.closePrinterPopup}>
              <Box maxHeight={25} width={40}>
                {printing ? (
                  <React.Fragment>
                    <Text key={1} marginBottom={2} scale={3} align="center" color="white">{intl.formatMessage(messages.closurePrintPreviewPrinting)}</Text>
                    <Box paddingTop={3}>
                      <Spinner color="white" />
                    </Box>
                  </React.Fragment>
                ) : (
                  <PrintersList onSelect={this.print} />
                )}
              </Box>
            </Popup>
          </InactiveBg>
        }
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(ClosurePrintPreview);
