// @flow
import type { KeyboardDisplayProps } from '../index';
import React from 'react';
import { injectIntl } from 'react-intl';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../../../browser/components/Icon';
import messages from '../messages/buttons';
import TextInput from '../../TextInput';
import Text from '../../Text';

class Display extends React.PureComponent<KeyboardDisplayProps> {
  formatValue = (value) => {
    const { intl } = this.props;

    const replaced = (`${value}` || '0').replace(/\./g, intl.formatMessage(messages.dot));
    return replaced;
  };

  _onCancelPress = () => {
    const { onPress } = this.props;

    onPress('cancel');

    if (this.input) {
      this.input.focus();
    }
  };

  render() {
    const { value, onPress, unit } = this.props;

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor="teal"
        borderStyle="solid"
        borderColor="teal"
        style={theme => ({
          borderRightWidth: theme.baseSize,
          height: theme.typography.lineHeight(5) * 1.75
        })}
      >
        <Button
          onPress={this._onCancelPress}
          style={theme => ({
            paddingLeft: theme.baseSize,
            paddingRight: theme.baseSize
          })}
        >
          <Icon name="delete" color="textInverse" justifyContent="center" scale={2}
                style={theme => ({ ':active': { color: theme.colors.textInverseActive } })} />
        </Button>
        <TextInput
          nativeRef={c => { this.input = c; }}
          autoFocus
          backgroundColor="teal"
          flexGrow={1}
          bold
          color="white"
          scale={5}
          justifyContent="center"
          height="100%"
          overflow="auto"
          style={theme => ({
            whiteSpace: 'nowrap',
            paddingRight: theme.baseSize * 1.5 * (unit ? 0.25 : 0),
          })}
          value={this.formatValue(value)}
          align="right"
          onChange={() => {
          }}
          onKeyPress={({ key, charCode }) => {
            onPress(charCode === 13 ? 'ok' : key);
          }}
          onKeyDown={({ keyCode }) => {
            if (keyCode === 8) { // backspace
              onPress('backspace');
            }
          }}
        />
        {unit &&
          <Text
            flexDirection="row"
            alignItems="baseline"
            color="textInverse"
            style={theme => ({
              lineHeight: theme.typography.lineHeight(5) * 2
            })}
          >
            {unit}
          </Text>}
      </Box>
    );
  }
}

export default injectIntl(Display);
