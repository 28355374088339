// @flow
import type { CategoryContent, MenuItemsPage, MenuItemContent, Item, Category } from '../types';
import { prop, map, pipe, omit, find, propEq, filter, reduce, uniqWith } from 'rambda';
import { groupBy, mapObjIndexed, maxBy } from 'ramda';

export const buildMenuItemsPages = (categoryContents: CategoryContent[]): MenuItemsPage[] => {
  const groupByContaingCategory = groupBy(prop('containingCategoryId'));

  const clean = mapObjIndexed((group, key) => {
    const markItemAndCategory = map((categoryContent: CategoryContent) => ({
      ...categoryContent,
      id: categoryContent.subCategoryId || categoryContent.itemId,
      isItem: !!categoryContent.itemId
    }));

    const omitUnusedProperties = map(omit(['containingCategoryId', 'itemId', 'subCategoryId']));

    const makeItemsUnique = uniqWith(
      (x, y) => (x.id === y.id) && (x.isItem === y.isItem)
    );

    const cleanedContent = pipe(
      markItemAndCategory,
      omitUnusedProperties,
      makeItemsUnique
    )(group);

    return (
      { id: +key, content: cleanedContent }
    );
  });

  const toList = Object.values;

  const pages = pipe(
    groupByContaingCategory,
    clean,
    toList
  )(categoryContents);

  return pages;
};

export const connectMenuItemsWithItems =
(menuItems: MenuItemContent[], items: Item[], categories: Category[]) => {
  const findItem = (id) => find(propEq('id', id), items);
  const findCategory = (id) => find(propEq('id', id), categories);

  const connected = map((menuItem: MenuItemContent) => {
    const link = menuItem.isItem ? findItem(menuItem.id) : findCategory(menuItem.id);

    return ({
      ...menuItem,
      link
    });
  }, menuItems);

  return filter(menuItem => !!menuItem.link, connected);
};

export const itemsToMenuItemContent = (items: Item[]): MenuItemContent[] => {
  const menuItemContent = map(item => ({ id: item.id, isItem: true }), items);

  return menuItemContent;
};

export const findMaxRow = (menuItems: MenuItemContent) =>
  reduce(maxBy(({ geometry: { row } }) => row), { geometry: { row: 0 } }, menuItems).geometry.row;
