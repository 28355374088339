// @flow
import React from 'react';
import { connect } from 'react-redux';
import Popup from '../components/Popup';
import Keyboard from '../../common/components/Keyboard';
import PopupWeightFormButtons from '../../common/components/Keyboard/popupWeightForm/Buttons';
import PopupWeightFormDisplay from '../../common/components/Keyboard/popupWeightForm/Display';
import createFormOperations from '../../common/components/Keyboard/popupWeightForm/operations';
import Heading from '../../common/components/Heading';
import InactiveBg from '../components/InactiveBg';
import { setNumberOfCopies } from '../../common/payment/actions';
import {compose} from 'rambda';
import type {State} from '../../common/types';
import {injectIntl} from 'react-intl';
import messages from '../../common/messages/payment';

const popupNumericDisplayWithUnit = (unit, forceValue, DisplayComponent) => props =>
  <DisplayComponent {...props} value={forceValue} unit={unit} />;

const popupNumericButtonsWithoutDot = (hasDot, ButtonsComponent) => props =>
  <ButtonsComponent {...props} showDot={hasDot} />;

class NumberOfCopiesPopup extends React.Component {
  state = {
    lastDisplayValue: '',
  };

  onSubmit = key => {
    if (key !== 'ok') return;

    const { dispatch, onClose } = this.props;
    const { lastDisplayValue } = this.state;

    dispatch(setNumberOfCopies(parseInt(lastDisplayValue, 10)));
    onClose();
  };

  render() {
    const { onClose, intl } = this.props;
    const { lastDisplayValue } = this.state;

    return (
      <InactiveBg onClose={onClose}>
        <Popup showClose onClose={onClose} width={20}>
          <Heading scale={4} color="white" style={{ marginTop: 0, marginBottom: 10 }}>
            {intl.formatMessage(messages.paymentNumberOfCopiesHeader)}
          </Heading>

          <Keyboard
            style={theme => ({ width: theme.typography.lineHeight(5) * 1.75 * 3 })}
            createOperations={createFormOperations}
            Buttons={
              popupNumericButtonsWithoutDot(
                false,
                PopupWeightFormButtons
              )
            }
            Display={
              popupNumericDisplayWithUnit(
                'ks',
                `${lastDisplayValue === '' ? 0 : lastDisplayValue}`,
                PopupWeightFormDisplay
              )
            }
            onLastKey={this.onSubmit}
            onDisplayValue={val => {
              this.setState({ lastDisplayValue: val });
            }}
            defaultValue="0"
            marginTop={1}
            marginHorizontal="auto"
          />
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(NumberOfCopiesPopup);
