// @flow
import React from 'react';
import OneLineDisplay from './OneLineDisplay';

const formatValue = value => {
  const replaced = value.replace(/./g, '*');
  return replaced;
};

class OneLineAuthDisplay extends React.PureComponent {
  render() {
    return <OneLineDisplay {...this.props} value={formatValue(this.props.value)} />;
  }
}

export default OneLineAuthDisplay;
