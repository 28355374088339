// @flow
import React from 'react';
import titlesMessages from '../../../common/app/messages/titles';
import Title from '../../components/Title';
import Box from '../../../common/components/Box';
import TopBar from '../TopBar';
import PriceBox from './PriceBox';

class PriceListPage extends React.PureComponent {
  render() {
    return (
      <Box flex={1} height="100%" backgroundColor="appBg">
        <Title message={titlesMessages.admin} />

        <TopBar />

        <Box zIndex={1} flex={1} height="calc(100% - 36px)">
          <PriceBox />
        </Box>
      </Box>
    );
  }
}

export default PriceListPage;
