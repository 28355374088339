// @flow
import type { Operations } from '../index';
import escapeRegexp from 'escape-string-regexp';

const operations: Operations = (saveChanges) => ({
  sendKey(store, key) {
    if (/^[A-Za-z0-9.,? ]$/.test(escapeRegexp(key))) {
      store.setNewInput(`${store.curInput()}${key}`);
      store.setLastKey(`${key}`);

      saveChanges(store);
    } else if (key === 'del') {
      store.setNewInput(`${store.curInput().substring(0, store.curInput().length - 1)}`);

      saveChanges(store);
    } else if (key === 'enter') {
      store.setNewInput(`${store.curInput()}\n`);

      saveChanges(store);
    } else if (key === 'cancel') {
      store.setNewInput('');

      saveChanges(store);
    } else if (key === 'send') {
      store.setLastKey(key);
      saveChanges(store);
    }
  }
});

export default operations;
