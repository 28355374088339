// @flow
import React from 'react';
import Box from './Box';
import Spinner from 'react-spinner';
import { FormattedMessage } from 'react-intl';
import messages from '../auth/messages/form';
import Text from './Text';

// TODO - make fancier loading screen
const LoadingScreen = ({ backgroundColor, width, height, ...restProps }) => (
    <Box
      zIndex={10000}
      justifyContent="center"
      position="absolute"
      top={0}
      left={0}
      width={width || '100vw'}
      height={height || '100vh'}
      backgroundColor={backgroundColor || 'appBg'}
      {...restProps}
    >
      <Box
        justifyContent="center"
        position="absolute"
        left="50%"
        style={{
          transform: 'translateX(-50%)',
          height: '100vh'
        }}
      >
        <Box>
          <Spinner />
        </Box>
        <Box height={1} />
        <Text color="white" scale={5} align="center">
          <FormattedMessage {...messages.loading} />
        </Text>
      </Box>
    </Box>
);

export default LoadingScreen;
