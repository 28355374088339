import React from 'react';
import Text from 'common/components/Text';
import { version } from '../../../package.json';

export default () => (
  <Text
    position="absolute"
    bottom={0}
    right={0}
    color="gray"
  >
    {`v${version}`}
  </Text>
);
