// @flow
import type { Action, Id } from '../../types';

export const toggleHistoryIsLoading = (is: boolean): Action => ({
  type: 'ADMIN_CLOSURES_HISTORY_IS_LOADING',
  payload: { is }
});

export const setHistoryPreviewData = (data: string): Action => ({
  type: 'ADMIN_CLOSURES_HISTORY_SET_PREVIEW_DATA',
  payload: { data }
});

export const toggleHistoryClosureId = (closureId: Id): Action => ({
  type: 'ADMIN_CLOSURES_HISTORY_TOGGLE',
  payload: {
    id: closureId,
  }
});

export const toggleJournalIsLoading = (is: boolean): Action => ({
  type: 'ADMIN_CLOSURES_JOURNAL_IS_LOADING',
  payload: { is }
});

export const setJournalPreviewData = (data: string): Action => ({
  type: 'ADMIN_CLOSURES_JOURNAL_SET_PREVIEW_DATA',
  payload: { data }
});

export const toggleJournalId = (journalId: Id): Action => ({
  type: 'ADMIN_CLOSURES_JOURNAL_TOGGLE',
  payload: {
    id: journalId,
  }
});
