// @flow
import React from 'react';
import { VelocityComponent } from 'velocity-react';
import Text from '../../common/components/Text';
import Popup from './Popup';
import InactiveBg from './InactiveBg';
import Spinner from 'react-spinner';

const InactiveBgWithTextSpinner = ({ text }) => (
  <InactiveBg backgroundColor="transparent"> {/* disable all clicks immediately */}
    <VelocityComponent delay={500}>
      <InactiveBg>
        <Popup showClose={false} style={{ paddingTop: 0 }}>
          <div className="notification-spinner"><Spinner /></div>
          <Text color="white" marginTop={1} scale={1}>{text}</Text>
        </Popup>
      </InactiveBg>
    </VelocityComponent>
  </InactiveBg>
);

export default InactiveBgWithTextSpinner;
