// @flow
import type { Action, DeviceState } from '../types';
import { REHYDRATE } from 'redux-persist/constants';
import uuid from 'uuid';

const initialState = {
  apiUrl: 'http://ext.alto.sk:7001',
  machineId: '01',
  machinePassword: 'wer777',
  sessionId: null
};

const reducer = (state: DeviceState = initialState, action: Action): DeviceState => {
  switch (action.type) {
    case REHYDRATE: {
      const {
        device: {
          apiUrl,
          machineId,
          machinePassword,
          sessionId = uuid.v4()
        } = {}
      } = action.payload;

      return { ...state, apiUrl, machineId, machinePassword, sessionId };
    }

    case 'DEVICE_SETUP': {
      const { apiUrl, machineId, machinePassword } = action.payload;
      return { ...state, apiUrl, machineId, machinePassword };
    }

    default:
      return state;
  }
};

export default reducer;
