// @flow
import type { Action } from '../types';

export const signInDone = ({ employeeId, token, lastDataUpdate, defaultPrinter }, usingWaiterKey: boolean = false): Action => ({
  type: 'SIGN_IN_DONE',
  payload: {
    user: {
      id: employeeId,
      token
    },
    lastDataUpdate,
    defaultPrinter,
    usingWaiterKey
  }
});

export const signInFail = (error: Error): Action => ({
  type: 'SIGN_IN_FAIL',
  payload: { error }
});

export const signIn = (password: string, usingWaiterKey: boolean = false): Action => ({
  type: 'SIGN_IN',
  payload: {
    password,
    usingWaiterKey
  }
});

export const signOut = (): Action => ({
  type: 'SIGN_OUT',
});

export const resetState = () => ({
  type: 'AUTH_RESET'
});

export const waiterKeyInitDone = (process): Action => ({
  type: 'WAITER_KEY_INIT_DONE',
  payload: { process }
});

export const waiterKeyProcessClosed = () => ({
  type: 'WAITER_KEY_PROCESS_CLOSED'
});

export const waiterKeyKeepAlive = () => ({
  type: 'WAITER_KEY_KEEP_ALIVE'
});
