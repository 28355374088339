// @flow
import type { Employee, Id, ServiceArea, State, User } from '../../common/types';
import React from 'react';
import { compose, filter } from 'rambda';
import { connect } from 'react-redux';
import Box from '../../common/components/Box';
import Title from '../components/Title';
import TopBar from '../../common/components/TopBar';
import Time from '../../common/components/Time';
import Text from '../../common/components/Text';
import SidebarSpace from '../components/Sidebar';
import Sidebar from './Sidebar';
import TablesGrid from './TablesGrid';
import TablesList from './TablesList';
import Icon from '../components/Icon';
import titlesMessages from '../../common/app/messages/titles';
import EditTable from './EditTable';
import Button from '../../common/components/Button';
import { isServiceAreaVirtual } from '../../common/serviceAreas/utils';
import { signOut } from '../../common/auth/actions';
import signOutOnInactivity from '../../common/components/hoc/signOutOnInactivity';
import { activityRegister } from '../../common/activity/actions';
import { findUserName } from '../../common/employees/utils';
import AdminMenu from '../admin/AdminMenu';
import { clearError, clearOrderLinesDirtyList } from '../../common/order/actions';
import Notification from '../components/Notification';
import { displayEllipsis } from '../themes/utils';
import { emptyArray } from '../../common/lib/utils';
import bindScreenLifecycleActions from '../components/hoc/bindScreenLifecycleActions';
import serviceAreasNavigatorActions from '../../common/serviceAreas/screenActions';
import AutoUpdates from '../autoUpdates/AutoUpdates';
import NewCardPopup from '../payment/NewCardPopup';
import theme from '../../common/themes/theme';
import { findParamValue } from '../../common/parameters/utils';

type ServiceAreasPageProps = {
  user: User,
  activeAreaId: boolean,
  areas: ServiceArea[],
  editTableId: Id,
  firstSyncDone: boolean,
  employees: Employee[],
}

class ServiceAreasPage extends React.PureComponent {

  clearError = () => {
    const { dispatch } = this.props;
    dispatch(clearError());
  };

  // TODO - just temporary solution
  // fixme - because when you came from loyalman to paymant - payment trans. conflict occured
  _tempRemovePaymentConflict = errors => filter(er => er.message !== 'Orderline conflicts in payment', errors);

  render() {
    const {
      user,
      employees,
      areas,
      activeAreaId,
      editTableId,
      showTablesList,
      isAdminMenuOpen,
      dispatch,
      isNewCardPopupOpen,
      parameters
    }: ServiceAreasPageProps = this.props;
    let { error } = this.props;

    const userName = user ? findUserName(user.id, employees) : '';

    error = this._tempRemovePaymentConflict(error);

    const enableLoyalman = findParamValue('K32.enable_loyalman', parameters);

    return (
      <Box flex={1} height="100%">
        <Title message={titlesMessages.serviceAreas} />

        {editTableId && <EditTable />}

        <TopBar>
          <Box paddingLeft={1} justifyContent="center">
            <AutoUpdates />
          </Box>
          <Box position="absolute" justifyContent="center" height="100%" left="50%" style={{ transform: 'translateX(-50%)' }}>
            <Time bold color="white" />
          </Box>
          <Button
            paddingRight={1}
            flexDirection="row"
            onPress={() => {
              dispatch(signOut());
            }}
            alignItems="center"
            maxWidth="33%"
          >
            <Icon name="user" color="white" bold marginRight={0.25} scale={1} />
            <Text bold color="white" style={displayEllipsis}>{userName}</Text>
          </Button>
        </TopBar>

        {isAdminMenuOpen && (
          <AdminMenu hideLoyalman={!enableLoyalman} />
        )}

        {isNewCardPopupOpen &&
          <NewCardPopup />
        }

        <Box flexDirection="row" flex={1} height="100%">
          {(showTablesList || isServiceAreaVirtual(activeAreaId, areas))
            ? <TablesList />
            : <TablesGrid />
          }

          <SidebarSpace
            minWidth={theme.thinSidebar.minWidth}
            maxWidth={theme.thinSidebar.maxWidth}
            width={theme.thinSidebar.width}
            overflow="hidden"
            height="100%"
            display="block"
          >
            <Sidebar />
          </SidebarSpace>
        </Box>

        {error.length ? (
          <Box zIndex={100}>
            <Notification
              showClose
              text={error}
              mode="bottom"
              color="white"
              backgroundColor="red"
              onClose={this.clearError}
            />
          </Box>
        ) : null}
      </Box>
    );
  }
}

export default compose(
  bindScreenLifecycleActions(serviceAreasNavigatorActions),
  connect((state: State) => ({
    employees: state.employees.employees,
    user: state.auth.user,
    activeAreaId: state.serviceAreas.activeAreaId,
    areas: state.serviceAreas.serviceAreas,
    showTablesList: state.serviceAreas.showTablesList,
    editTableId: state.tables.editTable,
    isAdminMenuOpen: state.tables.isAdminMenuOpen,
    error: state.orders.error || emptyArray,
    isNewCardPopupOpen: state.payment.isNewCardPopupOpen,
    parameters: state.parameters.parameters,
    outbox: state.offline.outbox
  })),
  signOutOnInactivity,
)(ServiceAreasPage);
