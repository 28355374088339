// @flow
import type { State } from '../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import RenameTable from './RenameTable';
import ChangePriceLevel from './ChangePriceLevel';

class EditTable extends React.PureComponent {
  render() {
    const { editTableDetail } = this.props;

    switch (editTableDetail) {
      case 'name':
        return <RenameTable />;
      case 'priceLevel':
        return <ChangePriceLevel />;
      default:
        return null;
    }
  }
}

export default connect((state: State) => ({
  editTableDetail: state.tables.editTableDetail,
}))(EditTable);
