// @flow
import type { State } from '../../common/types';
import React from 'react';
import { Route, withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { isDeviceConfigured } from '../../common/device/utils';
import { connect } from 'react-redux';
import { compose } from 'rambda';

class DeviceConfigurationGuard extends React.PureComponent {
  constructor(props) {
    super(props);
    this._check = this._check.bind(this);
    this._redirectTo = { pathname: '/setup' };
  }

  _check() {
    const { children, device } = this.props;

    return isDeviceConfigured(device)
      ? children
      : <Redirect to={this._redirectTo} />;
  }

  render() {
    return <Route render={this._check} />;
  }
}

export default compose(
  withRouter,
  connect((state: State) => ({
    device: state.device
  })),
)(DeviceConfigurationGuard);
