// @flow
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../../common/components/Box';
import Button from '../../../../common/components/Button';
import theme from '../../../themes/defaultTheme';
import Closure from '../../../components/Closure';
import { monospaceFamily } from '../../../../common/themes/typography';
import { BottomDecoration } from '../../../../common/components/PaymentSheet';
import {
  addAdminError,
  toggleAdminNotificationPopup
} from '../../../../common/admin/general/actions';
import api from '../../../../common/lib/api';
import InactiveBg from '../../../components/InactiveBg';
import Popup from '../../../components/Popup';
import PrintersList from '../../../../common/components/PrintersList';
import { replace } from 'connected-react-router';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/closures';

class ClosePreview extends React.PureComponent {
  state = {
    isLoading: true,
    data: '',
    selectPrinter: false
  };

  componentDidMount() {
    const { dispatch } = this.props;

    return api.closure.getClosures('intermediate')
      .then(({ body: { result: { preview: { data } } } }) => {
        this.setState({ data, isLoading: false });
      }).catch(e => {
        dispatch(addAdminError(e));
        this.setState({ isLoading: false });
      });
  }

  selectPrinter = () => {
    this.setState({ selectPrinter: true });
  };

  closePrinterPopup = () => {
    this.setState({ selectPrinter: false });
  };

  print = printer => {
    const { dispatch, intl } = this.props;

    this.setState({ isLoading: true, selectPrinter: false });

    return api.closure.getClosures('intermediate', { printer, print: true })
      .then(() => {
        dispatch([
          toggleAdminNotificationPopup(intl.formatMessage(messages.closureToPrintInter)),
          replace('/serviceAreas')
        ]);
      }).catch(e => {
        this.setState({ isLoading: false, selectPrinter: false });
        dispatch(addAdminError(e));
      });
  };

  render() {
    const { intl } = this.props;
    const { isLoading, data, selectPrinter } = this.state;

    return (
      <Box flex={1} marginHorizontal="auto" marginVertical={1} height="calc(100% - 36px)">
        <Closure
          isLoading={isLoading}
          data={data}
          backgroundColor="white"
          paddingVertical={2}
          overflow="auto"
          height="100%"
        />
        {BottomDecoration}
        {!isLoading &&
          <React.Fragment>
            <Button
              position="absolute"
              top={0}
              left={`calc(50% + ${theme.typography.lineHeight(0, monospaceFamily) * 12}px)`}
              backgroundColor="teal"
              outline
              color="white"
              marginTop={1}
              marginLeft={1}
              paddingVertical={0.75}
              paddingHorizontal={2}
              onPress={() => this.print()}
              style={{ whiteSpace: 'nowrap' }}
            >
              {intl.formatMessage(messages.confirmAndPrint)}
            </Button>

            <Button
              position="absolute"
              top={3.5}
              left={`calc(50% + ${theme.typography.lineHeight(0, monospaceFamily) * 12}px)`}
              backgroundColor="teal"
              outline
              color="white"
              marginTop={1}
              marginLeft={1}
              paddingVertical={0.75}
              paddingHorizontal={2}
              onPress={this.selectPrinter}
              style={{ whiteSpace: 'nowrap' }}
            >{intl.formatMessage(messages.confirmAndChoosePrinter)}</Button>
          </React.Fragment>
        }

        {selectPrinter &&
        <InactiveBg onClose={this.closePrinterPopup}>
          <Popup onClose={this.closePrinterPopup}>
            <Box maxHeight={25} width={40}>
              <PrintersList onSelect={this.print} />
            </Box>
          </Popup>
        </InactiveBg>
        }
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(ClosePreview);
