// @flow
import type { Action, CategoriesState } from '../types';
import { isReactNative } from '../app/detectPlatform';
import { clearFsStore } from '../lib/utils';

const initialState = {
  categories: [],
};

const reducer = (state: CategoriesState = initialState, action: Action): CategoriesState => {
  switch (action.type) {
    case 'REHYDRATE_FS_COMMIT': {
      return {
        ...state,
        categories: action.payload.categories
      };
    }
    case 'SYNC_SERVER_DATA_COMMIT': {
      const { payload: { Category: categories } } = action;

      if (isReactNative) {
        const Store = require('react-native-fs-store').default;

        clearFsStore('categories').then(() => {
          new Store('categories').setItem('categories', categories);
        });
      }

      return { categories };
    }

    default:
      return state;
  }
};

export default reducer;
