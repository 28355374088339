// @flow
import React from 'react';
import Text from '../../common/components/Text';
import { Textfit } from 'react-textfit';
import theme from '../themes/defaultTheme';

type props = {
  width: number,
  height: number,
  numberOfLines?: number,
  minTextSize: number, // px
  maxTextSize: number // px
}

class TextAdjustableSize extends React.PureComponent<props> {
  render() {
    const {
      minTextSize,
      maxTextSize,
      numberOfLines = 0,
      lineHeight = 1.25,
      children,
      className = '',
      ...rest
    } = this.props;

    if (!minTextSize && !maxTextSize) {
      return <Text {...this.props} />;
    }

    return (
      <Text {...rest}>
        <Textfit
          mode="multi"
          min={minTextSize}
          max={maxTextSize}
          className={`textfit no-${numberOfLines} ${className}`}
          style={{
            height: rest.height * theme.typography.lineHeight(),
            lineHeight
          }}>{children}</Textfit>
      </Text>
    );
  }
}

export default TextAdjustableSize;
