// @flow
import type { Action, ActivityState } from '../types';

const initialState = {
  lastActivity: null,
  allowSignOutOnInactivity: true
};

const reducer = (state: ActivityState = initialState, action: Action): ActivityState => {
  switch (action.type) {
    case 'ACTIVITY_REGISTER': {
      const { payload: { now } } = action;
      return { ...state, lastActivity: now };
    }
    case 'TOGGLE_ALLOW_SIGN_OUT_ON_INACTIVITY': {
      const { payload: { allow } } = action;
      return { ...state, allowSignOutOnInactivity: allow };
    }

    default:
      return state;
  }
};

export default reducer;
