// @flow
import type { State } from '../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, equals } from 'rambda';
import { AutoSizer, Column,
  defaultTableCellRenderer, defaultTableHeaderRenderer } from 'react-virtualized';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import TableSearch from '../TableSearch';
import {
  clearSelectedCategories, setCategories, setFilters, setItems,
  toggleSelectedCategoryId
} from '../../../common/admin/usage/actions';
import SimpleItemsTable, { withLoadingState, withSelectableRows } from '../Table';
import moment from 'moment';
import { loadItems, loadUsage } from './fetchHelpers';
import debounce from 'debounce';
import Button from '../../../common/components/Button';
import checkPermission from '../../../common/permissions/service';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/usage';

const inlineBlock = {
  display: 'inline-block',
  lineHeight: 'normal'
};

const Table = compose(
  withSelectableRows('druh'),
  withLoadingState
)(SimpleItemsTable);

const dateRangeOptions = {
  defaultFrom: moment().subtract(1, 'days'),
  defaultTo: moment().subtract(1, 'days'),
};

class CategoriesList extends React.Component {

  state = {
    dateFrom: dateRangeOptions.defaultFrom.format(),
    dateTo: dateRangeOptions.defaultTo.format(),
    filter: '',
    showActualUsage: checkPermission('actual.usage.read'),
  };

  componentWillMount() {
    this.loadAll();
  }

  componentWillReceiveProps(nextProps) {
    if (!equals(nextProps.selectedCategoriesId, this.props.selectedCategoriesId)) {
      const state = this.state;
      const { dispatch } = nextProps;
      loadItems(nextProps, state).then(items => {
        // fixme this state===state is a mess
        if (this.state === state) {
          dispatch([
            setItems(items),
            setFilters(state)
          ]);
        }
      });
    }
  }

  loadAll = () => {
    const { dispatch } = this.props;
    let state = this.state;

    loadUsage(this.props, state).then(usage => {
      if (this.state === state) {
        dispatch(setCategories(usage));
        state = this.state;

        loadItems(this.props, state).then(items => {
          if (this.state === state) {
            dispatch([
              setItems(items),
              setFilters(state)
            ]);
          }
        });
      }
    });
  };

  setDateRange = (from, to) => {
    const { dispatch } = this.props;
    dispatch(clearSelectedCategories());

    this.setState({ dateFrom: from.format(), dateTo: to.format() }, () => {
      this.loadAll();
    });
  };

  debouncedFilter = debounce(this.loadAll, 200);

  setFilterValue = (filter, key) => {
    if (key === 'send') return;

    this.setState({ filter }, this.debouncedFilter);
  };

  onRowClick = ({ rowData: { druh } }) => {
    const { dispatch } = this.props;
    dispatch(toggleSelectedCategoryId(druh));
  };

  _showActualUsage = (show) => {
    const { dispatch } = this.props;
    dispatch(clearSelectedCategories());

    this.setState({ showActualUsage: show }, () => {
      this.loadAll();
    });
  };

  render() {
    const { categories, selectedCategoriesId, isLoadingCategories, intl } = this.props;
    const isActualReadable = checkPermission('actual.usage.read');
    const isHistoryReadable = checkPermission('usage.read');
    const { showActualUsage } = this.state;

    if (!isActualReadable && !isHistoryReadable) return;

    const switchUnclosedReceipts = (
      <Box marginLeft={3} flexDirection="row" alignItems="flex-start">
        {isActualReadable &&
          <Button
            onPress={() => this._showActualUsage(true)}
          >
            <Text color={showActualUsage ? 'white' : 'lightGray'} bold={showActualUsage}>
              {intl.formatMessage(messages.usageActual)}
            </Text>
          </Button>}
        {isActualReadable && isHistoryReadable &&
          <Text color="white" marginLeft={0.5} marginRight={0.5}>/</Text>}
        {isHistoryReadable &&
          <Button
            onPress={() => this._showActualUsage(false)}
          >
            <Text color={showActualUsage ? 'lightGray' : 'white'} bold={!showActualUsage}>
              {intl.formatMessage(messages.usageRange)}
            </Text>
          </Button>}
      </Box>
    );

    return (
      <Box
        width="100%"
        flex={1}
        backgroundColor="appBg"
        marginTop={1}
        paddingLeft={1}
        overflow="auto"
      >
        <TableSearch
          enableDateRange={!showActualUsage}
          enableFilter
          enableCalendar={false}
          customFilter={switchUnclosedReceipts}
          dateRangeOptions={dateRangeOptions}
          onDateRangeChange={this.setDateRange}
          onFilterChange={this.setFilterValue}
        />

        <Box flexBasis="100%" backgroundColor="white" marginBottom={1} maxHeight="100vh" height="9999px">
          <AutoSizer>
            {({ height, width }) => (
              <Table
                width={width}
                height={height}
                onRowClick={this.onRowClick}
                headerClassName="offset-first"
                className="light"
                items={categories}
                selectedItems={selectedCategoriesId}
                isLoading={isLoadingCategories}
              >
                <Column
                  label={intl.formatMessage(messages.usageTableCategory)}
                  dataKey="druh"
                  width={width * 0.7}
                  cellRenderer={props =>
                    <Text paddingLeft={1} color="inherit">
                      {defaultTableCellRenderer(props)}
                    </Text>
                  }
                />
                <Column
                  label={intl.formatMessage(messages.usageSoldAmount)}
                  dataKey="sumMnozstvi"
                  width={width * 0.3}
                  cellRenderer={props =>
                    <Text align="right" paddingRight={1.5} color="inherit">
                      {defaultTableCellRenderer(props)}
                    </Text>
                  }
                  headerRenderer={props =>
                    <Text align="right" paddingRight={1.5} style={inlineBlock} width="100%">
                      {defaultTableHeaderRenderer(props)}
                    </Text>
                  }
                />
              </Table>
            )}
          </AutoSizer>
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    categories: state.admin.usage.categories,
    selectedCategoriesId: state.admin.usage.selectedCategoriesId,
    isLoadingCategories: state.admin.usage.isLoadingCategories
  })),
  injectIntl
)(CategoriesList);
