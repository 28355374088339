// @flow
import type { Reducer, Action } from '../../types';
import { propEq, find, map, filter, concat, path } from 'rambda';
import { assocPath } from 'ramda';
import moment from 'moment';

/*
  Actions matrix:

  client         server fresh      server old      action
  -------------------------------------------------------
  exists         exists            -               overwrite
  exists         -                 exists          nothing
  exists         not exists        -               delete
  exists         -                 not exists      nothing
  not exists     exists            -               add
  not exists     -                 exists          add
  not exists     not exists        -               nothing      (invalid)
  not exists     -                 not exists      nothing      (invalid)
*/

type ReadonlyStrategyOptions = {
  idKey: string,
  clientTimestampKey: string,
  serverTimestampKey: string,
  serverGlobalTimestamp: any,
};

export const mergeWithReadonlyStrategy =
(clientItems, serverItems, config: ReadonlyStrategyOptions = {}) => {
  const {
    idKey = 'id',
    clientTimestampKey = 'updatedAt',
    serverTimestampKey = 'updatedAt',
    serverGlobalTimestamp = Date.now()
  } = config;

  const newActionPayloadItemsFromServer = map(serverItem => {
    const foundExistingClientItem = find(propEq(idKey, serverItem[idKey]), clientItems);

    if (foundExistingClientItem) {
      const serverTime = typeof serverItem[serverTimestampKey] === 'number'
        ? serverItem[serverTimestampKey]
        : Date.parse(serverItem[serverTimestampKey]);

      const clientTime = typeof foundExistingClientItem[clientTimestampKey] === 'number'
        ? foundExistingClientItem[clientTimestampKey]
        : Date.parse(foundExistingClientItem[clientTimestampKey]);

      const serverIsNewer = (serverTime | 0) >= (clientTime | 0);

      return serverIsNewer ? serverItem : foundExistingClientItem;
    }

    return serverItem;
  }, serverItems);

  const onlyInClient = filter(
    item => !find(propEq(idKey, item[idKey]), serverItems),
    clientItems);

  const newActionPayloadItemsFromClient = filter(clientItem => {
    const serverTime = typeof serverGlobalTimestamp === 'number'
      ? serverGlobalTimestamp
      : Date.parse(serverGlobalTimestamp);

    const clientTime = typeof clientItem[clientTimestampKey] === 'number'
      ? clientItem[clientTimestampKey]
      : Date.parse(clientItem[clientTimestampKey]);

    const serverIsNewer = (serverTime | 0) >= (clientTime | 0);

    return !serverIsNewer;
  }, onlyInClient);

  const merged = concat(
    newActionPayloadItemsFromServer,
    newActionPayloadItemsFromClient
  );

  return merged;
};

export const readonlyReducerStrategy = (reducer: Reducer, config: Object) =>
  (state: Object, action: Action) => {
    if (!state || !action.type) return reducer(state, action);

    if (action.type.endsWith('_SYNC') || action.type === 'SYNC_SERVER_DATA_COMMIT') {
      let {
        payloadItemsKey = 'items',
        stateItemsKey = 'items',
      } = config;

      const {
        serverTimestampKey = 'updatedAt'
      } = config;

      payloadItemsKey = Array.isArray(payloadItemsKey) ? payloadItemsKey : [payloadItemsKey];
      stateItemsKey = Array.isArray(stateItemsKey) ? stateItemsKey : [stateItemsKey];

      const stateItems = path(stateItemsKey, state);
      const payloadItems = path(payloadItemsKey, action.payload);
      const payloads = Array.isArray(payloadItems)
        ? payloadItems
        : [payloadItems];

      const merged = mergeWithReadonlyStrategy(stateItems, payloads, {
        ...config,
        serverGlobalTimestamp: action.meta ? moment(action.meta[serverTimestampKey]) : moment()
      });

      const payload = assocPath(payloadItemsKey, merged, action.payload);

      return reducer(state, { ...action, payload });
    }

    return reducer(state, action);
  };
