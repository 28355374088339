// @flow
import type { Action, IntlState } from '../types';
import loadMessages from './loadMessages';

const defaultLocale = process.env.DEFAULT_LOCALE || 'sk';
const messages = loadMessages();

const initialState = {
  currentLocale: defaultLocale,
  defaultLocale,
  locales: Object.keys(messages),
  messages: messages[defaultLocale]
};

const reducer = (
  state: IntlState = initialState,
  action?: Action,
): IntlState => {
  // Because it's called from the createInitialState.
  if (!action) return state;

  switch (action.type) {
    case 'SET_CURRENT_LOCALE': {
      const { locale } = action.payload;
      return { ...state, currentLocale: locale, messages: messages[locale] };
    }
    case 'SET_INTL': {
      const { intl } = action.payload;
      return { ...state, useIntl: intl };
    }

    default:
      return state;

  }
};

export default reducer;
