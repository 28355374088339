// @flow
import React from 'react';
import MenuItemCore from '../../../common/components/MenuItemCore';
import Button from '../../../common/components/Button';
import { activateCategory } from '../../../common/menuItems/actions';
import { connect } from 'react-redux';
import ChangeAvailability from './ChangeAvailability';
import api from '../../../common/lib/api';
import { addAdminError } from '../../../common/admin/general/actions';
import { clearItemAvailabilityLocalDiff, resetItemAvailability } from '../../../common/items/actions';
import moment from 'moment';
import checkPermission from '../../../common/permissions/service';

class MenuItem extends React.PureComponent {
  state = {
    changeAvailability: false
  };

  _onPress = () => {
    const { isItem, dispatch, id } = this.props;

    if (isItem) {
      this.setState({ changeAvailability: true });
    } else {
      dispatch(activateCategory(id));
    }
  };

  _changeAvailability = newAvailability => {
    const { id, dispatch } = this.props;

    if (newAvailability !== Infinity) {
      newAvailability = +newAvailability;
    }

    api.usage.resetUsageItems(id, newAvailability === Infinity ? -1 : newAvailability).then(() => {
      dispatch([
        resetItemAvailability(id, newAvailability, moment().format()),
        clearItemAvailabilityLocalDiff(id)
      ]);
      this.setState({ changeAvailability: false });
    }).catch(e => {
      dispatch(addAdminError(e));
    });
  };

  render() {
    const { availability, isWeighted } = this.props;
    const { changeAvailability } = this.state;

    const button = (
      <Button
        height="100%"
        key={1}
        {...(checkPermission('availabilityhistory.change')
          ? { onPress: this._onPress } : {}
        )}
      >
        <MenuItemCore {...this.props} />
      </Button>
    );

    if (changeAvailability) {
      return [
        button,
        <ChangeAvailability
          key={2}
          availability={availability}
          unit={isWeighted ? 'g' : 'ks'}
          onCancel={() => this.setState({ changeAvailability: false })}
          onSubmit={this._changeAvailability}
        />
      ];
    }

    return button;
  }
}

export default connect()(MenuItem);
