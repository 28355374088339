// @flow
import React from 'react';
import InactiveBg from '../components/InactiveBg';
import Popup from '../components/Popup';
import Text from '../../common/components/Text';
import Box from '../../common/components/Box';
import { connect } from 'react-redux';
import { compose, map, pathOr } from 'rambda';
import { isEmpty } from 'ramda';
import ComposedButton from '../../common/components/ComposedButton';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import { toggleNewCardPopup, updatePaymentTransaction, toggleNewCardPopupFirstStep } from '../../common/payment/actions';
import Heading from '../../common/components/Heading';
import PopupTextEditor from './PopupTextEditor';
import { displayEllipsis } from '../themes/utils';
import { injectIntl } from 'react-intl';
import messages from '../../common/payment/messages/page';
import type { State } from '../../common/types';
import { emptyArray } from '../../common/lib/utils';
import Divider from '../../common/components/Divider';
import Radio from '../../common/components/Radio';
import withApolloProvider from '../../common/loyalman/hoc/withApolloProvider';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import api from '../../common/lib/api';
import { processReceivedPaymentTransactionData } from '../../common/payment/utils';
import Spinner from '../../common/components/Spinner';
import DatePicker from '../components/DatePicker';
import paymentMessages from '../../common/messages/payment';

const labels = intl => ({
  name: intl.formatMessage(paymentMessages.paymentNewCardPopupName),
  surname: intl.formatMessage(paymentMessages.paymentNewCardPopupSurname),
  email: intl.formatMessage(paymentMessages.paymentNewCardPopupEmail),
  phone: intl.formatMessage(paymentMessages.paymentNewCardPopupPhone),
  adress: intl.formatMessage(paymentMessages.paymentNewCardPopupAddress),
  work: intl.formatMessage(paymentMessages.paymentNewCardPopupWork),
});

const overflow = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

const newUserQuery = gql`
    mutation NewUserMutation(
        $record: String
        $name: String!,
        $surname: String!,
        $email: String!,
        $phone: String!,
        $adress: String!,
        $work: String!,
        $cardCode: String!,
        $holderName: String!,
        $holderSurname: String!,
        $holderEmail: String!,
    ){
        user(
            data: {
                record: $record
                username: $email
                status: ACTIVE
                name: $name
                surname: $surname
                email: $email
                phoneNumber: $phone
                address: $adress
                work: $work
                cardHolders: {
                    card: $cardCode
                    holder: {
                        name: $holderName
                        surname: $holderSurname
                        email: $holderEmail
                    }
                }
            }) {
            user{
                id: record
                name
                username
            }
        }
    }
`;

const checkCardCode = gql`
  query($code: String!) {
    cards(code: $code) {
      edges {
        node {
          id: record
          code
          status
          discountPackages {
            edges {
              node {
                id: record
                name
              }
            }
          }
        }
      }
    }
  }
`;

const getDiscountPackages = gql`
  query {
    discountPackages(status: "active", orderBy: ["name"]) {
      edges {
        node {
          name
          id: record
        }
      }
    }
  }
`;

const createCardQuery = gql`
  mutation($data: CardInput!) {
    card(data: $data) {
      card {
        id: record
      }
    }
  }
`;

class NewCardPopup extends React.PureComponent {

  initialCardCodeCheck = {
    cardCode: null,
    isCardRegistered: false,
    isCardNonexistent: false,
    isExistingCard: false,
    isInactiveCard: false
  };

  constructor(props) {
    super(props);
    const { isNewCardPopupOpen } = props;
    const isLoyalmanUser = typeof isNewCardPopupOpen === 'object';

    this.state = {
      formData: {
        record: isLoyalmanUser ? pathOr('', 'id', isNewCardPopupOpen) : '',
        name: isLoyalmanUser ? pathOr('', 'name', isNewCardPopupOpen) : '',
        surname: isLoyalmanUser ? pathOr('', 'surname', isNewCardPopupOpen) : '',
        email: isLoyalmanUser ? pathOr('', 'email', isNewCardPopupOpen) : '',
        phone: '',
        adress: '',
        work: '',
        cardCode: ''
      },
      cardCodeCheck: this.initialCardCodeCheck,
      showTextEditor: false,
      newUserStep: false,
      newCardStep: false,
      availablePackagesLoaded: false,
      availablePackages: [],
      selectedPackages: [],
      expiresAt: null,
      registerSeparately: false,
      isLoyalmanUserSelected: isLoyalmanUser
    };
  }

  componentWillUpdate(nextProps) {
    const { isNewCardPopupOpen: isNextNewCardPopupOpen } = nextProps;
    const { isNewCardPopupOpen } = this.props;
    let { formData } = this.state;

    if (isNewCardPopupOpen !== isNextNewCardPopupOpen) {
      formData = { ...formData, cardCode: isNextNewCardPopupOpen };
      this.setState({ formData });
    }
  }

  _areFormDataIncomplete = () => {
    const { formData } = this.state;
    const { intl } = this.props;
    let incomplete = false;

    map(
      labelKey => {
        if (isEmpty(formData[labelKey])) {
          incomplete = true;
        }
      },
      Object.keys(labels(intl))
    );

    return incomplete;
  };

  hideTextEditor = () => {
    this.setState({ showTextEditor: false });
  };

  changeTextField = field => value => {
    let { formData } = this.state;
    formData = { ...formData, [field]: value };
    this.setState({ formData, showTextEditor: false });
  };

  resetTextField = field => {
    let { formData } = this.state;
    formData = { ...formData, [field]: '' };
    this.setState({ formData, showTextEditor: false });
  };

  showTextEditor = field => {
    this.setState({ showTextEditor: field });
  };

  closePopup = () => {
    const { dispatch } = this.props;

    dispatch(toggleNewCardPopup(null));
  };

  submitForm = data => {
    const { dispatch, paymentTransactionId } = this.props;
    const { formData: { cardCode } } = this.state;

    api.payment.addCardDiscount(paymentTransactionId, cardCode)
      .then(({ body }) => {
        dispatch(updatePaymentTransaction(processReceivedPaymentTransactionData(body)));
      })
      .catch(e => {
        console.log('New Card select (after new user registration) - some catch took place: ', e);
      });

    this.closePopup();
  };

  submitCardForm = () => {
    this.setState({
      newUserStep: false,
      newCardStep: false,
      cardCodeCheck: this.initialCardCodeCheck
    });
  };

  goForwardCard = () => {
    const { dispatch } = this.props;

    this.setState({ newCardStep: true, selectedPackages: [], expiresAt: null });

    dispatch(toggleNewCardPopupFirstStep(false));

  };

  goForwardUser = () => {
    const { dispatch } = this.props;

    this.setState({ newUserStep: true });

    dispatch(toggleNewCardPopupFirstStep(false));
  };

  goBack = () => {
    const { dispatch } = this.props;

    this.setState({ newUserStep: false, newCardStep: false });

    dispatch(toggleNewCardPopupFirstStep(true));
  };

  storeAvailablePackages = (packages) => {
    this.setState({
      availablePackagesLoaded: true,
      availablePackages: packages,
      selectedPackages: [],
      expiresAt: null
    });
  };

  InputField = (labelKey, formData, labels) => (
    <Box flexDirection="column" overflow="hidden" color="white" marginBottom={0.5} key={labelKey}>
      <Text color="white" overflow="hidden" paddingRight={0.5} justifyContent="center">
        <span style={overflow}>{labels[labelKey]}</span>
      </Text>

      <Box flexBasis="100%" overflow="hidden" paddingTop={0.15}>
        <Button
          outline
          noActiveStyle
          color="black"
          backgroundColor="white"
          height={2.5}
          justifyContent="center"
          alignItems="center"
          onPress={() => this.showTextEditor(labelKey)}
        >
          <Text
            maxWidth="100%"
            style={displayEllipsis}
            margin={0.5}
            marginRight={2}
          >
            {`${formData[labelKey]}`}
          </Text>
        </Button>
        <Button
          position="absolute"
          top={0}
          bottom={0}
          right={0}
          marginHorizontal={0.75}
          alignItems="center"
          onPress={() => this.resetTextField(labelKey)}
        >
          <Icon color="black" name="delete" scale={2} />
        </Button>
      </Box>
    </Box>
  );

  FirstOptions = (intl, registerSeparately, cardCode, cardCodeCheck, isLoyalmanUserSelected) => (
    <Box>
        <Box>
          {/* <Button */}
          {/*  disabled */}
          {/*  backgroundColor="appBg" */}
          {/*  outline */}
          {/*  color="white" */}
          {/*  paddingVertical={0.75} */}
          {/*  paddingHorizontal={2} */}
          {/*  onPress={() => this.setState({ registerSeparately: !registerSeparately })} */}
          {/*  flexShrink={0} */}
          {/* > */}
          {/*  <Radio size={1} color="white" left={0.75} position="absolute" backgroundColor="appBg" checked={registerSeparately} /> */}
          {/*  <Text color="white" bold> */}
          {/*    {intl.formatMessage(messages.registerSeparately)} */}
          {/*  </Text> */}
          {/* </Button> */}
        {!isLoyalmanUserSelected &&
          <Button
            disabled={!cardCodeCheck.isCardRegistered}
            backgroundColor="appBg"
            outline
            color="white"
            marginTop={0.5}
            marginBottom={0.75}
            paddingVertical={0.75}
            paddingHorizontal={2}
            onPress={this.goForwardUser}
            flexShrink={0}
          >

            <Radio size={1} color="white" left={0.75} position="absolute" backgroundColor="appBg" />
            <Text color="white" bold>
              {intl.formatMessage(messages.registerNewUser)}
            </Text>
          </Button>
        }
          <Button
            disabled={!cardCodeCheck.isCardNonexistent}
            backgroundColor="appBg"
            outline
            color="white"
            paddingVertical={0.75}
            paddingHorizontal={2}
            onPress={this.goForwardCard}
            flexShrink={0}
          >
            <Radio size={1} color="white" left={0.75} position="absolute" backgroundColor="appBg" />
            <Text color="white" bold>
              {intl.formatMessage(messages.createNewCard)}
            </Text>
          </Button>

          <Divider />
        </Box>

      <Text color="white" bold marginTop={0.25} paddingRight={0.5} justifyContent="center" style={displayEllipsis}>
        {intl.formatMessage(messages.cardCode)}
      </Text>
      <Button
        noActiveStyle
        color="gray"
        backgroundColor="darkGray"
        height={2.5}
        marginTop={0.5}
        justifyContent="center"
        alignItems="center"
      >
        <Text
          bold
          color="gray"
          maxWidth="100%"
          margin={0.5}
          marginRight={2}
          style={displayEllipsis}
        >
          {isEmpty(cardCode)
            ? intl.formatMessage(messages.touchTheCardReader)
            : cardCodeCheck.cardCode === cardCode // so query is called only once for the same card, not on every rerender
              ? cardCodeCheck.isExistingCard
                ? cardCodeCheck.isInactiveCard
                  ? cardCodeCheck.cardCode
                  : <Text bold color="red">{intl.formatMessage(messages.cardNotInactive)}</Text>
                : <Text bold color="red">{intl.formatMessage(messages.cardNotRegistered)}</Text>
              : <Query query={checkCardCode} variables={{ code: cardCode }}>
                  {({ loading, error, data }) => {
                    if (loading) return 'loading...';

                    if (error) return null; // TODO: handle the error?

                    let isExistingCard = false; // checkIfCardExists(cardCode, data);
                    let isInactiveCard = false; // checkIfCardIsInactive(cardCode, data);

                    if (data.cards.edges.length) {
                      isExistingCard = true;
                      isInactiveCard = data.cards.edges[0].node.status === 'UNRESOLVED';
                    }

                    this.setState({
                      cardCodeCheck: {
                        cardCode,
                        isCardRegistered: isExistingCard ? isInactiveCard : false,
                        isCardNonexistent: !isExistingCard,
                        isExistingCard,
                        isInactiveCard
                      }
                    });

                    return <Box/>;
                  }}
                </Query>}
        </Text>
      </Button>

      <Button
        noActiveStyle
        backgroundColor="popupBg"
        height={2.5}
        marginTop={0.5}
        justifyContent="center"
        alignItems="center"
        onPress={() => this.showTextEditor('cardCode')}
      >
        <Box
          size={2}
          overflow="hidden"
          backgroundColor="popupBg"
          width={1.5}
          height={1.5}
          alignItems="center"
          justifyContent="center"
          borderWidth={2}
          borderStyle="solid"
          borderColor="white"
          style={() => ({ borderRadius: '50%' })}
        >
          <Icon name={'notes'} size={1} color="white" justifyContent="center" />
        </Box>
        <Text
          maxWidth="100%"
          color="white"
          style={displayEllipsis}
          margin={0.5}
          marginRight={2}
        >
          {intl.formatMessage(messages.enterCardManualy)}
        </Text>
      </Button>
    </Box>
  );

  UserSubmitButton = (submitFn, caption, newUserStep, isLoyalmanUserSelected) => (
    <Box
      marginTop={1}
      flexShrink={0}
      width="100%"
      paddingHorizontal={0}
    >
      <ComposedButton
        disabled={!isLoyalmanUserSelected && (!newUserStep || this._areFormDataIncomplete())}
        icon="printer"
        caption={caption}
        backgroundColor="blue"
        shadowColor="darkBlue"
        onPress={submitFn}
      />
    </Box>);

  CardSubmitButton = (submitFn, caption, newCardStep) => (
    <Box
      marginTop={1}
      flexShrink={0}
      width="100%"
      paddingHorizontal={0}
    >
      <ComposedButton
        disabled={!newCardStep}
        icon="printer"
        caption={caption}
        backgroundColor="blue"
        shadowColor="darkBlue"
        onPress={submitFn}
      />
    </Box>);

  CheckboxForm = (items, selectedItems, toggleItemSelection) => (
    <Box overflow="auto" height="50vh" paddingHorizontal="0.5em">
      {
        items.map(({ id, name }) => (
          <Button
            key={id}
            backgroundColor="appBg"
            outline
            color="white"
            marginTop={0.5}
            marginBottom={0.75}
            paddingVertical={0.75}
            paddingHorizontal={2}
            onPress={() => toggleItemSelection(id)}
            flexShrink={0}
          >
            <Radio size={1} color="white" left={0.75} position="absolute" backgroundColor="appBg" checked={selectedItems.indexOf(id) >= 0} />
            <Text color="white" bold>
              {name}
            </Text>
          </Button>
        ))
      }
    </Box>
  );

  togglePackageSelection = (id) => {
    const { selectedPackages } = this.state;

    const index = selectedPackages.indexOf(id);

    if (index !== -1) {
      this.setState({ selectedPackages: selectedPackages.filter(e => e !== id) });
    } else {
      this.setState({ selectedPackages: selectedPackages.concat([id]) });
    }
  };

  setExpiresAt = (date) => {
    this.setState({ expiresAt: date });
  };

  render() {
    const { intl } = this.props;
    const {
      showTextEditor,
      formData,
      cardCodeCheck,
      newUserStep,
      newCardStep,
      availablePackagesLoaded,
      availablePackages,
      selectedPackages,
      expiresAt,
      isLoyalmanUserSelected,
      registerSeparately
    } = this.state;

    return (
      <InactiveBg onClose={this.closePopup}>
        <Popup onClose={this.closePopup} onBack={newUserStep || newCardStep ? this.goBack : null}>
          <Box flexDirection="row" width={18} justifyContent="space-between">
            <Box flex={1} maxWidth="100%">
              {!isLoyalmanUserSelected
                && newCardStep
                    ? <Heading bold marginBottom={0.75} scale={3} color="white">{`${intl.formatMessage(messages.newCardPackages)} (${formData.cardCode})`}</Heading>
                    : <Heading bold marginBottom={0.75} scale={3} color="white">{intl.formatMessage(messages.newCustomer)}</Heading>}

              {newUserStep
                && Object.keys(labels(intl)).map(labelKey => this.InputField(labelKey, formData, labels(intl)))
              }

              {newCardStep
                && (availablePackagesLoaded // so we dont have to call query on every rerender
                  ? this.CheckboxForm(availablePackages, selectedPackages, this.togglePackageSelection)
                  : <Query query={getDiscountPackages}>
                      {({ loading, error, data }) => {
                        if (loading) return <Box paddingTop={10}><Spinner/></Box>;

                        if (error) return null; // TODO: handle the error?

                        const packages = data.discountPackages.edges.map(({ node: { id, name } }) => ({ id, name }));

                        this.storeAvailablePackages(packages);
                        return <Box />;
                      }}
                    </Query>)}

              {newCardStep && (
                <Box>
                  <Divider />
                  <Heading bold marginBottom={0.75} scale={3} color="white">{intl.formatMessage(messages.cardExpiration)}</Heading>
                  <DatePicker onDateChange={this.setExpiresAt} showToday={false} />
                </Box>
              )}

              {(!newUserStep && !newCardStep)
                && this.FirstOptions(intl, registerSeparately, formData.cardCode, cardCodeCheck, isLoyalmanUserSelected)}

              <Divider />

              {newCardStep
                ? <Mutation
                    mutation={createCardQuery}
                    variables={
                      {
                        data: {
                          status: 'UNRESOLVED',
                          discountPackages: selectedPackages,
                          expiresAt: expiresAt ? expiresAt.format('YYYY-MM-DD') : null,
                          code: [formData.cardCode],
                          record: undefined
                        }
                      }
                    }
                    onCompleted={ data => {
                      this.submitCardForm();
                    }}>
                    {(submitFn, { error, loading }) => {
                      if (loading) {
                        return (
                          this.CardSubmitButton(
                            submitFn,
                            intl.formatMessage(paymentMessages.paymentCreatingCard),
                            newCardStep
                          ));
                      }
                      if (error) {
                        return (
                          this.CardSubmitButton(
                            submitFn,
                            intl.formatMessage(paymentMessages.paymentServerError),
                            newCardStep
                          ));
                      }

                      return this.CardSubmitButton(
                        submitFn,
                        intl.formatMessage(messages.createNewCard),
                        newCardStep
                      );
                    }}
                  </Mutation>
                : <Mutation
                    mutation={newUserQuery}
                    variables={
                      isLoyalmanUserSelected
                        ? {
                          ...formData,
                          name: '',
                          surname: '',
                          email: '',
                          holderName: formData.name,
                          holderSurname: formData.surname,
                          holderEmail: formData.email
                        }
                        : {
                          ...formData,
                          holderName: formData.name,
                          holderSurname: formData.surname,
                          holderEmail: formData.email
                        }
                    }
                    onCompleted={data => {
                      this.submitForm(data);
                    }}>
                    {(submitFn, { error, loading }) => {
                      if (loading) {
                        return (
                          this.UserSubmitButton(
                            submitFn,
                            intl.formatMessage(paymentMessages.paymentCreatingUser),
                            newUserStep
                          ));
                      }
                      if (error) {
                        return (
                          this.UserSubmitButton(
                            submitFn,
                            intl.formatMessage(paymentMessages.paymentServerError),
                            newUserStep
                          ));
                      }

                      return this.UserSubmitButton(
                        submitFn,
                        isLoyalmanUserSelected
                          ? intl.formatMessage(messages.assignToUser)
                          : intl.formatMessage(messages.createNewUser),
                        newUserStep,
                        isLoyalmanUserSelected
                      );
                    }}
                  </Mutation>}
            </Box>
          </Box>
        </Popup>

        {showTextEditor
          && (
            <PopupTextEditor
              title={labels(intl)[showTextEditor]}
              onClose={this.hideTextEditor}
              onSubmit={this.changeTextField(showTextEditor)}
              value={`${formData[showTextEditor]}`}
            />
          )}
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    user: state.auth.user,
    error: state.orders.error || emptyArray,
    isNewCardPopupOpen: state.payment.isNewCardPopupOpen,
    paymentTransactionId: state.payment.paymentTransactionId,
    loyalmanConnection: state.loyalman.general,
    loyalmanSelectedUser: state.loyalman.users.active
  })),
  withApolloProvider,
  injectIntl
)(NewCardPopup);
