// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl, apiEndpointWithGetParameters } from './index';
import type { Id } from '../../types';
const W3CWebSocket = require('websocket').w3cwebsocket;
// for orderKeeper
export const paymentOperationsName = 'payment';

export const openPayTransaction = (
  selectedOrderLines: number[]
): Promise =>
  fetch(apiEndpoint('v1/pay'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        orderlines: selectedOrderLines
      }
    }
  });

export const openReceiptsPayTransaction = (
  receiptId: number,
  closureId: number
): Promise =>
  fetch(apiEndpoint('v1/pay'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        bill: receiptId,
        closure: closureId
      }
    }
  });

export const closePayTransaction = (
  paymentTransactionId: string
): Promise =>
  fetch(apiEndpoint(`v1/pay/${paymentTransactionId || ''}`), {
    method: 'DELETE',
    baseURL: getApiUrl()
  });

export const addCustomDiscount = (
  paymentTransactionId: string,
  type: string,
  amount: number,
  description: string
): Promise =>
  fetch(apiEndpoint('v1/pay/discount'), {
    method: 'PUT',
    baseURL: getApiUrl(),
    body: {
      params: {
        payment_id: paymentTransactionId,
        type,
        amount,
        description
      }
    }
  });

export const addPredefinedDiscount = (
  paymentTransactionId: string,
  predefinedDiscountId: number
): Promise =>
  fetch(apiEndpoint('v1/pay/discount'), {
    method: 'PUT',
    baseURL: getApiUrl(),
    body: {
      params: {
        payment_id: paymentTransactionId,
        predefined_discount_id: predefinedDiscountId
      }
    }
  });

export const deleteDiscount = (
  paymentTransactionId: string,
  operationId: string
): Promise =>
  fetch(apiEndpoint(`v1/pay/${paymentTransactionId || ''}/discount/${operationId || ''}`), {
    method: 'DELETE',
    baseURL: getApiUrl()
  });

export const addCardDiscount = (
  paymentTransactionId: string,
  loyalmanCard: string
): Promise =>
  fetch(apiEndpoint('v1/pay/discount'), {
    method: 'PUT',
    baseURL: getApiUrl(),
    body: {
      params: {
        payment_id: paymentTransactionId,
        loyalman_card: loyalmanCard
      }
    }
  });

export const addPredefinedItemsDiscount = (
  paymentTransactionId: string,
  loyalmanCard: string,
  loyalmanProductWithdraw: Array,
  loyalmanProductPurchase: Array
): Promise =>
  fetch(apiEndpoint('v1/pay/discount'), {
    method: 'PUT',
    baseURL: getApiUrl(),
    body: {
      params: {
        payment_id: paymentTransactionId,
        loyalman_card: loyalmanCard,
        loyalman_product_withdraw: loyalmanProductWithdraw,
        loyalman_product_purchase: loyalmanProductPurchase
      }
    }
  });

export const addPaymentRecord = (
  paymentTransactionId: Id,
  paymentMediaId: Id,
  amount: string,
  additionalData = {},
  wallet: string,
  tip: string
): Promise =>
  fetch(apiEndpoint('v1/pay/record'), {
    method: 'PUT',
    baseURL: getApiUrl(),
    body: {
      params: {
        payment_id: paymentTransactionId,
        payment_media_id: paymentMediaId,
        amount,
        additional_data: additionalData,
        wallet_id: wallet,
        tip
      }
    }
  });

export const deletePaymentRecord = (
  paymentTransactionId: string,
  paymentRecordId: string
): Promise =>
  fetch(apiEndpoint(`v1/pay/${paymentTransactionId || ''}/record/${paymentRecordId || ''}`), {
    method: 'DELETE',
    baseURL: getApiUrl()
  });

export const editPaymentRecord = (
  paymentTransactionId: Id,
  paymentRecordId: Id,
  amount: string,
  tip: string,
  wallet: string
): Promise =>
  fetch(apiEndpoint(`v1/pay/record/${paymentRecordId}`), {
    method: 'PUT',
    baseURL: getApiUrl(),
    body: {
      params: {
        payment_id: paymentTransactionId,
        amount,
        tip,
        wallet_id: wallet
      }
    }
  });

// TODO - it would be great to put those GET parameters (printer & copies) as body params.
//  It is done like this, because backend prepared it so (do not know why).
export const finishPayment = (
  paymentTransactionId: string,
  selectedPrinter: string = '',
  noOfCopies: number = ''
): Promise => {
  const additionalParameters = `${(selectedPrinter !== '' || noOfCopies !== '') ? '?' : ''}${selectedPrinter ? `printer=${selectedPrinter}` : ''}${(selectedPrinter !== '' && noOfCopies !== '') ? '&' : ''}${noOfCopies ? `copies=${noOfCopies}` : ''}`;

  return fetch(apiEndpointWithGetParameters(`v1/pay/${paymentTransactionId ? `${paymentTransactionId}/` : ''}${additionalParameters}`), {
    method: 'PUT',
    baseURL: getApiUrl()
  });
};

export const printPrereceipt = (
  paymentTransactionId: string,
  selectedPrinter: string = ''
): Promise => {
  const additionalParameters = `${(selectedPrinter !== '') ? `?printer=${selectedPrinter}` : ''}`;
  return fetch(`${apiEndpoint(`v1/pay/preview/${paymentTransactionId ? `${paymentTransactionId}/` : ''}`)}${additionalParameters}`, {
    method: 'PATCH',
    baseURL: getApiUrl()
  });
};

export const getParters = (): Promise =>
  fetch(apiEndpoint('v1/pay/partners/'), {
    baseURL: getApiUrl()
  });

export const createPartner = (partner): Promise =>
  fetch(apiEndpoint('v1/pay/partners/'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        partner
      },
      name: 'partner',
      id: '',
      session_id: '',
      device_id: ''
    }
  });

export const getPrinterUrl = (socketSelectedPrinter, socketDefaultPrinter) => {
  if (socketSelectedPrinter && socketSelectedPrinter.cmdOn && socketSelectedPrinter.cmdCs) {
    let cleanedCmdOn = socketSelectedPrinter.cmdOn.replace('http://', '');
    cleanedCmdOn = cleanedCmdOn.replace('https://', '');
    return `${cleanedCmdOn.trim()}:${socketSelectedPrinter.cmdCs.trim()}`;
  }

  if (socketDefaultPrinter && socketDefaultPrinter.cmdOn && socketDefaultPrinter.cmdCs) {
    let cleanedCmdOn = socketDefaultPrinter.cmdOn.replace('http://', '');
    cleanedCmdOn = cleanedCmdOn.replace('https://', '');
    return `${cleanedCmdOn.trim()}:${socketDefaultPrinter.cmdCs.trim()}`;
  }

  return null;
};

export const createTerminalLoadingSocket = (sessionId, socketUrl, onMessage) => {
  if (!sessionId || !socketUrl || !onMessage) {
    return null;
  }

  const client = new W3CWebSocket(`ws://${socketUrl}/terminal/subscribe/${sessionId}`);
  client.onerror = () => console.log('Terminal Socket Connection Error');
  // client.onopen = () => {
  //   console.log('WebSocket Client Connected');
  //   setTimeout(() => {
  //     client.close();
  //   }, 50000);
  // };

  client.onclose = () => console.log('Terminal Socket Connection Closed');

  client.onmessage = e => {
    if (typeof e.data === 'string' && onMessage) {
      onMessage(e.data);
    }
  };

  return client;
};

export const quickPayment = (
  selectedOrderLines: number[],
  paymentMediaId: number,
  openTableId: number,
  prgDotaz: Object,
  additionalData: Object,
  printer: string = undefined
): Promise =>
  fetch(apiEndpoint('v1/pay/quick'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        orderlines: selectedOrderLines,
        payment: {
          payment_media_id: paymentMediaId,
          additional_data: prgDotaz
        },
        open_table_id: openTableId,
        additional_data: additionalData,
        printer
      }
    }
  });

export const deposit = (paymentMediaId: Id, price: number, type: string = null, prn_no: string = null): Promise =>
  fetch(apiEndpoint('v1/pay/deposit'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        payment_id: paymentMediaId,
        amount: price,
        type,
        prn_no
      }
    }
  });

export const withdrawal = (paymentMediaId: Id, price: number, type: string = null, prn_no: string = null): Promise =>
  deposit(paymentMediaId, -price, type, prn_no);

export const registerParagon = (
  paymentId: String,
  paragonId: String,
  date: String
): Promise =>
  fetch(apiEndpoint('v1/pay/paragon'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        payment_id: paymentId,
        id: paragonId,
        date
      }
    }
  });

export const reprintBill = (
  fuciarkod: String
): Promise =>
  fetch(apiEndpoint('v1/pay/reprint'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        bill_id: fuciarkod
      }
    }
  });

export const finishUnfinishedBill = (
  bill: number,
  closure: number,
  fuciarkod: String,
  uid: String,
  okp: String
): Promise =>
  fetch(apiEndpoint('v1/pay/finish_unfinished'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        bill,
        closure,
        bill_id: fuciarkod,
        uid,
        okp
      }
    }
  });

export const rollbackBill = (
  bill: number,
  closure: number,
  fuciarkod: String
): Promise =>
  fetch(apiEndpoint('v1/pay/rollback'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        bill,
        closure,
        bill_id: fuciarkod
      }
    }
  });

export const sendPaymentReceiptByEmail = (
  paymentTransactionId: Id,
  email: string
): Promise =>
  fetch(apiEndpoint('v1/pay/email'), {
    method: 'PUT',
    baseURL: getApiUrl(),
    body: {
      params: {
        payment_id: paymentTransactionId,
        email
      }
    }
  });
