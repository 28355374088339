// @flow
import React from 'react';
import api from '../../lib/api';
import { addError } from '../../order/actions';
import { path } from 'rambda';
import { hidePrgDotaz, updatePaymentTransaction } from '../actions';
import { processReceivedPaymentTransactionData } from '../utils';
import { connect } from 'react-redux';
import { paymentOperationsName } from '../../lib/api/payment';
import orderKeeper, { RequestOrderError } from '../../lib/fetch/orderKeeper';

export default BaseComponent => connect((state: State) => ({
  paymentTransactionId: state.payment.paymentTransactionId,
  isTextPopupOpen: state.orders.active.isTextPopupOpen,
  parameters: state.parameters.parameters
}))(class extends React.PureComponent {

  reqOrderKeeper = orderKeeper(paymentOperationsName);

  confirmPayment = prgData => {
    const { dispatch, prgDotaz, prgDotazPayment, paymentTransactionId, customPay } = this.props;

    if (customPay) {
      return customPay(prgDotaz, prgDotazPayment, prgData);
    } else {
      const { id: paymentMediaId, amount, additionalData = {} } = prgDotazPayment;

      return this.reqOrderKeeper(() =>
        api.payment.addPaymentRecord(paymentTransactionId, paymentMediaId, amount, { ...additionalData, ...prgData })
      ).then(({ body }) => {
        if (path(['payment', 'id'], body)) {
          dispatch([
            updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
            hidePrgDotaz()
          ]);
          return true; // useful for RN (to dispatch go back)
        }
      }).catch(e => {
        if (e instanceof RequestOrderError) return;

        dispatch([
          addError(e),
          hidePrgDotaz()
        ]);
      });
    }
  };

  cancelPayment = () => {
    const { dispatch } = this.props;
    dispatch(hidePrgDotaz());
  };

  render() {
    return (
      <BaseComponent
        {...this.props}
        confirmPayment={this.confirmPayment}
        cancelPayment={this.cancelPayment}
      />
    );
  }
});
