// @flow

import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';
import type { Id } from '../../types';

export const saveTableDefinition = (tableDefinition: Object): Promise =>
  fetch(apiEndpoint('v1/TableDefinition'), {
    method: tableDefinition.tableDefinitionId ? 'PUT' : 'POST',
    baseURL: getApiUrl(),
    body: {
      params: tableDefinition,
      name: 'TableDefinition',
      id: '',
      session_id: '',
      device_id: ''
    }
  });

export const deleteTableDefinition = (tableId: Id): Promise =>
  fetch(apiEndpoint(`v1/TableDefinition/${tableId}`), {
    method: 'DELETE',
    baseURL: getApiUrl(),
    body: {}
  });
