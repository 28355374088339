// @flow
import type { State } from '../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import Title from '../../components/Title';
import Box from '../../../common/components/Box';
import Sidebar from './Sidebar';
import ReceiptsList from './ReceiptsList';
import titlesMessages from '../../../common/app/messages/titles';
import TopBar from './TopBar';
import SidebarSpace from '../../components/Sidebar';
import { clearError as clearOrderError } from '../../../common/order/actions';
import { clearAdminError } from '../../../common/admin/general/actions';
import Tables from '../../components/Tables';
import NotificationPlace from '../../order/NotificationPlace';
import { getAllErrors } from '../../../common/admin/receipts/selectors';
import AdminNotification from '../AdminNotification';
import InactiveBgWithTextSpinner from '../../components/InactiveBgWithTextSpinner';
import bindScreenLifecycleActions from '../../components/hoc/bindScreenLifecycleActions';
import paymentsNavigatorActions from '../../../common/payment/screenActions';
import { compose } from 'rambda';
import theme from '../../themes/defaultTheme';
import PopupTip from './PopupTip';
import PopupUnfinishedBill from './PopupUnfinishedBill';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/receipts';

class ReceiptPage extends React.PureComponent {
  _clearError = () => {
    const { dispatch } = this.props;

    dispatch([
      clearOrderError(),
      clearAdminError()
    ]);
  };

  render() {
    const {
      isCancelingReceiptAndReturningToTable,
      allErrors,
      loadingScreen,
      isTipPopupOpen,
      isUnfinishedBillPopupOpen,
      activeReceipt,
      dispatch,
      intl
    } = this.props;

    return (
      <Box flex={1} height="100%">
        <Title message={titlesMessages.admin} />

        <TopBar />

        <Box flexDirection="row" zIndex={1} flex={1} height="calc(100% - 36px)">
          <Box flex={1} id="receipts" width="100%">
            {isCancelingReceiptAndReturningToTable
              ? <Tables />
              : <ReceiptsList />
            }

            <NotificationPlace />

            <AdminNotification
              errors={allErrors}
              clearError={this._clearError}
            />
          </Box>

          <SidebarSpace>
            <Sidebar />
          </SidebarSpace>
        </Box>

        {loadingScreen
          && <InactiveBgWithTextSpinner text={intl.formatMessage(messages.receiptsOperationInProgress)} />}

        <Box zIndex={theme.zIndex.popupLayer}>
          {isTipPopupOpen &&
            <PopupTip />}
          {isUnfinishedBillPopupOpen &&
            <PopupUnfinishedBill
              dispatch={dispatch}
              bill={activeReceipt.id}
              closureId={activeReceipt.closureId}
              fuciarkod={activeReceipt.fuciarkod}
            />}
        </Box>
      </Box>
    );
  }
}

export default compose(
  bindScreenLifecycleActions(paymentsNavigatorActions),
  connect((state: State) => ({
    parameters: state.parameters.parameters,
    isCancelingReceiptAndReturningToTable:
      state.admin.receipts.isCancelingReceiptAndReturningToTable,
    isTipPopupOpen: state.admin.receipts.isTipPopupOpen,
    isUnfinishedBillPopupOpen: state.admin.receipts.isUnfinishedBillPopupOpen,
    allErrors: getAllErrors(state),
    loadingScreen: state.admin.general.loadingScreen,
    activeReceipt: state.admin.receipts.active.receipt
  })),
  injectIntl
)(ReceiptPage);
