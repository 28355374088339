// @flow
import React from 'react';
import { connect } from 'react-redux';
import { find, propEq, prop, compose, filter, sortBy, map, prepend, pluck, flatten, concat } from 'rambda';
import { groupBy, sum } from 'ramda';
import Box from '../../common/components/Box';
import Icon from '../../common/components/Icon';
import Button from '../../common/components/Button';
import Radio from '../../common/components/Radio';
import Text from '../../common/components/Text';
import Popup from '../components/Popup';
import Heading from '../../common/components/Heading';
import { addOrderLine, toggleFastFoodMenuForm, toggleTextInput } from '../../common/order/actions';
import type { State } from '../../common/types';
import { computeItemPrice, formatPriceToFixed } from '../../common/order/utils';
import uuid from 'uuid';
import Divider from '../../common/components/Divider';
import InactiveBg from '../components/InactiveBg';
import { getItemAvailability } from '../../common/items/utils';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/order';
import { getCurrencySymbol } from '../../common/parameters/service';
import ScrollView from '../../common/components/ScrollView';

class PopupFastFoodMenuForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const { fastFoodMenu: { id: fastFoodMenuId, count: fastFoodMenuCount = 1 }, items, fastMenuItems, itemPrices,
      activePriceLevelId, itemsAvailability, itemsAvailabilityDiff, intl } = props;

    this.fastFoodMenuParent = {
      ...find(propEq('id', fastFoodMenuId), items),
      itemId: fastFoodMenuId,
      singlePrice: computeItemPrice(itemPrices, fastFoodMenuId, activePriceLevelId),
      priceLevelId: activePriceLevelId,
      orderType: 10
    };

    const filteredFastMenuItems = filter(fmi => fmi.parentItemId === fastFoodMenuId, fastMenuItems);
    const fastMenuSubItems = map(
      fmi => {
        const relatedItem = find(propEq('id', fmi.itemId), items);
        return relatedItem
          ? ({
              ...fmi,
              menuName: relatedItem.menuName,
              receiptName: relatedItem.receiptName,
              unitName: relatedItem.unit,
              singlePrice: computeItemPrice(itemPrices, relatedItem.id, fmi.priceLevelId),
              orderType: 11,
              spart: relatedItem.spart,
              availability: getItemAvailability(relatedItem.id, itemsAvailability,
                itemsAvailabilityDiff)
            })
          : fmi;
      }, filteredFastMenuItems);
    const groupByMenuCategory = groupBy(menu => menu.groupOrder);
    const groupedByMenuCategory = groupByMenuCategory(sortBy(prop('groupOrder'), fastMenuSubItems));

    if (groupedByMenuCategory) {
      Object.keys(groupedByMenuCategory).forEach(category => {
        groupedByMenuCategory[category] = sortBy(prop('position'), groupedByMenuCategory[category]);
      });
    }

    this.groupedFastMenuSubItems = Object.values(groupedByMenuCategory);

    const numberOfGroups = this.groupedFastMenuSubItems.length;

    this.state = {
      numberOfGroups,
      activeGroup: 0,
      title: this.fastFoodMenuParent ? (this.fastFoodMenuParent.receiptName || intl.formatMessage(messages.orderFastFoodDailyMenu)) : intl.formatMessage(messages.orderFastFoodDailyMenu),
      totalMenuPrice: this.fastFoodMenuParent.singlePrice,
      preparedLinkedToId: Array(fastFoodMenuCount).fill(1).map(() => uuid.v4())
    };

    this.chosenMenu = Array(numberOfGroups);
  }

  componentDidMount() {
    const fastMenuSubItems = this.groupedFastMenuSubItems[this.state.activeGroup];
    if (fastMenuSubItems && fastMenuSubItems.length === 1) {
      this._addSubMenuItem(this.state.activeGroup, 0);
    }
  }

  _addSubMenuItem = (group, subMenuGroupIndex) => {
    const { dispatch, activeSubTableId, activeFoodCourse } = this.props;

    this.chosenMenu[group] = { ...this.groupedFastMenuSubItems[group][subMenuGroupIndex], subMenuGroupIndex };
    this.setState({
      totalMenuPrice: this.fastFoodMenuParent.singlePrice + sum(pluck('singlePrice', (this.chosenMenu).filter(el => el.singlePrice)))
    });

    if (this.state.activeGroup + 1 < this.state.numberOfGroups) {
      this.setState(
        { activeGroup: this.state.activeGroup + 1 },
        () => {
          if (this.groupedFastMenuSubItems[this.state.activeGroup].length === 1) {
            this._addSubMenuItem(this.state.activeGroup, 0);
          }
        }
      );
    } else {
      const fullFastMenuItems = prepend(
        this.fastFoodMenuParent,
        this.chosenMenu
      );

      const customGroupHash = this.state.preparedLinkedToId && uuid.v4();

      const addFastFoodMenus = flatten(
        map(
          uid => map(
            el => addOrderLine(
              {
                ...el,
                subTableId: activeSubTableId,
                foodCourse: activeFoodCourse,
                linkedToId: uid,
                customGroupHash
              },
              { selectAfterAddition: this.fastFoodMenuParent.mustEnterText }
              ),
            fullFastMenuItems
          ),
          this.state.preparedLinkedToId
        )
      );

      const dispatchLoad = concat(
        concat(
          [toggleFastFoodMenuForm(false)],
          addFastFoodMenus
        ),
        this.fastFoodMenuParent.mustEnterText ? [toggleTextInput(true)] : []
      );

      dispatch(dispatchLoad);
    }
  };

  _forthInMenu = () => {
    if (this.state.activeGroup + 1 < this.state.numberOfGroups) {
      this.setState(
        { activeGroup: this.state.activeGroup + 1 },
        () => {
          if (this.groupedFastMenuSubItems[this.state.activeGroup].length === 1) {
            this._forthInMenu();
          }
        }
      );
    }
  };

  _backInMenu = () => {
    if (this.state.activeGroup > 0) {
      if (this.groupedFastMenuSubItems[this.state.activeGroup - 1].length === 1) {
        this.setState(
          { activeGroup: this.state.activeGroup - 1 },
          () => this._backInMenu()
        );
      } else {
        this.setState({ activeGroup: this.state.activeGroup - 1 });
      }
    } else if (this.groupedFastMenuSubItems[this.state.activeGroup].length === 1) {
      this._forthInMenu();
    }
  };

  render() {
    const { dispatch, itemsAvailability, itemsAvailabilityDiff, intl } = this.props;
    const { numberOfGroups, activeGroup, title, totalMenuPrice } = this.state;

    return (
      <InactiveBg onClose={() => dispatch(toggleFastFoodMenuForm(false))}>
        <Popup
          showClose
          backgroundColor="popupBg"
          color="white"
          onClose={() => dispatch(toggleFastFoodMenuForm(false))}
          onCloseButtonShadow="none"
        >
          <Box flexDirection="row" justifyContent="space-between">
            <Heading scale={3} color="white" width={13}>{title}</Heading>
            <Heading scale={3} color="white">{`${formatPriceToFixed(totalMenuPrice)} ${getCurrencySymbol()}`}</Heading>
          </Box>

          <ScrollView maxHeight="calc(100vh - 220px)" overflowX="hidden">
            {this.groupedFastMenuSubItems[activeGroup]
              ? this.groupedFastMenuSubItems[activeGroup].map((element, index) => (
                <Button
                  key={index}
                  backgroundColor="appBg"
                  outline
                  color="white"
                  marginTop={index === 0 ? 0 : 0.75}
                  width={18}
                  paddingVertical={0.75}
                  paddingHorizontal={2}
                  onPress={() => this._addSubMenuItem(activeGroup, index)}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  disabled={!element.itemId}
                >
                  <Radio
                    size={1}
                    color="white"
                    left={0.75}
                    position="absolute"
                    backgroundColor="appBg"
                    checked={this.chosenMenu[activeGroup] && this.chosenMenu[activeGroup].subMenuGroupIndex === index}
                  />
                  <Box marginLeft={0.75} flexDirection="column">
                    <Text color="white" bold>
                      {element.receiptName || intl.formatMessage(messages.orderFastFoodMenuItem)}
                    </Text>
                    {element.itemId ? (
                      <Text color="white">
                        {`${formatPriceToFixed(element.singlePrice)} ${getCurrencySymbol()} / ${element.unitName} `}
                        {getItemAvailability(element.itemId, itemsAvailability,
                          itemsAvailabilityDiff) < 1 && <em>{intl.formatMessage(messages.orderFastFoodNotAvailable)}</em>}
                      </Text>
                    ) : (
                      <Text color="white">
                        {`${intl.formatMessage(messages.orderFastFoodWrongConfiguration)} (ID ${element.id})`}
                      </Text>
                    )}
                  </Box>
                </Button>))
              : <Text color="white">
                {intl.formatMessage(messages.orderFastFoodNoItems)}
                </Text>
            }
          </ScrollView>

          <Box
            width="100%"
            height={3.5}
            backgroundColor="popupBg"
            justifyContent="center"
            alignItems="flex-end"
            borderBottomLeftRadius="normal"
            borderBottomRightRadius="normal"
            paddingTop={1}
            flexShrink={0}
          >
            <Divider color="white" width="100%" style={{ opacity: 0.2 }} marginBottom={0.5} flexShrink={0} />
            {activeGroup
              ? <Box width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" flexShrink={0}>
                <Button
                  width={2}
                  height={2}
                  alignItems="center"
                  justifyContent="center"
                  flexShrink={0}
                  backgroundColor="white"
                  style={theme => ({ borderRadius: theme.typography.lineHeight() })}
                  onPress={() => this._backInMenu()}
                >
                  <Icon name={'arrow-up'} color="popupBg" scale={2} style={{ transform: 'rotate(-90deg)' }} />
                </Button>
                <Text scale={3} color="white">{`${activeGroup + 1}/${numberOfGroups}`}</Text>
              </Box>
              : <Text scale={3} color="white" flexShrink={0}>{`${activeGroup + 1}/${numberOfGroups}`}</Text>}
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    items: state.items.items,
    fastMenuItems: state.items.fastMenuItems,
    itemPrices: state.items.itemPrices,
    activePriceLevelId: state.items.activePriceLevelId,
    activeSubTableId: state.tables.active.subTableId,
    activeFoodCourse: state.tables.active.foodCourse,
    itemsAvailability: state.items.availability,
    itemsAvailabilityDiff: state.items.availabilityLocalDiff
  })),
  injectIntl
)(PopupFastFoodMenuForm);
