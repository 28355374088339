// @flow
import api from '../lib/api';
import { actionWithServerDate } from '../lib/fetch';
import { serializeParameters } from '../parameters/utils';
import paramsValueTypes from '../parameters/paramsValueTypes';
import { availabilityAsNumbers, addCustomParameters } from '../items/utils';
import { completeOffline } from '../network/actions';
import { setCurrentLocale } from '../intl/actions';
import { pathOr } from 'rambda';

const getCurrentLocaleFromHeaders = (headers) => {
  const contentLanguage = pathOr('sk-sk', 'content-language', headers);
  const languageCodeAndCountry = contentLanguage.split('-');
  if (languageCodeAndCountry.length === 2){
    const language = languageCodeAndCountry[0];
    return language;
  }

  return 'sk';
};

export const syncServerDataCommit = (data: Object) => {
  const serializedParameters = serializeParameters(data.DanubeConfiguration, paramsValueTypes);

  return actionWithServerDate({
    type: 'SYNC_SERVER_DATA_COMMIT',
    payload: {
      ServiceAreaDefinition: data.ServiceAreaDefinition,
      OpenTable: data.OpenTable,
      SubTable: data.SubTable,
      TableDefinition: data.TableDefinition,
      CategoryContent: data.CategoryContent,
      Category: data.Category,
      Item: addCustomParameters(data.Item, serializedParameters),
      FastMenuItem: data.FastMenuItem,
      ItemPrice: data.ItemPrice,
      PriceLevel: data.PriceLevel,
      OrderLine: data.OrderLine,
      Employee: data.Employee,
      PaymentMedia: data.PaymentMedia,
      PaymentTypes: data.DruhyPl,
      PaymentTransactions: data.PaymentTransactions,
      // serialize now, so all reducers have correct data types
      Parameters: serializedParameters,
      ServerDiscounts: data.ZlavyHlav,
      Availability: availabilityAsNumbers(data.Availability),
      Printers: data.PrnDef,
      Permissions: data.Permissions,
      Messages: data.Spravy,
      LoyalMan: data.LoyalMan,
      SLimits: data.SLimityZoznam,
      Auth: data.Foodie
    }
  });
};

const syncServerDataRollback = () => ({
  type: 'SYNC_SERVER_DATA_ROLLBACK'
});

export const syncServerData = (isManual = false) => ({
  type: 'SYNC_SERVER_DATA',
  meta: {
    offline: {
      effect: () => api.sync.sync(),
      commit: ({ payload: { body: { result: { sync } }, headers } }) => [
        completeOffline(),
        syncServerDataCommit(sync)(headers),
        setCurrentLocale(getCurrentLocaleFromHeaders(headers))
      ],
      rollback: () => [
        completeOffline(),
        syncServerDataRollback()
      ]
    }
  },
  payload: {
    isManual
  }
});

export const blitzSyncServerDataCommit = (data: Object) => actionWithServerDate({
  type: 'BLITZ_SYNC_SERVER_DATA_COMMIT',
  payload: {
    OpenTable: data.OpenTable,
    SubTable: data.SubTable,
    TableDefinition: data.TableDefinition,
    OrderLine: data.OrderLine,
    Availability: availabilityAsNumbers(data.Availability),
    PaymentTransactions: data.PaymentTransactions,
    Parameters: data.Parameters,
    Permissions: data.Permissions,
    LoyalMan: data.LoyalMan,
    SLimits: data.SLimityZoznam,
    Printers: data.PrnDef,
    Auth: data.Foodie
  }
});

const blitzSyncServerDataRollback = () => ({
  type: 'BLITZ_SYNC_SERVER_DATA_ROLLBACK'
});

export const blitzSyncServerData = () => ({ getState }) => ({
  type: 'BLITZ_SYNC_SERVER_DATA',
  meta: {
    offline: {
      effect: () => api.sync.blitzSync(),
      commit: ({ payload: { body: { result: { sync } }, headers } }) => [
        completeOffline(),
        blitzSyncServerDataCommit({
          ...sync,
          Parameters: getState().parameters.parameters
        })(headers)
      ],
      rollback: () => [
        completeOffline(),
        blitzSyncServerDataRollback()
      ]
    }
  }
});
