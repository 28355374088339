// @flow
import React from 'react';
import Text from '../Text';
import Button from '../Button';
import theme from '../../themes/theme';
import { isReactNative } from '../../app/detectPlatform';

const primaryFelaStyle = {
  ':active': {
    backgroundColor: `${theme.colors.buttonTealActive} !important`
  }
};

const operatorFelaStyle = {
  ':active': {
    backgroundColor: `${theme.colors.keyboardButtonHighlightActive} !important`
  }
};

const defaultFelaStyle = {
  ':active': {
    backgroundColor: `${theme.colors.keyboardButtonActive} !important`
  }
};

class TypewriterButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this._onPress = this._onPress.bind(this);
  }

  _onPress() {
    const { onPress, sendChar, char } = this.props;

    onPress(sendChar === undefined ? char : sendChar);
  }

  render() {
    const {
      char,
      sendChar,
      onPress,
      size = 1,
      last = false,
      operator = false,
      primary = false,
      children,
      ...restProps
    } = this.props;

    return (
      <Button
        color={(operator && 'keyboardButtonHighlight') || (primary && 'teal') || 'keyboardButton'}
        backgroundColor={(operator && 'keyboardButtonHighlight') || (primary && 'buttonTeal') || 'keyboardButton'}
        borderColor={(primary && 'buttonTealShadow') || 'keyboardButtonShadow'}
        borderStyle="solid"
        borderBottomWidth={1}
        margin="0"
        marginRight={last ? 0 : 0.75}
        flexShrink={0}
        paddingHorizontal={0}
        width={`${theme.typography.lineHeight(3) * 1.75 * size}px`}
        height={`${theme.typography.lineHeight(3) * 1.75}px`}
        felaStyle={
          isReactNative
            ? null
            : (primary && primaryFelaStyle) || (operator && operatorFelaStyle) || defaultFelaStyle
        }
        onPress={this._onPress}
        {...restProps}
      >
        {children ||
        <Text scale={primary ? 2 : 3} thin={!primary} justifyContent="center"
              color={(operator || primary) ? 'white' : 'black'}>{char}</Text>
        }
      </Button>
    );
  }
}

export default TypewriterButton;
