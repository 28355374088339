// @flow
import React from 'react';
import Box from './Box';

type ArrowProps = {
  size?: number,
  backgroundColor: string,
  orientation: 'to-left' | 'to-right' | 'to-top' | 'to-bottom'
}

const Arrow = ({ size = 10, backgroundColor, orientation, top, ...restProps }: ArrowProps) => (
  <Box
    width="0"
    height="0"
    position="absolute"
    top={top || '50%'} // TODO fix for to-top, to-bottom
    borderStyle="solid"
    borderRightColor={backgroundColor}
    borderLeftColor={backgroundColor}
    style={() => ({
      borderTopWidth: size,
      borderBottomWidth: size,
      [orientation === 'to-right' ? 'borderLeftWidth' : 'borderRightWidth']: size,
      borderTopColor: 'transparent',
      borderBottomColor: 'transparent',
      [orientation === 'to-right' ? 'right' : 'left']: -size,
      transform: 'translateY(-50%)'
    })}
    {...restProps}
  />
);

export default Arrow;
