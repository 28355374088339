// @flow
import type { Action, ReceiptsState } from '../../types';
import { assocPath, dissocPath, isEmpty } from 'ramda';
import { append, filter } from 'rambda';

const initialState = {
  active: {
    receipt: null,
  },
  selectedReceiptItems: {},
  isCancelingReceiptAndReturningToTable: false,
  isTipPopupOpen: false,
  isUnfinishedBillPopupOpen: false
};

const reducer = (state: ReceiptsState = initialState, action: Action) => {
  switch (action.type) {
    case 'ADMIN_RECEIPT_ACTIVATE_RECEIPT': {
      const { receiptId, closureId, date, selectedMonth, showClosedReceipts, idLimit, unfinished, fuciarkod } = action.payload;
      return assocPath(['active', 'receipt'], { id: receiptId, closureId, date, selectedMonth, showClosedReceipts, idLimit, unfinished, fuciarkod }, state);
    }

    case 'ADMIN_RECEIPT_DEACTIVATE_RECEIPT': {
      return assocPath(['active', 'receipt'], null, state);
    }

    case 'ADMIN_RECEIPT_STORE_RECEIPT_SUM': {
      const { sum } = action.payload;
      return assocPath(['active', 'receipt', 'sum'], sum, state);
    }

    case 'ADMIN_RECEIPT_SELECT_ITEMS': {
      const id = `${action.payload.id}`; // dissocPath doesnt play well with num
      const { subId } = action.payload;
      const isNew = !state.selectedReceiptItems[id];

      const checkSubId = (subId, allIds) =>
        allIds.indexOf(subId) < 0
          ? append(subId, allIds)
          : filter(el => el !== subId, allIds);

      if (subId && typeof subId === 'number') {
        return isNew
          ? assocPath(['selectedReceiptItems', id], [subId], state)
          : isEmpty(checkSubId(subId, state.selectedReceiptItems[id]))
            ? dissocPath(['selectedReceiptItems', id], state)
            : assocPath(['selectedReceiptItems', id], checkSubId(subId, state.selectedReceiptItems[id]), state);
      }

      return isNew
        ? assocPath(['selectedReceiptItems', id], subId, state)
        : dissocPath(['selectedReceiptItems', id], state);
    }

    case 'ADMIN_RECEIPT_CLEAR_SELECTED_ITEMS': {
      return assocPath(['selectedReceiptItems'], {}, state);
    }

    case 'ADMIN_RECEIPT_IS_CANCELING_RECEIPT_AND_RETURNING_TO_TABLE': {
      const { is } = action.payload;
      return assocPath(['isCancelingReceiptAndReturningToTable'], is, state);
    }

    case 'ADMIN_RECEIPT_TOGGLE_TIP_POPUP': {
      const { is = false } = action.payload;
      return assocPath(['isTipPopupOpen'], is, state);
    }

    case 'ADMIN_RECEIPT_UNFINISHED_BILL_POPUP': {
      const { is = false } = action.payload;
      return assocPath(['isUnfinishedBillPopupOpen'], is, state);
    }

    default:
      return state;
  }
};

export default reducer;
