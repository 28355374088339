// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import domToImage from 'dom-to-image';
import Box from '../../common/components/Box';
import Button from '../../common/components/Button';
import Text from '../../common/components/Text';
import { DragSource } from 'react-dnd';
import { draggableTable } from '../components/Table';
import type { DraggableTableItem } from '../components/Table';

const buttonAttrs = {
  backgroundColor: 'transparent',
  color: 'white',
  size: 2.5,
  overflow: 'hidden',
  borderWidth: 2,
  borderColor: 'white',
  borderStyle: 'dashed',
  width: 2.5,
  height: 2.5,
  noActiveStyle: true
};

class DraggableNewTable_ extends React.PureComponent {
  render() {
    const { rounded, connectDragSource } = this.props;

    return connectDragSource(
      <div style={{ display: 'block', position: 'absolute', top: -2, left: -2, height: '100%' }}>
        <Button {...buttonAttrs} rounded={rounded}>
          <Text color="white" scale={5} justifyContent="center">+</Text>
        </Button>
      </div>
    );
  }
}

const spec = {
  beginDrag({ rounded }) {
    const item: DraggableTableItem = {
      id: null,
      geometry: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        rounded
      }
    };

    return item;
  }
};

const collect = (connect) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
});

const DraggableNewTable = DragSource(draggableTable, spec, collect)(DraggableNewTable_);

const TablesController = () => (
  <Box
    position="absolute"
    right={0}
    top="50%"
    width={3.7}
    height={7.5}
    paddingVertical={0.5}
    alignItems="center"
    justifyContent="space-around"
    zIndex={1}
    style={{
      backgroundColor: 'rgba(52, 52, 59, 0.7)',
      transform: 'translateY(-50%)'
    }}
  >
    <Button {...buttonAttrs} rounded>
      <Text color="white" scale={5} justifyContent="center">+</Text>
      <DraggableNewTable rounded />
    </Button>

    <Button {...buttonAttrs}>
      <Text color="white" scale={5} justifyContent="center">+</Text>
      <DraggableNewTable />
    </Button>
  </Box>
);

export default TablesController;
