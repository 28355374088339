// @flow
import React from 'react';
import InactiveBg from '../../../components/InactiveBg';
import { hidePrgDotaz, setPrgFilterValue } from '../../../../common/payment/actions';
import Popup from '../../../components/Popup';
import Rooms from '../../../../common/payment/prgDotazy/hores/Rooms';
import Hotels from '../../../../common/payment/prgDotazy/hores/Hotels';
import Guests from '../../../../common/payment/prgDotazy/hores/Guests';
import prgDotaz from '../../../../common/payment/hoc/prgDotaz';
import RoomsFilter from './RoomsFilter';
import { findParamValue } from '../../../../common/parameters/utils';
import FidelioOptions from './FidelioOptions';
import FidelioOptionType from '../../../../common/payment/prgDotazy/hores/FidelioOptionType';
import FidelioGuests from '../../../../common/payment/prgDotazy/hores/FidelioGuests';
import FidelioRooms from '../../../../common/payment/prgDotazy/hores/FidelioRooms';

class DotazHo extends React.PureComponent {
  constructor(props) {
    super(props);
    const { prgDotaz: [, hotelId] } = this.props;

    this.state = {
      step: hotelId ? 1 : 0,
      firstStep: hotelId ? 1 : 0,
      hotelId,
      roomId: undefined,
      accountId: undefined,
      guestId: undefined,
      roomName: undefined,
      guestName: undefined,
      fidelioType: undefined,
      fidelioCode: undefined,
      isLoading: false,
    };
  }

  cancel = () => {
    const { dispatch } = this.props;
    dispatch([
      hidePrgDotaz(),
      setPrgFilterValue('')
    ]);
  };

  goBack = () => {
    const { dispatch } = this.props;
    const { step } = this.state;

    this.setState({
      step: step - 1,
    });

    dispatch(setPrgFilterValue(''));
  };

  selectHotel = (hotelId) => {
    this.setState({ hotelId, step: 1 });
  };

  selectRoom = (roomId, accountId, roomName, isRoom = true) => {
    if (isRoom) {
      const { dispatch } = this.props;

      this.setState({
        roomId, accountId, roomName, step: 2
      });
      dispatch(setPrgFilterValue(''));
    } else {
      this.setState({
        roomId, accountId, roomName: '', guestName: roomName
      }, () => this.savePrgDotaz());
    }
  };

  selectGuest = (guestId, guestName) => {
    this.setState({ guestId, guestName }, () => this.savePrgDotaz());
  };

  selectFidelioGuest = (id, guest, roomCode) => {
    const questId = id ? parseInt(id.trim(), 10) : id;
    const guestName = guest ? guest.trim() : guest;
    const room = roomCode ? roomCode.trim() : roomCode;
    this.setState({
      guestId: questId, accountId: questId, guestName, roomId: room, roomName: room
    }, () => this.savePrgDotaz());
  };

  selectFidelioRoom = (id, guest, roomCode) => {
    const questId = id ? parseInt(id.trim(), 10) : id;
    const guestName = guest ? guest.trim() : guest;
    const room = roomCode ? roomCode.trim() : roomCode;
    this.setState({
      guestId: questId, accountId: questId, guestName, roomId: room, roomName: room
    }, () => this.savePrgDotaz());
  };

  selectFidelioOption = (type, code) => {
    this.setState({ fidelioType: type, fidelioCode: code, step: 2 });
  };

  savePrgDotaz = () => {
    this.setState({ isLoading: true });

    const { confirmPayment } = this.props;
    const {
 hotelId, accountId, guestId, guestName, roomName
} = this.state;

    const prgData = {
      hotel_id: hotelId,
      guest_id: guestId,
      account_id: accountId,
      name: guestName,
      room: roomName,
    };

    confirmPayment(prgData);
  };

  stepComponents = [
    { cmp: Hotels, confirm: this.selectHotel, hasFilter: false },
    {
      cmp: Rooms,
      confirm: this.selectRoom,
      hasFilter: hotelId => (<RoomsFilter onConfirm={this.selectRoom} hotelId={hotelId} />)
    },
    { cmp: Guests, confirm: this.selectGuest, hasFilter: false }
  ];

  fidelioStepComponents = (type) => {
    const components = [
      { cmp: Hotels, confirm: this.selectHotel, hasFilter: false },
      { cmp: FidelioOptions, confirm: this.selectFidelioOption, hasFilter: false }
    ];

    if (type === FidelioOptionType.ROOM) {
      components.push({ cmp: FidelioGuests, confirm: this.selectFidelioGuest, hasFilter: false });
    } else if (type === FidelioOptionType.CARD) {
      components.push({ cmp: FidelioRooms, confirm: this.selectFidelioRoom, hasFilter: false });
    }

    return components;
  }

  isFidelioDotaz = (parameters) => parameters && findParamValue('K32.typ_hotel', parameters) === '6'

  render() {
    const {
 firstStep, step, hotelId, roomId, guestId, fidelioType, fidelioCode, isLoading
} = this.state;
    const { isTextPopupOpen, parameters } = this.props;
    const fidelioStepComponents = this.fidelioStepComponents(fidelioType);
    const { cmp: StepCmp, confirm, hasFilter } = this.isFidelioDotaz(parameters)
      ? fidelioStepComponents[step] : this.stepComponents[step];

    return (
      <InactiveBg onClose={this.cancel}>
        <Popup pushUpward={isTextPopupOpen} onClose={this.cancel} onBack={(step - firstStep) ? this.goBack : null}>
          <StepCmp
            onConfirm={confirm}
            isLoading={isLoading}
            hotelId={hotelId}
            roomId={roomId}
            guestId={guestId}
            code={fidelioCode}
            hasFilter={hasFilter}
          />
        </Popup>
      </InactiveBg>
    );
  }
}

export default prgDotaz(DotazHo);
