// @flow
import type { ItemsAvailability, Id, ItemsAvailabilityDiff } from '../types';
import { path, pathOr, max } from 'ramda';
import { map, reduce } from 'rambda';
import { findParamValue, isSpartIncludedInParams } from '../parameters/utils';

export const getItemAvailability = (
  itemId: Id,
  itemsAvailability: ItemsAvailability,
  itemsAvailabilityDiff: ItemsAvailabilityDiff
): number => {
  const availability = path([itemId, 'availability'], itemsAvailability);

  if (availability === undefined) return Infinity;

  const diff = pathOr(0, [itemId], itemsAvailabilityDiff);
  return max(0, availability + diff);
};

export const availabilityAsNumbers = (itemsAvailability: ItemsAvailability) =>
  map(({ availability, ...rest }) => ({
    ...rest,
    availability: isNaN(+availability) ? undefined : +availability
  }), itemsAvailability);

export const addCustomParameters = (items: Object[], parameters: Object[]) => {
  const paramValueForCustomPrice = findParamValue('K32.kc_spart', parameters);
  const paramValueForIsWeighted = findParamValue('K32.vah_sp_l', parameters);
  const paramValueForFiskalSpPohladavka = findParamValue('K32.fiskal_sp_pohladavka', parameters);

  return map((item) => ({
    ...item,
    hasCustomPrice: isSpartIncludedInParams(paramValueForCustomPrice, item.spart)
      || isSpartIncludedInParams(paramValueForFiskalSpPohladavka, item.spart)
      || item.spart === 'DOB', // defined by clients
    isWeighted: isSpartIncludedInParams(paramValueForIsWeighted, item.spart),
    needsInvoiceNo: isSpartIncludedInParams(paramValueForFiskalSpPohladavka, item.spart)
  }), items);
};

export const mergeItemsAndQuantity = items => reduce((acc, { itemId, quantity }) => {
  acc[itemId] = (acc[itemId] || 0) + quantity;
  return acc;
}, {}, items);
