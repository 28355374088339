// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Table, InfiniteLoader, defaultTableRowRenderer } from 'react-virtualized';
import { concat } from 'rambda';
import { isEmpty } from 'ramda';
import theme from '../../common/themes/theme';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import invariant from 'invariant';
import type { State } from '../../common/types';
import Spinner from '../../common/components/Spinner';
import messages from '../../common/messages/common';
import { FormattedMessage } from 'react-intl';

const defaultRowRenderer = ({ key, style, items, ...restProps }) => (
  <Text key={key} style={style} justifyContent="center">
    {defaultTableRowRenderer(restProps)}
  </Text>
);

export default class extends React.PureComponent {
  rowRendererOverride = (args) => {
    const {
      items,
      rowRenderer = defaultRowRenderer
    } = this.props;

    return rowRenderer({ ...args, items });
  };

  noRowsPlaceholder = () => (
    <Text
      justifyContent="center"
      alignItems="center"
      paddingTop={0.5}
      borderColor="lightGray"
      borderStyle="solid"
      borderTopWidth={1}
    >
      <FormattedMessage {...messages.commonEmptyTable} />
    </Text>
  );

  render() {
    const {
      registerRef,
      rowHeight = theme.table.normalRowHeight,
      items,
      children,
      ...restProps
    } = this.props;

    return (
      <Table
        ref={registerRef}
        rowGetter={({ index }) => items[index]}
        rowCount={items.length}
        rowHeight={rowHeight}
        headerHeight={theme.table.headerHeight}
        noRowsRenderer={this.noRowsPlaceholder}
        {...restProps}
        rowRenderer={this.rowRendererOverride}
      >{children}</Table>
    );
  }
}

export const withGroups = ({ isGroupRow, groupHeaderDataGetter }) => BaseComponent =>
  class extends React.PureComponent {
    rowHeight = ({ index }) => isGroupRow(index, this.props.items)
      ? theme.table.groupRowHeight + theme.table.normalRowHeight
      : theme.table.normalRowHeight;

    rowRenderer = ({ key, index, style, ...restProps }) => (
      <Box style={style} key={key}>
        {isGroupRow(index, this.props.items) &&
        <Text
          className="ReactVirtualized__Table__row separator"
          flexBasis={`${theme.table.groupRowHeight}px`}
          alignItems="flex-start"
          paddingLeft={1}
          justifyContent="center"
          bold
          scale={-1}
          backgroundColor="lighterGray"
          borderStyle="solid"
          borderColor="lightGray"
          borderTopWidth={1}
          borderBottomWidth={1}
        >
          {groupHeaderDataGetter(restProps)}
        </Text>
        }
        <Text flexDirection="row" flexBasis={`${theme.table.normalRowHeight}px`}>
          {defaultTableRowRenderer(restProps)}
        </Text>
      </Box>
    );

    render() {
      return (
        <BaseComponent
          rowHeight={this.rowHeight}
          rowRenderer={this.rowRenderer}
          {...this.props}
        />
      );
    }
  };

export const withSelectableRows = (rowDataKey = 'id') => BaseComponent =>
class extends React.PureComponent {
  rowRendererOverride = (args) => {
    const {
      selectedItems,
      rowRenderer = defaultRowRenderer
    } = this.props;

    if (!selectedItems) return rowRenderer(args);

    invariant(typeof selectedItems === 'object', 'You forgot to pass selectedItems prop');
    const idKey = args.rowData[rowDataKey];

    const className = selectedItems[idKey] ? `${args.className} selected` : args.className;

    return rowRenderer({ ...args, className });
  };

  render() {
    const { selectedItems, ...restProps } = this.props;

    return (
      <BaseComponent
        {...restProps}
        rowRenderer={this.rowRendererOverride}
      />
    );
  }
};

// for graphql requests
export const withInfiniteLoading = BaseComponent => ({ loadMoreRows, ...props }) => (
  <InfiniteLoader
    threshold={100}
    rowCount={9999} // should be enough for all scenarios
    isRowLoaded={({ index }) => !!props.items[index]}
    loadMoreRows={loadMoreRows}>
    {({ onRowsRendered, registerChild }) => (
      <BaseComponent {...props} onRowsRendered={onRowsRendered} registerRef={registerChild} />
    )}
  </InfiniteLoader>
);

// for fetch requests
export const withInfiniteLoader = ({
  minimumBatchSize = 40,
  rowDataKey = 'id',
} = {}) => BaseComponent => connect((state: State) => ({
  reloadAdminTable: state.admin.general.reloadAdminTable
}))(class extends React.PureComponent {
  state = {
    loadedItems: [],
    loadAfterId: null,
    isLoading: false
  };

  componentDidMount() {
    this.loadMoreRows();
  }

  componentWillUpdate(nextProps) {
    const { reloadAdminTable: nextReloadAdminTable } = nextProps;
    const { reloadAdminTable } = this.props;

    if (nextReloadAdminTable !== reloadAdminTable) {
      this._clearData(() => this.loadMoreRows());
    }
  }

  _activateLoadingState = () => {
    this.setState({
      isLoading: true
    });
  };

  _clearData = (callback) => {
    this.setState({
      loadedItems: [],
      loadAfterId: null,
      isLoading: false,
    }, callback);
  };

  isRowLoaded = ({ index }) => {
    const { loadedItems } = this.state;
    return !!loadedItems[index];
  };

  loadMoreRows = () => {
    const { loadedItems, loadAfterId, isLoading } = this.state;
    const { lazyFetch } = this.props;

    if (isLoading) return;
    this._activateLoadingState();

    lazyFetch({ loadAfterId })
      .then((result) => {
        if (!result || isEmpty(result)) {
          this.setState({
            isLoading: false
          });
        } else {
          this.setState({
            loadedItems: concat(loadedItems, result),
            loadAfterId: result ? result[result.length - 1][rowDataKey] : undefined,
            isLoading: false
          });
        }
      });
  };

  render() {
    const { loadedItems = [] } = this.state;

    return (
      <InfiniteLoader
        isRowLoaded={this.isRowLoaded}
        loadMoreRows={this.loadMoreRows}
        rowCount={Number.MAX_VALUE}
        minimumBatchSize={minimumBatchSize}
      >
        {({ onRowsRendered, registerChild }) =>
          <BaseComponent
            {...this.props}
            ref={registerChild}
            onRowsRendered={onRowsRendered}
            items={loadedItems}
          />
        }
      </InfiniteLoader>
    );
  }
});

export const withLoadingState = BaseComponent =>
class extends React.PureComponent {
  render() {
    const { isLoading, width, height, ...restProps } = this.props;

    if (!isLoading) {
      return (
        <BaseComponent
          width={width}
          height={height}
          {...restProps}
        />
      );
    }

    return (
      <span>
        <BaseComponent
          width={width}
          height={height}
          {...restProps}
        />
        <Box width={`${width}px`} height={`${height}px`} position="absolute" top={0} backgroundColor="rgba(255,255,255,0.8)">
          <Spinner />
          <Text top="50%" align="center" scale={1}><FormattedMessage {...messages.commonLoadingTable} /></Text>
        </Box>
      </span>
    );
  }
};
