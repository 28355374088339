// @flow
import React from 'react';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import moment from 'moment';
import { VelocityComponent } from 'velocity-react';

class MonthPicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      year: props.selectedYear || moment().year(),
      month: props.selectedMonth
    };
  }

  months = moment.monthsShort();

  _setNextYear() {
    this.setState({ year: this.state.year + 1 });
  }

  _setPrevYear() {
    this.setState({ year: this.state.year - 1 });
  }

  _pickMonth(month) {
    const { closeMonthPicker, setMonth } = this.props;

    if (closeMonthPicker) {
      closeMonthPicker();
    }

    setMonth({
      month,
      year: this.state.year,
      name: moment().set({ year: this.state.year, month }).format('MMMM YYYY')
    });
  }

  render() {
    const { selectedYear } = this.props;

    return (
      <VelocityComponent animation={'transition.slideDownIn'} duration={500} delay={0} runOnMount>
        <Box
          backgroundColor="darkGray"
          width={10}
          padding={1}
          justifyContent="center"
          alignItems="center"
        >
          <Box marginBottom={1} flexDirection="row" justifyContent="space-between">
            <Button onPress={() => this._setPrevYear()} justifyContent="center" alignItems="center">
              <Icon color="white" name="back" scale={-1} />
            </Button>
            <Text color="white" bold>{this.state.year}</Text>
            <Button onPress={() => this._setNextYear()} justifyContent="center" alignItems="center">
              <Icon color="white" name="back" scale={-1} style={{ transform: 'scaleX(-1)' }} />
            </Button>
          </Box>

          <Box flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
            {this.months.map((month, index) => {
              const isActive = this.state.year === selectedYear && index === this.state.month;

              return (
                <Button key={month} onPress={() => this._pickMonth(index)} width="33%"
                        justifyContent="center" alignItems="center" padding={0.5}>
                  <Text color="white" bold={isActive} opacity={isActive ? 1 : 0.75}>
                    {month}
                  </Text>
                </Button>
              );
            })}
          </Box>
        </Box>
      </VelocityComponent>
    );
  }
}

export default MonthPicker;
