// @flow
import React from 'react';
import { findDOMNode } from 'react-dom';

export const closestElementOfComponent = (element: Element, cmp: React.Component) => {
  if (!element || !cmp) return null;

  const cmpEl = findDOMNode(cmp);

  do {
    if (element === cmpEl) return element;
    element = element.parentElement;
  } while (element !== null);

  return null;
};
