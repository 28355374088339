// @flow
import alcohol from './alcohol/reducer';
import closures from './closures/reducer';
import general from './general/reducer';
import receipts from './receipts/reducer';
import usage from './usage/reducer';
import { combineReducers } from 'redux';

const reducer =
  combineReducers({
    alcohol,
    receipts,
    usage,
    closures,
    general
  });

export default reducer;
