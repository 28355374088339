// @flow
import type { Action, Deps } from '../types';

export const activityRegister = (): Action =>
  ({ now }: Deps): Action => ({
    type: 'ACTIVITY_REGISTER',
    payload: { now: now() }
});

export const toggleAllowSignOutOnInactivity = (allow: boolean): Action => ({
    type: 'TOGGLE_ALLOW_SIGN_OUT_ON_INACTIVITY',
    payload: {
        allow
    }
});

export const changeFullScreen = (goFullScreen: boolean): Action => ({
    type: 'CHANGE_FULL_SCREEN',
    payload: {
        goFullScreen
    }
});

export const toggleFullScreen = (): Action => ({
  type: 'TOGGLE_FULL_SCREEN'
});
