// @flow
import React from 'react';
import Box from './Box';

const Divider = ({ color = 'divider', ...restProps }) => (
  <Box
    flexDirection="row"
    height="1px"
    backgroundColor={color}
    style={theme => ({
      marginTop: theme.typography.lineHeight() * 0.5,
      marginBottom: theme.typography.lineHeight() * 0.5,
    })}
    {...restProps}
  />
);

export default Divider;
