// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { OrderLine } from '../../common/types';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import Popup from '../components/Popup';
import Keyboard from '../../common/components/Keyboard';
import {
  addNote,
  toggleTextInput,
  clearSelectedOrderLines,
  setActiveRoomsFilterListener
} from '../../common/order/actions';
import TypewriterButtons, { NoEnterButtons as NoEnterTypewriterButtons }
  from '../../common/components/Keyboard/typewriter/Buttons';
import TypewriterDisplay from '../../common/components/Keyboard/typewriter/Display';
import OneLineTypewriterDisplay from '../../common/components/Keyboard/typewriter/OneLineDisplay';
import createTypewriterOperations from '../../common/components/Keyboard/typewriter/operations';
import { find, prop, propEq, sortBy } from 'rambda';
import InactiveBg from '../components/InactiveBg';
import Button from '../../common/components/Button';
import Text from '../../common/components/Text';
import Divider from '../../common/components/Divider';
import { getOrderLineMessages } from '../../common/order/selectors';
import orderMessages from '../../common/messages/order';
import { FormattedMessage } from 'react-intl';
import ScrollView from '../../common/components/ScrollView';

type PopupTextFormProps = {
  filterValue: string,
  updateFilter: Function,
  isTextPopupOpen: boolean | string | Object,
  orderLines: Array<OrderLine>,
  selectedOrderLines: Array<string>,
  messages: Array<Object>,
};

const additionalButtons = ({ onPress, disabled }, messages) => (
  <Box
    flexDirection="column"
    justifyContent="flex-start"
    flexShrink={0}
  >
    <Divider color="white" style={{ marginTop: 0, marginBottom: 10, opacity: 0.2, width: '100%' }} />
    <ScrollView maxHeight="calc(100vh - 500px)" overflowX="hidden">
      <Box
        flexDirection="row"
        justifyContent="flex-start"
        marginTop={0.15}
        flexShrink={0}
        flexWrap="wrap"
      >
        {messages && sortBy(prop('poradie'), messages).map(
          message =>
            <Button
              key={message.idSprava}
              onPress={() => onPress(`; ${(message.sprava).trim()}`)}
              disabled={disabled}
              backgroundColor="appBg"
              outline
              color="white"
              marginBottom={0.5}
              marginRight={0.5}
              paddingVertical={0.75}
              paddingHorizontal={1}
              flexShrink={0}
            >
              <Text color="white" bold>
                {(message.sprava).trim()}
              </Text>
            </Button>
        )}
      </Box>
    </ScrollView>
  </Box>);

class PopupTextForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      lastDisplayValue: this.getExistingNote()
    };
  }

  getExistingNote = () => {
    const { orderLines, selectedOrderLines } = this.props;

    return selectedOrderLines.length > 0
        ? selectedOrderLines.every((sol) => find(propEq('id', sol), orderLines).orderText === find(propEq('id', selectedOrderLines[0]), orderLines).orderText)
        ? find(propEq('id', selectedOrderLines[0]), orderLines).orderText
        : ''
        : '';
  };

  _onClose = () => {
    const { dispatch } = this.props;

    dispatch(toggleTextInput(false));
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setActiveRoomsFilterListener(false));

  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    setTimeout(() => {
      dispatch(setActiveRoomsFilterListener(true));
    }, 1000);
  }

  render() {
    const {
      filterValue,
      updateFilter,
      isTextPopupOpen,
      dispatch,
      selectedOrderLines,
      messages
     }: PopupTextFormProps = this.props;

    const isForInvoiceNo = typeof isTextPopupOpen === 'object';
    const isAnyFilterOpen = isTextPopupOpen === 'filter' || isTextPopupOpen === 'prgFilter';

    return (
      <InactiveBg onClose={this._onClose}>
        <Popup onClose={this._onClose}>
          <Box alignItems="center">
            {isForInvoiceNo &&
              <Heading scale={4} alignSelf="flex-start" color="white" style={{ marginTop: 0, marginBottom: 10 }}>
                <FormattedMessage {...orderMessages.orderPopupTextFormNumber} />
              </Heading>}
            <Keyboard
              style={theme => ({
                width: theme.typography.lineHeight(3) * 1.75 * 11 + theme.typography.lineHeight() * 7.5
              })}
              createOperations={createTypewriterOperations}
              Buttons={isAnyFilterOpen
                ? NoEnterTypewriterButtons
                : TypewriterButtons}
              Display={isAnyFilterOpen ? OneLineTypewriterDisplay : TypewriterDisplay}
              altPressed={isForInvoiceNo}
              additionalButtons={(!isAnyFilterOpen && !isForInvoiceNo)
                ? props => additionalButtons(props, messages)
                : () => null}
              onLastKey={key => {
                if (key === 'send') {
                  const { lastDisplayValue } = this.state;

                  if (isForInvoiceNo) {
                    dispatch(isTextPopupOpen.dispatchLoad(lastDisplayValue));
                  } else if (!isAnyFilterOpen) {
                    dispatch(addNote(selectedOrderLines, lastDisplayValue, this.getExistingNote()));
                    dispatch(clearSelectedOrderLines());
                  }
                  this._onClose();
                }
              }}
              onDisplayValue={val => {
                this.setState({ lastDisplayValue: val });
                if (isAnyFilterOpen) {
                  updateFilter(val);
                }
              }}
              defaultValue={isAnyFilterOpen
                ? filterValue
                : isForInvoiceNo
                  ? ''
                  : this.getExistingNote()
              }
            />
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default connect(
  (state) => ({
    isTextPopupOpen: state.orders.active.isTextPopupOpen,
    selectedOrderLines: state.orders.selectedOrderLines,
    orderLines: state.orders.orderLines,
    messages: getOrderLineMessages(state)
  })
)(PopupTextForm);
