// @flow
import React from 'react';
import debounce from 'debounce';

const withLongPress = (delay: number = 300) =>
  (Component) => class extends React.PureComponent {
    onMouseUp = e => {
      clearTimeout(this.pressTimer);
      const { onPress } = this.props;

      if (onPress && !this.longClickActionDidFire) {
        onPress(e);
      }
    };

    onMouseDown = e => {
      const { onLongPress } = this.props;
      this.longClickActionDidFire = false;

      this.pressTimer = setTimeout(() => {
        const { disabled } = this.props;
        if (!disabled) {
          onLongPress(e);
          this.longClickActionDidFire = true;
        }
      }, delay);
    };

    debouncedOnMouseDown = debounce(this.onMouseDown, delay, true);

    debouncedOnMouseUp = debounce(this.onMouseUp, delay, true);

    render() {
      const { onLongPress, onPress, ...restProps } = this.props;

      return onLongPress
        ? <Component
          onTouchStart={this.debouncedOnMouseDown}
          // this is disabled, b/c on touchscreens the event fires twice (touch and then mouse) (with a little time gap) so it makes mess (i.e. closing InactiveBg that just opened)
          // onTouchEnd={this.debouncedOnMouseUp}
          onMouseDown={this.debouncedOnMouseDown}
          onMouseUp={this.debouncedOnMouseUp}
          {...restProps}
        />
        : <Component onPress={onPress} {...restProps} />;
    }
  };

export default withLongPress;
