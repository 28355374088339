// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  serviceAreasDepositCompleted: {
    defaultMessage: 'Vklad prebehol úspešne',
    id: 'serviceAreas.depositCompleted'
  },
  serviceAreasWithdrawalCompleted: {
    defaultMessage: 'Výber prebehol úspešne',
    id: 'serviceAreas.withdrawalCompleted'
  },
  serviceAreasDeposit: {
    defaultMessage: 'Vklad',
    id: 'serviceAreas.deposit'
  },
  serviceAreasWithdrawal: {
    defaultMessage: 'Výber',
    id: 'serviceAreas.withdrawal'
  },
  serviceAreasTablesGridAccessDenied: {
    defaultMessage: 'Nemáte právo na čítanie stolov',
    id: 'serviceAreas.tablesGrid.accessDenied'
  },
  serviceAreasOpenedTables: {
    defaultMessage: 'Otvorené stoly',
    id: 'serviceAreas.openedTables'
  },
  serviceAreasReverted: {
    defaultMessage: 'Položka účtenky stornovaná a navrátená na stôl',
    id: 'serviceAreas.reverted'
  },
  serviceAreasRevertError: {
    defaultMessage: 'Error pri storne účtenky a návrate na stôl:',
    id: 'serviceAreas.revertError'
  },
  serviceAreaPaymentType: {
    defaultMessage: 'Spôsob platby',
    id: 'serviceAreas.paymentType'
  },
  serviceAreaPrinterSelection: {
    defaultMessage: 'Výber tlačiarne',
    id: 'serviceAreas.printerSelection'
  },
  serviceAreasNoRoomsError: {
    defaultMessage: 'Nie sú definované žiadne miestnosti.',
    id: 'serviceAreas.noRoomsError'
  },
  serviceAreasOneTimeTableName: {
    defaultMessage: 'Názov stola',
    id: 'serviceAreas.onetimeTable.name'
  },
  selectServiceArea: {
    defaultMessage: 'Vyberte miestnosť',
    id: 'serviceAreas.selectServiceArea'
  },
});
