// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import type { State } from '../../common/types';
import PrgDotaz from '../../common/payment/prgDotazy/PrgDotaz';
import { checkIfIsBar } from '../../common/payment/utils';
import { setQuickPaymentMediaId, toggleQuickPaymentLoading } from '../../common/order/actions';
import { confirmOrder } from '../../common/order/utils';
import { undeletedOpenTablesSelector } from '../../common/tables/selectors';

class PrgDotazWrapper extends React.PureComponent {
  quickPayPrgDotaz = (prgDotaz, { id: paymentMediaId }, prgData) => {
    const {
      dispatch, activeOpenTableId, openTables, subTables, orderLines
    } = this.props;
    const isBar = checkIfIsBar(activeOpenTableId, openTables);

    dispatch([
      setQuickPaymentMediaId(paymentMediaId),
      toggleQuickPaymentLoading(true),
      confirmOrder(
        activeOpenTableId,
        orderLines,
        subTables,
        {
          payAfterConfirmWith: paymentMediaId,
          payAfterConfirmPrgData: prgData,
          isBar,
          redirect: '/serviceAreas',
        }
      )
    ]);
  };

  render() {
    const { prgDotaz, prgDotazPayment, parameters } = this.props;

    if (!prgDotaz) return null;

    return (
      <PrgDotaz
        prgDotaz={prgDotaz}
        prgDotazPayment={prgDotazPayment}
        customPay={this.quickPayPrgDotaz}
        parameters={parameters}
      />
    );
  }
}

export default connect((state: State) => ({
  prgDotaz: state.payment.prgDotaz,
  prgDotazPayment: state.payment.prgDotazPayment,
  activeOpenTableId: state.tables.active.openTableId,
  orderLines: state.orders.orderLines,
  subTables: state.tables.subTables,
  openTables: undeletedOpenTablesSelector(state),
}))(PrgDotazWrapper);
