// @flow
import * as actions from './actions';
import { isReactNative } from '../app/detectPlatform';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { blitzSyncServerData } from './actions';
import { signOut } from '../auth/actions';

export const syncFromServerAfterUserSignIn = (action$, { getState }) =>
  action$
    .ofType('SIGN_IN_DONE')
    .filter(() => {
      const user = getState().auth.user;
      const token = user && user.token;

      return token;
    })
    .map(() => {
      const doBlitzSync = getState().app.firstSyncDone && !getState().auth.authNeedsFullSync;

      return doBlitzSync
        ? actions.blitzSyncServerData()
        : actions.syncServerData();
    });

const delay = isReactNative
  ? 10000 // 10 seconds
  : 10000; // 10 seconds;

export const repeatedlySyncAfterSync = (action$, { getState }) =>
  action$
    .ofType(
      'SYNC_SERVER_DATA_COMMIT',
      'BLITZ_SYNC_SERVER_DATA_COMMIT',
      'SYNC_SERVER_DATA_ROLLBACK',
      'BLITZ_SYNC_SERVER_DATA_ROLLBACK'
    )
    .filter(() => getState().auth.user)
    .debounceTime(delay)
    .map(() => actions.blitzSyncServerData());

export const syncAfterPriceListWasUpdated = (action$, { getState }) =>
  action$
    .ofType('ADMIN_PRICE_LIST_UPDATED')
    .filter(() => getState().auth.user)
    .map(() => actions.syncServerData());


const Store = isReactNative
  ? require('react-native-fs-store').default
  : null;

export const rehydrateFromFs = (action$: any) =>
  action$
    .ofType('REHYDRATE_FS')
    .mergeMap(() => forkJoin([
        new Store('items').getItem('items').catch(e => ({ errorOccurred: true, error: e })),
        new Store('categories').getItem('categories').catch(e => ({ errorOccurred: true, error: e })),
        new Store('employees').getItem('employees').catch(e => ({ errorOccurred: true, error: e })),
        new Store('menuItems').getItem('menuItems').catch(e => ({ errorOccurred: true, error: e })),
        new Store('parameters').getItem('parameters').catch(e => ({ errorOccurred: true, error: e })),
        new Store('payment').getItem('payment').catch(e => ({ errorOccurred: true, error: e })),
        new Store('serviceAreas').getItem('serviceAreas').catch(e => ({ errorOccurred: true, error: e })),
        new Store('tables').getItem('tableDefinitions').catch(e => ({ errorOccurred: true, error: e })),
        new Store('selectedPrinter').getItem('selectedPrinter').catch(e => ({ errorOccurred: true, error: e }))
    ]).map(results => {
        if (results.slice(0, -1).some(r => (r && r.errorOccurred) || r === undefined)) {
            return [
              signOut()
            ];
        }

        return [
          ({
            type: 'REHYDRATE_FS_COMMIT',
            payload: {
              items: results[0],
              categories: results[1],
              employees: results[2],
              menuItems: results[3],
              parameters: results[4],
              payment: results[5],
              serviceAreas: results[6],
              tableDefinitions: results[7],
              selectedPrinter: results[8]
            }
          }),
          blitzSyncServerData()
        ];
      }));
