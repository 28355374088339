// @flow
import 'velocity-animate';
//import 'velocity-animate/velocity.ui';
require('velocity-animate/velocity.ui.js');
import { velocityHelpers } from 'velocity-react';


const Animations = {
  pulse: velocityHelpers.registerEffect({
    calls: [
      [{ scaleX: 1.1, scaleY: 1.1 }, 0.50, { easing: 'easeInExpo' }],
      [{ scaleX: 1, scaleY: 1 }, 0.50]
    ]
  }),
  easeFadeIn: velocityHelpers.registerEffect({
    calls: [
      [{ opacity: [1, 0] }, 1, { easing: 'easeOutSine' }]
    ]
  })
};

export default Animations;
