// @flow
import React from 'react';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import Text from '../../common/components/Text';
import Permission from '../../common/permissions/Permission';
import Box from '../../common/components/Box';
import connect from 'react-redux/es/connect/connect';
import type { State } from '../../common/types';
import {findIndex, propEq, pluck, compose} from 'rambda';
import { VelocityTransitionGroup } from 'velocity-react';
import ScrollView from '../../common/components/ScrollView';
import { activateSubTable, addSubTable } from '../../common/tables/actions';
import { toggleOrderLineSelect, toggleSubTableMenu } from '../../common/order/actions';
import { activeOpenTableSelector } from '../../common/tables/selectors';
import SubTablesList, { subTablesWithPseudoAllSelector } from '../../common/components/order/SubTablesList';
import { formatSubTableName, getAllOrderLinesInFocus } from '../../common/order/utils';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/order';
import commonMessages from '../../common/messages/common';

class SubTables extends React.PureComponent {
  isFirstSubTable = () => {
    const { subTables, activeSubTableId } = this.props;
    const activeIndex = findIndex(propEq('id', activeSubTableId))(subTables);

    return activeIndex <= 0;
  };

  isLastSubTable = () => {
    const { subTables, activeSubTableId } = this.props;
    const activeIndex = findIndex(propEq('id', activeSubTableId))(subTables);

    return activeIndex >= subTables.length - 1;
  };

  setLowerSubTable = () => {
    const { dispatch, subTables, activeSubTableId } = this.props;
    const activeIndex = findIndex(propEq('id', activeSubTableId))(subTables);
    const newIndex = activeIndex > 0
      ? activeIndex - 1
      : activeIndex;

    dispatch(activateSubTable(subTables[newIndex].id));
  };

  setHigherSubTable = () => {
    const { dispatch, subTables, activeSubTableId } = this.props;
    const activeIndex = findIndex(propEq('id', activeSubTableId))(subTables);
    const newIndex = activeIndex < subTables.length - 1
      ? activeIndex + 1
      : activeIndex;

    dispatch(activateSubTable(subTables[newIndex].id));
  };

  toggleSubTableMenu = (e, specified) => {
    const { dispatch } = this.props;
    const isSubTableMenuOpen = specified === undefined ? !this.props.isSubTableMenuOpen : specified;

    dispatch(toggleSubTableMenu(isSubTableMenuOpen));
  };

  addSubTable = () => {
    const {
      activeOpenTable,
      isRelocatingToSubTable,
      isDeletingSubTable,
      dispatch,
    } = this.props;

    const actions = [
      addSubTable(activeOpenTable.id, {
        activateSubTable: !isRelocatingToSubTable && !isDeletingSubTable
      }),
    ];

    if (!isRelocatingToSubTable) {
      actions.push(toggleSubTableMenu(false));
    }

    dispatch(actions);
  };

  selectAllOrderLinesInFocus = () => {
    const { dispatch, subTables, orderLines, activeOpenTableId, activeSubTableId } = this.props;
    const focusedOrderLines = getAllOrderLinesInFocus(subTables, orderLines, activeOpenTableId, activeSubTableId);

    dispatch(toggleOrderLineSelect(pluck('id', focusedOrderLines), { forceSelect: true }));
  };

  render() {
    const {
      subTables,
      activeSubTableId,
      isRelocatingToSubTable,
      isRelocatingToFoodCourse,
      isDeletingSubTable,
      isSubTableMenuOpen,
      activeIsLimit,
      intl
    } = this.props;

    const subtableIndex = subTables.map(el => (el.id)).indexOf(activeSubTableId);
    const actualSubTable = subtableIndex <= 0
      ? intl.formatMessage(messages.orderSubTablesGuests)
      : formatSubTableName(subTables[subtableIndex].name, intl.formatMessage(commonMessages.orderGuest));

    return (
      <Box flexShrink={0}>
        <Box
          paddingTop={0.5}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          zIndex={7}
          flexShrink={0}
          backgroundColor="appBg"
        >
          <Button
            disabled={isRelocatingToSubTable || isRelocatingToFoodCourse || isDeletingSubTable || this.isFirstSubTable() || activeIsLimit}
            onPress={this.setLowerSubTable}
            rounded
            size={2}
            lineHeight="normal"
            overflow="hidden"
            position="absolute"
            left={0}
            alignItems="center"
            justifyContent="center"
          >
            <Icon color="white" name="navigation-left" scale={0} marginLeft={-0.1} />
          </Button>
          <Button
            disabled={isRelocatingToSubTable || isRelocatingToFoodCourse || isDeletingSubTable}
            onPress={this.selectAllOrderLinesInFocus}
            rounded
            size={2}
            lineHeight="normal"
            overflow="hidden"
            position="absolute"
            left={2.5}
            alignItems="center"
            justifyContent="center"
          >
            <Icon color="white" name="subitems" scale={0} marginLeft={-0.1} />
          </Button>
          <Button
            alignItems="center"
            marginTop={0.75}
            marginBottom={0.75}
            onPress={this.toggleSubTableMenu}
            disabled={isRelocatingToSubTable || isRelocatingToFoodCourse || isDeletingSubTable || activeIsLimit}
          >
            <Text color="white">
              {activeSubTableId ? actualSubTable : ''}
            </Text>
            <Icon
              name="navigation-top"
              color="iconGray"
              marginLeft={0.5}
              scale={1}
              style={() => (!isSubTableMenuOpen
                ? { transform: 'scaleY(-1)' }
                : null)}
            />
          </Button>
          <Permission name="subtable.add">
            <Button
              disabled={isRelocatingToFoodCourse || activeIsLimit}
              onPress={this.addSubTable}
              rounded
              size={2}
              lineHeight="normal"
              overflow="hidden"
              position="absolute"
              right={2.5}
              alignItems="center"
              justifyContent="center"
            >
              +
            </Button>
          </Permission>
          <Button
            disabled={isRelocatingToSubTable || isRelocatingToFoodCourse || isDeletingSubTable || this.isLastSubTable() || activeIsLimit}
            onPress={this.setHigherSubTable}
            rounded
            size={2}
            lineHeight="normal"
            overflow="hidden"
            position="absolute"
            right={0}
            alignItems="center"
            justifyContent="center"
          >
            <Icon color="white" name="navigation-right" scale={0} marginRight={-0.1} />
          </Button>
        </Box>

        <Box zIndex={5} flexShrink={0} backgroundColor="appBg" paddingBottom={0.5}>
          <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
            {isSubTableMenuOpen
              ? <div>
                <ScrollView maxHeight={18} paddingBottom="1px">
                  <SubTablesList />
                </ScrollView>
              </div>
              : null}
          </VelocityTransitionGroup>
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    subTables: subTablesWithPseudoAllSelector(state),
    activeSubTableId: state.tables.active.subTableId,
    activeOpenTableId: state.tables.active.openTableId,
    activeOpenTable: activeOpenTableSelector(state),
    isRelocatingToSubTable: state.orders.active.isRelocatingToSubTable,
    isRelocatingToFoodCourse: state.orders.active.isRelocatingToFoodCourse,
    isDeletingSubTable: state.orders.active.isDeletingSubTable,
    orderLines: state.orders.orderLines,
    selectedOrderLines: state.orders.selectedOrderLines,
    isSubTableMenuOpen: state.orders.active.isSubTableMenuOpen,
    activeIsLimit: state.serviceAreas.activeIsLimit
  })),
  injectIntl
)(SubTables);
