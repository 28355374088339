// @flow
import Fela from '../../common/components/FelaProvider';
import React from 'react';
import configureFela from '../configureFela';
import '../configureAnimations';
import { Provider as Redux } from 'react-redux';
import NetworkConnectivity from '../../common/components/NetworkConnectivity';

// Render button as div because button is not consistently rendered across
// browsers and it's hard and tricky to enforce the same look. Making button
// from regular div is much easier.
// developer.mozilla.org/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_button_role
// developer.mozilla.org/docs/Web/Accessibility/Keyboard-navigable_JavaScript_widgets
const DivButton = (
  props: {
    disabled?: boolean,
    onClick?: Function,
    style?: Object,
  },
) => (
  <div // eslint-disable-line jsx-a11y/no-static-element-interactions
    {...props}
    role="button"
    onKeyPress={e => {
      if (props.disabled) return;
      const isSpacebar = e.key === ' ';
      if (!isSpacebar) return;
      e.preventDefault();
      if (typeof props.onClick !== 'function') return;
      props.onClick(e);
    }}
    style={{
      ...(props.style || null),
      pointerEvents: props.disabled ? 'none' : 'auto'
    }}
    tabIndex={props.disabled ? -1 : 0}
  />
);

type BaseRootProps = {
  RenderComponent?: any,
  felaRenderer?: Object,
  store: Object,
};

class BaseRoot extends React.PureComponent {
  props: BaseRootProps;

  render() {
    const {
      store,
      felaRenderer = configureFela(),
      RenderComponent
    } = this.props;

    return (
      <Redux store={store}>
        <Fela
          Button={DivButton}
          Image="img"
          Text="span"
          TextInput={({ nativeRef, ...props }) => <input {...props} ref={nativeRef} />}
          TextInputMultiLine={({ nativeRef, ...props }) => <textarea {...props} ref={nativeRef} />}
          View="div"
          renderer={felaRenderer}
        >
          <NetworkConnectivity RenderComponent={RenderComponent} />
        </Fela>
      </Redux>
    );
  }
}

export default BaseRoot;
