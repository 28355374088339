// @flow
import type { State } from '../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../common/components/Box';
import { find, propEq, compose } from 'rambda';
import Sidebar from '../../components/Sidebar';
import {
  toggleIsChangingPriceLevel,
} from '../../../common/order/actions';
import Text from '../../../common/components/Text';
import Button from '../../../common/components/Button';
import Icon from '../../../common/components/Icon';
import { signOut } from '../../../common/auth/actions';
import { findParamValue } from '../../../common/parameters/utils';
import { activeOpenTableSelector } from '../../../common/tables/selectors';
import Permission from '../../../common/permissions/Permission';
import { myNameSelector } from '../../../common/auth/selectors';
import guard from '../../../common/components/hoc/guard';
import invariant from 'invariant';
import { displayEllipsis } from '../../themes/utils';

class OrderSidebarHeader extends React.Component {
  render() {
    const {
      dispatch,
      userName,
      priceLevels,
      openTable,
      activePriceLevelId,
      parameters,
    } = this.props;

    // FIXME: this fails if there is no price level with id = activePriceLevelId
    // since activePriceLevelId defaults to 1, this can fail pretty easily
    // - initial activePriceLevelId should be null and we should handle that case here (and anywhere else)
    // - items reducer should set activePriceLevelId to the id of first retrieved price level
    const priceLevel = find(propEq('id', activePriceLevelId), priceLevels);
    const priceLevelName = priceLevel.name || '___';

    const paramIsPriceLevelChangeable = (openTable.bar || openTable.barCash)
      ? findParamValue('K32.is_chlbar', parameters)
      : true;

    return (
      <Sidebar paddingVertical={0.5} zIndex={100}>
        <Box zIndex={10} paddingHorizontal={1} flexDirection="row" justifyContent="space-between" flex={1}>
          <Permission name="pricelevel.read">
            <Button
              onClick={() => paramIsPriceLevelChangeable
                ? dispatch(toggleIsChangingPriceLevel('activate'))
                : null}
            >
              <Text bold color="white" flexDirection="row">
                <Icon name="price-level" color="white" bold marginRight={0.25} scale={1} />
                {priceLevelName}
              </Text>
            </Button>
          </Permission>
          <Button flexDirection="row" onPress={() => dispatch(signOut())} maxWidth="50%" justifyContent="flex-start">
            <Text bold color="white" flexDirection="row" width="100%">
              <Icon name="user" color="white" bold marginRight={0.25} scale={1} />
              <span style={displayEllipsis}>{userName}</span>
            </Text>
          </Button>
        </Box>
      </Sidebar>
    );
  }
}

export default compose(
  connect((state: State) => ({
    priceLevels: state.items.priceLevels,
    activePriceLevelId: state.items.activePriceLevelId,
    userName: myNameSelector(state),
    parameters: state.parameters.parameters,
    openTable: activeOpenTableSelector(state)
  })),
  guard(({ openTable }) =>
    invariant(openTable, 'missing openTable in OrderSidebarHeader'))
)(OrderSidebarHeader);
