// @flow
import fetch from '../fetch';
import { signRequest } from '../fetch/signature';
import { setDefaultHeaders } from '../fetch/headers';
import { apiEndpoint, getApiUrl } from './index';
import moment from 'moment';

export const verifyApi = (id: string, password: string, sessionId: string): Promise => {
  setDefaultHeaders();

  return signRequest(password, apiEndpoint('v1/auth/signature')).then(signature =>
    fetch(apiEndpoint('v1/auth/signature'), {
      method: 'POST',
      baseURL: getApiUrl(),
      headers: {
        'X-Alto-Kasa': id,
        'X-Alto-Signature': signature,
        'X-Alto-Session': sessionId,
        'X-Alto-Time': moment().format('YYYY-MM-DDTHH:mm:ss.sss')
      }
    }));
};

export const signInUser = (password: string, idKas: string): Promise =>
  fetch(apiEndpoint('v1/auth/token'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      password,
      id_kas: idKas
    }
  });

export const signOutUser = (token: string): Promise =>
  fetch(apiEndpoint('v1/login2'), {
    method: 'DELETE',
    baseURL: getApiUrl(),
    body: {
      token
    }
  });
