// @flow
import React from 'react';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import Box from '../../components/Box';
import Heading from '../../components/Heading';
import {
 compose, pathOr, join, filter, identity
} from 'rambda';
import remoteLoad from '../../components/hoc/remoteLoad';
import api from '../../lib/api/index';
import Text from '../../components/Text';
import ScrollView from '../../components/ScrollView';
import Spinner from '../../components/Spinner';
import { isReactNative } from '../../app/detectPlatform';
import {injectIntl} from 'react-intl';
import paymentMessages from '../../messages/payment';

class Partners extends React.PureComponent {
  render() {
    const {
      remotePayload,
      remoteError,
      remoteIsLoading,
      newPartner,
      selectPartner,
      disabled,
      intl
    } = this.props;

    const partners = pathOr([], ['result', 'partners'], remotePayload);

    const inner = remoteIsLoading
      ? <Spinner />
      : (<React.Fragment>
          <ScrollView>
            {partners.map(partner => {
              const title = join(
                ', ',
                filter(identity, [partner.hjmeno, partner.adresa1, partner.adresa2, partner.adresa3])
              );

              return (
                <Button
                  key={partner.idriadok}
                  marginHorizontal={1}
                  backgroundColor="white"
                  paddingVertical={0.5}
                  paddingHorizontal={1}
                  marginBottom={0.5}
                  justifyContent="flex-start"
                  onPress={() => selectPartner(partner.idriadok)}
                  disabled={disabled}
                  flexShrink={0}
                >
                  {title}
                </Button>
              );
            })}
          </ScrollView>

          <Button
            onPress={newPartner}
            outline
            backgroundColor="teal"
            marginTop={1}
            marginHorizontal={1}
            paddingVertical={0.5}
            color="white"
            disabled={disabled}
            flexShrink={0}
          >
            {intl.formatMessage(paymentMessages.paymentNewPartner)}
          </Button>
        </React.Fragment>);

    return (
      <Box
        width={isReactNative ? null : 20}
        maxHeight={isReactNative ? '100%' : '70vh'}
        marginHorizontal={-1}
        flex={1}
      >
        <Heading scale={2} color="white" marginHorizontal={1}>
          {intl.formatMessage(paymentMessages.paymentBankPartner)}
        </Heading>

        {inner}

        {remoteError ? <Text color="error" marginVertical={1}>{remoteError}</Text> : null}
      </Box>
    );
  }
}

export default compose(
  connect(),
  remoteLoad(api.payment.getParters),
  injectIntl
)(Partners);
