// @flow

// eslint-disable-next-line import/no-mutable-exports
let provider;

if (process.env.IS_REACT_NATIVE === '1') {
  provider = require('react-native-offline').withNetworkConnectivity;
} else {
  provider = require('../../../browser/components/hoc/networkConnectivityProvider').default;
}

export default provider;

