// @flow
import type { State } from '../../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../../common/components/Box';
import {addError, toggleTextInput} from '../../../../common/order/actions';
import { setPrgFilterValue } from '../../../../common/payment/actions';
import Button from '../../../../common/components/Button';
import Icon from '../../../../common/components/Icon';
import TextInput from '../../../../common/components/TextInput';
import api from '../../../../common/lib/api/index';
import { RequestOrderError } from '../../../../common/lib/fetch/orderKeeper';
import { excludedMetaKeys, replaceSKKeyCharsWithNums } from '../../../../common/lib/utils';

class RoomsFilter extends React.Component {
  state = {
    valuesFromInputListener: ''
  };

  clearFilter = () => {
    const { dispatch } = this.props;

    dispatch(setPrgFilterValue(''));
  };

  handleCardReading = event => {
    const { dispatch, onConfirm, hotelId, activeRoomsFilterListener } = this.props;
    const { valuesFromInputListener } = this.state;

    if (!activeRoomsFilterListener){
      this.setState({ valuesFromInputListener: '' });
      return;
    }

    if (event.keyCode === 13) {
      api.hores.checkCard(hotelId, valuesFromInputListener)
        .then(({ body: { room_id: roomId, account_id: accountId, room_code: roomCode } }) => {
          if (roomId && accountId && roomCode) {
            onConfirm(roomId, accountId, roomCode);
          }
        }).catch(e => {
          if (e instanceof RequestOrderError) return;

          dispatch(addError(e));
        });
      this.setState({ valuesFromInputListener: '' });
    } else if (excludedMetaKeys(event)) {
      this.setState({
        valuesFromInputListener: `${valuesFromInputListener}${replaceSKKeyCharsWithNums(event.key)}`
      });
    }
  };

  // TODO react antipattern - will be moved to parent element or hoc
  // - sofar I have found 2 good react libs (react-key-handler, react-keyboard-event-handler),
  // which where not enough for what we need
  // - react-keyboard-event-handler look awesome, but does not catch the events in some cases (i.e. just after opening popup)
  componentDidMount() {
    document.addEventListener('keyup', this.handleCardReading);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleCardReading);
  }

  render() {
    const { dispatch, filterValue } = this.props;

    return (
      <Box width="100%" justifyContent="center" marginBottom={1} paddingHorizontal={1}>
        <Box
          backgroundColor="appBg"
          paddingHorizontal={1}
          paddingVertical={0.25}
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="left"
        >
          <Button flexDirection="row" onPress={() => dispatch(toggleTextInput('prgFilter'))} alignItems="center">
            <Icon name="search" color="white" marginRight={0.5} />
          </Button>
          <TextInput
            height={1.25}
            width="86%"
            color="white"
            backgroundColor="appBg"
            value={filterValue}
            onClick={(e) => {
              dispatch(toggleTextInput('prgFilter'));
              e.target.blur();
            }}
            readOnly
          />
          <Button onPress={this.clearFilter} alignItems="center" marginLeft="auto">
            <Icon color="white" name="delete" scale={1} />
          </Button>
        </Box>
      </Box>
    );
  }
}

export default connect((state: State) => ({
  filterValue: state.payment.prgFilterValue,
  activeRoomsFilterListener: state.orders.active.activeRoomsFilterListener
}))(RoomsFilter);
