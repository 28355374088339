// @flow
import React from 'react';
import type { OpenTable } from '../../types';
import { belongsOpenTableToEmployee, hasOpenTableOrderLine } from '../../tables/utils';
import { find, pluck, propEq, reject, all } from 'rambda';
import { isEmpty, isNil } from 'ramda';
import { connect } from 'react-redux';
import { activeOpenTableSelector } from '../../tables/selectors';
import { deleteOpenTable } from '../../tables/actions';
import { getAllOrderLinesOfOpenTable } from '../utils';
import { deleteOrderLines } from '../actions';
import { activateArea } from '../../serviceAreas/actions';

const exitOrderPage = OrderPage => connect(
  (state: State) => ({
    _serviceAreas: state.serviceAreas.serviceAreas,
    _user: state.auth.user,
    _orderLines: state.orders.orderLines,
    _subTables: state.tables.subTables,
    _openTable: activeOpenTableSelector(state),
    _activeServiceAreaId: state.serviceAreas.activeAreaId,
  }),
  dispatch => ({
    _resetArea: id => dispatch(activateArea(id))
  })
)(class extends React.PureComponent {
    _belongsToMe = (openTable: OpenTable) => {
      const { _user } = this.props;
      return belongsOpenTableToEmployee(openTable, _user.id);
    };

    _hasOrderLines = (openTable: OpenTable) => {
      const {
        _orderLines: allOrderLines,
        _subTables: allSubTables,
      } = this.props;

      // TODO unify _isDeleted && _delete
      const orderLines = reject(orderLine => orderLine._isDeleted && !orderLine._local,
        allOrderLines);
      const subTables = reject(subTable => subTable._delete && !subTable._local,
        allSubTables);

      return hasOpenTableOrderLine(openTable, orderLines, subTables);
    };

    _hasLocalOrderLines = (openTable: OpenTable) => {
      const {
        _orderLines: allOrderLines,
        _subTables: allSubTables,
      } = this.props;

      const orderLines = reject(orderLine => (!orderLine._local || orderLine.isSentToKitchen),
        allOrderLines);
      const subTables = reject(subTable => subTable._delete && !subTable._local,
        allSubTables);

      return hasOpenTableOrderLine(openTable, orderLines, subTables);
    };

    _isBar = (openTable: OpenTable) =>
      openTable.bar || openTable.barCash;

    _deleteBar = (openTable: OpenTable) => {
      const {
        dispatch,
        _subTables: subTables,
        _orderLines: orderLines
      } = this.props;

      const orderLinesForOpenTable = getAllOrderLinesOfOpenTable(subTables, orderLines,
        openTable.id);
      const hasOnlyUnconfirmedOrderLines = all(orderLine => !orderLine.isSentToKitchen,
        orderLinesForOpenTable);

      if (orderLinesForOpenTable.length && !hasOnlyUnconfirmedOrderLines) {
        dispatch(deleteOrderLines(pluck('id', orderLinesForOpenTable), { deleteOpenTableId: openTable.id }));
      } else {
        dispatch(deleteOpenTable(openTable.id, openTable.tableDefinitionId));
      }
    };

    _deleteOpenTable = (openTable: OpenTable, withTableDefinition: boolean) => {
        const { dispatch } = this.props;

        dispatch(deleteOpenTable(openTable.id,
          withTableDefinition ? openTable.tableDefinitionId : undefined
        ));
      };

    onExitPage = ({ redirect, promptExit, promptExit2, activeServiceAreaId }, ignorePrompt, ignorePrompt2) => {
      const {
        _resetArea: resetArea,
        _openTable: openTable,
        _serviceAreas: serviceAreas
      } = this.props;

      if (isEmpty(openTable) || isNil(openTable)) return;

      if (openTable.serviceAreaId !== activeServiceAreaId) {
        resetArea(openTable.serviceAreaId);
      }

      if (!this._belongsToMe(openTable)) {
        redirect();
        return;
      }

      if (this._isBar(openTable)) {
        if (this._hasOrderLines(openTable) && !ignorePrompt) {
          promptExit();
        } else {
          this._deleteBar(openTable);
          redirect();
        }
      } else if (this._hasLocalOrderLines(openTable) && !ignorePrompt2) {
        promptExit2();
      } else {
        redirect();
        if (!this._hasOrderLines(openTable)) {
          const area = find(propEq('id', openTable.serviceAreaId), serviceAreas);
          const withTableDefinition = area.isVirtual;

          this._deleteOpenTable(openTable, withTableDefinition);
        }
      }
    };

    render() {
      const {
        _resetArea,
        _serviceAreas,
        _user,
        _orderLines,
        _subTables,
        _openTable,
        _activeServiceAreaId,
        ...props
      } = this.props;
      return <OrderPage {...props} onExitPage={this.onExitPage} />;
    }
  }
);

export default exitOrderPage;
