// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  openGroup: {
    defaultMessage: 'Otvoriť skupinu',
    id: 'order.contextMenus.openGroup'
  },
  closeGroup: {
    defaultMessage: 'Zavrieť skupinu',
    id: 'order.contextMenus.closeGroup'
  },
  addNote: {
    defaultMessage: 'Poznámka',
    id: 'order.contextMenus.addNote'
  },
  addOne: {
    defaultMessage: 'Pridať',
    id: 'order.contextMenus.addOne'
  },
  addMultipleTimes: {
    defaultMessage: 'Podržte pre nastavenie počtu',
    id: 'order.contextMenus.addMultipleTimes'
  },
  closeMenu: {
    defaultMessage: 'Zavrieť menu',
    id: 'order.contextMenus.closeMenu'
  },
  deleteSelected: {
    defaultMessage: 'Zmazať vybrané',
    id: 'order.contextMenus.deleteSelected'
  },
  deleteSelectedMultipleTimes: {
    defaultMessage: 'Podržte pre nastavenie počtu',
    id: 'order.contextMenus.deleteSelectedMultipleTimes'
  },
  selectCount: {
    defaultMessage: 'Vybrať počet',
    id: 'order.contextMenus.selectCount'
  },
  addPortion: {
    defaultMessage: 'Pridať porciu',
    id: 'order.contextMenus.addPortion'
  },
  quantityUnit: {
    defaultMessage: '{quantity} ks',
    id: 'order.contextMenus.quantityUnit'
  },
  divideGroup: {
    defaultMessage: 'Rozdeliť',
    id: 'order.contextMenus.divideGroup'
  },
  relocateToFoodCourse: {
    defaultMessage: 'Presunúť na chod',
    id: 'order.contextMenus.relocateToFoodCourse'
  },
  relocateToSubTable: {
    defaultMessage: 'Presunúť na hosťa',
    id: 'order.contextMenus.relocateToSubTable'
  },
  relocateToOpenTable: {
    defaultMessage: 'Presunúť na stôl',
    id: 'order.contextMenus.relocateToOpenTable'
  },
  relocateToOpenTableStop: {
    defaultMessage: 'Zrušiť presúvanie',
    id: 'order.contextMenus.relocateToOpenTableStop'
  },
  changePriceLevel: {
    defaultMessage: 'Zmeniť cenovú hladinu',
    id: 'order.contextMenus.changePriceLevel'
  },
  choosePriceLevel: {
    defaultMessage: 'Vyberte cenovú hladinu',
    id: 'order.contextMenus.choosePriceLevel'
  },
  changeItemSize: {
    defaultMessage: 'Zmeniť veľkosť porcie',
    id: 'order.contextMenus.changeItemSize'
  },
  choosePortion: {
    defaultMessage: 'Vyberte veľkosť porcie',
    id: 'order.contextMenus.choosePortion'
  },
  chooseSubTable: {
    defaultMessage: 'Vyberte hosťa, na ktorého chcete presunúť položky',
    id: 'order.notifications.chooseSubTable'
  },
  chooseFoodCourse: {
    defaultMessage: 'Vyberte chod, na ktorý chcete presunúť položky',
    id: 'order.notifications.chooseFoodCourse'
  },
  chooseTable: {
    defaultMessage: 'Vyberte stôl, na ktorý chcete presunúť položky',
    id: 'order.notifications.chooseTable'
  },
  close: {
    defaultMessage: 'Zavrieť',
    id: 'order.page.close'
  },
  pageChoosePriceLevel: {
    defaultMessage: 'Vyberte cenovú hladinu',
    id: 'order.page.choosePriceLevel'
  },
  pageChangeItemSize: {
    defaultMessage: 'Zmeniť veľkosť porcie',
    id: 'order.contextMenus.changeItemSize'
  },
  pageChoosePortion: {
    defaultMessage: 'Vyberte veľkosť porcie',
    id: 'order.contextMenus.choosePortion'
  },
  chooseWeight: {
    defaultMessage: 'Zadajte váhu',
    id: 'order.contextMenus.chooseWeight'
  },
  choosePrice: {
    defaultMessage: 'Zadajte cenu',
    id: 'order.contextMenus.choosePrice'
  },
  chooseQuantity: {
    defaultMessage: 'Zadajte množstvo',
    id: 'order.contextMenus.chooseQuantity'
  },
  itemPortion1: {
    defaultMessage: 'Celá porcia',
    id: 'order.contextMenus.itemPortion1'
  },
  itemPortion2: {
    defaultMessage: '½ porcia',
    id: 'order.contextMenus.itemPortion2'
  },
  itemPortion3: {
    defaultMessage: '⅓ porcia',
    id: 'order.contextMenus.itemPortion3'
  },
  itemPortion4: {
    defaultMessage: '¼ porcia',
    id: 'order.contextMenus.itemPortion4'
  },
  orderGenericCancelOrderHeader: {
    defaultMessage: 'Zrušenie objednávky',
    id: 'order.generic.cancelOrder.header'
  },
  orderGenericCancelOrderDescription: {
    defaultMessage: 'Objednávka nebude uložená, naozaj chcete ísť späť?',
    id: 'order.generic.cancelOrder.description'
  },
  orderGenericCancelOrderConfirm: {
    defaultMessage: 'Áno',
    id: 'order.generic.cancelOrder.confirm'
  },
  orderGenericCancelOrderCancel: {
    defaultMessage: 'Zostať',
    id: 'order.generic.cancelOrder.cancel'
  },
  orderGenericLocalItemsRemoveHeader: {
    defaultMessage: 'Vymazanie lokálnych položiek',
    id: 'order.generic.localItems.remove.header'
  },
  orderGenericLocalItemsRemoveDescription: {
    defaultMessage: 'Vymazať lokálne položky?',
    id: 'order.generic.localItems.remove.description'
  },
  orderGenericLocalItemsRemoveConfirm: {
    defaultMessage: 'Áno',
    id: 'order.generic.localItems.remove.confirm'
  },
  orderGenericLocalItemsRemoveCancel: {
    defaultMessage: 'Nie',
    id: 'order.generic.localItems.remove.cancel'
  },
  orderGenericCloseTable: {
    defaultMessage: 'Zavrieť stôl',
    id: 'order.generic.closeTable'
  },
  orderRelocatingRooms: {
    defaultMessage: 'Miestnosti',
    id: 'order.relocating.rooms'
  },
  orderRelocatingCancel: {
    defaultMessage: 'Zrušiť presun',
    id: 'order.relocating.cancel'
  },
  orderRelocatingChooseRoom: {
    defaultMessage: 'Vyberte miestnosť',
    id: 'order.relocating.chooseRoom'
  },
  orderAvailabilityBlockerHeader: {
    defaultMessage: 'Tovar nie je dostupný',
    id: 'order.availabilityBlocker.header'
  },
  orderAvailabilityBlockerAvailability: {
    defaultMessage: 'Dostupnosť',
    id: 'order.availabilityBlocker.availability'
  },
  orderAvailabilityBlockerConfirmChange: {
    defaultMessage: 'Potvrďte alebo zmenťe počet.',
    id: 'order.availabilityBlocker.confirmChange'
  },
  orderChosenItems: {
    defaultMessage: 'Vybrané položky',
    id: 'order.chosenItems'
  },
  orderPageCount: {
    defaultMessage: 'Počet v ks',
    id: 'order.page.count'
  },
  orderPageError: {
    defaultMessage: 'Chyba bločku - dokončiť bloček',
    id: 'order.page.error'
  },
  orderFastFoodDailyMenu: {
    defaultMessage: 'Denné menu',
    id: 'order.fastFood.dailyMenu'
  },
  orderFastFoodMenuItem: {
    defaultMessage: 'menu položka',
    id: 'order.fastFood.menuItem'
  },
  orderFastFoodNotAvailable: {
    defaultMessage: 'Nedostupné',
    id: 'order.fastFood.notAvailable'
  },
  orderFastFoodWrongConfiguration: {
    defaultMessage: 'Produkt je zle nakonfigurovaný',
    id: 'order.fastFood.wrongConfiguration'
  },
  orderFastFoodNoItems: {
    defaultMessage: 'Menu nemá žiadne položky',
    id: 'order.fastFood.noItems'
  },
  orderPriceOrQuantityPrice: {
    defaultMessage: 'Zadajte cenu',
    id: 'order.priceOrQuantity.price'
  },
  orderPriceOrQuantityCount: {
    defaultMessage: 'Zadajte počet',
    id: 'order.priceOrQuantity.count'
  },
  orderPriceOrQuantityAmount: {
    defaultMessage: 'Zadajte množstvo',
    id: 'order.priceOrQuantity.amount'
  },
  orderPriceOrQuantityMaxDelete: {
    defaultMessage: 'max. na vymazanie',
    id: 'order.priceOrQuantity.maxDelete'
  },
  orderPriceOrQuantityKs: {
    defaultMessage: 'ks',
    id: 'order.priceOrQuantity.ks'
  },
  orderPriceOrQuantityMaxSelected: {
    defaultMessage: 'max. na označenie',
    id: 'order.priceOrQuantity.maxSelected'
  },
  orderPopupTextFormNumber: {
    defaultMessage: 'Zadajte číslo faktúry',
    id: 'order.popupTextForm.number'
  },
  orderPopupWeightFormPlate: {
    defaultMessage: 'Tanier',
    id: 'order.popupWeightForm.plate'
  },
  orderPopupWeightFormKg: {
    defaultMessage: 'kg',
    id: 'order.popupWeightForm.kg'
  },
  orderPopupWeightFormWithoutPlate: {
    defaultMessage: 'Bez taniera',
    id: 'order.popupWeightForm.withoutPlate'
  },
  orderPopupWeightFormWeightItem: {
    defaultMessage: 'Váženie tovaru',
    id: 'order.popupWeightForm.weightItem'
  },
  orderPopupWeightFormUnitPrice: {
    defaultMessage: 'Jednotková cena',
    id: 'order.popupWeightForm.unitPrice'
  },
  orderPopupWeightFormActualPrice: {
    defaultMessage: 'Aktuálna cena',
    id: 'order.popupWeightForm.actualPrice'
  },
  orderPopupWeightFormAvailability: {
    defaultMessage: 'Dostupnosť',
    id: 'order.popupWeightForm.availability'
  },
  orderPopupWeightFormNotAvailable: {
    defaultMessage: 'Požadované množstvo nie je dostupné',
    id: 'order.popupWeightForm.notAvailable'
  },
  orderSidebarHeader: {
    defaultMessage: 'Vymazanie položiek',
    id: 'order.sidebar.header'
  },
  orderSidebarDescription: {
    defaultMessage: 'Označené položky budú odstránené, chcete ich naozaj vymazať?',
    id: 'order.sidebar.description'
  },
  orderSidebarConfirm: {
    defaultMessage: 'Áno',
    id: 'order.sidebar.confirm'
  },
  orderSidebarCancel: {
    defaultMessage: 'Zrušiť',
    id: 'order.sidebar.cancel'
  },
  orderSidebarFrom: {
    defaultMessage: 'z',
    id: 'order.sidebar.from'
  },
  orderSidebarPayCash: {
    defaultMessage: 'Zaplatiť v hotovosti',
    id: 'order.sidebar.payCash'
  },
  orderSidebarPaySelected: {
    defaultMessage: 'Zaplatiť vybrané',
    id: 'order.sidebar.paySelected'
  },
  orderSidebarPayAll: {
    defaultMessage: 'Zaplatiť všetko',
    id: 'order.sidebar.payAll'
  },
  orderSidebarCaptionConfirm: {
    defaultMessage: 'Potvrdiť',
    id: 'order.sidebar.caption.confirm'
  },
  orderSubTablesGuests: {
    defaultMessage: 'Všetci hostia',
    id: 'order.subTables.guests'
  },
  orderAll: {
    defaultMessage: 'Všetci',
    id: 'order.all'
  },
  orderNewGuest: {
    defaultMessage: 'Nový hosť',
    id: 'order.newGuest'
  },
  orderNoSelectedItems: {
    defaultMessage: 'Žiadne položky pre vybraný chod',
    id: 'order.noSelectedItems'
  },
  orderMenuItemsMainCategory: {
    defaultMessage: 'Hlavná',
    id: 'order.menuItems.mainCategory'
  },
  orderOrderPageNoAccess: {
    defaultMessage: 'Nemáte právo na prístup k objednávkam',
    id: 'order.orderPage.noAccess'
  },
  orderPopupTextFormOk: {
    defaultMessage: 'OK',
    id: 'order.popupTextForm.ok'
  },
  orderBack: {
    defaultMessage: 'Vrátiť sa',
    id: 'order.back'
  },
  orderWeightFormConfirm: {
    defaultMessage: 'Potvrdiť váhu',
    id: 'order.weightForm.confirm'
  },
  orderConfirmingItems: {
    defaultMessage: 'Potvrdzujú sa položky',
    id: 'order.confirmingItems'
  },
});
