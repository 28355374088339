// @flow
import React from 'react';
import Popup from '../components/Popup';
import InactiveBg from '../components/InactiveBg';
import Keyboard from '../../common/components/Keyboard/index';
import Heading from '../../common/components/Heading';
import DepositDisplay from '../../common/components/Keyboard/deposit/Display';
import DepositButtons from '../../common/components/Keyboard/deposit/Buttons';
import createDepositOperations from '../../common/components/Keyboard/deposit/operations';
import Notification from '../components/Notification';
import Box from '../../common/components/Box';
import ContextMenuButton from '../../common/components/ContextMenuButton';
import { connect } from 'react-redux';
import type { State } from '../../common/types';
import {compose, filter, prop, sortBy} from 'rambda';
import { leftJoin } from '../../common/lib/moreRambda';
import Text from '../../common/components/Text';
import theme from '../../common/themes/theme';
import { deposit, withdrawal } from '../../common/lib/api/payment';
import ScrollView from '../../common/components/ScrollView';
import Spinner from '../../common/components/Spinner';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/serviceAreas';
import { getCurrencySymbol } from '../../common/parameters/service';
import Icon from '../../common/components/Icon';
import Button from '../../common/components/Button';
import PrinterSelectPopup from './popups/PrinterSelectPopup';
import { selectServicePrinter } from '../../common/serviceAreas/actions';

const displayWithUnit = (unit, DisplayComponent) => props =>
  <DisplayComponent {...props} unit={unit} />;

class DepositAndWithdrawal extends React.PureComponent {
  constructor(props) {
    super(props);

    const { paymentMedia, paymentTypes } = props;

    const validPaymentTypes = filter(({ cisfplat = ''}) => {
      return cisfplat !== '0';
    }, paymentTypes);

    const validPaymentMedia = leftJoin(
      ({ idriadok }, { druhyPlId }) => idriadok === druhyPlId,
      validPaymentTypes,
      paymentMedia
    );

    const sortedPaymentMedia = validPaymentMedia ? sortBy(prop('sortOrder'), validPaymentMedia) : [];

    this.state = {
      success: null,
      error: null,
      loading: false,
      showPrinterSelectPopup: false,
      paymentMedia: sortedPaymentMedia,
      paymentMediaId: sortedPaymentMedia.length > 0 ? sortedPaymentMedia[0].id : undefined
    };
  }

  togglePrintersPopup = value => {
    this.setState({ showPrinterSelectPopup: value });
  };

  submit = (price) => {
    if (!price) return;

    this.setState({ loading: true }, () => {
      const { depositOrWithdrawal, selectedPrinterId, authDefaultPrinter, intl } = this.props;
      const prnNo = selectedPrinterId || authDefaultPrinter;

      const { paymentMediaId } = this.state;

      const promise = depositOrWithdrawal === 'deposit'
        ? deposit(paymentMediaId, +price * 100, undefined, prnNo)
        : withdrawal(paymentMediaId, +price * 100, undefined, prnNo);

      promise.then(() => {
        this.setState({
          loading: false,
          error: null,
          success: depositOrWithdrawal === 'deposit'
            ? intl.formatMessage(messages.serviceAreasDepositCompleted)
            : intl.formatMessage(messages.serviceAreasWithdrawalCompleted)
        });
      }).catch(error => {
        this.setState({
          loading: false,
          error,
          success: false
        });
      });
    });
  };

  onClose = () => {
    const { dispatch, cancel, authDefaultPrinter } = this.props;
    const { loading } = this.state;
    if (!loading && cancel) {
      cancel();
    }
    dispatch(selectServicePrinter(authDefaultPrinter));
  };

  render() {
    const { cancel, depositOrWithdrawal, selectedPrinterId, authDefaultPrinter, printers, intl } = this.props;
    const { success, error, loading, showPrinterSelectPopup, paymentMediaId, paymentMedia } = this.state;

    const selectedPrinter = selectedPrinterId && printers && printers.find(printer => printer.prnNo === selectedPrinterId);
    const defaultPrinter = printers && printers.find(printer => printer.prnNo === authDefaultPrinter);
    const displayedPrinterName = `${(selectedPrinter && selectedPrinter.prnName) || (defaultPrinter && defaultPrinter.prnName)}`.trim();

    if (success) {
      return (
        <Notification text={success} mode="overlay" onClose={cancel} showClose />
      );
    }

    return (
      <InactiveBg zIndex={10} onClose={loading ? undefined : cancel}>
        <Popup onClose={this.onClose}>
          {loading ? (
            <InactiveBg position="absolute">
              <Spinner color="light" />
            </InactiveBg>
          ) : null}

          {showPrinterSelectPopup && (
            <PrinterSelectPopup
              availablePrinters={printers}
              onClose={() => this.togglePrintersPopup(false)}
            />
          )}

          <Heading scale={4} color="white">
            {depositOrWithdrawal === 'deposit' ? intl.formatMessage(messages.serviceAreasDeposit) : intl.formatMessage(messages.serviceAreasWithdrawal)}
          </Heading>

          <Box flexDirection="row">
            <Box height={`${theme.typography.lineHeight(5) * 1.75 * 5}px`}>
              <Text color="white" marginBottom={0.5} flexShrink={0}>{`${intl.formatMessage(messages.serviceAreaPaymentType)}:`}</Text>
              <ScrollView height="auto">
                <Box width={paymentMedia.length > 1 ? 25 : 12} flexDirection="row" flexWrap="wrap">
                  {paymentMedia.map(paymentMedia =>
                    <ContextMenuButton
                      key={paymentMedia.id}
                      text={paymentMedia.name}
                      onPress={() => this.setState({ paymentMediaId: paymentMedia.id })}
                      icon="price-level"
                      width="50%"
                      color={paymentMedia.id === paymentMediaId ? 'teal' : 'white'}
                    />
                  )}
                </Box>
              </ScrollView>
              <Box marginTop={0.5}>
                <Text color="white" marginBottom={0.5} flexShrink={0}>{`${intl.formatMessage(messages.serviceAreaPrinterSelection)}:`}</Text>
                <Button
                  width="48%"
                  color="white"
                  onPress={() => this.togglePrintersPopup(true)}
                  flexShrink={0}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box
                    borderStyle="solid"
                    borderWidth={2}
                    color="white"
                    width={2}
                    height={2}
                    alignItems="center"
                    justifyContent="center"
                    flexShrink={0}
                    marginRight={0.5}
                    style={theme => ({ borderRadius: theme.typography.lineHeight() })}
                  >
                    <Icon color="white" name="edit" scale={2} />
                  </Box>
                  <Text color="white" bold>
                    {`${displayedPrinterName} `}
                  </Text>
                </Button>
              </Box>
            </Box>

            <Keyboard
              style={theme => ({
                width: theme.typography.lineHeight(5) * 1.75 * 3,
              })}
              createOperations={createDepositOperations}
              Buttons={DepositButtons}
              Display={displayWithUnit(getCurrencySymbol(), DepositDisplay)}
              onLastKey={(key, price) => {
                if (key === 'submit') {
                  this.submit(price);
                }
              }}
              disabled={loading}
              flexShrink={0}
            />
          </Box>

          {error ? <Text color="error" align="center" marginTop={1}>{error.toString()}</Text> : null}
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    paymentMedia: state.payment.paymentMedia,
    paymentTypes: state.payment.paymentTypes,
    authDefaultPrinter: state.auth.defaultPrinter,
    selectedPrinterId: state.serviceAreas.selectedPrinterId,
    printers: state.peripherals.printers
  })),
  injectIntl
)(DepositAndWithdrawal);
