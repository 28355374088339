// @flow
import configureDeps from './configureDeps';
import configureEpics from './configureEpics';
import { createLogger } from 'redux-logger';
import { isBrowser, isElectron, isReactNative } from './app/detectPlatform';
import { createEpicMiddleware } from 'redux-observable';

export const injectMiddleware = deps =>
  ({ dispatch, getState }: any) =>
    (next: any) =>
      (action: any) => {
        let realActions = action;

        if (!Array.isArray(action)) {
          realActions = [action];
        }

        realActions.forEach(action => {
          next(
            typeof action === 'function'
              ? action({ ...deps, dispatch, getState })
              : action
          );
        });
      };

const configureMiddleware = (
  initialState: any,
  platformDeps: any,
  platformMiddleware: any,
  platformEpics: any
) => {
  const deps = configureDeps(initialState, platformDeps);
  const rootEpic = configureEpics(platformEpics, deps);
  const epicMiddleware = createEpicMiddleware(rootEpic);

  const middleware = [
    injectMiddleware(deps),
    epicMiddleware,
    ...platformMiddleware,
  ];

  const enableLogger = process.env.ENV !== 'production'
    && (isBrowser || isElectron
      // remote debugging is enabled
      || (isReactNative && typeof atob !== 'undefined'));

  // Logger must be the last middleware in chain.
  if (enableLogger) {
    const logger = createLogger({
      timestamp: false,
      collapsed: true,
      duration: true,
      // diff: true // major performance issues
    });
    middleware.push(logger);
  }

  if (module.hot && typeof module.hot.accept === 'function') {
    if (isReactNative) {
      module.hot.accept(() => {
        const configureEpics = require('./configureEpics').default;

        epicMiddleware.replaceEpic(configureEpics(deps));
      });
    } else {
      module.hot.accept('./configureEpics', () => {
        const configureEpics = require('./configureEpics').default;

        epicMiddleware.replaceEpic(configureEpics(deps));
      });
    }
  }

  return middleware;
};

export default configureMiddleware;
