// @flow
import React from 'react';
import InactiveBg from '../../../../browser/components/InactiveBg';
import Popup from '../../../../browser/components/Popup';
import Heading from '../../../../common/components/Heading';
import Button from '../../../../common/components/Button';
import Box from '../../../../common/components/Box';
import Text from '../../../../common/components/Text';
import TextInput from '../../../../common/components/TextInput';
import {compose, equals} from 'rambda';
import messages from '../../../../common/messages/payment';
import {FormattedMessage, injectIntl} from 'react-intl';
import createTypewriterOperations from '../../../../common/components/Keyboard/typewriter/operations';
import TypewriterButtons from '../../../../common/components/Keyboard/typewriter/Buttons';
import theme from '../../../../common/themes/theme';
import OneLineDisplay from '../../../../common/components/Keyboard/typewriter/OneLineDisplay';
import Keyboard from '../../../../common/components/Keyboard';
import ScrollView from '../../../../common/components/ScrollView';
import { RequestOrderError } from '../../../../common/lib/fetch/orderKeeper';
import {connect} from 'react-redux';

class NewPartner extends React.Component {
  state = {
    focus: null,
    form: {},
    saving: false
  };

  _voidFunction = () => {};

  _focus = (field) => {
    this.setState({
      focus: field,
      form: {
        ...this.state.form,
        [field]: this.state.form[field]
      }
    });
  };

  _close = () => {
    this.setState({
      focus: null,
    });
  };

  _save = () => {
    const { savePartner } = this.props;
    this.setState({ saving: true });

    savePartner(this.state.form).then(() => {
      this.setState({ saving: false });
    }).catch(e => {
      if (e instanceof RequestOrderError) return;

      throw e;
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props !== nextProps) || !equals(this.state, nextState);
  }

  render() {
    const { focus, form, saving } = this.state;
    const { intl } = this.props;
    const fields = ['hjmeno', 'adresa1', 'adresa2', 'adresa3', 'ico',
      'icdph', 'dic', 'schvalil', 'txt'];

    return (
      <Box width={20} maxHeight="70vh" marginHorizontal={-1}>
        <Heading scale={2} color="white" marginHorizontal={1}>{intl.formatMessage(messages.paymentPgrDotazyNewPartner)}</Heading>

        <ScrollView>
          {fields.map(field => (
            <Box key={field} flexGrow={1} flexShrink={0} flexBasis="auto" marginHorizontal={1}>
              <Text bold marginBottom={0.25} color="white">
                <FormattedMessage {...messages[field]} />
              </Text>
              <TextInput
                maxLength={35}
                padding={0.5}
                scale={2}
                color="white"
                backgroundColor="darkGray"
                borderWidth={2}
                borderStyle="solid"
                borderColor="lightGray"
                value={form[field]}
                marginBottom={1}
                onClick={(e) => {
                  e.target.blur();
                  this._focus(field);
                }}
                onChange={this._voidFunction}
                disabled={saving}
              />
            </Box>
          ))}

          <Button
            backgroundColor="teal"
            outline
            color="white"
            paddingVertical={0.75}
            onPress={this._save}
            marginBottom={0.5}
            marginHorizontal={1}
            disabled={saving}
            flexShrink={0}
          >
            {intl.formatMessage(messages.paymentPgrDotazyAddPartner)}
          </Button>
        </ScrollView>

        {focus &&
        <InactiveBg>
          <Popup showClose={false}>
            <Heading scale={2} color="white">
              <FormattedMessage {...messages[this.state.focus]} />
            </Heading>

            <Keyboard
              width={`${theme.typography.lineHeight(3) * 1.75 * 11
                + theme.typography.lineHeight() * 7.5}px`}
              createOperations={createTypewriterOperations}
              Buttons={TypewriterButtons}
              Display={OneLineDisplay}
              onLastKey={key => {
                if (key === 'send') {
                  this._close();
                }
              }}
              onDisplayValue={lastDisplayValue => {
                this.setState({
                  form: {
                    ...this.state.form,
                    [this.state.focus]: lastDisplayValue
                  }
                });
              }}
              defaultValue={this.state.form[this.state.focus]}
            />
          </Popup>
        </InactiveBg>
        }
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(NewPartner);
