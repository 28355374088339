// @flow
import React from 'react';
import { animateScroll } from 'react-scroll/modules';
import { equals, find, last, propEq } from 'rambda';
import { getAllOrderLinesInFocus } from '../../order/utils';
import connect from 'react-redux/es/connect/connect';
import type { State } from '../../types';
import { undeletedSubTablesSelector } from '../../tables/selectors';

export default BaseComponent => connect((state: State) => ({
  _selectedOrderLines: state.orders.selectedOrderLines,
  _subTables: undeletedSubTablesSelector(state),
  _orderLinesUnsorted: state.orders.orderLines,
  _activeOpenTableId: state.tables.active.openTableId,
  _activeSubTableId: state.tables.active.subTableId,
}))(class scrollToFoodCourse extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const scrollToFoodCourse = (foodCourseNumber) => {
      // TODO anti pattern - fixme - make better
      const activeFoodCourseElement = document.getElementById(`food-course-${foodCourseNumber}`);
      const firstFoodCourseElement = document.getElementById('food-course-0');
      const activeFoodCourseElementDistance = activeFoodCourseElement
        ? activeFoodCourseElement.getBoundingClientRect().top
        : 0;
      const firstFoodCourseDistance = firstFoodCourseElement
        ? firstFoodCourseElement.getBoundingClientRect().top
        : 0;
      const correctionSizer = 15;

      const scrollDistance = activeFoodCourseElementDistance - firstFoodCourseDistance - correctionSizer;

      setTimeout(() =>
          animateScroll.scrollTo(scrollDistance, {
            containerId: 'OrderLineSheetScrollContainer',
            duration: 400,
            smooth: true
          }),
        500
      );
    };

    if (prevProps._selectedOrderLines.length && !this.props._selectedOrderLines.length) {
      const prevLastSelected = find(propEq('id', last(prevProps._selectedOrderLines)),
        prevProps._orderLinesUnsorted);
      const lastSelected = find(propEq('id', last(prevProps._selectedOrderLines)),
        this.props._orderLinesUnsorted);
      const areSame = equals(prevLastSelected, lastSelected);
      if (!areSame) {
        scrollToFoodCourse(lastSelected ? lastSelected.foodCourse : prevLastSelected.foodCourse);
      }
    } else {
      const {
        _subTables,
        _orderLinesUnsorted,
        _activeOpenTableId,
        _activeSubTableId
      } = this.props;

      const prevOrderLines = getAllOrderLinesInFocus(prevProps._subTables,
        prevProps._orderLinesUnsorted, _activeOpenTableId, _activeSubTableId);
      const thisOrderLines = getAllOrderLinesInFocus(_subTables, _orderLinesUnsorted,
        _activeOpenTableId, _activeSubTableId);

      if (prevOrderLines.length !== thisOrderLines.length && thisOrderLines.length) {
        scrollToFoodCourse(last(thisOrderLines).foodCourse);
      }
    }
  }

  render() {
    const {
      _selectedOrderLines,
      _subTables,
      _orderLinesUnsorted,
      _activeOpenTableId,
      _activeSubTableId,
      dispatch,
      ...props
    } = this.props;

    return <BaseComponent {...props} />;
  }
});
