// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
 compose, find, propEq, concat
} from 'rambda';
import Text from '../../common/components/Text';
import Popup from '../components/Popup';
import Keyboard from '../../common/components/Keyboard';
import { toggleWeightForm } from '../../common/peripherals/actions';
import { addOrderLine, toggleTextInput } from '../../common/order/actions';
import { computeItemPrice } from '../../common/order/utils';
import PopupWeightFormButtons from '../../common/components/Keyboard/popupWeightForm/Buttons';
import PopupWeightFormDisplay from '../../common/components/Keyboard/popupWeightForm/Display';
import createFormOperations from '../../common/components/Keyboard/popupWeightForm/operations';
import type { State } from '../../common/types';
import Heading from '../../common/components/Heading';
import {FormattedMessage, injectIntl} from 'react-intl';
import messages from '../../common/components/Keyboard/messages/buttons';
import orderMessages from '../../common/messages/order';
import Divider from '../../common/components/Divider';
import InactiveBg from '../components/InactiveBg';
import { getItemAvailability } from '../../common/items/utils';
import Button from '../../common/components/Button';
import Radio from '../../common/components/Radio';
import { findParamValue } from '../../common/parameters/utils';
import { getCurrencySymbol } from '../../common/parameters/service';

class SelectorButton extends React.PureComponent {
  onSelect = (id, value) => {
    const { setActiveWeight } = this.props;
    setActiveWeight({ id, value });
  };

  render() {
    const { id, value, activeWeight, intl } = this.props;
    const isActive = (id === activeWeight.id);

    return (
      <Button
        key={id}
        backgroundColor={isActive ? 'teal' : 'appBg'}
        outline
        color="white"
        marginBottom={0.5}
        paddingVertical={0.75}
        paddingHorizontal={2}
        onPress={() => this.onSelect(id, value)}
        flexShrink={0}
        style={{ justifyContent: 'flex-start' }}
      >
        <Radio size={1} color="white" left={0.75} position="absolute" backgroundColor={isActive ? 'teal' : 'appBg'} checked={isActive} />
        <Text color="white" bold>
          {(id > 0) ? `${intl.formatMessage(orderMessages.orderPopupWeightFormPlate)} ${id}(${value}${intl.formatMessage(orderMessages.orderPopupWeightFormKg)})` : intl.formatMessage(orderMessages.orderPopupWeightFormWithoutPlate)}
        </Text>
      </Button>
    );
  }
}

const popupNumericDisplayWithUnit = (unit, forceValue, DisplayComponent) => props =>
  <DisplayComponent {...props} value={forceValue} unit={unit} />;

class WeightSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeWeight: { id: 0, value: 0 }
    };
  }

  setActiveWeight = activeWeight => {
    const { setMinusWeightValue } = this.props;
    setMinusWeightValue(activeWeight.value);
    this.setState({ activeWeight });
  };

  render() {
    const { items, intl } = this.props;
    const { activeWeight } = this.state;

    return (
      <div style={{ flexDirection: 'column' }}>
        {items.map(item => (
          <SelectorButton
            key={item.id}
            id={item.id}
            value={item.value}
            activeWeight={activeWeight}
            setActiveWeight={this.setActiveWeight}
            intl={intl}
          />
        ))}
      </div>
    );
  }
}

class PopupWeightForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lastDisplayValue: '', minusWeightValue: 0 };
  }

  onSubmit = key => {
    if (key !== 'ok') return;

    const {
      dispatch,
      actualWeight,
      activePriceLevelId,
      isWeightFormOpen,
      activeSubTableId,
      activeFoodCourse,
      selectedOrderLines,
      orderLines,
      itemPrices,
      items,
      itemsAvailability,
      itemsAvailabilityDiff
    } = this.props;

    const { lastDisplayValue, minusWeightValue } = this.state;

    let itemFoodCourse = 0;
    if (isWeightFormOpen.selectAfter) {
      const selectedOrderLine = find(propEq('id', selectedOrderLines[0].id), orderLines);
      if (selectedOrderLine) {
        itemFoodCourse = selectedOrderLine.foodCourse || 0;
      }
    }

    const quantity = ((lastDisplayValue === '' ? actualWeight : +lastDisplayValue) - minusWeightValue)
      * 1000;

    const menuItem = find(propEq('id', isWeightFormOpen.id), items);
    if (!menuItem) return;

    const singlePrice = computeItemPrice(itemPrices, menuItem.id, activePriceLevelId);

    const orderLine = {
      itemId: isWeightFormOpen.id,
      singlePrice,
      subTableId: activeSubTableId,
      foodCourse: isWeightFormOpen.selectAfter ? itemFoodCourse : (activeFoodCourse || 0),
      priceLevelId: activePriceLevelId,
      quantity,
      unit: menuItem.unit,
      hasCustomPrice: menuItem.hasCustomPrice,
      needsInvoiceNo: menuItem.needsInvoiceNo,
      spart: menuItem.spart,
      isWeighted: true
    };

    const availability = getItemAvailability(menuItem.id, itemsAvailability, itemsAvailabilityDiff);

    if (availability >= quantity && quantity > 0) {
      // workaround (that timeout) - so that if enter key is hit, the handleKeyboardPress from MenuItemsList.js is not triggered
      setTimeout(
        () => dispatch(
          concat(
            [
              addOrderLine(
                orderLine,
                { selectAfterAddition: isWeightFormOpen.selectAfter || menuItem.mustEnterText }
              ),
              toggleWeightForm(false)
            ],
            menuItem.mustEnterText ? [toggleTextInput(true)] : []
          )
        ),
        200
      );
    }
  };

  render() {
    const {
      dispatch,
      intl,
      items,
      itemPrices,
      actualWeight,
      activePriceLevelId,
      isWeightFormOpen,
      itemsAvailability,
      itemsAvailabilityDiff,
      parameters
    } = this.props;
    const { lastDisplayValue, minusWeightValue } = this.state;

    const menuItem = find(propEq('id', isWeightFormOpen.id), items);
    if (!menuItem) return null;

    const weight = lastDisplayValue === '' ? actualWeight : +lastDisplayValue;
    const unitPrice = computeItemPrice(itemPrices, menuItem.id, activePriceLevelId);
    const actualPrice = Math.round(unitPrice * (weight - minusWeightValue) + Number.EPSILON);

    const availability = getItemAvailability(menuItem.id, itemsAvailability, itemsAvailabilityDiff);

    const weightItemsListParam = findParamValue('K32.foodman_weight_items', parameters);

    const parseWeightItems = itemsListParam => {
      const weightItems = [];
      if (itemsListParam) {
        const items = itemsListParam.split(';');
        items.forEach(item => {
          const splittedItem = item.split('_');
          weightItems.push({ id: parseFloat(splittedItem[0]), value: parseFloat(splittedItem[1]) });
        });
        return weightItems;
      }

      return [];
    };

    const setMinusWeightValue = minusWeightValue => {
      this.setState({ minusWeightValue });
    };

    return (
      <InactiveBg onClose={() => dispatch(toggleWeightForm(false))}>
        <Popup
          showClose
          onClose={() => dispatch(toggleWeightForm(false))}
          width={(weightItemsListParam) ? 30 : 20}
        >
          <Heading scale={4} color="white" style={{ marginTop: 0, marginBottom: 10 }}>{intl.formatMessage(orderMessages.orderPopupWeightFormWeightItem)}</Heading>
          <Text scale={1} color="white">{menuItem.menuName}</Text>

          <Divider color="white" style={{ marginTop: 10, marginBottom: 10, opacity: 0.2 }} />

          <Text scale={1} color="white">
            {`${intl.formatMessage(orderMessages.orderPopupWeightFormUnitPrice)}: `}
            {(`${unitPrice / 100} ${getCurrencySymbol()}`).replace(/\./g, intl.formatMessage(messages.dot))}
          </Text>

          <Text scale={1} color="white" marginBottom={1}>
            {`${intl.formatMessage(orderMessages.orderPopupWeightFormActualPrice)}: `}
            {(`${actualPrice / 100} ${getCurrencySymbol()}`).replace(/\./g, intl.formatMessage(messages.dot))}
          </Text>

          {availability !== Infinity
            && (
            <Text scale={1} color="white" marginTop={-1} marginBottom={1}>
              {`${intl.formatMessage(orderMessages.orderPopupWeightFormAvailability)}: `}
              {availability / 1000}
            kg
            </Text>
            )
          }

          {((lastDisplayValue - minusWeightValue) * 1000) > availability && (
            <Text color="error" marginBottom={1} marginTop={-0.5}>{intl.formatMessage(orderMessages.orderPopupWeightFormNotAvailable)}</Text>
          )}

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {weightItemsListParam && (
              <div style={{ margin: '20px' }}>
                <WeightSelector
                  items={parseWeightItems(weightItemsListParam)}
                  setMinusWeightValue={setMinusWeightValue}
                  intl={intl}
                />
              </div>
              )
            }
            <Keyboard
              style={theme => ({ width: theme.typography.lineHeight(5) * 1.75 * 3 })}
              createOperations={createFormOperations}
              Buttons={PopupWeightFormButtons}
              Display={popupNumericDisplayWithUnit(
                'kg',
                `${lastDisplayValue === '' ? actualWeight : lastDisplayValue}`,
                PopupWeightFormDisplay
)
              }
              onLastKey={this.onSubmit}
              onDisplayValue={val => {
                this.setState({ lastDisplayValue: val });
              }}
              defaultValue=""
              marginTop={1}
              marginHorizontal="auto"
            />
          </div>

        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    items: state.items.items,
    itemPrices: state.items.itemPrices,
    activePriceLevelId: state.items.activePriceLevelId,
    actualWeight: state.peripherals.actualWeight,
    isWeightFormOpen: state.peripherals.isWeightFormOpen,
    activeSubTableId: state.tables.active.subTableId,
    activeFoodCourse: state.tables.active.foodCourse,
    selectedOrderLines: state.orders.selectedOrderLines,
    orderLines: state.orders.orderLines,
    itemsAvailability: state.items.availability,
    itemsAvailabilityDiff: state.items.availabilityLocalDiff,
    parameters: state.parameters.parameters
  })),
  injectIntl
)(PopupWeightForm);
