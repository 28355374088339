// @flow
import type { Device } from './index';
import configurePeripherals from './index';
import protocol4225 from './serials/protocol-4.2.25';
import protocolNciPos from './serials/protocol-nci.pos';
import protocolCas from './serials/protocol-cas';
import protocolTScale from './serials/protocol-tscale';
import { updateActualWeight } from '../../common/peripherals/actions';

let p;
let intervalId;
let globalDispatch;

const myPeripherals = {
  peripherals: [
    {
      name: 'vaha',
      interface: 'serial',
      path: '/dev/tty.usbserial-AL01UI31',
      protocols: {
        'nci.pos': {
          protocol: protocolNciPos,
          stopBit: 1,
          parity: 'even',
          dataBits: 7,
          baudRate: 9600
        },
        '4.2.25': {
          protocol: protocol4225,
          stopBit: 1,
          parity: 'none',
          dataBits: 8,
          baudRate: 9600
        },
        cas: {
          protocol: protocolCas,
          stopBit: 1,
          parity: 'even',
          dataBits: 7,
          baudRate: 9600
        },
        tscale: {
          protocol: protocolTScale,
          stopBit: 1,
          parity: 'none',
          dataBits: 8,
          baudRate: 9600
        }
      }
    }
  ]
};

const alwaysPrepareWeight = (deviceProtocol, device) => {
  intervalId = setInterval(() => {
    if (device.isOpened) {
      deviceProtocol.prepareWeight();
    } else {
      clearInterval(intervalId);
    }
  }, 1500);
};

export const startWeighting = (protocolName: string) =>
  configurePeripherals(myPeripherals)
    .pollPeripheral('vaha')
    .subscribe((device: Device) => {
      device.open(protocolName).then(() => {
        p = false;

        const deviceProtocol = myPeripherals.peripherals[0].protocols[protocolName].protocol(device);
        deviceProtocol.prepareWeight();

        if (!p) {
          alwaysPrepareWeight(deviceProtocol, device);
        }

        p = true;

        device.getObserver().subscribe(data => {
          deviceProtocol.setResponse(data);
          const weight = deviceProtocol.getLastWeight();

          if (weight !== null) {
            globalDispatch(updateActualWeight(weight));
          }
        }, e => {
          console.error(e);
        });
      });
    });

export const injectDispatchToPeripherals = (dispatch) => {
  globalDispatch = dispatch;
};

export const injectParametersToPeripherals = (protocol: string, storeParameters) => {
  // TODO find by name, not by index
  myPeripherals.peripherals[0].path = `${storeParameters.path}`;
  // eslint-disable-next-line max-len
  myPeripherals.peripherals[0].protocols[protocol].stopBit = parseInt(storeParameters.stopBit, 10) || myPeripherals.peripherals[0].protocols[protocol].stopBit;
  // eslint-disable-next-line max-len
  myPeripherals.peripherals[0].protocols[protocol].dataBits = parseInt(storeParameters.dataBits, 10) || myPeripherals.peripherals[0].protocols[protocol].dataBits;

  if (storeParameters.parity) {
    // eslint-disable-next-line default-case
    switch (parseInt(storeParameters.parity, 10)) {
      case 0:
        myPeripherals.peripherals[0].protocols[protocol].parity = 'none';
        break;
      case 1:
        myPeripherals.peripherals[0].protocols[protocol].parity = 'odd';
        break;
      case 2:
        myPeripherals.peripherals[0].protocols[protocol].parity = 'even';
        break;
    }
  }
};
