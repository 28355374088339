// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  logo_1: {
    defaultMessage: 'Tlačiť',
    id: 'admin.values.logo_1'
  },
  logo_0: {
    defaultMessage: 'Netlačiť',
    id: 'admin.values.logo_2'
  },
  state_1: {
    defaultMessage: 'Stav ON',
    id: 'admin.form.state_on'
  },
  state_0: {
    defaultMessage: 'Stav OFF',
    id: 'admin.form.state_off'
  },
});
