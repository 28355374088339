// @flow
import users from './users/reducer';
import general from './general/reducer';
import { combineReducers } from 'redux';

const reducer =
  combineReducers({
    general,
    users
  });

export default reducer;
