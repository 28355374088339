// @flow
import type { Action, AuthState } from '../types';
import moment from 'moment';
import { REHYDRATE } from 'redux-persist/constants';
import { keepAliveMessage } from '../../browser/auth/waiterKeyMiddleware';

const initialState = {
  formDisabled: false,
  error: null,
  user: null,
  lastDataUpdate: null,
  authNeedsFullSync: false,
  syncAvailable: false,
  waiterKey: {
    running: false,
    keepAliveRunning: false,
    process: null,
    signedUsingKey: false
  }
};

const reducer = (state: AuthState = initialState, action: Action): AuthState => {
  switch (action.type) {
    case REHYDRATE: {
      if (action.payload.auth) {
        return { ...state, ...action.payload.auth };
      }
      return state;
    }

    case 'BLITZ_SYNC_SERVER_DATA_COMMIT': {
      const { Auth: { lastDataUpdate = null } = {} } = action.payload;
      const { lastDataUpdate: previousLastDataUpdate } = state;

      if (!lastDataUpdate || !previousLastDataUpdate) {
        return { ...state, syncAvailable: false };
      }

      const lastDataUpdateMoment = moment(lastDataUpdate).milliseconds(0);
      const previousLastDataUpdateMoment = moment(previousLastDataUpdate).milliseconds(0).milliseconds(0);

      const syncAvailable = lastDataUpdateMoment.isAfter(previousLastDataUpdateMoment);
      return { ...state, syncAvailable };
    }

    case 'SYNC_SERVER_DATA_COMMIT': {
      const { Auth: { lastDataUpdate = null } = {} } = action.payload;

      return { ...state, lastDataUpdate, syncAvailable: false };
    }

    case 'SIGN_IN': {
      return { ...state, formDisabled: true, error: null };
    }

    case 'SIGN_IN_DONE': {
      const { user, lastDataUpdate, defaultPrinter, usingWaiterKey } = action.payload;
      const { lastDataUpdate: previousLastDataUpdate } = state;

      const authNeedsFullSync = moment(lastDataUpdate).isAfter(previousLastDataUpdate);

      return {
        ...state,
        formDisabled: false,
        error: null,
        user,
        lastDataUpdate,
        defaultPrinter,
        authNeedsFullSync,
        waiterKey: {
          ...state.waiterKey,
          signedUsingKey: usingWaiterKey,
        }
      };
    }

    case 'SIGN_OUT': {
      return { ...state, user: null, error: null, waiterKey: { ...state.waiterKey, signedUsingKey: false } };
    }

    case 'SIGN_IN_FAIL': {
      const { error } = action.payload;
      return { ...state, error, formDisabled: false };
    }

    case 'SYNC_SERVER_DATA_ROLLBACK': {
      return { ...state, user: null, error: 'Nepodarilo sa načítať dáta kasy, skúste sa prihlásiť znova' };
    }

    case 'AUTH_RESET': {
      // return initialState;
      return { ...state, user: null, error: null, formDisabled: false, authNeedsFullSync: false, syncAvailable: false };
    }

    case 'WAITER_KEY_INIT_DONE': {
      const { process } = action.payload;

      return { ...state, waiterKey: { ...state.waiterKey, running: true, process } };
    }

    case 'WAITER_KEY_PROCESS_CLOSED': {
      return { ...state, waiterKey: { ...state.waiterKey, running: false, process: null } };
    }

    case 'WAITER_KEY_KEEP_ALIVE': {
      const {
        waiterKey: {
          running,
          process
        }
      } = state;

      if (running && process) {
        // to imports // import {Readable} from "stream";
        // const stdinStream = new Readable();
        // stdinStream.push(keepAliveMessage()); // Add data to the internal queue for users of the stream to consume
        // stdinStream.push(null); // Signals the end of the stream (EOF)
        // stdinStream.pipe(process.stdin);

        process.stdin.cork();
        process.stdin.write(keepAliveMessage() + "\r\n");
        process.stdin.uncork();
      }

      return { ...state, waiterKey: { ...state.waiterKey, keepAliveRunning: true } };
    }

    default:
      return state;
  }
};

export default reducer;
