// @flow
import type { Action, PaymentState } from '../types';
import { append, contains, pluck, without } from 'rambda';
import { assocPath } from 'ramda';
import { isReactNative } from '../app/detectPlatform';
import { clearFsStore } from '../lib/utils';

const initialState = {
  paymentMedia: [],
  paymentTypes: [],
  paymentTransactions: [],
  knownDiscounts: [],

  payments: [],
  appliedDiscounts: [],
  appliedOperations: [],
  availableDiscounts: [],
  availablePaymentMedia: [],
  loyalmanOperations: [],
  loyalmanPrepaidProducts: [],
  stats: {
    overallSum: 0,
    overallSumAfterDiscount: 0,
    leftToPay: 0,
    overallTip: 0,
    overpay: 0,
    allowTip: true
  },
  prgDotazes: [],
  prgDotaz: null, // move to active maybe (also some other properties)
  prgDotazPayment: null,
  formDisabled: false,
  paymentTransactionId: null,
  editPaymentRecord: null,
  prgFilterValue: '',
  operations: [],
  loyalman: null,
  isNewCardPopupOpen: false,
  isNewCardPopupFirstStep: false,
  isLoyalmanPrepaidItemsPopupOpen: false,
  openingLoyalmanPrepaidItemsPopup: false,
  isServiceCouponPopupOpen: false,
  isSendReceiptByEmailPopupOpen: false,
  availablePrinters: [],
  defaultPrinter: {},
  selectedPrinter: null,
  copies: 0,
  isNotePopupOpen: false
};

const reducer = (state: PaymentState = initialState, action: Action): PaymentState => {
  switch (action.type) {
    case 'REHYDRATE_FS_COMMIT': {
      const { selectedPrinter: { printer } = { printer: null } } = action.payload;

      return {
        ...state,
        ...action.payload.payment,
        selectedPrinter: printer
      };
    }
    case 'SYNC_SERVER_DATA_COMMIT': {
      const {
        payload: {
          PaymentMedia: paymentMedia,
          PaymentTypes: paymentTypes,
          ServerDiscounts: knownDiscounts,
          PaymentTransactions: paymentTransactions = []
        }
      } = action;

      if (isReactNative) {
        const Store = require('react-native-fs-store').default;

        clearFsStore('payment').then(() => {
          new Store('payment').setItem('payment', {
            paymentMedia,
            paymentTypes,
            paymentTransactions,
            knownDiscounts
          });
        });

      }

      return {
        ...state,
        paymentMedia,
        paymentTypes,
        paymentTransactions,
        knownDiscounts
      };
    }

    case 'PAYMENT_TRANSACTION_UPDATE': {
      const {
        payments,
        appliedDiscounts,
        appliedOperations,
        loyalmanOperations,
        loyalmanPrepaidProducts,
        stats,
        paymentTransactionId,
        availableDiscounts,
        availablePaymentMedia,
        loyalman,
        availablePrinters,
        defaultPrinter,
        copies
      } = action.payload;

      let paymentTransactions;
      if (paymentTransactionId) {
        // add to paymentTransactions (be faster than server 🏎)
        paymentTransactions = append(paymentTransactionId, state.paymentTransactions);
      } else {
        // remove old from paymentTransactions (be faster than server 🏎)
        const oldPaymentTransactionId = state.paymentTransactionId;
        paymentTransactions = without([oldPaymentTransactionId], state.paymentTransactions);
      }

      const selectedPrinterIsAvailable = state.selectedPrinter
        && contains(state.selectedPrinter.id, pluck('prn_no', availablePrinters));

      return {
        ...state,
        payments,
        appliedDiscounts,
        appliedOperations,
        loyalmanOperations,
        loyalmanPrepaidProducts,
        stats,
        paymentTransactionId,
        paymentTransactions,
        availableDiscounts,
        availablePaymentMedia,
        loyalman,
        availablePrinters,
        defaultPrinter,
        selectedPrinter: selectedPrinterIsAvailable ? state.selectedPrinter : null,
        copies: copies || 0
      };
    }

    case 'PAYMENT_TOGGLE_DISABLE_FORM': {
      const { disable } = action.payload;
      return { ...state, formDisabled: disable };
    }

    case 'PAYMENT_TOGGLE_PAYMENT_RECORD_FORM': {
      const { payment } = action.payload;
      return { ...state, editPaymentRecord: payment };
    }

    case 'PAYMENT_SHOW_PRG_DOTAZ': {
      const { payload: { prgDotaz, payment } } = action;
      return { ...state, prgDotaz, prgDotazPayment: payment };
    }

    case 'PAYMENT_HIDE_PRG_DOTAZ': {
      return { ...state, prgDotaz: null, prgDotazPayment: null };
    }

    case 'PAYMENT_TOGGLE_NOTE_POPUP': {
      const { payload: { toggle } } = action;
      return { ...state, isNotePopupOpen: toggle };
    }

    case 'ORDER_RESET':
    case 'ORDER_SOFT_RESET':
    case 'PAYMENT_TRANSACTION_CLEAR': {
      return {
        ...initialState,
        paymentMedia: state.paymentMedia,
        paymentTypes: state.paymentTypes,
        paymentTransactions: state.paymentTransactions,
        knownDiscounts: state.knownDiscounts,
        selectedPrinter: state.selectedPrinter
      };
    }

    case 'PAYMENT_SET_PRG_FILTER_VALUE': {
      const prgFilterValue = action.payload.value;
      return { ...state, prgFilterValue };
    }

    case 'PAYMENT_TOGGLE_NEW_CARD_FORM': {
      const { open } = action.payload;
      return { ...state, isNewCardPopupOpen: open, isNewCardPopupFirstStep: open };
    }

    case 'PAYMENT_TOGGLE_NEW_CARD_FORM_FIRST_STEP': {
      const { is } = action.payload;
      return { ...state, isNewCardPopupFirstStep: is };
    }

    case 'PAYMENT_TOGGLE_CHOOSE_PREPAID_ITEMS_FORM': {
      const { open } = action.payload;
      return { ...state, isLoyalmanPrepaidItemsPopupOpen: open, openingLoyalmanPrepaidItemsPopup: false };
    }

    case 'PAYMENT_PREOPENING_PREPAID_ITEMS_FORM': {
      return { ...state, openingLoyalmanPrepaidItemsPopup: true };
    }

    case 'PAYMENT_SERVICE_COUPON_POPUP': {
      const { is = false } = action.payload;
      return assocPath(['isServiceCouponPopupOpen'], is, state);
    }

    case 'PAYMENT_SEND_RECEIPT_BY_EMAIL_POPUP': {
      const { is = false } = action.payload;
      return assocPath(['isSendReceiptByEmailPopupOpen'], is, state);
    }

    case 'PAYMENT_SELECT_PRINTER': {
      const { printer = null } = action.payload;

      if (isReactNative) {
        const Store = require('react-native-fs-store').default;

        clearFsStore('selectedPrinter').then(() => {
          if (printer) {
            new Store('selectedPrinter').setItem('selectedPrinter', {
              printer
            });
          }
        });
      }

      return assocPath(['selectedPrinter'], printer, state);
    }

    case 'PAYMENT_SET_NUMBER_OF_COPIES': {
      const { number = 0 } = action.payload;
      return assocPath(['copies'], number, state);
    }

    case 'ORDER_TERMINAL_PAYMENT_TOGGLE_LOADING': {
      const { terminal, onTerminalMessage } = action.payload;
      const updatedOnMessage = assocPath(['onTerminalMessage'], onTerminalMessage, state);
      const updatedOnTerminal = assocPath(['terminal'], terminal, state);
      // if (!terminal) {
      //   const updatedTerminalMessage = assocPath(['terminalMessage'], null, state);
      //   return { ...updatedOnMessage, ...updatedOnTerminal, ...updatedTerminalMessage };
      // }
      return { ...updatedOnMessage, ...updatedOnTerminal };
    }

    case 'TERMINAL_PAYMENT_SOCKET_MESSAGE_LOADING': {
      const { terminalMessage } = action.payload;
      return assocPath(['terminalMessage'], terminalMessage, state);
    }

    default:
      return state;
  }
};

export default reducer;
