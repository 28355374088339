// @flow
import type { State } from '../types';
import { all, any } from 'rambda';

let getState: () => State;

export const configurePermissionsService = _getState => {
  getState = _getState;
};

const checkPermission = (...permissionsList: string[]) => {
  const { permissions } = getState().permissions;

  return all(permission => permissions[permission], permissionsList);
};

export const checkIfAnyPermission = (...permissionsList: string[]) => {
  const { permissions } = getState().permissions;

  return any(permission => permissions[permission], permissionsList);
};

export default checkPermission;
