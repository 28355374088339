// @flow
import type { Action, IdsState } from '../types';

const initialState = {};

const reducer = (state: IdsState = initialState, action: Action): IdsState => {
  switch (action.type) {
    case 'LOCAL_IDS_ADD': {
      const { pairs } = action.payload; // oldId: newId

      return { ...state, ...pairs };
    }

    default:
      return state;
  }
};

export default reducer;

