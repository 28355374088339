// @flow
/* eslint-disable import/no-mutable-exports */

let platformLabel;

if (process.env.IS_REACT_NATIVE === '1') {
  platformLabel = require('../../native/components/Label').default;
} else {
  platformLabel = require('../../browser/components/Label').default;
}

export default platformLabel;
