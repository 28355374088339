// @flow
import React from 'react';
import { connect } from 'react-redux';
import { path } from 'rambda';

const saveMenuScrollableInfo = onUpdate => BaseComponent => {
  class MenuScrollableInfo extends React.PureComponent {
    componentDidUpdate() {
      const { dispatch } = this.props;

      if (onUpdate && path(['contentRect', 'bounds', 'bottom'], this.props)) {
        const { contentRect: { bounds: { bottom } } } = this.props;
        dispatch(onUpdate(window.innerHeight < bottom - 5));
      }
    }

    render() {
      const { dispatch, ...rest } = this.props;

      return <BaseComponent {...rest} />;
    }
  }

  return connect()(MenuScrollableInfo);
};

export default saveMenuScrollableInfo;
