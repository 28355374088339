// @flow
import { createSelector } from 'reselect';
import { sortWith, isEmpty } from 'ramda';
import { filter, map, pathOr } from 'rambda';
import { findParamValue } from '../parameters/utils';
import { parametersSelector } from '../parameters/selectors';
import { filterValueSelector } from '../order/selectors';
import { activePageSelector } from '../menuItems/selectors';
import { computeItemPrice, isFiltering } from '../order/utils';
import latinize from 'latinize';
import escapeRegexp from 'escape-string-regexp';
import { isReactNative } from '../app/detectPlatform';

const allItemsSelector = (state: State) => state.items.items;

const itemPricesSelector = (state: State) => state.items.itemPrices;

const activePriceLevelIdSelector = (state: State) => state.items.activePriceLevelId;

// TODO perf
export const displayMenuItemsSelector = createSelector(
  filterValueSelector,
  allItemsSelector,
  activePriceLevelIdSelector,
  activePageSelector,
  itemPricesSelector,
  parametersSelector,
  (filterValue, items, priceLevelId, activePage, itemPrices, parameters) => {
    filterValue = latinize(filterValue);

    const isFilteringActive = isFiltering(filterValue);

    let filteredItems;

    if (isFilteringActive) {
      const regex = RegExp(filterValue, 'i');

      filteredItems = filter(
        item => (regex.test(escapeRegexp(item.menuNameDeaccented)) || (item.plu === +filterValue)) && item.idKas !== 'A0',
        items
      );
    } else {
      filteredItems = activePage ? activePage.content : [];
    }

    filteredItems = map(item =>
      isFilteringActive
        ? {
            ...item,
            itemPrice: computeItemPrice(itemPrices, item.id, priceLevelId),
            isItem: true
          }
        : item.isItem
          ? {
              ...item,
              itemPrice: computeItemPrice(itemPrices, item.id, priceLevelId)
            }
          : item,
      filteredItems
    );

    const paramIsObjNul = findParamValue('K32.is_objnul', parameters);

    if (!paramIsObjNul) {
      if (!isFilteringActive) {
        filteredItems = filteredItems.map(fi => {
          const item = items.find(i => i.id === pathOr(undefined, 'link.id', fi));
          return {
            ...fi,
            hasCustomPrice: item ? item.hasCustomPrice : false
          };
        });
      }

      filteredItems = filter(({ isItem, itemPrice, hasCustomPrice }) => isItem ? (itemPrice !== 0 || hasCustomPrice) : true, filteredItems);
    }

    if (isFilteringActive) {
      const byName = ({ menuNameDeaccented: a }, { menuNameDeaccented: b }) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      };
      filteredItems = sortWith([byName], filteredItems);
    }

    if (isReactNative && !isFilteringActive && !isEmpty(filteredItems) && !!filteredItems[0].geometry) {
      const byRows = ({ geometry: { row: a } }, { geometry: { row: b } }) => a - b;
      const byColumns = ({ geometry: { column: a } }, { geometry: { column: b } }) => a - b;

      filteredItems = sortWith([byRows, byColumns], filteredItems);
    }

    return filteredItems;
  }
);
