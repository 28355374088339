// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, find, propEq, repeat } from 'rambda';
import Text from '../../common/components/Text';
import Popup from '../components/Popup';
import Keyboard from '../../common/components/Keyboard';
import { addOrderLine, hideAvailabilityBlocker } from '../../common/order/actions';
import PopupWeightFormButtons from '../../common/components/Keyboard/popupWeightForm/Buttons';
import PopupWeightFormDisplay from '../../common/components/Keyboard/popupWeightForm/Display';
import createFormOperations from '../../common/components/Keyboard/popupWeightForm/operations';
import type { Item, State } from '../../common/types';
import Heading from '../../common/components/Heading';
import InactiveBg from '../components/InactiveBg';
import { getItemAvailability } from '../../common/items/utils';
import {injectIntl} from 'react-intl';
import messages from '../../common/messages/order';
import uuid from 'uuid';

const customDisplay = unit => props => <PopupWeightFormDisplay {...props} unit={unit} />;
const CustomButtons = props => <PopupWeightFormButtons {...props} showDot={false} />;

class AvailabilityBlocker extends React.PureComponent {
  onClose = () => {
    const { dispatch, dispatchLoad } = this.props;

    const dispatchParams = dispatchLoad
      ? [hideAvailabilityBlocker(), ...dispatchLoad(0)]
      : hideAvailabilityBlocker();

    this.availabilityKeyboard.sendKey('setTo1');
    dispatch(dispatchParams);
  };

  onSubmit = (key, newCount) => {
    if (key !== 'ok') return;

    const { dispatch, orderLineParams, dispatchLoad } = this.props;
    const count = +newCount;
    const customGroupHash = count > 1 && uuid.v4();

    const dispatchParams = dispatchLoad
      ? dispatchLoad(count)
      : repeat(addOrderLine({ ...orderLineParams, customGroupHash }), count);

    this.availabilityKeyboard.sendKey('setTo1');
    dispatch([
      hideAvailabilityBlocker(),
      ...dispatchParams
    ]);
  };

  render() {
    const {
      wantedCount,
      items,
      orderLineParams,
      unit,
      itemsAvailability,
      itemsAvailabilityDiff,
      intl
    } = this.props;

    const menuItemId = orderLineParams.itemId;

    const menuItem: Item = find(propEq('id', menuItemId), items);

    if (!menuItem) return null;

    const availability = getItemAvailability(menuItem.id, itemsAvailability, itemsAvailabilityDiff);

    const itemPureName = menuItem.menuName;

    return (
      <InactiveBg onClose={this.onClose}>
        <Popup onClose={this.onClose} width={20}>
          <Heading scale={4} color="white" marginBottom={0}>{intl.formatMessage(messages.orderAvailabilityBlockerHeader)}</Heading>
          <Text color="white" marginBottom={1}>{itemPureName}</Text>

          <Text scale={1} color="white" bold>{`${intl.formatMessage(messages.orderAvailabilityBlockerAvailability)}: ${availability}${unit}`}</Text>

          <Text scale={1} color="white" bold>{intl.formatMessage(messages.orderAvailabilityBlockerConfirmChange)}</Text>

          <Keyboard
            ref={c => this.availabilityKeyboard = c}
            style={theme => ({ width: theme.typography.lineHeight(5) * 1.75 * 3 })}
            createOperations={createFormOperations}
            Buttons={CustomButtons}
            Display={customDisplay(unit)}
            onLastKey={this.onSubmit}
            defaultValue={wantedCount}
            marginTop={1}
            marginHorizontal="auto"
          />
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    wantedCount: state.orders.availabilityBlocker.wantedCount,
    unit: state.orders.availabilityBlocker.unit,
    orderLineParams: state.orders.availabilityBlocker.orderLineParams,
    dispatchLoad: state.orders.availabilityBlocker.dispatchLoad,
    items: state.items.items,
    itemsAvailability: state.items.availability,
    itemsAvailabilityDiff: state.items.availabilityLocalDiff
  })),
  injectIntl
)(AvailabilityBlocker);
