// @flow
import type { State } from '../../common/types';
import React from 'react';
import DefaultTopBar from '../../common/components/TopBar';
import { connect } from 'react-redux';
import OrderSidebarHeader from './topbars/OrderSidebarHeader';
import OrdersFilter from './topbars/OrdersFilter';
import Generic from './topbars/Generic';
import RelocatingItems from './topbars/RelocatingItems';
import ChoosePriceLevels from './topbars/ChoosePriceLevels';
import ChoosePortions from './topbars/ChoosePortions';

const TopBar = ({ isFilterOpened, isRelocatingToTable, isChangingPriceLevel, isChangingPortion }) => (
  <DefaultTopBar>
    {
      (isFilterOpened && <OrdersFilter />)
      || (isRelocatingToTable && <RelocatingItems />)
      || <Generic />
    }

    {isChangingPriceLevel && <ChoosePriceLevels />}
    {isChangingPortion && <ChoosePortions />}
    <OrderSidebarHeader />
  </DefaultTopBar>
);

export default connect((state: State) => ({
  isFilterOpened: state.orders.isFilterOpened,
  isRelocatingToTable: state.orders.active.isRelocatingToTable,
  isChangingPriceLevel: state.orders.active.isChangingPriceLevel,
  isChangingPortion: state.orders.active.isChangingPortion,
}))(TopBar);
