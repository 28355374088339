// @flow
import React from 'react';
import Title from '../../../components/Title';
import Box from '../../../../common/components/Box';
import Sidebar from './Sidebar';
import titlesMessages from '../../../../common/app/messages/titles';
import TopBar from '../../mealsCount/TopBar';
import HistoryList from './HistoryList';
import { connect } from 'react-redux';
import { toggleHistoryClosureId } from '../../../../common/admin/closures/actions';
import AdminNotification from '../../AdminNotification';

class CloseHistoryPage extends React.PureComponent {
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(toggleHistoryClosureId(null));
  }

  render() {
    return (
      <Box flex={1} height="100%" backgroundColor="appBg">
        <Title message={titlesMessages.admin} />

        <TopBar />

        <Box flexDirection="row" zIndex={1} flex={1} height="calc(100% - 36px)">
          <Box flex={1} width="100%">
            <HistoryList />
          </Box>

          <Sidebar />
        </Box>

        <AdminNotification position="absolute" bottom={0} left={0} right={0} />
      </Box>
    );
  }
}


export default connect()(CloseHistoryPage);
