// @flow
import type { State } from '../../../common/types';
import React from 'react';
import DefaultTopBar from '../../../common/components/TopBar';
import { connect } from 'react-redux';
import OrdersFilter from '../../order/topbars/OrdersFilter';
import AdminTopBar from '../TopBar';

class TopBar extends React.PureComponent {
  render() {
    const { isFilterOpened } = this.props;

    if (isFilterOpened) {
      return (
        <DefaultTopBar backgroundColor="lessDarkGray">
          <OrdersFilter />
        </DefaultTopBar>
      );
    }

    return <AdminTopBar />;
  }
}

export default connect((state: State) => ({
  isFilterOpened: state.orders.isFilterOpened,
}))(TopBar);
