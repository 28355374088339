// @flow
import type { State, Discount } from '../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, path } from 'rambda';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Divider from '../../common/components/Divider';
import { injectIntl } from 'react-intl';
import messages from '../../common/payment/messages/contextMenus';
import ContextMenuButton from '../../common/components/ContextMenuButton';
import { processReceivedPaymentTransactionData } from './utils';
import { toggleDisableForm, updatePaymentTransaction } from './actions';
import api from '../lib/api';
import { addError } from '../order/actions';
import { paymentOperationsName } from '../lib/api/payment';
import orderKeeper, { RequestOrderError } from '../lib/fetch/orderKeeper';
import { validDiscountsSelector } from './selectors';
import { getCurrencySymbol } from '../parameters/service';

class DiscountContextMenuContent extends React.PureComponent {
  reqOrderKeeper = orderKeeper(paymentOperationsName);

  addDiscount = (predefinedDiscountId) => {
    const { dispatch, paymentTransactionId } = this.props;

    dispatch(toggleDisableForm(true));

    this.reqOrderKeeper(() =>
        api.payment.addPredefinedDiscount(paymentTransactionId, predefinedDiscountId)
      ).then(({ body }) => {
        if (path(['payment', 'id'], body)) {
          dispatch([
            updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
            toggleDisableForm(false)
          ]);
        }
      }).catch(e => {
        if (e instanceof RequestOrderError) return;

        dispatch([
          addError(e),
          toggleDisableForm(false)
        ]);
      });
  };

  render() {
    const { intl, openCustomDiscount, closeAllContextMenus, discounts } = this.props;

    return (<Box>
      {discounts.map((discount: Discount) =>
        <ContextMenuButton
          key={discount.idriadok}
          text={discount.meno}
          onPress={() => {
            this.addDiscount(discount.idriadok);
            if (closeAllContextMenus) closeAllContextMenus();
          }}
          icon={<Text color="white" bold scale={2}>%</Text>}
          iconBackground="darkGray"
          width="auto"
        />
      )}

      {discounts.length
        ? <Divider
            color="textNormal"
            style={theme => ({ marginTop: 0, marginBottom: theme.typography.lineHeight() * 0.5 })}
          />
        : null
      }

      <ContextMenuButton
        text={intl.formatMessage(messages.percentageDiscount)}
        onPress={() => openCustomDiscount('relative')}
        icon={<Text color="white" bold scale={2}>%</Text>}
        iconBackground="darkGray"
        width="auto"
      />
      <ContextMenuButton
        text={intl.formatMessage(messages.absoluteDiscount)}
        onPress={() => openCustomDiscount('absolute')}
        icon={<Text color="white" bold scale={2}>{getCurrencySymbol()}</Text>}
        iconBackground="darkGray"
        width="auto"
      />
    </Box>);
  }
}

export default compose(
  connect((state: State) => ({
    discounts: validDiscountsSelector(state),
    paymentTransactionId: state.payment.paymentTransactionId
  })),
  injectIntl,
)(DiscountContextMenuContent);
