import api from '../../../common/lib/api';
import {
  isLoadingCategories,
  isLoadingItems
} from '../../../common/admin/usage/actions';
import { addAdminError } from '../../../common/admin/general/actions';
import { map, pluck } from 'rambda';

export const loadUsage = ({ dispatch }, { showActualUsage, dateFrom, dateTo, filter }) => {
  dispatch(isLoadingCategories(true));

  return api.usage.getUsage(showActualUsage, dateFrom, dateTo, filter)
    .then(({ body: { result: { usage } } }) => {
      dispatch(isLoadingCategories(false));
      return usage;
    }).catch(e => {
      dispatch([
        addAdminError(e),
        isLoadingCategories(false)
      ]);
    });
};

export const loadItems = ({ dispatch, categories, selectedCategoriesId }, { showActualUsage, dateFrom, dateTo }) => {
  dispatch(isLoadingItems(true));

  let selectedCategories = Object.keys(selectedCategoriesId || {});

  if (!selectedCategories.length) {
    selectedCategories = pluck('druh', categories);
  }

  return api.usage.getUsageItems(showActualUsage, dateFrom, dateTo, selectedCategories)
    .then(({ body: { result: { items } } }) => {
      // TODO check if newDateFrom === dateFrom etc.
      const newItems = map(map(item => ({
        ...item,
        id: item.nazev,
        menuName: item.nazev,
        singlePrice: item.sumCena,
        quantity: item.sumMnozstvi
      })), items);

      dispatch(isLoadingItems(false));

      return newItems;
    }).catch(e => {
      dispatch([
        addAdminError(e),
        isLoadingItems(false)
      ]);
    });
};
