// @flow
import type { Employee, State, User } from '../types';
import { createSelector } from 'reselect';
import { findUserName } from '../employees/utils';

export const meSelector = (state: State) => state.auth.user;

const employeesSelector = (state: State) => state.employees.employees;

export const myNameSelector = createSelector(
  meSelector,
  employeesSelector,
  (me: User, employees: Employee[]) => {
    if (!me) return '';

    return findUserName(me.id, employees);
  }
);
