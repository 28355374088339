// @flow
import type { ColorProps, Theme } from '../themes/types';
import type { TextProps } from './Text';
import Box from './Box';
import React from 'react';
import Text, { computeTextStyle } from './Text';
import withLongPress from '../../browser/components/hoc/withLongPress';
import preventDoubleTaps from '../../native/components/hoc/preventDoubleTaps';
import { isReactNative } from '../../common/app/detectPlatform';
import PropTypes from 'prop-types';
import theme from '../themes/theme';

export type ButtonProps = ColorProps & TextProps & {
  // For blindness accessibility features. Consider making it mandatory.
  accessibilityLabel?: string,
  children?: any,
  disabled?: boolean,
  onPress?: (e?: SyntheticMouseEvent) => any,
  outline?: boolean,
  rounded?: boolean,
  style?: (theme: Theme) => Object,
  textStyle?: (theme: Theme) => Object,
  overflow?: boolean,
  noActiveStyle?: boolean
};

type ButtonContext = {
  Button: () => React.Element<*>,
};

const Button = (
  {
    as,
    accessibilityLabel,
    style,
    felaStyle,
    children,
    disabled,
    onPress = () => {},
    outline,
    rounded,
    textStyle,
    noActiveStyle,
    ...props
  }: ButtonProps,
  {
    Button: PlatformButton,
  }: ButtonContext,
) => {
  const platformProps = isReactNative
    ? {
        accessibilityComponentType: 'button',
        accessibilityLabel,
        accessibilityTraits: ['button'],
        activeOpacity: noActiveStyle ? undefined : theme.states.active.opacity,
        onPress,
      }
    : {
        onClick: onPress,
      };

  const colorProps = Object.keys(theme.colors);

  // <Button primary
  // any is needed probably because Array find is not yet fully typed.
  const propColor: any = colorProps.find(color => props.color === color);

  const { size = 1 } = props;

  // <Button primary outline
  if (propColor && outline) {
    props = {
      ...props,
      bold: true,
      borderColor: propColor || 'white',
      borderStyle: 'solid',
      borderWidth: size >= 3 ? 4 : 2,
      color: propColor || 'white',
    };
  }

  // <Button rounded
  if (rounded) {
    props = {
      backgroundColor: 'darkGray',
      color: 'white',
      borderColor: 'white',
      borderWidth: 2,
      borderStyle: 'solid',
      ...props,
      borderRadius: theme.typography.lineHeight() * size * 0.5,
      width: size,
      height: size,
    };
  }

  // Button consists of two components, Box and Text. That's because Button can
  // render not only text, but any component, and React Native Text can't
  // contain View based components.
  // Therefore, we have to split props for Box and props for Text. Fortunately,
  // that's what computeTextStyle does by design. It picks own props and return
  // the rest. We can also use boxStyle and textStyle props for further styling.
  const [computedTextStyle, allBoxProps] = computeTextStyle(theme, props);
  // To prevent "Unknown prop" warning, we have to remove color props.
  const boxProps = colorProps.reduce(
    (props, prop) => {
      delete props[prop];
      return props;
    },
    allBoxProps,
  );
  const childrenIsString = typeof children === 'string';
  const {
    borderRadius = theme.button.borderRadius,
  } = props;

  // for browser only
  const activeStyles = noActiveStyle ? {} : {
    transition: 'transform linear 50ms',
    ':active': {
      transform: 'translateY(1px)',
    }
  };

  return (
    <Box
      as={as || PlatformButton}
      borderRadius={borderRadius}
      disabled={disabled} // Do we need that?
      flexDirection="row"
      justifyContent="center"
      opacity={disabled ? theme.states.disabled.opacity : 1}
      {...platformProps}
      {...boxProps}
      style={style}
      felaStyle={(...args) => ({
        ...(isReactNative ? null : activeStyles),
        ...(typeof felaStyle === 'function' ? felaStyle(...args) : felaStyle)
      })}
    >
      {childrenIsString
        ? <Text
            style={theme => ({
              ...computedTextStyle,
              ...(textStyle && textStyle(theme, computedTextStyle)),
              lineHeight: theme.typography.lineHeight() * size - (props.borderWidth || 0) * 2,
            })}
          >
            {children}
          </Text>
        : children}
    </Box>
  );
};

Button.contextTypes = {
  Button: PropTypes.func,
};

export default isReactNative ? preventDoubleTaps()(Button) : withLongPress()(Button);
