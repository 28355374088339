// @flow
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../common/components/Box';
import Image from '../../common/components/Image';
import Popup from '../components/Popup';
import Keyboard from '../../common/components/Keyboard';
import AuthButtons from '../../common/components/Keyboard/auth/Buttons';
import AuthDisplay from '../../common/components/Keyboard/auth/Display';
import createAuthOperations from '../../common/components/Keyboard/auth/operations';
import logoImg from '../../common/themes/assets/logo.png';
import { signIn } from '../../common/auth/actions';
import { VelocityComponent } from 'velocity-react';
import LoadingScreen from '../../common/components/LoadingScreen';
import Text from '../../common/components/Text';
import theme from '../../common/themes/theme';
import { activityRegister } from '../../common/activity/actions';

type LoginPageFormProps = {
  userIsLoggedIn: boolean
}

export const animationDuration = 500;

const Logo = <Image src={logoImg} size={{ width: '100px' }} />;

// performance reasons
class KeyboardWrapper extends React.PureComponent {
  state = {
    lastDisplayValue: '',
  };

  componentWillReceiveProps({ receivedError }) {
    if (receivedError !== this.props.receivedError) {
      if (this.Keyboard) {
        this.Keyboard.sendKey('cancel');
      }
    }
  }

  _keyboardOnLastKey = (key) => {
    const { onSubmit, dispatch } = this.props;
    const { lastDisplayValue } = this.state;

    dispatch(activityRegister());

    if (key === 'unlock') {
      onSubmit(lastDisplayValue);
      this.Keyboard.sendKey('cancel');
    }
  };

  _keyboardOnDisplayValue = (val) => {
    this.setState({ lastDisplayValue: val });
  };

  render() {
    const { formDisabled } = this.props;

    return (<Keyboard
      ref={c => { this.Keyboard = c; }}
      disabled={formDisabled}
      style={{ width: '100%' }}
      createOperations={createAuthOperations}
      Buttons={AuthButtons}
      Display={AuthDisplay}
      onLastKey={this._keyboardOnLastKey}
      onDisplayValue={this._keyboardOnDisplayValue}
    />);
  }
}

class LoginPageForm extends React.PureComponent {
  props: LoginPageFormProps;

  state = {
    willRedirect: false,
    receivedError: false
  };

  componentWillReceiveProps({ userIsLoggedIn, error }) {
    if (userIsLoggedIn !== this.props.userIsLoggedIn) {
      this.setState({
        willRedirect: userIsLoggedIn
      });
    }

    this.setState({ receivedError: error && (error !== this.props.error) });
  }

  _onSubmit = password => {
    const { dispatch } = this.props;
    dispatch(signIn(password));
  };

  render() {
    const { willRedirect, receivedError } = this.state;
    const { dispatch, formDisabled, error } = this.props;

    const keyboardWidth = theme.typography.lineHeight(5) * 1.75 * 3;

    return (
      <Box backgroundColor="appBg" height="100vh">
        {willRedirect &&
          <LoadingScreen backgroundColor="transparent" />
        }

        <VelocityComponent animation={willRedirect ? { translateY: '100%' } : null} duration={animationDuration}>
          <Popup width={20} height={32} showClose={false}>
            <Box alignItems="center">
              <Box alignItems="center" style={{ width: keyboardWidth }}>
                {Logo}

                <Box height={2} />

                <KeyboardWrapper
                  formDisabled={formDisabled}
                  onSubmit={this._onSubmit}
                  receivedError={receivedError}
                  dispatch={dispatch}
                />

                <Text color="error" marginTop={1} alignSelf="flex-start">{(error && error.toString())}&nbsp;</Text>
              </Box>
            </Box>
          </Popup>
        </VelocityComponent>
      </Box>
    );
  }
}

export default connect((state: State) => ({
  userIsLoggedIn: !!state.auth.user,
  formDisabled: state.auth.formDisabled,
  error: state.auth.error
}))(LoginPageForm);
