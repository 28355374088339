// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  receiptsTipAdded: {
    defaultMessage: 'TIP bol pridaný',
    id: 'receipts.tipAdded'
  },
  receiptsEnterTip: {
    defaultMessage: 'Zadajte TIP',
    id: 'receipts.enterTip'
  },
  receiptsChoosePayment: {
    defaultMessage: 'Výber platby',
    id: 'receipts.choosePayment'
  },
  receiptsFinishBill: {
    defaultMessage: 'Dokončenie bločku',
    id: 'receipts.finishBill'
  },
  receiptsPrintBillRepeat: {
    defaultMessage: 'Znovu vytlačiť bloček',
    id: 'receipts.printBillRepeat'
  },
  receiptsCancelPayment: {
    defaultMessage: 'Zrušiť platbu',
    id: 'receipts.cancelPayment'
  },
  receiptsSheetItemsError: {
    defaultMessage: 'Error pri vypísaní položiek účtenky: ',
    id: 'receipts.sheetItemsError'
  },
  receiptsSheetNoItems: {
    defaultMessage: 'Žiadne položky',
    id: 'receipts.sheetNoItems'
  },
  receiptsNoData: {
    defaultMessage: 'žiaden záznam',
    id: 'receipts.noData'
  },
  receiptsPrevious: {
    defaultMessage: 'Účty z min. uzávierok',
    id: 'receipts.previous'
  },
  receiptsActual: {
    defaultMessage: 'Účty v aktuál. uzávierke',
    id: 'receipts.actual'
  },
  receiptsTableId: {
    defaultMessage: 'Id',
    id: 'receipts.table.id'
  },
  receiptsTableDate: {
    defaultMessage: 'Dátum',
    id: 'receipts.table.date'
  },
  receiptsTableTable: {
    defaultMessage: 'Stôl',
    id: 'receipts.table.table'
  },
  receiptsTablePayments: {
    defaultMessage: 'Platby',
    id: 'receipts.table.payments'
  },
  receiptsTableUser: {
    defaultMessage: 'Užívateľ',
    id: 'receipts.table.user'
  },
  receiptsOperationInProgress: {
    defaultMessage: 'Prebieha operácia s účtami',
    id: 'receipts.operationInProgress'
  },
  receiptsStorno: {
    defaultMessage: 'Účtenka stornovaná',
    id: 'receipts.storno'
  },
  receiptsItemStorno: {
    defaultMessage: 'Položka účtenky stornovaná',
    id: 'receipts.item.storno'
  },
  receiptsSendPrinter: {
    defaultMessage: 'Účtenka bola odoslaná na tlač',
    id: 'receipts.send.printer'
  },
  receiptsFinish: {
    defaultMessage: 'Dokončiť bloček',
    id: 'receipts.finish'
  },
  receiptsCancelReceipt: {
    defaultMessage: 'Stornovať účet',
    id: 'receipts.cancel.receipt'
  },
  receiptsCancelChosen: {
    defaultMessage: 'Stornovať vybrané',
    id: 'receipts.cancel.chosen'
  },
  receiptsCancelMoveTable: {
    defaultMessage: 'Storno a presun na stôl',
    id: 'receipts.cancel.moveTable'
  },
  receiptsPrintCopy: {
    defaultMessage: 'Tlač kópie účtu',
    id: 'receipts.print.copy'
  },
  receiptsPaymentChange: {
    defaultMessage: 'Zmeniť platby',
    id: 'receipts.payment.change'
  },
  receiptsAddTip: {
    defaultMessage: 'Pridať TIP',
    id: 'receipts.add.tip'
  },
  receiptsCancel: {
    defaultMessage: 'Zrušiť',
    id: 'receipts.cancel'
  },
  receiptsCancelMove: {
    defaultMessage: 'Zrušiť presun na stôl',
    id: 'receipts.cancel.move'
  },
  receiptsTip: {
    defaultMessage: 'tip',
    id: 'receipts.tip'
  },
  receiptsOkp: {
    defaultMessage: 'okp',
    id: 'receipts.okp'
  },
  receiptsUid: {
    defaultMessage: 'uid',
    id: 'receipts.uid'
  },
});
