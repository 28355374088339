// @flow
import React from 'react';
import { replace } from 'connected-react-router';
import Box from '../../common/components/Box';
import Button from '../../common/components/Button';
import ComposedButton from '../../common/components/ComposedButton';
import OrderLineSheet from '../../common/components/OrderLineSheet/OrderLineSheet';
import Text from '../../common/components/Text';
import Icon from '../../common/components/Icon';
import Blocker from '../components/Blocker';
import ScrollButtons from './ScrollButtons';
import { connect } from 'react-redux';
import {
 find, propEq, map, pathOr, compose, filter
} from 'rambda';
import { leftJoin } from '../../common/lib/moreRambda';
import { sum, isEmpty } from 'ramda';
import {
  toggleFilter,
  toggleTextInput,
  toggleQuickPaymentLoading,
  setQuickPaymentMediaId,
  toggleTerminalPaymentLoading, terminalPaymentSocketMessageLoading
} from '../../common/order/actions';
import {
  addFoodCourse,
} from '../../common/tables/actions';
import type { OpenTable, State } from '../../common/types';
import {
  selectedOrderLinesSum,
  confirmOrder,
  getAllOrderLinesOfOpenTable,
  getAllOrderLinesInFocus,
  deleteAllSelectedOrderLines,
  isConfirmedAmongSelected,
  calculateOrderLinePriceWithParams,
  formatPriceToFixed,
  getQuickPayments
} from '../../common/order/utils';
import { findParamValue } from '../../common/parameters/utils';
import { animateScroll } from 'react-scroll';
import OrderLineSheetContextMenu from './ContextMenus';
import { undeletedOpenTablesSelector } from '../../common/tables/selectors';
import checkPermission from '../../common/permissions/service';
import { checkIfIsBar, parsePrgDotaz } from '../../common/payment/utils';
import SubTables from './SubTables';
import { prgDotazIsValid } from '../../common/payment/prgDotazy';
import {
  showPrgDotaz,
  toggleNotePopupOpen
} from '../../common/payment/actions';
import { toggleLoyalmanUsers } from '../../common/loyalman/users/actions';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/order';
import PaymentNotePopup from '../payment/PaymentNotePopup';
import { getCurrencySymbol } from '../../common/parameters/service';

class Sidebar extends React.Component {
  state = {
    showBlocker: false,
    scrollContainerId: 'OrderLineSheetScrollContainer',
    addNoteStep: false,
    addNoteStepPayment: null
  };

  componentDidUpdate() {
    const { dispatch, openTables, activeOpenTableId } = this.props;

    const activeOpenTable = find(propEq('id', activeOpenTableId), openTables);

    // TODO hoc
    if (!activeOpenTable) {
      dispatch(replace('/serviceAreas'));
    }
  }

  _showBlocker = () => {
    this.setState({ showBlocker: true });
  };

  _hideBlocker = () => {
    this.setState({ showBlocker: false });
  };

  togglePaymentNote = (toggle, payment = null) => {
    const { dispatch } = this.props;
    this.setState({
      addNoteStep: toggle,
      addNoteStepPayment: payment
    });
    dispatch(toggleNotePopupOpen(toggle));
  };

  addFoodCourse = (openTableId) => {
    const { dispatch } = this.props;

    dispatch(addFoodCourse(openTableId));

    setTimeout(() =>
      animateScroll.scrollToBottom({
        containerId: this.state.scrollContainerId,
        duration: 400
      }),
      300);
  };

  orderSum() {
    const {
      orderLines,
      activeOpenTableId,
      activeSubTableId,
      subTables,
      allParameters
    } = this.props;

    return sum(
      map(orderLine => calculateOrderLinePriceWithParams(orderLine, allParameters.parameters) || 0,
        getAllOrderLinesInFocus(subTables, orderLines, activeOpenTableId, activeSubTableId)
      )
    );
  }

  submitOrder(flags = {}) {
    const { dispatch, activeOpenTableId, orderLines, subTables } = this.props;
    if (!activeOpenTableId) return;

    dispatch(confirmOrder(
      activeOpenTableId,
      orderLines,
      subTables,
      flags
    ));
  }

  submitOrderAndClearSelected() {
    this.submitOrder({ redirectOffline: '/serviceAreas', clearSelected: true });
  }

  goPay() {
    const { selectedOrderLines, subTables, orderLines, activeOpenTableId, activeSubTableId } = this.props;

    const orderLineIdsInFocus = selectedOrderLines.length
      ? []
      : map(
          ol => ol.id,
          getAllOrderLinesInFocus(subTables, orderLines, activeOpenTableId, activeSubTableId)
        );

    this.submitOrder({ redirect: '/payment', orderLineIdsInFocus });
  }

  quickPay = (paymentMedia, additionalData) => {
    const {
      dispatch, activeOpenTableId, openTables, authDefaultPrinter, printers
    } = this.props;

    const prgDotazParsed = parsePrgDotaz(paymentMedia.prg_dotaz);

    const onTerminalMessage = message => {
      dispatch([
        terminalPaymentSocketMessageLoading(message)
      ]);
    };

    if (prgDotazIsValid(prgDotazParsed)) {
      const amount = this.orderSum() * 100;
      dispatch(showPrgDotaz(prgDotazParsed, { ...paymentMedia, amount }));
    } else {
      const isBar = checkIfIsBar(activeOpenTableId, openTables);
      dispatch([
        setQuickPaymentMediaId(paymentMedia.id),
        toggleQuickPaymentLoading(true),
        toggleTerminalPaymentLoading(paymentMedia.terminal, onTerminalMessage)
      ]);

      this.submitOrder({
        payAfterConfirmWith: paymentMedia.id,
        additionalData,
        isBar,
        redirect: '/serviceAreas'
      });
    }
  };

  _isOrderEmpty = () => {
    const { subTables, orderLines, activeOpenTableId, activeSubTableId } = this.props;
    return isEmpty(
      getAllOrderLinesOfOpenTable(subTables, orderLines, activeOpenTableId, activeSubTableId)
    );
  };

  openLoyalMan = () => {
    const { dispatch } = this.props;
    dispatch(toggleLoyalmanUsers(true, null, null, true, true));
  };

  render() {
    const {
      dispatch,
      openTables,
      orderLines,
      selectedOrderLines,
      activeOpenTableId,
      parameters,
      allParameters,
      paymentMedia,
      paymentTypes,
      isRelocatingToFoodCourse,
      isRelocatingToTable,
      isSubTableMenuOpen,
      subTables,
      activeSubTableId,
      activeIsLimit,
      isConnected,
      intl
  } = this.props;

    const { addNoteStep, addNoteStepPayment } = this.state;

    if (!activeOpenTableId) return null;

    const activeOpenTable: OpenTable = find(propEq('id', activeOpenTableId), openTables);

    if (!activeOpenTable) return null;

    const quickPayments = getQuickPayments(parameters, paymentTypes, paymentMedia);

    const isAnyQuickPaymentButton = !isEmpty(quickPayments) && !activeOpenTable.barCash;
    const paramIsHores = findParamValue('K32.is_hores', parameters);
    const paramOnlyNonFiscal = findParamValue('K32.len_nefis_platby', parameters);

    const paymentMediaWithTypes = leftJoin(
      ({ idriadok }, { druhyPlId }) => idriadok === druhyPlId,
      paymentTypes,
      paymentMedia
    );

    const paramBarPayInCash = pathOr(
        false,
        'id',
        find(
          propEq('nazev', findParamValue('K32.bar_pay_in_cash', parameters)),
          paymentMediaWithTypes
        )
      );

    const allSeletedOrderLines = getAllOrderLinesInFocus(subTables, orderLines, activeOpenTableId, activeSubTableId);
    const isLimitPayAll = activeIsLimit && selectedOrderLines.length && selectedOrderLines.length < allSeletedOrderLines.length;

    const enableLoyalman = findParamValue('K32.enable_loyalman', parameters);

    return (
      <Box
        backgroundColor="appBg"
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        {this.state.showBlocker
          ? (
              <Blocker
                actionConfirm={() => {
                  this._hideBlocker();
                  deleteAllSelectedOrderLines(dispatch, orderLines, selectedOrderLines);
                }}
                actionCancel={() => this._hideBlocker()}
                textHeader={intl.formatMessage(messages.orderSidebarHeader)}
                textDescription={intl.formatMessage(messages.orderSidebarDescription)}
                textConfirm={intl.formatMessage(messages.orderSidebarConfirm)}
                textCancel={intl.formatMessage(messages.orderSidebarCancel)}
              />
            ) : null}

        {selectedOrderLines.length && !isSubTableMenuOpen && !isRelocatingToFoodCourse && !isRelocatingToTable
          ? (
              <OrderLineSheetContextMenu
              target={this}
              backgroundColor={isConfirmedAmongSelected(selectedOrderLines, orderLines) ? 'lightTeal' : 'popupBg'}
              confirmDelete={this._showBlocker}
              />
          ) : null}

        {addNoteStep && (
          <PaymentNotePopup
            onSubmit={(notes) => {
              this.quickPay(addNoteStepPayment, { notes });
              this.togglePaymentNote(false);
            }}
            onClose={() => this.togglePaymentNote(false)}
          />
        )}

        <Box zIndex={1} flex={1}>
          <Box paddingHorizontal={1} flex={1}>
            <SubTables />
            <OrderLineSheet
              isSubTableMenuOpen={isSubTableMenuOpen}
              scrollContainerId={this.state.scrollContainerId}
              addFoodCourse={this.addFoodCourse}
            />
          </Box>

          <Box paddingHorizontal={1} paddingVertical={0.25} zIndex={5} flexShrink={0}>
            <Box
              paddingVertical={0.25}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ScrollButtons containerId={this.state.scrollContainerId} />

              <Box flexDirection="row" alignItems="baseline" justifyContent="center">
                <Text color="white" marginRight={0.5}>
                  {`${formatPriceToFixed(selectedOrderLinesSum(orderLines, selectedOrderLines, allParameters))} ${getCurrencySymbol()} ${intl.formatMessage(messages.orderSidebarFrom)}`}
                </Text>
                <Text color="white" scale={3} decoration="underline">{formatPriceToFixed(this.orderSum())} {getCurrencySymbol()}</Text>
              </Box>

              <Box flexDirection="row">
                {enableLoyalman &&
                  <Button
                    rounded
                    size={2}
                    lineHeight="normal"
                    alignSelf="flex-start"
                    overflow="hidden"
                    alignItems="center"
                    justifyContent="center"
                    marginRight={0.5}
                    onPress={() => this.openLoyalMan()}
                  >
                    <Icon color="white" name="consume-card" scale={2} />
                  </Button>
                }
                <Button
                  rounded
                  size={2}
                  lineHeight="normal"
                  alignSelf="flex-start"
                  overflow="hidden"
                  alignItems="center"
                  justifyContent="center"
                  onPress={() => {
                    dispatch([
                      toggleFilter(true),
                      toggleTextInput('filter')
                    ]);
                  }}
                >
                  <Icon color="white" name="barcode" scale={2} />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        {isAnyQuickPaymentButton && checkPermission('payment.add') &&
        <Box
          paddingBottom={0}
          marginHorizontal={0.75}
          flexDirection="row"
          justifyContent="center"
          zIndex={10}
          flexShrink={0}
        >
          {quickPayments.map(quickPayment =>
            <Box
              key={quickPayment.id}
              flexGrow={1}
              flexShrink={0}
              paddingHorizontal={0.25}
              flexBasis={`${100 / quickPayments.length}%`}
              maxWidth={`${100 / quickPayments.length}%`}
            >
              <ComposedButton
                disabled={this._isOrderEmpty() || (paramOnlyNonFiscal ? quickPayment.cisfplat !== '0' : false)
                || isLimitPayAll || (quickPayment.nazev === 'N_HOTEL' && !paramIsHores) || !isConnected}
                caption={quickPayment.name}
                backgroundColor="blue"
                shadowColor="darkBlue"
                icon="credit-card"
                onPress={() => this.quickPay(quickPayment)}
                onLongPress={() => this.togglePaymentNote(true, quickPayment)}
              />
            </Box>
          )}
        </Box>}

        <Box
          paddingTop={isAnyQuickPaymentButton ? 0 : 1}
          paddingBottom={0.25}
          marginHorizontal={0.75}
          flexDirection="row"
          justifyContent="center"
          zIndex={10}
          flexShrink={0}
        >
          {checkPermission('payment.add') &&
          <Box
            flex={1}
            flexBasis="50%"
            paddingHorizontal={0.25}
          >
            <ComposedButton
              disabled={this._isOrderEmpty() || isLimitPayAll || !isConnected}
              caption={activeOpenTable.barCash
                ? intl.formatMessage(messages.orderSidebarPayCash)
                : selectedOrderLines.length
                  ? intl.formatMessage(messages.orderSidebarPaySelected)
                  : intl.formatMessage(messages.orderSidebarPayAll)
              }
              backgroundColor="blue"
              shadowColor="darkBlue"
              icon="pay"
              onPress={() => activeOpenTable.barCash
                ? this.quickPay({ id: paramBarPayInCash || 1 })
                : this.goPay()}
            />
          </Box>
          }
          {!activeOpenTable.barCash && !activeOpenTable.bar && checkPermission('orderline.confirmation.add') &&
            <Box
              flex={1}
              flexBasis="50%"
              paddingHorizontal={0.25}
            >
              <ComposedButton
                disabled={this._isOrderEmpty()}
                caption={intl.formatMessage(messages.orderSidebarCaptionConfirm)}
                backgroundColor="teal"
                shadowColor="darkTeal"
                icon="check"
                onPress={() => this.submitOrderAndClearSelected()}
              />
            </Box>
          }
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect(
    (state: State) => ({
      subTables: state.tables.subTables,
      openTables: undeletedOpenTablesSelector(state),
      selectedOrderLines: state.orders.selectedOrderLines,
      isRelocatingToTable: state.orders.active.isRelocatingToTable,
      isRelocatingToFoodCourse: state.orders.active.isRelocatingToFoodCourse,
      activeSubTableId: state.tables.active.subTableId,
      activeOpenTableId: state.tables.active.openTableId,
      orderLines: state.orders.orderLines,
      parameters: state.parameters.parameters,
      allParameters: state.parameters,
      paymentMedia: state.payment.paymentMedia,
      paymentTypes: state.payment.paymentTypes,
      isSubTableMenuOpen: state.orders.active.isSubTableMenuOpen,
      activeIsLimit: state.serviceAreas.activeIsLimit,
      authDefaultPrinter: state.auth.defaultPrinter,
      printers: state.peripherals.printers,
      sessionId: state.device.sessionId,
      isConnected: state.network.isConnected
    })
  ),
  injectIntl
)(Sidebar);
