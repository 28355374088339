// @flow
import type { KeyboardButtonsProps } from '../index';
import React from 'react';
import Box from '../../Box';
import KbButton from '../CalculatorButton';
import { injectIntl } from 'react-intl';
import Icon from '../../../../browser/components/Icon';
import messages from '../messages/buttons';

class Buttons extends React.PureComponent<KeyboardButtonsProps> {
  render() {
    const { onPress, intl, disabled } = this.props;

    return (
      <Box flexDirection="row">
          <Box width="75%">
            <Box flexDirection="row" flexShrink={0}>
              <KbButton char={7} onPress={onPress} borderRight borderTop disabled={disabled} />
              <KbButton char={8} onPress={onPress} borderRight borderTop disabled={disabled} />
              <KbButton char={9} onPress={onPress} borderRight borderTop disabled={disabled} />
            </Box>
            <Box flexDirection="row" flexShrink={0}>
              <KbButton char={4} onPress={onPress} borderRight borderTop disabled={disabled} />
              <KbButton char={5} onPress={onPress} borderRight borderTop disabled={disabled} />
              <KbButton char={6} onPress={onPress} borderRight borderTop disabled={disabled} />
            </Box>
            <Box flexDirection="row" flexShrink={0}>
              <KbButton char={1} onPress={onPress} borderRight borderTop disabled={disabled} />
              <KbButton char={2} onPress={onPress} borderRight borderTop disabled={disabled} />
              <KbButton char={3} onPress={onPress} borderRight borderTop disabled={disabled} />
            </Box>
            <Box flexDirection="row" flexShrink={0}>
              <KbButton char={0} onPress={onPress} borderRight borderTop disabled={disabled} />
              <KbButton char={intl.formatMessage(messages.dot)} onPress={onPress} borderRight borderTop sendChar="." disabled={disabled} />
              <KbButton char="&minus;" onPress={onPress} borderRight borderTop sendChar="-" operator disabled={disabled} />
            </Box>
          </Box>
          <Box flexDirection="row" flexShrink={0} width="25%">
            <KbButton char="ok" onPress={onPress} borderTop disabled={disabled} primary heighMultiplier={4}>
              <Icon name="check" scale={4} color="white" />
            </KbButton>
          </Box>
      </Box>
    );
  }
}

export default injectIntl(Buttons);
