// @flow
import type { KeyboardDisplayProps } from '../index';
import React from 'react';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../../../browser/components/Icon';
import TextInput from '../../TextInput';
import theme from '../../../themes/theme';

const formatValue = value => {
  const replaced = value.replace(/./g, '*');
  return replaced;
};

class Display extends React.PureComponent {
  props: KeyboardDisplayProps;

  _onInputKeyPress = ({ key, charCode }) => {
    const { onPress } = this.props;

    onPress(charCode === 13 ? 'unlock' : key);
    if (charCode === 13 && this.input) { // enter
      // fixme
      setTimeout(() => this.input.focus(), 200);
    }
  };

  _onInputKeyDown = ({ keyCode }) => {
    const { onPress } = this.props;

    if (keyCode === 8) { // backspace
      onPress('backspace');
    }
    if (keyCode === 13 && this.input) { // enter
      // fixme
      setTimeout(() => this.input.focus(), 200);
    }
  };

  _onCancelPress = () => {
    const { onPress } = this.props;
    onPress('cancel');

    if (this.input) {
      this.input.focus();
    }
  };

  render() {
    const { value, disabled, lastKey } = this.props;

    // fixme
    if (lastKey === 'unlock' && this.input) {
      setTimeout(() => this.input.focus(), 200);
    }

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor="teal"
        borderStyle="solid"
        borderColor="teal"
        borderLeftWidth={`${theme.baseSize * 1.5}px`}
        height={`${theme.typography.lineHeight(5) * 1.75}px`}
      >
        <TextInput
          nativeRef={c => { this.input = c; }}
          autoFocus
          backgroundColor="teal"
          flexGrow={1}
          bold
          color="white"
          scale={5}
          justifyContent="center"
          height="100%"
          overflow="auto"
          whiteSpace="nowrap"
          style={{ letterSpacing: '0.5rem' }}
          value={formatValue(value)}
          onKeyPress={this._onInputKeyPress}
          onKeyDown={this._onInputKeyDown}
          disabled={disabled}
        />
        <Button
          onPress={this._onCancelPress}
          paddingHorizontal={`${theme.baseSize * 1.5}px`}
          disabled={disabled}
          opacity={1}
        >
          <Icon
            name="delete"
            color="textInverse"
            justifyContent="center"
            scale={2}
          />
        </Button>
      </Box>
    );
  }
}

export default Display;
