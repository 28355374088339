// @flow
import { forEachObjIndexed, zipObj } from 'ramda';
import { map } from 'rambda';
import moment from 'moment';

let headers = {};

export const setHeader = (headerName: string, value: string | Function) => {
  headers[headerName] = value;
};

export const clearHeaders = () => {
  headers = {};
};

export const getHeaders = (url: string, payload: string): Promise => {
  const headersObject = { ...headers };
  headersObject['X-Alto-Time'] = moment().format('YYYY-MM-DDTHH:mm:ss.sss');

  const plainHeadersNames = Object.keys(headersObject);
  const promiseHeadersValues = map((val) => {
      const newVal = (typeof val === 'function' ? val(url, payload) : val);
      return (newVal === undefined || newVal === null) ? '' : newVal;
    },
    Object.values(headersObject));

  return Promise.all(promiseHeadersValues).then(plainHeadersValues =>
    zipObj(plainHeadersNames, plainHeadersValues));
};

const headerDict = headers => {
  let dict = {};

  // eslint-disable-next-line no-undef
  if (headers instanceof Headers) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of headers.entries()) {
      dict[key] = value;
    }
  } else {
    dict = headers;
  }
  return dict;
};

export const setDefaultHeaders = () => {
  clearHeaders();
  setHeader('Content-Type', 'application/json; charset=utf-8');
  setHeader('Accept', 'application/json');
};

export const setCustomHeaders = (headers: Object) => {
  setDefaultHeaders();
  forEachObjIndexed((val, key) => setHeader(key, val), headers);
};

export const prepareHeaders = (url: string, params: Object = {}): Promise => {
  const preparedParams = { ...params };
  const dynamicHeaders = getHeaders(url, preparedParams.data);

  return dynamicHeaders.then(finishedHeaders => {
    preparedParams.headers = { ...headerDict(preparedParams.headers), ...finishedHeaders };
    return { url, params: preparedParams };
  });
};
