// @flow
import React from 'react';
import { withContentRect } from 'react-measure';
import { compose, find, pathOr, propEq } from 'rambda';
import { connect } from 'react-redux';
import Canvas from '../components/Canvas';
import { TableWithContextMenu as Table } from '../components/Table';
import type { ServiceArea, State, TableDefinition } from '../../common/types';
import { canEditTable } from '../components/TableArea';
import { ClosedTableMenu, EditTableMenu, RelocatingTableMenu } from './ContextMenus';
import Box from '../../common/components/Box';
import {
  findUsedOpenTables,
} from '../../common/tables/utils';
import {
  sortedTablesWithOpenTablesSelector,
  tablesInActiveServiceAreaSelector,
  undeletedOpenTablesSelector,
  undeletedSubTablesSelector,
} from '../../common/tables/selectors';
import theme from '../../common/themes/theme';
import checkPermission from '../../common/permissions/service';
import messages from '../../common/serviceAreas/messages/options';
import serviceAreasMessages from '../../common/messages/serviceAreas';

import { injectIntl } from 'react-intl';
import { undeletedOrderLinesSelector } from '../../common/order/selectors';

class Tables extends React.PureComponent {
  render() {
    const {
      measureRef,
      contentRect,
      tables,
      isEditing,
      openTables,
      isRelocatingToTable,
      isCancelingReceiptAndReturningToTable,
      showTablesList,
      serviceAreas,
      subTables,
      orderLines,
      intl
    } = this.props;

    const hasAccess = checkPermission('tabledefinition.read') && checkPermission('opentable.read');
    if (!hasAccess) {
      return (
        <div ref={measureRef} style={{ display: 'flex', width: '100%' }}>
          {intl.formatMessage(serviceAreasMessages.serviceAreasTablesGridAccessDenied)}
        </div>
      );
    }

    const { bounds: { width: contentWidth } } = contentRect;

    const tableWidth = Math.floor(contentWidth / 2) - theme.typography.lineHeight() - 10;

    let tablesList = tables;

    if (showTablesList) {
      const openTablesIds = tables.map(table => pathOr(undefined, 'openTable.id', table));
      const subTablesByOpenTables = subTables.filter(subTable => openTablesIds.includes(subTable.openTableId));
      tablesList = tables.filter(table => {
        const subTable = subTablesByOpenTables.find(subTable => subTable.openTableId === pathOr(undefined, 'openTable.id', table));
        return !!subTable && !!orderLines.find(orderLine => orderLine.subTableId === subTable.id);
      });
    }

    return (
      <div ref={measureRef} style={{ display: 'flex', width: '100%', height: '100%' }}>
        <Canvas
          bgType="grid"
          flex={1}
          flexDirection="row"
          flexWrap="wrap"
          alignContent="flex-start"
          overflow="auto"
        >
          <Box height={1} width="100%" />

          {contentWidth && tablesList.map((table: TableDefinition, i) => {
            const isReallyEditing = isEditing && canEditTable(table);

            let serviceAreaName;

            if (showTablesList) {
              const serviceArea: ServiceArea = find(propEq('id', table.serviceAreaId), serviceAreas);
              serviceAreaName = !serviceArea || serviceArea.isVirtual
                ? intl.formatMessage(messages.noMap)
                : serviceArea.name;
            }

            const tableName = showTablesList ? `${table.name} (${serviceAreaName})` : table.name;

            const tableWithStackGeometry = {
              ...table,
              name: tableName,
              geometry: {
                x: 0, y: 0, width: tableWidth, height: 100
              }
            };

            const isLimit = table.limitDayId;

            return (
              <Box key={table.id} marginHorizontal={0.5} marginBottom={1}>
                <Table
                  isEditing={isReallyEditing}
                  table={tableWithStackGeometry}
                  animate={{
                    animation: 'transition.slideDownIn',
                    duration: 125,
                    delay: i * 50,
                    runOnMount: true
                  }}
                  ContextMenu={
                    (isRelocatingToTable || isCancelingReceiptAndReturningToTable)
                      ? RelocatingTableMenu
                      : isLimit
                        ? null
                        : isReallyEditing
                          ? EditTableMenu
                          : findUsedOpenTables(table.id, openTables).length
                            ? ClosedTableMenu
                            : null
                  }
                  ContextMenuBoundaries={this}
                />
              </Box>
            );
          })}
        </Canvas>
      </div>
    );
  }
}

export default compose(
  withContentRect('bounds'),
  injectIntl,
  connect((state: State) => ({
    openTables: undeletedOpenTablesSelector(state),
    isEditing: state.tables.editPosition,
    isRelocatingToTable: state.orders.active.isRelocatingToTable,
    isCancelingReceiptAndReturningToTable: state.admin.receipts.isCancelingReceiptAndReturningToTable,
    showTablesList: state.serviceAreas.showTablesList,
    serviceAreas: state.serviceAreas.serviceAreas,
    tables: state.serviceAreas.showTablesList
      ? sortedTablesWithOpenTablesSelector(state)
      : tablesInActiveServiceAreaSelector(state),
    subTables: undeletedSubTablesSelector(state),
    orderLines: undeletedOrderLinesSelector(state)
  }))
)(Tables);
