// @flow
import React from 'react';
import { Route, Switch } from 'react-router';
import ReceiptsPage from './receipts/ReceiptsPage';
import FiscalModulePage from './fiscal/module/FiscalModulePage';
import FiscalConfirmPage from './fiscal/confirm/FiscalConfirmPage';
import FiscalTimePage from './fiscal/time/FiscalTimePage';
import FiscalResetPage from './fiscal/reset/FiscalResetPage';
import AlcoholPage from './alcohol/AlcoholPage';
import PriceListPage from './priceList/PriceListPage';
import MessagePage from './message/MessagePage';
import UsagePage from './usage/UsagePage';
import MealsCountPage from './mealsCount/MealsCountPage';
import IntermediateClosurePage from './closures/intermediate/IntermediateClosurePage';
import NewClosurePage from './closures/new/NewClosurePage';
import IntervalClosurePage from './closures/interval/IntervalClosurePage';
import ClosureHistoryPage from './closures/history/ClosureHistoryPage';
import JournalPage from './closures/journal/JournalPage';
import BulkClosePage from './closures/bulkClose/BulkClosePage';
import SummaryClosurePage from './closures/summary/SummaryClosurePage';
import SummaryMultipleClosurePage from './closures/summaryMultiple/SummaryMultipleClosurePage';

class AdminPage extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route path="/admin/receipts" component={ReceiptsPage} />
        <Route path="/admin/fiscal/module" component={FiscalModulePage} />
        <Route path="/admin/fiscal/confirm" component={FiscalConfirmPage} />
        <Route path="/admin/fiscal/time" component={FiscalTimePage} />
        <Route path="/admin/fiscal/reset" component={FiscalResetPage} />
        <Route path="/admin/alcohol" component={AlcoholPage} />
        <Route path="/admin/priceList" component={PriceListPage} />
        <Route path="/admin/message" component={MessagePage} />
        <Route path="/admin/usage" component={UsagePage} />
        <Route path="/admin/mealsCount" component={MealsCountPage} />
        <Route path="/admin/closures/intermediate" component={IntermediateClosurePage} />
        <Route path="/admin/closures/new" component={NewClosurePage} />
        <Route path="/admin/closures/interval" component={IntervalClosurePage} />
        <Route path="/admin/closures/summary" component={SummaryClosurePage} />
        <Route path="/admin/closures/summaryMultiple" component={SummaryMultipleClosurePage} />
        <Route path="/admin/closures/history" component={ClosureHistoryPage} />
        <Route path="/admin/closures/journal" component={JournalPage} />
        <Route path="/admin/closures/bulkClose" component={BulkClosePage} />
      </Switch>
    );
  }
}

export default AdminPage;
