// @flow
import { ApiError } from './errors';
import { signOut } from '../../auth/actions';

export const signOutOn401 = dispatch => error => {
  if (error instanceof ApiError && error.status === 401) {
    dispatch(signOut());
  }
};

