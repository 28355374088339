// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  paymentPgrDotazyGuestName: {
    defaultMessage: 'Meno hosťa',
    id: 'payment.pgrDotazy.guestName'
  },
  paymentPgrDotazySaveName: {
    defaultMessage: 'Uložiť meno',
    id: 'payment.pgrDotazy.saveName'
  },
  paymentPgrDotazyPaymentApproved: {
    defaultMessage: 'Platbu schválil…',
    id: 'payment.pgrDotazy.paymentApproved'
  },
  paymentPgrDotazyNewPartner: {
    defaultMessage: 'Nový úverový partner',
    id: 'payment.pgrDotazy.newPartner'
  },
  paymentPgrDotazyAddPartner: {
    defaultMessage: 'Pridať nového partnera',
    id: 'payment.pgrDotazy.addPartner'
  },
  paymentPgrDotazyChooseGuest: {
    defaultMessage: 'Výber hosťa',
    id: 'payment.pgrDotazy.chooseGuest'
  },
  paymentPgrDotazySkipChooseGuest: {
    defaultMessage: 'Preskočiť výber hosťa',
    id: 'payment.pgrDotazy.skipChooseGuest'
  },
  paymentPgrDotazyRoomNumber: {
    defaultMessage: 'Číslo izby',
    id: 'payment.pgrDotazy.roomNumber'
  },
  paymentPgrDotazyChooseOption: {
    defaultMessage: 'Výber z možností',
    id: 'payment.pgrDotazy.chooseOption'
  },
  paymentPgrDotazyNumber: {
    defaultMessage: 'Číslo',
    id: 'payment.pgrDotazy.number'
  },
  paymentPgrDotazySetRoomNumber: {
    defaultMessage: 'Zadať názov izby',
    id: 'payment.pgrDotazy.setRoomNumber'
  },
  paymentPgrDotazyReadGuestCard: {
    defaultMessage: 'Načítať hosťovskú kartu',
    id: 'payment.pgrDotazy.readGuestCard'
  },
  title: {
    defaultMessage: 'Platba',
    id: 'payment.page.title'
  },
  closePayment: {
    defaultMessage: 'Zavrieť platbu',
    id: 'payment.page.closePayment'
  },
  overallSum: {
    defaultMessage: 'Celková suma',
    id: 'payment.page.overallSum'
  },
  overallSumAfterDiscount: {
    defaultMessage: 'Suma po zľave',
    id: 'payment.page.overallSumAfterDiscount'
  },
  tip: {
    defaultMessage: 'TIP',
    id: 'payment.page.tip'
  },
  leftToPay: {
    defaultMessage: 'Zostáva doplatiť',
    id: 'payment.page.leftToPay'
  },
  leftToPayTip: {
    defaultMessage: 'Preplatok (TIP)',
    id: 'payment.page.leftToPayTip'
  },
  leftToPaySurplus: {
    defaultMessage: 'Preplatok',
    id: 'payment.page.leftToPaySurplus'
  },
  leftToPayExact: {
    defaultMessage: 'Zaplatené presne',
    id: 'payment.page.leftToPayExact'
  },
  setDiscount: {
    defaultMessage: 'Zadať zľavu',
    id: 'payment.page.setDiscount'
  },
  subtotal: {
    defaultMessage: 'Medzivýsledky',
    id: 'payment.page.subtotal'
  },
  cash: {
    defaultMessage: 'V hotovosti',
    id: 'payment.page.cash'
  },
  bankCard: {
    defaultMessage: 'Banková karta',
    id: 'payment.page.bankCard'
  },
  mealVouchers: {
    defaultMessage: 'Stravné lístky',
    id: 'payment.page.mealVouchers'
  },
  backToTable: {
    defaultMessage: 'Naspäť na stôl',
    id: 'payment.page.backToTable'
  },
  backToPayment: {
    defaultMessage: 'Naspäť na platbu',
    id: 'payment.page.backToPayment'
  },
  backPayment: {
    defaultMessage: 'Späť na platbu',
    id: 'payment.page.backPayment'
  },
  finishAndPrintReceipt: {
    defaultMessage: 'Uzavrieť účet a tlačiť blok',
    id: 'payment.page.finishAndPrintReceipt'
  },
  printPrereceipt: {
    defaultMessage: 'Tlačiť predúčet',
    id: 'payment.page.printPrereceipt'
  },
  dot: {
    defaultMessage: ',',
    id: 'payment.page.dot'
  },
  closeDiscountMenu: {
    defaultMessage: 'Zavrieť výber zľavy',
    id: 'payment.page.closeDiscountMenu'
  },
  discount: {
    defaultMessage: 'Zľava',
    id: 'payment.page.discount'
  },
  discountReason: {
    defaultMessage: 'Dôvod zľavy',
    id: 'payment.page.discountReason'
  },
  relative: {
    defaultMessage: '%',
    id: 'payment.page.relative'
  },
  absolute: {
    defaultMessage: 'EUR',
    id: 'payment.page.absolute'
  },
  pay: {
    defaultMessage: 'Platiť',
    id: 'payment.page.pay'
  },
  remaind: {
    defaultMessage: 'zostatok',
    id: 'payment.page.remaind'
  },

  hjmeno: {
    defaultMessage: 'Firma',
    id: 'payment.page.company'
  },
  adresa1: {
    defaultMessage: 'Ulica',
    id: 'payment.page.address1'
  },
  adresa2: {
    defaultMessage: 'Mesto, PSČ',
    id: 'payment.page.address2'
  },
  adresa3: {
    defaultMessage: 'Krajina',
    id: 'payment.page.address3'
  },
  ico: {
    defaultMessage: 'IČO',
    id: 'payment.page.ico'
  },
  dic: {
    defaultMessage: 'DIČ',
    id: 'payment.page.dic'
  },
  icdph: {
    defaultMessage: 'IČ DPH',
    id: 'payment.page.icdph'
  },
  schvalil: {
    defaultMessage: 'Schválil',
    id: 'payment.page.schvalil'
  },
  txt: {
    defaultMessage: 'Poznámka',
    id: 'payment.page.txt'
  },
  name: {
    defaultMessage: 'Meno',
    id: 'payment.page.name'
  },
  registerParagon: {
    defaultMessage: 'Registrovať paragon',
    id: 'payment.page.registerParagon'
  },
  printer: {
    defaultMessage: 'Tlačiareň',
    id: 'payment.page.printer'
  },
  copies: {
    defaultMessage: 'Kópií',
    id: 'payment.page.copies'
  },
  sendReceiptByEmail: {
    defaultMessage: 'Poslať bloček mailom',
    id: 'payment.page.sendReceiptByEmail'
  },
  percentageDiscount: {
    defaultMessage: 'Percentuálna',
    id: 'payment.contextMenus.percentageDiscount'
  },
  absoluteDiscount: {
    defaultMessage: 'Absolútna',
    id: 'payment.contextMenus.absoluteDiscount'
  },
  relativeDiscount: {
    defaultMessage: 'Percentuálna',
    id: 'payment.contextMenus.relativeDiscount'
  },
  roomService: {
    defaultMessage: 'Prirážka room service',
    id: 'payment.contextMenus.roomService'
  },
  discount5Percent: {
    defaultMessage: 'Zľava 5%',
    id: 'payment.contextMenus.discount5Percent'
  },
  discount10Percent: {
    defaultMessage: 'Zľava 10%',
    id: 'payment.contextMenus.discount10Percent'
  },
  discount15Percent: {
    defaultMessage: 'Zľava 15%',
    id: 'payment.contextMenus.discount15Percent'
  },
  discount20Percent: {
    defaultMessage: 'Zľava 20%',
    id: 'payment.contextMenus.discount20Percent'
  },
  discount25Percent: {
    defaultMessage: 'Zľava 25%',
    id: 'payment.contextMenus.discount25Percent'
  },
  paymentEditPaymentRecordError: {
    defaultMessage: 'Error pri editovaní platby: ',
    id: 'payment.editPaymentRecord.error'
  },
  paymentEditPaymentRecordPrice: {
    defaultMessage: 'Cena',
    id: 'payment.editPaymentRecord.price'
  },
  paymentEditPaymentRecordTip: {
    defaultMessage: 'Tip',
    id: 'payment.editPaymentRecord.tip'
  },
  paymentNumberOfCopiesHeader: {
    defaultMessage: 'Zadajte počet kópií',
    id: 'payment.numberOfCopies.header'
  },
  paymentPaymentNoteHeader: {
    defaultMessage: 'Poznámky k platbe',
    id: 'payment.numberOfCopies.paymentNote.header'
  },
  paymentPaymentPageUnfinishedBill: {
    defaultMessage: 'Chyba bločku - dokončiť bloček',
    id: 'payment.paymentPage.unfinishedBill'
  },
  paymentPrinterSelect: {
    defaultMessage: 'Vyberte tlačiareň',
    id: 'payment.printerSelect'
  },
  paymentRegisterParagonId: {
    defaultMessage: 'Paragon Id',
    id: 'payment.registerParagon.id'
  },
  paymentRegisterParagonDate: {
    defaultMessage: 'Dátum',
    id: 'payment.registerParagon.date'
  },
  paymentRegisterParagonTime: {
    defaultMessage: 'Čas',
    id: 'payment.registerParagon.time'
  },
  paymentSendReceiptByEmail: {
    defaultMessage: 'e-mail',
    id: 'payment.sendReceiptByEmail'
  },
  paymentServiceCouponCode: {
    defaultMessage: 'Kód kupónu',
    id: 'payment.serviceCoupon.code'
  },
  paymentTransactionErrorHeader: {
    defaultMessage: 'Už existuje platobná transakcia pre vybrané položky',
    id: 'payment.transactionError.header'
  },
  paymentTransactionErrorWaiter: {
    defaultMessage: 'Čašník',
    id: 'payment.transactionError.waiter'
  },
  paymentTransactionErrorItems: {
    defaultMessage: 'Položky',
    id: 'payment.transactionError.items'
  },
  paymentTransactionErrorBack: {
    defaultMessage: 'Vrátiť sa',
    id: 'payment.transactionError.back'
  },
  paymentTransactionErrorCancelAndCreate: {
    defaultMessage: 'Zrušiť pôvodnú a vytvoriť novú platbu',
    id: 'payment.transactionError.cancelAndCreate'
  },
  paymentBackToPayment: {
    defaultMessage: 'Späť na platbu',
    id: 'payment.backToPayment'
  },
  paymentBackToPayments: {
    defaultMessage: 'Späť na platby',
    id: 'payment.backToPayments'
  },
  paymentBackToUsers: {
    defaultMessage: 'Späť na používateľov',
    id: 'payment.backToUsers'
  },
  paymentBackToHotel: {
    defaultMessage: 'Späť na výber hotela',
    id: 'payment.backToHotel'
  },
  paymentRoomName: {
    defaultMessage: 'Zadať názov izby',
    id: 'payment.roomName'
  },
  paymentGuestCardRead: {
    defaultMessage: 'Načítať hosťovskú kartu',
    id: 'payment.guestCardRead'
  },
  paymentPartnersList: {
    defaultMessage: 'Zoznam partnerov',
    id: 'payment.partnersList'
  },
  paymentConfirm: {
    defaultMessage: 'Potvrdiť',
    id: 'payment.confirm'
  },
  paymentChooseWallet: {
    defaultMessage: 'VÝBER - Vyberte peňaženku',
    id: 'payment.chooseWallet'
  },
  paymentChoosePackage: {
    defaultMessage: 'NÁKUP - Vyberte balík',
    id: 'payment.choosePackage'
  },
  paymentItemWithoutName: {
    defaultMessage: 'položka bez mena',
    id: 'payment.itemWithoutName'
  },
  paymentAccountStateCredit: {
    defaultMessage: 'stav účtu (v kreditoch)',
    id: 'payment.accountStateCredit'
  },
  paymentExpiration: {
    defaultMessage: 'expirácia',
    id: 'payment.expiration'
  },
  paymentExpirationDays: {
    defaultMessage: 'expirácia (v dňoch)',
    id: 'payment.expirationDays'
  },
  paymentNoItems: {
    defaultMessage: 'Žiadne položky',
    id: 'payment.noItems'
  },
  paymentEditPaymentError: {
    defaultMessage: 'Error pri editovaní platby: ',
    id: 'payment.editPaymentError'
  },
  paymentEditPayment: {
    defaultMessage: 'Editácia Platby',
    id: 'payment.editPayment'
  },
  paymentEditPaymentPrice: {
    defaultMessage: 'Cena',
    id: 'payment.editPaymentPrice'
  },
  paymentEditPaymentTip: {
    defaultMessage: 'Tip',
    id: 'payment.editPaymentTip'
  },
  paymentEditPaymentConfirmCounts: {
    defaultMessage: 'Potvrdiť počty',
    id: 'payment.editPaymentConfirmCount'
  },
  paymentChooseCard: {
    defaultMessage: 'Vyberte kartu.',
    id: 'payment.chooseCard'
  },
  paymentLoyalmanUsers: {
    defaultMessage: 'LoyalMAN používatelia',
    id: 'payment.loyalmanUsers'
  },
  paymentLoadingSelectedUser: {
    defaultMessage: 'Vybraný používateľ sa načítava',
    id: 'payment.loadingSelectedUser'
  },
  paymentOk: {
    defaultMessage: 'OK',
    id: 'payment.ok'
  },
  paymentCodeLoaded: {
    defaultMessage: 'Kód načítaný.',
    id: 'payment.codeLoaded'
  },
  paymentEmail: {
    defaultMessage: 'Email',
    id: 'payment.email'
  },
  paymentSendByMail: {
    defaultMessage: 'Poslať bloček emailom',
    id: 'payment.sendByMail'
  },
  paymentCouponCode: {
    defaultMessage: 'couponCode',
    id: 'payment.couponCode'
  },
  paymentConfirmCouponCode: {
    defaultMessage: 'Potvrdiť kód kupónu',
    id: 'payment.confirmCouponCode'
  },
  paymentDescription: {
    defaultMessage: 'Poznámka k platbe',
    id: 'payment.description'
  },
  paymentGoBack: {
    defaultMessage: 'Vrátiť sa',
    id: 'payment.goBack'
  },
  paymentOkp: {
    defaultMessage: 'okp',
    id: 'payment.okp'
  },
  paymentErrorFinishHeader: {
    defaultMessage: 'Chyba platby - dokončenie bločku',
    id: 'payment.error.finish.header'
  },
  paymentErrorFinishButton: {
    defaultMessage: 'Dokončiť bloček',
    id: 'payment.error.finish.button'
  },
  paymentErrorReprint: {
    defaultMessage: 'Znovu vytlačiť bloček',
    id: 'payment.error.reprint'
  },
  paymentErrorCancelPayment: {
    defaultMessage: 'Zrušiť platbu',
    id: 'payment.error.cancelPayment'
  },
  paymentSelectOptions: {
    defaultMessage: 'Výber z možností',
    id: 'payment.selectOptions'
  },
  paymentNumber: {
    defaultMessage: 'Číslo',
    id: 'payment.number'
  },
  paymentNewCardPopupName: {
    defaultMessage: 'Meno',
    id: 'payment.newCardPopup.name'
  },
  paymentNewCardPopupSurname: {
    defaultMessage: 'Priezvisko',
    id: 'payment.newCardPopup.surname'
  },
  paymentNewCardPopupEmail: {
    defaultMessage: 'Email',
    id: 'payment.newCardPopup.email'
  },
  paymentNewCardPopupPhone: {
    defaultMessage: 'Telefón',
    id: 'payment.newCardPopup.phone'
  },
  paymentNewCardPopupAddress: {
    defaultMessage: 'Adresa',
    id: 'payment.newCardPopup.address'
  },
  paymentNewCardPopupWork: {
    defaultMessage: 'Zamestnávateľ',
    id: 'payment.newCardPopup.work'
  },
  createNewCard: {
    defaultMessage: 'Vytvoriť novú kartu',
    id: 'payment.page.createNewCard'
  },
  newCardPackages: {
    defaultMessage: 'Balíčky pre novú kartu',
    id: 'payment.page.newCardPackages'
  },
  cardExpiration: {
    defaultMessage: 'Expirácia karty',
    id: 'payment.page.cardExpiration'
  },
  paymentCreatingCard: {
    defaultMessage: 'vytváram kartu ...',
    id: 'payment.creatingCard'
  },
  paymentServerError: {
    defaultMessage: 'Chyba servera.',
    id: 'payment.serverError'
  },
  paymentCreatingUser: {
    defaultMessage: 'vytváram užívateľa ...',
    id: 'payment.creatingUser'
  },
  paymentTransactionAborted: {
    defaultMessage: 'Platobná transakcia bola prerušená',
    id: 'payment.transactionAborted'
  },
  paymentHoresChooseGuest: {
    defaultMessage: 'Výber hosťa',
    id: 'payment.hores.chooseGuest'
  },
  paymentHoresRoomNumber: {
    defaultMessage: 'Číslo izby',
    id: 'payment.hores.roomNumber'
  },
  paymentHoresChooseRoom: {
    defaultMessage: 'Výber izby',
    id: 'payment.hores.chooseRoom'
  },
  paymentHoresSkipChooseGuest: {
    defaultMessage: 'Preskočiť výber hosťa',
    id: 'payment.hores.skipChooseGuest'
  },
  paymentHoresChooseHotel: {
    defaultMessage: 'Výber hotela',
    id: 'payment.hores.chooseHotel'
  },
  paymentHoresRoomNumberIs: {
    defaultMessage: 'izba č.',
    id: 'payment.hores.roomNumberis'
  },
  paymentNewPartner: {
    defaultMessage: 'Nový partner',
    id: 'payment.newPartner'
  },
  paymentBankPartner: {
    defaultMessage: 'Úverový partner',
    id: 'payment.bankPartner'
  },
  paymentPaymentInProgress: {
    defaultMessage: 'Prebieha platobná operácia',
    id: 'payment.paymentInProgress'
  },
});
