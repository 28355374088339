// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  priceListUnknownError: {
    defaultMessage: 'Unknown error',
    id: 'priceList.unknownError'
  },
  priceListUpdateHeader: {
    defaultMessage: 'Aktualizácia cenníka',
    id: 'priceList.updateHeader'
  },
  priceListUpdatedInfo: {
    defaultMessage: 'Cenník bol aktualizovaný',
    id: 'priceList.updatedInfo'
  },
  priceListUpdatingInfo: {
    defaultMessage: 'Prebieha aktualizácia…',
    id: 'priceList.updatingInfo'
  },
  priceListUpdateNow: {
    defaultMessage: 'Aktualizovať teraz',
    id: 'priceList.updateNow'
  },
});
