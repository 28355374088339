// @flow
import React from 'react';
import { connect } from 'react-redux';
import { toggleFilter } from '../../../common/order/actions';
import InactiveBg from '../../components/InactiveBg';
import Popup from '../../components/Popup';
import Keyboard from '../../../common/components/Keyboard';
import { NoEnterButtons as TypewriterButtons } from '../../../common/components/Keyboard/typewriter/Buttons';
import OneLineTypewriterDisplay from '../../../common/components/Keyboard/typewriter/OneLineDisplay';
import createTypewriterOperations from '../../../common/components/Keyboard/typewriter/operations';
import Box from '../../../common/components/Box';

class PopupTextForm extends React.PureComponent {
  _onClose = () => {
    const { dispatch } = this.props;
    dispatch(toggleFilter(false));
  };

  render() {
    const {
      filterValue,
      updateFilter,
    } = this.props;

    return (
      <InactiveBg onClose={this._onClose}>
        <Popup onClose={this._onClose}>
          <Box alignItems="center">
            <Keyboard
              style={theme => ({
                width: theme.typography.lineHeight(3) * 1.75 * 11 + theme.typography.lineHeight() * 7.5
              })}
              createOperations={createTypewriterOperations}
              Buttons={TypewriterButtons}
              Display={OneLineTypewriterDisplay}
              onLastKey={key => {
                if (key === 'send') {
                  this._onClose();
                }
              }}
              onDisplayValue={val => {
                updateFilter(val);
              }}
              defaultValue={filterValue}
            />
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default connect()(PopupTextForm);
