// @flow
import React from 'react';
import Box from '../../../components/Box';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import ScrollView from '../../../components/ScrollView';
import api from '../../../lib/api/index';
import remoteLoad, {remoteLoadFromProps} from '../../../components/hoc/remoteLoad';
import { isReactNative } from '../../../app/detectPlatform';
import Text from '../../../components/Text';
import Spinner from '../../../components/Spinner';
import {compose} from 'rambda';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import paymentMessages from '../../../messages/payment';

class Hotels extends React.PureComponent {
  // TODO - this is a quick fix, but it would be nice to optimize the native navigation, so that there are no infinite loops taking place
  isConfirmed = false;

  componentWillReceiveProps(nextProps) {
    const { remoteIsLoading, remoteError, remotePayload: hotels, onConfirm } = nextProps;

    if (remoteIsLoading || remoteError) return;

    if (hotels.length === 1 && !this.isConfirmed) {
      this.isConfirmed = true;
      onConfirm(hotels[0].id);
    }
  }

  render() {
    const {
      remotePayload: hotels = [],
      remoteError,
      remoteIsLoading,
      onConfirm,
      intl
    } = this.props;

    return (
      <Box
        width={isReactNative ? null : 20}
        maxHeight={isReactNative ? '100%' : '70vh'}
        marginHorizontal={-1}
        flex={1}
      >
        <Heading scale={2} color="white" marginHorizontal={1}>{intl.formatMessage(paymentMessages.paymentHoresChooseHotel)}</Heading>

        {remoteError ? null : remoteIsLoading
          ? (
            <Box marginTop={4}>
              <Spinner color={isReactNative ? 'gray' : ''} size="large" />
            </Box>
          ) : (
            <ScrollView>
              {hotels.map(({ id, name, cashier }) => (
                <Button
                  key={id}
                  marginHorizontal={1}
                  backgroundColor="white"
                  paddingVertical={0.5}
                  paddingHorizontal={1}
                  marginBottom={0.5}
                  justifyContent="flex-start"
                  alignItems={isReactNative ? 'center' : undefined}
                  flexShrink={0}
                  onPress={() => {
                    this.isConfirmed = true;
                    onConfirm(id);
                  }}
                >
                  <Box height={1.25} justifyContent="center">
                    <Text>{`${name} (${cashier})`}</Text>
                  </Box>
                </Button>
              ))}
            </ScrollView>
          )
        }

        {remoteError ? <Text color="error" marginVertical={1}>{remoteError}</Text> : null}
      </Box>
    );
  }
}

export default compose(
  remoteLoad(api.hores.getHotels),
  connect(),
  injectIntl
)(Hotels);
