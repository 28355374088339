// @flow
import type { State, OpenTable } from '../types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, find, propEq, path, pathOr, pluck, contains } from 'rambda';
import { isEmpty, sum } from 'ramda';
import Box from './Box';
import Text from './Text';
import Button from './Button';
import Icon from './Icon';
import ComposedButton from './ComposedButton';
import {
  activeSubTableName,
  formatPriceToFixed,
  getAllOrderLinesOfOpenTable
} from '../order/utils';
import { injectIntl } from 'react-intl';
import messages from '../payment/messages/page';
import { isReactNative } from '../app/detectPlatform';
import DiscountButton from '../payment/DiscountButton';
import { findParamValue } from '../parameters/utils';
import { activeTableNameSelector } from '../tables/selectors';
import {
  toggleDisableForm,
  toggleNewCardPopup,
  togglePaymentRecordForm,
  togglePrepaidItemsPopup,
  updatePaymentTransaction,
  toggleSendReceiptByEmailPopup
} from '../payment/actions';
import {
  addError,
  deleteOrderLinesCommit,
  terminalPaymentSocketMessageLoading,
  toggleTerminalPaymentLoading
} from '../order/actions';
import checkPermission from '../permissions/service';
import api from '../lib/api';
import {
  checkIfIsBar,
  processReceivedPaymentTransactionData,
  isLoyalmanPrepaidCase,
  extractPaymentWarnings
} from '../payment/utils';
import { redirectNavigation } from '../lib/utils';
import { lookUpId } from '../localIds/lookUpService';
import orderKeeper, { RequestOrderError } from '../lib/fetch/orderKeeper';
import {createTerminalLoadingSocket, getPrinterUrl, paymentOperationsName} from '../lib/api/payment';
import { conflictStatusCode } from '../payment/TransactionErrorPrompt';
import guard from './hoc/guard';
import NavigationActions from 'react-navigation/src/NavigationActions';
import { toggleLoyalmanUsers } from '../loyalman/users/actions';
import { toggleUnfinishedBillPopup } from '../admin/receipts/actions';
import Radio from './Radio';
import componentsMessages from '../messages/components';
import { getCurrencySymbol } from '../parameters/service';
import ScrollView from './ScrollView';

export const BottomDecoration = (
  <Box
    width="100%"
    position="absolute"
    bottom={-0.35}
    left={0}
    height={1}
    flexDirection="row"
    overflow="hidden"
  >
    {[...Array(50)].map((x, i) =>
      <Box
        key={i}
        marginLeft={-0.2}
        style={{ transform: 'rotate(90deg)' }}
      >
        <Icon name="navigation-left" color="appBg" scale={-1} />
      </Box>
    )}
  </Box>
);

const getCompletePayment = (id, parameters, paymentTypes, paymentMedia) => [1, 2, 3]
  .map(el => findParamValue(`K32.objednavka_tlacitko${el}`, parameters))
  .map(el => el ? find(propEq('nazev', el), paymentTypes) : undefined)
  .filter(el => el !== undefined)
  .filter(el => find(propEq('druhyPlId', el.idriadok), paymentMedia))
  .map(el => ({ ...el, ...find(propEq('druhyPlId', el.idriadok), paymentMedia) }))
  .find(el => el.id === id);

const hasTerminalPayment = (payments, parameters, paymentTypes, paymentMedia) => {
  if (payments && payments.length > 0) {
    const terminalPayment = payments.find(payment =>
      (getCompletePayment(payment.paymentMediumId, parameters, paymentTypes, paymentMedia)
        && getCompletePayment(payment.paymentMediumId, parameters, paymentTypes, paymentMedia).terminal));
    if (terminalPayment) {
      return true;
    }
  }
  return false;
};

const leftToPayThreshold = 0.001;

class PaymentSheet extends React.Component {
  reqOrderKeeper = orderKeeper(paymentOperationsName);

  state = {
    sendReceiptByEmail: false
  };

  redirectBackAction = (redirectToServicesAreas) => {
    const { activeReceipt } = this.props;

    const browserPage = activeReceipt
      ? '/admin/receipts'
      : redirectToServicesAreas ? '/serviceAreas' : '/order';

    const nativeActions = [NavigationActions.back()];

    if (redirectToServicesAreas) {
      nativeActions.push(NavigationActions.back());
    }

    return isReactNative
      ? nativeActions
      : [redirectNavigation(browserPage)];
  };

  componentWillMount() {
    const { dispatch, activeReceipt, selectedOrderLines, isLoyalManOn } = this.props;

    dispatch(toggleDisableForm(true));

    if (activeReceipt) {
      const { id: receiptId, closureId } = activeReceipt;

      this.reqOrderKeeper(api.payment.openReceiptsPayTransaction, receiptId, closureId)
        .then(({ body }) => {
          if (path(['payment', 'id'], body)) {
            dispatch([
              updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
              addError(extractPaymentWarnings(body)),
              toggleDisableForm(false)
            ]);
          }
        }).catch(e => {
          if (e instanceof RequestOrderError) return;

          dispatch([
            addError(e),
            this.redirectBackAction()
          ]);
        });
    } else {
      this.reqOrderKeeper(() => api.payment.openPayTransaction(selectedOrderLines))
        .then(({ body }) => {
          if (path(['payment', 'id'], body)) {
            dispatch([
              updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
              addError(extractPaymentWarnings(body)),
              toggleDisableForm(false)
            ]);
          }
        }).catch(e => {
          if (e instanceof RequestOrderError) return;

          if (e.status === conflictStatusCode) {
            // do not redirect

            dispatch(isLoyalManOn
              ? toggleDisableForm(false)
              : [
                  addError(e),
                  toggleDisableForm(false)
                ]);
          } else {
            dispatch([
              addError(e),
              this.redirectBackAction()
            ]);
          }
        });
    }
  }

  componentWillUpdate(nextProps) {
    const { dispatch } = this.props;

    if (isLoyalmanPrepaidCase(this.props, nextProps)) {
      if (process.env.IS_REACT_NATIVE === '1') {
        dispatch([
          NavigationActions.navigate({
            routeName: 'ChoosePrepaidItemsPage',
            key: 'ChoosePrepaidItemsPage'
          }),
          togglePrepaidItemsPopup(false)
        ]);
      } else {
        dispatch(togglePrepaidItemsPopup(true));
      }
    }
  }

  addCardPopup = () => {
    const { dispatch } = this.props;

    dispatch(toggleNewCardPopup(true));
  };

  executeSubmit = (apiFn, params) => {
    const {
      dispatch,
      paymentTransactionId,
      selectedOrderLines,
      isBar,
      activeOpenTableId,
      subTables,
      orderLines,
      selectedPrinter,
      copies,
      paramIsEkasa,
      socketSelectedPrinter,
      socketDefaultPrinter,
      isTerminalPay,
      sessionId
    } = params;

    dispatch(toggleDisableForm(true));

    dispatch(toggleTerminalPaymentLoading(isTerminalPay));

    let terminalSocket = null;
    if (isTerminalPay){
      const onMessage = message => {
        dispatch(terminalPaymentSocketMessageLoading(message));
      };
      terminalSocket = createTerminalLoadingSocket(sessionId, getPrinterUrl(socketSelectedPrinter, socketDefaultPrinter), onMessage);
    }

    const closeTerminalSocket = () => {
      if (terminalSocket){
        terminalSocket.close();
      }
    };


    this.reqOrderKeeper(() => apiFn(paymentTransactionId, pathOr(undefined, 'id', selectedPrinter), copies > 0 ? copies : undefined))
      .then(() => {
        let paidAllOrderlines = false;

        if (!isBar) {
          const allOrderlines = getAllOrderLinesOfOpenTable(subTables, orderLines, activeOpenTableId);
          if (selectedOrderLines.length === allOrderlines.length) {
            paidAllOrderlines = true;
          }
        }

        closeTerminalSocket();

        dispatch([
          toggleDisableForm(false),
          toggleTerminalPaymentLoading(false),
          this.redirectBackAction(paidAllOrderlines),
          deleteOrderLinesCommit(
            lookUpId(selectedOrderLines),
            {
              deleteOpenTableId: !isBar ? activeOpenTableId : null,
            }
          ),
          toggleLoyalmanUsers(false)
        ]);
      })
      .catch(e => {
        closeTerminalSocket();

        if (e instanceof RequestOrderError) return;

        if (e.additionalData && paramIsEkasa) {
          if (process.env.IS_REACT_NATIVE === '1') {
            dispatch([
              NavigationActions.navigate({
                routeName: 'UnfinishedBillPage',
                key: 'UnfinishedBillPage',
                params: e.additionalData
              }),
              addError(e),
              toggleDisableForm(false)
            ]);
          } else {
            dispatch([
              toggleUnfinishedBillPopup(e.additionalData),
              addError(e),
              toggleDisableForm(false),
              toggleTerminalPaymentLoading(false)
            ]);
          }
        } else {
          dispatch([
            addError(e),
            toggleDisableForm(false),
            toggleTerminalPaymentLoading(false)
          ]);
        }
      });
  };

  closeAndPrint = () => {
    const {
      dispatch,
      paymentTransactionId,
      selectedOrderLines,
      isBar,
      activeOpenTableId,
      subTables,
      orderLines,
      defaultPrinter,
      selectedPrinter,
      socketSelectedPrinter,
      socketDefaultPrinter,
      copies,
      paramIsEkasa,
      parameters,
      paymentMedia,
      paymentTypes,
      payments,
      sessionId
    } = this.props;
    const { sendReceiptByEmail } = this.state;

    const isTerminalPay = hasTerminalPayment(payments, parameters, paymentTypes, paymentMedia);

    if (sendReceiptByEmail) {
      if (process.env.IS_REACT_NATIVE === '1') {
        dispatch(
          NavigationActions.navigate({
            routeName: 'SendReceiptByEmailPage',
            key: 'SendReceiptByEmailPage',
            params: {
              paymentTransactionId,
              payFn: () => this.executeSubmit(
                api.payment.finishPayment,
                {
                  dispatch,
                  paymentTransactionId,
                  selectedOrderLines,
                  isBar,
                  activeOpenTableId,
                  subTables,
                  orderLines,
                  selectedPrinter,
                  copies,
                  paramIsEkasa,
                  socketSelectedPrinter,
                  socketDefaultPrinter,
                  isTerminalPay,
                  sessionId
                }
              )
            }
          })
        );
      } else {
        dispatch(
          toggleSendReceiptByEmailPopup(
            {
              paymentTransactionId,
              payFn: () => this.executeSubmit(
                api.payment.finishPayment,
                {
                  dispatch,
                  paymentTransactionId,
                  selectedOrderLines,
                  isBar,
                  activeOpenTableId,
                  subTables,
                  orderLines,
                  selectedPrinter,
                  copies,
                  paramIsEkasa,
                  socketSelectedPrinter,
                  socketDefaultPrinter,
                  isTerminalPay,
                  sessionId
                }
              )
            }
          )
        );
      }
    } else {
      this.executeSubmit(
        api.payment.finishPayment,
        {
          dispatch,
          paymentTransactionId,
          selectedOrderLines,
          isBar,
          activeOpenTableId,
          subTables,
          orderLines,
          selectedPrinter,
          copies,
          paramIsEkasa,
          socketSelectedPrinter,
          socketDefaultPrinter,
          isTerminalPay,
          sessionId
        }
      );
    }
  };

  printPrereceipt = () => {
    const {
      dispatch,
      paymentTransactionId,
      selectedPrinter
    } = this.props;

    dispatch(toggleDisableForm(true));

    this.reqOrderKeeper(() => api.payment.printPrereceipt(paymentTransactionId, pathOr(undefined, 'id', selectedPrinter)))
      .then(() => {
        dispatch([
          toggleDisableForm(false)
        ]);
      })
      .catch(e => {
        if (e instanceof RequestOrderError) return;

        dispatch([
          addError(e),
          toggleDisableForm(false)
        ]);
      });
  };

  deleteDiscount = operationId => {
    const { dispatch, paymentTransactionId } = this.props;

    dispatch(toggleDisableForm(true));

    this.reqOrderKeeper(() => api.payment.deleteDiscount(paymentTransactionId, operationId)
      ).then(({ body }) => {
        if (path(['payment', 'id'], body)) {
          dispatch([
            updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
            addError(extractPaymentWarnings(body)),
            toggleDisableForm(false)
          ]);
        }
      })
      .catch(e => {
        if (e instanceof RequestOrderError) return;

        dispatch([
          addError(e),
          toggleDisableForm(false)
        ]);
      });
  };

  deletePayment = paymentRecordId => {
    const { dispatch, paymentTransactionId } = this.props;

    dispatch(toggleDisableForm(true));

    this.reqOrderKeeper(() => api.payment.deletePaymentRecord(paymentTransactionId, paymentRecordId)
      ).then(({ body }) => {
        if (path(['payment', 'id'], body)) {
          dispatch([
            updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
            addError(extractPaymentWarnings(body)),
            toggleDisableForm(false)
          ]);
        }
      })
      .catch(e => {
        if (e instanceof RequestOrderError) return;

        dispatch([
          addError(e),
          toggleDisableForm(false)
        ]);
      });
  };

  editPayment = extendedPayment => {
    const { dispatch } = this.props;

    if (process.env.IS_REACT_NATIVE === '1') {
      dispatch(NavigationActions.navigate({
        routeName: 'EditPaymentRecordPage',
        key: 'EditPaymentRecordPage',
        params: extendedPayment
      }));
    } else {
      dispatch(togglePaymentRecordForm(extendedPayment));
    }
  };

  _formatDiscountValue = (type, value) => {
    const { intl } = this.props;

    const discountValues = {
      relative: ((-1) * value).toFixed(2).replace(/\./g, intl.formatMessage(messages.dot)),
      absolute: formatPriceToFixed((-1) * value)
    };

    const discountSigns = {
      relative: '%',
      absolute: getCurrencySymbol()
    };

    return `${discountValues[type]} ${discountSigns[type]}`;
  };

  _formatLoyalmanValue = (value, customUnit) => {
    const { intl } = this.props;

    const discountValue = formatPriceToFixed(value);

    const discountSign = customUnit || getCurrencySymbol();

    return `${discountValue} ${discountSign}`;
  };

  _checkSendEmail = () => {
    const { sendReceiptByEmail } = this.state;
    this.setState({ sendReceiptByEmail: !sendReceiptByEmail });
  };

  openPrintersPopup = () => {
    const { togglePrintersPopup, availablePrinters } = this.props;
    if (togglePrintersPopup) togglePrintersPopup(availablePrinters);
  };

  openNoOfCopiesPopup = () => {
    const { toggleNoOfCopiesPopup } = this.props;
    if (toggleNoOfCopiesPopup) toggleNoOfCopiesPopup(true);
  };

  openParagonPopup = () => {
    const { toggleParagonPopup } = this.props;
    if (toggleParagonPopup) toggleParagonPopup(true);
  };

  formatDiscountName = (description, isServerDiscount) => {
    const { intl } = this.props;
    return isServerDiscount ? `${intl.formatMessage(componentsMessages.paymentSheetDiscount)} ${description}` : description;
  };

  render() {
    const {
      intl,
      payments,
      discounts,
      operations,
      isContextMenuOpen,
      openCustomDiscount,
      isCustomDiscountMenuOpen,
      toggleContextMenu,
      openLoyalMan,
      paymentStats: {
        overallSum,
        overallSumAfterDiscount,
        leftToPay,
        overallTip,
        overpay
      },
      enableLoyalman,
      loyalman,
      loyalmanOperations,
      loyalmanPrepaidProducts,
      formDisabled,
      paramIsTipAllowed,
      activeReceipt,
      paymentTransactionId,
      tableName,
      showFinishingButton,
      availablePrinters = [],
      defaultPrinter,
      selectedPrinter,
      copies = 0
    } = this.props;
    const { sendReceiptByEmail } = this.state;

    const showPrinterSelectButton = availablePrinters.length > 1;
    const displayedPrinterName = (selectedPrinter ? pathOr('', 'name', selectedPrinter) : pathOr('', 'prn_name', defaultPrinter)).trim();
    const overpayInfo = `${overallTip > 0 ?
      `${intl.formatMessage(componentsMessages.paymentSheetTipSimple)}: ${formatPriceToFixed(Math.abs(overallTip))}${getCurrencySymbol()}${overpay > 0 ? ', ' : ''}` :
      ''}${overpay > 0 ? `${intl.formatMessage(componentsMessages.paymentSheetOverpay)}: ${formatPriceToFixed(Math.abs(overpay))}${getCurrencySymbol()}` : ''}`;

    const sumOfLoyalmanOperations = sum(pluck('absoluteDiscount', loyalmanOperations));
    const sumOfLoyalmanPrepaidProducts = sum(pluck('value', loyalmanPrepaidProducts));

    const loyalmanCredit = pathOr(0, 'credit_increase', loyalman);
    const loyalmanBonus = pathOr(0, 'bonus_increase', loyalman);

    return (
      <Box
        width={isReactNative ? '100%' : '80%'}
        height="100%"
        marginTop={isReactNative ? 0 : 2}
        flexShrink={0}
      >
        <ScrollView maxHeight={isReactNative ? '100%' : 'calc(100vh - 80px)'} overflowX="hidden">
        <Box
          backgroundColor="white"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            width="100%"
            borderBottomColor="orderLineCountLabel"
            borderBottomWidth={1}
            borderStyle={isReactNative ? 'solid' : 'dashed'}
            paddingBottom={0.5}
          >
            <Text scale={4} bold marginTop={1} color="darkGray" align="center">
              {!loyalman ? intl.formatMessage(messages.title) : pathOr('LoyalMan User', 'name', loyalman)}
            </Text>
            {!activeReceipt && <Text color="textNormal" align="center">{tableName}</Text>}
          </Box>

          {!isReactNative &&
            <Box
              width="80%"
              minHeight={1.25}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              marginTop={0.5}
            >
              <Text color="darkGray">{intl.formatMessage(messages.overallSum)}</Text>
              <Text
                color="darkGray">{`${formatPriceToFixed(overallSum)} ${getCurrencySymbol()}`}</Text>
            </Box>}

          {isReactNative && discounts.length ? <Box height={1} width="80%" /> : null}

          {discounts.map(discount =>
            <Box
              key={discount.id}
              flexShrink={0}
              width="80%"
              minHeight={1.25}
              flexDirection="row"
              alignItems="center"
            >
              <Text color="darkGray" flexGrow={1} width={0}>{this.formatDiscountName(discount.description, discount.paymentRecordId)}</Text>
              {!discount.paymentRecordId && checkPermission('paymentoperation.delete') ?
                <Button
                  disabled={formDisabled}
                  onPress={() => this.deleteDiscount(discount.id)}
                  padding={0.25}
                >
                  <Icon color="gray" name="delete" scale={1} />
                </Button>
                : null
              }
              <Text color="darkGray" marginLeft={0.5}>
                {this._formatDiscountValue(discount.type, discount.value)}
              </Text>
            </Box>
          )}

          {operations.map(operation =>
            <Box
              key={operation.id}
              flexShrink={0}
              minHeight={1.25}
              width="80%"
              flexDirection="row"
              alignItems="center"
            >
              <Text color="darkGray" flexGrow={1} width={0}>{`${operation.description} – cen.hl.`}</Text>
              <Text color="darkGray">{operation.value}</Text>
            </Box>
          )}

          {sumOfLoyalmanOperations > 0 &&
            <Box
              flexShrink={0}
              minHeight={1.25}
              width="80%"
              flexDirection="row"
              alignItems="center"
            >
              <Text color="darkGray" flexGrow={1} width={0}>LoyalMAN zľava</Text>
              <Text color="darkGray">{this._formatLoyalmanValue((-1) * sumOfLoyalmanOperations)}</Text>
            </Box>
          }

          {sumOfLoyalmanPrepaidProducts > 0 &&
            <Box
              flexShrink={0}
              minHeight={1.25}
              width="80%"
              flexDirection="row"
              alignItems="center"
            >
              <Text color="darkGray" flexGrow={1} width={0}>{intl.formatMessage(componentsMessages.paymentSheetPrepaid)}</Text>
              <Text color="darkGray">{this._formatLoyalmanValue((-1) * sumOfLoyalmanPrepaidProducts)}</Text>
            </Box>
          }

          {!isReactNative && (loyalmanCredit > 0 || loyalmanBonus > 0) ?
            <Box
              width="100%"
              height={1}
              borderBottomColor="orderLineCountLabel"
              borderBottomWidth={1}
              borderStyle={isReactNative ? 'solid' : 'dashed'}
              marginBottom={1}
            />
            : null}

          {loyalmanCredit > 0 &&
            <Box
              flexShrink={0}
              minHeight={1.25}
              width="80%"
              flexDirection="row"
              alignItems="center"
            >
              <Text color="darkGray" flexGrow={1} width={0}>{intl.formatMessage(componentsMessages.paymentSheetCreditIncr)}</Text>
              <Text color="darkGray">{this._formatLoyalmanValue(loyalmanCredit)}</Text>
            </Box>}

          {loyalmanBonus > 0 &&
            <Box
              flexShrink={0}
              minHeight={1.25}
              width="80%"
              flexDirection="row"
              alignItems="center"
            >
              <Text color="darkGray" flexGrow={1} width={0}>{intl.formatMessage(componentsMessages.paymentSheetBonusIncr)}</Text>
              <Text color="darkGray">{this._formatLoyalmanValue(loyalmanBonus, 'B')}</Text>
            </Box>}

          {discounts.length
            ? <Box
                minHeight={1.25}
                width="80%"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text color="darkGray">
                  {intl.formatMessage(messages.overallSumAfterDiscount)}
                </Text>
                <Text color="darkGray" style={{ textDecoration: 'underline' }}>
                  {`${formatPriceToFixed(overallSumAfterDiscount)} ${getCurrencySymbol()}`}
                </Text>
              </Box>
            : null
          }

          <Box
            width="80%"
            flexDirection="row"
            alignItems="center"
            justifyContent={isReactNative || !enableLoyalman ? 'center' : 'space-between'}
          >
            {enableLoyalman &&
              <DiscountButton
                caption={intl.formatMessage(messages.loyalMAN)}
                icon={(<Icon color="white" name="consume-card" scale={2} />)}
                isContextMenuOpen={false}
                onClick={openLoyalMan}
                openCustomDiscount={false}
                isCustomDiscountMenuOpen={false}
                marginBottom={isReactNative ? 0.5 : 0}
                marginRight={1}
                disabled={formDisabled}
              />}

            {checkPermission('discount.add') &&
              <DiscountButton
                caption={intl.formatMessage(messages.setDiscount)}
                isContextMenuOpen={isContextMenuOpen}
                onClick={toggleContextMenu}
                openCustomDiscount={openCustomDiscount}
                isCustomDiscountMenuOpen={isCustomDiscountMenuOpen}
                marginBottom={isReactNative ? 0.5 : 0}
                marginLeft={1}
                disabled={formDisabled}
              />}
          </Box>

          {!isReactNative || payments.length ?
            <Box
              width="100%"
              height={1}
              borderBottomColor="orderLineCountLabel"
              borderBottomWidth={1}
              borderStyle={isReactNative ? 'solid' : 'dashed'}
              marginBottom={1}
            />
            : null}
          {payments.map(payment =>
            <Box
              key={payment.id}
              flexShrink={0}
              flexDirection="column"
              width="80%"
            >
              <Box
                flexShrink={0}
                width="100%"
                minHeight={1.25}
                flexDirection="row"
                alignItems="center"
              >
                <Box
                  padding={0.25}
                  paddingLeft={0}
                  flexGrow={1}
                  width={0}
                  flexDirection="row"
                >
                  <Button
                    disabled={formDisabled}
                    onPress={() => this.editPayment({ ...payment, paymentTransactionId, preFocusField: 'tip' })}
                    color="darkGray"
                    justifyContent="flex-start"
                    flexGrow={1}
                    width={0}
                  >
                    {payment.description}
                  </Button>
                  <Button
                    disabled={formDisabled}
                    onPress={() => this.editPayment({ ...payment, paymentTransactionId })}
                    justifyContent="flex-start"
                  >
                    <Icon color="gray" name="edit" scale={1} />
                    <Text color="darkGray" marginLeft={0.5}>
                      {`${formatPriceToFixed(payment.amount)} ${getCurrencySymbol()}`}
                    </Text>
                  </Button>
                </Box>
                <Button
                  disabled={formDisabled}
                  onPress={() => this.deletePayment(payment.id)}
                  paddingLeft={0.25}
                >
                  <Icon color="gray" name="delete" scale={1} />
                </Button>
              </Box>
              {payment.allowTip
                ? payment.tip > 0 ? (
                  <Box
                    flexShrink={0}
                    width="100%"
                    flexDirection="row"
                    alignItems="center"
                    marginTop={-0.25}
                    marginBottom={0.25}
                  >
                    <Button
                      disabled={formDisabled}
                      onPress={() => this.editPayment({ ...payment, paymentTransactionId })}
                      flexGrow={1}
                      width={0}
                    >
                      <Text color="gray" flexGrow={1} width={0}>TIP</Text>
                      <Text color="gray" marginRight={1.4}>
                        {`${formatPriceToFixed(payment.tip)} ${getCurrencySymbol()}`}
                      </Text>
                    </Button>
                  </Box>
                ) : (
                  <Box
                    flexShrink={0}
                    width="100%"
                    flexDirection="row"
                    alignItems="center"
                    marginTop={-0.25}
                    marginBottom={0.25}
                  >
                    <Button
                      disabled={formDisabled}
                      onPress={() => this.editPayment({ ...payment, paymentTransactionId, preFocusField: 'tip' })}
                      flexGrow={1}
                      width={0}
                    >
                      <Text color="gray" flexGrow={1} width={0}>{intl.formatMessage(componentsMessages.paymentSheetTip)}</Text>
                    </Button>
                  </Box>
                )
                : null}
              {payment.overpay > 0 &&
                <Box
                  flexShrink={0}
                  width="100%"
                  flexDirection="row"
                  alignItems="center"
                  marginTop={-0.25}
                  marginBottom={0.25}
                >
                  <Button
                    disabled={formDisabled}
                    onPress={() => this.editPayment({ ...payment, paymentTransactionId })}
                    flexGrow={1}
                    width={0}
                  >
                    <Text color="gray" flexGrow={1} width={0}>{intl.formatMessage(componentsMessages.paymentSheetOverpay)}</Text>
                    <Text color="gray" marginRight={1.4}>
                      {`${formatPriceToFixed(payment.overpay)} ${getCurrencySymbol()}`}
                    </Text>
                  </Button>
                </Box>}
              {payment.txt && payment.txt.notes && (
                <Box
                  flexShrink={0}
                  width="100%"
                  flexDirection="row"
                  alignItems="center"
                  marginTop={-0.25}
                >
                  <Text color="gray" flexGrow={1} width={0}>
                    {`${intl.formatMessage(componentsMessages.paymentSheetDesc)}: `}
                    {payment.txt.notes.filter(Boolean).join(', ')}
                  </Text>
                </Box>
              )}
            </Box>
          )}

          {!isReactNative || leftToPay <= leftToPayThreshold
            ? <Box
                marginTop={1}
                backgroundColor={
                  leftToPay > leftToPayThreshold || !paymentTransactionId
                    ? 'blue'
                    : Math.abs(leftToPay) < leftToPayThreshold
                      ? 'green'
                      : overallSum < 0
                        ? 'blue'
                        : 'red'
                }
                height={overpayInfo ? 5 : 4}
                width="100%"
                flexDirection="row"
                justifyContent="center"
              >
                <Box
                  height="100%"
                  width="80%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent={overpayInfo ? 'flex-start' : 'space-around'}
                >
                  <Box
                    paddingTop={overpayInfo ? 1 : 0}
                    width="100%"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text color="white" scale={1}>
                      {paymentTransactionId
                        ? intl.formatMessage(
                            leftToPay > leftToPayThreshold
                              ? messages.leftToPay
                              : Math.abs(leftToPay) < leftToPayThreshold
                                ? messages.leftToPayExact
                                : overallSum < 0
                                  ? messages.leftToPay
                                  : paramIsTipAllowed
                                    ? messages.leftToPayTip
                                    : messages.leftToPaySurplus
                          )
                        : ''
                      }
                    </Text>
                    <Text color="white" scale={2}>
                      {Math.abs(leftToPay) > leftToPayThreshold &&
                      `${formatPriceToFixed(overallSum < 0 ? leftToPay : Math.abs(leftToPay))} ${getCurrencySymbol()}`}
                    </Text>
                  </Box>
                  {overpayInfo ?
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text color="lighterGray" scale={0}>
                        {overpayInfo}
                      </Text>
                    </Box> : null}
                </Box>

                {!isReactNative && BottomDecoration}
              </Box>
            : <Box height={1} width="100%" />}
        </Box>

        {!isReactNative && enableLoyalman &&
          <Button
            backgroundColor="appBg"
            outline
            color="white"
            marginTop={1.5}
            paddingVertical={0.75}
            paddingHorizontal={2}
            onPress={this.addCardPopup}
            flexShrink={0}
          >
            <Text color="white" bold>
              {intl.formatMessage(messages.registerNewUser)}
            </Text>
          </Button>}

        {(!isReactNative || showFinishingButton) ?
          <Box flexDirection="row" justifyContent="space-between" paddingHorizontal={isReactNative ? 1.25 : 0}>
            {showPrinterSelectButton &&
              <Button
                width="48%"
                backgroundColor="appBg"
                color="white"
                marginTop={0.5}
                paddingTop={0.75}
                onPress={this.openPrintersPopup}
                flexShrink={0}
                justifyContent="flex-start"
              >
                <Text color="white" bold>
                  {`${displayedPrinterName} `}
                </Text>
                <Icon color="white" name="edit" scale={1} marginLeft={0.5} />
              </Button>}

            <Button
              width="48%"
              backgroundColor="appBg"
              color="white"
              marginTop={0.5}
              paddingTop={0.75}
              onPress={this.openNoOfCopiesPopup}
              flexShrink={0}
              flexDirection="row"
              justifyContent={showPrinterSelectButton ? 'flex-end' : 'flex-start'}
            >
              <Text color="white" bold>
                {`${intl.formatMessage(messages.copies)}: ${copies} `}
              </Text>
              <Icon color="white" name="edit" scale={1} marginLeft={0.5} />
            </Button>
          </Box> : null}

        {!isReactNative &&
          <Button
            backgroundColor="appBg"
            outline
            color="white"
            marginTop={1.5}
            paddingVertical={0.75}
            paddingHorizontal={2}
            onPress={this.openParagonPopup}
            flexShrink={0}
          >
            <Text color="white" bold>
              {intl.formatMessage(messages.registerParagon)}
            </Text>
          </Button>}

        {isEmpty(payments) && checkPermission('preview.payment.add') ?
          <Box
            marginTop={1}
            marginBottom={showFinishingButton ? 0 : 1}
            flexShrink={0}
            width="100%"
            paddingHorizontal={isReactNative ? 1 : 0}
          >
            <ComposedButton
              disabled={formDisabled}
              icon="printer"
              caption={intl.formatMessage(messages.printPrereceipt)}
              backgroundColor="darkGray"
              shadowColor="black"
              onPress={this.printPrereceipt}
            />
          </Box> : null}

        {showFinishingButton ?
          <Box
            marginTop={1}
            marginBottom={1}
            flexShrink={0}
            width="100%"
            paddingHorizontal={isReactNative ? 1 : 0}
            style={{ position: isReactNative ? 'relative' : 'sticky', bottom: 0, left: 0, right: 0 }}
            backgroundColor="appBg"
          >
            <ComposedButton
              disabled={formDisabled}
              icon="printer"
              caption={intl.formatMessage(messages.finishAndPrintReceipt)}
              backgroundColor="blue"
              shadowColor="darkBlue"
              onPress={this.closeAndPrint}
            />

            <Button
              backgroundColor="appBg"
              color="white"
              marginBottom={0.5}
              marginTop={0.5}
              paddingVertical={0.75}
              paddingHorizontal={2}
              onPress={this._checkSendEmail}
              flexShrink={0}
              alignItems="center"
              justifyContent="center"
            >
              <Radio size={1} color="white" flexGrow={0} marginRight={0.75} backgroundColor="appBg" checked={sendReceiptByEmail} />
              <Text color="white" bold>
                {intl.formatMessage(messages.sendReceiptByEmail)}
              </Text>
            </Button>
          </Box> : null}
        </ScrollView>
      </Box>
    );
  }
}

export default compose(
  connect(
    (state: State) => ({
      activeOpenTableId: state.tables.active.openTableId,
      activeSubTableId: state.tables.active.subTableId,
      openTables: state.tables.openTables,
      subTables: state.tables.subTables,
      orderLines: state.orders.orderLines,
      selectedOrderLines: state.orders.selectedOrderLines,
      paymentTransactionId: state.payment.paymentTransactionId,
      payments: state.payment.payments,
      parameters: state.parameters.parameters,
      discounts: state.payment.appliedDiscounts,
      operations: state.payment.appliedOperations,
      loyalmanOperations: state.payment.loyalmanOperations,
      loyalmanPrepaidProducts: state.payment.loyalmanPrepaidProducts,
      tableName: activeTableNameSelector(state),
      paymentStats: state.payment.stats,
      formDisabled: state.payment.formDisabled,
      activeReceipt: state.admin.receipts.active.receipt,
      loyalman: state.payment.loyalman,
      openingLoyalmanPrepaidItemsPopup: state.payment.openingLoyalmanPrepaidItemsPopup,
      isLoyalManOn: state.loyalman.users.isLoyalManOn,
      loyalmanSelectedOrderLines: state.loyalman.users.selectedOrderLines,
      availablePrinters: state.payment.availablePrinters,
      defaultPrinter: state.payment.defaultPrinter,
      selectedPrinter: state.payment.selectedPrinter,
      authDefaultPrinter: state.auth.defaultPrinter,
      copies: state.payment.copies,
      paymentMedia: state.payment.paymentMedia,
      paymentTypes: state.payment.paymentTypes,
      sessionId: state.device.sessionId,
      printers: state.peripherals.printers,
      useIntl: state.intl.useIntl
    }),
    null,
    ({
       payments,
       paymentTransactionId,
       formDisabled,
       paymentStats,
       parameters,
       discounts,
       loyalman,
       loyalmanOperations,
       loyalmanPrepaidProducts,
       operations,
       activeOpenTableId,
       activeSubTableId,
       openTables,
       subTables,
       tableName,
       activeReceipt,
       selectedOrderLines,
       orderLines,
       openingLoyalmanPrepaidItemsPopup,
       isLoyalManOn,
       loyalmanSelectedOrderLines,
       availablePrinters,
       defaultPrinter,
       selectedPrinter,
       authDefaultPrinter,
       copies,
       paymentMedia,
       paymentTypes,
       sessionId,
       printers,
       useIntl
     }, { dispatch }, ownProps) => {
      const { leftToPay, overallSum } = paymentStats;
      const paramIsTipAllowed = findParamValue('K32.is_tipdot', parameters);
      const paramIsEkasa = findParamValue('K32.ekasa', parameters);

      const showFinishingButton = paymentTransactionId
        ? (paramIsTipAllowed && overallSum > 0)
          ? leftToPay <= leftToPayThreshold
          : Math.abs(leftToPay) <= leftToPayThreshold
        : false;

      const tableFullName = `${tableName}, ${activeSubTableName(subTables, activeOpenTableId, activeSubTableId, useIntl)}`;

      const isBar = checkIfIsBar(activeOpenTableId, openTables);

      const enableLoyalman = findParamValue('K32.enable_loyalman', parameters);

      // NOTE: riesi sa to primarne v payment reduceri - case 'PAYMENT_TRANSACTION_UPDATE'
      //  ale pre istotu to checkujem aj tu nech sa tu nahodou nedostane tlaciaren co nema
      const selectedPrinterIsAvailable = selectedPrinter
        && contains(selectedPrinter.id, pluck('prn_no', availablePrinters));

      const selectedPrinterData = selectedPrinterIsAvailable ? selectedPrinter : null;
      const selectedPrinterObject = printers && selectedPrinterData && printers.find(per => (per.prnNo === selectedPrinterData.id));
      const authDefaultPrinterObject = printers && authDefaultPrinter && printers.find(per => (per.prnNo === authDefaultPrinter));

      return {
        ...ownProps,
        dispatch,
        payments,
        paymentTransactionId,
        formDisabled,
        showFinishingButton,
        paramIsTipAllowed,
        paramIsEkasa,
        paymentStats,
        discounts,
        enableLoyalman,
        loyalman,
        loyalmanOperations,
        loyalmanPrepaidProducts,
        operations,
        activeOpenTableId,
        openTables,
        subTables,
        tableName: tableFullName,
        activeReceipt,
        selectedOrderLines,
        orderLines,
        isBar,
        openingLoyalmanPrepaidItemsPopup,
        isLoyalManOn,
        loyalmanSelectedOrderLines,
        availablePrinters,
        defaultPrinter,
        selectedPrinter: selectedPrinterIsAvailable ? selectedPrinter : null,
        socketSelectedPrinter: selectedPrinterObject,
        socketDefaultPrinter: authDefaultPrinterObject,
        copies,
        parameters,
        paymentMedia,
        paymentTypes,
        sessionId
      };
    }
  ),
  guard(({ activeReceipt, activeOpenTableId, openTables }) => {
    if (!activeReceipt) {
      if (!activeOpenTableId) return false;
      const activeOpenTable: OpenTable = find(propEq('id', activeOpenTableId), openTables);
      if (!activeOpenTable) return false;
    }
    return true;
  }),
  injectIntl,
)(PaymentSheet);
