// @flow
import React from 'react';
import Box from './Box';
import Button from './Button';
import MenuItemCore from './MenuItemCore';
import {
  addError,
  addOrderLine, changeAddingItemCount, showAvailabilityBlocker,
  toggleFastFoodMenuForm, togglePriceOrQuantityForm, toggleTextInput
} from '../order/actions';
import Text from './Text';
import Icon from './Icon';
import { activateCategory, activateLastPage } from '../menuItems/actions';
import { toggleWeightForm } from '../peripherals/actions';
import { isReactNative } from '../app/detectPlatform';
import { invertToReadableColor, darkenColor } from '../lib/colors';
import theme from '../themes/theme';
import { connect } from 'react-redux';
import { repeat } from 'ramda';
import { concat, filter, pathOr } from 'rambda';
import Swipeable from 'react-swipeable';
import checkPermission from '../permissions/service';
import type { State } from '../types';
import NavigationActions from 'react-navigation/src/NavigationActions';
import { getAllFocusedOrderLinesSelector } from '../order/selectors';
import { findParamValue } from '../parameters/utils';
import uuid from 'uuid';

type MenuItemProps = {
  width: number | string,
  name: String,
  id: String,
  isItem: boolean,
  unitPrice?: number,
  itemCode?: string,
  itemsCount?: number,
  icon?: string,
  backgroundColor?: number,
};

const swipeableStyle = {
  cursor: 'pointer',
  flexDirection: 'column',
  height: isReactNative ? `${theme.typography.lineHeight() * 3 + 3}px` /* for shadow */ : '100%',
  // width: isReactNative ? width : undefined
};

const differentItemTypeError = { message: 'Nie je možné pridať rozdielny typ položky.' };

class MenuItem extends React.PureComponent<MenuItemProps> {
  _addMenuItemToOrder = (count = 1) => {
    const {
      dispatch,
      spart,
      unit,
      unitPrice,
      activeSubTableId,
      activeFoodCourse,
      priceLevelId,
      availability,
      id,
      hasCustomPrice,
      isWeighted,
      needsInvoiceNo,
      mustEnterText,
      parameters
    } = this.props;

    if (!checkPermission('orderline.add')) return;

    const orderLine = {
      itemId: id,
      singlePrice: unitPrice,
      subTableId: activeSubTableId,
      foodCourse: activeFoodCourse || 0,
      priceLevelId,
      quantity: 1,
      unit,
      hasCustomPrice,
      spart,
      isWeighted,
      needsInvoiceNo,
      mustEnterText
    };

    let dispatchLoad = null;

    if (isWeighted) {
      dispatchLoad = () =>
        isReactNative
        ? [
            NavigationActions.back(),
            NavigationActions.navigate({
              routeName: 'WeightFormPage',
              key: 'WeightFormPage',
              params: { id }
            })
          ]
        : [toggleWeightForm({ id })];
    } else if (spart === 'FST' || (parameters && spart === findParamValue('K32.menu_spart', parameters))) {
      dispatchLoad = count =>
        isReactNative
          ? count ? [
              NavigationActions.back(),
              NavigationActions.navigate({
                routeName: 'FastFoodMenuPage',
                key: 'FastFoodMenuPage',
                params: { id, count }
              })
            ] : [NavigationActions.back()]
          : count ? [toggleFastFoodMenuForm({ id, count })] : [];
    } else if (hasCustomPrice) {
      dispatchLoad = count =>
        isReactNative
          ? count ? [
              NavigationActions.back(),
              NavigationActions.navigate({
                routeName: 'PriceOrQuantityPage',
                key: 'PriceOrQuantityPage',
                params: { type: 'price', uniqueOrderLine: { ...orderLine, quantity: count }, freshInsert: true }
              })
            ] : [NavigationActions.back()]
          : count ? [togglePriceOrQuantityForm({
              type: 'price',
              uniqueOrderLine: { ...orderLine, quantity: count },
              freshInsert: true
            })] : [];
    } else {
      const customGroupHash = count > 1 && uuid.v4();
      dispatchLoad = count => count
        ? concat(
            repeat(addOrderLine({ ...orderLine, customGroupHash }, { selectAfterAddition: mustEnterText }), count),
            mustEnterText
              ? [isReactNative
                ? NavigationActions.navigate({
                    routeName: 'SetRequiredMessagePage',
                    key: 'SetRequiredMessagePage',
                    params: { id }
                  })
                : toggleTextInput(true)]
              : []
          )
        : isReactNative
          ? [NavigationActions.back()]
          : [];
    }

    if (count > availability && !isWeighted) {
      dispatch(
        isReactNative
          ? [
              NavigationActions.back(),
              NavigationActions.navigate({
                routeName: 'AvailabilityBlockerPage',
                key: 'AvailabilityBlockerPage',
                params: {
                  count,
                  orderLineParams: orderLine,
                  dispatchLoad,
                  unit: 'ks'
                }
              })
            ]
          : showAvailabilityBlocker(count, { ...orderLine, quantity: count }, dispatchLoad)
      );
    } else {
      dispatch(dispatchLoad(count));
    }
  };

  _activateCategory = () => {
    const { dispatch, id } = this.props;

    dispatch(activateCategory(id));
  };

  // this can measure (e, deltaX, deltaY, absX, absY, velocity)
  _swiping = (e, deltaX) => {
    const {
 dispatch, isWeighted, id, isPriceOrQuantityFormOpen, needsInvoiceNo, focusedOrderLines
} = this.props;
    const notAllowedAction = this.hasOpposingTypeInvoicedVsNormal(needsInvoiceNo, focusedOrderLines);

    if (isPriceOrQuantityFormOpen) return;

    this.isSwiping = true;

    if (notAllowedAction) return;

    if (isWeighted) {
      dispatch(toggleWeightForm({ id }));
    } else {
      const count = deltaX > -1 ? 0 : (-1) * Math.round(deltaX / 50);
      dispatch([
        changeAddingItemCount(count)
      ]);
    }
  };

  // this can measure (e, deltaX, deltaY, isFlick, velocity)
  _swiped = (e, deltaX) => {
    const {
 dispatch, addingItemCount, isPriceOrQuantityFormOpen, needsInvoiceNo, focusedOrderLines
} = this.props;
    const notAllowedAction = this.hasOpposingTypeInvoicedVsNormal(needsInvoiceNo, focusedOrderLines);

    if (isPriceOrQuantityFormOpen) return;
    if (notAllowedAction) {
      dispatch([
        changeAddingItemCount(0),
        addError(differentItemTypeError)
      ]);
      // fixme - ugly solution
      setTimeout(() => { this.isSwiping = false; }, 310); // a little more than the delay of withLongPress (300ms)
      return;
    }

    const count = deltaX > -1 ? 0 : (-1) * Math.round(deltaX / 50);
    if (addingItemCount > 0) {
      this._addMenuItemToOrder(count);
      dispatch(changeAddingItemCount(0));
    }
    // fixme - ugly solution
    setTimeout(() => { this.isSwiping = false; }, 310); // a little more than the delay of withLongPress (300ms)
  };

  _tap = () => {
    const {
 dispatch, isItem, needsInvoiceNo, focusedOrderLines
} = this.props;
    const notAllowedAction = this.hasOpposingTypeInvoicedVsNormal(needsInvoiceNo, focusedOrderLines);

    if (notAllowedAction) {
      dispatch(addError(differentItemTypeError));
      return;
    }

    if (isItem) {
      this._addMenuItemToOrder();
    } else {
      this._activateCategory();
    }
  };

  _longPress = () => {
    const {
      dispatch,
      unit,
      spart,
      unitPrice,
      activeSubTableId,
      activeFoodCourse,
      priceLevelId,
      id,
      hasCustomPrice,
      isWeighted,
      addingItemCount,
      needsInvoiceNo,
      focusedOrderLines,
      mustEnterText
    } = this.props;

    if (this.isSwiping) return;

    const notAllowedAction = this.hasOpposingTypeInvoicedVsNormal(needsInvoiceNo, focusedOrderLines);

    if (notAllowedAction) {
      dispatch(addError(differentItemTypeError));
      return;
    }

    if (isWeighted) {
      dispatch(
        isReactNative
          ? NavigationActions.navigate({
              routeName: 'WeightFormPage',
              key: 'WeightFormPage',
              params: { id }
            })
          : toggleWeightForm({ id })
      );
    } else if (addingItemCount === 0) {
      const orderLine = {
        itemId: id,
        singlePrice: unitPrice,
        subTableId: activeSubTableId,
        foodCourse: activeFoodCourse || 0,
        priceLevelId,
        unit,
        hasCustomPrice,
        spart,
        isWeighted,
        needsInvoiceNo,
        mustEnterText
      };

      dispatch(
        isReactNative
          ? NavigationActions.navigate({
              routeName: 'PriceOrQuantityPage',
              key: 'PriceOrQuantityPage',
              params: { type: 'quantity', uniqueOrderLine: orderLine, freshInsert: true }
            })
          : togglePriceOrQuantityForm(
              { type: 'quantity', uniqueOrderLine: orderLine, freshInsert: true }
            )
      );
    }
  };

  _renderSwipeItem = (textColor, shadowColor, itemPureName, itemUnit) => {
    const {
      width,
      icon,
      spart,
      isWeighted,
      backgroundColor,
      description,
      unitPrice,
      itemCode,
      itemsCount,
      priceLevelId,
      availability,
      isItem,
      adjustFontSize
    } = this.props;

    return (
      <Swipeable
        trackMouse
        onSwiping={this._swiping}
        onSwiped={this._swiped}
        style={swipeableStyle}
      >
        <MenuItemCore
          textColor={textColor}
          shadowColor={shadowColor}
          itemPureName={itemPureName}
          itemUnit={itemUnit}
          width={width}
          icon={icon}
          spart={spart}
          isWeighted={isWeighted}
          backgroundColor={backgroundColor}
          description={description}
          unitPrice={unitPrice}
          itemCode={itemCode}
          itemsCount={itemsCount}
          priceLevelId={priceLevelId}
          availability={isItem ? (availability === Infinity ? undefined : availability) : undefined}
          isItem={isItem}
          onPress={this._tap}
          onLongPress={isItem ? this._longPress : null}
          adjustFontSize={adjustFontSize}
        />
      </Swipeable>
);
  };

  _renderNormalItem = (textColor, shadowColor, itemPureName, itemUnit) => {
    const {
      width,
      icon,
      spart,
      isWeighted,
      backgroundColor,
      description,
      unitPrice,
      itemCode,
      itemsCount,
      priceLevelId,
      availability,
      isItem,
      adjustFontSize,
      forRecyclerList
    } = this.props;

    const heightOffset = forRecyclerList ? 12 : 3;

    return (
      <Box
        height={isReactNative ? `${theme.typography.lineHeight() * 3 + heightOffset}px` /* for shadow */ : '100%'}
        width={isReactNative ? width : undefined}
        padding={forRecyclerList ? 0.25 : null}
      >
        <MenuItemCore
          textColor={textColor}
          shadowColor={shadowColor}
          itemPureName={itemPureName}
          itemUnit={itemUnit}
          width={width}
          icon={icon}
          spart={spart}
          isWeighted={isWeighted}
          backgroundColor={backgroundColor}
          description={description}
          unitPrice={unitPrice}
          itemCode={itemCode}
          itemsCount={itemsCount}
          priceLevelId={priceLevelId}
          availability={isItem ? (availability === Infinity ? undefined : availability) : undefined}
          isItem={isItem}
          onPress={this._tap}
          onLongPress={isItem ? this._longPress : null}
          adjustFontSize={adjustFontSize}
        />
      </Box>
);
  };

  hasOpposingTypeInvoicedVsNormal = (needsInvoiceNo, focusedOrderLines) => {
    const pure = filter(
      fol => pathOr(false, 'needsInvoiceNo', fol) !== needsInvoiceNo,
      focusedOrderLines
    );

    return pure.length;
  };

  render() {
    const {
      name,
      isItem,
      unit,
      backgroundColor
    } = this.props;

    const textColor = invertToReadableColor(backgroundColor).toHexString();
    const shadowColor = darkenColor(backgroundColor).toHexString();

    const itemPureName = name;

    return (isItem && !isReactNative)
        ? this._renderSwipeItem(textColor, shadowColor, itemPureName, unit)
        : this._renderNormalItem(textColor, shadowColor, itemPureName, unit);
  }
}

export default connect((state: State) => ({
  addingItemCount: state.orders.active.addingItemCount,
  isPriceOrQuantityFormOpen: state.orders.active.isPriceOrQuantityFormOpen,
  focusedOrderLines: getAllFocusedOrderLinesSelector(state),
  parameters: state.parameters.parameters
}))(MenuItem);

// eslint-disable-next-line react/no-multi-comp
class GoBack_ extends React.PureComponent {
  _onPress = () => {
    const { dispatch } = this.props;
    dispatch(activateLastPage());
  };

  render() {
    const { name, forRecyclerList } = this.props;

    const heightOffset = forRecyclerList ? 12 : 3;

    return (
      <Button
        flexDirection="column"
        onPress={this._onPress}
        height={isReactNative ? `${theme.typography.lineHeight() * 3 + heightOffset}px` /* for shadow */ : '100%'}
        padding={forRecyclerList ? 0.25 : null}
      >
        <Box
          paddingHorizontal={0.5}
          flexDirection="row"
          alignItems="center"
          flexGrow={1}
          backgroundColor="buttonGray"
          overflow="hidden"
        >
          <Box
            width={1.5}
            height={1.5}
            marginRight={0.5}
            alignItems="center"
            justifyContent="center"
            borderWidth={2}
            borderStyle="solid"
            borderColor="white"
            borderRadius={theme.typography.lineHeight()}
          >
            <Icon color="white" name="back-arrow" scale={-1} />
          </Box>
          <Box overflow="hidden" width="100%">
            <Text bold color="white">Naspäť na</Text>
            <Text color="white">{name}</Text>
          </Box>
        </Box>
        <Box
          marginLeft="3px"
          marginRight="3px"
          height="3px"
          flexGrow={0}
          flexShrink={0}
          backgroundColor="buttonGrayShadow"
        />
      </Button>
    );
  }
}

export const GoBack = connect()(GoBack_);
