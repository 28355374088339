// @flow
import { map, path, prop, reverse, sortBy, find, propEq } from 'rambda';
import moment from 'moment';


/**
 * @memberof common/admin/alcohol/utils
 * @param data
 */
export const processReceivedAlcoholList = (data: Object) => {
  const alcohol = path('result.Alcohol.AlcoholSale', data)
    ? reverse(sortBy(prop('datum'))(data.result.Alcohol.AlcoholSale))
    : [];

  return map(
    alcoholItem => ({
      id: alcoholItem.idriadok,
      ean: alcoholItem.ean,
      name: alcoholItem.popis,
      count: Number(alcoholItem.pocet) * (-1),
      author: alcoholItem.autor,
      date: alcoholItem.datum ? moment(alcoholItem.datum, 'YYYY-MM-DD') : moment(),
      apiId: alcoholItem.idriadok,
    }),
    alcohol
  );
};

/**
 * @memberof common/admin/alcohol/utils
 * @param data
 */
export const processReceivedEansList = (data: Object) => {
  const products = path('result.Eans.Product', data)
    ? reverse(sortBy(prop('idriadok'))(data.result.Eans.Product))
    : [];

  return map(
    productItem => ({
      id: productItem.idriadok,
      ean: productItem.ean,
      name: productItem.popis,
      amount: productItem.objem,
      cardNumber: productItem.cKarty
    }),
    products
  );
};

/**
 * @memberof common/admin/alcohol/utils
 * @param value
 * @param data
 */
export const getEanProductInfo = (value: String, data: Object) => {
  const products = path('result.Eans.Product', data)
    ? reverse(sortBy(prop('idriadok'))(data.result.Eans.Product))
    : [];

  return find(propEq('ean', value))(products);
};
