// @flow
import React from 'react';
import Text from './Text';
import { FormattedTime } from 'react-intl';
import moment from 'moment';

class Time extends React.Component {
  constructor(props) {
    super(props);
    this.state = { curTime: Date.now() };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        curTime: Date.now()
      });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { curTime } = this.state;

    return (
      <Text {...this.props}>{moment(curTime).format('LT')}</Text>
      // <FormattedTime
      //   hour="numeric"
      //   minute="numeric"
      //   value={curTime}
      // >
      //   {message => <Text {...this.props}>{message}</Text>}
      // </FormattedTime>
    );
  }
}

export default Time;
