// @flow
import axios from 'axios';

const updateUrl = '/version';

export const checkForUpdates = runningVersion =>
  axios.get(updateUrl, {
    headers: { version: runningVersion },
    params: { t: Date.now() }
  }).then(({ data: body }) => ({
    updateExists: body.latestVersion && (`${body.latestVersion}` !== `${runningVersion}`),
    latestVersion: body.latestVersion
  }));
