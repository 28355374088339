// @flow

/**
 * @memberof common/admin/fiscal/utils
 * @param data
 */
export const processReceivedFiscalData = (data: Object) => {
  const {
    dph_levels: dphLevels = {},
    main_params: mainParams = {},
    main_headers: mainHeaders = {},
    stats = {},
    versions = {}
  } = data;

  return {
    dph: {
      A: dphLevels.A || 0,
      B: dphLevels.B || 0,
      C: dphLevels.C || 0,
      D: dphLevels.D || 0,
      E: dphLevels.E || 0
    },
    icdph: stats.icdph || '',
    dic: stats.dic || '',
    ico: stats.ico || '',
    dkp: stats.dkp || '',
    state: +stats.fiskal_status || 0,
    fiscalDay: versions.fiskal_day || '',
    firmwareVersion: versions.ver_fiskal || '',
    afsVersion: versions.ver_afs || '',
    commSpeed: mainParams.comm_speed || 0,
    databit: mainParams.databit || 0,
    stopbit: mainParams.stopbit || 0,
    parity: mainParams.parity || '',
    flowManagement: mainParams.flow_management || 0,
    portType: mainParams.port_type || '',
    fiskalType: mainParams.fiskal_type || '',
    fiskalPort: mainParams.fiskal_port || '',
    cutOffTime: mainParams.cut_off_time || '',
    logo: +mainParams.print_logo || 0,
    mainHeaders: Object.values(mainHeaders),
  };
};
