// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, pathOr, uniqWith } from 'rambda';
import type { State } from '../../../common/types';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Icon from '../../../common/components/Icon';
import { clearSelectedOrderLines } from '../../../common/order/actions';
import LoadingScreen from '../../../common/components/LoadingScreen';
import TableSearch from '../../admin/TableSearch';
import {
  AutoSizer, Column,
  defaultTableCellRenderer, defaultTableHeaderRenderer,
} from 'react-virtualized';
import SimpleItemsTable, { withInfiniteLoading, withSelectableRows } from '../../admin/Table';
import gql from 'graphql-tag';
import { activateLoyalmanUser } from '../../../common/loyalman/users/actions';
import Spinner from '../../../common/components/Spinner';
import { isFiltering } from '../../../common/order/utils';
import QueryUsers from '../../../common/loyalman/QueryUsers';
import { injectIntl } from 'react-intl';
import loyalmanMessages from '../../../common/messages/loyalman';

const inlineBlock = {
  display: 'inline-block',
  lineHeight: 'normal'
};

const GET_USERS = gql`
    query Users($filter: String!, $after: String){
        users(first: 10, orderBy: "name", after: $after, any: $filter, role: "consumer") {
            edges {
                node {
                    id: record
                    status
                    name
                    surname
                    phoneNumber
                    email
                    validTill
                    productPrepaymentsUsers {
                        balance
                        expiresAt
                        productPrepayment {
                            name
                        }
                    }
                    myTransactions{
                        edges{
                            node{
                                amount
                                transactionType
                                createdAt
                                company{
                                    name
                                }
                                currency{
                                    code
                                }
                                userWallet {
                                  id: record
                                  wallet {
                                    id: record
                                    name
                                  }
                                }
                            }
                        }
                    }
                    userDiscountPackages {
                        package {
                            name
                        }
                    }
                    userWallets{
                        edges{
                            node{
                                wallet{
                                    walletType
                                    name
                                    record
                                    currency {
                                        code
                                    }
                                }
                                balance
                                record
                            }
                        }
                    }
                    myCards{
                        edges{
                            node{
                                code
                                status
                                createdAt
                                expiresAt
                                holder {
                                  id: record
                                  name
                                  surname
                                }
                                discountPackages{
                                    edges{
                                        node{
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                cursor
            }
            pageInfo {
                endCursor
            }
        }
    }
`;

const Table = compose(
  withInfiniteLoading,
  withSelectableRows()
)(SimpleItemsTable);

class Users extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      filter: ''
    };
  }

  _setActiveUser = (user) => {
    const { dispatch } = this.props;

    if (user) {
      dispatch(activateLoyalmanUser(user));
    }
  };

  filterData = (filter, key) => {
    const { storeLoyalmanFilter } = this.props;

    if (key === 'send' || key === 'submit' || key === 'enter' || filter === '') {
      storeLoyalmanFilter(filter);
      this.setState({ filter });
    }
  };

  render() {
    const { dispatch, activeLoyalmanUser, showLoyalmanKeyboard, intl } = this.props;
    const { filter } = this.state;

    const selectedTableRow = {};
    if (activeLoyalmanUser) {
      selectedTableRow[activeLoyalmanUser.id] = true;
    }

    return (
      <Box
        width="100%"
        flex={1}
        backgroundColor="appBg"
        onMouseDown={() => dispatch(clearSelectedOrderLines())}
        paddingTop={1}
        paddingLeft={1}
      >
        <TableSearch
          enableFilter
          enableCalendar={false}
          onFilterChange={this.filterData}
          replaceSkCharsWithNums={true}
          showKeyboard={showLoyalmanKeyboard}
        />

        <QueryUsers query={GET_USERS} variables={{ filter: isFiltering(filter) ? filter : '' }}>
          {({ loading, error, users, fetchMore }) => {
            if (loading) return <Box paddingTop={10}><Spinner /></Box>;

            if (error) return null;

            return (
              <Box flexBasis="100%" backgroundColor="white" marginBottom={1} maxHeight="100vh" height="9999px">
                <AutoSizer>
                  {({ height, width }) => (
                    <Table
                      loadMoreRows={fetchMore}
                      width={width}
                      height={height}
                      items={users}
                      selectedItems={selectedTableRow}
                      onRowClick={({ rowData }) =>
                        this._setActiveUser(rowData)
                      }
                      headerClassName="offset-first"
                      className="light"
                    >
                      <Column
                        label={intl.formatMessage(loyalmanMessages.loyalmanUserName)}
                        dataKey="fullname"
                        width={width * 0.2}
                        cellRenderer={props =>
                          <Text bold paddingLeft={0.5} paddingRight={1} color="inherit">
                            {defaultTableCellRenderer(props)}
                          </Text>
                        }
                      />
                      <Column
                        label={intl.formatMessage(loyalmanMessages.loyalmanUserPhone)}
                        dataKey="phone"
                        width={width * 0.2}
                        cellRenderer={props =>
                          <Text paddingRight={1} color="inherit" flexDirection="row" justifyContent="flex-start" alignItems="center">
                            {defaultTableCellRenderer(props)}
                          </Text>
                        }
                      />
                      <Column
                        label={intl.formatMessage(loyalmanMessages.loyalmanUserEmail)}
                        dataKey="email"
                        width={width * 0.3}
                        cellRenderer={props =>
                          <Text paddingRight={1} color="inherit" flexDirection="row" justifyContent="flex-start" alignItems="center">
                            {defaultTableCellRenderer(props)}
                          </Text>
                        }
                      />
                      <Column
                        label={intl.formatMessage(loyalmanMessages.loyalmanUserDiscountPackages)}
                        dataKey="discountPackages"
                        width={width * 0.4}
                        cellRenderer={props =>
                          <Text align="right" paddingRight={0.5} color="inherit" flexDirection="row">
                            <Icon name="pay" marginRight={0.4} />
                            {defaultTableCellRenderer(props)}
                          </Text>
                        }
                        headerRenderer={props =>
                          <Text
                            align="right"
                            paddingRight={0.5}
                            style={inlineBlock}
                          >
                            {defaultTableHeaderRenderer(props)}
                          </Text>
                        }
                      />
                    </Table>
                  )}
                </AutoSizer>
              </Box>
            );
          }}
        </QueryUsers>

        {this.state.showLoading &&
          <LoadingScreen backgroundColor="transparent" width="100%" height="80%" left={1} />}

      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    activeLoyalmanUser: state.loyalman.users.active.user,
    showLoyalmanKeyboard: state.loyalman.users.showKeyboard
  })),
  injectIntl
)(Users);
