// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  apiUrl: {
    defaultMessage: 'Adresa API',
    id: 'device.form.apiUrl'
  },
  machineId: {
    defaultMessage: 'ID kasy',
    id: 'device.form.machineId'
  },
  machinePassword: {
    defaultMessage: 'Heslo',
    id: 'device.form.machinePassword'
  },
  error: {
    defaultMessage: 'Nepodarilo sa spojiť s API',
    id: 'device.form.error'
  },
  deviceFormSetup: {
    defaultMessage: 'Nastavenia zariadenia',
    id: 'device.form.setup'
  },
  deviceFormContinue: {
    defaultMessage: 'Pokračovať',
    id: 'device.form.continue'
  },
});
