// @flow
import React from 'react';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Icon from '../../../common/components/Icon';
import type { State } from '../../../common/types';
import { connect } from 'react-redux';
import { formatPriceToFixed } from '../../../common/order/utils';
import { isEmpty } from 'ramda';
import TableSearch from '../../admin/TableSearch';
import { findParamValue } from '../../../common/parameters/utils';
import debounce from 'debounce';
import { activateLoyalmanUser } from '../../../common/loyalman/users/actions';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../common/components/Spinner';
import { processReceivedUsers } from '../../../common/loyalman/users/utils';
import moment from 'moment';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import loyalmanMessages from '../../../common/messages/loyalman';

const formatPriceValue = (value, currency) => {
  const price = formatPriceToFixed(value);

  return currency
    ? `${price} ${currency}`
    : `${price}`;
};

const cardsArrayBlock = (content, parentFilter = '', intl) => (
  <Box paddingVertical={1} backgroundColor="white">
    <Box
      width="100%"
      justifyContent="center"
      align="center"
      paddingVertical={0.5}
      backgroundColor="lighterGray"
    >
      <Icon name="pay" marginTop={-0.75} />
      <Text bold>{intl.formatMessage(loyalmanMessages.loyalmanUserCards)}</Text>
    </Box>
    {content.map((element, index) => {
      let backgroundColor = 'white';

      if ((element.expiresAt && element.expiresAt.isBefore(moment().startOf('day')))
          || element.status.toLowerCase() !== 'active') {
        backgroundColor = 'red';
      } else if (element.code.toString() === parentFilter.toString()) {
        backgroundColor = 'blue';
      }
      return (
        <React.Fragment key={index}>
          <Box
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            align="center"
            paddingTop={0.5}
            paddingHorizontal={1}
            backgroundColor={backgroundColor}
            borderColor="lightGray"
            borderStyle="solid"
            borderTopWidth={1}
          >
            <Text>{element.code}</Text>
            {element.expiresAt
              && <Text>{`${intl.formatMessage(loyalmanMessages.loyalmanUserExp)}: ${element.expiresAt.format('DD.MM.YYYY')}`}</Text>
            }
            {(!element.expiresAt && element.status.toLowerCase() !== 'active')
              && <Text>{intl.formatMessage(loyalmanMessages.loyalmanUserBlocked)}</Text>
            }
          </Box>
          <Box
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            align="center"
            paddingBottom={0.5}
            paddingHorizontal={1}
            backgroundColor={backgroundColor}
          >
            <Text scale={-1}>{element.holderFullName}</Text>
          </Box>
        </React.Fragment>);
      })
    }
  </Box>
);

const transactionsArrayBlock = (content, intl) => (
  <Box paddingVertical={1} backgroundColor="white">
    <Box
      width="100%"
      justifyContent="center"
      align="center"
      paddingVertical={0.5}
      backgroundColor="lighterGray"
    >
      <Icon name="pay" marginTop={-0.75} />
      <Text bold>{intl.formatMessage(loyalmanMessages.loyalmanUserTransactions)}</Text>
    </Box>
    {content.map((element, index) => (
      <React.Fragment key={index}>
        <Box
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          align="center"
          paddingTop={0.5}
          paddingHorizontal={1}
          backgroundColor="white"
          borderColor="lightGray"
          borderStyle="solid"
          borderTopWidth={1}
        >
          <Text>{element.walletName || element.transactionType}</Text>
          <Text>{formatPriceValue(element.amount, element.currency)}</Text>
        </Box>
        <Box
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          align="center"
          paddingBottom={0.5}
          paddingHorizontal={1}
          backgroundColor="white"
        >
          <Text scale={-1}>{element.createdAt ? element.createdAt.format('DD.MM.YYYY LT') : null}</Text>
        </Box>
      </React.Fragment>))
    }
  </Box>);

const arrayBlock = (title, content, icon, customKey, customUnit) => (
  <Box paddingVertical={1} backgroundColor="white">
    <Box
      width="100%"
      justifyContent="center"
      align="center"
      paddingVertical={0.5}
      backgroundColor="lighterGray"
    >
      <Icon name={icon} marginTop={-0.75} />
      <Text bold>{title}</Text>
    </Box>
    {content.map((element, index) => (
        <Box
          key={`${title}-${index}`}
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          align="center"
          paddingVertical={0.5}
          paddingHorizontal={1}
          backgroundColor="white"
          borderColor="lightGray"
          borderStyle="solid"
          borderTopWidth={1}
        >
          <Text>{element.name}</Text>
          <Text>{`${customKey ? element[customKey] : formatPriceValue(element.amount, customUnit || element.currency)}${customKey && customUnit ? ` ${customUnit}` : ''}`}</Text>
        </Box>))
    }
  </Box>);

const GET_USERS = gql`
    query($cardId: String!){
        users(first: 10, orderBy: "name", after:"", card: $cardId, role: "consumer") {
            edges {
                node {
                    id: record
                    status
                    validTill
                    name
                    surname
                    phoneNumber
                    email
                    productPrepaymentsUsers {
                        balance
                        expiresAt
                        productPrepayment {
                            name
                        }
                    }
                    myTransactions{
                        edges{
                            node{
                                amount
                                transactionType
                                createdAt
                                company{
                                    name
                                }
                                currency{
                                    code
                                }
                                userWallet {
                                  id: record
                                  wallet {
                                    id: record
                                    name
                                  }
                                }
                            }
                        }
                    }
                    userDiscountPackages {
                        package {
                            name
                        }
                    }
                    userWallets{
                        edges{
                            node{
                                wallet{
                                    walletType
                                    name
                                    record
                                    currency {
                                        code
                                    }
                                }
                                balance
                                record
                            }
                        }
                    }
                    myCards{
                        edges{
                            node{
                                code
                                status
                                expiresAt
                                createdAt
                                holder {
                                  id: record
                                  name
                                  surname
                                }
                                discountPackages{
                                    edges{
                                        node{
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                cursor
            }
        }
    }
`;

class UsersSheet extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filter: ''
    };
  }

  filterData = (filter, key) => {
    const { dispatch } = this.props;

    if (key === 'send' || key === 'cancel' || filter === '') {
      this.setState({ filter });
      dispatch(activateLoyalmanUser(null));
    }
  };

  setActiveUser = debounce(user => {
    const { dispatch } = this.props;

    dispatch(activateLoyalmanUser(user));
  }, 200);

  render() {
    const { activeLoyalmanUser, parameters, parentFilter, intl } = this.props;
    const { filter } = this.state;
    const loyalmanFullUsersInfo = findParamValue('K32.loyalman_full_users_info', parameters) || false;

    const filteredByCard = activeLoyalmanUser && filter !== '' ? activeLoyalmanUser.cards.find(c => c.code === filter) : null;

    return (
      <Box
        backgroundColor="white"
        paddingTop={loyalmanFullUsersInfo ? 2 : 0}
        marginBottom={0}
        flexGrow={(!loyalmanFullUsersInfo && activeLoyalmanUser) ? 0 : 1}
        flexShrink={0}
        flexDirection="column"
        align="center"
        maxHeight={(loyalmanFullUsersInfo || activeLoyalmanUser) ? '100%' : '30%'}
        overflow="auto"
      >

        {!loyalmanFullUsersInfo &&
          <Box
            height={loyalmanFullUsersInfo ? '100%' : 'auto'}
            flexGrow={1}
            flexShrink={0}
            flexDirection="column"
          >
            <TableSearch
              enableFilter
              enableCalendar={false}
              onFilterChange={this.filterData}
              marginBottom={2}
            />

            {!activeLoyalmanUser
              ? <Text bold marginVertical="auto" paddingHorizontal={2}>
                  {intl.formatMessage(loyalmanMessages.loyalmanUserDetailCard)}
                </Text>
              : <Box height={2} />}
          </Box>}

        {!loyalmanFullUsersInfo && !activeLoyalmanUser && filter !== '' &&
          <Query query={GET_USERS} variables={{ cardId: filter }}>
            {({ loading, error, data, fetchMore }) => {
              if (loading) return <Box paddingTop={10}><Spinner/></Box>;

              if (error) return null;

              const processedData = processReceivedUsers(data) || [];
              this.setActiveUser(processedData[0]);

              return <Box />;
            }}
          </Query>}

        {activeLoyalmanUser &&
          <Box flexShrink={0}>
            <Text size={2} marginBottom={0.5} paddingTop={0.5}>{activeLoyalmanUser.fullname}</Text>
            <Text color="gray" paddingBottom={0.5}>{`${activeLoyalmanUser.phone}, ${activeLoyalmanUser.email}`}</Text>
            {activeLoyalmanUser.validTill &&
              <Text
                paddingVertical={0.5}
                backgroundColor={activeLoyalmanUser.validTill.isBefore(moment().startOf('day')) ? 'red' : 'white'}
              >
                {`${intl.formatMessage(loyalmanMessages.loyalmanUserValidTo)}: ${activeLoyalmanUser.validTill.format('DD.MM.YYYY')}`}
              </Text>
            }
            {(!loyalmanFullUsersInfo && filteredByCard && filteredByCard.expiresAt) &&
              <Text
                backgroundColor={filteredByCard.expiresAt.isBefore(moment().startOf('day')) ? 'red' : 'white'}
              >{`${intl.formatMessage(loyalmanMessages.loyalmanUserCard)} (${filteredByCard.code}) ${intl.formatMessage(loyalmanMessages.loyalmanUserExpire)}: ${filteredByCard.expiresAt.format('DD.MM.YYYY')}`}</Text>
            }
            <Box
              width="100%"
              justifyContent="center"
              align="center"
              paddingHorizontal={1}
              marginVertical={1}
            >
              {loyalmanFullUsersInfo && !isEmpty(activeLoyalmanUser.cards) &&
              cardsArrayBlock(activeLoyalmanUser.cards, parentFilter, intl)}

              {!isEmpty(activeLoyalmanUser.discountPackages) &&
                <Box
                  width="100%"
                  paddingVertical={0.5}
                  backgroundColor="lighterGray"
                >
                  <Text>{String(activeLoyalmanUser.discountPackages)}</Text>
                </Box>}

              {!isEmpty(activeLoyalmanUser.credit) &&
              arrayBlock(intl.formatMessage(loyalmanMessages.loyalmanUserCredit), activeLoyalmanUser.credit, 'pay')}
              {!isEmpty(activeLoyalmanUser.bonus) &&
              arrayBlock(intl.formatMessage(loyalmanMessages.loyalmanUserBonus), activeLoyalmanUser.bonus, 'pay', null, 'B')}
              {!isEmpty(activeLoyalmanUser.prepaidProducts) &&
              arrayBlock(intl.formatMessage(loyalmanMessages.loyalmanUserPrepaidProducts), activeLoyalmanUser.prepaidProducts, 'pay', 'balance', 'ks')}
              {!isEmpty(activeLoyalmanUser.transactions) &&
              transactionsArrayBlock(activeLoyalmanUser.transactions, intl)}
            </Box>
          </Box>}
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    parameters: state.parameters.parameters,
    activeLoyalmanUser: state.loyalman.users.active.user
  })),
  injectIntl
)(UsersSheet);
