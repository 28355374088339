// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { compose, propEq, find } from 'rambda';
import { mergeDeepRight } from 'ramda';
import Box from '../../common/components/Box';
import Table, { draggableTable as draggableTableType } from './Table';
import { addTableDefinition, updateTableGeometry } from '../../common/tables/actions';
import type { DraggableTableItem } from './Table';
import { undeletedTableDefinitionsSelector } from '../../common/tables/selectors';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/components';

type TableAreaProps = {
  children?: any
}

type DroppableTableAreaProps = TableAreaProps & {
  isEnabledDnd?: boolean,
  connectDropTarget: Function,
  scaleFactor: number,
  serviceAreaId: number
};

export const canEditTable = ({ isInUse }) => !isInUse;

class DragDropTableArea extends React.PureComponent {
  props: DroppableTableAreaProps;

  render() {
    const {
      connectDropTarget,
      isEnabledDnd,
      children,
    } = this.props;

    const changedChildren = React.Children.map(children, child => {
      if (child.type === Table) {
        return React.cloneElement(child,
          { isEditing: isEnabledDnd && canEditTable(child.props) }
        );
      }

      return child;
    });

    return isEnabledDnd
      ?
        connectDropTarget(
          <div style={{ width: '100%', height: '100%', display: 'block' }}>
            <Box width="100%" height="100%">{changedChildren}</Box>
          </div>
        )
      : <Box width="100%" height="100%">{changedChildren}</Box>;
  }
}

const spec = {
  drop(props: DroppableTableAreaProps, monitor, component) {
    const item: DraggableTableItem = monitor.getItem(); // defined in Table.js in spec.beginDrag()

    if (item.id) {
      const { scaleFactor, dispatch, tables } = props;
      const delta = monitor.getDifferenceFromInitialOffset();
      const tableDefinition = find(propEq('id', item.id), tables);

      const updateTable = mergeDeepRight(tableDefinition, {
        geometry: {
          x: tableDefinition.geometry.x + (delta.x / scaleFactor),
          y: tableDefinition.geometry.y + (delta.y / scaleFactor),
        }
      });

      dispatch(updateTableGeometry(updateTable));
    } else {
      const { x: offsetX, y: offsetY } = monitor.getDifferenceFromInitialOffset();
      const minDistance = 20;

      if (Math.sqrt(offsetX * offsetX + offsetY * offsetY) < minDistance) {
        return;
      }

      const { scaleFactor, serviceAreaId, dispatch, useIntl } = props;
      // eslint-disable-next-line react/no-find-dom-node
      const { left: areaX, top: areaY } = ReactDOM.findDOMNode(component).getBoundingClientRect();
      const { x, y } = monitor.getSourceClientOffset();

      dispatch(addTableDefinition({
        name: useIntl.formatMessage(messages.tableAreaNewTable),
        serviceAreaId,
        // number: maxTableNumber(openTables) + 1,
        geometry: {
          ...item.geometry,
          x: (x - areaX) / scaleFactor,
          y: (y - areaY) / scaleFactor
        }
      }));
    }
  }
};

const collect = (connect) => ({
  connectDropTarget: connect.dropTarget(),
});

export default compose(
  connect((state: State) => ({
    tables: undeletedTableDefinitionsSelector(state),
    scaleFactor: state.serviceAreas.scale,
    serviceAreaId: state.serviceAreas.activeAreaId,
    isEnabledDnd: state.tables.editPosition,
    useIntl: state.intl.useIntl
  })),
  DragDropContext(HTML5Backend),
  DropTarget(draggableTableType, spec, collect),
  injectIntl
)(DragDropTableArea);
