// @flow
import { findParamValue } from '../../common/parameters/utils';
import { signIn, signOut, waiterKeyInitDone, waiterKeyProcessClosed } from '../../common/auth/actions';
import { isDeviceConfigured } from '../../common/device/utils';

// https://everyday.codes/javascript/improve-your-redux-skills-by-writing-custom-middleware/

type MessageDataProps = {
  MessageType?: string,
  Payload?: string,
}

const MessageTypes = {
  KeyInserted: 'KeyInserted',
  KeyRemoved: 'KeyRemoved',
  KeepAlive: 'KeepAlive',
  Failure: 'Failure',
  Quit: 'Quit',
};

export const keepAliveMessage = () => {
  const message: MessageDataProps = {
    MessageType: MessageTypes.KeepAlive,
    Payload: ''
  };

  return JSON.stringify(message);
};

const createWaiterKeyMiddleware = interceptors => store => next => action => {
  next(action);

  if (process.env.IS_ELECTRON === '1') {
    interceptors
      .filter(interceptor => interceptor.type === action.type)
      .forEach(interceptor => interceptor.handler(action, store.dispatch, store.getState));
  }
};

const parseMessageData = (dispatch, getState, message) => {
  const {
    auth: {
      waiterKey: waiterKeyState
    },
    device
  } = getState();

  let data: MessageDataProps = null;

  try {
    data = JSON.parse(message.trim());
  } catch (e) {}

  if (!data) {
    return;
  }

  switch (data.MessageType) {
    case MessageTypes.KeyInserted: {
      if (waiterKeyState.running && isDeviceConfigured(device)) {
        dispatch(signOut());
        dispatch(signIn(data.Payload, true));
      }
      break;
    }
    case MessageTypes.KeyRemoved: {
      if (waiterKeyState.signedUsingKey) {
        dispatch(signOut());
      }
      break;
    }
    case MessageTypes.KeepAlive: {
      break;
    }
    case MessageTypes.Failure: case MessageTypes.Quit: {
      if (waiterKeyState.signedUsingKey) {
        dispatch(signOut());
      }
      break;
    }
    default: break;
  }
};

const onProcessClose = (dispatch, getState) => {
  const {
    auth: {
      waiterKey: waiterKeyState
    }
  } = getState();

  if (waiterKeyState.signedUsingKey) {
    dispatch(signOut());
  }

  dispatch(waiterKeyProcessClosed());
};

const initWaiterKey = (action, dispatch, getState) => {
  const {
    parameters: {
      parameters
    },
    auth: {
      waiterKey: waiterKeyState
    }
  } = getState();

  if (waiterKeyState.running) {
    return;
  }

  const useWaiterKey = findParamValue('K32.waiter_key_enabled', parameters);
  const waiterKeyComPort = findParamValue('K32.waiter_key_com_port', parameters);

  if (useWaiterKey) {
    const childProcess = process.env.IS_ELECTRON === '1' ? require('child_process') : {};

    if (process.env.IS_ELECTRON !== '1') {
      return;
    }

    const wkProcess = childProcess.spawn('waiterKey\\WaiterKeyReader.exe', [waiterKeyComPort], { detached: true });

    dispatch(waiterKeyInitDone(wkProcess));

    // https://stackoverflow.com/questions/20086849/how-to-read-from-stdin-line-by-line-in-node
    let buff = '';
    wkProcess.stdout.on('data', (data) => {
      buff += data;
      const lines = buff.split(/[\r\n|\n]/);
      buff = lines.pop();
      lines.forEach(line => parseMessageData(dispatch, getState, line));
    }).on('end', () => {
      if (buff.length > 0) parseMessageData(dispatch, getState, buff);
    });

    // wkProcess.stdin.setEncoding('utf-8');

    wkProcess.on('exit', () => onProcessClose(dispatch, getState)); // wkProcess.on('close', () => onProcessClose(dispatch, getState));
  }
};

const interceptors = [
  { type: 'APP_STARTED', handler: initWaiterKey },
  { type: 'BLITZ_SYNC_SERVER_DATA', handler: initWaiterKey },
  { type: 'WAITER_KEY_PROCESS_CLOSED', handler: initWaiterKey },
];

export default createWaiterKeyMiddleware(interceptors);
