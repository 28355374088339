// @flow
import { reject, equals } from 'rambda';

export const RequestOrderError = function (operationName) {
  this.name = 'RequestOrderError';
  this.request = operationName;

  this.toString = () => this.name;
};
RequestOrderError.prototype = Object.create(Error.prototype);
RequestOrderError.prototype.constructor = RequestOrderError;

let runningRequests = {};

export const clearAll = operationName => {
  if (operationName) {
    // TODO existing cancel requests
    delete operationName[operationName];
  } else {
    runningRequests = {};
  }
};

export default (operationName): () => Promise => {
  const forgetRequest = request => {
    const requests = runningRequests[operationName] || [];
    runningRequests[operationName] = reject(equals(request), requests);
  };

  const pushRequest = request => {
    if (!runningRequests[operationName]) {
      runningRequests[operationName] = [];
    }

    runningRequests[operationName].push(request);
  };

  const isOperationRunning = () => {
    const requests = runningRequests[operationName];

    if (!requests) return false;

    return requests.length;
  };

  return (callback: Promise | () => Promise, ...args): Promise => {
    if (isOperationRunning()) {
      return Promise.reject(new RequestOrderError(operationName));
    }

    const request = typeof callback === 'function' ? callback(...args) : callback;

    pushRequest(request);

    return request.then(response => {
      forgetRequest(request);
      return response;
    }).catch(e => {
      forgetRequest(request);
      throw e;
    });
  };
};
