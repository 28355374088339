// @flow
import React from 'react';
import Text from '../../common/components/Text';
import InactiveBg from './InactiveBg';
import Box from '../../common/components/Box';
import Corner from './Corner';

export default ({
  mode,
  text,
  color = 'black',
  backgroundColor = 'white',
  onClose,
  showClose = false,
  position = 'fixed',
  customStyle = {},
  ...props
}) =>
  (mode === 'overlay')
    ? (<InactiveBg zIndex={20} onClose={onClose} position={position}>
      <Box
        flexShrink={0}
        paddingVertical={1.75}
        paddingHorizontal={2.5}
        backgroundColor={backgroundColor}
        boxShadow="default"
        position="absolute"
        top="50%"
        left="50%"
        style={{ transform: 'translate(-50%, -50%)', ...customStyle }}
        width="60%"
      >
        {Array.isArray(text)
          ? text.map((msg, index) =>
            <Box width="100%" key={index}>
              <Text
                bold
                align="center"
                color={color}
                scale={2}
                {...props}
              >
                {text.toString()}
              </Text>
            </Box>)
          : <Text
              bold
              align="center"
              color={color}
              scale={2}
              {...props}
            >
              {text.toString()}
            </Text>
        }

        {showClose && (
          <Corner
            size={2.5}
            backgroundColor={backgroundColor}
            onPress={onClose}
            borderRadius="normal"
            corner="top-right"
          >
            <Text color="black" bold size={1.75}>&times;</Text>
          </Corner>
        )}
      </Box>
      </InactiveBg>)
    : (<Box
        flexShrink={0}
        padding={0.75}
        zIndex={1000}
        backgroundColor={backgroundColor}
        boxShadow="default"
        style={customStyle}
      >
        {Array.isArray(text)
          ? text.map((msg, index) =>
            <Box width="100%" key={index}>
              <Text
                bold
                align="center"
                color={color}
                width="100%"
                {...props}
              >
                {msg.toString()}
              </Text>
            </Box>)
          : <Text
              bold
              align="center"
              color={color}
              width="100%"
              {...props}
            >
              {text.toString()}
            </Text>
        }

        {showClose &&
          <Corner size={2.5} backgroundColor={backgroundColor} onPress={onClose} borderRadius="normal" right={0}>
            <Text color={color} bold size={1.75}>&times;</Text>
          </Corner>}
      </Box>);
