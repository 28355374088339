// @flow
import type { TextProps } from '../../common/components/Text';
import React from 'react';
import { Link as FoundRouterLink } from 'react-router-dom';
import Text from '../../common/components/Text';

type LinkProps = TextProps & {
  to: string,
  noDecorationOnHover: boolean,
};

const isExternalLink = to => to.includes('://');

const Anchor = ({ to, ...props }) => (
  <a href={to} rel="noopener noreferrer" target="_blank" {...props} />
);

const RouterLink = props => (
  <FoundRouterLink replace {...props} />
);

const Link = (
  {
    color = 'primary',
    style,
    ...props
  }: LinkProps,
) => {
  const AnchorOrRouterLink = isExternalLink(props.to) ? Anchor : RouterLink;
  const linkStyle = {
    ':hover': {
      textDecoration: props.noDecorationOnHover ? 'none' : 'underline',
    },
  };
  return (
    <Text
      as={AnchorOrRouterLink}
      color={color}
      decoration="none"
      style={(theme, textStyle) => ({
        ...linkStyle,
        ...(style && style(theme, { ...textStyle, ...linkStyle })),
      })}
      {...props}
    />
  );
};

export default Link;
