// @flow
import type { State } from '../../common/types';
import React from 'react';
import ContextMenu from '../components/ContextMenu';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import { connect } from 'react-redux';
import {
  compose, find, propEq, filter, map, pipe, pluck, last
} from 'rambda';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../common/messages/order';
import ContextMenuButton from '../../common/components/ContextMenuButton';
import Divider from '../../common/components/Divider';
import {
  toggleRelocatingToFoodCourse,
  toggleRelocatingToSubTable,
  toggleRelocatingToTable,
  toggleTextInput,
  toggleIsChangingPriceLevel,
  toggleIsChangingPortion,
  togglePriceOrQuantityForm,
  clearSelectedOrderLines,
  toggleSubTableMenu,
  showAvailabilityBlocker,
} from '../../common/order/actions';
import {
  checkSelectForWeighted,
  checkSelectForFastFoodMenu,
  checkSelectForCustomPriced,
  extractTotalUniqueOrderLines,
  checkAvailabilityOfMultipleItems,
  plusOrderLine,
  findAllIdenticOrderLines,
  allMatchingItemsExist
} from '../../common/order/utils';
import { activateArea } from '../../common/serviceAreas/actions';
import { findParamValue } from '../../common/parameters/utils';
import { toggleWeightForm } from '../../common/peripherals/actions';
import { undeletedOrderLinesSelector } from '../../common/order/selectors';
import { activeOpenTableSelector } from '../../common/tables/selectors';
import checkPermission from '../../common/permissions/service';

const hasAnyCustomPrice = orderLines => {
  if (orderLines){
    const customPriceOrderLine = orderLines.find(line => line.hasCustomPrice === true);
    if (customPriceOrderLine !== undefined) {
      return true;
    }
  }

  return false;
};

const OrderLineSheetContextMenu = ({
    dispatch,
    target,
    backgroundColor,
    orderLines,
    selectedOrderLines: allSelectedOrderLineIds,
    activeOpenTable,
    confirmDelete,
    parameters,
    items,
    isSubTableMenuOpen,
    itemsAvailability,
    itemsAvailabilityDiff,
    isLimit,
    intl
  }) => {
  const paramIsPriceLevelChangeable = activeOpenTable
    ? (activeOpenTable.bar || activeOpenTable.barCash)
      ? findParamValue('K32.is_chlbar', parameters)
      : true
    : true;

  const menuSpartParam = findParamValue('K32.menu_spart', parameters);
  const selectedOrderLines = pipe(
    map(ol => {
      const orderLine = find(propEq('id', ol), orderLines);
      return (orderLine && orderLine.orderType !== 11) ? orderLine : null;
    }),
    filter(Boolean)
  )(allSelectedOrderLineIds);

  const selectedOrderLineIds = pluck('id', selectedOrderLines);

  const lastSelectedOrderLine = last(selectedOrderLines);
  const isNotOnlyOneOfAKind = findAllIdenticOrderLines(lastSelectedOrderLine, orderLines).length > 1;

  const totalUniqueOrderLines = extractTotalUniqueOrderLines(orderLines, selectedOrderLineIds);

  const allCorespondingItemsExist = allMatchingItemsExist(totalUniqueOrderLines, items);

  const { multipleSelectedInclWeighted, hasWeighted, weightedItemId } =
    allCorespondingItemsExist
      ? checkSelectForWeighted(totalUniqueOrderLines, items)
      : { multipleSelectedInclWeighted: false, hasWeighted: false, weightedItemId: null };

  const { multipleSelectedInclFastFoodMenu, hasFastFoodMenu } =
    allCorespondingItemsExist
      ? checkSelectForFastFoodMenu(totalUniqueOrderLines, items, menuSpartParam)
      : { multipleSelectedInclFastFoodMenu: false, hasFastFoodMenu: false };

  const { multipleSelectedInclCustomPriced, needsInvoiceNo } =
    allCorespondingItemsExist
      ? checkSelectForCustomPriced(totalUniqueOrderLines, items)
      : { multipleSelectedInclCustomPriced: false, needsInvoiceNo: false };

  const containsOrderLineInPayment = !!find(({ paymentId }) => paymentId, selectedOrderLines);
  const containsOnlyUnconfirmedOrderLines = !!find(({ isSentToKitchen }) => isSentToKitchen, selectedOrderLines);//isConfirmedAmongSelected(selectedOrderLines, orderLines);

  const increaseItems = (uniqueOrderLines) => {
    const fillDispatchLoadFn = (callback, orderLine) =>
      showAvailabilityBlocker(
        1,
        { ...orderLine, quantity: 1 },
        count => callback
          ? [
            plusOrderLine([orderLine], orderLines, count),
            callback
          ]
          : [plusOrderLine([orderLine], orderLines, count)]
      );

    const dispatchLoad = checkAvailabilityOfMultipleItems(
      uniqueOrderLines, itemsAvailability, itemsAvailabilityDiff, orderLines, fillDispatchLoadFn
    );

    dispatch([
      clearSelectedOrderLines(),
      ...dispatchLoad
    ]);
  };

  const withSubText = (text, subText) => (
    <React.Fragment>
      {text}
      <Text size={0.75} color="white">{subText}</Text>
    </React.Fragment>);

  let menuItems = [
    [
      {
        key: 1,
        condition: !multipleSelectedInclWeighted
          && !multipleSelectedInclFastFoodMenu
          && !multipleSelectedInclCustomPriced
          && !needsInvoiceNo
          && checkPermission('orderline.change'),
        icon: <Text color="white" bold scale={3}>+</Text>,
        text: totalUniqueOrderLines.length === 1 && !hasWeighted && !needsInvoiceNo
          ? withSubText(<FormattedMessage {...messages.addOne} />, <FormattedMessage {...messages.addMultipleTimes} />)
          : <FormattedMessage {...messages.addOne} />,
        onPress: () => hasWeighted
          ? dispatch(toggleWeightForm({ id: weightedItemId }, true))
          : increaseItems(totalUniqueOrderLines),
        onLongPress: () => totalUniqueOrderLines.length === 1 && !hasWeighted
          ? dispatch(togglePriceOrQuantityForm({
            type: 'quantity',
            uniqueOrderLine: totalUniqueOrderLines[0]
          }))
          : null,
      },
      {
        key: 2,
        condition: !containsOrderLineInPayment && (checkPermission('orderline.delete') || !containsOnlyUnconfirmedOrderLines),
        icon: 'trash',
        text: totalUniqueOrderLines.length === 1
          ? withSubText(<FormattedMessage {...messages.deleteSelected} />, <FormattedMessage {...messages.deleteSelectedMultipleTimes} />)
          : <FormattedMessage {...messages.deleteSelected} />,
        onPress: () => confirmDelete(),
        onLongPress: () => totalUniqueOrderLines.length === 1 && checkPermission('orderline.delete')
          ? dispatch(togglePriceOrQuantityForm({
            type: 'deleteQuantity',
            uniqueOrderLine: totalUniqueOrderLines[0]
          }))
          : null,
      },
      {
        key: 3,
        condition: isNotOnlyOneOfAKind && !hasFastFoodMenu,
        icon: 'subitems',
        text: <FormattedMessage {...messages.selectCount} />,
        onPress: () =>
          dispatch(togglePriceOrQuantityForm({
            type: 'selectExactCount',
            uniqueOrderLine: lastSelectedOrderLine
          }))
      },
      {
        key: 4,
        condition: !containsOrderLineInPayment && !isLimit
          && paramIsPriceLevelChangeable
          && checkPermission('pricelevel.change') && !hasAnyCustomPrice(selectedOrderLines),
        icon: 'price-level',
        text: <FormattedMessage {...messages.changePriceLevel} />,
        onPress: () => dispatch(toggleIsChangingPriceLevel(true))
      },
      {
        key: 5,
        condition: !containsOrderLineInPayment && !isLimit
          && !hasFastFoodMenu
          && checkPermission('orderline.size.change'),
        icon: <Text color="white" scale={2} bold>½</Text>,
        text: <FormattedMessage {...messages.changeItemSize} />,
        onPress: () => dispatch(toggleIsChangingPortion(true))
      }
    ],
    [
      {
        key: 6,
        condition: !containsOrderLineInPayment && checkPermission('orderline.note.add') && !isLimit,
        icon: 'notes',
        text: <FormattedMessage {...messages.addNote} />,
        onPress: () => dispatch(toggleTextInput(true))
      }
    ],
    [
      {
        key: 7,
        condition: !containsOrderLineInPayment && checkPermission('orderline.foodcourse.change') && !isLimit,
        icon: 'food',
        text: <FormattedMessage {...messages.relocateToFoodCourse} />,
        onPress: () => dispatch(toggleRelocatingToFoodCourse(true))
      },
      {
        key: 8,
        condition: !containsOrderLineInPayment && checkPermission('orderline.subtable.change') && !isLimit,
        icon: 'users',
        text: <FormattedMessage {...messages.relocateToSubTable} />,
        onPress: () => dispatch([
          toggleRelocatingToSubTable(true),
          toggleSubTableMenu(!isSubTableMenuOpen)
        ])
      },
      {
        key: 9,
        condition: !containsOrderLineInPayment && checkPermission('orderline.opentable.change'),
        icon: 'one-stand-table',
        text: <FormattedMessage {...messages.relocateToOpenTable} />,
        onPress: () =>
          dispatch([
            toggleRelocatingToTable(true),
            activateArea(activeOpenTable.serviceAreaId)
          ])
      }
    ]
  ];

  menuItems = map(filter(({ condition }) => condition), menuItems);
  menuItems = filter(group => group.length, menuItems);
  const dividerStyle = { marginTop: 0, marginBottom: 10, opacity: 0.2 };

  return (
    <Box position="absolute" top="0px" width="1000%" zIndex={2} backgroundColor="red">
      <ContextMenu
        belongsTo={target}
        boundaries={target}
        backgroundColor={backgroundColor}
        side="left"
        isOrderSheetContextMenu
      >
        <Text color="white" scale={-1} marginBottom={0.5} align="center">
          {`${intl.formatMessage(messages.orderChosenItems)}: ${selectedOrderLines.length}`}
        </Text>

        {menuItems.map((itemsGroup, i) =>
          <React.Fragment key={i}>
            <Divider color="white" style={dividerStyle} />

            {itemsGroup.map(({ condition, ...props }) => <ContextMenuButton key={props.key} {...props} />)}
          </React.Fragment>
        )}
      </ContextMenu>
    </Box>
  );
};

export default compose(
  connect(
    (state: State) => ({
      activeOpenTable: activeOpenTableSelector(state),
      orderLines: undeletedOrderLinesSelector(state),
      selectedOrderLines: state.orders.selectedOrderLines,
      parameters: state.parameters.parameters,
      items: state.items.items,
      isSubTableMenuOpen: state.orders.active.isSubTableMenuOpen,
      itemsAvailability: state.items.availability,
      itemsAvailabilityDiff: state.items.availabilityLocalDiff,
      isLimit: state.serviceAreas.activeIsLimit
    })
  ),
  injectIntl,
)(OrderLineSheetContextMenu);
