// @flow
import React from 'react';
import { connect } from 'react-redux';
import TablesGrid from '../serviceAreas/TablesGrid';
import { isServiceAreaVirtual } from '../../common/serviceAreas/utils';
import TablesList from '../serviceAreas/TablesList';

const Tables = ({ activeAreaId, areas }) => (
  (isServiceAreaVirtual(activeAreaId, areas))
    ? <TablesList />
    : <TablesGrid />
);

export default connect((state: State) => ({
  activeAreaId: state.serviceAreas.activeAreaId,
  areas: state.serviceAreas.serviceAreas,
}))(Tables);
