// @flow
import React from 'react';
import Box from '../../../components/Box';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import ScrollView from '../../../components/ScrollView';
import { isReactNative } from '../../../app/detectPlatform';
import { remoteLoadFromProps } from '../../../components/hoc/remoteLoad';
import api from '../../../lib/api';
import Spinner from '../../../components/Spinner';
import Text from '../../../components/Text';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import paymentMessages from '../../../messages/payment';

class Guests extends React.PureComponent {
  render() {
    const {
      remotePayload: guests = [],
      remoteError,
      remoteIsLoading,
      isLoading,
      guestId,
      onConfirm,
      intl
    } = this.props;

    return (
      <Box
        width={isReactNative ? null : 20}
        maxHeight={isReactNative ? '100%' : '70vh'}
        marginHorizontal={-1}
        flex={1}
      >
        <Heading scale={2} color="white" marginHorizontal={1}>{intl.formatMessage(paymentMessages.paymentHoresChooseGuest)}</Heading>

        {remoteError ? null : remoteIsLoading
          ? (
            <Box marginTop={4}>
              <Spinner color={isReactNative ? 'gray' : ''} size="large" />
            </Box>
          ) : (
            <Box>
              <ScrollView>
                {guests.map(({
                  id,
                  guest_name: guest,
                  checkin_date: checkIn,
                  checkout_date: checkOut
                }) => (
                  <Button
                    key={id}
                    marginHorizontal={1}
                    backgroundColor="white"
                    paddingVertical={0.5}
                    paddingHorizontal={1}
                    marginBottom={0.5}
                    justifyContent="flex-start"
                    disabled={isLoading}
                    flexShrink={0}
                    onPress={() => onConfirm(id, guest)}
                  >
                    {isLoading && guestId === id
                      ? <Box marginRight={0.5}><Spinner size="small" /></Box>
                      : null
                    }
                    <Box height="100%">
                      <Text>{guest}</Text>
                      <Text scale={-2}>{`${checkIn} – ${checkOut}`}</Text>
                    </Box>
                  </Button>
                ))}
              </ScrollView>

              <Button
                onPress={() => onConfirm()}
                outline
                color="white"
                backgroundColor="teal"
                marginTop={1}
                marginHorizontal={1}
                paddingVertical={0.5}
                disabled={isLoading}
                flexShrink={0}
              >
                {isLoading && !guestId
                  ? <Box marginRight={0.5}><Spinner size="small" color="white" /></Box>
                  : null
                }

                <Box justifyContent="center" height={1.25}>
                  <Text color="white" bold>{intl.formatMessage(paymentMessages.paymentHoresSkipChooseGuest)}</Text>
                </Box>
              </Button>
            </Box>
          )
        }

        {remoteError ? <Text color="error" marginVertical={1}>{remoteError}</Text> : null}
      </Box>
    );
  }
}

export default compose(
  remoteLoadFromProps(
    api.hores.getGuests,
    ({ hotelId, roomId }) => [hotelId, roomId]
  ),
  connect(),
  injectIntl
)(Guests);
