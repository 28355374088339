// @flow
import React from 'react';
import { connect } from 'react-redux';
import type { State } from '../types';
import networkConnectivityProvider from './hoc/networkConnectivityProvider';

class NetworkConnectivity extends React.PureComponent {
  render() {
    const { RenderComponent, apiUrl } = this.props;

    if (apiUrl) {
      const apiUrlTrimmedSlash = apiUrl.replace(/\/+$/, '');
      const RenderComponentWithNetwork = networkConnectivityProvider({
        withRedux: true,
        checkConnectionInterval: 5000,
        timeout: 7000,
        pingServerUrl: `${apiUrlTrimmedSlash}/api/heartbeat/`
      })(RenderComponent);

      return <RenderComponentWithNetwork />;
    }

    return <RenderComponent />;
  }
}

export default connect((state: State) => ({
  apiUrl: state.device.apiUrl
}))(NetworkConnectivity);
