// @flow
import type { Operations } from '../index';

const operations: Operations = (saveChanges) => ({
  sendKey(store, key): void {
    // eslint-disable-next-line default-case
    switch (key) {
      case 1: case '1':
      case 2: case '2':
      case 3: case '3':
      case 4: case '4':
      case 5: case '5':
      case 6: case '6':
      case 7: case '7':
      case 8: case '8':
      case 9: case '9':
      case 0: case '0':
        if (!store.curInput() || store.curInput() === '0' || store.getLastKey() === '=' || store.isReset()) {
          store.setNewInput(`${key}`);
        } else {
          store.setNewInput(`${store.curInput()}${key}`);
        }
        store.setLastKey(`${key}`);
        break;

      case '.': {
        const lastGroup = store.curInput().split(' ').pop();
        if (store.getLastKey() !== '=' && lastGroup.includes(key)) {
          return;
        }

        if (store.isReset() || !['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(store.getLastKey())) {
          key = '0.';
        }

        if (store.isReset() || store.getLastKey() === 'c' || store.getLastKey() === '=') {
          store.setNewInput(key);
        } else {
          store.setNewInput(`${store.curInput()}${key}`);
        }
        store.setLastKey(`${key}`);
        break;
      }

      case 'ok': {
        store.setLastKey(key);
        break;
      }

      case '+':
      case '-':
      case '*':
      case '/': {
        const lastKey = store.getLastKey();

        if (['+', '-', '*', '/'].includes(lastKey)) {
          const noLastEq = store.input.substr(0, store.input.length - 3);

          store.setNewInput(`${noLastEq} ${key} `);
        } else {
          store.setNewInput(`${store.curInput()} ${key} `);
        }

        store.setLastKey(key);
        break;
      }

      case '=': {
        try {
          const trimmedInput = store.curInput().slice(-1) === ' '
            ? store.curInput().slice(0, -3)
            : store.curInput();
          // eslint-disable-next-line no-eval
          const evaled = eval(trimmedInput);
          const evaledString = typeof evaled === 'number' ? `${evaled.toFixed(2)}` : '';
          store.setNewInput(evaledString);
          store.setLastKey(key);
          store.saveMemory(evaledString);
        } catch (e) {
          console.log(e);
        }

        break;
      }

      case 'c': {
        store.setNewInput('0');
        store.setLastKey(key);
      }
    }

    saveChanges(store);
  }
});

export const withDefaultInputValue = (operations: Operations) => {
  let thereIsDefaultInputValue = true;

  return (saveChanges) => {
    const createdStandardOperations = operations(saveChanges);

    return ({
      sendKey(store, key) {
        switch (key) {
          case 1: case '1':
          case 2: case '2':
          case 3: case '3':
          case 4: case '4':
          case 5: case '5':
          case 6: case '6':
          case 7: case '7':
          case 8: case '8':
          case 9: case '9':
          case 0: case '0':
            if (thereIsDefaultInputValue) {
              store.input = '';
            }

            thereIsDefaultInputValue = false;
            break;

          case '+':
          case '-':
          case '*':
          case '/':
          case '=':
          case 'c':
            thereIsDefaultInputValue = false;
            break;

          default:
        }

        return createdStandardOperations.sendKey(store, key);
      }
    });

  };
};

export default operations;
