// @flow
import type {
  Item, SubTable, OrderLine, ItemPrice, OpenTable, Id, AllParameters, Parameter
} from '../types';
import {
  find, findIndex, propEq, map, pluck, filter, uniqWith, repeat, sort, flatten, reduce,
  contains, update, reject, pickAll, uniq, all, prepend, path, prop, equals, append
} from 'rambda';
import { mergeAll } from 'rambdax';
import { sum, clone, groupBy, isEmpty } from 'ramda';
import {
  confirmOrderLine,
  addOrderLine,
  deleteOrderLines,
  clearSelectedOrderLines,
  confirmOrderLineCommit
} from './actions';
import { ApiError } from '../lib/fetch/errors';
import moment from 'moment';
import { findParamValue, isSpartIncludedInParams } from '../parameters/utils';
import PrgDotaz from '../payment/prgDotazy/PrgDotaz';
import { addLocalId } from '../localIds/actions';
import uuid from 'uuid';
import { getItemAvailability } from '../items/utils';
import commonMessages from '../messages/common';
import { lookUpId } from '../localIds/lookUpService';

/**
 * @memberof common/order/utils
 * @param orderLines
 * @param items
 * @returns {*}
 */
export const removeItemFromOrderLines =
(orderLines: OrderLine | [OrderLine], items: Item | [Item]): [OrderLine] => {
  orderLines = Array.isArray(orderLines) ? orderLines : [orderLines];
  items = Array.isArray(items) ? items : [items];

  const orderLinesMatchesItem = (orderLine, items) =>
    find(propEq('id', orderLine.itemId), items);

  const markDeletedItems = orderLine =>
    orderLinesMatchesItem(orderLine, items) ? { ...orderLine, _isDeleted: true } : orderLine;

  const orderLinesWithDeletedItems = map(markDeletedItems, orderLines);

  return orderLinesWithDeletedItems;
};

export const mapOrderLinesToOpenTables =
(orderLines: OrderLine[], allSubTables: SubTable[], allOpenTables: OpenTable[]): OpenTable[] => {
  const subTablesId = pluck('subTableId', orderLines);
  const matchingSubTables = filter(
    (subTable: SubTable) => contains(subTable.id, subTablesId),
    allSubTables
  );

  const matchingOpenTables = filter(
    (openTable: OpenTable) =>
      find((subTable: SubTable) =>
        openTable.id === subTable.openTableId, matchingSubTables) !== undefined,
    allOpenTables
  );

  return matchingOpenTables;
};

  // TODO add linter to disable mutable operations
  // TODO run linters after unit tests
/**
 * @memberof common/order/utils
 * @param stateOrderLines
 * @param id
 * @param parameters
 * @returns {*}
 */
export const updateOrderLineOnParameter = (stateOrderLines: OrderLine[], id: Id, parameters: Object): OrderLine => {
  // TODO make this generic, do not depend on orderlines (can by any array)
  const index = findIndex(propEq('id', id))(stateOrderLines);

  if (index === -1) return stateOrderLines;

  return update(
    index,
    { ...stateOrderLines[index], ...parameters },
    stateOrderLines
  );
};

/**
 * @memberof common/order/utils
 * @param stateOrderLines
 * @param ids
 * @returns {*}
 */
export const rejectMultipleOrderLines = (stateOrderLines: OrderLine[], ids: Id[]): OrderLine => {
  let orderLines = clone(stateOrderLines);
  // TODO 😲
  ids.map(id => orderLines = reject(propEq('id', id), orderLines));
  return orderLines;
};

// TODO perf
/**
 * @memberof common/order/utils
 * @param itemPrices
 * @param itemId
 * @param priceLevelId
 * @returns {number}
 */
export const computeItemPrice = (itemPrices: ItemPrice[], itemId: Id, priceLevelId: Id): number => {
  const foundPrice = itemPrices.filter(el => el.itemId === itemId && el.priceLevelId === priceLevelId);
  return foundPrice.length > 0 ? foundPrice[0].price : 0;
};

/**
 * @memberof common/order/utils
 * @param price
 */
export const formatPriceToFixed = (price: number): string =>
  (Math.round(price || 0) / 100).toFixed(2).replace(/\./g, ','); // TODO - delimeters and decimal with intl

/**
 * @memberof common/order/utils
 * @param price
 */
export const formatDirectPriceToFixed = (price: number = 0): string =>
  (+price).toFixed(2).replace(/\./g, ','); // TODO - delimeters and decimal with intl

/**
 * @memberof common/order/utils
 * @param quantity
 */
export const formatQuantity = (quantity: number): string =>
  (quantity || '').toString().replace(/\./g, ','); // TODO - delimeters and decimal with intl

/**
 * @memberof common/order/utils
 * @param quantity
 */
export const formatPortion = (portion: number): string =>
  portion && portion !== 1
    ? `1/${portion}`
    : '1';

/**
 * @memberof common/order/utils
 * @param price
 */
export const formatPriceNoIntl = (price: number): string =>
  (Math.round(price || 0) / 100).toString();

/**
 * @memberof common/order/utils
 * @param text
 * @param guest
 */
export const formatSubTableName = (text: string = '', guest: string = 'hosť') => {

 return text.split('.')[1]
   ? `${text.split('.')[1]}. ${guest}`
   : text;
};

/**
 * @memberof common/order/utils
 * @param subTables
 * @param activeOpenTableId
 * @param activeSubTableId
 * @param intl
 * @returns {number}
 */
export const activeSubTableName = (subTables: SubTable[], activeOpenTableId: Id, activeSubTableId: Id, intl): string => {
  const actualSubTables = subTables
    .filter(
      st => st.openTableId === activeOpenTableId
    );
  const subtableIndex = actualSubTables.map(el => (el.id)).indexOf(activeSubTableId);

  return subtableIndex < 0
    ? intl.formatMessage(commonMessages.allGuests)
    : formatSubTableName(actualSubTables[subtableIndex].name, intl.formatMessage(commonMessages.orderGuest));
};

/**
 * @memberof common/order/utils
 * @param activeOpenTableId
 * @param orderLines
 * @param subTables
 * @param flags
 */
export const confirmOrder = (activeOpenTableId: Id, orderLines: OrderLine[], subTables: SubTable[], flags = {}) => {
  const subTablesIds = pluck('id',
    filter(propEq('openTableId', activeOpenTableId), subTables)
  );
  const orderLinesToBeConfirmed = filter(
    orderLine => contains(orderLine.subTableId, subTablesIds)
      && !orderLine._isDeleted
      && !orderLine.isSentToKitchen,
    orderLines);

  return confirmOrderLine(orderLinesToBeConfirmed, flags);

  // TODO how to tell server opentable was touched?
  // dispatch(putOpenedTimeToOpenTable(activeOpenTableId));
  // maybe: server will touch opentables on orderlines change and later on sync we will get new time
};


export const extractItemUniqueOrderLines = (orderLines, selectedOrderLineIds) =>
  uniqWith(
    (a, b) => (a.foodCourse === b.foodCourse && a.itemId === b.itemId),
    filter(ol => ol !== undefined,
      map(solId => find(propEq('id', solId), orderLines), selectedOrderLineIds)
    )
);

const compareSubItemsOfFSTs = (a, b, orderLines) => {
        const sortFn = (a, b) => a - b;
        const subItemsA = sort(sortFn, pluck('itemId', filter(ol => ol.orderType === 11 && ol.linkedToId === a.id, orderLines)));
        const subItemsB = sort(sortFn, pluck('itemId', filter(ol => ol.orderType === 11 && ol.linkedToId === b.id, orderLines)));

        return equals(subItemsA, subItemsB);
      };

const compareFSTs = (a, b, orderLines) => // compare 2 FastFoodMenus
  (a.foodCourse === b.foodCourse && a.itemId === b.itemId)
    ? compareSubItemsOfFSTs(a, b, orderLines)
    : false;

export const extractTotalUniqueOrderLines = (orderLines, selectedOrderLineIds) =>
  uniqWith(
    (a, b) => (a.orderType === 10 && b.orderType === 10) // both are FastFoodMenus (spart ==== 'FST')
      ? compareFSTs(a, b, orderLines)
      : (
          a.foodCourse === b.foodCourse
          && a.itemId === b.itemId
          && a.subTableId === b.subTableId
          && a.priceLevelId === b.priceLevelId
          && a.finalPrice === b.finalPrice
          && a.portion === b.portion
          && (a.orderText || '') === (b.orderText || '')
          && a.orderType === b.orderType
        ),
    filter(ol => ol !== undefined,
      map(solId => find(propEq('id', solId), orderLines), selectedOrderLineIds)
    )
);

/**
 * @memberof common/order/utils
 * @param orderLines
 * @param uniqueOrderLine
 */
export const findAllIdenticOrderLines = (uniqueOrderLine, orderLines) =>
  (uniqueOrderLine && !isEmpty(uniqueOrderLine))
    ? filter(
        ol =>
          ol !== undefined
          && ol.foodCourse === uniqueOrderLine.foodCourse
          && ol.itemId === uniqueOrderLine.itemId
          && ol.subTableId === uniqueOrderLine.subTableId
          && ol.priceLevelId === uniqueOrderLine.priceLevelId
          && ol.finalPrice === uniqueOrderLine.finalPrice
          && ol.portion === uniqueOrderLine.portion
          && (ol.orderText || '') === (uniqueOrderLine.orderText || '')
          && ol.orderType === uniqueOrderLine.orderType,
        orderLines
      )
    : [];

/**
 * @memberof common/order/utils
 * @param lastDisplayValue
 * @param possibleToSelectCount
 * @param possibleToSelectOrderLines
 * @param uniqueOrderLine
 * @param orderLines
 */
export const selectOrderLinesMassively = (
  lastDisplayValue,
  possibleToSelectCount,
  possibleToSelectOrderLines,
  uniqueOrderLine,
  orderLines
) => {
  const count = Math.round(Number(lastDisplayValue) + Number.EPSILON);

  let selectedOrderLinesForMassSelection = count < possibleToSelectCount
    ? pluck('id', possibleToSelectOrderLines.slice(0, count))
    : pluck('id', possibleToSelectOrderLines);

  if (selectedOrderLinesForMassSelection.indexOf(uniqueOrderLine.id) === -1) {
    selectedOrderLinesForMassSelection = append(
      uniqueOrderLine.id,
      selectedOrderLinesForMassSelection.slice(0, selectedOrderLinesForMassSelection.length - 1)
    );
  }

  if (uniqueOrderLine.orderType === 10) {
    let subItems = [];
    map(
      solfd => {
        subItems = append(
          pluck('id',
            filter(
              ol => ol.orderType === 11 && ol.linkedToId === solfd,
              orderLines
            ),
          ),
          subItems
        );
      },
      selectedOrderLinesForMassSelection
    );

    selectedOrderLinesForMassSelection = flatten(
      append(
        subItems,
        selectedOrderLinesForMassSelection
      )
    );
  }

  return selectedOrderLinesForMassSelection;
};

/**
 * @memberof common/order/utils
 * @param parenOrderLine
 * @param orderLines
 * @param count
 */
const exportFSTParentWithSubItems = (
  parenOrderLine: OrderLine, orderLines: OrderLine[], count: number = 1
) => {
  const subItems = filter(ol =>
    ol && ol.orderType === 11 && ol.linkedToId === parenOrderLine.id, orderLines
  );

  const fullFastMenuItems = prepend(
    parenOrderLine,
    subItems
  );

  const preparedLinkedToId = Array(count).fill(1).map(() => uuid.v4());

  return flatten(
    map(
      uid => map(
        fmi => addOrderLine(
          {
            ...fmi,
            foodCourse: parenOrderLine.foodCourse,
            linkedToId: uid
          },
          { selectAfterAddition: true }
        ),
        fullFastMenuItems
      ),
      preparedLinkedToId
    )
  );
};

/**
 * @memberof common/order/utils
 * @param uniqueOrderLines
 * @param orderLines
 * @param count
 */
export const plusOrderLine = (
  uniqueOrderLines: OrderLine[], orderLines: OrderLine[], count: number = 1
) =>
  flatten(
    map(
      uniqueOrderLine =>
        repeat((uniqueOrderLine.orderType === 10)
          ? exportFSTParentWithSubItems(uniqueOrderLine, orderLines)
          : addOrderLine(
            uniqueOrderLine,
            { selectAfterAddition: true }
          ),
          count),
      uniqueOrderLines
    )
  );

/**
 * @memberof common/order/utils
 * @param isPriceOrQuantityFormOpen
 * @param activeFoodCourse
 * @param activeSubTableId
 * @param orderLines
 * @param items
 * @param itemPrices
 * @param lastDisplayValue
 * @param freshInsert
 * @param note
 * @param forceSelect
 */
export const constructCustomPriceOrQuantityOrderLines = params => {
  const {
    isPriceOrQuantityFormOpen: {
      type,
      uniqueOrderLine
    },
    activeFoodCourse,
    activeSubTableId,
    orderLines,
    items,
    itemPrices,
    lastDisplayValue,
    freshInsert,
    note = '',
    forceSelect = false
  } = params;

  if (!uniqueOrderLine) return [];

  const singlePrice = type === 'price'
    ? Math.round(Number(lastDisplayValue) * 100 + Number.EPSILON)
    : uniqueOrderLine.singlePrice || computeItemPrice(itemPrices, uniqueOrderLine.itemId, uniqueOrderLine.priceLevelId || 1);

  const foodCourse = activeFoodCourse
    || (path('foodCourse', uniqueOrderLine) ? uniqueOrderLine.foodCourse : 0);

  const count = type === 'price' ? (uniqueOrderLine.quantity || 1) : Math.round(Number(lastDisplayValue) + Number.EPSILON);

  const customGroupHash = count > 1 && uuid.v4();

  const orderLine = {
    ...uniqueOrderLine,
    singlePrice,
    subTableId: activeSubTableId,
    foodCourse,
    priceLevelId: type === 'price' ? 1 : uniqueOrderLine.priceLevelId, // priceLevel (set intently to 1 when 'price')
    quantity: type === 'price' ? 1 : uniqueOrderLine.quantity,
    portion: uniqueOrderLine.portion || 1,
    hasCustomPrice: type === 'price' ? true : uniqueOrderLine.hasCustomPrice,
    orderText: note,
    customGroupHash
  };

  return (type === 'quantity' && orderLine.orderType === 10)
    ? exportFSTParentWithSubItems(orderLine, orderLines, count)
    : repeat(
        addOrderLine(
          orderLine,
          { selectAfterAddition: forceSelect || (type === 'price' ? false : !freshInsert) }
        ),
        count
      );
};

/**
 * @memberof common/order/utils
 * @param uniqueOrderLines
 * @param items
 */
export const checkSelectForWeighted = (uniqueOrderLines: OrderLine[], items: Item[]) => {
  if (!uniqueOrderLines) return {};

  const matchingItems = map(ol => find(propEq('id', ol.itemId), items), uniqueOrderLines);
  const existsWeightedInMatchingItems = contains(
    true,
    uniq(
      pluck('isWeighted', matchingItems)
    )
  );

  const weightedItemId = uniqueOrderLines.length === 1 && existsWeightedInMatchingItems
    ? uniqueOrderLines[0].itemId
    : undefined;

  return {
    multipleSelectedInclWeighted: uniqueOrderLines.length > 1 && existsWeightedInMatchingItems,
    hasWeighted: existsWeightedInMatchingItems,
    weightedItemId
  };
};

/**
 * @memberof common/order/utils
 * @param uniqueOrderLines
 * @param items
 */
export const checkSelectForCustomPriced = (uniqueOrderLines: OrderLine[], items: Item[]) => {
  if (!uniqueOrderLines) return {};

  const matchingItems = map(ol => find(propEq('id', ol.itemId), items), uniqueOrderLines);

  const customPriceOfItems = uniq(
    pluck('hasCustomPrice', matchingItems)
  );

  const needsInvoiceNoSet = uniq(
    pluck('needsInvoiceNo', matchingItems)
  );

  return {
    multipleSelectedInclCustomPriced: uniqueOrderLines.length > 1 && contains(true, customPriceOfItems),
    needsInvoiceNo: contains(true, needsInvoiceNoSet),
  };
};

/**
 * @memberof common/order/utils
 * @param uniqueOrderLines
 * @param items
 */
export const checkSelectForFastFoodMenu = (uniqueOrderLines: OrderLine[], items: Item[], spartParam) => {
  if (!uniqueOrderLines) return false;

  uniqueOrderLines = filter(
    uol => uol.orderType !== 11,
    uniqueOrderLines
  );

  const matchingItems = map(ol => find(propEq('id', ol.itemId), items), uniqueOrderLines);
  const existsFastFoodMenuInMatchingItems = contains(
    'FST',
    uniq(
      pluck('spart', matchingItems)
    )
  ) || (spartParam && contains(
    spartParam,
    uniq(
      pluck('spart', matchingItems)
    )
  ));

  const fastFoodMenuItemId = uniqueOrderLines.length === 1 && existsFastFoodMenuInMatchingItems
    ? uniqueOrderLines[0].itemId
    : undefined;

  return {
    multipleSelectedInclFastFoodMenu: uniqueOrderLines.length > 1 && existsFastFoodMenuInMatchingItems,
    hasFastFoodMenu: existsFastFoodMenuInMatchingItems,
    fastFoodMenuItemId
  };
};

/**
 * @memberof common/order/utils
 * @param dispatch
 * @param orderLines
 * @param selectedOrderLines
 */
export const deleteAllSelectedOrderLines = (dispatch: Function, orderLines: OrderLine[], selectedOrderLines: Id[]) => {
  // TODO don't use dispatch, this is side-effect
  dispatch(deleteOrderLines(selectedOrderLines));
  dispatch(clearSelectedOrderLines());
};

/**
 * @memberof common/order/utils
 * @param orderLinesIds
 * @param allOrderLines
 */
export const mapOrderlinesIdToOrderlines = (orderLinesIds: Id[], allOrderLines: OrderLine[]) => {
  const matchingOrderlines = filter(
    (orderLine: OrderLine) => contains(orderLine.id, orderLinesIds),
    allOrderLines
  );

  return matchingOrderlines;
};

/**
 * @memberof common/order/utils
 * @param subTables
 * @param orderLines
 * @param activeOpenTableId
 * @param activeSubTableId
 */
export const getAllOrderLinesInFocus = (subTables: SubTable[], orderLines: OrderLine[], activeOpenTableId: Id[], activeSubTableId: Id[]) => {
  const allTableSubTables = subTables.filter(st => st.openTableId === activeOpenTableId);

  return orderLines.filter(
    ol => (activeSubTableId === 'all'
      ? filter(propEq('id', ol.subTableId))(allTableSubTables).length > 0
      : ol.subTableId === activeSubTableId)
  );
};

/**
 * @memberof common/order/utils
 * @param subTables
 * @param orderLines
 * @param activeOpenTableId
 */
export const getAllOrderLinesOfOpenTable = (subTables: SubTable[], orderLines: OrderLine[], activeOpenTableId: Id) => {
  const allTableSubTables = subTables.filter(st => st.openTableId === activeOpenTableId);

  return orderLines.filter(ol => filter(propEq('id', ol.subTableId))(allTableSubTables).length > 0);
};

export const getAllOrderLinesOfOpenTableWithLookUpId = (subTables: SubTable[], orderLines: OrderLine[], activeOpenTableId: Id) => {
  const allTableSubTables = subTables.filter(st => lookUpId(st.openTableId) === lookUpId(activeOpenTableId));
  return orderLines.filter(ol => allTableSubTables.find(st => lookUpId(st.id) === lookUpId(ol.subTableId)));
};

/**
 * @memberof common/order/utils
 * @param orderLine
 */
export const pickOrderLineApiData = (orderLine: OrderLine) => {
  const orderLineApiData = {
    orderText: orderLine.orderText,
    priceLevelId: orderLine.priceLevelId,
    foodCourse: orderLine.foodCourse,
    quantity: orderLine.quantity,
    portion: orderLine.portion,
    orderType: orderLine.orderType,
    clientLinkedToId: orderLine.linkedToId,
    createdAt: moment(orderLine.createdAt).toJSON(),
    updatedAt: moment(orderLine.updatedAt).toJSON(),
    customGroupHash: orderLine.customGroupHash
  };

  if (orderLine.hasCustomPrice) {
    orderLineApiData.customPrice = orderLine.singlePrice;
  }

  return orderLine._local
    ? orderLineApiData
    : pickAll(orderLine._dirty || [], orderLineApiData);
};

/**
 * @memberof common/order/utils
 * @param id,
 * @param note,
 * @param usedOldOrderText
 * @param orderLines,
 */
export const constructCombinedNote = (id: Id, note: string, usedOldOrderText: boolean, orderLines: OrderLine[]) => {
  const orderLine = find(propEq('id', id))(orderLines);
  if (!orderLine) return '';

  const oldNote = orderLine.orderText;
  return oldNote === '' || oldNote === undefined
    ? note
    : usedOldOrderText
      ? note
      : `${oldNote}\n---\n${note}`;
};

/**
 * @memberof common/order/utils
 * @param { singlePrice, quantity, itemId, portion },
 * @param parameters,
 */
export const calculateOrderLinePriceWithParams = (
  { _local, isSentToKitchen, finalPrice, singlePrice, quantity, itemId, portion }: OrderLine,
  parameters: Parameter[]
) => {
  if (!_local && isSentToKitchen) return finalPrice;

  const paramKoefC = prepend(1, map(i => findParamValue(`K32.koef_c_${i}`, parameters) || 1, [2, 3, 4]));

  return Math.round(
    singlePrice
    * ((quantity === 1 && portion === 1)
      ? 1
      : portion !== 1
        ? paramKoefC[portion - 1]
        : quantity / 1000)
    + Number.EPSILON
  );
};

/**
 * @memberof common/order/utils
 * @param orderLines
 * @param selectedOrderLines
 * @param allParameters
 * @returns {number}
 */
export const selectedOrderLinesSum =
(orderLines: OrderLine[], selectedOrderLines: Id[], allParameters: AllParameters): number =>
  sum(
    map(sol => {
        const fullOrderLine = find(propEq('id', sol))(orderLines);
        if (!fullOrderLine) return 0;
        return (fullOrderLine.quantity === 1 && fullOrderLine.portion === 1)
          ? fullOrderLine.singlePrice
          : calculateOrderLinePriceWithParams(fullOrderLine, allParameters.parameters) || 0;
      },
      selectedOrderLines)
  );

/**
 * @memberof common/order/utils
 * @param selectedOrderLines,
 * @param orderLines,
 */
export const isConfirmedAmongSelected = (selectedOrderLines: number[], orderLines: OrderLine[]) =>
  contains(true,
    pluck('isSentToKitchen',
      map(sol => find(propEq('id', sol), orderLines) || { isSentToKitchen: false }, selectedOrderLines)
    )
  );

// TODO potrebovali by sme zachytavat errory, ktore mozu nastat v processError lol
// alebo len zjednodusit tuto fciu
/**
 * @memberof common/order/utils
 * @param error,
 * @param orderLines,
 * @param items,
 */
export const processError = (error: ApiError, orderLines: OrderLine[], items: Item[]) => {
  if (!error) return false;

  if (typeof error === 'string') {
    return [error];
  }

  if (typeof error.message === 'string') {
    return [error.message];
  }

  if (error.message === undefined) {
    return ['Unknown error'];
  }

  return map(
    er => `(${er.length}x) ${er[0]}`,
    Object.values(
      groupBy(el => el,
        path(['body', 'result', 'pos_error'], error)
          ? (error.body.result.pos_error)
              .map(er => er.orderLineId ? er.orderLineId : er.clientId)
              .map(id => find(propEq('id', id), orderLines))
              .map(orderLine => find(propEq('id', orderLine.itemId), items))
              .map((item, index) =>
                `${item.receiptName}: ${error.message[index]}`
          )
          : path(['status'], error)
            ? (error.status).map(
                (st, index) => error.message[index]
              )
            : [error]
      )
    )
  );
};

export const haveOrderLinesDefaultPriceLevel = (orderLines: OrderLine[],
  defaultPriceLevel: Id, prgDotazes: PrgDotaz[] = []
) => {
  if (prgDotazes.length) {
    const prgDotazNames = map(prgDotaz => prgDotaz[0], prgDotazes);
    if (contains('dotaz_st', prgDotazNames) || contains('dotaz_re', prgDotazNames)) {
      return false;
    }
  }

  return all(propEq('priceLevelId', defaultPriceLevel), orderLines);
};

export const isFiltering = (filter: string, filterThreshold: number = 3) => {
  if (!filter) return false;

  return filter.length >= filterThreshold;
};

export const confirmUpdatedOrderLines = (flags, changesProps, ...extra) =>
  ({ payload: { body: { result: { orderLinesSaved = [] }, diff = [] } } }) => {
    const mapLocalId = filter(prop('clientId'), orderLinesSaved);

    const orderLines = map(({ update, insert }) =>
        update.OrderLine ? update.OrderLine[0] : insert.OrderLine[0],
      diff
    );
    const orderLinesId = map(prop('id'), orderLines);

    return [
      addLocalId(mergeAll(
        map(({ clientId, orderLineId }) => ({ [clientId]: orderLineId }), mapLocalId)
      ), 'orderLineId'),
      confirmOrderLineCommit(orderLinesId, orderLines, changesProps, flags, extra)
    ];
  };

export const commonPriceLevelNameForOrderLines = (orderLines: OrderLine[]) => {
  if (!orderLines.length) return null;

  const firstPriceLevelName = orderLines[0].priceLevelName;

  const allSame = all(propEq('priceLevelName', firstPriceLevelName), orderLines);

  return allSame ? firstPriceLevelName : null;
};

export const commonDisplayQuantityForOrderLines = (orderLines: OrderLine[]) => {
  if (!orderLines.length) return null;

  const firstQuantity = (orderLines[0].quantity !== 1 || orderLines[0].displayQuantity !== 1) && orderLines[0].displayQuantity;

  const allSame = all(({ quantity, displayQuantity }) =>
    ((quantity !== 1 || displayQuantity !== 1) && displayQuantity) === firstQuantity
  , orderLines);

  return allSame ? firstQuantity : null;
};

/**
 * @memberof common/order/utils
 * @param uniqueOrderLines
 * @param itemsAvailability
 * @param itemsAvailabilityDiff
 * @param orderLines
 * @param fillDispatchLoadFn
 */
export const checkAvailabilityOfMultipleItems = (
  uniqueOrderLines,
  itemsAvailability,
  itemsAvailabilityDiff,
  orderLines,
  fillDispatchLoadFn
): number => {
    let availableOrderLines = [];
    let notAvailableOrderLines = [];

    map(
      uniqueOrderLine => {
        const availability = getItemAvailability(uniqueOrderLine.itemId, itemsAvailability, itemsAvailabilityDiff);
        const chosenCount = 1;

        if (chosenCount > availability) {
          notAvailableOrderLines = append({ ...uniqueOrderLine, quantity: 1 }, notAvailableOrderLines);
        } else {
          availableOrderLines = append(uniqueOrderLine, availableOrderLines);
        }
      },
      uniqueOrderLines
    );

    let dispatchLoad = [];

    if (!isEmpty(availableOrderLines)) {
      dispatchLoad = append(
        plusOrderLine(availableOrderLines, orderLines),
        dispatchLoad
      );
    }

    if (!isEmpty(notAvailableOrderLines)) {
      dispatchLoad = append(
        reduce(
          fillDispatchLoadFn,
          null,
          notAvailableOrderLines
        ),
        dispatchLoad
      );
    }

    return dispatchLoad;
  };

export const getPriceLevelName = (priceLevelId, priceLevels) => {
  const priceLevel = find(propEq('id', priceLevelId), priceLevels);
  return priceLevel ? priceLevel.name : '';
};

export const quantityAsIntegerElseOne = (receivedQuantity) => receivedQuantity
  ? (+receivedQuantity) % 1 === 0
    ? (+receivedQuantity)
    : 1
  : 1;

/**
 * @memberof common/order/utils
 * @param orderLine,
 * @param paramValueForIsWeighted
 */
export const checkOrderLineIfWeighted = (orderLine, paramValueForIsWeighted) =>
  isSpartIncludedInParams(paramValueForIsWeighted, orderLine.spart)
    ? { ...orderLine, isWeighted: true }
    : orderLine;

/**
 * @memberof common/order/utils
 * @param orderLine,
 * @param paramValueForFiskalSpPohladavka
 */
export const checkOrderLineIfFiskalSpPohladavka = (orderLine, paramValueForFiskalSpPohladavka) =>
  isSpartIncludedInParams(paramValueForFiskalSpPohladavka, orderLine.spart)
    ? { ...orderLine, hasCustomPrice: true, needsInvoiceNo: true }
    : orderLine;

/**
 * @memberof common/order/utils
 * @param parameters,
 * @param paymentTypes
 * @param paymentMedia
 */
export const getQuickPayments = (parameters, paymentTypes, paymentMedia) => [1, 2, 3]
    .map(number => findParamValue(`K32.objednavka_tlacitko${number}`, parameters))
    .map(param => param ? find(propEq('nazev', param), paymentTypes) : undefined)
    .filter(paymentType => paymentType !== undefined)
    .filter(paymentType => find(propEq('druhyPlId', paymentType.idriadok), paymentMedia))
    .map(paymentType => ({ ...paymentType, ...find(propEq('druhyPlId', paymentType.idriadok), paymentMedia) }));

/**
 * @memberof common/order/utils
 * @param orderLines,
 * @param items
 */
export const allMatchingItemsExist = (orderLines, items) => {
  const nonExistingItems = filter(
    el => !find(propEq('id', el.itemId), items),
    orderLines
  );

  return nonExistingItems.length <= 0;
};

/**
 * @memberof common/order/utils
 * @param orderLines,
 * @param stateSubTables
 * @param stateOpenTables
 */
export const addTablesDefinitionsToOrderLines = (orderLines, stateSubTables, stateOpenTables) => (
    map((orderLine) => {
      const { openTableId } = stateSubTables && stateSubTables.find(t => lookUpId(t.id) === lookUpId(orderLine.subTableId)) || {};
      const { serviceAreaId, tableDefinitionId } = stateOpenTables.find(t => lookUpId(t.id) === lookUpId(openTableId)) || {};

      return {
        ...orderLine,
        openTableId: lookUpId(openTableId),
        serviceAreaId,
        tableDefinitionId: lookUpId(tableDefinitionId)
      };
    }, orderLines)
);

export const isInOrderLines = (id, orderLines) => {
  if (id && orderLines) {
    return !!orderLines.find(orderLine => orderLine.id === id);
  }

  return false;
};

export const isValidTableDefinitionId = (id, tableDefinitions) => {
  if (!id) {
    return true;
  }

  if (tableDefinitions) {
    return !!tableDefinitions.find(tableDefinition => lookUpId(tableDefinition.id) === lookUpId(id));
  }

  return false;
};

export const isValidOpenTableId = (id, openTables) => {
  if (!id) {
    return true;
  }

  if (openTables) {
    return !!openTables.find(openTable => lookUpId(openTable.id) === lookUpId(id));
  }

  return false;
};

export const isValidSubTableId = (id, subTables) => {
  if (!id) {
    return true;
  }

  if (subTables) {
    return !!subTables.find(subTable => lookUpId(subTable.id) === lookUpId(id));
  }

  return false;
};

export const isHashValue = value => value && `${value}`.includes('-');
