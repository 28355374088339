// @flow
import InactiveBg from '../../../components/InactiveBg';
import Popup from '../../../components/Popup';
import React from 'react';
import TimePicker from 'rc-time-picker';
import Heading from '../../../../common/components/Heading';
import Button from '../../../../common/components/Button';
import Box from '../../../../common/components/Box';
import moment from 'moment';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/fiscal';

class PopupTime extends React.PureComponent {
  selectedTime = this.props.time || moment();

  onTimeChange = (time) => {
    this.selectedTime = time;
  };

  onSubmit = () => {
    const { onSubmit } = this.props;

    onSubmit(this.selectedTime.format('LT'));
    this.onClose();
  };

  onClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  render() {
    const { time, intl } = this.props;

    return (
      <InactiveBg zIndex={3000} onClose={this.onClose}>
        <Popup onClose={this.onClose}>
          <Heading scale={2} color="white">{intl.formatMessage(messages.fiscalModuleDeadline)}</Heading>

          <Box alignItems="center">
            <Box flexDirection="row" width="58%" height={14}>
              <TimePicker
                value={time}
                open
                inputReadOnly
                onChange={this.onTimeChange}
                allowEmpty={false}
                showSecond={false}
              />
            </Box>

            <Button
              outline
              color="teal"
              backgroundColor="white"
              padding={0.5}
              bold
              marginTop={2}
              onPress={this.onSubmit}
            >
              {intl.formatMessage(messages.fiscalModuleConfirmTime)}
            </Button>
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(PopupTime);
