// @flow
import type { Action, ServiceAreasState } from '../types';
import invariant from 'invariant';
import { isReactNative } from '../app/detectPlatform';
import { clearFsStore } from '../lib/utils';
import { assocPath } from 'ramda';

const initialState = {
  serviceAreas: [],
  activeAreaId: null,
  activeIsLimit: false,
  showTablesList: false,
  scale: null,
  isOneTimeTableNamePopupOpen: false,
  selectedPrinterId: null
};

const reducer = (state: ServiceAreasState = initialState, action: Action): ServiceAreasState => {
  switch (action.type) {
    case 'REHYDRATE_FS_COMMIT': {
      return {
        ...state,
        ...action.payload.serviceAreas
      };
    }
    case 'SYNC_SERVER_DATA_COMMIT': {
      const { payload: { ServiceAreaDefinition: serviceAreas } } = action;

      invariant(Array.isArray(serviceAreas), 'serviceAreas has to be an array');

      const firstFoundArea = serviceAreas.length ? serviceAreas[0] : null;
      const firstFoundId = firstFoundArea ? firstFoundArea.id : null;
      const activeAreaId =
        (state.activeAreaId && serviceAreas.find(area => area.id === state.activeAreaId))
          ? state.activeAreaId
          : firstFoundId;

      if (isReactNative) {
        const Store = require('react-native-fs-store').default;

        clearFsStore('serviceAreas').then(() => {
          new Store('serviceAreas').setItem('serviceAreas', {
            serviceAreas,
            activeAreaId,
            activeIsLimit: firstFoundArea.isLimit
          });
        });
      }

      return { ...state, serviceAreas, activeAreaId, activeIsLimit: firstFoundArea && firstFoundArea.isLimit };
    }

    case 'SERVICE_AREAS_ACTIVATE': {
      const { payload: { id } } = action;
      return { ...state, activeAreaId: id };
    }

    case 'SERVICE_AREAS_SET_IS_LIMIT': {
      const { payload: { is } } = action;
      return { ...state, activeIsLimit: is };
    }

    case 'SERVICE_AREAS_TOGGLE_TABLE_LIST': {
      const { payload: toggle } = action;
      return { ...state, showTablesList: !!toggle };
    }

    case 'SERVICE_AREAS_SAVE_SCALE_FACTOR': {
      const { scale } = action.payload;
      return { ...state, scale };
    }

    case 'SERVICE_TOGGLE_ONETIME_TABLE_NAME_POPUP': {
      const { payload: { toggle } } = action;
      return { ...state, isOneTimeTableNamePopupOpen: toggle };
    }

    case 'SERVICE_SELECT_PRINTER': {
      const { printerId = null } = action.payload;

      if (isReactNative) {
        const Store = require('react-native-fs-store').default;

        clearFsStore('selectedPrinterId').then(() => {
          if (printerId) {
            new Store('selectedPrinterId').setItem('selectedPrinterId', {
              printerId
            });
          }
        });
      }

      return assocPath(['selectedPrinterId'], printerId, state);
    }

    default:
      return state;
  }
};

export default reducer;
