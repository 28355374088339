// @flow
import React from 'react';
import Box from '../../common/components/Box';

type GridItemProps = {
  columnStart: number | string,
  columnEnd?: number | string,
  rowStart: number | string,
  rowEnd?: number | string,
  area?: string,
  justifySelf?: 'start' | 'end' | 'center' | 'stretch',
  alignSelf?: 'start' | 'end' | 'center' | 'stretch',
  style?: any
};

const GridItem = ({
  columnStart,
  columnEnd = 'auto',
  rowStart,
  rowEnd = 'auto',
  area,
  justifySelf,
  alignSelf,
  style = {},
  children,
  ...restProps
}: GridItemProps) => (
  <Box
    style={theme => ({
      gridColumn: `${columnStart} / ${columnEnd}`,
      gridRow: `${rowStart} / ${rowEnd}`,
      alignSelf,
      justifySelf,
      ...(area && { gridArea: area }),
      ...(typeof style === 'function' ? style(theme) : style)
    })}
    {...restProps}
  >
    {children}
  </Box>
);

export default GridItem;
