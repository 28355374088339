// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, filter, pathOr, map, find, propEq } from 'rambda';
import { groupBy } from 'ramda';
import Box from '../../common/components/Box';
import Icon from '../../common/components/Icon';
import Button from '../../common/components/Button';
import Radio from '../../common/components/Radio';
import Text from '../../common/components/Text';
import Popup from '../components/Popup';
import Heading from '../../common/components/Heading';
import { togglePrepaidItemsPopup, updatePaymentTransaction } from '../../common/payment/actions';
import type { State } from '../../common/types';
import Divider from '../../common/components/Divider';
import InactiveBg from '../components/InactiveBg';
import api from '../../common/lib/api';
import { processReceivedPaymentTransactionData } from '../../common/payment/utils';
import ScrollView from '../../common/components/ScrollView';
import moment from 'moment';
import {injectIntl} from 'react-intl';
import paymentMessages from '../../common/messages/payment';

class ChoosePrepaidItemsPopup extends React.PureComponent {
  constructor(props) {
    super(props);

    const { loyalman, intl } = props;

    const prepaidProductsPurchase = pathOr([], 'prepaid_product_purchase', loyalman);
    const prepaidProductsWithdrawal = pathOr([], 'prepaid_product_withdrawal', loyalman);

    const groupByItemId = groupBy(el => el.itemId);

    this.groupedWithdrawalItems = Object.values(
      filter(el => el.length > 0, groupByItemId(prepaidProductsWithdrawal))
    );

    this.groupedPurchaseItems = Object.values(
      filter(el => el.length > 1, groupByItemId(prepaidProductsPurchase))
    );

    this.allGroupedPrepaidItems = this.groupedWithdrawalItems.concat(this.groupedPurchaseItems);

    this.state = {
      numberOfGroups: this.allGroupedPrepaidItems.length,
      numberOfWithdrawalGroups: this.groupedWithdrawalItems.length,
      activeGroup: 0,
      titleWithdrawal: `${intl.formatMessage(paymentMessages.paymentChooseWallet)}:`,
      titlePurchase: `${intl.formatMessage(paymentMessages.paymentChoosePackage)}:`,
    };

    this.chosenPrepaidItems = Array(this.state.numberOfGroups);
  }

  componentWillMount() {
    const { dispatch } = this.props;
    const { numberOfGroups } = this.state;

    if (numberOfGroups < 1) {
      dispatch(togglePrepaidItemsPopup(false));
    }
  }

  _addSubMenuItem = (group, subMenuGroupIndex) => {
    const { dispatch, paymentTransactionId, loyalman: { cardCode }, selectedOrderLines, orderLines } = this.props;
    const { numberOfGroups, numberOfWithdrawalGroups, activeGroup } = this.state;

    const chosenItem = filter(
      fol => fol.cKarty === this.allGroupedPrepaidItems[group][0].itemId,
      map(
        sol => find(propEq('id', sol), orderLines),
        selectedOrderLines
      )
    );

    const numberOfItems = chosenItem.length || 1;

    this.chosenPrepaidItems[group] = { ...this.allGroupedPrepaidItems[group][subMenuGroupIndex], count: numberOfItems, subMenuGroupIndex };

    if (activeGroup + 1 < numberOfGroups) {
      this.setState({ activeGroup: activeGroup + 1 });
    } else {
      dispatch(togglePrepaidItemsPopup(false));

      api.payment.addPredefinedItemsDiscount(
        paymentTransactionId,
        cardCode,
        this.chosenPrepaidItems.slice(0, numberOfWithdrawalGroups),
        this.chosenPrepaidItems.slice(numberOfWithdrawalGroups, numberOfGroups)
      )
        .then(({ body }) => {
          dispatch(updatePaymentTransaction(processReceivedPaymentTransactionData(body, cardCode, true)));
        })
        .catch(e => {
          console.log('some catch took place at card reading: ', e);
        });
    }
  };

  _backInMenu() {
    const { activeGroup } = this.state;
    if (activeGroup > 0) {
      this.setState({ activeGroup: activeGroup - 1 });
    }
  }

  _closePopup = () => {
    const { dispatch } = this.props;

    dispatch(togglePrepaidItemsPopup(false));
  };

  render() {
    const { numberOfGroups, activeGroup, titlePurchase, titleWithdrawal } = this.state;
    const { intl } = this.props;
    return (
      <InactiveBg onClose={this._closePopup}>
        <Popup
          showClose
          backgroundColor="popupBg"
          color="white"
          onClose={this._closePopup}
          onCloseButtonShadow="none"
        >

          {this.allGroupedPrepaidItems[activeGroup]
            ? <Text scale={1} color="white" width={15} marginBottom={0.5}>
                {this.allGroupedPrepaidItems[activeGroup][0].userWalletId ? titleWithdrawal : titlePurchase}
              </Text>
            : null}

          {this.allGroupedPrepaidItems[activeGroup]
            ? <Heading color="white" scale={2} marginBottom={1}>
                {this.allGroupedPrepaidItems[activeGroup][0].itemName}
              </Heading>
            : null}


          <ScrollView maxHeight="60vh">
            {this.allGroupedPrepaidItems[activeGroup]
              ? this.allGroupedPrepaidItems[activeGroup].map((element, index) => (
                <Button
                  key={index}
                  backgroundColor="appBg"
                  outline
                  color="white"
                  marginTop={index === 0 ? 0 : 0.75}
                  width={20}
                  paddingVertical={0.5}
                  paddingHorizontal={0.5}
                  onPress={() => this._addSubMenuItem(activeGroup, index)}
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  disabled={!element.packageId}
                >
                  <Radio
                    size={1}
                    color="white"
                    left={0.75}
                    top="calc(50% - 9px)"
                    position="absolute"
                    backgroundColor="appBg"
                    checked={this.chosenPrepaidItems[activeGroup] && this.chosenPrepaidItems[activeGroup].subMenuGroupIndex === index}
                  />
                  <Box marginLeft={2} flexDirection="column">
                    <Text color="white" bold>
                      {element.packageName || intl.formatMessage(paymentMessages.paymentItemWithoutName)}
                    </Text>
                    {this.allGroupedPrepaidItems[activeGroup][0].userWalletId
                      ? <Box>
                          <Text color="white" bold>
                            {`${intl.formatMessage(paymentMessages.paymentAccountStateCredit)}: ${element.balance || '-'}`}
                          </Text>
                          <Text color="white" bold>
                            {`${intl.formatMessage(paymentMessages.paymentExpiration)}: ${moment(element.expiration).format('L') || '-'}`}
                          </Text>
                        </Box>
                      : <Box>
                          <Text color="white" bold>
                            {`${intl.formatMessage(paymentMessages.paymentAccountStateCredit)}: ${element.credits || '-'}`}
                          </Text>
                          <Text color="white" bold>
                            {`${intl.formatMessage(paymentMessages.paymentExpirationDays)}: ${element.expireInDays || '-'}`}
                          </Text>
                        </Box>}
                  </Box>
                </Button>))
              : <Text color="white">
                  {intl.formatMessage(paymentMessages.paymentNoItems)}
                </Text>
            }
          </ScrollView>

          <Box
            width="100%"
            height={3.5}
            backgroundColor="popupBg"
            justifyContent="center"
            alignItems="flex-end"
            borderBottomLeftRadius="normal"
            borderBottomRightRadius="normal"
            paddingTop={1}
            flexShrink={0}
          >
            <Divider color="white" width="100%" style={{ opacity: 0.2 }} marginBottom={0.5} flexShrink={0} />
            {activeGroup
              ? <Box width="100%" flexDirection="row" justifyContent="space-between" alignItems="center" flexShrink={0}>
                <Button
                  width={2}
                  height={2}
                  alignItems="center"
                  justifyContent="center"
                  flexShrink={0}
                  backgroundColor="white"
                  style={theme => ({ borderRadius: theme.typography.lineHeight() })}
                  onPress={() => this._backInMenu()}
                >
                  <Icon name={'arrow-up'} color="popupBg" scale={2} style={{ transform: 'rotate(-90deg)' }} />
                </Button>
                <Text scale={3} color="white">{`${activeGroup + 1}/${numberOfGroups}`}</Text>
              </Box>
              : <Text scale={3} color="white" flexShrink={0}>{`${activeGroup + 1}/${numberOfGroups}`}</Text>}
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    loyalman: state.payment.loyalman,
    paymentTransactionId: state.payment.paymentTransactionId,
    selectedOrderLines: state.orders.selectedOrderLines,
    orderLines: state.orders.orderLines
  })),
  injectIntl
)(ChoosePrepaidItemsPopup);
