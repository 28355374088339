// @flow

// inlehmansterms.net/2014/06/09/groove-to-a-vertical-rhythm
// 24ways.org/2011/composing-the-new-canon
// modularscale.com
// type-scale.com

import { isReactNative } from '../app/detectPlatform';

export const defaultFontFamily = isReactNative
  ? 'Roboto-Regular'
  : [
    'Roboto', // TODO match RN and browser font names
    'sans-serif',
  ].join(', ');

export const monospaceFamily = isReactNative
  ? 'Inconsolata-Regular'
  : [
    'Inconsolata',
    'monospace'
  ].join(', ');

export const fontFamilyName = ({ bold, condensed, thin, family = defaultFontFamily }) => {
  if (family.indexOf('Roboto') !== -1) {
    if (bold) {
      if (condensed) {
        return isReactNative ? 'Roboto-BoldCondensed' : 'RobotoBoldCondensed, Roboto, sans-serif';
      }

      return isReactNative ? 'Roboto-Bold' : 'RobotoBold, Roboto, sans-serif';
    }

    if (condensed) {
      return isReactNative ? 'Roboto-Condensed' : 'RobotoCondensed, Roboto, sans-serif';
    }

    if (thin) {
      return isReactNative ? 'Roboto-Thin' : 'RobotoThin, Roboto, sans-serif';
    }
  } else if (family.indexOf('Inconsolata') !== -1) {
    if (bold) {
      return isReactNative ? 'Inconsolata-Bold' : 'InconsolataBold, Inconsolata, monospace';
    }

    return isReactNative ? 'Inconsolata-Regular' : 'Inconsolata, monospace';
  }

  return family;
};

const typography = (
  {
    lineHeights,
    fontSizes,
    letterSpacings
  }
) => ({
  fontSize: (scale: number = 0, font = 'Roboto') =>
    font.includes('Inconsolata') ? fontSizes.monospace[scale] : fontSizes.default[scale],

  lineHeight: (scale: number = 0, font = 'Roboto') =>
    font.includes('Inconsolata') ? lineHeights.monospace[scale] : lineHeights.default[scale],

  letterSpacing: (scale: number = 0, font = 'Roboto') =>
    font.includes('Roboto') && !font.includes('Bold')
      ? letterSpacings.default[scale]
      : undefined,
});

export default typography;
