// @flow
import * as actions from './actions';
import { find, propEq, filter, pluck, contains, reduce, range } from 'rambda';
import { isEmpty, difference, max } from 'ramda';
import type { OpenTable, State } from '../types';
import { findUsedOpenTables, generateOpenTableName } from './utils';
import { getAllOrderLinesOfOpenTable } from '../order/utils';

export const addLocalSubTableAfterLocalOpenTableIsAdded = (action$, deps) =>
  action$
    .ofType('OPEN_TABLE_ADD')
    .map(({ payload: { openTable, flags } }) =>
      actions.addSubTableLocal(openTable.id, flags)(deps)
    );

export const activateOpenTableAfterItIsAdded = (action$) =>
  action$
    .ofType('OPEN_TABLE_ADD')
    .filter(({ payload: { flags = {} } }) => !!flags.activate)
    .map(({ payload: { openTable } }) =>
      actions.activateOpenTable(openTable.id)
    );

export const deleteOpenTableAfterLastSubtableRollback = (action$, { getState }) =>
  action$
    .ofType('SUB_TABLE_ADD_ROLLBACK')
    .map(({ payload: { id } }) => {
      const state: State = getState();
      const subTable = find(propEq('id', id), state.tables.subTables);

      if (!subTable) return [];

      const subTables = filter(propEq('openTableId', subTable.openTableId), state.tables.subTables);

      if (subTables.length <= 1) {
        return actions.deleteOpenTable(subTable.openTableId);
      }

      return [];
    });

export const activateSubTableAfterOpenTableIsActivated = (action$) =>
  action$
    .ofType('OPEN_TABLE_ACTIVATE')
    .map(({ payload: { flags = {} } }) => {
      if (flags.activateSubTable) {
        return actions.activateSubTable(flags.activateSubTable);
      }

      return actions.activateSubTable('all');
    });

export const rewriteActiveSubTableAfterSubTableUpdate = action$ =>
  action$
    .ofType('SUB_TABLE_UPDATE_COMMIT', 'SUB_TABLE_ADD_COMMIT')
    .filter(({ payload: { flags = {} } }) => flags.activateSubTable)
    .map(({ payload: { subTable } }) =>
      actions.activateSubTable(subTable.id));

export const addLocalOpenTableAfterTableDefinitionIsAdded = (action$, { getState }) =>
  action$
    .ofType('TABLE_DEFINITION_ADD')
    .map(({ payload }) => {
      const { tableDefinition, flags = {} } = payload;

      if (!flags.willOpen) return [];

      const state: State = getState();

      return actions.addOpenTableLocal({
        tableDefinitionId: tableDefinition.id,
        serviceAreaId: tableDefinition.serviceAreaId,
        name: generateOpenTableName(tableDefinition, state.tables.openTables),
        ...flags.openTableAttr
      }, flags);
    });

export const commitOpenTableAfterTableDefinitionCommit = (action$, { getState }) =>
  action$
    .ofType('TABLE_DEFINITION_UPDATE_COMMIT')
    .map(({ payload }) => {
      const { tableDefinition, flags = {} } = payload;

      if (!flags.willOpen) return [];

      const state: State = getState();
      const { openTables } = state.tables;

      const usedOpenTables = findUsedOpenTables(tableDefinition.id, openTables);
      const openTable = find(openTable => openTable._local, usedOpenTables);

      if (!openTable) return [];

      return actions.addOpenTableRemote(openTable.id, openTable, flags);
    });

// TODO check: asi je opentable uz je na serveri zmazany, zmaz len u seba lokalne opentables
// export const deleteOpenTableAfterTableDefintionIsDeleted = (action$, { getState }) =>
//   action$
//     .ofType('TABLE_DEFINITION_DELETE_COMMIT')
//     .map(({ payload: { id } }) => {
//       const state: State = getState();
//       const openTables = filter(propEq('tableDefinitionId', id), state.tables.openTables);
//       const openTablesId = pluck('id', openTables);
//
//       return openTablesId.map(actions.deleteOpenTable);
//     });

export const activateFoodCourseEpic = (action$: any) =>
  action$
    .ofType('FOOD_COURSE_ADD')
    .map(({ payload: { foodCourse: { id } } }) =>
      actions.activateFoodCourse(id)
    );

export const deleteSubtablesAfterOpenTableIsDeleted = (action$: any) =>
  action$
    .ofType('OPEN_TABLE_DELETE_COMMIT')
    .filter(({ payload: { willDeleteSubTablesId } }) => Array.isArray(willDeleteSubTablesId))
    .map(({ payload: { willDeleteSubTablesId } }) =>
      willDeleteSubTablesId.map(actions.deleteSubTableCommit)
    );

export const deleteTableDefinitionAfterOpenTableIsDeleted = (action$: any) =>
  action$
    .ofType('OPEN_TABLE_DELETE_COMMIT')
    .filter(({ payload: { willDeleteTableDefinitionId } }) => !!willDeleteTableDefinitionId)
    .map(({ payload: { willDeleteTableDefinitionId } }) =>
      actions.deleteTableDefinition(willDeleteTableDefinitionId)
    );

export const deleteOpenTableAfterPaymentWhenOrderIsEmpty = (action$, { getState }) =>
  action$
    .ofType('ORDER_LINES_DELETE_COMMIT')
    // .ofType('ORDER_LINES_CONFIRM_COMMIT', 'ORDER_LINES_DELETE_COMMIT')
    // .filter(({ payload: { flags = {} }, type }) => type === 'ORDER_LINES_DELETE_COMMIT'
    //   ? flags.deleteOpenTableId
    //   : true)
    .filter(({ payload: { flags = {} } }) => flags.deleteOpenTableId)
    .map(({ payload: { flags: { deleteOpenTableId } } }) => {
      const state: State = getState();
      const openTables = state.tables.openTables;
      const subTables = state.tables.subTables;
      const orderLines = state.orders.orderLines;
      const serviceAreas = state.serviceAreas.serviceAreas;

      const openTable: OpenTable = find(propEq('id', deleteOpenTableId), openTables);
      if (!openTable) return [];

      const area = find(propEq('id', openTable.serviceAreaId), serviceAreas);
      if (!area) return [];

      if (isEmpty(getAllOrderLinesOfOpenTable(subTables, orderLines, deleteOpenTableId))) {
        if (area.isVirtual) {
          return actions.deleteOpenTable(deleteOpenTableId, openTable.tableDefinitionId);
        }

        return actions.deleteOpenTable(deleteOpenTableId);
      }

      return [];
    });

export const addFoodCoursesToOpenTableAfterRelocatingOrderlineToSubtable =
(action$: any, { getState }) =>
  action$
    .ofType('ORDER_LINE_RELOCATE_TO_SUB_TABLE_COMMIT')
    .map(({ payload: { orderLineIds, subTableId } }) => {
      const {
        tables: { subTables, foodCourses },
        orders: { orderLines }
      } = getState();

      const subTable = find(propEq('id', subTableId), subTables);

      if (!subTable) return [];

      const openTableFoodCourses = pluck('id',
        filter(propEq('openTableId', subTable.openTableId), foodCourses)
      );

      const matchingOrderLines = filter(
        orderLine => contains(orderLine.id, orderLineIds),
        orderLines
      );
      const matchingOrderLinesFoodCourses = pluck('foodCourse', matchingOrderLines);

      const maxFoodCourse = reduce(max, -1, matchingOrderLinesFoodCourses);

      if (maxFoodCourse < 1) return [];

      const movedFoodCourses = range(1, maxFoodCourse + 1);
      const newFoodCourses = difference(movedFoodCourses, openTableFoodCourses);

      return actions.injectFoodCourses(newFoodCourses, subTable.openTableId);
    });
