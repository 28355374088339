// @flow
import type { TableDefinition } from '../types';
import { reduce, map, pluck } from 'rambda';
import { maxBy, minBy, zipWith } from 'ramda';

export const scaleTables =
(tables: TableDefinition[], canvasWidth, canvasHeight, minTableSize?: number) => {
  const tablesGeometry = pluck('geometry', tables);
  const minTableXVal = reduce(minBy(table => table.x), { x: Infinity }, tablesGeometry).x;
  const maxTableX = reduce(maxBy(table => table.x + table.width), { x: -Infinity, width: 0 },
    tablesGeometry);
  const maxTableXVal = maxTableX.x + maxTableX.width;

  const minTableYVal = reduce(minBy(table => table.y), { y: Infinity }, tablesGeometry).y;
  const maxTableY = reduce(maxBy(table => table.y + table.height), { y: -Infinity, height: 0 },
    tablesGeometry);
  const maxTableYVal = maxTableY.y + maxTableY.height;

  let factorX = canvasWidth / ((maxTableXVal - minTableXVal) || 1);
  let factorY = canvasHeight / ((maxTableYVal - minTableYVal) || 1);

  if (!factorX || !factorY) {
    factorX = canvasWidth;
    factorY = canvasHeight;
  }

  let leadingFactor = Math.min(factorX, factorY);

  if (minTableSize) {
    const minTableWidth = reduce(
      minBy(table => table.width),
      { width: Infinity },
      tablesGeometry
    ).width;

    const minTableHeight = reduce(
      minBy(table => table.height),
      { height: Infinity },
      tablesGeometry
    ).height;

    const minWidthHeight = Math.min(minTableWidth, minTableHeight);
    const actualMinTableSize = minWidthHeight * leadingFactor;

    if (actualMinTableSize < minTableSize) {
      leadingFactor *= minTableSize / actualMinTableSize;
    }
  }

  const newTablesGeometry = map(table => ({
    // odrataj, aby si sa dostal na zaciatok suradnicovej sustavy
    x: table.x * leadingFactor - minTableXVal * leadingFactor,
    y: table.y * leadingFactor - minTableYVal * leadingFactor,
    width: table.width * leadingFactor,
    height: table.height * leadingFactor,
    rounded: table.rounded
  }), tablesGeometry);

  const tablesWithNewGeometry = zipWith(
    (table, tableGeometry) => ({ ...table, geometry: tableGeometry }),
    tables, newTablesGeometry);

  const mostWideTable = reduce(maxBy(table => table.x + table.width), { x: -Infinity, width: 0 },
    newTablesGeometry);
  const totalWidth = mostWideTable.x + mostWideTable.width;

  const mostTallTable = reduce(maxBy(table => table.y + table.height), { y: -Infinity, height: 0 },
    newTablesGeometry);
  const totalHeight = mostTallTable.y + mostTallTable.height;

  return {
    tables: tablesWithNewGeometry,
    scale: leadingFactor,
    dimensions: { width: totalWidth, height: totalHeight }
  };
};
