// @flow

import React from 'react';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import Box from '../../common/components/Box';
import PropTypes from 'prop-types';
import TextInput from '../../common/components/TextInput';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';

export default class Picker extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.object,
    onDateChange: PropTypes.func,
    showDateInput: PropTypes.bool,
    showToday: PropTypes.bool,
    showOk: PropTypes.bool
  };

  constructor(props) {
    super(props);

    const { defaultValue } = props;

    this.calendarContainerRef = React.createRef();

    this.state = {
      value: defaultValue,
      isOpen: false
    };
  }

  onChange = (value) => {
    const { onDateChange } = this.props;

    onDateChange(value);
    this.setState({ value });
  };

  onOpenChange = (isOpen) => {
    this.setState({
      isOpen
    });
  };

  onFocus = () => {
    const { isOpen, isMouseDown } = this.state;

    if (!isOpen && isMouseDown) {
      // focus from a "click" event, let the picker trigger automatically open the calendar
      this.setState({
        isMouseDown: false
      });
    } else {
      // focus not caused by "click" (such as programmatic or via keyboard)
      this.setState({
        isOpen: true
      });
    }
  };

  onMouseDown = () => {
    this.setState({
      isMouseDown: true
    });
  };

  resetValue = (e) => {
    e.stopPropagation();
    this.setState({
      value: null
    });
  };

  getCalendarContainer = () => this.calendarContainerRef.current;

  render() {
    const { showDateInput = true, showToday = false, showOk = true } = this.props;

    const { value, isOpen } = this.state;

    const showDateFormat = 'DD.MM.YYYY';

    return (
      <DatePicker
        animation="slide-up"
        calendar={(
          <Calendar
            disabledTime={() => true}
            showDateInput={showDateInput}
            showToday={showToday} // NOT WORKING?
            showOk={showOk}
            mode="date"
            format={showDateFormat}
            value={value}
          />
        )}
        value={value}
        onChange={this.onChange}
        getCalendarContainer={this.getCalendarContainer}
        onOpenChange={this.onOpenChange}
        open={isOpen}
        style={{ zIndex: 9999 }}
      >
        {
          ({ value }) => (
              <Box onMouseDown={this.onMouseDown} onFocus={this.onFocus} flexBasis="100%" marginBottom={1.5}>
                <TextInput
                  maxLength={20}
                  padding={1}
                  scale={2}
                  color="white"
                  backgroundColor="lessDarkGray"
                  value={value && value.format(showDateFormat) || ''}
                />
                <Button
                  position="absolute"
                  top={0}
                  bottom={0}
                  right={0}
                  paddingHorizontal={0.75}
                  alignItems="center"
                  onPress={this.resetValue}
                >
                  <Icon color="white" name="delete" scale={2} />
                </Button>

                  <div ref={this.calendarContainerRef} />
              </Box>
            )
        }
      </DatePicker>
    );
  }
}
