// @flow
import type { KeyboardDisplayProps } from '../index';
import React from 'react';
import Box from '../../Box';
import Text from '../../Text';
import Button from '../../Button';
import Icon from '../../Icon';
import TextInput from '../../TextInput';

class Display extends React.Component {
  props: KeyboardDisplayProps;

  componentDidMount() {
    const { activeInput } = this.props;

    setTimeout(() => {
      if (this[`input${activeInput}`]) {
        this[`input${activeInput}`].focus();
      }
    }, 200);
  }

  _formatValue(value) {
    // TODO define some value formating if neccessary
    return value;
  }

  voidFunction = () => {};

  onKeyPress = ({ key, charCode }) => {
    if (charCode === 13) return; // enter

    const { onPress } = this.props;
    onPress(key);
  };

  onKeyDown = ({ keyCode }) => {
    const { onPress } = this.props;

    if (keyCode === 8) { // backspace
      onPress('del');
    } else if (keyCode === 13) { // enter
      onPress('send');
    }
  };

  pressCancel = (currentInputNo) => {
    const { activateAndPress } = this.props;
    activateAndPress(currentInputNo, 'cancel');

    if (this[`input${currentInputNo}`]) {
      this[`input${currentInputNo}`].focus();
    }
  };

  render() {
    const { value, activeInput, activateInput, discountTypeValue } = this.props;

    const formattedValue = this._formatValue(value);

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          flexDirection="row"
          justifyContent="space-between"
          backgroundColor="blue"
          borderStyle="solid"
          borderColor={activeInput === 0 ? 'white' : 'blue'}
          borderWidth={1}
          flexShrink={0}
          width="22%"
          style={theme => ({
            paddingLeft: theme.baseSize * 1.5,
            paddingRight: theme.baseSize * 1.5,
            height: theme.typography.lineHeight(5) * 1.75
          })}
          onClick={() => activateInput(0)}
        >
          <TextInput
            nativeRef={c => { this.input0 = c; }}
            value={formattedValue[0]}
            backgroundColor="blue"
            border="none"
            outline="none"
            color="white"
            bold
            width="100%"
            padding={1}
            scale={2}
            onKeyPress={this.onKeyPress}
            onKeyDown={this.onKeyDown}
            onChange={this.voidFunction}
          />
          <Text justifyContent="center" color="white" size={2} style={theme => ({ marginLeft: theme.baseSize * 1.5 })}>
            {discountTypeValue}
          </Text>
        </Box>

        <Box
          flexDirection="row"
          justifyContent="space-between"
          backgroundColor="blue"
          borderStyle="solid"
          borderColor={activeInput === 1 ? 'white' : 'blue'}
          borderWidth={1}
          flexShrink={0}
          width="75%"
          style={theme => ({
            paddingLeft: theme.baseSize * 1.5,
            paddingRight: theme.baseSize * 1.5,
            height: theme.typography.lineHeight(5) * 1.75
          })}
          onClick={() => activateInput(1)}
        >
          <TextInput
            nativeRef={c => { this.input1 = c; }}
            value={formattedValue[1]}
            backgroundColor="blue"
            border="none"
            outline="none"
            color="white"
            bold
            width="100%"
            padding={1}
            scale={2}
            onKeyPress={this.onKeyPress}
            onKeyDown={this.onKeyDown}
            onChange={this.voidFunction}
          />
          <Button
            onPress={() => this.pressCancel(1)}
            style={theme => ({ marginLeft: theme.baseSize * 1.5 })}
          >
            <Text justifyContent="center" color="white">
              <Icon color="white" name="delete" scale={2} />
            </Text>
          </Button>
        </Box>
      </Box>
    );
  }
}

export default Display;
