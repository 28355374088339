// @flow
import { redirectNavigation } from '../lib/utils';
import { setCustomHeaders } from '../lib/fetch/headers';
import { getUserAgent } from '../device/utils';
import { signRequest } from '../lib/fetch/signature';
import api from '../lib/api';
import { signInDone, signInFail, waiterKeyKeepAlive } from './actions';
import { completeOffline } from '../network/actions';

export const signInEpic = (action$: any, { getState }) =>
  action$
    .ofType('SIGN_IN')
    .mergeMap(({ payload: { password, usingWaiterKey } }) => {
      const { device: { machineId, machinePassword, sessionId } } = getState();

      setCustomHeaders({
        'X-Alto-Kasa': machineId,
        'X-Alto-Signature': (url, payload) => signRequest(machinePassword, url, payload),
        'X-Alto-Session': sessionId,
        'x-user-agent': getUserAgent()
      });

      return api.auth.signInUser(password, machineId).then(({ body }) => {
        const { device: { machineId, machinePassword, sessionId } } = getState();

        setCustomHeaders({
          Authorization: `Token ${body.token}`,
          'X-Alto-Kasa': machineId,
          'X-Alto-Signature': (url, payload) => signRequest(machinePassword, url, payload),
          'X-Alto-Session': sessionId,
          'x-user-agent': getUserAgent()
        });
        return [
          completeOffline(),
          signInDone(body, usingWaiterKey)
        ];
      }).catch(e => [
        completeOffline(),
        signInFail(e)
      ]);
    });

export const signOutEpic = (action$: any) =>
  action$
    .ofType('SIGN_OUT')
    .map(() => {
      const direction = process.env.IS_REACT_NATIVE === '1'
        ? 'SignInPage'
        : '/';

      return redirectNavigation(direction);
    });

export const repeatFirstWaiterKeyKeepAlive = (action$: any, { getState }) =>
  action$
    .ofType(
      'WAITER_KEY_INIT_DONE'
    )
    .filter(() => !getState().auth.waiterKey.keepAliveRunning)
    .delay(1000) // 1 second
    .map(() => waiterKeyKeepAlive());

export const repeatWaiterKeyKeepAlive = (action$: any) =>
  action$
    .ofType(
      'WAITER_KEY_KEEP_ALIVE',
    )
    .delay(5000) // 5 seconds
    .map(() => waiterKeyKeepAlive());
