// @flow
import React from 'react';
import Box from './Box';

// eslint-disable-next-line import/no-mutable-exports
let ScrollViewImpl;

if (process.env.IS_REACT_NATIVE === '1') {
  ScrollViewImpl = require('react-native').ScrollView;
} else {
  ScrollViewImpl = props =>
    <Box overflow="auto" height="100%" {...props} />;
}


export default ScrollViewImpl;
