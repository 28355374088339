// @flow
import type { Action } from '../../types';


export const initiateAdminTableReload = (): Action => ({
  type: 'ADMIN_INITIATE_ADMIN_TABLE_RELOAD',
});

export const addAdminError = (error: string[]) => ({
  type: 'ADMIN_ADD_ERROR',
  payload: { error }
});

export const clearAdminError = () => ({
  type: 'ADMIN_CLEAR_ERROR',
  payload: {}
});

export const toggleAdminNotificationPopup = (show: boolean) => ({
  type: 'ADMIN_TOGGLE_NOTIFICATION',
  payload: { show }
});

export const toggleAdminLoadingScreen = (show: boolean) => ({
  type: 'ADMIN_TOGGLE_LOADING_SCREEN',
  payload: { show }
});
