// @flow
import React from 'react';
import Box from '../../common/components/Box';
import Corner from './Corner';
import Text from '../../common/components/Text';
import theme from '../../common/themes/theme';
import Icon from './Icon';

type PopupProps = {
  onClose?: Function,
  onBack?: Function,
  width?: number | string,
  height?: number | string,
  showClose?: boolean,
  style?: any,
  children?: any,
  backgroundColor?: string,
  color?: string,
  onCloseButtonShadow?: string,
  pushUpward?: boolean
}

class Popup extends React.PureComponent {
  props: PopupProps;

  render() {
    const {
      width = 'auto',
      height = 'auto',
      onClose,
      showClose = true,
      onBack,
      style,
      children,
      backgroundColor = 'popupBg',
      color = 'white',
      onCloseButtonShadow = 'default',
      pushUpward = false
    } = this.props;

    return (
      <Box
        marginLeft="auto"
        marginRight="auto"
        marginTop={pushUpward ? -1 : 0}
        width={width}
        flexDirection="column"
        justifyContent={pushUpward ? 'flex-start' : 'center'}
        flexGrow="1"
        height="100%"
        style={style}
      >
        <Box
          height={height}
          backgroundColor={backgroundColor}
          boxShadow="default"
          borderRadius="normal"
          borderColor="popupBg"
          borderWidth={1}
          borderStyle="solid"
        >
          <Box padding={`${theme.baseSize * 3}px`}>
            {children}
          </Box>

          {showClose &&
            <Corner
              size={2.5}
              backgroundColor={backgroundColor}
              onPress={onClose}
              borderRadius="normal"
              boxShadow={onCloseButtonShadow}
            >
              <Text color={color} bold size={1.75}>&times;</Text>
            </Corner>}

          {onBack &&
          <Corner
            size={2.5}
            backgroundColor={backgroundColor}
            onPress={onBack}
            borderRadius="normal"
            boxShadow={onCloseButtonShadow}
            corner="top-left"
          >
            <Icon color={color} bold name="back-arrow" />
          </Corner>}
        </Box>
      </Box>
    );
  }
}

export default Popup;
