// @flow
import type { State } from '../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, path } from 'rambda';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Popup from '../components/Popup';
import { injectIntl } from 'react-intl';
import messages from '../../common/payment/messages/page';
import Keyboard from '../../common/components/Keyboard/customDiscount/Keyboard';
import TypewriterButtons from '../../common/components/Keyboard/customDiscount/Buttons';
import TypewriterDisplay from '../../common/components/Keyboard/customDiscount/Display';
import createTypewriterOperations from '../../common/components/Keyboard/customDiscount/operations';
import { toggleDisableForm, updatePaymentTransaction } from '../../common/payment/actions';
import { processReceivedPaymentTransactionData } from '../../common/payment/utils';
import api from '../../common/lib/api';
import { addError } from '../../common/order/actions';
import { paymentOperationsName } from '../../common/lib/api/payment';
import orderKeeper, { RequestOrderError } from '../../common/lib/fetch/orderKeeper';


class CustomDiscountPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numericValue: '',
      textValue: '',
      activeKeyboard: 1,
    };
  }

  reqOrderKeeper = orderKeeper(paymentOperationsName);

  addDiscount = (type, amount, description) => {
    const { dispatch, paymentTransactionId } = this.props;

    dispatch(toggleDisableForm(true));

    this.reqOrderKeeper(() =>
        api.payment.addCustomDiscount(paymentTransactionId, type, amount, description)
      ).then(({ body }) => {
        if (path(['payment', 'id'], body)) {
          dispatch([
            updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
            toggleDisableForm(false)
          ]);
        }
      }).catch(e => {
        if (e instanceof RequestOrderError) return;

        dispatch([
          addError(e),
          toggleDisableForm(false)
        ]);
      });
  };

  render() {
    const { intl, closeCustomDiscount, discountTypeValue } = this.props;

    return (
      <Box
        zIndex={1001}
        position="absolute"
        top={0} bottom={0} left={0} right={0}
        width="100%"
        height="100%"
        backgroundColor="grid"
        alignItems="center"
        justifyContent="center"
      >
        <Popup showClose onClose={() => closeCustomDiscount()}>
          <Box marginBottom={0.5} flexDirection="row">
            <Text
              color="white"
              backgroundColor="fullTransparent"
              bold
              onClick={() => this.setState({ activeKeyboard: 1 })}
            >
              {`${intl.formatMessage(messages.discount)} ${intl.formatMessage(messages[`${discountTypeValue}Discount`])}`}
            </Text>
            <Text
              color="white"
              backgroundColor="fullTransparent"
              bold
              position="absolute"
              left={10.4}
              onClick={() => this.setState({ activeKeyboard: 2 })}
            >
              {intl.formatMessage(messages.discountReason)}
            </Text>
          </Box>
          <Box alignItems="center">
            <Keyboard
              zIndex={this.state.activeKeyboard === 1 ? 2 : 1}
              style={theme => ({
                width: theme.typography.lineHeight(3) * 1.75 * 11 + theme.typography.lineHeight() * 7.5
              })}
              createOperations={createTypewriterOperations}
              Buttons={TypewriterButtons}
              Display={TypewriterDisplay}
              onLastKey={key => {
                if (key === 'send') {
                  const amount = this.state.numericValue === ''
                    ? 0
                    : discountTypeValue === 'relative'
                      ? this.state.numericValue * 1
                      : this.state.numericValue * 100;

                  const description = this.state.textValue === ''
                    ? intl.formatMessage(messages.discount)
                    : this.state.textValue;

                  this.addDiscount(discountTypeValue, amount, description);

                  closeCustomDiscount();
                }
              }}
              onDisplayValue={val => {
                this.setState({ numericValue: val[0].replace(intl.formatMessage(messages.dot), '.') });
                this.setState({ textValue: val[1] });
              }}
              discountTypeValue={intl.formatMessage(messages[discountTypeValue])}
              intlDot={intl.formatMessage(messages.dot)}
              // defaultValue={intl.formatMessage(messages[discountTypeValue])}
            />
          </Box>
        </Popup>
      </Box>);
  };
}

export default compose(
  connect(
    (state: State) => ({
      orderLines: state.orders.orderLines,
      selectedOrderLines: state.orders.selectedOrderLines,
      payments: state.payment.payments,
      paymentTransactionId: state.payment.paymentTransactionId,
      discounts: state.payment.appliedDiscounts,
    })
  ),
  injectIntl,
)(CustomDiscountPopup);
