// @flow

import type { Deps, State } from './types';
import { lookUpId } from './localIds/lookUpService';

const configureDeps = (initialState: State, platformDeps: Deps) => ({
  ...platformDeps,
  getUid: () => platformDeps.uuid.v4(),
  now: () => Date.now(),
  lookUpId,
});

export default configureDeps;
