// @flow
import type { State } from '../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../common/components/Box';
import {
  toggleIsChangingPortion,
  changePortion,
  clearSelectedOrderLines
} from '../../../common/order/actions';
import Button from '../../../common/components/Button';
import Text from '../../../common/components/Text';
import Popup from '../../components/Popup';
import Heading from '../../../common/components/Heading';
import { FormattedMessage } from 'react-intl';
import messages from '../../../common/messages/order';

const ChoosePortions = ({ dispatch, selectedOrderLines }) => {

  return (
    <Box
      backgroundColor="inactiveBg"
      zIndex={1000}
      position="absolute"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Popup showClose onClose={() => dispatch(toggleIsChangingPortion(false))} style={{ width: '20rem' }}>
        <Heading scale={3} color="white"><FormattedMessage {...messages.choosePortion} /></Heading>

        {[1, 2, 3, 4].map((el) => (
            <Button
              key={el}
              backgroundColor="teal"
              outline
              color="white"
              paddingVertical={0.5}
              marginBottom={0.5}
              onPress={() => {
                dispatch(changePortion(selectedOrderLines, el));
                dispatch(clearSelectedOrderLines());
                dispatch(toggleIsChangingPortion(false));
              }}
            >
              <Text color="white" bold overflow="hidden">
                <FormattedMessage {...messages[`itemPortion${el}`]} />
              </Text>
            </Button>
          )
        )}

      </Popup>
    </Box>
  );
};

export default connect((state: State) => ({
  selectedOrderLines: state.orders.selectedOrderLines,
}))(ChoosePortions);
