// @flow
import React from 'react';
import debounce from 'debounce';

export default (delay: number = 300) => BaseComponent => class extends React.PureComponent {
  debouncedOnPress = (...args) => {
    this.props.onPress && this.props.onPress(...args);
  };

  onPress = debounce(this.debouncedOnPress, delay, true);

  render() {
    return <BaseComponent {...this.props} onPress={this.onPress} />;
  }
};
