// @flow
import * as alcohol from './alcohol';
import * as auth from './auth';
import * as cashiers from './cashiers';
import * as closure from './closure';
import * as ean from './ean';
import * as fiscal from './fiscal';
import * as item from './item';
import * as openTable from './openTable';
import * as payment from './payment';
import * as priceList from './priceList';
import * as receipt from './receipt';
import * as subTable from './subTable';
import * as sync from './sync';
import * as tableDefinition from './tableDefinition';
import * as usage from './usage';
import * as kitchenMessage from './kitchenMessage';
import * as hores from './hores';

let apiUrl = '';

export const configureApi = (url: string) => {
  apiUrl = (url || '').trim();
};

export const getApiUrl = () =>
  apiUrl;

export const apiEndpoint = endpoint =>
  `api/${endpoint}${endpoint.endsWith('/') ? '' : '/'}`;

export const apiEndpointWithGetParameters = endpoint =>
  `api/${endpoint}`;

export default {
  alcohol,
  auth,
  cashiers,
  closure,
  ean,
  fiscal,
  item,
  hores,
  openTable,
  payment,
  priceList,
  receipt,
  subTable,
  sync,
  tableDefinition,
  usage,
  kitchenMessage
};
