// @flow
import type { KeyboardDisplayProps } from '../index';
import React from 'react';
import Box from '../../Box';
import Text from '../../Text';
import Button from '../../Button';
import Icon from '../../Icon';
import theme from '../../../themes/theme';

class Display extends React.PureComponent {
  props: KeyboardDisplayProps;

  componentDidMount() {
    if (!this.textarea) return;
    this.textarea.focus();
  }

  componentDidUpdate() {
    if (!this.textarea) return;
    this.textarea.focus();
  }

  render() {
    const { value, onPress } = this.props;

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor="teal"
        borderStyle="solid"
        borderColor="teal"
        flexShrink={0}
        paddingVertical={0.5}
        style={theme => ({
          borderLeftWidth: theme.baseSize * 1.5,
          borderRightWidth: theme.baseSize * 1.5,
          height: theme.typography.lineHeight(5) * 2.75
        })}
      >
        <textarea
          ref={c => { this.textarea = c; }}
          value={value}
          style={{
            backgroundColor: '#109b97', // teal
            border: 'none',
            outline: 'none',
            padding: 0,
            color: 'white',
            fontFamily: 'RobotoBold',
            fontSize: theme.typography.fontSize(3, 'RobotoBold'),
            lineHeight: `${theme.typography.lineHeight(3, 'RobotoBold')}px`,
            width: '100%',
            height: '100%',
            minHeight: theme.typography.lineHeight(5) * 1.75,
          }}
          onKeyPress={({ key, charCode }) => onPress(charCode === 13 ? 'enter' : key)}
          onKeyDown={({ keyCode }) => {
            if (keyCode === 8) { // backspace
              onPress('del');
            }
          }}
          onChange={() => {}}
        />
        <Button onPress={() => onPress('cancel')} style={theme => ({ marginLeft: theme.baseSize * 1.5 })}>
          <Text color="white" paddingTop={0.25}>
            <Icon color="white" name="delete" scale={3} />
          </Text>
        </Button>
      </Box>
    );
  }
}

export default Display;
