// @flow
import type { OpenTable, OrderLine, State, TableDefinition } from '../types';
import React from 'react';
import moment from 'moment';
import Box from './Box';
import Text, { TextAdjustableSize } from './Text';
import Label from './Label';
import { monospaceFamily } from '../themes/typography';
import Corner from '../../browser/components/Corner';
import { isReactNative } from '../app/detectPlatform';
import Icon from './Icon';
import Button from './Button';
import { filter, compose, find, propEq, pathOr } from 'rambda';
import { isEmpty } from 'ramda';
import {
  belongsOpenTableToEmployee, belongsOpenTableWithFirstOrderLineToEmployee,
  findEmployeeFromFirstCreatedOrderLine,
  hasOpenTableOrderLine
} from '../tables/utils';
import { connect } from 'react-redux';
import { undeletedSubTablesSelector } from '../tables/selectors';
import checkPermission from '../permissions/service';
import checkParameter from '../parameters/service';
import theme from '../themes/theme';
import guard from './hoc/guard';

export type TableProps = {
  table: TableDefinition,
  openTable?: OpenTable,
  openTable2?: OpenTable,
  isEditing?: boolean,
  disabled?: boolean,
  position?: string,
  onPress?: Function,
  onLongPress?: Function,
  onDelete?: Function,
};

const vertical = ({ geometry: { height, width } = {} }: TableDefinition) =>
  height > width;

const padZero = (num) => (num < 10) ? `0${num}` : num;

const formatDuration = (duration) =>
  `${moment.duration(duration).hours()}:${padZero(moment.duration(duration).minutes())}`

let isAndroid = false;
if (process.env.IS_REACT_NATIVE === '1') {
  isAndroid = require('react-native').Platform.OS === 'android';
}

class Table extends React.Component {
  props: TableProps;

  constructor(props) {
    super(props);

    const {
      openTable: { openedTime: openedTime1 } = {},
      openTable2: { openedTime: openedTime2 } = {}
    } = props;

    this.state = {
      duration1: openedTime1 ? Math.max(0, Date.now() - +moment(openedTime1)) : null,
      duration2: openedTime2 ? Math.max(0, Date.now() - +moment(openedTime2)) : null
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props !== nextProps)
      || (this.state.duration1 !== nextState.duration1)
      || (this.state.duration2 !== nextState.duration2);
  }

  componentDidMount() {
    const {
      openTable: { openedTime: openedTime1 } = {},
      openTable2: { openedTime: openedTime2 } = {}
    } = this.props;

    if (!openedTime1) return;

    this.interval = setInterval(() => {
      const duration1 = Math.max(0, Date.now() - +moment(openedTime1));
      const duration2 = Math.max(0, Date.now() - +moment(openedTime2));
      const isInSameMinute1 = moment(duration1).isSame(this.state.duration1, 'minute');
      const isInSameMinute2 = moment(duration2).isSame(this.state.duration2, 'minute');

      if (openedTime1 && !isInSameMinute1) {
        this.setState({
          duration1
        });
      }

      if (openedTime2 && !isInSameMinute2) {
        this.setState({
          duration2
        });
      }
    }, 5000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const {
      table,
      openTable,
      openTable2,
      disabled,
      position,
      isEditing,
      onDelete = () => {},
      onPress = () => {},
      onLongPress = () => {},
      forceBorder,
      style,
      user,
      employees,

      orderLines,
      subTables,
      dispatch,
      ...restProps
    } = this.props;
    const tableAdminPermission = checkPermission('opentable.others.change');
    const isFilter = checkParameter('K32.is_filter');
    const isRestricted = isFilter && !tableAdminPermission;

    const restrictedLongPress = isRestricted ? () => {} : onLongPress;

    const { duration1, duration2 } = this.state;
    const isSplitted = openTable2 && !isEmpty(openTable2);
    const isVertical = vertical(table);
    const { geometry: { x, y, height, width, rounded } } = table;

    const orderlinesConfirmed = filter((orderLine: OrderLine) => orderLine.isSentToKitchen, orderLines);
    const orderlinesUnconfirmed = filter((orderLine: OrderLine) => !orderLine.isSentToKitchen, orderLines);

    const orderlineConfirmed1 = openTable ? hasOpenTableOrderLine(openTable, orderlinesConfirmed, subTables) : false;
    const orderlineUnconfirmed1 = openTable ? hasOpenTableOrderLine(openTable, orderlinesUnconfirmed, subTables) : false;
    const hasNoOrderlines1 = !orderlineConfirmed1 && !orderlineUnconfirmed1;
    const hasOnlyUnconfirmedOrderLine1 = !orderlineConfirmed1 && orderlineUnconfirmed1;

    const orderlineConfirmed2 = openTable2 ? hasOpenTableOrderLine(openTable2, orderlinesConfirmed, subTables) : false;
    const orderlineUnconfirmed2 = openTable2 ? hasOpenTableOrderLine(openTable2, orderlinesUnconfirmed, subTables) : false;
    const hasNoOrderlines2 = !orderlineConfirmed2 && !orderlineUnconfirmed2;
    const hasOnlyUnconfirmedOrderLine2 = !orderlineConfirmed2 && orderlineUnconfirmed2;

    const borderRadius = rounded ? (width + height) * 0.25 : 0;

    const emptyOrderline1 = openTable && !isEmpty(openTable) && hasNoOrderlines1;
    const emptyOrderline2 = openTable2 && !isEmpty(openTable2) && hasNoOrderlines2;
    const backgroundColor1 = (hasOnlyUnconfirmedOrderLine1 && 'buttonGray') || (openTable && !isEmpty(openTable) && !emptyOrderline1 && 'teal') || 'white';
    const backgroundColor2 = (hasOnlyUnconfirmedOrderLine2 && 'buttonGray') || (openTable2 && !isEmpty(openTable2) && !emptyOrderline2 && 'teal') || (emptyOrderline2 && 'lightgray') || 'white';

    const firstOrderLineEmployeeId = findEmployeeFromFirstCreatedOrderLine(orderlinesConfirmed, openTable, subTables);
    const firstOrderLineEmployeeId2 = findEmployeeFromFirstCreatedOrderLine(orderlinesConfirmed, openTable2, subTables);

    const belongsToMe = firstOrderLineEmployeeId
      ? belongsOpenTableWithFirstOrderLineToEmployee(openTable, firstOrderLineEmployeeId, user.id)
      : openTable
        ? belongsOpenTableToEmployee(openTable, user.id)
        : true;
    const belongsToMe2 = firstOrderLineEmployeeId2
      ? belongsOpenTableWithFirstOrderLineToEmployee(openTable2, firstOrderLineEmployeeId2, user.id)
      : openTable2
        ? belongsOpenTableToEmployee(openTable2, user.id)
        : true;

    const employee = firstOrderLineEmployeeId
      ? find(propEq('id', firstOrderLineEmployeeId), employees)
      : openTable
        ? find(propEq('id', pathOr(null, 'employeeId', openTable)), employees)
        : null;
    const employee2 = firstOrderLineEmployeeId2
      ? find(propEq('id', firstOrderLineEmployeeId2), employees)
      : openTable2
        ? find(propEq('id', pathOr(null, 'employeeId', openTable2)), employees)
        : null;

    const userName = employee ? employee.userName : '';
    const userName2 = employee2 ? employee2.userName : '';

    return (
      <Box
        position={position || 'absolute'}
        height={`${height + 3}px`}
        width={`${width}px`}
        top={`${y}px`}
        left={`${x}px`}
        flexDirection="column"
        {...restProps}
      >
        {/* shadow: */}
        <Box
          position="absolute"
          top="3px"
          left={rounded ? 0 : '2px'}
          height={`${height}px`}
          width={`${rounded ? width : width - 4}px`}
          opacity={(disabled || isEditing) ? 0 : 1}
          backgroundColor="tableShadow"
          borderRadius={borderRadius}
        />

        <Box
          borderStyle={(isEditing || forceBorder) ? 'dashed' : 'solid'}
          borderColor="tableShadow"
          borderWidth={(isEditing || forceBorder) ? 2 : 0}
          width={`${width}px`}
          height={`${height}px`}
          opacity={disabled ? 0.1 : 1}
        >
          {isEditing && (!openTable || isEmpty(openTable)) && checkPermission('tabledefinition.delete') &&
            <Corner
              size={2.5}
              backgroundColor="red"
              borderWidth="3"
              borderStyle="solid"
              borderColor="white"
              onPress={onDelete}
              zIndex={1}
            >
              <Icon color="white" name="trash" />
            </Corner>
          }

          <Background
            backgroundColor1={backgroundColor1}
            backgroundColor2={backgroundColor2}
            isSplitted={isSplitted}
            splitHorizontally={isVertical}
            borderRadius={borderRadius}
          />

          <TableCaptions
            table={table}
            isSplitted={isSplitted}
            backgroundColor1={backgroundColor1}
            backgroundColor2={backgroundColor2}
            borderRadius={borderRadius}
          />

          <BubbleCorners
            isEditing={isEditing}
            splitHorizontally={isVertical}
            isSplitted={isSplitted}
            hasUnconfirmedOrderlines1={orderlineUnconfirmed1}
            hasUnconfirmedOrderlines2={orderlineUnconfirmed2}
            emptyOrderline1={emptyOrderline1}
            emptyOrderline2={emptyOrderline2}
            belongsToMe={belongsToMe}
            belongsToMe2={belongsToMe2}
            userName={userName}
            userName2={userName2}
          />

          {isSplitted
            ? <HalfMask
                splitHorizontally={isVertical}
                onPress={isRestricted ? belongsToMe ? onPress : () => {} : onPress}
                onPress2={isRestricted ? belongsToMe2 ? onPress : () => {} : onPress}
                onLongPress={belongsToMe ? onLongPress : restrictedLongPress}
                onLongPress2={belongsToMe2 ? onLongPress : restrictedLongPress}
                openTable={openTable}
                openTable2={openTable2}
                borderRadius={borderRadius}
                duration1={emptyOrderline1 ? null : duration1}
                duration2={emptyOrderline2 ? null : duration2}
                backgroundColor1={backgroundColor1}
                backgroundColor2={backgroundColor2}
              />
            : <FullMask
                onPress={isRestricted ? belongsToMe ? onPress : () => {} : onPress}
                onLongPress={belongsToMe ? onLongPress : restrictedLongPress}
                borderRadius={borderRadius}
                backgroundColor={backgroundColor1}
                duration={emptyOrderline1 ? null : duration1}
                openTable={openTable}
              />
          }
        </Box>
      </Box>
    );
  }
}

const Background = ({
  backgroundColor1,
  backgroundColor2,
  isSplitted,
  splitHorizontally,
  borderRadius
}) => (
  <Box
    position="absolute"
    top={0}
    bottom={0}
    left={0}
    right={0}
    flexDirection={splitHorizontally ? 'column' : 'row'}
    backgroundColor={backgroundColor1}
    borderRadius={borderRadius}
  >
    <Box
      height={splitHorizontally ? '50%' : '100%'}
      width={splitHorizontally ? '100%' : '50%'}
      backgroundColor={backgroundColor1}
      {...(splitHorizontally
        ? { borderTopLeftRadius: borderRadius, borderTopRightRadius: borderRadius, }
        : { borderTopLeftRadius: borderRadius, borderBottomLeftRadius: borderRadius }
        )}
    />
    <Box
      height={splitHorizontally ? '50%' : '100%'}
      width={splitHorizontally ? '100%' : '50%'}
      backgroundColor={isSplitted ? backgroundColor2 : backgroundColor1}
      {...(splitHorizontally
          ? { borderBottomLeftRadius: borderRadius, borderBottomRightRadius: borderRadius }
          : { borderTopRightRadius: borderRadius, borderBottomRightRadius: borderRadius }
      )}
    />
  </Box>
);

// eslint-disable-next-line react/no-multi-comp
class BubbleCorners extends React.PureComponent {
  render() {
    const {
      isEditing,
      emptyOrderline1,
      emptyOrderline2,
      hasUnconfirmedOrderlines1,
      hasUnconfirmedOrderlines2,
      isSplitted,
      splitHorizontally,
      belongsToMe,
      belongsToMe2,
      userName,
      userName2
    } = this.props;

    if (isEditing) return null;

    const SpecializedCorner = ({
      userName,
      icon,
      corner
    }) => (
      <Corner
        size={2.5}
        width={userName ? 3.7 : 2}
        backgroundColor="buttonGray"
        zIndex={1}
        borderRadius={isReactNative ? undefined : 3}
        corner={corner}
        inside={isReactNative}
        flexDirection="row"
        justifyContent={userName ? 'space-between' : 'center'}
        alignItems="center"
        paddingHorizontal={userName ? 0.5 : 0}
      >
        <Icon color="white" name={icon} scale={1} />
        {userName &&
          <Text color="white">{(userName || '').substring(0, 3)}</Text>}
      </Corner>);

    return (
      <Box position="absolute" top={0} bottom={0} left={0} right={0}>
        {belongsToMe
          ? (hasUnconfirmedOrderlines1
            ? <SpecializedCorner
                icon="food"
                corner={(splitHorizontally && isSplitted && 'top-right') ||
                (!splitHorizontally && isSplitted && 'top-left') ||
                'top-right'}
              />
            : null)
          : (!emptyOrderline1
            ? <SpecializedCorner
                userName={userName}
                icon="user"
                corner={(splitHorizontally && isSplitted && 'top-right') ||
                (!splitHorizontally && isSplitted && 'top-left') ||
                'top-right'}
              />
            : null)}

        {isSplitted
          ? (belongsToMe2
            ? <Box>
              {hasUnconfirmedOrderlines2 &&
                <SpecializedCorner
                  icon="food"
                  corner={'bottom-right'}
                />}
              </Box>
            : (!emptyOrderline2
                ? <SpecializedCorner
                    userName={userName2}
                    icon="user"
                    corner={'bottom-right'}
                  />
                : null))
          : null
        }
      </Box>
    );
  }
}

const TableCaptions = ({
  table,
  backgroundColor1,
  backgroundColor2,
  isSplitted,
  borderRadius,
}) => {
  const itemsCount = 0; // TODO
  const { geometry: { height, width, rounded } } = table;

  return (
    <Box
      flexDirection="row"
      width={(isSplitted && !rounded) ? '100%' : 'auto'}
      flexGrow={1}
      flexShrink={1}
    >
      <Box
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor="transparent"
        style={isReactNative ? { width: '100%' } : { marginLeft: 10, width: width - 20 }}
        borderRadius={borderRadius}
      >
        <TextAdjustableSize
          bold
          align="center"
          justifyContent="center"
          color={(!isSplitted && backgroundColor1 === 'white') || (isSplitted && (backgroundColor1 === 'white' || backgroundColor2 === 'lightgray')) ? 'black' : 'white'}
          {...(isReactNative
            ? { numberOfLines: 2, ellipsizeMode: 'tail' }
            : { height: (height - 30) / theme.typography.lineHeight() * (rounded ? 0.75 : 1),
                width: (width - 30) / theme.typography.lineHeight() * (rounded ? 0.75 : 1),
                className: 'just-center' }
          )}
          minTextSize={12}
          maxTextSize={120}
        >
          {table.name}
        </TextAdjustableSize>

        {!!itemsCount && <Label
            rounded
            backgroundColor="white"
            color="teal"
            top={0}
            right={isAndroid ? 0 : -1.2}
            position="absolute"
            zIndex={1}
          >
            {`${itemsCount}`}
          </Label>
        }
      </Box>
    </Box>
  );
};

const HalfMask = ({
  splitHorizontally,
  openTable,
  openTable2,
  onPress,
  onPress2,
  onLongPress,
  onLongPress2,
  borderRadius,
  duration1,
  duration2,
  backgroundColor1,
  backgroundColor2,
  ...restProps
}) => {
  const isSplitted = openTable2 && !isEmpty(openTable2);
  const splitColor = backgroundColor1 === 'white' && (backgroundColor2 === 'white' || backgroundColor2 === 'lightgray') ? 'black' : 'white';

  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      flexDirection={splitHorizontally ? 'column' : 'row'}
      borderRadius={borderRadius}
      {...restProps}
    >
      {isSplitted && (splitHorizontally
          ? <Box backgroundColor={splitColor} height="1px" width="100%" top="50%" position="absolute" opacity={0.4} />
          : <Box backgroundColor={splitColor} height="100%" width="1px" left="50%" position="absolute" opacity={0.4} />
      )}

      {isSplitted && (splitHorizontally
          ? <Box backgroundColor="white" height="50%" width="100%" top="50%" position="absolute" opacity={0.2} borderBottomLeftRadius={borderRadius} borderBottomRightRadius={borderRadius} />
          : <Box backgroundColor="white" height="100%" width="50%" left="50%" position="absolute" opacity={0.2} borderTopRightRadius={borderRadius} borderBottomRightRadius={borderRadius} />
      )}

      <Button
        height={splitHorizontally ? '50%' : '100%'}
        width={splitHorizontally ? '100%' : '50%'}
        justifyContent={splitHorizontally ? 'center' : 'flex-start'}
        alignItems={splitHorizontally ? 'flex-start' : 'center'}
        onPress={(e) => onPress(e, { openTable: openTable || {} })}
        onLongPress={(e) => onLongPress(e, { openTable: openTable || {} })}
      >
        {!isReactNative && duration1 !== null && (
          <Box flexDirection="row" marginTop={splitHorizontally ? 0.25 : 0} marginLeft={splitHorizontally ? 0 : 0.25} borderWidth="3px" borderColor="transparent" borderStyle="solid" backgroundColor={`${backgroundColor1}_a`}>
            <Icon name="clock" color="white" bold scale={1} />
            <Text color="white" bold fontFamily={monospaceFamily} marginLeft={0.1}>
              {formatDuration(duration1)}
            </Text>
          </Box>
        )}
      </Button>
      <Button
        height={splitHorizontally ? '50%' : '100%'}
        width={splitHorizontally ? '100%' : '50%'}
        justifyContent={splitHorizontally ? 'center' : 'flex-end'}
        alignItems={splitHorizontally ? 'flex-end' : 'center'}
        onPress={(e) => onPress2(e, { openTable: openTable2 || {} })}
        onLongPress={(e) => onLongPress2(e, { openTable: openTable2 || {} })}
      >
        {!isReactNative && duration2 !== null && (
          <Box flexDirection="row" marginBottom={splitHorizontally ? 0.25 : 0} marginRight={splitHorizontally ? 0 : 0.25} borderWidth="3px" borderColor="transparent" borderStyle="solid" background={`${backgroundColor2}_a2`}>
            <Icon name="clock" color="white" bold scale={1} />
            <Text color="white" bold fontFamily={monospaceFamily} marginLeft={0.1}>
              {formatDuration(duration2)}
            </Text>
          </Box>
        )}
      </Button>
    </Box>
  );
};

// eslint-disable-next-line react/no-multi-comp
class FullMask extends React.PureComponent {
  _onPress = e => {
    const { onPress, openTable = {} } = this.props;
    onPress(e, { openTable });
  };

  _onLongPress = e => {
    const { onLongPress, openTable = {} } = this.props;
    onLongPress(e, { openTable });
  };

  render() {
    const { onPress, onLongPress, openTable, duration, borderRadius, backgroundColor, ...restProps } = this.props;

    return (
      <Button
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        right={0}
        onPress={this._onPress}
        onLongPress={this._onLongPress}
        borderRadius={borderRadius}
        {...restProps}
      >
        {!isReactNative && duration !== null && (
          <Box flexDirection="row" justifyContent="center" alignItems="flex-end" marginBottom={0.25} alignSelf="flex-end" borderWidth="3px" borderColor="transparent" borderStyle="solid" backgroundColor={`${backgroundColor}_a`}>
            <Icon name="clock" color="white" bold scale={1} />
            <Text color="white" bold fontFamily={monospaceFamily} marginLeft={0.1}>
              {formatDuration(duration)}
            </Text>
          </Box>
        )}
      </Button>
    );
  }
}

export default compose(
  connect((state: State) => ({
    orderLines: state.orders.orderLines,
    subTables: undeletedSubTablesSelector(state),
    user: state.auth.user,
    employees: state.employees.employees
  })),
  guard(({ user }) => !!user)
)(Table);
