// @flow
import React from 'react';
import Box from './Box';
import Text from './Text';
import Button from './Button';
import type { ButtonProps } from './Button';
import { isReactNative } from '../app/detectPlatform';
import Icon from './Icon';
import { displayEllipsis } from '../../browser/themes/utils';

type ComplexButtonProps = ButtonProps & {
  icon: string | React.Element<*>,
  iconSize?: number,
  caption: string,
  backgroundColor: string,
  shadowColor: string,
  color?: string,
  roundBorderColor?: string,
  disabled?: boolean,
  width?: string | number,
};

const ComposedButton = ({
  backgroundColor, caption, shadowColor, icon, iconSize, color, roundBorderColor, disabled, ...restProps
}: ComplexButtonProps) => (
  <Button
    flexDirection="column"
    justifyContent="flex-start"
    marginBottom="3px"
    style={theme => ({
      height: theme.typography.lineHeight() * 3.5 + 3 // place for shadow
    })}
    disabled={disabled}
    {...restProps}
  >
    <Box height={0.5} />

    <Box justifyContent="flex-end" flexGrow={1} backgroundColor={backgroundColor}>
      <Text
        align="center"
        color={color || 'white'}
        bold
        paddingBottom={0.3}
        paddingHorizontal={0.25}
        overflow="hidden"
        {...isReactNative ? { numberOfLines: 1 } : { style: displayEllipsis }}
      >
        {caption}
      </Text>
    </Box>

    <Box height="3px" />

    <Box
      position="absolute"
      left={0}
      right={0}
      top="1px"
      flexDirection="row"
      justifyContent="center"
      height={2}
    >
      <Box
        size={2}
        overflow="hidden"
        backgroundColor={shadowColor}
        width={2}
        height={2}
        alignItems="center"
        justifyContent="center"
        borderWidth={2}
        borderStyle="solid"
        borderColor={roundBorderColor || "white"}
        style={theme => ({ borderRadius: theme.typography.lineHeight() })}
      >
        {(typeof icon === 'string')
          ? <Icon name={icon} size={iconSize || 1.5} color={color || "white"} justifyContent="center" />
          : icon}
      </Box>
    </Box>

    <Box
      position="absolute"
      right="3px"
      left="3px"
      bottom="0"
      height="3px"
      backgroundColor={shadowColor}
    />
  </Button>
);

export default ComposedButton;
