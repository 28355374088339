// @flow
import type { Operations } from '../index';

const operations: Operations = (saveChanges) => ({
  sendKey(store, key) {
    // eslint-disable-next-line default-case
    switch (key) {
      case 1: case '1':
      case 2: case '2':
      case 3: case '3':
      case 4: case '4':
      case 5: case '5':
      case 6: case '6':
      case 7: case '7':
      case 8: case '8':
      case 9: case '9':
      case 0: case '0':
        store.setLastKey(`${key}`);
        break;

      case '.': {
        store.setLastKey(',');
        break;
      }

      case 'del': {
        store.setLastKey(key);
        break;
      }
    }

    saveChanges(store);
  }
});

export default operations;
