// @flow
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../common/components/Box';
import {
  toggleIsChangingPriceLevel,
  changePriceLevelOfOrderLine,
  clearSelectedOrderLines
} from '../../../common/order/actions';
import { setActivePriceLevel } from '../../../common/items/actions';
import Button from '../../../common/components/Button';
import Text from '../../../common/components/Text';
import Popup from '../../components/Popup';
import Heading from '../../../common/components/Heading';
import { FormattedMessage } from 'react-intl';
import messages from '../../../common/messages/order';
import ScrollView from '../../../common/components/ScrollView';
import { prop, sortBy } from 'rambda';

const ChoosePriceLevels = ({ dispatch, priceLevels, selectedOrderLines, isChangingPriceLevel }) => (
  <Box
    backgroundColor="inactiveBg"
    zIndex={1000}
    position="absolute"
    top={0}
    left={0}
    width="100vw"
    height="100vh"
    onMouseDown={(e) => e.stopPropagation()}
  >
    <Popup showClose onClose={() => dispatch(toggleIsChangingPriceLevel(false))} style={{ width: '20rem' }}>
      <Heading scale={3} color="white"><FormattedMessage {...messages.choosePriceLevel} /></Heading>

      <Box maxHeight={20}>
        <ScrollView>
          {priceLevels && sortBy(prop('sortOrder'), priceLevels).map(({ name, id }) => (
            <Button
              key={id}
              backgroundColor={'teal'}
              outline
              color="white"
              marginBottom={0.5}
              padding={0.25}
              flexShrink={0}
              onPress={() => {
                if (isChangingPriceLevel === 'activate') {
                  dispatch(setActivePriceLevel(id));
                  dispatch(toggleIsChangingPriceLevel(false));
                } else {
                  dispatch(changePriceLevelOfOrderLine(selectedOrderLines, id));
                  dispatch(clearSelectedOrderLines());
                  dispatch(toggleIsChangingPriceLevel(false));
                }
              }}
            >
              <Text color="white" bold overflow="hidden">
                {name}
              </Text>
            </Button>
            ))}
        </ScrollView>
      </Box>
    </Popup>
  </Box>
);

export default connect((state: State) => ({
  priceLevels: state.items.priceLevels,
  isChangingPriceLevel: state.orders.active.isChangingPriceLevel,
  selectedOrderLines: state.orders.selectedOrderLines,
}))(ChoosePriceLevels);
