// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';

export const getFiscal = (): Promise =>
  fetch(apiEndpoint('v1/fiskal/params_main'), {
    baseURL: getApiUrl(),
  });

export const updateFiscal = (
  mainParams: Object,
  dph: Object
): Promise =>
  fetch(apiEndpoint('v1/fiskal/params_main'), {
    method: 'PATCH',
    baseURL: getApiUrl(),
    body: {
      params: {
        main_params: mainParams,
        dph_levels: dph,
      },
      name: '',
      id: '',
      session_id: '',
      device_id: ''
    }
  });

export const confirmMessage = (): Promise =>
  fetch(apiEndpoint('v1/fiskal/confirm_message'), {
    baseURL: getApiUrl(),
  });

export const setTime = (): Promise =>
  fetch(apiEndpoint('v1/fiskal/set_time'), {
    baseURL: getApiUrl(),
  });

export const reset = (): Promise =>
  fetch(apiEndpoint('v1/fiskal/reset'), {
    baseURL: getApiUrl(),
  });
