const defaultDescriptors = {
  cs: require('../../../messages/cs').default,  // cannot dynamically (`messages/${file}`) because RN
  sk: require('../../../messages/_default').default,
};

const defaultDescriptorsKeys = Object.keys(defaultDescriptors);

const descriptorsToMessages = descriptors =>
  descriptors.reduce(
    (previous, { defaultMessage, id }) => ({
      ...previous,
      [id]: defaultMessage,
    }), {});

const loadMessages = (descriptors = defaultDescriptors) =>
  defaultDescriptorsKeys.map(lang => ({
    descriptors: descriptors[lang],
    locale: lang,
  })).reduce((previous, { descriptors, locale }) => ({
    ...previous,
    [locale]: descriptorsToMessages(descriptors),
  }), {});

export default loadMessages;
