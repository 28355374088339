// @flow
import React from 'react';
import Title from '../../../components/Title';
import Box from '../../../../common/components/Box';
import titlesMessages from '../../../../common/app/messages/titles';
import TopBar from '../../TopBar';
import Heading from '../../../../common/components/Heading';
import remoteLoad, {remoteLoadFromProps} from '../../../../common/components/hoc/remoteLoad';
import api from '../../../../common/lib/api';
import Text from '../../../../common/components/Text';
import Spinner from '../../../../common/components/Spinner';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/fiscal';
import { compose } from 'rambda';

class FiscalConfirmPage extends React.PureComponent {
  render() {
    const {
      remotePayload,
      remoteError,
      remoteIsLoading,
      intl
    } = this.props;

    return (
      <Box flex={1} height="100%" backgroundColor="appBg">
        <Title message={titlesMessages.admin} />

        <Box zIndex={10} flexShrink={0}>
          <TopBar />
        </Box>

        <Box marginTop={4}>
          <Heading scale={4} marginBottom={0.5} align="center" color="white">{intl.formatMessage(messages.confirmPageHeader)}</Heading>

          {remoteIsLoading && (
            <Box paddingTop={4}>
              <Spinner color="white" />
            </Box>
          )}

          {remotePayload && !remoteError &&
            <Text scale={2} color="white" align="center" marginTop={1}>{intl.formatMessage(messages.confirmPageInfo)}</Text>
          }

          {remoteError && <Text color="error" marginTop={1} align="center">{remoteError}</Text>}
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect(),
  remoteLoad(api.fiscal.confirmMessage),
  injectIntl
)(FiscalConfirmPage);
