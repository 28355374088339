// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, filter, map } from 'rambda';
import Box from '../../../components/Box';
import Heading from '../../../components/Heading';
import Button from '../../../components/Button';
import ScrollView from '../../../components/ScrollView';
import { remoteLoadFromProps } from '../../../components/hoc/remoteLoad';
import api from '../../../lib/api/index';
import { isReactNative } from '../../../app/detectPlatform';
import Spinner from '../../../components/Spinner';
import Text from '../../../components/Text';
import { isFiltering } from '../../../order/utils';
import type { State } from '../../../types';
import latinize from 'latinize';
import escapeRegexp from 'escape-string-regexp';
import {injectIntl} from 'react-intl';
import paymentMessages from '../../../messages/payment';


class Rooms extends React.PureComponent {
  _filterRooms = (rooms, filterValue) => {
    if (filterValue === '' || !isFiltering(filterValue)) return rooms;

    rooms = map(
      room => ({
        ...room,
        roomNameDeaccented: latinize(room.type === 'room' ? room.room_name : room.name),
        guestNameDeaccented: latinize(room.type === 'room' ? room.guest_name : '')
      }),
      rooms
    );
    filterValue = latinize(filterValue);

    const regex = RegExp(filterValue, 'i');

    return filter(
        room => regex.test(escapeRegexp(room.roomNameDeaccented))
          || regex.test(escapeRegexp(room.guestNameDeaccented))
          || (room.account_id === +filterValue),
        rooms
      );
  };

  render() {
    const {
      remoteError,
      remoteIsLoading,
      onConfirm,
      hasFilter,
      filterValue = '',
      hotelId,
      intl
    } = this.props;
    let {
      remotePayload: rooms = []
    } = this.props;

    rooms = this._filterRooms(rooms, filterValue);

    return (
      <Box
        width={isReactNative ? null : 20}
        maxHeight={isReactNative ? '100%' : '70vh'}
        marginHorizontal={-1}
        flex={1}
      >
        <Heading scale={2} color="white" marginHorizontal={1}>{intl.formatMessage(paymentMessages.paymentHoresChooseRoom)}</Heading>

        {hasFilter ? hasFilter(hotelId) : null}

        {remoteError ? null : remoteIsLoading
          ? (
            <Box marginTop={4}>
              <Spinner color={isReactNative ? 'gray' : ''} size="large" />
            </Box>
          ) : (
            <Box>
              <ScrollView maxHeight={isReactNative ? '100%' : '50vh'}>
                {rooms.map(({
                  id,
                  type,
                  name,
                  account_id,
                  room_name: room,
                  guest_name: guest,
                  checkin_date: checkIn,
                  checkout_date: checkOut
                }) => (
                  <Button
                    key={id}
                    marginHorizontal={1}
                    backgroundColor="white"
                    paddingVertical={0.5}
                    paddingHorizontal={1}
                    marginBottom={0.5}
                    justifyContent="flex-start"
                    flexShrink={0}
                    onPress={() => onConfirm(id, account_id, type === 'room' ? room : name, type === 'room')}
                  >
                    <Box height="100%">
                      <Text>
                        {type === 'room' ? `${guest}, ${intl.formatMessage(paymentMessages.paymentHoresRoomNumberIs)} ${room}` : `${name}`}
                        {type === 'room' ? `${guest}, izba č. ${room}` : `${name}`}
                      </Text>
                      <Text scale={-2}>{`${checkIn} – ${checkOut}`}</Text>
                    </Box>
                  </Button>
                ))}
              </ScrollView>
            </Box>
          )
        }

        {remoteError ? <Text color="error" marginVertical={1}>{remoteError}</Text> : null}
      </Box>
    );
  }
}

export default compose(
  remoteLoadFromProps(
    api.hores.getRooms,
    ({ hotelId }) => [hotelId]
  ),
  connect((state: State) => ({
    filterValue: state.payment.prgFilterValue
  })),
  injectIntl
)(Rooms);
