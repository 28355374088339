// @flow
import React from 'react';
import Text from '../../common/components/Text';
import { monospaceFamily } from '../../common/themes/typography';
import theme from '../themes/defaultTheme';
import Spinner from '../../common/components/Spinner';
import Box from '../../common/components/Box';
import messages from '../../common/messages/components';
import { FormattedMessage } from 'react-intl';

export default class extends React.PureComponent {
  render() {
    const { data, overflow, isLoading, isSelected = true, ...restProps } = this.props;

    const paddingHorizontal = 2;
    const width = `${theme.typography.fontSize(0, monospaceFamily) * (overflow === 'auto' ? 20 + (paddingHorizontal * 2) : 20)}px`;

    if (isLoading) {
      return (
        <Box width={width} flex={1} {...restProps}>
          <Spinner />
        </Box>
      );
    }

    if (!isSelected) {
      return (
        <Box width={width} flex={1} {...restProps}>
          <Text align="center"><FormattedMessage {...messages.closureChooseLeft} /></Text>
        </Box>
      );
    }

    if (!data) {
      return (
        <Box width={width} flex={1} {...restProps}>
          <Text align="center"><FormattedMessage {...messages.closureNoData} /></Text>
        </Box>
      );
    }

    return (
      <Text
        fontFamily={monospaceFamily}
        whiteSpace="pre"
        width={width}
        overflow={overflow}
        paddingHorizontal={`${theme.typography.fontSize(0, monospaceFamily) * paddingHorizontal}px`}
        {...restProps}
      >
        {data}
      </Text>
    );
  }
}

