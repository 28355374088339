// @flow
import React from 'react';
import { compose, map } from 'rambda';
import { assocPath } from 'ramda';
import Heading from '../../../../common/components/Heading';
import Box from '../../../../common/components/Box';
import ReadOnlyField from '../../../components/ReadOnlyField';
import Button from '../../../../common/components/Button';
import Text from '../../../../common/components/Text';
import Icon from '../../../components/Icon';
import PopupSelectbox from './PopupSelectbox';
import PopupTime from './PopupTime';
import moment from 'moment';
import PopupVatEditor from './PopupVatEditor';
import api from '../../../../common/lib/api/index';
import {
  addAdminError,
  clearAdminError,
  toggleAdminNotificationPopup
} from '../../../../common/admin/general/actions';
import { connect } from 'react-redux';
import remoteLoad from '../../../../common/components/hoc/remoteLoad';
import Spinner from '../../../../common/components/Spinner';
import { processReceivedFiscalData } from '../../../../common/admin/fiscal/utils';
import PopupTextEditor from './PopupTextEditor';
import ScrollView from '../../../../common/components/ScrollView';
import Permission from '../../../../common/permissions/Permission';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/fiscal';

const AdminBox = (props) => (
  <Box flexGrow={1} flexShrink={1} flexBasis="33.333%" backgroundColor="adminPanelBg" borderWidth={2} borderColor="white" borderStyle="solid" padding={1} overflow="hidden">
    {props.children}
  </Box>
);

const labels = intl => ({
  fiskalType: intl.formatMessage(messages.fiscalFormFiscalType),
  fiskalPort: intl.formatMessage(messages.fiscalFormFiscalPort),
  portType: intl.formatMessage(messages.fiscalFormPortType),
  commSpeed: intl.formatMessage(messages.fiscalFormCommSpeed),
  parity: intl.formatMessage(messages.fiscalFormParity),
  databit: intl.formatMessage(messages.fiscalFormDatabit),
  stopbit: intl.formatMessage(messages.fiscalFormStopbit),
  flowManagement: intl.formatMessage(messages.fiscalFormFlowManagement),
});

class FiscalForm extends React.PureComponent {
  state = {
    showTextEditor: false,
    showPopupTime: false,
    showPopupSelectbox: false,
    popupSelectboxType: '',
    showVatEditor: false,
    fiscalData: null,
    isUpdating: false,
  };

  componentWillReceiveProps({ remotePayload }) {
    if (remotePayload !== this.props.remotePayload) {
      this.setState({ fiscalData: processReceivedFiscalData(remotePayload.params.return) });
    }
  }

  updateSelectibleValue = (type, value) => {
    this.setState({ fiscalData: { ...this.state.fiscalData, [type]: value } });
  };

  changeTime = time => {
    this.setState({ fiscalData: { ...this.state.fiscalData, cutOffTime: time } });
  };

  changeVat = value => {
    const { fiscalData } = this.state;
    const updatedFiscalData = assocPath(['dph', `${this.state.showVatEditor}`], +value, fiscalData);
    this.setState({ fiscalData: updatedFiscalData });
  };

  updateFiscal = () => {
    const { dispatch, intl } = this.props;
    const {
      fiscalData: {
        dph,
        cutOffTime,
        logo,
        fiskalType,
        fiskalPort,
        portType,
        commSpeed,
        parity,
        databit,
        stopbit,
        flowManagement
      }
    } = this.state;

    const mainParams = {
      cut_off_time: cutOffTime,
      print_logo: logo,
      fiskal_type: fiskalType,
      fiskal_port: fiskalPort,
      port_type: portType,
      comm_speed: commSpeed,
      parity,
      databit,
      stopbit,
      flow_management: flowManagement
    };

    dispatch(clearAdminError());
    this.setState({ isUpdating: true });

    api.fiscal.updateFiscal(mainParams, dph)
      .then(({ body }) => {
        dispatch(toggleAdminNotificationPopup(intl.formatMessage(messages.fiscalFormUpdated)));

        this.setState({
          isUpdating: false,
          fiscalData: processReceivedFiscalData(body.return)
        });
      })
      .catch(error => {
        dispatch(addAdminError(error));
        this.setState({ isUpdating: false });
      });
  };

  showTextEditor = field => {
    this.setState({ showTextEditor: field });
  };

  hideTextEditor = () => {
    this.setState({ showTextEditor: false });
  };

  changeTextField = field => value => {
    let { fiscalData } = this.state;
    fiscalData = { ...fiscalData, [field]: value };
    this.setState({ fiscalData, showTextEditor: false });
  };

  showPopupTime = () => {
    this.setState({ showPopupTime: true });
  };

  hidePopupTime = () => {
    this.setState({ showPopupTime: false });
  };

  showPopupSelectbox = (popupSelectboxType) => {
    this.setState({ showPopupSelectbox: true, popupSelectboxType });
  };

  hidePopupSelectbox = () => {
    this.setState({ showPopupSelectbox: false });
  };

  showVatEditor = key => {
    this.setState({ showVatEditor: key });
  };
  hideVatEditor = () => {
    this.setState({ showVatEditor: false });
  };

  render() {
    const {
      showPopupTime,
      showPopupSelectbox,
      popupSelectboxType,
      showTextEditor,
      showVatEditor,
      fiscalData,
      updateError,
      isUpdating
    } = this.state;

    const { remoteIsLoading, remoteError, intl } = this.props;

    if (remoteError) {
      return (
        <Box flex={1}>
          <Text color="error" marginVertical={1}>{remoteError}</Text>
        </Box>
      );
    }

    if (remoteIsLoading) {
      return (
        <Box flex={1} marginTop={5} marginBottom={2}>
          <Spinner />
          <Text top="50%" align="center" scale={3}>{intl.formatMessage(messages.fiscalFormLoadingData)}</Text>
        </Box>
      );
    }

    if (!fiscalData) {
      return null;
    }

    return (
      <Box>
        <ScrollView>
          <Box flexDirection="row" flexWrap="wrap">
            <AdminBox>
              <Heading scale={1}>{intl.formatMessage(messages.fiscalFormModuleData)}</Heading>

              {Object.keys(labels(intl)).map(labelKey =>
                <ReadOnlyField label={labels(intl)[labelKey]} labelWidth="40%" valueWidth="60%" marginBottom={0.5} key={labelKey}>
                  <Button
                    outline
                    color="black"
                    backgroundColor="white"
                    padding={0.5}
                    onPress={() => this.showTextEditor(labelKey)}
                  >
                    {`${fiscalData[labelKey]}`}
                  </Button>
                </ReadOnlyField>
              )}
            </AdminBox>

            <AdminBox>
              <Heading scale={1}>{intl.formatMessage(messages.fiscalFormStatistics)}</Heading>

              <ReadOnlyField label={intl.formatMessage(messages.fiscalFormIco)} value={fiscalData.ico} labelWidth="40%" valueWidth="60%" marginBottom={0.5} />

              <ReadOnlyField label={intl.formatMessage(messages.fiscalFormIcdph)} value={fiscalData.icdph} labelWidth="40%" valueWidth="60%" marginBottom={0.5} />

              <ReadOnlyField label={intl.formatMessage(messages.fiscalFormDic)} value={fiscalData.dic} labelWidth="40%" valueWidth="60%" marginBottom={0.5} />

              <ReadOnlyField label={intl.formatMessage(messages.fiscalFormDkp)} value={fiscalData.dkp} labelWidth="40%" valueWidth="60%" marginBottom={0.5} />
            </AdminBox>

            <AdminBox>
              <Heading scale={1}>{intl.formatMessage(messages.fiscalFormVersions)}</Heading>

              <ReadOnlyField label={intl.formatMessage(messages.fiscalFormFiscalDay)} value={fiscalData.fiscalDay} labelWidth="40%" valueWidth="60%" marginBottom={0.5} />

              <ReadOnlyField label={intl.formatMessage(messages.fiscalFormFirmwareVersion)} value={fiscalData.firmwareVersion} labelWidth="40%" valueWidth="60%" marginBottom={0.5} />

              <ReadOnlyField label={intl.formatMessage(messages.fiscalFormAfsVersion)} value={fiscalData.afsVersion} labelWidth="40%" valueWidth="60%" marginBottom={0.5} />
            </AdminBox>

            <AdminBox>
              <Heading marginBottom={0.25}>{intl.formatMessage(messages.fiscalFormDeadline)}</Heading>
              <Box flexDirection="row" overflow="hidden">
                <Button
                  outline
                  color="black"
                  backgroundColor="white"
                  marginBottom={1}
                  padding={0.5}
                  flexGrow={1}
                  flexShrink={1}
                  flexBasis="50%"
                  onPress={this.showPopupTime}
                >
                  {moment(fiscalData.cutOffTime, 'HH:mm:ss').format('LT')}
                </Button>
              </Box>

              <Heading marginBottom={0.25}>{intl.formatMessage(messages.fiscalFormPrintLogo)}</Heading>
              <Button
                outline
                color="black"
                backgroundColor="white"
                marginBottom={1}
                paddingVertical={0.5}
                paddingHorizontal={2}
                onPress={() => this.showPopupSelectbox('logo')}
              >
                <Text color="black" bold>{fiscalData.logo === 1 ? intl.formatMessage(messages.fiscalFormPrint) : intl.formatMessage(messages.fiscalFormNotPrint)}</Text>
                <Icon
                  name="navigation-right"
                  size={1}
                  position="absolute"
                  right={1}
                  style={{ transform: 'translateY(2px)' }}
                />
              </Button>
            </AdminBox>

            <AdminBox>
              <Heading marginBottom={0.25}>{intl.formatMessage(messages.fiscalFormHeaders)}</Heading>

              {fiscalData.mainHeaders.map((header, i) =>
                <Text marginBottom={0.5} key={i}>{header}</Text>
              )}
            </AdminBox>

            <AdminBox>
              <Heading marginBottom={0.25}>{intl.formatMessage(messages.fiscalFormDph)}</Heading>

              {map(key => (
                <Box key={key} flexDirection="row" overflow="hidden" justifyContent="space-between" alignItems="center" marginBottom={0.5}>
                  <Text flexBasis="30%" overflow="hidden">{`DPH ${key}`}</Text>
                  <Text flexBasis="45%" overflow="hidden" bold>{`${fiscalData.dph[key]}%`}</Text>
                  <Button rounded outline color="black" backgroundColor="white" size={2} alignItems="center" onPress={() => this.showVatEditor(key)}>
                    <Icon name="edit" scale={1} />
                  </Button>
                </Box>),
                Object.keys(fiscalData.dph))}
            </AdminBox>

            <Permission name="prndef.change">
              <Box flexGrow={1} flexShrink={1} flexBasis="100%" backgroundColor="adminPanelBg" borderWidth={2} borderColor="white" borderStyle="solid" padding={1} overflow="hidden" marginBottom={1}>
                <Button
                  outline
                  color="teal"
                  backgroundColor="white"
                  paddingVertical={0.5}
                  paddingHorizontal={2}
                  onPress={this.updateFiscal}
                >
                  {isUpdating && <Box marginRight={0.5}><Spinner color="teal" size="small" /></Box>}
                  <Text bold color="teal">
                    {intl.formatMessage(messages.fiscalFormSendChanges)}
                  </Text>
                </Button>

                {updateError && <Text color="error" marginTop={1}>{updateError}</Text>}
              </Box>
            </Permission>
          </Box>
        </ScrollView>

        {showPopupSelectbox &&
          <PopupSelectbox
            type={popupSelectboxType}
            selected={popupSelectboxType === 'state' ? fiscalData.state : fiscalData.logo }
            onClose={this.hidePopupSelectbox}
            onSelect={this.updateSelectibleValue}
          />}
        {showPopupTime &&
          <PopupTime
            time={moment(fiscalData.cutOffTime, 'HH:mm:ss')}
            onClose={this.hidePopupTime}
            onSubmit={this.changeTime}
          />}
        {showVatEditor &&
          <PopupVatEditor
            onClose={this.hideVatEditor}
            onSubmit={this.changeVat}
            defaultValue={fiscalData.dph[`${this.state.showVatEditor}`]}
          />}
        {showTextEditor &&
          <PopupTextEditor
            title={labels(intl)[showTextEditor]}
            onClose={this.hideTextEditor}
            onSubmit={this.changeTextField(showTextEditor)}
            value={`${fiscalData[showTextEditor]}`}
          />}
      </Box>
    );
  }
}

export default compose(
  connect(),
  remoteLoad(api.fiscal.getFiscal),
  injectIntl
)(FiscalForm);
