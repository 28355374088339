// @flow
import React from 'react';
import Box from '../Box';
import Text from '../Text';
import theme from '../../themes/theme';
import Icon from '../Icon';
import Button from '../Button';

type OrderLineHeadingProps = {
  name: string,
  quantity: number,
  isActive: boolean,
  isDisabled: boolean,
  onKitchenReminderPress: any
};

const OrderLineHeading = ({ name, quantity, isActive, isDisabled, onKitchenReminderPress }: OrderLineHeadingProps) => (
  <Box
    flexDirection="row"
    borderStyle="solid"
    borderTopColor={isActive ? 'orderLineHeadingBorderSelected' : 'lightGray'}
    borderBottomColor={isActive ? 'orderLineHeadingBorderSelected' : 'transparent'}
    borderTopWidth={1}
    borderBottomWidth={1}
    backgroundColor={isActive ? 'orderLineHeadingSelected' : 'orderLineBg'}
    width="100%"
    paddingVertical={0.25}
  >
    <Box
      marginRight={0.1}
      flexGrow={1}
      maxWidth={1.75}
      height={1}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      {!isDisabled &&
      <Box
        color="darkGray"
        width={0.85}
        height={0.85}
        alignItems="center"
        justifyContent="center"
        borderWidth={1}
        borderStyle="solid"
        borderColor="darkGray"
        overflow="hidden"
      >
        {isActive
          ? <Box
              width={0.45}
              height={0.45}
              backgroundColor="darkGray"
            />
          : null}
      </Box>
      }
    </Box>
    <Box
      paddingHorizontal={0.5}
      flexGrow={4}
      height={1}
      flexDirection="row"
      justifyContent="space-between"
    >
      <Text bold scale={-1} condensed lineHeight={theme.typography.lineHeight()}>{name}</Text>
      <Box flexDirection="row" position="relative">
        <Box
          width={1}
          height={1}
          alignItems="center"
          justifyContent="center"
          backgroundColor="lightGray"
          marginRight="0px"
          style={{ borderRadius: theme.typography.lineHeight() }}
        >
          <Text color="darkGray" scale={-3} bold condensed>{quantity}</Text>
        </Box>
        <Button
          marginRight="0px"
          marginLeft="10px"
          marginTop="-7px"
          width="31px"
          height="31px"
          alignItems="center"
          justifyContent="center"
          borderColor="darkGray"
          borderWidth={1}
          borderStyle="solid"
          backgroundColor="orderLineBg"
          position="relative"
          zIndex={1}
          style={{ borderRadius: theme.typography.lineHeight() }}
          onPress={e => {
            e.stopPropagation();
            if (onKitchenReminderPress) {
              onKitchenReminderPress();
            }
          }}
        >
          <Icon name="food" color="darkGray" />
        </Button>
      </Box>
    </Box>
  </Box>
);

export default OrderLineHeading;
