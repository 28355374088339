// @flow
import type { State } from '../../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'rambda';
import { AutoSizer, Column, defaultTableCellRenderer } from 'react-virtualized';
import TableSearch from '../../TableSearch';
import SimpleItemsTable, { withInfiniteLoader, withSelectableRows } from '../../Table';
import moment from 'moment';
import Box from '../../../../common/components/Box';
import Text from '../../../../common/components/Text';
import {
  setHistoryPreviewData,
  toggleHistoryClosureId,
  toggleHistoryIsLoading,
  toggleJournalId
} from '../../../../common/admin/closures/actions';
import { addAdminError, initiateAdminTableReload } from '../../../../common/admin/general/actions';
import api from '../../../../common/lib/api';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/closures';

const Table = compose(
  withInfiniteLoader({ rowDataKey: 'idriadok' }),
  withSelectableRows('idriadok'),
)(SimpleItemsTable);

const dateRangeOptions = {
  defaultFrom: moment().startOf('month'),
  defaultTo: moment().endOf('month'),
};

class JournalList extends React.PureComponent {
  state = {
    dateFrom: dateRangeOptions.defaultFrom.format(),
    dateTo: dateRangeOptions.defaultTo.format()
  };

  loadHistory = ({ loadAfterId }) => {
    // const { dispatch } = this.props;
    // const { dateFrom, dateTo } = this.state;
    //
    // return api.closure.getClosures('', { dateFrom, dateTo, loadAfterId })
    //   .then(({ body: { result: { closures } } }) => closures)
    //   .catch(e => {
    //     dispatch(addAdminError(e));
    //   });
  };

  loadDetail = ({ rowData: { idriadok } }) => {
    // const { dispatch } = this.props;
    //
    // dispatch([
    //   toggleHistoryClosureId(idriadok),
    //   toggleHistoryIsLoading(true)
    // ]);
    //
    // api.closure.getClosure(idriadok).then(({ body: { result: { closure: { data } } } }) => {
    //   dispatch([
    //     setHistoryPreviewData(data),
    //     toggleHistoryIsLoading(false)
    //   ]);
    // }).catch(e => {
    //   dispatch([
    //     toggleHistoryIsLoading(false),
    //     addAdminError(e),
    //   ]);
    // });
  };

  setDateRange = (from, to) => {
    const { dispatch } = this.props;
    dispatch(toggleHistoryClosureId(null));

    this.setState({ dateFrom: from.format(), dateTo: to.format() }, () => {
      dispatch(initiateAdminTableReload());
    });
  };

  render() {
    const { selectedJournalId, intl } = this.props;
    const selectedItems = selectedJournalId ? { [selectedJournalId]: true } : undefined;

    return (
      <Box marginTop={1} marginLeft={1}>
        <TableSearch
          enableDateRange
          enableCalendar={false}
          dateRangeOptions={dateRangeOptions}
          onDateRangeChange={this.setDateRange}
        />

        <Box flexBasis="100%" backgroundColor="white" marginBottom={1} maxHeight="100vh" height="9999px">
          <AutoSizer>
            {({ height, width }) => (
              <Table
                width={width}
                height={height}
                onRowClick={this.loadDetail}
                headerClassName="offset-first"
                className="light"
                selectedItems={selectedItems}
                lazyFetch={this.loadHistory}
              >
                <Column
                  label={intl.formatMessage(messages.journalDate)}
                  dataKey="date"
                  width={width}
                  // cellRenderer={props =>
                  //   <Text paddingLeft={0.5} color="inherit">
                  //     {defaultTableCellRenderer(props)}
                  //   </Text>
                  // }
                  // cellDataGetter={({ rowData: { date } }) => moment(date).format('L')}
                />
              </Table>
            )}
          </AutoSizer>
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    selectedJournalId: state.admin.closures.journal.selectedJournalId
  })),
  injectIntl
)(JournalList);
