// @flow
import type { KeyboardButtonsProps } from '../index';
import React from 'react';
import { injectIntl } from 'react-intl';
import Box from '../../Box';
import KbButton from '../StandardButton';
import Icon from '../../../../browser/components/Icon';
import messages from '../messages/buttons';

const Buttons = ({ intl, onPress, showDot = true, showBackspace = false }: KeyboardButtonsProps) => (
  <Box>
    <Box flexDirection="row">
      <KbButton char={7} onPress={onPress} borderRight borderTop />
      <KbButton char={8} onPress={onPress} borderRight borderTop />
      <KbButton char={9} onPress={onPress} borderTop />
    </Box>
    <Box flexDirection="row">
      <KbButton char={4} onPress={onPress} borderRight borderTop />
      <KbButton char={5} onPress={onPress} borderRight borderTop />
      <KbButton char={6} onPress={onPress} borderTop />
    </Box>
    <Box flexDirection="row">
      <KbButton char={1} onPress={onPress} borderRight borderTop />
      <KbButton char={2} onPress={onPress} borderRight borderTop />
      <KbButton char={3} onPress={onPress} borderTop />
    </Box>
    <Box flexDirection="row">
      <KbButton char={0} onPress={onPress} borderRight borderTop size={showDot ? 1 : showBackspace ? 1 : 2} />
      {showDot &&
        <KbButton char={intl.formatMessage(messages.dot)} onPress={onPress} borderRight borderTop sendChar="." />}
      {showBackspace &&
        <KbButton char="backspace" onPress={onPress} borderRight borderTop operator>
          <Icon name="key-delete" scale={4} color="white" justifyContent="center" />
        </KbButton>}
      <KbButton char="ok" onPress={onPress} borderTop primary>
        <Icon name="check" scale={4} color="white" />
      </KbButton>
    </Box>
  </Box>

);

export default injectIntl(Buttons);
