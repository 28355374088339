// @flow
import React from 'react';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import LoginPage from '../auth/LoginPage';
import ServiceAreasPage from '../serviceAreas/ServiceAreasPage';
import OrderPage from '../order/OrderPage';
import PaymentPage from '../payment/PaymentPage';
import AdminRoutingPage from '../admin/AdminRoutingPage';
import LoyalmanUsersPage from '../loyalman/users/LoyalmanUsersPage';
import App from './App';
import history from '../createHistory';
import DeviceConfigurationGuard from '../device/DeviceConfigurationGuard';
import DeviceSetupPage from '../device/DeviceSetupPage';
import AllowedRoute from './AllowedRoute';

const RouterConfig = () => (
  <ConnectedRouter history={history}>
    <App>
      <Switch>
        <Route path="/setup" component={DeviceSetupPage} />
        <DeviceConfigurationGuard>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <AllowedRoute
              path="/serviceAreas"
              component={ServiceAreasPage}
              permission="servicearea.read"
              redirect="/"
            />
            <AllowedRoute
              path="/order"
              component={OrderPage}
              permission="orderline.read"
              redirect="/serviceAreas"
            />
            <AllowedRoute
              path="/payment"
              component={PaymentPage}
              permission="payment.add"
              redirect="/order"
            />
            <Route // TODO
              path="/admin"
              component={AdminRoutingPage}
              // permission=""
              // redirect="/serviceAreas"
            />
            <AllowedRoute
              path="/loyalman"
              component={LoyalmanUsersPage}
              permission="payment.add"
              redirect="/loyalman"
            />
          </Switch>
        </DeviceConfigurationGuard>
      </Switch>
    </App>
  </ConnectedRouter>
);

export default RouterConfig;
