// @flow
import React from 'react';
import Calendar from 'rc-calendar';
import TimePicker from 'rc-time-picker';
import InactiveBg from '../components/InactiveBg';
import Popup from '../components/Popup';
import NumericButtons from '../../common/components/Keyboard/popupWeightForm/Buttons';
import createNumericOperations from '../../common/components/Keyboard/popupWeightForm/operations';
import Keyboard from '../../common/components/Keyboard';
import TextInput from '../../common/components/TextInput';
import Text from '../../common/components/Text';
import Box from '../../common/components/Box';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import moment from 'moment';
import { connect } from 'react-redux';
import type { State } from '../../common/types';
import api from '../../common/lib/api';
import { addError } from '../../common/order/actions';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/payment';

const CustomNumericButtons = props => <NumericButtons {...props} showDot={false} showBackspace />;

class RegisterParagon extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        paragonId: '',
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm:ss')
      },
      defaultDate: moment(),
      defaultTime: moment(),
    };
  }

  NumericKeyboard = (dispatch, refFn, fields, paymentTransactionId) => (
    <Box display="none">
      <Keyboard
        ref={refFn}
        createOperations={createNumericOperations}
        Buttons={CustomNumericButtons}
        onDisplayValue={value => {
          this.setState(
            { fields: { ...this.state.fields, paragonId: value } });
        }}
        onLastKey={key => {
          const { paragonId, date, time } = this.state.fields;

          if (key === 'ok' && !!paragonId) {
            const fullDate = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss').format();

            api.payment.registerParagon(paymentTransactionId, paragonId, fullDate)
              .then(({ body }) => {
                this.closePopup();
              })
              .catch(e => {
                dispatch(addError(e));
              });
          }
        }}
        defaultValue={String(fields.paragonId)}
      />
    </Box>
  );

  InputField = (refFn, keyboard, input, fields) => (
    <Box flexDirection="row" ref={refFn}>
      <TextInput
        flex={1}
        flexGrow={0}
        maxLength={1000}
        padding={1}
        paddingRight={2}
        width={11.86}
        scale={2}
        value={fields.paragonId}
        onKeyPress={({ key }) => {
          this[keyboard].sendKey(key);
        }}
        onKeyDown={({ keyCode }) => {
          if (keyCode === 8) { // backspace
            this[keyboard].sendKey('del');
          }
        }}
        onChange={() => {}}
      />
      <Button
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        paddingHorizontal={0.75}
        alignItems="center"
        onPress={() => {
          this.setState(
            { fields: { ...this.state.fields, paragonId: '' } }
          );
        }}
      >
        <Icon color="black" name="delete" scale={2} />
      </Button>
    </Box>
  );

  closePopup = () => {
    const { toggleParagonPopup } = this.props;
    if (toggleParagonPopup) toggleParagonPopup(false);
  };

  getDate = value => {
    const date = value.format('YYYY-MM-DD');
    this.setState({ fields: { ...this.state.fields, date } });
  };

  getTime = value => {
    const time = value.format('HH:mm:ss');
    this.setState({ fields: { ...this.state.fields, time } });
  };

  render() {
    const { dispatch, paymentTransactionId, intl } = this.props;
    const { fields, defaultDate, defaultTime } = this.state;

    return (
      <InactiveBg onClose={this.closePopup}>
        <Popup onClose={this.closePopup}>
          <Box flexDirection="row" width={41} paddingRight={10} justifyContent="space-between">
            <Box flex={1} marginRight={1.5}>
              <Text bold marginBottom={0.25} color="white">{intl.formatMessage(messages.paymentRegisterParagonId)}</Text>
              {this.InputField(c => this.paragonIdBlock = c, 'paragonIdKeyboard', this.paragonIdBlock, fields)}

              {this.NumericKeyboard(dispatch, c => this.paragonIdKeyboard = c, fields, paymentTransactionId)}
            </Box>


            <Box paddingBottom={2}>
              <Text bold marginBottom={0.25} color="white">{intl.formatMessage(messages.paymentRegisterParagonDate)}</Text>
              <Calendar
                width={2}
                showDateInput={false}
                showToday={false}
                showOk={false}
                mode="date"
                defaultValue={defaultDate}
                onSelect={this.getDate}
              />
            </Box>


            <Box paddingBottom={2} marginLeft={1.5}>
              <Text bold marginBottom={0.25} color="white">{intl.formatMessage(messages.paymentRegisterParagonTime)}</Text>
              <TimePicker
                defaultValue={defaultTime}
                open
                onChange={this.getTime}
                allowEmpty={false}
              />
            </Box>
          </Box>
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    editedRow: state.admin.alcohol.editRow
  })),
  injectIntl
)(RegisterParagon);
