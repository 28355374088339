// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmPageHeader: {
    defaultMessage: 'Potvrdzovanie výzvy',
    id: 'fiscal.confirm.header'
  },
  confirmPageInfo: {
    defaultMessage: 'Výzva bola potvrdená',
    id: 'fiscal.confirm.info'
  },
  fiscalFormFiscalType: {
    defaultMessage: 'Typ',
    id: 'fiscal.form.fiscalType'
  },
  fiscalFormFiscalPort: {
    defaultMessage: 'Port fiškálu',
    id: 'fiscal.form.fiscalPort'
  },
  fiscalFormPortType: {
    defaultMessage: 'Typ portu',
    id: 'fiscal.form.portType'
  },
  fiscalFormCommSpeed: {
    defaultMessage: 'Rýchlosť portu',
    id: 'fiscal.form.commSpeed'
  },
  fiscalFormParity: {
    defaultMessage: 'Parita',
    id: 'fiscal.form.parity'
  },
  fiscalFormDatabit: {
    defaultMessage: 'Databit',
    id: 'fiscal.form.databit'
  },
  fiscalFormStopbit: {
    defaultMessage: 'Stopbit',
    id: 'fiscal.form.stopbit'
  },
  fiscalFormFlowManagement: {
    defaultMessage: 'Flow mng',
    id: 'fiscal.form.flowManagement'
  },
  fiscalFormUpdated: {
    defaultMessage: 'Fiškál aktualizovaný',
    id: 'fiscal.form.updated'
  },
  fiscalFormLoadingData: {
    defaultMessage: 'Načítavajú sa údaje…',
    id: 'fiscal.form.loadingData'
  },
  fiscalFormModuleData: {
    defaultMessage: 'Údaje modulu',
    id: 'fiscal.form.moduleData'
  },
  fiscalFormStatistics: {
    defaultMessage: 'Štatistiky',
    id: 'fiscal.form.statistics'
  },
  fiscalFormIco: {
    defaultMessage: 'IČO',
    id: 'fiscal.form.ico'
  },
  fiscalFormIcdph: {
    defaultMessage: 'IČ DPH',
    id: 'fiscal.form.icdph'
  },
  fiscalFormDic: {
    defaultMessage: 'DIČ',
    id: 'fiscal.form.dic'
  },
  fiscalFormDkp: {
    defaultMessage: 'DKP',
    id: 'fiscal.form.dkp'
  },
  fiscalFormVersions: {
    defaultMessage: 'Verzie',
    id: 'fiscal.form.versions'
  },
  fiscalFormFiscalDay: {
    defaultMessage: 'Fiškál. deň',
    id: 'fiscal.form.fiscalDay'
  },
  fiscalFormFirmwareVersion: {
    defaultMessage: 'Firmware',
    id: 'fiscal.form.firmwareVersion'
  },
  fiscalFormAfsVersion: {
    defaultMessage: 'AFS',
    id: 'fiscal.form.afsVersion'
  },
  fiscalFormDeadline: {
    defaultMessage: 'Hraničný čas uzávierky',
    id: 'fiscal.form.deadline'
  },
  fiscalFormPrintLogo: {
    defaultMessage: 'Tlačiť logo',
    id: 'fiscal.form.printLogo'
  },
  fiscalFormPrint: {
    defaultMessage: 'Tlačiť',
    id: 'fiscal.form.print'
  },
  fiscalFormNotPrint: {
    defaultMessage: 'Netlačiť',
    id: 'fiscal.form.notprint'
  },
  fiscalFormHeaders: {
    defaultMessage: 'Hlavičky',
    id: 'fiscal.form.headers'
  },
  fiscalFormDph: {
    defaultMessage: 'Sadzba DPH',
    id: 'fiscal.form.dph'
  },
  fiscalFormSendChanges: {
    defaultMessage: 'Odoslať zmeny',
    id: 'fiscal.form.sendChanges'
  },
  fiscalModuleData: {
    defaultMessage: 'Údaje fiškálneho modulu',
    id: 'fiscal.module.data'
  },
  fiscalModuleDeadline: {
    defaultMessage: 'Hraničný čas uzávierky',
    id: 'fiscal.module.deadline'
  },
  fiscalModuleConfirmTime: {
    defaultMessage: 'Potvrdiť čas',
    id: 'fiscal.module.confirmTime'
  },
  fiscalModuleEditDph: {
    defaultMessage: 'Úprava DPH (v %)',
    id: 'fiscal.module.editDph'
  },
  fiscalResetTitle: {
    defaultMessage: 'Reset fiškálu',
    id: 'fiscal.reset.title'
  },
  fiscalResetInfo: {
    defaultMessage: 'Fiškál bol resetovaný',
    id: 'fiscal.reset.info'
  },
  fiscalTimeTitle: {
    defaultMessage: 'Nastavovanie času',
    id: 'fiscal.time.title'
  },
  fiscalTimeInfo: {
    defaultMessage: 'Čas bol nastavený',
    id: 'fiscal.time.info'
  }
});
