// @flow
import React from 'react';

export default (runGuard: Function) => BaseComponent =>
  class GuardHoc extends React.PureComponent {
    render() {
      let passed = true;
      try {
        passed = runGuard(this.props);
        if (passed === undefined) {
          passed = true;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        passed = false;
      }

      if (!passed) {
        // TODO: add support for custom action
        return null;
      }

      return <BaseComponent {...this.props} />;
    }
  };
