// @flow
import BaseRoot from './BaseRoot';
import React from 'react';
import Router from './routeConfig';
import { injectDispatchToPeripherals } from '../peripherals/service';
import { configure as configureFetch } from '../../common/lib/fetch';
import { signOutOn401 } from '../../common/lib/fetch/misc';
import { configurePermissionsService } from '../../common/permissions/service';
import { configureParametersService } from '../../common/parameters/service';

type RootProps = {
  renderArgs: Object,
  store: Object,
};

class Root extends React.PureComponent<RootProps> {
  componentWillMount() {
    const { store: { dispatch, getState } } = this.props;

    configureFetch(null, signOutOn401(dispatch));
    configurePermissionsService(getState);
    configureParametersService(getState);
    injectDispatchToPeripherals(dispatch);
  }

  render() {
    const { store } = this.props;

    return (
      <BaseRoot store={store} RenderComponent={Router} />
    );
  }
}

export default Root;
