// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  getAlcoholError: {
    defaultMessage: 'Error pri získavní zoznamu alkoholu: ',
    id: 'alcohol.errors.get.alcohol'
  },
  getEanError: {
    defaultMessage: 'Error pri získavaní eanov: ',
    id: 'alcohol.errors.get.ean'
  },
  addAlcoholError: {
    defaultMessage: 'Error pri pridávaní alkoholu: ',
    id: 'alcohol.errors.add.alcohol'
  },
  editAlcoholError: {
    defaultMessage: 'Error pri editovaní alkoholu: ',
    id: 'alcohol.errors.edit.alcohol'
  },
  deleteAlcoholError: {
    defaultMessage: 'Error pri mazaní alkoholu: ',
    id: 'alcohol.errors.delete.alcohol'
  },
  description: {
    defaultMessage: 'Popis',
    id: 'alcohol.description'
  },
  count: {
    defaultMessage: 'Počet',
    id: 'alcohol.count'
  },
  ean: {
    defaultMessage: 'EAN',
    id: 'alcohol.ean'
  },
  author: {
    defaultMessage: 'Autor',
    id: 'alcohol.author'
  },
  date: {
    defaultMessage: 'Dátum',
    id: 'alcohol.date'
  },
  finding: {
    defaultMessage: '...hľadám...',
    id: 'alcohol.finding'
  },
  eanNotFound: {
    defaultMessage: 'daný ean kód sa nenašiel',
    id: 'alcohol.eanNotFound'
  },
  itemAdded: {
    defaultMessage: 'Položka pridaná.',
    id: 'alcohol.itemAdded'
  },
  itemEdited: {
    defaultMessage: 'Položka upravená.',
    id: 'alcohol.itemEdited'
  },
  sidebarEdit: {
    defaultMessage: 'Upraviť',
    id: 'alcohol.sidebar.edit'
  },
  sidebarDelete: {
    defaultMessage: 'Zmazať',
    id: 'alcohol.sidebar.delete'
  },
  sidebarAdd: {
    defaultMessage: 'Pridať',
    id: 'alcohol.sidebar.add'
  },
  sidebarNotificationDeleted: {
    defaultMessage: 'Položka vymazaná.',
    id: 'alcohol.sidebar.notification.deleted'
  },
  sidebarDeleteItems: {
    defaultMessage: 'Vymazanie položiek',
    id: 'alcohol.sidebar.deleteItems'
  },
  sidebarConfirmMsgDeleteItems: {
    defaultMessage: 'Označené položky budú odstránené, chcete ich naozaj vymazať?',
    id: 'alcohol.sidebar.confirmMsgDeleteItems'
  },
  sidebarDeleteYes: {
    defaultMessage: 'Áno',
    id: 'alcohol.sidebar.deleteYes'
  },
  sidebarDeleteCancel: {
    defaultMessage: 'Zrušiť',
    id: 'alcohol.sidebar.deleteCancel'
  },
});
