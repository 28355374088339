// @flow
import React from 'react';
import { connect } from 'react-redux';
import Popup from '../../components/Popup';
import Heading from '../../../common/components/Heading';
import { replace } from 'connected-react-router';
import createTypewriterOperations from '../../../common/components/Keyboard/typewriter/operations';
import TypewriterDisplay from '../../../common/components/Keyboard/typewriter/Display';
import TypewriterButtons from '../../../common/components/Keyboard/typewriter/Buttons';
import Keyboard from '../../../common/components/Keyboard';
import Box from '../../../common/components/Box';
import Icon from '../../../common/components/Icon';
import Button from '../../../common/components/Button';
import Text from '../../../common/components/Text';
import Divider from '../../../common/components/Divider';
import { addAdminError, toggleAdminNotificationPopup } from '../../../common/admin/general/actions';
import api from '../../../common/lib/api';
import PrintersList from '../../../common/components/PrintersList';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/message';

class MessageBox extends React.PureComponent {
  state = {
    isKeyboardOn: true,
    messageToKitchen: ''
  };

  goToSelectPrinterStep = (messageToKitchen) => {
    this.setState({ isKeyboardOn: false, messageToKitchen });
  };

  goToKeyboardStep = () => {
    this.setState({ isKeyboardOn: true });
  };

  printMessage = (printerNumber) => {
    const { dispatch, intl } = this.props;
    const { messageToKitchen } = this.state;

    api.kitchenMessage.printKitchenMessage(printerNumber, messageToKitchen)
      .then(({ body }) => {
        // TODO - check if everything ok, update data accordingly
        dispatch([
          toggleAdminNotificationPopup(intl.formatMessage(messages.messagesKitchenSend)),
          replace('/serviceAreas')
        ]);
      })
      .catch(e => {
        console.error(intl.formatMessage(messages.messagesKitchenSendError), e);
        dispatch(addAdminError(e));
      });
  };

  keyboardStep = () => (
    <Keyboard
      createOperations={createTypewriterOperations}
      Buttons={TypewriterButtons}
      Display={TypewriterDisplay}
      onLastKey={(key, value) => {
        if (key === 'send') {
          this.goToSelectPrinterStep(value);
        }
      }}
      defaultValue={this.state.messageToKitchen}
    />
  );

  selectPrinterStep = () => (
    <PrintersList onSelect={this.printMessage} />
  );

  bottomInfoRow = () => (
    <Box
      width="100%"
      height={3.5}
      backgroundColor="popupBg"
      justifyContent="center"
      alignItems="flex-end"
      borderBottomLeftRadius="normal"
      borderBottomRightRadius="normal"
      paddingHorizontal={1.75}
    >
      <Box width="100%" position="absolute" top={0} left={0} paddingHorizontal={1}>
        <Divider color="white" width="100%" style={{ opacity: 0.2 }} />
      </Box>
      {this.state.isKeyboardOn
        ? <Text scale={3} color="white">1/2</Text>
        : (
          <Box width="100%" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Button
                width={2}
                height={2}
                alignItems="center"
                justifyContent="center"
                flexShrink={0}
                backgroundColor="white"
                style={theme => ({ borderRadius: theme.typography.lineHeight() })}
                onPress={() => this.goToKeyboardStep()}
              >
                <Icon name="arrow-up" color="popupBg" scale={2} style={{ transform: 'rotate(-90deg)' }} />
              </Button>
              <Text scale={3} color="white">2/2</Text>
          </Box>
        )}
    </Box>
  );

  render() {
    const { isKeyboardOn } = this.state;
    const { intl } = this.props;

    return (
      <Popup showClose={false}>
        <Heading scale={2} color="white">
          {isKeyboardOn ? intl.formatMessage(messages.messagesKitchenCreate)
            : intl.formatMessage(messages.messagesKitchenPrinter)}
        </Heading>

        <Box backgroundColor="popupBg" padding={1} paddingTop={0} width={44} height={23}>
          {isKeyboardOn
            ? this.keyboardStep()
            : this.selectPrinterStep()}
        </Box>

        {this.bottomInfoRow()}
      </Popup>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(MessageBox);
