// @flow
import React from 'react';
import { connect } from 'react-redux';
import { filter, contains, find, propEq, map, compose } from 'rambda';
import Box from '../components/Box';
import Text from '../components/Text';
import Button from '../components/Button';
import Heading from '../components/Heading';
import ScrollView from '../components/ScrollView';
import type { OrderLine } from '../types';
import { ApiError } from '../lib/fetch/errors';
import {
  addError,
  clearError as clearOrderError,
  setQuickPaymentMediaId,
  terminalPaymentSocketMessageLoading,
  toggleQuickPaymentLoading,
  toggleTerminalPaymentLoading
} from '../order/actions';
import Spinner from '../components/Spinner';
import { isReactNative } from '../app/detectPlatform';
import api from '../lib/api';
import { updatePaymentTransaction } from './actions';
import { processReceivedPaymentTransactionData } from './utils';
import { RequestOrderError } from '../lib/fetch/orderKeeper';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/payment';
import { createTerminalLoadingSocket } from '../lib/api/payment';
import {findParamValue} from '../parameters/utils';

const getCompletePayment = (id, parameters, paymentTypes, paymentMedia) => [1, 2, 3]
  .map(el => findParamValue(`K32.objednavka_tlacitko${el}`, parameters))
  .map(el => el ? find(propEq('nazev', el), paymentTypes) : undefined)
  .filter(el => el !== undefined)
  .filter(el => find(propEq('druhyPlId', el.idriadok), paymentMedia))
  .map(el => ({ ...el, ...find(propEq('druhyPlId', el.idriadok), paymentMedia) }))
  .find(el => el.id === id);

const hasTerminalPayment = (paymentMediaId, parameters, paymentTypes, paymentMedia) => {
  if (paymentMediaId) {
    const payment = getCompletePayment(paymentMediaId, parameters, paymentTypes, paymentMedia);
    if (payment && payment.terminal) {
      return true;
    }
  }
  return false;
};

export const conflictStatusCode = 409;

export const newPaymentTransaction = (dispatch, selectedOrderLines, onSuccess?) =>
  (conflictError: ApiError) => {
    const { body: { details: [{ id: paymentTransactionId }] } } = conflictError;

    return api.payment.closePayTransaction(paymentTransactionId)
      .then(() => api.payment.openPayTransaction(selectedOrderLines))
      .then(({ body }) => {
        dispatch([
          clearOrderError(),
          updatePaymentTransaction(processReceivedPaymentTransactionData(body)),
        ]);

        if (onSuccess) {
          onSuccess();
        }
      }).catch(error => {
        if (error.status === conflictStatusCode) {
          dispatch([
            clearOrderError(),
            addError(error)
          ]);
        } else {
          throw error;
        }
      });
  };

export const newQuickPaymentTransaction = (
    dispatch,
    selectedOrderLines,
    paymentMediaId,
    sessionId,
    printerUrl,
    parameters,
    paymentTypes,
    paymentMedia,
    onSuccess?
  ) => (conflictError: ApiError) => {
    const { body: { details: [{ id: paymentTransactionId, additionalData }] } } = conflictError;

    const isTerminalPay = hasTerminalPayment(paymentMediaId, parameters, paymentTypes, paymentMedia);

    if (isTerminalPay) {
      dispatch(toggleTerminalPaymentLoading(isTerminalPay));
    }

    let terminalSocket = null;
    if (isTerminalPay){
      const onMessage = message => {
        dispatch(terminalPaymentSocketMessageLoading(message));
      };

      terminalSocket = createTerminalLoadingSocket(sessionId, printerUrl, onMessage);
    }

    const closeTerminalSocket = () => {
      if (terminalSocket){
        terminalSocket.close();
      }
    };

    return api.payment.closePayTransaction(paymentTransactionId)
      .then(() => api.payment.quickPayment(selectedOrderLines, paymentMediaId, undefined, undefined, additionalData))
      .then(() => {
        closeTerminalSocket();
        dispatch([
          clearOrderError(),
          toggleQuickPaymentLoading(false),
          toggleTerminalPaymentLoading(false),
          setQuickPaymentMediaId(null)
        ]);

        if (onSuccess) {
          onSuccess();
        }
      }).catch(error => {
        closeTerminalSocket();

        if (error instanceof RequestOrderError) throw error;

        dispatch([
          toggleQuickPaymentLoading(false),
          toggleTerminalPaymentLoading(false)
        ]);

        if (error.status === conflictStatusCode) {
          dispatch([
            clearOrderError(),
            addError(error)
          ]);
        } else {
          throw error;
        }
      });
  };

type Props = {
  error: ApiError,
  orderLines: (OrderLine | { menuName: string })[],
  onCancel: Function,
  onNewTransaction: () => Promise
}

class TransactionErrorPrompt extends React.PureComponent<Props> {
  state = { loading: false, error: null };

  cancel = () => {
    const { dispatch, onCancel } = this.props;
    dispatch(clearOrderError());
    onCancel();
  };

  newTransaction = () => {
    const { onNewTransaction, error } = this.props;

    this.setState({ loading: true });

    onNewTransaction(error).catch(error => {
      if (error instanceof RequestOrderError) return;

      this.setState({ error, loading: false });
    });
  };

  render() {
    if (!this.props.error) return <Box flex={1} />;

    const {
      error: { body: { details: [{ employee }] } },
      orderLines = [],
      intl
    } = this.props;

    const { loading, error } = this.state;

    return (
      <Box flex={1}>
        <Heading color="white" scale={2}>{intl.formatMessage(messages.paymentTransactionErrorHeader)}</Heading>

        <Text color="white">{`${intl.formatMessage(messages.paymentTransactionErrorWaiter)}: ${employee}`}</Text>
        <Text color="white">{`${intl.formatMessage(messages.paymentTransactionErrorItems)}:`}</Text>

        <Box paddingLeft={1} flex={1} {...(isReactNative ? null : { maxHeight: 15 })}>
          <ScrollView>
            {orderLines.map(({ id, menuName }) => <Text key={id} color="white" flexShrink={0}>– {menuName}</Text>)}
          </ScrollView>
        </Box>

        {error ? <Text color="error">{error.toString()}</Text> : null}

        <Button
          outline
          color="teal"
          backgroundColor="white"
          marginTop={1}
          padding={1}
          onPress={this.cancel}
          disabled={loading}
        >
          {intl.formatMessage(messages.paymentTransactionErrorBack)}
        </Button>
        <Button
          outline
          backgroundColor="teal"
          marginTop={1}
          padding={1}
          color="white"
          onPress={this.newTransaction}
          disabled={loading}
        >
          {loading && <Box marginRight={0.5}><Spinner className="small" color="white" /></Box>}
          <Text color="white" bold>{intl.formatMessage(messages.paymentTransactionErrorCancelAndCreate)}</Text>
        </Button>
      </Box>
    );
  }
}

export default compose(
  connect(
    (state: State) => {
      const errors = state.orders.error || [];

      if (!errors.length) return null;

      const error = errors[0];
      const { body: { details: [{ orderlines: orderLinesId }] } } = error;
      const orderLines = filter(({ id }) => contains(id, orderLinesId), state.orders.orderLines);

      const items = state.items.items;

      const orderLinesWithNames = map(
        orderLine => {
          const item = find(propEq('id', orderLine.itemId), items);
          return item ? { ...orderLine, menuName: item.menuName } : orderLine;
        },
        orderLines
      );

      return ({
        error,
        orderLines: orderLinesWithNames,
      });
    }
  ),
  injectIntl
)(TransactionErrorPrompt);
