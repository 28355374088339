// @flow
import type { Action, Id } from '../types';

export const setActivePriceLevel = (activePriceLevelId: Id): Action => ({
  type: 'PRICE_LEVEL_ACTIVATE',
  payload: { activePriceLevelId }
});

export const updateItemAvailabilityLocalDiff = (diffs: Object<Id, number>) => ({
  type: 'ITEMS_UPDATE_ITEM_AVAILABILITY_LOCAL_DIFF',
  payload: {
    diffs
  }
});

export const clearItemAvailabilityLocalDiff = (itemId: Id) => ({
  type: 'ITEMS_UPDATE_ITEM_AVAILABILITY_CLEAR_LOCAL_DIFF',
  payload: {
    itemId,
  }
});

export const incrementItemAvailability = (diffs: Object<Id, number>) => ({
  type: 'ITEMS_INCREMENT_ITEM_AVAILABILITY',
  payload: {
    diffs
  }
});

export const resetItemAvailability = (itemId: Id, availability: number, resetAt: string) => ({
  type: 'ITEMS_RESET_ITEM_AVAILABILITY',
  payload: {
    itemId,
    availability,
    resetAt
  }
});
