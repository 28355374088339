// @flow
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../common/components/Box';
import Popup from '../components/Popup';
import Keyboard from '../../common/components/Keyboard';
import TypewriterButtons from '../../common/components/Keyboard/typewriter/Buttons';
import Display from '../../common/components/Keyboard/typewriter/OneLineDisplay';
import createTypewriterOperations from '../../common/components/Keyboard/typewriter/operations';
import api from '../../common/lib/api';
import { addError } from '../../common/order/actions';
import { paymentOperationsName } from '../../common/lib/api/payment';
import orderKeeper, { RequestOrderError } from '../../common/lib/fetch/orderKeeper';
import InactiveBg from '../components/InactiveBg';
import Heading from '../../common/components/Heading';
import { toggleSendReceiptByEmailPopup } from '../../common/payment/actions';
import type { State } from '../../common/types';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/payment';


class SendReceiptByEmailPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  reqOrderKeeper = orderKeeper(paymentOperationsName);

  _onClose = () => {
    const { dispatch } = this.props;
    dispatch(toggleSendReceiptByEmailPopup(false));
  };

  sendMail = () => {
    const { dispatch, isSendReceiptByEmailPopupOpen: { paymentTransactionId, payFn } } = this.props;
    const { email } = this.state;

    this.reqOrderKeeper(() =>
      api.payment.sendPaymentReceiptByEmail(paymentTransactionId, email)
    ).then(({ body }) => {
      this._onClose();
      payFn();
    }).catch(e => {
      if (e instanceof RequestOrderError) return;

      dispatch([
        addError(e),
        toggleSendReceiptByEmailPopup(false)
      ]);
    });
  };

  render() {
    const { intl } = this.props;

    return (
      <InactiveBg onClose={this._onClose}>
        <Popup onClose={this._onClose}>
          <Heading scale={4} color="white" marginBottom={1}>{intl.formatMessage(messages.paymentSendReceiptByEmail)}</Heading>
          <Box alignItems="center">
            <Keyboard
              zIndex={2}
              style={theme => ({
                width: theme.typography.lineHeight(3) * 1.75 * 11 + theme.typography.lineHeight() * 7.5
              })}
              createOperations={createTypewriterOperations}
              Buttons={TypewriterButtons}
              Display={Display}
              onLastKey={key => {
                if (key === 'send') {
                  this.sendMail();
                }
              }}
              onDisplayValue={val => {
                this.setState({ email: val });
              }}
            />
          </Box>
        </Popup>
      </InactiveBg>);
  };
}

export default compose(
  connect(
    (state: State) => ({
      isSendReceiptByEmailPopupOpen: state.payment.isSendReceiptByEmailPopupOpen,
    })
  ),
  injectIntl
)(SendReceiptByEmailPopup);
