// @flow
import type { State } from '../../common/types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

const common = () =>
  (WrappedComponent: Function) => {
    const Common = (
      {
        intl: { defaultLocale, currentLocale, messages },
        ...props
      }: any,
    ) => {
      moment.locale(currentLocale);

      return (
        <IntlProvider
          defaultLocale={defaultLocale}
          key={currentLocale} // github.com/yahoo/react-intl/issues/234#issuecomment-163366518
          locale={currentLocale}
          messages={messages}
        >
          <WrappedComponent {...props} />
        </IntlProvider>
      );
    };
    return connect((state: State) => ({
      intl: state.intl,
    }))(Common);
  };

export default common;
