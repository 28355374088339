// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  loyalmanOperationInProgress: {
    defaultMessage: 'Prebieha operácia s účtami',
    id: 'loyalman.operationInProgress'
  },
  loyalmanConfirmUser: {
    defaultMessage: 'Potvrdiť používateľa',
    id: 'loyalman.confirmUser'
  },
  loyalmanGetCard: {
    defaultMessage: 'Vydať novú kartu (pre vybraného)',
    id: 'loyalman.getCard'
  },
  loyalmanCancel: {
    defaultMessage: 'Zrušiť',
    id: 'loyalman.cancel'
  },
  loyalmanUserName: {
    defaultMessage: 'Meno',
    id: 'loyalman.user.name'
  },
  loyalmanUserPhone: {
    defaultMessage: 'Telefón',
    id: 'loyalman.user.phone'
  },
  loyalmanUserEmail: {
    defaultMessage: 'Email',
    id: 'loyalman.user.email'
  },
  loyalmanUserDiscountPackages: {
    defaultMessage: 'Balíky',
    id: 'loyalman.user.discountPackages'
  },
  loyalmanUserCards: {
    defaultMessage: 'Karty',
    id: 'loyalman.user.cards'
  },
  loyalmanUserExp: {
    defaultMessage: 'Exp',
    id: 'loyalman.user.exp'
  },
  loyalmanUserBlocked: {
    defaultMessage: 'Blokovaná',
    id: 'loyalman.user.blocked'
  },
  loyalmanUserTransactions: {
    defaultMessage: 'Transakcie',
    id: 'loyalman.user.transactions'
  },
  loyalmanUserDetailCard: {
    defaultMessage: 'Pre zobrazenie detailu je nutné zadať správne číslo karty.',
    id: 'loyalman.user.detailCard'
  },
  loyalmanUserValidTo: {
    defaultMessage: 'Používateľ platný do',
    id: 'loyalman.user.validTo'
  },
  loyalmanUserCard: {
    defaultMessage: 'Karta',
    id: 'loyalman.user.card'
  },
  loyalmanUserExpire: {
    defaultMessage: 'expiruje',
    id: 'loyalman.user.expire'
  },
  loyalmanUserCredit: {
    defaultMessage: 'Kredit',
    id: 'loyalman.user.credit'
  },
  loyalmanUserBonus: {
    defaultMessage: 'Bonus',
    id: 'loyalman.user.bonus'
  },
  loyalmanUserPrepaidProducts: {
    defaultMessage: 'Predplatené produkty',
    id: 'loyalman.user.prepaidProducts'
  },
});
