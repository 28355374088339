// @flow
import React from 'react';
import Text from '../Text';
import Button from '../Button';
import { isReactNative } from '../../app/detectPlatform';

const StandardButton = ({
  char,
  sendChar,
  onPress,
  borderTop,
  borderBottom,
  borderLeft,
  borderRight,
  size = 1,
  operator = false,
  primary = false,
  disabled,
  children,
  heighMultiplier = 1
}) => (
  <Button
    backgroundColor={(operator && 'keyboardButtonHighlight') || (primary && 'buttonTeal') || 'keyboardButton'}
    borderColor="keyboardButtonBorder"
    borderStyle="solid"
    borderTopWidth={borderTop ? 1 : 0}
    borderBottomWidth={borderBottom ? 1 : 0}
    borderLeftWidth={borderLeft ? 1 : 0}
    borderRightWidth={borderRight ? 1 : 0}
    alignItems="center"
    margin="0"
    flexShrink={0}
    style={theme => isReactNative
      ? ({
          width: '25%',
          height: theme.typography.lineHeight(5) * 2 * heighMultiplier,
          paddingLeft: 0,
          paddingRight: 0
        })
      : ({
          width: theme.typography.lineHeight(5) * 1.75 * size,
          height: theme.typography.lineHeight(5) * 1.75 * heighMultiplier,
          paddingLeft: 0,
          paddingRight: 0
        })
    }
    felaStyle={theme => isReactNative ? ({}) : ({
      ':active': {
        backgroundColor: ((primary && theme.colors.buttonTealActive) ||
          (operator && theme.colors.keyboardButtonHighlightActive) ||
          theme.colors.keyboardButtonActive) + ' !important'
      }
    })}
    onPress={() => onPress(sendChar === undefined ? char : sendChar)}
    disabled={disabled}
  >
    {children
    ||
      <Text scale={5} thin justifyContent="center" color={(operator || primary) ? 'white' : 'black'}>{char}</Text>
    }
  </Button>
);

export default StandardButton;
