// @flow
import type { Operations } from '../index';

const operations: Operations = (saveChanges) => ({
  sendKey(store, key, val) {
    // eslint-disable-next-line default-case
    switch (key) {
      case 1: case '1':
      case 2: case '2':
      case 3: case '3':
      case 4: case '4':
      case 5: case '5':
      case 6: case '6':
      case 7: case '7':
      case 8: case '8':
      case 9: case '9':
      case 0: case '0':
        // TODO: keep only isReset(), rm other conditions
      if (!store.isFirstZero() && (!store.curInput() || store.curInput() === '0' || store.getLastKey() === 'ok' || store.isReset())) {
        store.setNewInput(`${key}`);
      } else {
        store.setNewInput(`${store.curInput()}${key}`);
      }
      store.setLastKey(`${key}`);
      break;

      case ',':
        key = '.';
        // eslint-disable-next-line no-fallthrough
      case '.': {
        const lastGroup = store.curInput().split(' ').pop();
        if (store.getLastKey() !== 'ok' && lastGroup.includes(key)) {
          return;
        }

        if (store.isReset() || !['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(store.getLastKey())) {
          key = '0.';
        }

        if (store.getLastKey() === 'c' || store.getLastKey() === 'ok') {
          store.setNewInput(key);
        } else {
          store.setNewInput(`${store.curInput()}${key}`);
        }
        store.setLastKey(`${key}`);
        break;
      }

      case 'backspace':
      case 'del': {
        const curInput = store.curInput();
        if (!curInput.length) return;

        store.setNewInput(curInput.slice(0, -1));
        store.setLastKey(store.getLastKey(1));

        break;
      }

      case 'cancel': {
        store.setLastKey(key);
        store.setNewInput('');
        break;
      }

      // fixme (replace with something more sophisticated)
      case 'setTo1': {
        store.setLastKey(key);
        store.setNewInput('1');
        store.setReset(true);
        break;
      }

      case 'ok': {
        store.setLastKey(key);
        break;
      }

      case 'setMax': {
        store.setLastKey(key);
        store.setNewInput(`${val}`);
        break;
      }
    }

    saveChanges(store);
  }
});

export default operations;
