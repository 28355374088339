// @flow
import { createSelector } from 'reselect';
import { pendingNetworkOperation as pendingPaymentOperation, pendingTerminalOperation } from '../payment/selectors';
import { pendingOrderConfirmation } from '../order/selectors';
import commonMessages from '../messages/common';

const disconnected = (state: State) =>  ({
    isActive: !state.network.isConnected,
    text: state.intl.useIntl.formatMessage(commonMessages.commonApplicationServerNotAvailable)
  });

const syncAvailable = (state: State) => ({
  isActive: state.auth.syncAvailable,
  text: state.intl.useIntl.formatMessage(commonMessages.commonApplicationServerSyncAvailable)
});

export const networkNotificationsSelector = createSelector(
  disconnected,
  pendingPaymentOperation,
  pendingOrderConfirmation,
  pendingTerminalOperation,
  syncAvailable,
  (disconnected, paymentOperation, ordersOperation, pendingTerminalOperation, syncAvailable) => {
    const notifications = {
      disconnected,
      paymentOperation,
      ordersOperation,
      pendingTerminalOperation,
      syncAvailable
    };

    return notifications;
  }
);
