// @flow
import React from 'react';
import Box from '../../Box';
import { clone } from 'ramda';

type Operations = {|
  sendKey: (store: any, key: any) => void
|}

type KeyboardProps = {
  Buttons: () => React.Component,
  Display?: () => React.Component,
  createOperations: () => Operations,
  onLastKey?: any => {},
  onDisplayValue?: any => {},
  defaultValue?: string,
  discountTypeValue?: number,
  intlDot?: string,
}

class Keyboard extends React.Component {
  props: KeyboardProps;

  store = {
    activeInput: 0,
    input: ['', ''],
    memory: [[], []],
    lastKeys: [[], []],

    getLastKey(num = 0) {
      return this.lastKeys[this.activeInput].length ? this.lastKeys[this.activeInput][this.lastKeys[this.activeInput].length - num - 1] : '';
    },

    setLastKey(key) {
      this.lastKeys[this.activeInput].push(key);
    },

    curInput() {
      return this.input[this.activeInput];
    },

    activateInput(value) {
      this.setState({ activeInput: value });
    },

    activateInputAndPress(value, key) {
      this.setState(
        { activeInput: value },
        () => this._sendKey(key)
      );
    },

    curInputFormatted() {
      return this.input;
    },

    curMemories() {
      return this.memory[this.activeInput].filter((m) => m !== undefined);
    },

    saveMemory(input) {
      if (this.canSaveMemory) {
        this.memory[this.activeInput].push(input);
      }
      this.canSaveMemory = false;
    },

    setNewInput(str) {
      const curInput = str;
      const oldInput = this.curInput();

      if (oldInput !== curInput) {
        this.input[this.activeInput] = curInput;
        this.canSaveMemory = true;
      }
    }
  };

  constructor(props) {
    super(props);
    this.state = this.store;

    if (props.defaultValue) {
      this.state.input = props.defaultValue;
    }

    this.operations = props.createOperations(this._commitState.bind(this));

    this.activateInput = this.state.activateInput.bind(this);
    this.activateInputAndPress = this.state.activateInputAndPress.bind(this);
    this._sendKey = this._sendKey.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props !== nextProps) || (this.state !== nextState);
  }

  componentWillUpdate(nextProps, nextState) {
    const { onDisplayValue, onLastKey, onMemoryChange } = this.props;

    if (onDisplayValue && (this.state.curInputFormatted() !== nextState.curInputFormatted())) {
      onDisplayValue(nextState.curInputFormatted());
    }

    if (onLastKey && (this.state.lastKeys[this.state.activeInput].length !== nextState.lastKeys[this.state.activeInput].length)) {
      onLastKey(nextState.getLastKey());
    }

    if (onMemoryChange && (this.state.memory.length !== nextState.memory.length)) {
      onMemoryChange(nextState.curMemories());
    }

    this.operations = nextProps.createOperations(this._commitState.bind(this));
  }

  _commitState(newState) {
    this.setState(clone(newState));
  }

  _sendKey(key) {
    this.operations.sendKey(clone(this.state), key);
  }

  render() {
    const { createOperations, defaultValue, onLastKey, onDisplayValue, onMemoryChange,
      Buttons, Display, discountTypeValue, intlDot, ...restProps
    } = this.props;

    return (
      <Box {...restProps} flexShrink={0}>
        <Box>
          {!!Display &&
            <Display
              value={this.state.curInputFormatted()}
              onPress={this._sendKey}
              activeInput={this.state.activeInput}
              activateInput={this.activateInput}
              activateAndPress={this.activateInputAndPress}
              discountTypeValue={discountTypeValue}
            />
          }
        </Box>
        <Box>
          <Buttons
            onPress={this._sendKey}
            activeInput={this.state.activeInput}
            intlDot={intlDot}
          />
        </Box>
      </Box>
    );
  }
}

export default Keyboard;
