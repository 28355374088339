// @flow
import type { Action } from '../../types';
import { assocPath, dissocPath } from 'ramda';

const initialState = {
  alcohols: [],
  selectedAlcoholsId: {},
  editRow: null
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'ADMIN_ALCOHOL_TOGGLE_ID': {
      const id = `${action.payload.data.id}`; // dissocPath doesnt play well with num
      const is = !state.selectedAlcoholsId[id];
      const data = action.payload.data;

      return is
        ? assocPath(['selectedAlcoholsId', id], data, state)
        : dissocPath(['selectedAlcoholsId', id], state);
    }

    case 'ADMIN_ALCOHOL_CLEAR_IDS': {
      return assocPath(['selectedAlcoholsId'], {}, state);
    }

    case 'ADMIN_ALCOHOL_EDIT': {
      const { data } = action.payload;

      return { ...state, editRow: data };
    }

    default:
      return state;
  }
};

export default reducer;
