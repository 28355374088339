// @flow
import type { State } from '../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import messages from '../../common/order/messages/notifications';
import Notification from '../components/Notification';
import { compose } from 'rambda';
import { injectIntl } from 'react-intl';
import {
  clearSelectedOrderLines,
  toggleDeletingSubTable,
  toggleRelocatingToFoodCourse,
  toggleRelocatingToSubTable,
  toggleSubTableMenu
} from '../../common/order/actions';

// TODO merge with RN

const settings = {
  subTable: {
    text: messages.chooseSubTable,
    mode: 'overlay',
    showClose: true,
    onClose: dispatch => dispatch([
      toggleRelocatingToSubTable(false),
      toggleDeletingSubTable(false),
      toggleSubTableMenu(false),
      clearSelectedOrderLines()
    ]),
    position: 'absolute'
  },
  foodCourse: {
    text: messages.chooseFoodCourse,
    mode: 'overlay',
    showClose: true,
    onClose: dispatch => dispatch([
      toggleRelocatingToFoodCourse(false),
      clearSelectedOrderLines()
    ]),
    position: 'absolute'
  },
  table: {
    text: messages.chooseTable,
    mode: 'bottom'
  },
};

const NotificationPlace = ({ dispatch, target, notification, intl }) => {
  const setting = settings[notification];

  if (!setting) return null;

  return (
    <Notification
      text={intl.formatMessage(setting.text)}
      mode={setting.mode}
      showClose={setting.showClose}
      // eslint-disable-next-line react/jsx-no-bind
      onClose={setting.onClose ? setting.onClose.bind(null, dispatch) : undefined}
      position={setting.position}
    />
  );
};

export default compose(
  connect((state: State) => ({
    notification:
      (state.orders.active.isRelocatingToSubTable && 'subTable') ||
      (state.orders.active.isDeletingSubTable && 'subTable') ||
      (state.orders.active.isRelocatingToFoodCourse && 'foodCourse') ||
      (state.orders.active.isRelocatingToTable && 'table') ||
      (state.admin.receipts.isCancelingReceiptAndReturningToTable && 'table')
  })),
  injectIntl
)(NotificationPlace);
