// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose, find, propEq, pluck, concat } from 'rambda';
import Text from '../../common/components/Text';
import Popup from '../components/Popup';
import {
  clearSelectedOrderLines,
  deleteOrderLines,
  showAvailabilityBlocker,
  toggleFastFoodMenuForm,
  toggleOrderLineSelect,
  togglePriceOrQuantityForm,
  toggleTextInput
} from '../../common/order/actions';
import WeightKeyboard from '../../common/components/Keyboard';
import PopupWeightFormButtons from '../../common/components/Keyboard/popupWeightForm/Buttons';
import PopupWeightFormDisplay from '../../common/components/Keyboard/popupWeightForm/Display';
import CalculatorKeyboard from '../../common/components/Keyboard/calculator/Keyboard';
import CalculatorButtons from '../../common/components/Keyboard/calculator/ButtonsForPrice';
import CalculatorDisplay from '../../common/components/Keyboard/calculator/Display';
import createFormOperations from '../../common/components/Keyboard/popupWeightForm/operations';
import type { State } from '../../common/types';
import Heading from '../../common/components/Heading';
import { injectIntl } from 'react-intl';
import messages from '../../common/components/Keyboard/messages/buttons';
import orderMessages from '../../common/messages/order';
import Divider from '../../common/components/Divider';
import InactiveBg from '../components/InactiveBg';
import {
  constructCustomPriceOrQuantityOrderLines,
  findAllIdenticOrderLines, formatPortion, formatPriceToFixed,
  getPriceLevelName, selectOrderLinesMassively
} from '../../common/order/utils';
import { toggleWeightForm } from '../../common/peripherals/actions';
import { undeletedOrderLinesSelector } from '../../common/order/selectors';
import { getItemAvailability } from '../../common/items/utils';
import createCalculatorOperations from '../../common/components/Keyboard/calculator/operations';
import { findParamValue } from '../../common/parameters/utils';
import { getCurrencySymbol } from '../../common/parameters/service';

const popupNumericDisplayWithUnit = (unit, forceValue, DisplayComponent) => props =>
  <DisplayComponent {...props} value={forceValue} unit={unit} />;

const popupNumericButtonsWithoutDot = (hasDot, ButtonsComponent) => props =>
  <ButtonsComponent {...props} showDot={hasDot} />;

const updatedCalculatorDisplay = (unit, DisplayComponent) => props =>
  <DisplayComponent {...props} unit={unit} />;

class PopupPriceOrQuantityForm extends React.Component {
  constructor(props) {
    super(props);
    const {
      isPriceOrQuantityFormOpen,
      orderLines
    } = this.props;

    let foundOrderLines = [];
    if (isPriceOrQuantityFormOpen.type === 'deleteQuantity' || isPriceOrQuantityFormOpen.type === 'selectExactCount') {
      foundOrderLines = findAllIdenticOrderLines(isPriceOrQuantityFormOpen.uniqueOrderLine, orderLines);
    }

    this.state = {
      lastDisplayValue: '',
      possibleToSelectCount: foundOrderLines.length,
      possibleToSelectOrderLines: foundOrderLines
    };
  }

  onSubmit = key => {
    if (key !== 'ok') return;

    const {
      dispatch,
      isPriceOrQuantityFormOpen,
      activeFoodCourse,
      activeSubTableId,
      orderLines,
      items,
      itemPrices,
      itemsAvailability,
      itemsAvailabilityDiff,
      parameters
    } = this.props;
    const { lastDisplayValue } = this.state;
    const { type, uniqueOrderLine, freshInsert } = isPriceOrQuantityFormOpen;
    if (!uniqueOrderLine) return;

    // workaround (that timeout) - so that if enter key is hit, the handleKeyboardPress from MenuItemsList.js is not triggered
    setTimeout(
      () => dispatch(togglePriceOrQuantityForm(false)),
      200
    );

    if (type === 'quantity') {
      const { spart, itemId, hasCustomPrice, isWeighted } = uniqueOrderLine;
      const availability = getItemAvailability(uniqueOrderLine.itemId, itemsAvailability, itemsAvailabilityDiff);
      const chosenCount = Math.round(Number(lastDisplayValue) + Number.EPSILON);
      const availabilityOrderLine = { ...uniqueOrderLine, quantity: 1 };

      let dispatchLoad = null;

      if (isWeighted) {
        dispatchLoad = toggleWeightForm({ id: itemId });
      } else if ((spart === 'FST' || (parameters && spart === findParamValue('K32.menu_spart', parameters))) && freshInsert) {
        dispatchLoad = count => count ? [toggleFastFoodMenuForm({ id: itemId, count })] : [];
      } else if (hasCustomPrice && freshInsert) {
        dispatchLoad = quantity => quantity
          ? [togglePriceOrQuantityForm({
              type: 'price',
              uniqueOrderLine: { ...uniqueOrderLine, quantity }
            })]
          : [];
        this.setState({ lastDisplayValue: '' });
      } else {
        dispatchLoad = count => count
          ? concat(
              [
                clearSelectedOrderLines(),
                ...constructCustomPriceOrQuantityOrderLines({
                  isPriceOrQuantityFormOpen,
                  activeFoodCourse,
                  activeSubTableId,
                  orderLines,
                  items,
                  itemPrices,
                  lastDisplayValue: count,
                  freshInsert,
                  forceSelect: uniqueOrderLine.mustEnterText
                })
              ],
              uniqueOrderLine.mustEnterText ? [toggleTextInput(true)] : []
            )
          : [];
      }

      if (chosenCount > availability && !isWeighted) {
        dispatch(showAvailabilityBlocker(chosenCount, availabilityOrderLine, dispatchLoad));
      } else {
        dispatch(dispatchLoad(chosenCount));
      }
    } else if (type === 'price') {
      const { uniqueOrderLine } = isPriceOrQuantityFormOpen;

      if (uniqueOrderLine.needsInvoiceNo) {
        const dispatchLoad = note => [
          clearSelectedOrderLines(),
          ...constructCustomPriceOrQuantityOrderLines({
            isPriceOrQuantityFormOpen,
            activeFoodCourse,
            activeSubTableId,
            orderLines,
            items,
            itemPrices,
            lastDisplayValue,
            freshInsert,
            note
          })
        ];

        dispatch(toggleTextInput({ dispatchLoad }));
      } else {
        dispatch(
          concat(
            [
              clearSelectedOrderLines(),
              ...constructCustomPriceOrQuantityOrderLines({
                isPriceOrQuantityFormOpen,
                activeFoodCourse,
                activeSubTableId,
                orderLines,
                items,
                itemPrices,
                lastDisplayValue,
                freshInsert,
                forceSelect: uniqueOrderLine.mustEnterText
              })
            ],
            uniqueOrderLine.mustEnterText ? [toggleTextInput(true)] : []
          )
        );
      }
    } else if (type === 'deleteQuantity') {
      const { possibleToSelectOrderLines, possibleToSelectCount } = this.state;

      const selectedOrderLinesForDeletion = selectOrderLinesMassively(
        lastDisplayValue,
        possibleToSelectCount,
        possibleToSelectOrderLines,
        uniqueOrderLine,
        orderLines
      );

      dispatch([
        clearSelectedOrderLines(),
        deleteOrderLines(selectedOrderLinesForDeletion)
      ]);
    } else if (type === 'selectExactCount') {
      const { possibleToSelectOrderLines, possibleToSelectCount } = this.state;

      const selectedOrderLinesForMassSelection = selectOrderLinesMassively(
        lastDisplayValue,
        possibleToSelectCount,
        possibleToSelectOrderLines,
        uniqueOrderLine,
        orderLines
      );

      dispatch([
        toggleOrderLineSelect(pluck('id', possibleToSelectOrderLines), { forceDeselect: true }),
        toggleOrderLineSelect(selectedOrderLinesForMassSelection)
      ]);
    }
  };

  render() {
    const {
      dispatch,
      intl,
      items,
      isPriceOrQuantityFormOpen,
      priceLevels
    } = this.props;

    const { lastDisplayValue, possibleToSelectCount } = this.state;
    const { type, uniqueOrderLine } = isPriceOrQuantityFormOpen;

    if (!uniqueOrderLine) return null;
    const menuItem = find(propEq('id', uniqueOrderLine.itemId), items);
    if (!menuItem) return null;

    let itemInfo = '';
    if (type === 'selectExactCount') {
      const infoPrice = formatPriceToFixed(uniqueOrderLine.singlePrice);
      const infoPortion = formatPortion(uniqueOrderLine.portion);
      itemInfo = [
        getPriceLevelName(uniqueOrderLine.priceLevelId, priceLevels) || '',
        infoPortion === '1' ? '' : infoPortion,
        infoPrice ? `${infoPrice} ${getCurrencySymbol()}` : ''
      ].filter(x => x).join(', ');
    }

    return (
      <InactiveBg onClose={() => dispatch(togglePriceOrQuantityForm(false))}>
        <Popup showClose onClose={() => dispatch(togglePriceOrQuantityForm(false))} width={20}>
          <Heading scale={4} color="white" style={{ marginTop: 0, marginBottom: 10 }}>
            {type === 'price'
              ? intl.formatMessage(orderMessages.orderPriceOrQuantityPrice)
              : type === 'selectExactCount'
                ? intl.formatMessage(orderMessages.orderPriceOrQuantityCount)
                : intl.formatMessage(orderMessages.orderPriceOrQuantityAmount)}
          </Heading>
          <Text scale={1} color="white" decoration="underline">{menuItem.menuName}</Text>

          {type === 'deleteQuantity' &&
            <Text scale={1} color="white">{`${intl.formatMessage(orderMessages.orderPriceOrQuantityMaxDelete)}: ${possibleToSelectCount} ${intl.formatMessage(orderMessages.orderPriceOrQuantityKs)}`}</Text>}

          {type === 'selectExactCount' &&
            <React.Fragment>
              <Text scale={1} color="white">{itemInfo}</Text>

              <Text scale={1} color="white">{`${intl.formatMessage(orderMessages.orderPriceOrQuantityMaxSelected)}: ${possibleToSelectCount} ${intl.formatMessage(orderMessages.orderPriceOrQuantityKs)}`}</Text>
            </React.Fragment>}

          <Divider color="white" style={{ marginTop: 10, marginBottom: 10, opacity: 0.2 }} />

          {type === 'price'
            ? <CalculatorKeyboard
                style={theme => ({
                  width: theme.typography.lineHeight(5) * 1.75 * 4,
                  transformOrigin: '0 0',
                  transform: 'scale(1.045)',
                  marginBottom: 10
                })}
                createOperations={createCalculatorOperations}
                Buttons={CalculatorButtons}
                Display={updatedCalculatorDisplay(
                  getCurrencySymbol(),
                  CalculatorDisplay
                )}
                onLastKey={this.onSubmit}
                onDisplayValue={val =>
                  // eslint-disable-next-line no-eval
                  this.setState({ lastDisplayValue: Math.round((eval(val.slice(-1) === ' ' ? val.slice(0, -3) : val) + Number.EPSILON) * 100) / 100 })
                }
                defaultValue={0}
                flexShrink={0}
              />
            : <WeightKeyboard
                style={theme => ({ width: theme.typography.lineHeight(5) * 1.75 * 3 })}
                createOperations={createFormOperations}
                Buttons={popupNumericButtonsWithoutDot(
                  false,
                  PopupWeightFormButtons
                )}
                Display={popupNumericDisplayWithUnit(
                  'ks',
                  `${lastDisplayValue === '' ? 0 : lastDisplayValue}`,
                  PopupWeightFormDisplay
                )}
                onLastKey={this.onSubmit}
                onDisplayValue={val => {
                  this.setState({ lastDisplayValue: val });
                }}
                defaultValue="0"
                maxValue={possibleToSelectCount}
                marginTop={1}
                marginHorizontal="auto"
              />}
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect((state: State) => ({
    orderLines: undeletedOrderLinesSelector(state),
    items: state.items.items,
    itemPrices: state.items.itemPrices,
    priceLevels: state.items.priceLevels,
    isPriceOrQuantityFormOpen: state.orders.active.isPriceOrQuantityFormOpen,
    activeSubTableId: state.tables.active.subTableId,
    activeFoodCourse: state.tables.active.foodCourse,
    itemsAvailability: state.items.availability,
    itemsAvailabilityDiff: state.items.availabilityLocalDiff,
    parameters: state.parameters.parameters
  })),
  injectIntl
)(PopupPriceOrQuantityForm);
