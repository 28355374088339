// @flow
import React from 'react';
import Box from './Box';

const TopBar = ({ children, ...restProps }) => (
  <Box
    backgroundColor="darkGray"
    flexDirection="row"
    justifyContent={React.Children.count(children) === 1 ? 'center' : 'space-between'}
    flexShrink={0}
    height={2}
    {...restProps}
  >
    {children}
  </Box>
);

export default TopBar;
