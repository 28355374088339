/* eslint-disable max-len, quote-props, quotes */
export default [
  {
    "id": "admin.form.logo",
    "defaultMessage": "Tisknout logo",
  },
  {
    "id": "admin.form.state",
    "defaultMessage": "Stav kasy",
  },
  {
    "id": "admin.form.state_off",
    "defaultMessage": "Stav OFF",
  },
  {
    "id": "admin.form.state_on",
    "defaultMessage": "Stav ON",
  },
  {
    "id": "admin.values.logo_1",
    "defaultMessage": "Tisknout",
  },
  {
    "id": "admin.values.logo_2",
    "defaultMessage": "Netisknout",
  },
  {
    "id": "app.titles.admin",
    "defaultMessage": "Admin",
  },
  {
    "id": "app.titles.order",
    "defaultMessage": "Objednávka",
  },
  {
    "id": "app.titles.serviceAreas",
    "defaultMessage": "Místnosti",
  },
  {
    "id": "app.titles.setup",
    "defaultMessage": "Nastavení zařízení",
  },
  {
    "id": "auth.form.loading",
    "defaultMessage": "Načítají se údaje kasy",
  },
  {
    "id": "device.form.apiUrl",
    "defaultMessage": "Adresa API",
  },
  {
    "id": "device.form.error",
    "defaultMessage": "Nepodařilo se spojit s API",
  },
  {
    "id": "device.form.machineId",
    "defaultMessage": "ID kasy",
  },
  {
    "id": "device.form.machinePassword",
    "defaultMessage": "Heslo",
  },
  {
    "id": "keyboard.buttons.dot",
    "defaultMessage": ",",
  },
  {
    "id": "order.contextMenus.addMultipleTimes",
    "defaultMessage": "Podržte pro nastavení počtu",
  },
  {
    "id": "order.contextMenus.addNote",
    "defaultMessage": "Poznámka",
  },
  {
    "id": "order.contextMenus.addOne",
    "defaultMessage": "Přidat",
  },
  {
    "id": "order.contextMenus.addPortion",
    "defaultMessage": "Přidat porci",
  },
  {
    "id": "order.contextMenus.changeItemSize",
    "defaultMessage": "Změnit velikost porce",
  },
  {
    "id": "order.contextMenus.changeItemSize",
    "defaultMessage": "Změnit velikost porce",
  },
  {
    "id": "order.contextMenus.changePriceLevel",
    "defaultMessage": "Změnit cenovou hladinu",
  },
  {
    "id": "order.contextMenus.choosePortion",
    "defaultMessage": "Vyberte velikost porce",
  },
  {
    "id": "order.contextMenus.choosePortion",
    "defaultMessage": "Vyberte velikost porce",
  },
  {
    "id": "order.contextMenus.choosePrice",
    "defaultMessage": "Zadejte cenu",
  },
  {
    "id": "order.contextMenus.choosePriceLevel",
    "defaultMessage": "Vyberte cenovou hladinu",
  },
  {
    "id": "order.contextMenus.chooseQuantity",
    "defaultMessage": "Zadejte množství",
  },
  {
    "id": "order.contextMenus.chooseWeight",
    "defaultMessage": "Zadejte váhu",
  },
  {
    "id": "order.contextMenus.closeGroup",
    "defaultMessage": "Zavřít skupinu",
  },
  {
    "id": "order.contextMenus.closeMenu",
    "defaultMessage": "Zavřít menu",
  },
  {
    "id": "order.contextMenus.currencySymbol",
    "defaultMessage": "Kč",
  },
  {
    "id": "order.contextMenus.deleteSelected",
    "defaultMessage": "Smazat vybrané",
  },
  {
    "id": "order.contextMenus.deleteSelectedMultipleTimes",
    "defaultMessage": "Podržte pro nastavení počtu",
  },
  {
    "id": "order.contextMenus.divideGroup",
    "defaultMessage": "Rozdělit",
  },
  {
    "id": "order.contextMenus.itemPortion1",
    "defaultMessage": "Celá porce",
  },
  {
    "id": "order.contextMenus.itemPortion1",
    "defaultMessage": "Celá porce",
  },
  {
    "id": "order.contextMenus.itemPortion2",
    "defaultMessage": "½ porce",
  },
  {
    "id": "order.contextMenus.itemPortion2",
    "defaultMessage": "½ porce",
  },
  {
    "id": "order.contextMenus.itemPortion3",
    "defaultMessage": "⅓ porce",
  },
  {
    "id": "order.contextMenus.itemPortion3",
    "defaultMessage": "⅓ porce",
  },
  {
    "id": "order.contextMenus.itemPortion4",
    "defaultMessage": "¼ porce",
  },
  {
    "id": "order.contextMenus.itemPortion4",
    "defaultMessage": "¼ porce",
  },
  {
    "id": "order.contextMenus.openGroup",
    "defaultMessage": "Otevřít skupinu",
  },
  {
    "id": "order.contextMenus.quantityUnit",
    "defaultMessage": "{quantity} ks",
  },
  {
    "id": "order.contextMenus.relocateToFoodCourse",
    "defaultMessage": "Přesunout na chod",
  },
  {
    "id": "order.contextMenus.relocateToOpenTable",
    "defaultMessage": "Přesunout na stůl",
  },
  {
    "id": "order.contextMenus.relocateToOpenTableStop",
    "defaultMessage": "Zrušit přesouvání",
  },
  {
    "id": "order.contextMenus.relocateToSubTable",
    "defaultMessage": "Přesunout na hosta",
  },
  {
    "id": "order.contextMenus.selectCount",
    "defaultMessage": "Vybrat počet",
  },
  {
    "id": "order.notifications.chooseFoodCourse",
    "defaultMessage": "Vyberte chod, na který chcete přesunout položky",
  },
  {
    "id": "order.notifications.chooseSubTable",
    "defaultMessage": "Vyberte hosta, na kterého chcete přesunout položky",
  },
  {
    "id": "order.notifications.chooseTable",
    "defaultMessage": "Vyberte stůl, na který chcete přesunout položky",
  },
  {
    "id": "order.page.choosePriceLevel",
    "defaultMessage": "Vyberte cenovou hladinu",
  },
  {
    "id": "order.page.close",
    "defaultMessage": "Zavřít",
  },
  {
    "id": "payment.buttons.currencySymbol",
    "defaultMessage": "Kč",
  },
  {
    "id": "payment.contextMenus.absoluteDiscount",
    "defaultMessage": "Absolutní",
  },
  {
    "id": "payment.contextMenus.currencySymbol",
    "defaultMessage": "Kč",
  },
  {
    "id": "payment.contextMenus.discount10Percent",
    "defaultMessage": "Sleva 10%",
  },
  {
    "id": "payment.contextMenus.discount15Percent",
    "defaultMessage": "Sleva 15%",
  },
  {
    "id": "payment.contextMenus.discount20Percent",
    "defaultMessage": "Sleva 20%",
  },
  {
    "id": "payment.contextMenus.discount25Percent",
    "defaultMessage": "Sleva 25%",
  },
  {
    "id": "payment.contextMenus.discount5Percent",
    "defaultMessage": "Sleva 5%",
  },
  {
    "id": "payment.contextMenus.percentageDiscount",
    "defaultMessage": "Procentuální",
  },
  {
    "id": "payment.contextMenus.roomService",
    "defaultMessage": "Přirážka room service",
  },
  {
    "id": "payment.page.absolute",
    "defaultMessage": "Kč",
  },
  {
    "id": "payment.page.absoluteDiscount",
    "defaultMessage": "Absolutní",
  },
  {
    "id": "payment.page.address1",
    "defaultMessage": "Ulice",
  },
  {
    "id": "payment.page.address2",
    "defaultMessage": "Město, PSČ",
  },
  {
    "id": "payment.page.address3",
    "defaultMessage": "Země",
  },
  {
    "id": "payment.page.backToPayment",
    "defaultMessage": "Zpět na platbu",
  },
  {
    "id": "payment.page.backToTable",
    "defaultMessage": "Zpět na stůl",
  },
  {
    "id": "payment.page.bankCard",
    "defaultMessage": "Bankovní karta",
  },
  {
    "id": "payment.page.cardId",
    "defaultMessage": "ID karty",
  },
  {
    "id": "payment.page.cash",
    "defaultMessage": "V hotovosti",
  },
  {
    "id": "payment.page.chooseCard",
    "defaultMessage": "Vyberte kartu",
  },
  {
    "id": "payment.page.closeDiscountMenu",
    "defaultMessage": "Zavřít výběr slevy",
  },
  {
    "id": "payment.page.closePayment",
    "defaultMessage": "Zavřít platbu",
  },
  {
    "id": "payment.page.company",
    "defaultMessage": "Firma",
  },
  {
    "id": "payment.page.createNewUser",
    "defaultMessage": "Přidat uživatele",
  },
  {
    "id": "payment.page.currencySymbol",
    "defaultMessage": "Kč",
  },
  {
    "id": "payment.page.dic",
    "defaultMessage": "DIČ",
  },
  {
    "id": "payment.page.discount",
    "defaultMessage": "Sleva",
  },
  {
    "id": "payment.page.discountReason",
    "defaultMessage": "Důvod slevy",
  },
  {
    "id": "payment.page.dot",
    "defaultMessage": ",",
  },
  {
    "id": "payment.page.enterCardManualy",
    "defaultMessage": "Zadat ID ručně",
  },
  {
    "id": "payment.page.finishAndPrintReceipt",
    "defaultMessage": "Uzavřít účet a tisknout blok",
  },
  {
    "id": "payment.page.icdph",
    "defaultMessage": "IČ DPH",
  },
  {
    "id": "payment.page.ico",
    "defaultMessage": "IČO",
  },
  {
    "id": "payment.page.leftToPay",
    "defaultMessage": "Zbývá doplatit",
  },
  {
    "id": "payment.page.leftToPayExact",
    "defaultMessage": "Zaplaceno přesně",
  },
  {
    "id": "payment.page.leftToPaySurplus",
    "defaultMessage": "Přeplatek",
  },
  {
    "id": "payment.page.leftToPayTip",
    "defaultMessage": "Přeplatek (TIP)",
  },
  {
    "id": "payment.page.loyalMAN",
    "defaultMessage": "LoyalMAN",
  },
  {
    "id": "payment.page.mealVouchers",
    "defaultMessage": "Stravenky",
  },
  {
    "id": "payment.page.name",
    "defaultMessage": "Jméno",
  },
  {
    "id": "payment.page.newCustomer",
    "defaultMessage": "Nový zákazník",
  },
  {
    "id": "payment.page.overallSum",
    "defaultMessage": "Celková suma",
  },
  {
    "id": "payment.page.overallSumAfterDiscount",
    "defaultMessage": "Suma po slevě",
  },
  {
    "id": "payment.page.pay",
    "defaultMessage": "Platit",
  },
  {
    "id": "payment.page.relativeDiscount",
    "defaultMessage": "Procentuální",
  },
  {
    "id": "payment.page.printPrereceipt",
    "defaultMessage": "Tisknout předúčet",
  },
  {
    "id": "payment.page.registerNewUser",
    "defaultMessage": "Registrovat nového uživatele",
  },
  {
    "id": "payment.page.registerSeparately",
    "defaultMessage": "Zákazník se sám registruje na webu",
  },
  {
    "id": "payment.page.relative",
    "defaultMessage": "%",
  },
  {
    "id": "payment.page.remaind",
    "defaultMessage": "zůstatek",
  },
  {
    "id": "payment.page.schvalil",
    "defaultMessage": "Schválil",
  },
  {
    "id": "payment.page.setDiscount",
    "defaultMessage": "Zadat slevu",
  },
  {
    "id": "payment.page.subtotal",
    "defaultMessage": "Mezivýsledky",
  },
  {
    "id": "payment.page.tip",
    "defaultMessage": "TIP",
  },
  {
    "id": "payment.page.title",
    "defaultMessage": "Platba",
  },
  {
    "id": "payment.page.touchTheCardReader",
    "defaultMessage": "Priložte kartu ke čtečce",
  },
  {
    "id": "payment.page.txt",
    "defaultMessage": "Poznámka",
  },
  {
    "id": "serviceAreas.editables.tableName",
    "defaultMessage": "Název stolu",
  },
  {
    "id": "serviceAreas.options.areas",
    "defaultMessage": "Místnosti",
  },
  {
    "id": "serviceAreas.options.bar",
    "defaultMessage": "Bar",
  },
  {
    "id": "serviceAreas.options.barInCash",
    "defaultMessage": "Bar v hotovosti",
  },
  {
    "id": "serviceAreas.options.deposit",
    "defaultMessage": "Vklad",
  },
  {
    "id": "serviceAreas.options.edit",
    "defaultMessage": "Editovat",
  },
  {
    "id": "serviceAreas.options.editDone",
    "defaultMessage": "Editace",
  },
  {
    "id": "serviceAreas.options.noMap",
    "defaultMessage": "Jednorázové stoly",
  },
  {
    "id": "serviceAreas.options.oneTimeTable",
    "defaultMessage": "Jednoráz. stůl",
  },
  {
    "id": "serviceAreas.options.options",
    "defaultMessage": "Možnosti",
  },
  {
    "id": "serviceAreas.options.suflik",
    "defaultMessage": "Šuplík",
  },
  {
    "id": "serviceAreas.options.withdrawal",
    "defaultMessage": "Výběr",
  },
  {
    "id": "tables.contextMenus.cancel",
    "defaultMessage": "Zrušit",
  },
  {
    "id": "tables.contextMenus.changePriceLevel",
    "defaultMessage": "Změnit cenovou hladinu",
  },
  {
    "id": "tables.contextMenus.close",
    "defaultMessage": "Zavřít stůl",
  },
  {
    "id": "tables.contextMenus.edit",
    "defaultMessage": "Přejmenovat",
  },
  {
    "id": "tables.contextMenus.join",
    "defaultMessage": "Spojit podstoly",
  },
  {
    "id": "tables.contextMenus.newSubtable",
    "defaultMessage": "Nový host",
  },
  {
    "id": "tables.contextMenus.pay",
    "defaultMessage": "Zaplatit",
  },
  {
    "id": "tables.contextMenus.split",
    "defaultMessage": "Vytvořit podstůl",
  },
  {
    "id": "tables.states.unopened",
    "defaultMessage": "Neotevřený",
  },
  {
    defaultMessage: 'Chyba při získávávní seznamu alkoholu: ',
    id: 'alcohol.errors.get.alcohol'
  },
  {
    defaultMessage: 'Chyba při získavání EANů: ',
    id: 'alcohol.errors.get.ean'
  },
  {
    defaultMessage: 'Chyba při přidávaní alkoholu: ',
    id: 'alcohol.errors.add.alcohol'
  },
  {
    defaultMessage: 'Chyba při editování alkoholu: ',
    id: 'alcohol.errors.edit.alcohol'
  },
  {
    defaultMessage: 'Chyba při mazání alkoholu: ',
    id: 'alcohol.errors.delete.alcohol'
  },
  {
    defaultMessage: 'Popis',
    id: 'alcohol.description'
  },
  {
    defaultMessage: 'Počet',
    id: 'alcohol.count'
  },
  {
    defaultMessage: 'EAN',
    id: 'alcohol.ean'
  },
  {
    defaultMessage: 'Autor',
    id: 'alcohol.author'
  },
  {
    defaultMessage: 'Datum',
    id: 'alcohol.date'
  },
  {
    defaultMessage: '...hledám...',
    id: 'alcohol.finding'
  },
  {
    defaultMessage: 'daný EAN sa nenašel',
    id: 'alcohol.eanNotFound'
  },
  {
    defaultMessage: 'Položka přidána.',
    id: 'alcohol.itemAdded'
  },
  {
    defaultMessage: 'Položka upravena.',
    id: 'alcohol.itemEdited'
  },
  {
    defaultMessage: 'Upravit',
    id: 'alcohol.sidebar.edit'
  },
  {
    defaultMessage: 'Smazat',
    id: 'alcohol.sidebar.delete'
  },
  {
    defaultMessage: 'Přidat',
    id: 'alcohol.sidebar.add'
  },
  {
    defaultMessage: 'Položka smazána.',
    id: 'alcohol.sidebar.notification.deleted'
  },
  {
    defaultMessage: 'Smazání položek',
    id: 'alcohol.sidebar.deleteItems'
  },
  {
    defaultMessage: 'Označené položky budou odstraněny, chcete je opravdu smazat?',
    id: 'alcohol.sidebar.confirmMsgDeleteItems'
  },
  {
    defaultMessage: 'Ano',
    id: 'alcohol.sidebar.deleteYes'
  },
  {
    defaultMessage: 'Zrušit',
    id: 'alcohol.sidebar.deleteCancel'
  },
  {
    defaultMessage: '⚠️ byla nalezena aktualizace.',
    id: 'autoUpdates.updateFound'
  },
  {
    defaultMessage: 'Aktualizovat',
    id: 'autoUpdates.updateFound'
  },
  {
    defaultMessage: 'Nainstalovat a restartovat',
    id: 'autoUpdates.updateFound'
  },
  {
    defaultMessage: 'Ano',
    id: 'closures.yes'
  },
  {
    defaultMessage: 'Ne',
    id: 'closures.no'
  },
  {
    defaultMessage: 'Úspešné uzavření stolu, nepotvrzené položky ponechány.',
    id: 'closures.confirmTable'
  },
  {
    defaultMessage: 'Úspešné uzavření stolu',
    id: 'closures.closeTable'
  },
  {
    defaultMessage: 'Opravdu chcete uzavřít všechny účty?',
    id: 'closures.closeAllClosuresTitle'
  },
  {
    defaultMessage: 'Všechny otevřené účty budou zaplaceny vybraným způsobem platby bez slevy',
    id: 'closures.closeAllClosuresInfo'
  },
  {
    defaultMessage: 'Zavřít i nepotvrzené (černé) stoly, i stoly s nepotvrzenými objednávkami (nepotvrzené položky budou smazány).',
    id: 'closures.closeAllTablesInfo'
  },
  {
    defaultMessage: 'Uzávěrka od – do',
    id: 'closures.history.deadlineFromTo'
  },
  {
    defaultMessage: 'Uzávěrka byla odeslána k tisku',
    id: 'closures.history.closureToPrint'
  },
  {
    defaultMessage: 'Kopie fiskální uzávěrky byla odeslána k tisku',
    id: 'closures.history.closureCopyToPrint'
  },
  {
    defaultMessage: 'Vybrat tiskárnu',
    id: 'closures.history.choosePrinter'
  },
  {
    defaultMessage: 'Vytisknout',
    id: 'closures.history.print'
  },
  {
    defaultMessage: 'Přegenerovat uzávěrku',
    id: 'closures.history.regenerateClosure'
  },
  {
    defaultMessage: 'Kopie fiskál. uzávěrky',
    id: 'closures.history.copyClosure'
  },
  {
    defaultMessage: 'Uzávěrka byla odeslána k tisku',
    id: 'closures.intermediate.closureToPrintInter'
  },
  {
    defaultMessage: 'Potvrdit a vytisknout',
    id: 'closures.intermediate.confirmAndPrint'
  },
  {
    defaultMessage: 'Potvrdit a vybrat tiskárnu',
    id: 'closures.intermediate.confirmAndChoosePrinter'
  },
  {
    defaultMessage: 'Vytváří sa intervalová uzávěrka',
    id: 'closures.interval.creatingIntervalClosure'
  },
  {
    defaultMessage: 'Uzávěrka byla vytvořena',
    id: 'closures.interval.createdIntervalClosure'
  },
  {
    defaultMessage: 'Intervalová uzávěrka',
    id: 'closures.interval.intervalClosure'
  },
  {
    defaultMessage: 'Od:',
    id: 'closures.interval.intervalFrom'
  },
  {
    defaultMessage: 'Do:',
    id: 'closures.interval.intervalTo'
  },
  {
    defaultMessage: 'Potvrdit intervalovou uzávěrku',
    id: 'closures.interval.confirmIntervalClosure'
  },
  {
    defaultMessage: 'Vytisknout',
    id: 'closures.journal.journalPrint'
  },
  {
    defaultMessage: 'Hodnota výběru nesmí převyšovat součet hodnot platební metody, nebo dosahovat záporné hodnoty.',
    id: 'closures.new.overFlorError'
  },
  {
    defaultMessage: 'Nastala chyba při realizování výběrů:',
    id: 'closures.new.withdrawalError'
  },
  {
    defaultMessage: 'Uzávěrka byla odeslána k tisku',
    id: 'closures.new.closureSendToPrint'
  },
  {
    defaultMessage: 'Vytisknout',
    id: 'closures.new.closurePrint'
  },
  {
    defaultMessage: 'Vybrat tiskárnu',
    id: 'closures.new.closureChoosePrinter'
  },
  {
    defaultMessage: 'Probíhá tisk',
    id: 'closures.new.closurePrintInProgress'
  },
  {
    defaultMessage: 'Vytváří se nová uzávěrka',
    id: 'closures.new.closureCreatingNew'
  },
  {
    defaultMessage: 'Uzávěrka byla vytvořena',
    id: 'closures.new.closureCreatedNew'
  },
  {
    defaultMessage: 'Opravdu chcete udělat novou uzávěrku?',
    id: 'closures.new.closureConfirmNew'
  },
  {
    defaultMessage: 'Nastala chyba při realizování vkladu:',
    id: 'closures.new.closureDepositBlockError'
  },
  {
    defaultMessage: 'Provést vklad pro platební metodu',
    id: 'closures.new.closureDepositHeader'
  },
  {
    defaultMessage: 'Pro platební metodu {name} bude proveden vklad v hodnotě {sum} {currency}. Chcete pokračovat?',
    id: 'closures.new.closureDepositDescription'
  },
  {
    defaultMessage: 'Ano',
    id: 'closures.new.closureDepositConfirm'
  },
  {
    defaultMessage: 'Zrušit',
    id: 'closures.new.closureDepositCancel'
  },
  {
    defaultMessage: 'Metoda',
    id: 'closures.new.table.mediaName'
  },
  {
    defaultMessage: 'Tržba',
    id: 'closures.new.table.sale'
  },
  {
    defaultMessage: 'Úhrada pohledávky',
    id: 'closures.new.table.payment'
  },
  {
    defaultMessage: 'Sumář vkladů/výběrů',
    id: 'closures.new.table.summary'
  },
  {
    defaultMessage: 'Celkem',
    id: 'closures.new.table.overall'
  },
  {
    defaultMessage: 'Výběr',
    id: 'closures.new.table.withdrawal'
  },
  {
    defaultMessage: 'Vklad',
    id: 'closures.new.table.deposit'
  },
  {
    defaultMessage: 'Ano',
    id: 'closures.new.yes'
  },
  {
    defaultMessage: 'Ne',
    id: 'closures.new.no'
  },
  {
    defaultMessage: 'Součet hodnot platební metody nesmí být záporný.',
    id: 'closures.new.validation.negative'
  },
  {
    defaultMessage: 'Vytváří sa sumární uzávěrka',
    id: 'closures.summary.create'
  },
  {
    defaultMessage: 'Uzávěrka byla vytvořena',
    id: 'closures.summary.created'
  },
  {
    defaultMessage: 'Sumární uzávěrka',
    id: 'closures.summary.title'
  },
  {
    defaultMessage: 'Od:',
    id: 'closures.summary.from'
  },
  {
    defaultMessage: 'Do:',
    id: 'closures.summary.to'
  },
  {
    defaultMessage: 'Potvrdit sumární uzávěrku',
    id: 'closures.summary.confirm'
  },
  {
    defaultMessage: 'Vytváří se uzávěrka',
    id: 'closures.summaryMultiple.creating'
  },
  {
    defaultMessage: 'Uzávěrka byla vytvořena',
    id: 'closures.summaryMultiple.created'
  },
  {
    defaultMessage: 'Sumární uzávěrka za více kas',
    id: 'closures.summaryMultiple.cashiers'
  },
  {
    defaultMessage: 'Kasy',
    id: 'closures.summaryMultiple.cashiersTitle'
  },
  {
    defaultMessage: 'Nenašly sa vyhovující kasy',
    id: 'closures.summaryMultiple.cashiersNotFound'
  },
  {
    defaultMessage: 'Uzávěrky',
    id: 'closures.summaryMultiple.ClosuresTitle'
  },
  {
    defaultMessage: 'Nenašly sa vyhovující uzávěrky',
    id: 'closures.summaryMultiple.closuresNotFound'
  },
  {
    defaultMessage: 'Pokračovat na výběr uzávěrek',
    id: 'closures.summaryMultiple.chooseContinue'
  },
  {
    defaultMessage: 'Potvrdit uzávěrku',
    id: 'closures.summaryMultiple.confirmClosure'
  },
  {
    defaultMessage: 'Zpět na výběr kas',
    id: 'closures.summaryMultiple.backToCashiers'
  },
  {
    defaultMessage: 'Uzávěrka byla odeslána k tisku',
    id: 'closures.printPreview.send'
  },
  {
    defaultMessage: 'Vytisknout',
    id: 'closures.printPreview.print'
  },
  {
    defaultMessage: 'Vybrat tiskárnu',
    id: 'closures.printPreview.choosePrinter'
  },
  {
    defaultMessage: 'Probíhá tisk',
    id: 'closures.printPreview.printing'
  },
  {
    defaultMessage: 'Kopie a storna účtů',
    id: 'common.menu.copyCancel'
  },
  {
    defaultMessage: 'Zpráva do kuchyně',
    id: 'common.menu.kitchenMessage'
  },
  {
    defaultMessage: 'Uzávěrka kasy',
    id: 'common.menu.cashClose'
  },
  {
    defaultMessage: 'Prohlížení spotřeby',
    id: 'common.menu.consumptionView'
  },
  {
    defaultMessage: 'Prodej alkoholu',
    id: 'common.menu.alcohol'
  },
  {
    defaultMessage: 'Fiskál',
    id: 'common.menu.fiscal'
  },
  {
    defaultMessage: 'Počet porcí',
    id: 'common.menu.mealsCount'
  },
  {
    defaultMessage: 'Aktualizace ceníku',
    id: 'common.menu.priceList'
  },
  {
    defaultMessage: 'Přepnout režim na celou obrazovku',
    id: 'common.menu.fullScreen'
  },
  {
    defaultMessage: 'Meziuzávěrka',
    id: 'common.closures.intermediate'
  },
  {
    defaultMessage: 'Nová uzávěrka',
    id: 'common.closures.new'
  },
  {
    defaultMessage: 'Intervalová uzávěrka',
    id: 'common.closures.interval'
  },
  {
    defaultMessage: 'Sumární uzávěrka',
    id: 'common.closures.summary'
  },
  {
    defaultMessage: 'Sumární uzávěrka za více kas',
    id: 'common.closures.summaryMultiple'
  },
  {
    defaultMessage: 'Historie uzávěrek',
    id: 'common.closures.history'
  },
  {
    defaultMessage: 'Hromadné uzavření účtů',
    id: 'common.closures.bulkClose'
  },
  {
    defaultMessage: 'Fiskální modul',
    id: 'common.fiscal.module'
  },
  {
    defaultMessage: 'Potvrdit výzvu',
    id: 'common.fiscal.confirm'
  },
  {
    defaultMessage: 'Nastavit čas',
    id: 'common.fiscal.time'
  },
  {
    defaultMessage: 'Resetovat fiskál',
    id: 'common.fiscal.reset'
  },
  {
    defaultMessage: 'Možnosti kasy',
    id: 'common.cashier.options'
  },
  {
    defaultMessage: 'Žádné položky',
    id: 'common.table.empty'
  },
  {
    defaultMessage: 'Načítají se údaje…',
    id: 'common.table.loading'
  },
  {
    defaultMessage: 'Zavřít',
    id: 'common.topBar.close'
  },
  {
    defaultMessage: 'Vyberte uzávěrku ze seznamu vlevo',
    id: 'components.closure.chooseLeft'
  },
  {
    defaultMessage: 'Uzávěrka neobsahuje žádné údaje',
    id: 'components.closure.noDate'
  },
  {
    defaultMessage: 'Nový stůl',
    id: 'components.tableArea.newTable'
  },
  {
    defaultMessage: 'Adresa API',
    id: 'device.form.apiUrl'
  },
  {
    defaultMessage: 'ID kasy',
    id: 'device.form.machineId'
  },
  {
    defaultMessage: 'Heslo',
    id: 'device.form.machinePassword'
  },
  {
    defaultMessage: 'Nepodařilo se spojit s API',
    id: 'device.form.error'
  },
  {
    defaultMessage: 'Nastavení zařízení',
    id: 'device.form.setup'
  },
  {
    defaultMessage: 'Pokračovat',
    id: 'device.form.continue'
  },
  {
    defaultMessage: 'Potvrzování výzvy',
    id: 'fiscal.confirm.header'
  },
  {
    defaultMessage: 'Výzva byla potvrzena',
    id: 'fiscal.confirm.info'
  },
  {
    defaultMessage: 'Typ',
    id: 'fiscal.form.fiscalType'
  },
  {
    defaultMessage: 'Port fiskálu',
    id: 'fiscal.form.fiscalPort'
  },
  {
    defaultMessage: 'Typ portu',
    id: 'fiscal.form.portType'
  },
  {
    defaultMessage: 'Rychlost portu',
    id: 'fiscal.form.commSpeed'
  },
  {
    defaultMessage: 'Parita',
    id: 'fiscal.form.parity'
  },
  {
    defaultMessage: 'Databit',
    id: 'fiscal.form.databit'
  },
  {
    defaultMessage: 'Stopbit',
    id: 'fiscal.form.stopbit'
  },
  {
    defaultMessage: 'Flow mng',
    id: 'fiscal.form.flowManagement'
  },
  {
    defaultMessage: 'Fiskál aktualizován',
    id: 'fiscal.form.updated'
  },
  {
    defaultMessage: 'Načítají se údaje…',
    id: 'fiscal.form.loadingData'
  },
  {
    defaultMessage: 'Údaje modulu',
    id: 'fiscal.form.moduleData'
  },
  {
    defaultMessage: 'Statistiky',
    id: 'fiscal.form.statistics'
  },
  {
    defaultMessage: 'IČO',
    id: 'fiscal.form.ico'
  },
  {
    defaultMessage: 'IČ DPH',
    id: 'fiscal.form.icdph'
  },
  {
    defaultMessage: 'DIČ',
    id: 'fiscal.form.dic'
  },
  {
    defaultMessage: 'DKP',
    id: 'fiscal.form.dkp'
  },
  {
    defaultMessage: 'Verze',
    id: 'fiscal.form.versions'
  },
  {
    defaultMessage: 'Účetní dailyMenu',
    id: 'fiscal.form.fiscalDay'
  },
  {
    defaultMessage: 'Firmware',
    id: 'fiscal.form.firmwareVersion'
  },
  {
    defaultMessage: 'AFS',
    id: 'fiscal.form.afsVersion'
  },
  {
    defaultMessage: 'Hraniční čas uzávěrky',
    id: 'fiscal.form.deadline'
  },
  {
    defaultMessage: 'Tisknout logo',
    id: 'fiscal.form.printLogo'
  },
  {
    defaultMessage: 'Tisknout',
    id: 'fiscal.form.print'
  },
  {
    defaultMessage: 'Netisknout',
    id: 'fiscal.form.notprint'
  },
  {
    defaultMessage: 'Hlavičky',
    id: 'fiscal.form.headers'
  },
  {
    defaultMessage: 'Sadzba DPH',
    id: 'fiscal.form.dph'
  },
  {
    defaultMessage: 'Odeslat změny',
    id: 'fiscal.form.sendChanges'
  },
  {
    defaultMessage: 'Údaje fiskálního modulu',
    id: 'fiscal.module.data'
  },
  {
    defaultMessage: 'Hraniční čas uzávěrky',
    id: 'fiscal.module.deadline'
  },
  {
    defaultMessage: 'Potvrdit čas',
    id: 'fiscal.module.confirmTime'
  },
  {
    defaultMessage: 'Úprava DPH (v %)',
    id: 'fiscal.module.editDph'
  },
  {
    defaultMessage: 'Reset fiskálu',
    id: 'fiscal.reset.title'
  },
  {
    defaultMessage: 'Fiskál byl resetován',
    id: 'fiscal.reset.info'
  },
  {
    defaultMessage: 'Nastavování času',
    id: 'fiscal.time.title'
  },
  {
    defaultMessage: 'Čas byl nastaven',
    id: 'fiscal.time.info'
  },
  {
    defaultMessage: 'Dostupnost zboží',
    id: 'mealsCounts.goodsAvailability'
  },
  {
    defaultMessage: 'Hledat',
    id: 'mealsCounts.find'
  },
  {
    defaultMessage: 'Zpráva do kuchyně odeslána',
    id: 'messages.kitchenSend'
  },
  {
    defaultMessage: 'Chyba při posílání zprávy do kuchyně: ',
    id: 'messages.kitchenSendError'
  },
  {
    defaultMessage: 'Zpráva do kuchyně - zadání zprávy.',
    id: 'messages.kitchenCreate'
  },
  {
    defaultMessage: 'Zpráva do kuchyně - výběr tiskárny',
    id: 'messages.kitchenPrinter'
  },
  {
    defaultMessage: 'Otevřít skupinu',
    id: 'order.contextMenus.openGroup'
  },
  {
    defaultMessage: 'Zavřít skupinu',
    id: 'order.contextMenus.closeGroup'
  },
  {
    defaultMessage: 'Poznámka',
    id: 'order.contextMenus.addNote'
  },
  {
    defaultMessage: 'Přidat',
    id: 'order.contextMenus.addOne'
  },
  {
    defaultMessage: 'Podržte pro nastavení počtu',
    id: 'order.contextMenus.addMultipleTimes'
  },
  {
    defaultMessage: 'Zavřít menu',
    id: 'order.contextMenus.closeMenu'
  },
  {
    defaultMessage: 'Smazat vybrané',
    id: 'order.contextMenus.deleteSelected'
  },
  {
    defaultMessage: 'Podržte pro nastavení počtu',
    id: 'order.contextMenus.deleteSelectedMultipleTimes'
  },
  {
    defaultMessage: 'Vybrat počet',
    id: 'order.contextMenus.selectCount'
  },
  {
    defaultMessage: 'Přidat porci',
    id: 'order.contextMenus.addPortion'
  },
  {
    defaultMessage: '{quantity} ks',
    id: 'order.contextMenus.quantityUnit'
  },
  {
    defaultMessage: 'Rozdělit',
    id: 'order.contextMenus.divideGroup'
  },
  {
    defaultMessage: 'Přesunout na chod',
    id: 'order.contextMenus.relocateToFoodCourse'
  },
  {
    defaultMessage: 'Přesunout na hosta',
    id: 'order.contextMenus.relocateToSubTable'
  },
  {
    defaultMessage: 'Přesunout na stůl',
    id: 'order.contextMenus.relocateToOpenTable'
  },
  {
    defaultMessage: 'Zrušit přesouvání',
    id: 'order.contextMenus.relocateToOpenTableStop'
  },
  {
    defaultMessage: 'Změnit cenovou hladinu',
    id: 'order.contextMenus.changePriceLevel'
  },
  {
    defaultMessage: 'Vyberte cenovou hladinu',
    id: 'order.contextMenus.choosePriceLevel'
  },
  {
    defaultMessage: 'Změnit velikost porce',
    id: 'order.contextMenus.changeItemSize'
  },
  {
    defaultMessage: 'Vyberte velikost porce',
    id: 'order.contextMenus.choosePortion'
  },
  {
    defaultMessage: 'Vyberte hosta, na ktorého chcete položky přesunout',
    id: 'order.notifications.chooseSubTable'
  },
  {
    defaultMessage: 'Vyberte chod, na který chcete položky přesunout',
    id: 'order.notifications.chooseFoodCourse'
  },
  {
    defaultMessage: 'Vyberte stůl, na který chcete položky přesunout',
    id: 'order.notifications.chooseTable'
  },
  {
    defaultMessage: 'Zavřít',
    id: 'order.page.close'
  },
  {
    defaultMessage: 'Vyberte cenovou hladinu',
    id: 'order.page.choosePriceLevel'
  },
  {
    defaultMessage: 'Změnit velikost porce',
    id: 'order.contextMenus.changeItemSize'
  },
  {
    defaultMessage: 'Vyberte velikost porce',
    id: 'order.contextMenus.choosePortion'
  },
  {
    defaultMessage: 'Zadejte váhu',
    id: 'order.contextMenus.chooseWeight'
  },
  {
    defaultMessage: 'Zadejte cenu',
    id: 'order.contextMenus.choosePrice'
  },
  {
    defaultMessage: 'Zadejte množství',
    id: 'order.contextMenus.chooseQuantity'
  },
  {
    defaultMessage: 'Kč',
    id: 'order.contextMenus.currencySymbol'
  },
  {
    defaultMessage: 'Celá porce',
    id: 'order.contextMenus.itemPortion1'
  },
  {
    defaultMessage: '½ porce',
    id: 'order.contextMenus.itemPortion2'
  },
  {
    defaultMessage: '⅓ porce',
    id: 'order.contextMenus.itemPortion3'
  },
  {
    defaultMessage: '¼ porce',
    id: 'order.contextMenus.itemPortion4'
  },
  {
    defaultMessage: 'Zrušení objednávky',
    id: 'order.generic.cancelOrder.header'
  },
  {
    defaultMessage: 'Objednávka nebude uložena, opravdu se chcete vrátit?',
    id: 'order.generic.cancelOrder.description'
  },
  {
    defaultMessage: 'Ano',
    id: 'order.generic.cancelOrder.confirm'
  },
  {
    defaultMessage: 'Zůstat',
    id: 'order.generic.cancelOrder.cancel'
  },
  {
    defaultMessage: 'Vymazání lokálních položek',
    id: 'order.generic.localItems.remove.header'
  },
  {
    defaultMessage: 'Vymazat lokální položky?',
    id: 'order.generic.localItems.remove.description'
  },
  {
    defaultMessage: 'Ano',
    id: 'order.generic.localItems.remove.confirm'
  },
  {
    defaultMessage: 'Ne',
    id: 'order.generic.localItems.remove.cancel'
  },
  {
    defaultMessage: 'Zavřít stůl',
    id: 'order.generic.closeTable'
  },
  {
    defaultMessage: 'Místnosti',
    id: 'order.relocating.rooms'
  },
  {
    defaultMessage: 'Zrušit přesun',
    id: 'order.relocating.cancel'
  },
  {
    defaultMessage: 'Vyberte místnost',
    id: 'order.relocating.chooseRoom'
  },
  {
    defaultMessage: 'Zboží není dostupné',
    id: 'order.availabilityBlocker.header'
  },
  {
    defaultMessage: 'Dostupnost',
    id: 'order.availabilityBlocker.availability'
  },
  {
    defaultMessage: 'Potvrďte nebo změňte počet.',
    id: 'order.availabilityBlocker.confirmChange'
  },
  {
    defaultMessage: 'Vybrané položky',
    id: 'order.chosenItems'
  },
  {
    defaultMessage: 'Počet v ks',
    id: 'order.page.count'
  },
  {
    defaultMessage: 'Chyba účtu - dokončit účtu',
    id: 'order.page.error'
  },
  {
    defaultMessage: 'Denní menu',
    id: 'order.fastFood.dailyMenu'
  },
  {
    defaultMessage: 'položka menu',
    id: 'order.fastFood.menuItem'
  },
  {
    defaultMessage: 'Nedostupné',
    id: 'order.fastFood.notAvailable'
  },
  {
    defaultMessage: 'Produkt je chybně nakonfigurován',
    id: 'order.fastFood.wrongConfiguration'
  },
  {
    defaultMessage: 'Menu nemá žádné položky',
    id: 'order.fastFood.noItems'
  },
  {
    defaultMessage: 'Zadejte cenu',
    id: 'order.priceOrQuantity.price'
  },
  {
    defaultMessage: 'Zadejte počet',
    id: 'order.priceOrQuantity.count'
  },
  {
    defaultMessage: 'Zadejte množství',
    id: 'order.priceOrQuantity.amount'
  },
  {
    defaultMessage: 'max. na vymazání',
    id: 'order.priceOrQuantity.maxDelete'
  },
  {
    defaultMessage: 'ks',
    id: 'order.priceOrQuantity.ks'
  },
  {
    defaultMessage: 'max. na označení',
    id: 'order.priceOrQuantity.maxSelected'
  },
  {
    defaultMessage: 'Zadejte číslo faktury',
    id: 'order.popupTextForm.number'
  },
  {
    defaultMessage: 'Talíř',
    id: 'order.popupWeightForm.plate'
  },
  {
    defaultMessage: 'kg',
    id: 'order.popupWeightForm.kg'
  },
  {
    defaultMessage: 'Bez talíře',
    id: 'order.popupWeightForm.withoutPlate'
  },
  {
    defaultMessage: 'Vážení zboží',
    id: 'order.popupWeightForm.weightItem'
  },
  {
    defaultMessage: 'Jednotková cena',
    id: 'order.popupWeightForm.unitPrice'
  },
  {
    defaultMessage: 'Aktuální cena',
    id: 'order.popupWeightForm.actualPrice'
  },
  {
    defaultMessage: 'Dostupnost',
    id: 'order.popupWeightForm.availability'
  },
  {
    defaultMessage: 'Požadované množství není dostupné',
    id: 'order.popupWeightForm.notAvailable'
  },
  {
    defaultMessage: 'Vymazání položek',
    id: 'order.sidebar.header'
  },
  {
    defaultMessage: 'Označené položky budou odstraněny, chcete je opravdu smazat?',
    id: 'order.sidebar.description'
  },
  {
    defaultMessage: 'Ano',
    id: 'order.sidebar.confirm'
  },
  {
    defaultMessage: 'Zrušit',
    id: 'order.sidebar.cancel'
  },
  {
    defaultMessage: 'z',
    id: 'order.sidebar.from'
  },
  {
    defaultMessage: 'Zaplatit v hotovosti',
    id: 'order.sidebar.payCash'
  },
  {
    defaultMessage: 'Zaplatit vybrané',
    id: 'order.sidebar.paySelected'
  },
  {
    defaultMessage: 'Zaplatit všechno',
    id: 'order.sidebar.payAll'
  },
  {
    defaultMessage: 'Potvrdit',
    id: 'order.sidebar.caption.confirm'
  },
  {
    defaultMessage: 'Všichni hosté',
    id: 'order.subTables.guests'
  },
  {
    defaultMessage: 'Jméno hosta',
    id: 'payment.pgrDotazy.guestName'
  },
  {
    defaultMessage: 'Uložit jméno',
    id: 'payment.pgrDotazy.saveName'
  },
  {
    defaultMessage: 'Platbu schválil…',
    id: 'payment.pgrDotazy.paymentApproved'
  },
  {
    defaultMessage: 'Nový úvěrový partner',
    id: 'payment.pgrDotazy.newPartner'
  },
  {
    defaultMessage: 'Pridat nového partnera',
    id: 'payment.pgrDotazy.addPartner'
  },
  {
    defaultMessage: 'Platba',
    id: 'payment.page.title'
  },
  {
    defaultMessage: 'Zavřít platbu',
    id: 'payment.page.closePayment'
  },
  {
    defaultMessage: 'Celková suma',
    id: 'payment.page.overallSum'
  },
  {
    defaultMessage: 'Suma po slevě',
    id: 'payment.page.overallSumAfterDiscount'
  },
  {
    defaultMessage: 'TIP',
    id: 'payment.page.tip'
  },
  {
    defaultMessage: 'Zůstává doplatit',
    id: 'payment.page.leftToPay'
  },
  {
    defaultMessage: 'Přeplatek (TIP)',
    id: 'payment.page.leftToPayTip'
  },
  {
    defaultMessage: 'Přeplatek',
    id: 'payment.page.leftToPaySurplus'
  },
  {
    defaultMessage: 'Zaplaceno přesně',
    id: 'payment.page.leftToPayExact'
  },
  {
    defaultMessage: 'Zadat slevu',
    id: 'payment.page.setDiscount'
  },
  {
    defaultMessage: 'Mezivýsledky',
    id: 'payment.page.subtotal'
  },
  {
    defaultMessage: 'V hotovosti',
    id: 'payment.page.cash'
  },
  {
    defaultMessage: 'Bankovní karta',
    id: 'payment.page.bankCard'
  },
  {
    defaultMessage: 'Stravenky',
    id: 'payment.page.mealVouchers'
  },
  {
    defaultMessage: 'Zpět na stůl',
    id: 'payment.page.backToTable'
  },
  {
    defaultMessage: 'Zpět na platbu',
    id: 'payment.page.backToPayment'
  },
  {
    defaultMessage: 'Uzavřít účet a vytisknout',
    id: 'payment.page.finishAndPrintReceipt'
  },
  {
    defaultMessage: 'Tisknout předúčet',
    id: 'payment.page.printPrereceipt'
  },
  {
    defaultMessage: ',',
    id: 'payment.page.dot'
  },
  {
    defaultMessage: 'Zavřít výběr slevy',
    id: 'payment.page.closeDiscountMenu'
  },
  {
    defaultMessage: 'Sleva',
    id: 'payment.page.discount'
  },
  {
    defaultMessage: 'Důvod slevy',
    id: 'payment.page.discountReason'
  },
  {
    defaultMessage: '%',
    id: 'payment.page.relative'
  },
  {
    defaultMessage: 'Kč',
    id: 'payment.page.absolute'
  },
  {
    defaultMessage: 'Platit',
    id: 'payment.page.pay'
  },
  {
    defaultMessage: 'zůstatek',
    id: 'payment.page.remaind'
  },

  {
    defaultMessage: 'Firma',
    id: 'payment.page.company'
  },
  {
    defaultMessage: 'Ulice',
    id: 'payment.page.address1'
  },
  {
    defaultMessage: 'Město, PSČ',
    id: 'payment.page.address2'
  },
  {
    defaultMessage: 'Země',
    id: 'payment.page.address3'
  },
  {
    defaultMessage: 'IČO',
    id: 'payment.page.ico'
  },
  {
    defaultMessage: 'DIČ',
    id: 'payment.page.dic'
  },
  {
    defaultMessage: 'IČ DPH',
    id: 'payment.page.icdph'
  },
  {
    defaultMessage: 'Schválil',
    id: 'payment.page.schvalil'
  },
  {
    defaultMessage: 'Poznámka',
    id: 'payment.page.txt'
  },
  {
    defaultMessage: 'Jméno',
    id: 'payment.page.name'
  },
  {
    defaultMessage: 'Registrovat paragon',
    id: 'payment.page.registerParagon'
  },
  {
    defaultMessage: 'Tiskárna',
    id: 'payment.page.printer'
  },
  {
    defaultMessage: 'Kopií',
    id: 'payment.page.copies'
  },
  {
    defaultMessage: 'Poslat účet mailem',
    id: 'payment.page.sendReceiptByEmail'
  },
  {
    defaultMessage: 'Procentuální',
    id: 'payment.contextMenus.percentageDiscount'
  },
  {
    defaultMessage: 'Absolutní',
    id: 'payment.contextMenus.absoluteDiscount'
  },
  {
    defaultMessage: 'Přirážka room service',
    id: 'payment.contextMenus.roomService'
  },
  {
    defaultMessage: 'Sleva 5%',
    id: 'payment.contextMenus.discount5Percent'
  },
  {
    defaultMessage: 'Sleva 10%',
    id: 'payment.contextMenus.discount10Percent'
  },
  {
    defaultMessage: 'Sleva 15%',
    id: 'payment.contextMenus.discount15Percent'
  },
  {
    defaultMessage: 'Sleva 20%',
    id: 'payment.contextMenus.discount20Percent'
  },
  {
    defaultMessage: 'Sleva 25%',
    id: 'payment.contextMenus.discount25Percent'
  },
  {
    defaultMessage: 'Kč',
    id: 'payment.contextMenus.currencySymbol'
  },
  {
    defaultMessage: 'Chyba při editování platby: ',
    id: 'payment.editPaymentRecord.error'
  },
  {
    defaultMessage: 'Cena',
    id: 'payment.editPaymentRecord.price'
  },
  {
    defaultMessage: 'Tip',
    id: 'payment.editPaymentRecord.tip'
  },
  {
    defaultMessage: 'Zadejte počet kopií',
    id: 'payment.numberOfCopies.header'
  },
  {
    defaultMessage: 'Poznámky k platbě',
    id: 'payment.numberOfCopies.paymentNote.header'
  },
  {
    defaultMessage: 'Chyba účtu - dokončit účet',
    id: 'payment.paymentPage.unfinishedBill'
  },
  {
    defaultMessage: 'Vyberte tiskárnu',
    id: 'payment.printerSelect'
  },
  {
    defaultMessage: 'Paragon Id',
    id: 'payment.registerParagon.id'
  },
  {
    defaultMessage: 'Datum',
    id: 'payment.registerParagon.date'
  },
  {
    defaultMessage: 'Čas',
    id: 'payment.registerParagon.time'
  },
  {
    defaultMessage: 'e-mail',
    id: 'payment.sendReceiptByEmail'
  },
  {
    defaultMessage: 'Kód kupónu',
    id: 'payment.serviceCoupon.code'
  },
  {
    defaultMessage: 'Neznámá chyba',
    id: 'priceList.unknownError'
  },
  {
    defaultMessage: 'Aktualizace ceníku',
    id: 'priceList.updateHeader'
  },
  {
    defaultMessage: 'Ceník byl aktualizován',
    id: 'priceList.updatedInfo'
  },
  {
    defaultMessage: 'Probíhá aktualizace…',
    id: 'priceList.updatingInfo'
  },
  {
    defaultMessage: 'Aktualizovat nyní',
    id: 'priceList.updateNow'
  },
  {
    defaultMessage: 'TIP byl přidán',
    id: 'receipts.tipAdded'
  },
  {
    defaultMessage: 'Zadejte TIP',
    id: 'receipts.enterTip'
  },
  {
    defaultMessage: 'Výběr platby',
    id: 'receipts.choosePayment'
  },
  {
    defaultMessage: 'Dokončení bločku',
    id: 'receipts.finishBill'
  },
  {
    defaultMessage: 'Znovu vytisknout bloček',
    id: 'receipts.printBillRepeat'
  },
  {
    defaultMessage: 'Zrušit platbu',
    id: 'receipts.cancelPayment'
  },
  {
    defaultMessage: 'Error pri vypsání položek účtenky: ',
    id: 'receipts.sheetItemsError'
  },
  {
    defaultMessage: 'Žádné položky',
    id: 'receipts.sheetNoItems'
  },
  {
    defaultMessage: 'žádný záznam',
    id: 'receipts.noData'
  },
  {
    defaultMessage: 'Účty z min. uzávěrek',
    id: 'receipts.previous'
  },
  {
    defaultMessage: 'Účty v aktuál. uzávěrce',
    id: 'receipts.actual'
  },
  {
    defaultMessage: 'Id',
    id: 'receipts.table.id'
  },
  {
    defaultMessage: 'Datum',
    id: 'receipts.table.date'
  },
  {
    defaultMessage: 'stůl',
    id: 'receipts.table.table'
  },
  {
    defaultMessage: 'Platby',
    id: 'receipts.table.payments'
  },
  {
    defaultMessage: 'Uživatel',
    id: 'receipts.table.user'
  },
  {
    defaultMessage: 'Probíhá operace s účty',
    id: 'receipts.operationInProgress'
  },
  {
    defaultMessage: 'Účtenka stornována',
    id: 'receipts.storno'
  },
  {
    defaultMessage: 'Položka účtenky stornována',
    id: 'receipts.item.storno'
  },
  {
    defaultMessage: 'Účtenka byla odeslána k tisku',
    id: 'receipts.send.printer'
  },
  {
    defaultMessage: 'Dokončit účet',
    id: 'receipts.finish'
  },
  {
    defaultMessage: 'Stornovat účet',
    id: 'receipts.cancel.receipt'
  },
  {
    defaultMessage: 'Stornovat vybrané',
    id: 'receipts.cancel.chosen'
  },
  {
    defaultMessage: 'Storno a přesun na stůl',
    id: 'receipts.cancel.moveTable'
  },
  {
    defaultMessage: 'Tisk kopie účtu',
    id: 'receipts.print.copy'
  },
  {
    defaultMessage: 'Změnit platby',
    id: 'receipts.payment.change'
  },
  {
    defaultMessage: 'Přidat TIP',
    id: 'receipts.add.tip'
  },
  {
    defaultMessage: 'Zrušit',
    id: 'receipts.cancel'
  },
  {
    defaultMessage: 'Zrušit přesun na stůl',
    id: 'receipts.cancel.move'
  },
  {
    defaultMessage: 'Vklad proběhl úspěšně',
    id: 'serviceAreas.depositCompleted'
  },
  {
    defaultMessage: 'Výběr proběhl úspěšně',
    id: 'serviceAreas.withdrawalCompleted'
  },
  {
    defaultMessage: 'Vklad',
    id: 'serviceAreas.deposit'
  },
  {
    defaultMessage: 'Výběr',
    id: 'serviceAreas.withdrawal'
  },
  {
    defaultMessage: 'Nemáte právo na čtení stolů',
    id: 'serviceAreas.tablesGrid.accessDenied'
  },
  {
    defaultMessage: 'Aktuálně',
    id: 'usage.actual'
  },
  {
    defaultMessage: 'Za období',
    id: 'usage.range'
  },
  {
    defaultMessage: 'Kategorie',
    id: 'usage.table.category'
  },
  {
    defaultMessage: 'Prodané množství',
    id: 'usage.table.soldAmount'
  },
  {
    defaultMessage: 'Statistika byla odeslána k tisku',
    id: 'usage.sendPrintStatistics'
  },
  {
    defaultMessage: 'Vytisknout statistiku',
    id: 'usage.printStatistics'
  },
  {
    defaultMessage: 'Zrušit',
    id: 'usage.cancel'
  },
  {
    defaultMessage: 'Probíhá operace',
    id: 'serviceAreas.editables.operationInProgress'
  },
  {
    defaultMessage: 'Otevřené stoly',
    id: 'serviceAreas.options.openTables'
  },
  {
    "id": "app.buttons.add",
    "defaultMessage": "Přidat",
  },
  {
    "id": "app.buttons.cancel",
    "defaultMessage": "Zrušit",
  },
  {
    "id": "app.buttons.delete",
    "defaultMessage": "Smazat",
  },
  {
    "id": "app.buttons.dismiss",
    "defaultMessage": "Zrušit",
  },
  {
    "id": "app.buttons.edit",
    "defaultMessage": "Editovat",
  },
  {
    "id": "app.buttons.save",
    "defaultMessage": "Uložit",
  },
  {
    "id": "app.buttons.signIn",
    "defaultMessage": "Přihlášení",
  },
  {
    "id": "app.buttons.signOut",
    "defaultMessage": "Odhlásit se",
  },
  {
    "id": "app.buttons.signUp",
    "defaultMessage": "Registrace",
  },
  {
    "id": "app.buttons.submit",
    "defaultMessage": "Odeslat",
  },
  {
    "id": "app.confirm.areYouSure",
    "defaultMessage": "Jste si jistý?",
  },
  {
    "id": "app.confirm.unsavedChanges",
    "defaultMessage": "Máte neuložené změny. Jste si jistý?",
  },
  {
    "id": "app.links.fields",
    "defaultMessage": "Fields",
  },
  {
    "id": "app.links.home",
    "defaultMessage": "Úvod",
  },
  {
    "id": "app.links.intl",
    "defaultMessage": "Lokalizace",
  },
  {
    "id": "app.links.me",
    "defaultMessage": "Já",
  },
  {
    "id": "app.links.notFound",
    "defaultMessage": "Stránka nenalezena",
  },
  {
    "id": "app.links.offline",
    "defaultMessage": "Offline",
  },
  {
    "id": "app.links.profile",
    "defaultMessage": "Profil",
  },
  {
    "id": "app.links.settings",
    "defaultMessage": "Nastavení",
  },
  {
    "id": "app.links.signIn",
    "defaultMessage": "Přihlášení",
  },
  {
    "id": "app.links.order",
    "defaultMessage": "Objednávaní",
  },
  {
    "id": "app.links.todos",
    "defaultMessage": "Úkoly",
  },
  {
    "id": "app.links.users",
    "defaultMessage": "Uživatelé",
  },
  {
    "id": "auth.social.facebookSignIn",
    "defaultMessage": "Přihlášení přes Facebook",
  },
  {
    "id": "footer.madeByHtml",
    "defaultMessage": "Stvořil",
  },
  {
    "id": "intl.page.unreadCount",
    "defaultMessage": "{unreadCount, plural, one {zpráva} other {zpráv}}",
  },
  {
    "id": "loading.loadingText",
    "defaultMessage": "Nahrávám",
  },
  {
    "id": "loading.longLoadingText",
    "defaultMessage": "Stále nahrávám...",
  },
  {
    "id": "notFound.continue",
    "defaultMessage": "Pokračujte zde prosím.",
  },
  {
    "id": "notFound.h1",
    "defaultMessage": "Tato stránka není dostupná",
  },
  {
    "id": "notFound.p",
    "defaultMessage": "Možná máte špatný odkaz, nebo byla stránka odstraněna.",
  },
  {
    "id": "todos.buttons.add100",
    "defaultMessage": "Přidat 100 úkolů",
  },
  {
    "id": "todos.buttons.clearAll",
    "defaultMessage": "Vymazat vše",
  },
  {
    "id": "todos.buttons.clearCompleted",
    "defaultMessage": "Vymazat hotové",
  },
  {
    "id": "todos.leftTodos",
    "defaultMessage": "{leftTodos, plural, =0 {Nic, užívej :-)} one {Máš {leftTodos} úkol} other {Máš {leftTodos} úkolů}}",
  },
  {
    "id": "todos.newTodo.placeholder",
    "defaultMessage": "Co je třeba udělat?",
  },
  {
    "id": "todos.todos.empty",
    "defaultMessage": "Nic tu není...",
  }
];
