// @flow
import InactiveBg from '../components/InactiveBg';
import Popup from '../components/Popup';
import React from 'react';
import Heading from '../../common/components/Heading';
import createTypewriterOperations from '../../common/components/Keyboard/typewriter/operations';
import TypewriterButtons from '../../common/components/Keyboard/typewriter/Buttons';
import Keyboard from '../../common/components/Keyboard/index';
import Box from '../../common/components/Box';
import TextInput from '../../common/components/TextInput';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import { clone } from 'ramda';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from '../../common/messages/payment';

class PaymentNotePopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      notes: Array(4).fill(''),
      focusedNote: 0
    };
  }

  focusNote = (noteIndex) => {
    this.setState({ focusedNote: noteIndex });
  };

  resetValue = (noteIndex) => {
    this.sendKey(noteIndex, 'cancel');
  };

  sendKey = (noteIndex, key) => {
    if (key.toLowerCase() === 'enter' || key.toLowerCase() === 'Enter') {
      if (noteIndex < 3) {
        const newFocusedNoteIndex = noteIndex + 1;
        this.setState({
          focusedNote: newFocusedNoteIndex
        });

        if (this[`input${newFocusedNoteIndex}`]) {
          this[`input${newFocusedNoteIndex}`].focus();
        }
      }
      return;
    }

    const { notes } = clone(this.state);
    let noteValue = notes[noteIndex];

    switch (key) {
      case 'del':
        noteValue = `${noteValue.substring(0, noteValue.length - 1)}`;
        break;
      case 'cancel':
        noteValue = '';
        break;
      case 'send':
        break;
      default:
        // fixme - not a very nice solution :)
        //  -- copied from src/common/components/Keyboard/typewriter/operations.js
        if (noteValue === '') {
          const newKey = key.substring(0, 2) === '; '
            ? key.substring(2)
            : key;
          noteValue = `${noteValue}${newKey}`;
        } else {
          noteValue = `${noteValue}${key}`;
        }
    }

    notes.splice(noteIndex, 1, noteValue);
    this.setState({ notes });
  };

  NoteDisplay = ({ index, value, isFocused, sendKey, focusNote, resetValue }) => (
    <Box
      key={index}
      flexBasis="100%"
      marginBottom={0.5}
      onMouseDown={() => focusNote(index)}
      onFocus={() => focusNote(index)}
    >
      <TextInput
        nativeRef={c => { this[`input${index}`] = c; }}
        autoFocus={index === 0}
        maxLength={20}
        padding={1}
        scale={2}
        color="white"
        backgroundColor={isFocused ? 'lessDarkGray' : 'darkGray'}
        value={value}
        onKeyPress={({ key }) => {
          sendKey(index, key);
        }}
        onKeyDown={({ keyCode }) => {
          if (keyCode === 8) { // backspace
            sendKey(index, 'del');
          }
        }}
      />
      <Button
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        paddingHorizontal={0.75}
        alignItems="center"
        onPress={(e) => {
          e.stopPropagation();
          resetValue(index);
        }}
      >
        <Icon color="white" name="delete" scale={2} />
      </Button>
    </Box>
  );

  render() {
    const { onClose, onSubmit, intl } = this.props;
    const { notes, focusedNote } = this.state;

    return (
      <InactiveBg zIndex={3000} onClose={onClose}>
        <Popup onClose={onClose}>
          <Heading scale={2} color="white" minWidth={28}>{intl.formatMessage(messages.paymentPaymentNoteHeader)}</Heading>

          {notes.map((noteValue, index) =>
              this.NoteDisplay({
                index,
                value: noteValue,
                isFocused: index === focusedNote,
                sendKey: this.sendKey,
                focusNote: this.focusNote,
                resetValue: this.resetValue
              }))}

          <Keyboard
            createOperations={createTypewriterOperations}
            Buttons={TypewriterButtons}
            onLastKey={(key, val) => {
              this.sendKey(focusedNote, key);
              if (key === 'send') {
                onSubmit(notes);
              }
            }}
          />
        </Popup>
      </InactiveBg>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(PaymentNotePopup);
