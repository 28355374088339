// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  usageActual: {
    defaultMessage: 'Aktuálne',
    id: 'usage.actual'
  },
  usageRange: {
    defaultMessage: 'Za obdobie',
    id: 'usage.range'
  },
  usageTableCategory: {
    defaultMessage: 'Kategória',
    id: 'usage.table.category'
  },
  usageSoldAmount: {
    defaultMessage: 'Predané množstvo',
    id: 'usage.table.soldAmount'
  },
  usageSendPrintStatistics: {
    defaultMessage: 'Štatistika bola odoslaná na tlač',
    id: 'usage.sendPrintStatistics'
  },
  usagePrintStatistics: {
    defaultMessage: 'Vytlačiť štatistiku',
    id: 'usage.printStatistics'
  },
  usageCancel: {
    defaultMessage: 'Zrušiť',
    id: 'usage.cancel'
  },
});
