// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  tableName: {
    defaultMessage: 'Názov stolu',
    id: 'serviceAreas.editables.tableName'
  },
  operationInProgress: {
    defaultMessage: 'Prebieha operácia',
    id: 'serviceAreas.editables.operationInProgress'
  }
});
