// @flow
import configureMiddleware from './configureMiddleware';
import configureReducer from './configureReducer';
import configureOffline from './configureOffline';
import { applyMiddleware, compose, createStore } from 'redux';
import { createOffline } from '@redux-offline/redux-offline';
import { injectStore as injectStoreIntoLookUpIdService } from './localIds/lookUpService';
import { isReactNative } from './app/detectPlatform';
import { batchStoreEnhancer, batchMiddleware, batchActions } from 'redux-batch-enhancer';

type Options = {
  initialState: Object,
  platformDeps?: Object,
  platformReducers?: Object,
  platformMiddleware?: Array<Function>,
  platformStoreEnhancers?: Array<Function>,
};

const configureStore = (options: Options) => {
  const {
    initialState,
    enhanceRootReducer,
    platformDeps = {},
    platformMiddleware = [],
    platformEpics = [],
    platformReducers = {},
    platformStoreEnhancers = [],
  } = options;

  const reducer = configureReducer(platformReducers, enhanceRootReducer);

  const middleware = configureMiddleware(
    initialState,
    platformDeps,
    platformMiddleware,
    platformEpics
  );

  const {
    middleware: offlineMiddleware,
    enhanceReducer: offlineEnhanceReducer,
    enhanceStore: offlineEnhanceStore
  } = createOffline(configureOffline());

  const arraysAsBatch = () => (next: any) => (action: any) =>
    next(Array.isArray(action) ? batchActions(action) : action);

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) :
    compose;

  const store = createStore(
    offlineEnhanceReducer(reducer),
    initialState,
    composeEnhancers(
      offlineEnhanceStore,
      applyMiddleware(arraysAsBatch, batchMiddleware, ...middleware, offlineMiddleware),
      ...platformStoreEnhancers,
      batchStoreEnhancer
    )
  );

  injectStoreIntoLookUpIdService(store);
  // configureFetchEvents(store);

  // Enable hot reloading for reducers.
  if (module.hot && typeof module.hot.accept === 'function') {
    if (isReactNative) {
      // React Native for some reason needs accept without the explicit path.
      // facebook.github.io/react-native/blog/2016/03/24/introducing-hot-reloading.html
      module.hot.accept(() => {
        const configureReducer = require('./configureReducer').default;

        store.replaceReducer(configureReducer(platformReducers, enhanceRootReducer));
      });
    } else {
      // Webpack for some reason needs accept with the explicit path.
      module.hot.accept('./configureReducer', () => {
        const configureReducer = require('./configureReducer').default;

        store.replaceReducer(configureReducer(platformReducers, enhanceRootReducer));
      });
    }
  }

  return store;
};

export default configureStore;
