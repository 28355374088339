// @flow
import type { ApiReceiptItem, Id } from '../../types';
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';

export const getReceipts = ({
  fromDay,
  toDay,
  afterId,
  receiptId
}): Promise =>
  fetch(apiEndpoint('v1/Receipts'), {
    baseURL: getApiUrl(),
    params: {
      from: fromDay,
      to: toDay,
      id: afterId || '',
      receipt_id: receiptId
    }
  });

export const getUnclosedReceipts = ({
  afterId,
  receiptId
}): Promise =>
  fetch(apiEndpoint('v1/Receipts'), {
    baseURL: getApiUrl(),
    params: {
      id: afterId || '',
      receipt_id: receiptId
    }
  });

export const getReceiptItems = (
  receipt: Id,
  closure: Id
): Promise =>
  fetch(apiEndpoint('v1/ReceiptItems'), {
    baseURL: getApiUrl(),
    params: {
      ucet: receipt,
      c_uzaverka: closure || '-1'
    }
  });

export const cancelReceipt = (
  receipt: Id,
  closure: Id
): Promise =>
  fetch(apiEndpoint('v1/pay/cancel'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        closure: closure || '-1',
        bill: receipt
      }
    }
  });

export const cancelReceiptItem = (
  receipt: Id,
  closure: Id,
  receiptItems: ApiReceiptItem[]
): Promise =>
  fetch(apiEndpoint('v1/pay/cancel'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        closure: closure || '-1',
        bill: receipt,
        items: receiptItems
      }
    }
  });

export const cancelReceiptAndReturnToTable = (
  receipt: Id,
  closure: Id,
  subTableId: Id
): Promise =>
  fetch(apiEndpoint('v1/pay/cancel'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        closure: closure || '-1',
        bill: receipt,
        sub_table_id: subTableId
      }
    }
  });

export const printReceipt = (
  receipt: Id,
  closure: Id
): Promise =>
  fetch(apiEndpoint('v1/pay/reprint'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        closure: closure || '-1',
        bill: receipt,
      }
    }
  });

export const getPayments = (
  receipt: Id,
  closure: Id
): Promise =>
  fetch(apiEndpoint(`v1/Receipts/Payments/${closure || ''}/${receipt || ''}`), {
    method: 'GET',
    baseURL: getApiUrl()
  });

export const setTip = (
  id,
  amount
): Promise =>
  fetch(apiEndpoint('v1/Receipts/Tip'), {
    method: 'POST',
    baseURL: getApiUrl(),
    body: {
      params: {
        id,
        amount
      }
    }
  });

export const sendUnsendReceipts = (): Promise =>
  fetch(apiEndpoint('v1/fiskal/send_unsent'), {
    baseURL: getApiUrl(),
  });

export const openDrawer = (): Promise =>
  fetch(apiEndpoint('v1/fiskal/open_drawer'), {
    baseURL: getApiUrl(),
  });
