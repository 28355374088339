// @flow
import React from 'react';
import Box from './Box';

type RadioProps = {
  color: string,
  size: number,
  checked?: boolean
};

const borderWidth = 2;

const Radio = ({ size = 1, color = 'black', checked, ...restProps }: RadioProps) => (
  <Box
    justifyContent="center"
    alignItems="center"
    backgroundColor="white"
    borderColor={color}
    borderStyle="solid"
    borderWidth={borderWidth * size}
    width={size}
    height={size}
    flexGrow={1}
    flexShrink={0}
    flexBasis={1}
    {...restProps}
  >
    {checked ?
      <Box
        backgroundColor={color}
        style={theme => ({
          width: theme.typography.lineHeight() * size - (borderWidth * size * 2) - (6 * size),
          height: theme.typography.lineHeight() * size - (borderWidth * size * 2) - (6 * size)
        })}
      />
      : null }
  </Box>
);

export default Radio;
