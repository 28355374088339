// @flow
import type { Action, Deps, Id, OpenTable, SubTable, TableDefinition } from '../types';
import { getFoodCourseIncrement } from './utils';
import api from '../lib/api';
import { addLocalId } from '../localIds/actions';
import {find, pathOr, propEq} from 'rambda';
import { completeOffline } from '../network/actions';
import { addOrderLine, confirmOrderLine, updateOrderLinesLocal } from '../order/actions';

export const focusOnTable = (tableId: Id, openTableId: Id) => ({
  type: 'TABLES_FOCUS',
  payload: {
    tableId,
    openTableId
  }
});

export const editTable = (tableId: Id, detail) => ({
  type: 'TABLES_EDIT_DEFINITION',
  payload: {
    id: tableId,
    detail
  },
});

export const updateTableDefinitionCommit = (id: Id, tableDefinition: TableDefinition, flags = {}) =>
({
  type: 'TABLE_DEFINITION_UPDATE_COMMIT',
  payload: {
    id,
    tableDefinition,
    flags
  }
});

const updateTableDefinitionRollback = (id: Id) => ({
  type: 'TABLE_DEFINITION_UPDATE_ROLLBACK',
  payload: {
    id
  }
});

export const updateTableDefinition = (id: Id, tableDefinition: TableDefinition) => (deps) => ({
  type: 'TABLE_DEFINITION_UPDATE',
  payload: {
    id,
    tableDefinition,
  },
  meta: {
    offline: {
      effect: () => {
        const attrs = {
          tableDefinitionId: deps.lookUpId(id),
          ...tableDefinition
        };

        delete attrs.id;
        delete attrs._old;

        return api.tableDefinition.saveTableDefinition(attrs);
      },
      // TODO ak v response su udaje, tak ich zober
      commit: () => [
        completeOffline(),
        updateTableDefinitionCommit(id, { ...tableDefinition, id: deps.lookUpId(id) }),
      ],
      rollback: () => [
        completeOffline(),
        updateTableDefinitionRollback(id)
      ]
    }
  }
});

const addTableRollback = (id: Id) => ({
  type: 'TABLE_DEFINITION_ADD_ROLLBACK',
  payload: {
    id
  }
});

export const addTableDefinition =
(tableDefinition: TableDefinition, flags = {}) => (deps: Deps) => {
  const id = flags.id || deps.getUid();

  return {
    type: 'TABLE_DEFINITION_ADD',
    payload: {
      tableDefinition: { ...tableDefinition, id },
      flags
    },
    meta: {
      offline: {
        effect: () => api.tableDefinition.saveTableDefinition(tableDefinition),
        commit: ({
          payload: {
            body: {
              diff: {
                insert: {
                  TableDefinition: [TableDefinition]
                }
              }
            }
           }
        }) => {
          const actions = [
            completeOffline(),
            addLocalId({ [id]: TableDefinition.id }, 'tableDefinitionId'),
            updateTableDefinitionCommit(id, TableDefinition, flags)
          ];
          if (flags && flags.orderLinesForConfirm) {
            const orderLinesForConfirmWithDef = flags.orderLinesForConfirm.map(o => ({ ...o, tableDefinitionId: TableDefinition.id }));
            actions.push(addOpenTable({
              tableDefinitionId: TableDefinition.id,
              serviceAreaId: TableDefinition.serviceAreaId,
              name: TableDefinition.name
            }, {
              orderLinesForConfirm: orderLinesForConfirmWithDef
            }));
          }

          return actions;
        },
        rollback: () => [
          completeOffline(),
          addTableRollback(id),
        ]
      }
    }
  };
};

export const deleteTableDefinitionCommit = (id: Id) => ({
  type: 'TABLE_DEFINITION_DELETE_COMMIT',
  payload: { id }
});

const deleteTableDefinitionRollback = (id: Id) => ({
  type: 'TABLE_DEFINITION_DELETE_ROLLBACK',
  payload: { id }
});

export const deleteTableDefinition = (id: Id) => ({ lookUpId }) => ({
  type: 'TABLE_DEFINITION_DELETE',
  payload: {
    id
  },
  meta: {
    offline: {
      // TODO v effecte sa spytaj, ci stol nie je _local. Ak ano, sprav commit bez networku
      effect: () => api.tableDefinition.deleteTableDefinition(lookUpId(id)),
      commit: () => [
        completeOffline(),
        deleteTableDefinitionCommit(lookUpId(id))
      ],
      rollback: () => [
        completeOffline(),
        deleteTableDefinitionRollback(lookUpId(id))
      ]
    }
  }
});

export const updateTableGeometry = (updateTable: TableDefinition) => deps => {
  // TODO send only one table (calc with default scale so other tables' geometry are unchanged)
  // const state: State = getState();
  // const tables = tablesInServiceArea(state.tables.tableDefinitions, updateTable.serviceAreaId);
  //
  // const tablesWithNewGeometry = updateTablesGeometry(tables, updateTable);
  //
  // naive approach
  // forEach((table: TableDefinition) =>
  //   dispatch(updateTableDefinition(table.id, { geometry: table.geometry })),
  //   tablesWithNewGeometry
  // );

  // return { type: 'VOID' };
  return updateTableDefinition(updateTable.id, { geometry: updateTable.geometry })(deps);
};

export const toggleTablesPositionEditing = (toggle: boolean) => ({
  type: 'TABLES_TOGGLE_POSITION_EDITING',
  payload: toggle
});

export const addFoodCourse = (openTableId: Id) => ({ getState }: Deps): Action => ({
    type: 'FOOD_COURSE_ADD',
    payload: {
      foodCourse: {
        id: getFoodCourseIncrement(openTableId, getState()),
        openTableId
      },
    }
  });
export const injectFoodCourses = (foodCoursesIds: Id[], openTableId: Id): Action => ({
    type: 'FOOD_COURSE_INJECT',
    payload: {
      foodCoursesIds,
      openTableId
    }
  });

export const deleteFoodCourse = (id: Id): Action => ({
  type: 'FOOD_COURSE_DELETE',
  payload: { id },
});

export const activateFoodCourse = (foodCourse: Id): Action => ({
    type: 'FOOD_COURSE_ACTIVATE',
    payload: { foodCourse }
  });

export const updateSubTableCommit = (id: Id, subTable: SubTable, flags = {}): Action => ({
  type: 'SUB_TABLE_UPDATE_COMMIT',
  payload: {
    id,
    subTable: {
      ...subTable,
      isOpen: true
    },
    flags
  }
});

export const addSubTableRollback = (id: Id): Action => ({
  type: 'SUB_TABLE_ADD_ROLLBACK',
  payload: {
    id
  }
});

export const addSubTableLocal = (openTableId: Id, flags) => ({ getUid, lookUpId }) => ({
  type: 'SUB_TABLE_ADD',
  payload: {
    subTable: {
      openTableId: lookUpId(openTableId),
      id: getUid()
    },
    flags
  }
});

export const addSubTable = (openTableId: Id, flags): Action => (deps: Deps) => {
  const local = addSubTableLocal(openTableId, flags)(deps);
  const { payload: { subTable: { id } } } = local;

  return ({
    ...local,
    meta: {
      offline: {
        effect: () => api.subTable.createSubTable(openTableId),
        commit: ({ payload: { body: { result: { subTableId, subTableName, ...rest } } } }) => {
          // TODO move items from local subtable to this one
          // TODO delete old local id then, or just update the old subtable?
          const orderLinesToBeConfirmed = [];
          const orderLinesToBeUpdated = [];
          const { orderLinesForConfirm = [], orderLinesToBeAdded = [] } = flags || {};

          if (orderLinesForConfirm) {
            orderLinesForConfirm.forEach(orderLine => {
              if (orderLine.isSentToKitchen) {
                orderLinesToBeConfirmed.push({ ...orderLine, subTableId });
              } else {
                orderLinesToBeUpdated.push({ ...orderLine, subTableId });
              }
            });
          }

          const subTable = {
            id: subTableId,
            name: subTableName,
            openTableId: deps.lookUpId(openTableId),
            ...rest
          };

          const actions = [
            completeOffline(),
            addLocalId({ [id]: subTableId }, 'subTableId'),
            updateSubTableCommit(id, subTable, flags)
          ];

          if (orderLinesToBeConfirmed.length > 0) {
            actions.push(
              confirmOrderLine(orderLinesToBeConfirmed, flags)
            );
          }

          if (orderLinesToBeUpdated.length > 0) {
            actions.push(
              updateOrderLinesLocal(orderLinesToBeUpdated, flags)
            );
          }

          if (orderLinesToBeAdded.length > 0) {
            orderLinesToBeAdded.forEach(o => {
              actions.push(
                addOrderLine(o, flags)
              );
            });
          }

          return actions;
        },
        rollback: () => [
          completeOffline(),
          addSubTableRollback(id)
        ]
      }
    }
  });
};

export const deleteSubTableCommit = (id: Id): Action => ({
  type: 'SUB_TABLE_DELETE_COMMIT',
  payload: { id }
});

export const deleteSubTableRollback = (id: Id) => ({
  type: 'SUB_TABLE_DELETE_ROLLBACK',
  payload: { id }
});

export const deleteSubTable = (id: Id) => ({ lookUpId }) => ({
  type: 'SUB_TABLE_DELETE',
  payload: {
    id
  },
  meta: {
    offline: {
      effect: () => api.subTable.deleteSubTable(lookUpId(id)),
      commit: () => [
        completeOffline(),
        deleteSubTableCommit(lookUpId(id))
      ],
      rollback: () => [
        completeOffline(),
        deleteSubTableRollback(lookUpId(id))
      ]
    }
  }
});

export const activateSubTable = (subTableId: Id): Action => ({
  type: 'SUB_TABLE_ACTIVATE',
  payload: {
    subTableId
  }
});

export const deactivateSubTable = (): Action => ({
  type: 'SUB_TABLE_DEACTIVATE',
  payload: {}
});

export const updateOpenTableCommit = (id: Id, openTable: OpenTable, flags = {}) => ({
  type: 'OPEN_TABLE_UPDATE_COMMIT',
  payload: {
    id,
    openTable,
    flags
  }
});

export const addOpenTableRollback = (id: Id) => ({
  type: 'OPEN_TABLE_ADD_ROLLBACK',
  payload: {
    id
  }
});

export const addOpenTableLocal = (openTable: OpenTable, flags = {}) => ({ getUid }) => {
  const id = getUid();

  return {
    type: 'OPEN_TABLE_ADD',
    payload: {
      openTable: { id, ...openTable },
      flags
    }
  };
};

// somehow hack-ish action (need when createing one-time table)
export const addOpenTableRemote = (localId: Id, openTable: OpenTable, flags = {}) =>
(deps: Deps) => ({
  type: 'VOID',
  meta: {
    offline: {
      effect: () => api.openTable.addOpenTable({
        ...openTable,
        tableDefinitionId: deps.lookUpId(openTable.tableDefinitionId),
        tableName: openTable.name,
        name: undefined
      }),
      commit: ({ payload: { body } }) => {
        const { result: { isNew, openTableId, subTableId, tableName } } = body;
        const state = deps.getState();

        if (isNew) {
          const { diff: { insert: { OpenTable, SubTable } } } = body;

          const subTable = SubTable[0];

          const existingSubTable = find(subTable =>
            deps.lookUpId(subTable.openTableId) === localId, state.tables.subTables);

          const { orderLinesForConfirm } = flags || {};

          const orderLinesToBeConfirmed = [];
          const orderLinesToBeUpdated = [];

          if (orderLinesForConfirm) {
            orderLinesForConfirm.forEach(orderLine => {
              if (orderLine.isSentToKitchen) {
                orderLinesToBeConfirmed.push({ ...orderLine, openTableId, subTableId });
              } else {
                orderLinesToBeUpdated.push({ ...orderLine, openTableId, subTableId });
              }
            });
          }

          const actions = [
            completeOffline(),
            addLocalId({ [localId]: OpenTable[0].id }, 'openTableId'),
            updateOpenTableCommit(localId, OpenTable[0], { ...flags, subTable }),

            addLocalId({ [existingSubTable.id]: subTable.id }, 'subTableId'),
            updateSubTableCommit(existingSubTable.id, {
              ...subTable,
              openTableId
            }, { ...flags, subTable })
          ];

          if (orderLinesToBeConfirmed.length > 0) {
            actions.push(
              confirmOrderLine(orderLinesToBeConfirmed, flags)
            );
          }

          if (orderLinesToBeUpdated.length > 0) {
            actions.push(
              updateOrderLinesLocal(orderLinesToBeUpdated, flags)
            );
          }

          return actions;
        }

        const subTable = find(propEq('id', subTableId), state.tables.subTables);

        return [
          completeOffline(),

          addLocalId({ [localId]: openTableId }, 'openTableId'),
          updateOpenTableCommit(
            localId,
            { id: openTableId, name: tableName },
            { ...flags, subTableId }
          ),
          subTable
            ? updateSubTableCommit(subTableId, { ...subTable, openTableId }, flags)
            : addSubTable(openTableId), // TODO here get subtable from server
        ];
      },
      rollback: () => [
        completeOffline(),
        addOpenTableRollback(localId)
      ]
    }
  }
});

export const addOpenTable = (openTable: OpenTable, flags = {}) => (deps: Deps) => {
  const local = addOpenTableLocal(openTable, flags)(deps);
  const { payload: { openTable: { id } } } = local;

  return {
    ...local,
    meta: addOpenTableRemote(id, openTable, flags)(deps).meta
  };
};

export const deleteOpenTableCommit =
(id: Id, deleteTableDefinitionId: Id, deleteSubTableIds: Id[]) => ({
  type: 'OPEN_TABLE_DELETE_COMMIT',
  payload: {
    id,
    willDeleteTableDefinitionId: deleteTableDefinitionId,
    willDeleteSubTablesId: deleteSubTableIds
  }
});

const deleteOpenTableRollback = (id: Id) => ({
  type: 'OPEN_TABLE_DELETE_ROLLBACK',
  payload: {
    id
  }
});

const openTableDeleteTerminated = (id: Id, deleteTableDefinitionId?: Id) => ({
  type: 'OPEN_TABLE_DELETE_TERMINATED',
  payload: {
    id,
    deleteTableDefinitionId
  },
  meta: {
    offline: {
      effect: () => {
        return Promise.resolve({ body: { result: {} } });
      },
      commit: () => [completeOffline()],
      rollback: () => [completeOffline()]
    }
  }
});

// TODO delete table definition immediately (faster UI) + rollback
export const deleteOpenTable = (id: Id, deleteTableDefinitionId?: Id) => ({ lookUpId, getState }) => {
  const outbox = pathOr([], 'offline.outbox', getState());
  const relocatingPreviousTablesInOutbox = outbox.filter(o => o.type === 'RELOCATING_PREVIOUS_OPEN_TABLE_IDS');
  const relocationFromTablesIds = [];
  if (relocatingPreviousTablesInOutbox && relocatingPreviousTablesInOutbox.length > 0) {
    relocatingPreviousTablesInOutbox.forEach(relocation => {
      const previousOpenTableIds = pathOr([], 'payload.previousOpenTableIds', relocation);
      previousOpenTableIds.forEach(id => {
        relocationFromTablesIds.push(id);
      });
    });
  }

  // will be deleted after relocation
  const isRelocatedFrom = !!relocationFromTablesIds.find(relocatedFromId => lookUpId(relocatedFromId) === lookUpId(id));
  if (isRelocatedFrom) {
    return openTableDeleteTerminated(lookUpId(id), deleteTableDefinitionId);
  }

  return ({
    type: 'OPEN_TABLE_DELETE',
    payload: {
      id: lookUpId(id)
    },
    meta: {
      offline: {
        effect: () => api.openTable.deleteOpenTable(lookUpId(id)),
        commit: ({ payload: { body: { result: { subTableId: subTableIds } } } }) => [
          completeOffline(),
          deleteOpenTableCommit(lookUpId(id), lookUpId(deleteTableDefinitionId), subTableIds)
        ],
        rollback: () => [
          completeOffline(),
          deleteOpenTableRollback(lookUpId(id))
        ]
      }
    }
  });
}

export const activateOpenTable = (id: Id, flags) => ({
  type: 'OPEN_TABLE_ACTIVATE',
  payload: {
    id, flags
  }
});

export const deactivateOpenTable = () => ({
  type: 'OPEN_TABLE_DEACTIVATE',
  payload: {}
});

export const toggleAdminMenu = (open: boolean) => ({
  type: 'ADMIN_MENU_TOGGLE',
  payload: {
    open
  }
});

export const resetState = () => ({
  type: 'TABLES_RESET'
});
