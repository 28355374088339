// @flow
import React from 'react';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import type { State } from '../../../common/types';
import { VelocityTransitionGroup } from 'velocity-react';
import Receipts from '../../../common/components/SheetRenderes/Receipts';
import AdminSheetRow from '../../../common/components/SheetRenderes/AdminSheetRow';
import PrimaryRenderer from '../../../common/components/SheetRenderes/PrimaryRenderer';
import {equals, map, contains, compose} from 'rambda';
import { connect } from 'react-redux';
import api from '../../../common/lib/api';
import { processReceivedReceiptItems } from '../../../common/admin/receipts/utils';
import { clearSelectedReceiptItems, toggleReceiptItemSelect } from '../../../common/admin/receipts/actions';
import { addAdminError } from '../../../common/admin/general/actions';
import OrderLineSheetDecorations
  from '../../../common/components/OrderLineSheet/OrderLineSheetDecorations';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/receipts';


class ReceiptItemsSheet extends React.PureComponent {
  constructor(props) {
    super(props);

    const { dispatch, activeReceipt, intl } = props;

    this.state = {
      receiptItems: []
    };

    if (activeReceipt) {
      dispatch(clearSelectedReceiptItems());
      api.receipt.getReceiptItems(activeReceipt.id, activeReceipt.closureId)
        .then(({ body }) => {
          this.setState({ receiptItems: processReceivedReceiptItems(body, activeReceipt.date) });
        })
        .catch(e => {
          console.error(intl.formatMessage(messages.receiptsSheetItemsError), e);
          dispatch(addAdminError(e));
        });
    }
  }

  componentWillUpdate(nextProps) {
    const { dispatch, activeReceipt, intl } = this.props;
    const { activeReceipt: nextActiveReceipt } = nextProps;

    if (!equals(activeReceipt, nextActiveReceipt) && nextActiveReceipt) {
      dispatch(clearSelectedReceiptItems());
      api.receipt.getReceiptItems(nextActiveReceipt.id, nextActiveReceipt.closureId)
        .then(({ body }) => {
          this.setState({ receiptItems: processReceivedReceiptItems(body, nextActiveReceipt.date) });
        })
        .catch(e => {
          console.error(intl.formatMessage(messages.receiptsSheetItemsError), e);
          dispatch(addAdminError(e));
        });
    }
  }

  _selectRow = (itemId, itemSubId) => {
    const { dispatch } = this.props;

    dispatch(toggleReceiptItemSelect(itemId, itemSubId));
  };

  render() {
    const {
      activeReceipt,
      priceLevels,
      selectedReceiptItems,
      intl,
      dispatch
    } = this.props;

    const { receiptItems } = this.state;

    // TODO remove styles
    return (
      <Box flexGrow={1}>
        <OrderLineSheetDecorations marginBottom={1} flexGrow={1}>
          <Box
            zIndex={1}
            borderColor="lightGray"
            borderStyle="solid"
            overflow="hidden"
            {...{ marginRight: '-18px',
                  paddingRight: '18px',
                  marginTop: '-20vh',
                  paddingTop: '20vh',
                  marginBottom: window.innerHeight < 768 ? '-18vh' : '-12vh',
                  paddingBottom: window.innerHeight < 768 ? '18vh' : '12vh',
                  height: '100vh',
                }
            }
          >
            <Box
              overflow="auto"
            >
              <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
                {activeReceipt && (receiptItems).length
                  ? <PrimaryRenderer
                      data={{
                        activeReceipt,
                        receiptItems: map(
                          el => ({
                            ...el,
                            isSelected: contains(`${el.id}`, Object.keys(selectedReceiptItems)),
                            selectedSubItemIds: selectedReceiptItems[`${el.id}`] || []
                          }),
                          receiptItems
                        ),
                        priceLevels
                      }}
                      renderers={[
                        ({ renderers, id, data }) => (
                          <Receipts
                            renderers={renderers}
                            key={id} {...data}
                          />
                        ),
                        ({ id, data }) => (
                          <AdminSheetRow
                            key={id}
                            {...data}
                            hasSubItems
                            onPress={this._selectRow}
                          />
                        )
                      ]}
                    />
                  : <Box
                      height={1.5}
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text alignItems="center" justifyContent="center" paddingTop={0.25}>
                        {intl.formatMessage(messages.receiptsSheetNoItems)}
                      </Text>
                    </Box>}
              </VelocityTransitionGroup>
            </Box>
          </Box>
        </OrderLineSheetDecorations>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    activeReceipt: state.admin.receipts.active.receipt,
    priceLevels: state.items.priceLevels,
    selectedReceiptItems: state.admin.receipts.selectedReceiptItems
  })),
  injectIntl
)(ReceiptItemsSheet);
