// @flow
import type { State } from '../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'rambda';
import {
 AutoSizer, Column,
  defaultTableCellRenderer, defaultTableHeaderRenderer
} from 'react-virtualized';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import moment from 'moment';
import { toggleAlcoholId } from '../../../common/admin/alcohol/actions';
import SimpleItemsTable, { withGroups, withInfiniteLoader, withSelectableRows } from '../Table';
import { processReceivedAlcoholList } from '../../../common/admin/alcohol/utils';
import api from '../../../common/lib/api';
import { addAdminError } from '../../../common/admin/general/actions';
import { injectIntl } from 'react-intl';
import messages from '../../../common/messages/alcohol';


const inlineBlock = {
  display: 'inline-block',
  lineHeight: 'normal'
};

const Table = compose(
  withInfiniteLoader({
    minimumBatchSize: 40,
    rowDataKey: 'apiId',
  }),
  withGroups({
    isGroupRow: (rowIndex, items) =>
      !rowIndex
      || (moment(items[rowIndex].date).format('L') !== moment(items[rowIndex - 1].date).format('L')),
    groupHeaderDataGetter: ({ rowData: { date } }) => moment(date).format('L')
  }),
  withSelectableRows(),
)(SimpleItemsTable);

class AlcoholList extends React.Component {
  loadAlcohol = ({ loadAfterId }) => {
    const { dispatch, intl } = this.props;
    const pageSize = 37;

    return api.alcohol.getAlcohol(pageSize, loadAfterId)
        .then(({ body }) => processReceivedAlcoholList(body))
        .catch(e => {
          console.error(intl.formatMessage(messages.getAlcoholError), e);
          dispatch(addAdminError(e));
        });
  };

  render() {
    const { selectedAlcoholsId, intl } = this.props;

    return (
      <Box
        width="100%"
        flex={1}
        backgroundColor="appBg"
        marginTop={1}
        paddingLeft={1}
        overflow="auto"
      >
        <Box height={2} width="100%" backgroundColor="lessDarkGray" flexShrink={0} />

        <Box flexBasis="100%" backgroundColor="white" marginBottom={1} maxHeight="100vh" height="9999px">
          <AutoSizer>
            {({ height, width }) => (
              <Table
                width={width}
                height={height}
                selectedItems={selectedAlcoholsId}
                onRowClick={({ rowData }) => {
                  const { dispatch } = this.props;
                  dispatch(toggleAlcoholId(rowData));
                }}
                headerClassName="offset-first"
                className="light"
                lazyFetch={this.loadAlcohol}
              >
                <Column
                  label={intl.formatMessage(messages.ean)}
                  dataKey="ean"
                  width={width * 0.2}
                  cellDataGetter={({ rowData: { ean } }) => `# ${ean}`}
                  cellRenderer={props => (
                    <Text paddingLeft={1} color="inherit">
                      {defaultTableCellRenderer(props)}
                    </Text>
                  )}
                />
                <Column label={intl.formatMessage(messages.description)} dataKey="name" width={width * 0.25} />
                <Column
                  label={intl.formatMessage(messages.count)}
                  dataKey="count"
                  width={width * 0.1}
                  cellRenderer={props => (
                    <Text paddingRight={1} color="inherit" flexDirection="row" justifyContent="flex-end" alignItems="center">
                      {defaultTableCellRenderer(props)}
                    </Text>
                  )}
                  headerRenderer={props => (
                    <Text
                      align="right"
                      paddingRight={0.5}
                      style={inlineBlock}
                    >
                      {defaultTableHeaderRenderer(props)}
                    </Text>
                  )}
                />
                <Column label={intl.formatMessage(messages.author)} dataKey="author" width={width * 0.2} />
                <Column
                  label={intl.formatMessage(messages.date)}
                  dataKey="date"
                  width={width * 0.2}
                  cellDataGetter={({ rowData: { date } }) => moment(date).format('L')}
                  cellRenderer={props => (
                    <Text paddingRight={1} color="inherit">
                      {defaultTableCellRenderer(props)}
                    </Text>
                  )}
                />
              </Table>
            )}
          </AutoSizer>
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    selectedAlcoholsId: state.admin.alcohol.selectedAlcoholsId
  })),
  injectIntl
)(AlcoholList);
