// @flow
import type { Action, AppState } from '../types';

const initialState = {
  baselineShown: false,
  currentTheme: 'defaultTheme',
  error: null,
  online: false,
  started: false,
  firstSyncDone: false,
  isFullScreen: false,
  syncAfterRehydrateFsDone: true,
  manualSyncRunning: false
};

const reducer = (state: AppState = initialState, action: Action): AppState => {
  // Map all app errors into state.app.error.
  // In React Native, we show errors in one nicely animated unobtrusive alert.
  // In the browser, we prefer local error messages rendering.
  // TODO: Refactor it. We don't want sticky strings.
  if (action.type.endsWith('_FAIL')) {
    // $FlowFixMe
    state = { ...state, error: action.payload.error };
  }

  switch (action.type) {
    case 'REHYDRATE_FS':
      return { ...state, syncAfterRehydrateFsDone: false };

    case 'REHYDRATE_FS_COMMIT':
      return { ...state, firstSyncDone: true };

    case 'BLITZ_SYNC_SERVER_DATA_COMMIT':
      return { ...state, syncAfterRehydrateFsDone: true };

    case 'APP_ERROR':
      return { ...state, error: action.payload.error };

    case 'APP_SHOW_MENU':
      return { ...state, menuShown: action.payload.menuShown };

    case 'APP_ONLINE':
      return { ...state, online: action.payload.online };

    case 'APP_STARTED':
      return { ...state, started: true };

    case 'SYNC_SERVER_DATA':
      return { ...state, manualSyncRunning: action.payload.isManual };

    case 'SYNC_SERVER_DATA_COMMIT':
      return { ...state, firstSyncDone: true, syncAfterRehydrateFsDone: true, manualSyncRunning: false };

    case 'SYNC_SERVER_DATA_ROLLBACK':
      return { ...state, manualSyncRunning: false };

    case 'DEVICE_SETUP':
      return { ...state, firstSyncDone: false, syncAfterRehydrateFsDone: true, manualSyncRunning: false };

    case 'TOGGLE_BASELINE':
      return { ...state, baselineShown: !state.baselineShown };

    case 'SET_THEME':
      return { ...state, currentTheme: action.payload.theme };

    case 'CHANGE_FULL_SCREEN':
      return { ...state, isFullScreen: action.payload.goFullScreen };

    case 'TOGGLE_FULL_SCREEN':
      return { ...state, isFullScreen: !state.isFullScreen };


    default:
      return state;

  }
};

export default reducer;
