// @flow
import type { State } from '../../common/types';
import React from 'react';
import DefaultTopBar from '../../common/components/TopBar';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import Text from '../../common/components/Text';
import Time from '../../common/components/Time';
import { signOut } from '../../common/auth/actions';
import { myNameSelector } from '../../common/auth/selectors';
import messages from '../../common/messages/common';
import { FormattedMessage } from 'react-intl';

class TopBar extends React.PureComponent {
  render() {
    const { dispatch, userName } = this.props;

    return (
      <DefaultTopBar backgroundColor="lessDarkGray">
        <Time color="white" bold paddingLeft={1} justifyContent="center" />

        <Button
          flexDirection="row"
          alignItems="center"
          paddingLeft={2.5}
          zIndex={1}
          onPress={() =>
            dispatch(replace('/serviceAreas'))
          }
        >
          <Icon
            position="absolute"
            left={0}
            name="close"
            color="black"
            padding={0.75}
            scale={-1}
            backgroundColor="white"
            borderRadius="50%"
          />
          <Text color="white" bold><FormattedMessage {...messages.commonTopBarClose} /></Text>
        </Button>

        <Button
          paddingRight={1}
          flexDirection="row"
          onPress={() => {
            dispatch(signOut());
          }}
          alignItems="center"
        >
          <Icon name="user" color="white" bold marginRight={0.25} scale={1} />
          <Text bold color="white">{userName}</Text>
        </Button>
      </DefaultTopBar>
    );
  }
}

export default connect((state: State) => ({
  userName: myNameSelector(state)
}))(TopBar);
