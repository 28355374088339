// @flow
import type { Device } from '../index';

const protocolFactory = (device: Device) => {
  const ProtocolPrototype = function (device: Device) {
    const chr = String.fromCharCode;
    const weightRegexp = new RegExp('([0-9]{2}[.][0-9]{3})KG');

    let isWaitingForResponse = false;
    let currentString = '';

    this.prepareWeight = () => {
      if (!isWaitingForResponse) {
        isWaitingForResponse = true;
        device.writeLine(chr(0x57, 0x0D));
      }
    };

    this.setResponse = (buffer: Buffer) => {
      const res = buffer.toString('ascii');

      const matches = weightRegexp.exec(res);

      if (!matches || matches.length !== 2) {
        device.writeLine(chr(0x57, 0x0D));
        currentString = '';
        return;
      }

      currentString = res;

      isWaitingForResponse = false;
    };

    this.getLastWeight = () => {
      if (!currentString) return null;

      const matches = weightRegexp.exec(currentString);

      if (!matches) return null;

      const weight = parseFloat(matches[1]);

      return +weight;
    };
  };

  return new ProtocolPrototype(device);
};

export default protocolFactory;
