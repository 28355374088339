// @flow
import type { BoxProps } from './Box';
import type { Color, Theme } from '../themes/types';
import Box from './Box';
import React from 'react';
import { fontFamilyName } from '../themes/typography';
import PropTypes from 'prop-types';
import TextAdjustableSizeComponent from '../../browser/components/TextAdjustableSize';
import { isReactNative } from '../app/detectPlatform';
import theme from '../themes/theme';

// Universal styled Text component. The same API for browsers and React Native.
// Some props are ommited or limited or set to match React Native behaviour.
// Use style prop for platform specific styling.

export type TextProps = BoxProps & {
  fontFamily?: string,
  scale?: number,
  size?: number,
  align?: 'left' | 'right' | 'center' | 'justify',
  bold?: boolean,
  thin?: boolean,
  condensed?: boolean,
  color?: Color,
  decoration?: 'none' | 'underline' | 'line-through',
  italic?: boolean,
  lineHeight?: number | string
};

type TextContext = {
  Text: () => React.Element<*>,
};

const fontSizeWithComputedLineHeight = (typography, scale, fontFamily, size) => {
  const fontSize = typography.fontSize(scale, fontFamily) * size;

  // strange lineHeight rendering on ios/android
  if (isReactNative) {
    return { fontSize };
  }

  const lineHeight = typography.lineHeight(scale, fontFamily); // don't multiply lineHeight
  return { fontSize, lineHeight };
};

export const computeTextStyle = (
  theme: Theme,
  {
    fontFamily = theme.text.fontFamily,
    size = 1,
    scale = 0,
    align,
    bold,
    thin,
    condensed,
    color = 'black',
    decoration,
    italic,
    fontSize,
    lineHeight,
    ...props
  }: TextProps,
) => {
  const colorTheme = theme.colors[color];

  let style = {
    ...fontSizeWithComputedLineHeight(theme.typography, scale, fontFamily, size),
    color: colorTheme === undefined ? color : colorTheme,
    fontFamily,
  };

  if (align) {
    style = { ...style, textAlign: align };
  }

  if (bold) {
    style = { ...style, fontFamily: fontFamilyName({ bold: true, family: fontFamily }) };
  }

  if (thin) {
    style = { ...style, fontFamily: fontFamilyName({ thin: true, family: fontFamily }) };
  }

  if (condensed) {
    style = { ...style, fontFamily: fontFamilyName({ condensed: true, bold, family: fontFamily }) };
  }

  if (decoration) {
    style = { ...style, textDecoration: decoration };
  }

  if (italic) {
    style = { ...style, fontStyle: 'italic' };
  }

  if (fontSize) {
    style = { ...style, fontSize };
  }

  if (lineHeight) {
    style = { ...style, lineHeight };
  }

  style = { ...style, letterSpacing: theme.typography.letterSpacing(scale, style.fontFamily) };

  return [style, props];
};

const fixFontSmoothing = isThin =>
  isThin
    ? {}
    : {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased'
    };

const computePlatformTextStyle = (boxStyle, textStyle) => {
  if (isReactNative) {
    if (textStyle.textDecoration) {
      textStyle = {
        ...textStyle,
        textDecorationLine: textStyle.textDecoration,
      };
      delete textStyle.textDecoration;
    }

    delete textStyle.thin;
  } else {
    textStyle = {
      ...textStyle,
      ...fixFontSmoothing(textStyle.thin),
      lineHeight: `${textStyle.lineHeight}px`, // browsers need px
    };
  }
  return textStyle;
};

class Text extends React.PureComponent {
  props: TextProps;
  context: TextContext;

  static contextTypes = {
    Text: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
    ]).isRequired,
  };

  render() {
    const {
      as,
      style,
      ...props
    } = this.props;

    const {
      Text: PlatformText,
    } = this.context;

    const [textStyle, restProps] = computeTextStyle(theme, props);

    return (
      <Box
        as={as || PlatformText}
        {...restProps}
        style={(theme, boxStyle) =>
          computePlatformTextStyle(
            boxStyle,
            {
              ...textStyle,
              ...(typeof style === 'function' ? style(theme, { ...boxStyle, ...textStyle }) : style),
              thin: props.thin
            }
          )}
      />
    );
  }
}

export const TextAdjustableSize = isReactNative ? Text : TextAdjustableSizeComponent;

export default Text;
