// @flow
import type { State } from '../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import Box from '../../../common/components/Box';
import { setFilterValue, toggleFilter, toggleTextInput } from '../../../common/order/actions';
import Button from '../../../common/components/Button';
import Icon from '../../../common/components/Icon';
import TextInput from '../../../common/components/TextInput';

const OrdersFilter = ({ dispatch, filterValue }) => {
  const updateFilter = (value) => {
    dispatch(setFilterValue(value));
  };

  const closeFilter = () => {
    updateFilter('');
    dispatch(toggleFilter(false));
  };

  return (
    <Box width="100%" backgroundColor="lessDarkGray" justifyContent="center">
      <Box paddingHorizontal={1} width="100%" flexDirection="row" alignItems="center" justifyContent="left">
        <Button flexDirection="row" onPress={() => dispatch(toggleTextInput('filter'))} alignItems="center">
          <Icon name="search" color="white" marginRight={0.5} />
        </Button>
        <TextInput
          height={1}
          width="86%"
          color="white"
          backgroundColor="lessDarkGray"
          value={filterValue}
          onClick={(e) => {
            dispatch(toggleTextInput('filter'));
            e.target.blur();
          }}
          readOnly
        />
        <Button onPress={closeFilter} alignItems="center" marginLeft="auto">
          <Icon color="white" name="delete" scale={1} />
        </Button>
      </Box>
    </Box>
  );
};

export default connect((state: State) => ({
  filterValue: state.orders.filterValue
}))(OrdersFilter);
