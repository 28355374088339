export default {
  'K32.vah_lnplu': 'number',
  'K32.vah_lnvah': 'number',
  'K32.sup_op': 'boolean',
  'K32.sc_saver': 'number',
  'K32.ini_page_n': 'number',
  'K32.is_stolchl': 'boolean',
  'K32.def_cenhla': 'number',
  'K32.len_nefis_platby': 'boolean',
  'K32.is_platdel': 'boolean',
  'K32.is_tipdot': 'boolean',
  'K32.koef_c_2': 'number',
  'K32.koef_c_3': 'number',
  'K32.koef_c_4': 'number',
  'K32.is_objnul': 'boolean',
  'K32.is_chlbar': 'boolean',
  'K32.loyalman_full_users_info': 'boolean',
  'K32.bar_pay_in_cash': 'string',
  'K32.ekasa': 'boolean',
  'K32.is_filter': 'boolean',
  'K32.enable_loyalman': 'boolean',
  'K32.a8_scanner': 'boolean'
};
