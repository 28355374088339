// @flow
import React from 'react';
import { compose, pathOr, uniqWith } from 'rambda';
import { processReceivedUsers } from './users/utils';
import withApolloConsumer from './hoc/withApolloConsumer';

type UserQueryProps = {
  query: any,
  variables: any,
  children: any
};

class QueryUsers extends React.PureComponent {
  props: UserQueryProps;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      processedUsers: [],
      endCursor: ''
    };
  }

  componentDidMount() {
    this.loadMoreData({});
  }

  loadMoreData = ({ hasNewFilter = false }) => {
    const { query, variables, client } = this.props;
    const { endCursor, processedUsers } = this.state;

    if (hasNewFilter) {
      this.setState({
        error: false,
        loading: true,
        processedUsers: [],
        endCursor: ''
      });
    } else {
      this.setState({ error: false });
    }

    client.query({ query, variables: { ...variables, after: hasNewFilter ? '' : endCursor } }).then(
      ({
         data
       }) => {
        const fetchedUsers = processReceivedUsers(data);
        const fetchedAfterCursor = pathOr('', 'users.pageInfo.endCursor', data);

        if (fetchedUsers.length) {
          const newProcessedData = uniqWith(
            (a, b) => a.id === b.id,
            hasNewFilter ? fetchedUsers : [...processedUsers, ...fetchedUsers]
          );
          this.setState({
            loading: false,
            processedUsers: newProcessedData,
            endCursor: fetchedAfterCursor
          });
        } else {
          this.setState({ loading: false });
        }
      }
    ).catch(error => {
      this.setState({ loading: false, error });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { variables: { filter } } = this.props;

    if (prevProps.variables.filter !== filter) {
      this.loadMoreData({ hasNewFilter: true });
    }
  }

  render() {
    const { children } = this.props;
    const { loading, error, processedUsers } = this.state;

    return children({
      loading,
      error,
      users: processedUsers,
      fetchMore: this.loadMoreData
    });
  }
}

export default compose(withApolloConsumer)(QueryUsers);
