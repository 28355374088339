// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';

export const getCashiersClosuresForRange = (dateFrom, dateTo): Promise =>
  fetch(apiEndpoint('v1/cashiers/'), {
      baseURL: getApiUrl(),
      params: {
        date_from: dateFrom,
        date_to: dateTo,
      }
    });

export const multiClosure = (
  closures, { print, printer } = {}
): Promise =>
  fetch(apiEndpoint('v1/cashiers/'), {
    method: 'POST',
    baseURL: getApiUrl(),
    params: {
      print: print ? 1 : undefined,
      printer
    },
    body: {
      uzavierky: closures
    }
  });

export const getStatus = (): Promise =>
  fetch(apiEndpoint('v1/cashiers/status/'), {
    baseURL: getApiUrl()
  });
