// @flow
import React from 'react';
import {find, propEq, contains, append, map, compose} from 'rambda';
import { isEmpty } from 'ramda';
import { VelocityTransitionGroup } from 'velocity-react';
import Box from '../Box';
import Button from '../Button';
import Text from '../Text';
import { isReactNative } from '../../app/detectPlatform';
import Icon from '../Icon';
import theme from '../../themes/theme';
import SubRow from './OrderLineRow';
import { formatDirectPriceToFixed, quantityAsIntegerElseOne } from '../../order/utils';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import componentsMessages from '../../messages/components';
import { getCurrencySymbol } from '../../parameters/service';

class AdminSheetRow extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isGroupOpened: false
    };
  }

  _onPress = () => {
    const { receiptItem: { id, quantity: receivedQuantity = 1, fastMenuSubItems = [] }, onPress } = this.props;
    const quantity = quantityAsIntegerElseOne(receivedQuantity);
    let fullGroup = [];
    const maxNumber = quantity || 0;

    for (let i = 1; i <= maxNumber; i++) {
      fullGroup = append(i, fullGroup);
    }

    if (onPress) {
      onPress(id, fullGroup);

      if (!isEmpty(fastMenuSubItems)) {
        map(
          fastMenuSubItem => onPress(fastMenuSubItem.id, fullGroup),
          fastMenuSubItems
        );
      }
    }
  };

  _selectSubItem = (subId) => {
    const { receiptItem: { id, fastMenuSubItems = [] }, onPress } = this.props;

    if (onPress) {
      onPress(id, subId);

      if (!isEmpty(fastMenuSubItems)) {
        map(
          fastMenuSubItem => onPress(fastMenuSubItem.id, subId),
          fastMenuSubItems
        );
      }
    }
  };

  _openRow = () => {
    const { receiptItem: { quantity: receivedQuantity = 1 } } = this.props;
    const quantity = quantityAsIntegerElseOne(receivedQuantity);

    if (quantity !== 1) {
      this.setState({ isGroupOpened: !this.state.isGroupOpened });
    }
  };

  render() {
    const {
      receiptItem: {
        menuName: name,
        singlePrice = 0,
        quantity: receivedQuantity = 1,
        updatedAt,
        priceLevelId,
        isSelected,
        orderText,
        selectedSubItemIds,
        cancelQuantity,
        orderType,
        fastMenuFullPrice: displayPrice = singlePrice,
        fastMenuSubItems = []
      },
      onPress,
      priceLevels,
      hasSubItems = false,
      intl
    } = this.props;

    let {
      receiptItem: {
        isGroupOpened
      }
    } = this.props;

    isGroupOpened = isGroupOpened || this.state.isGroupOpened;
    const isFastMenuHeader = orderType === 10;

    const priceLevel = find(propEq('id', priceLevelId), priceLevels);
    const priceLevelName = priceLevel &&
      priceLevel.name;

    const detailInfo = [];

    if (updatedAt) {
      detailInfo.push(`čas ${updatedAt}`);
    }

    if (priceLevelName) {
      detailInfo.push(priceLevelName);
    }

    const quantity = quantityAsIntegerElseOne(receivedQuantity);

    if (!((+receivedQuantity) % 1 === 0)) {
      detailInfo.push(`${receivedQuantity} kg`);
    }

    if (isFastMenuHeader) {
      detailInfo.push(' - základ');
    }
    const subItemsCounter = Array(Math.abs(quantity)).fill(1);
    const adminColors = cancelQuantity
      ? cancelQuantity === receivedQuantity
        ? 'blue'
        : 'orange'
      : cancelQuantity === 0
        ? 'red'
        : singlePrice < 0
          ? 'red'
          : 'blue';

    return (
      <Box>
        <Button
          onPress={this._onPress}
          onLongPress={hasSubItems && this._openRow}
          noActiveStyle
          disabled={!onPress}
          opacity={1}
        >
          <Box
            width="100%"
            flexDirection="row"
            justifyContent="flex-start"
            cursor={isReactNative ? undefined : 'pointer'}
          >
            <Box
              borderColor="lightGray"
              borderStyle="solid"
              borderTopWidth={1}
              borderRightWidth={1}
              backgroundColor={isSelected ? adminColors : isGroupOpened ? 'lighterGray' : 'white'}
              marginRight={0.1}
              flexGrow={1}
              minWidth={1.75}
              maxWidth={1.75}
              paddingTop={isFastMenuHeader ? 0.5 : 0}
              flexDirection="column"
              justifyContent={isFastMenuHeader ? 'flex-start' : 'center'}
              alignItems="center"
            >
              {selectedSubItemIds && selectedSubItemIds.length > 0
                ? <Box>
                    <Box
                      width={1}
                      height={0.85}
                      alignItems="center"
                      justifyContent="flex-end"
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor={adminColors}
                      backgroundColor="white"
                      borderTopLeftRadius={isReactNative ? +theme.typography.lineHeight() : `${theme.typography.lineHeight()}px`}
                      borderTopRightRadius={isReactNative ? +theme.typography.lineHeight() : `${theme.typography.lineHeight()}px`}
                      borderBottomLeftRadius={0}
                      borderBottomRightRadius={0}
                    >
                      <Text color={adminColors} alignItems="flex-end" scale={-3} bold>
                        {selectedSubItemIds.length}
                      </Text>
                    </Box>
                    <Box
                      width={1}
                      height={0.85}
                      alignItems="center"
                      justifyContent="flex-start"
                      backgroundColor={adminColors}
                    >
                      <Text color="white" scale={-3} bold>{quantity}</Text>
                    </Box>
                  </Box>
                : <Box
                    width={1}
                    height={1}
                    alignItems="center"
                    justifyContent="center"
                    borderWidth={1}
                    borderStyle="solid"
                    borderColor={adminColors}
                    backgroundColor={isSelected ? 'white' : adminColors}
                    borderRadius={theme.typography.lineHeight()}
                  >
                    <Text color={isSelected ? adminColors : 'white'} scale={-3} bold>
                      {quantity}
                    </Text>
                  </Box>
              }
            </Box>
            <Box
              paddingHorizontal={0.25}
              paddingRight={0.5}
              borderColor="lightGray"
              borderStyle="solid"
              borderTopWidth={1}
              borderLeftWidth={1}
              backgroundColor={isGroupOpened ? 'lighterGray' : isSelected ? adminColors : 'white'}
              flexGrow={4}
            >
              <Box
                flexDirection="row"
                justifyContent="flex-start"
              >
                <Text bold color={isGroupOpened ? 'gray' : isSelected ? 'white' : 'darkGray'} flexDirection="row" alignItems="flex-start">
                  {name}
                </Text>
                {orderText
                  ? <Box marginLeft={0.25}><Icon color={isGroupOpened ? 'gray' : isSelected ? 'white' : 'gray'} name="notes" scale={1} /></Box>
                  : null}
                <Text marginLeft="auto" bold color={isGroupOpened ? 'gray' : isSelected ? 'white' : 'darkGray'}>
                  {`${formatDirectPriceToFixed(displayPrice)} ${getCurrencySymbol()}`}
                </Text>
              </Box>

              <Box flexDirection="column" justifyContent="space-between">
                <Box flexDirection="row" justifyContent="space-between">
                  <Text scale={-1} height="20px" lineHeight={20} color={isGroupOpened ? 'gray' : isSelected ? 'white' : 'darkGray'}>
                    {detailInfo.join(', ')}
                  </Text>
                  {isFastMenuHeader &&
                    <Text scale={-1} lineHeight={20} color={isGroupOpened ? 'gray' : isSelected ? 'white' : 'darkGray'}>
                      {`${intl.formatMessage(componentsMessages.sheetRenderersFor)} ${formatDirectPriceToFixed(singlePrice)} ${getCurrencySymbol()}`}
                    </Text>}
                </Box>
            {!isEmpty(fastMenuSubItems) &&
              fastMenuSubItems.map(item =>
                <Box
                  key={`fastFoodMenu-${item.id}`}
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Text scale={-1} lineHeight={20} color={isGroupOpened ? 'gray' : isSelected ? 'white' : 'darkGray'}>
                    {item.menuName}
                  </Text>
                  <Text scale={-1} lineHeight={20} color={isGroupOpened ? 'gray' : isSelected ? 'white' : 'darkGray'}>
                    {`${intl.formatMessage(componentsMessages.sheetRenderersFor)} ${formatDirectPriceToFixed(item.singlePrice)} ${getCurrencySymbol()}`}
                  </Text>
                </Box>)}
              </Box>
            </Box>
          </Box>

        </Button>

        {hasSubItems &&
          <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
            {isGroupOpened &&
              subItemsCounter.map((key, index) => (
                <Button
                  key={index}
                  onPress={() => this._selectSubItem(index + 1)}
                >
                  {isFastMenuHeader
                    ? <SubRow
                      isChild
                      isAdmin
                      count={1}
                      selected={contains(index + 1, selectedSubItemIds)}
                      name={name}
                      price={displayPrice * 100 / quantity}
                      unitPrice={singlePrice * 100 / quantity}
                      unit="ks"
                      quantity={1}
                      displayQuantity={1}
                      isCanceled={
                        cancelQuantity <= 0
                        || (cancelQuantity > 0 && cancelQuantity !== quantity && index < (quantity - cancelQuantity))
                      }
                      orderText=""
                      centeredFirstTextLine
                      isFastMenuHeader
                      fastMenuSubItems={
                        map(
                          fastMenuSubItem => ({
                            ...fastMenuSubItem,
                            receiptName: fastMenuSubItem.menuName,
                            singlePrice: fastMenuSubItem.singlePrice * 100 / quantity
                          }),
                          fastMenuSubItems
                        )
                      }
                    />
                    : <SubRow
                      isChild
                      isAdmin
                      selected={contains(index + 1, selectedSubItemIds)}
                      name={name}
                      price={singlePrice * 100 / quantity}
                      unit="ks"
                      quantity={1}
                      displayQuantity={1}
                      isCanceled={
                        cancelQuantity <= 0
                        || (cancelQuantity > 0 && cancelQuantity !== quantity && index < (quantity - cancelQuantity))
                      }
                      orderText=""
                      centeredFirstTextLine
                    />}
                </Button>))}
          </VelocityTransitionGroup>}
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(AdminSheetRow);
