// @flow
import React from 'react';
import Box from '../Box';
import Text from '../Text';
import Button from '../Button';
import OrderLineHeading from './OrderLineHeading';
import { isReactNative } from '../../app/detectPlatform';
import { connect } from 'react-redux';
import { clearSelectedOrderLines, relocateToFoodCourse, toggleRelocatingToFoodCourse } from '../../order/actions';
import { activateFoodCourse } from '../../tables/actions';
import { compose, reduce } from 'rambda';
import { injectIntl } from 'react-intl';
import messages from '../../messages/common';
import kitchenMessages from '../../../common/messages/message';
import api from '../../lib/api';
import {addAdminError, toggleAdminNotificationPopup} from '../../admin/general/actions';
import Blocker from '../../../browser/components/Blocker';

class OrderLineFoodCourse extends React.PureComponent {
  state = {
    showKitchenReminderConfirm: false
  };

  sendKitchenReminder = () => {
    const { dispatch, intl, foodCourse } = this.props;
    const subTableIds = [];
    const { id, foodCourseOrderLines } = foodCourse;
    if (foodCourseOrderLines) {
      foodCourseOrderLines.forEach(fc => {
        fc.forEach(ol => {
          if (!subTableIds.includes(ol.subTableId)) {
            subTableIds.push(ol.subTableId);
          }
        });
      });
    }

    api.kitchenMessage.kitchenReminder(subTableIds, id)
      .then(() => {
        dispatch(toggleAdminNotificationPopup(intl.formatMessage(kitchenMessages.messagesKitchenReminderSend)));
        this.setState({ showKitchenReminderConfirm: false });
      })
      .catch(e => {
        console.error(intl.formatMessage(kitchenMessages.messagesKitchenReminderSendError), e);
        dispatch(addAdminError(e));
        this.setState({ showKitchenReminderConfirm: false });
      });
  };

  _onPress = () => {
    const { dispatch } = this.props;
    const { foodCourse, isRelocatingToFoodCourse, selectedOrderLines } = this.props;

    dispatch(activateFoodCourse(foodCourse.id));

    if (isRelocatingToFoodCourse) {
      dispatch([
        relocateToFoodCourse(selectedOrderLines, foodCourse.id),
        toggleRelocatingToFoodCourse(false),
        clearSelectedOrderLines()
      ]);
    }
  };

  render() {
    const {
      foodCourse,
      isRelocatingToFoodCourse,
      isRelocatingToTable,
      activeFoodCourse,
      isSubTableMenuOpen,
      selectedOrderLines,
      onKitchenReminderNativePress,
      renderers,
      intl
    } = this.props;
    const actualRenderer = renderers[0];

    const { foodCourseOrderLines } = foodCourse;
    const orderLinesCount = reduce(
      (count, orderLines) => count + orderLines.length,
      0,
      foodCourseOrderLines);

    const OrderLineSheetFoodCourse = (
      <Button onPress={this._onPress}>
        <OrderLineHeading
          name={foodCourse.name || `${foodCourse.id}. ${intl.formatMessage(messages.foodCourse)}`}
          quantity={orderLinesCount}
          isActive={foodCourse.id === activeFoodCourse}
          onKitchenReminderPress={() => {
            if (isReactNative) {
              if (onKitchenReminderNativePress) {
                onKitchenReminderNativePress(activeFoodCourse);
              }
            } else {
              this.setState({ showKitchenReminderConfirm: true });
            }
          }}
        />
      </Button>
    );

    const NoGroupOrderlines = (
      <Box
        height={1.5}
        backgroundColor="white"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Text alignItems="center" justifyContent="center" paddingTop={isReactNative ? 0 : 0.25}>
          {intl.formatMessage(messages.commonNoItems)}
        </Text>
        {isRelocatingToFoodCourse
          ? <Box
            zIndex={3}
            height="100%"
            position="absolute"
            width="100%"
            top={0}
            left={0}
            right={0}
            backgroundColor="inactiveItemsOverlay"
          />
          : null}
      </Box>
    );

    return (
      <Box id={`food-course-${foodCourse.id}`} flexShrink={0}>
        {!isReactNative && this.state.showKitchenReminderConfirm
          ? (
            <Blocker
              actionConfirm={() => {
                this.sendKitchenReminder();
              }}
              actionCancel={() => this.setState({ showKitchenReminderConfirm: false })}
              textHeader={intl.formatMessage(messages.kitchenReminderHeader)}
              textDescription={intl.formatMessage(messages.kitchenReminderText)}
              textConfirm={intl.formatMessage(messages.kitchenReminderYes)}
              textCancel={intl.formatMessage(messages.kitchenReminderNo)}
            />
          ) : null}
        {/*<AnimationWrapper component="div" enter="slideDown" leave="slideUp">*/}
          {OrderLineSheetFoodCourse}
          {foodCourseOrderLines.length
            ? foodCourseOrderLines.map(groupOrderLines =>
                actualRenderer({
                  id: groupOrderLines[0].id,
                  data: {
                    groupOrderLines,
                    isSubTableMenuOpen,
                    selectedOrderLines,
                    isRelocatingToFoodCourse,
                    isRelocatingToTable
                  },
                  renderers: renderers.slice(1)
                })
              )
            : NoGroupOrderlines}
        {/*</AnimationWrapper>*/}
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(OrderLineFoodCourse);
