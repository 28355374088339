// @flow
import type { DeviceState } from '../types';
import api, { configureApi } from '../lib/api';
import { version as appVersion } from '../../../package.json';

export const isDeviceConfigured = ({ apiUrl, machineId, machinePassword }: DeviceState) =>
  !!(apiUrl && machineId && machinePassword);

export const tryApiSettings = ({ apiUrl, machineId, machinePassword }, sessionId): Promise => {
  configureApi(apiUrl);
  return api.auth.verifyApi(machineId, machinePassword, sessionId).then(() => true);
};

export const getUserAgent = () => {
  if (process.env.IS_REACT_NATIVE === '1') {
    const UserAgent = require('react-native-user-agent');

    const Platform = require('react-native').Platform;

    return `foodie;${Platform.OS};${Platform.Version};${appVersion};${UserAgent.getUserAgent()}`;
  }

  if (process.env.IS_ELECTRON === '1') {
    // eslint-disable-next-line no-undef
    return `foodie;electron;${process.versions.electron};${appVersion};${window.navigator.userAgent}`;
  }

  if (process.env.IS_BROWSER === '1') {
    // eslint-disable-next-line no-undef
    return `foodie;browser;;${appVersion};${window.navigator.userAgent}`;
  }

  return '';
};
