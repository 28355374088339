/* eslint-disable max-len, quote-props, quotes */
export default [
  {
    "id": "admin.form.logo",
    "defaultMessage": "Tlačiť logo",
  },
  {
    "id": "admin.form.state",
    "defaultMessage": "Stav kasy",
  },
  {
    "id": "admin.form.state_off",
    "defaultMessage": "Stav OFF",
  },
  {
    "id": "admin.form.state_on",
    "defaultMessage": "Stav ON",
  },
  {
    "id": "admin.values.logo_1",
    "defaultMessage": "Tlačiť",
  },
  {
    "id": "admin.values.logo_2",
    "defaultMessage": "Netlačiť",
  },
  {
    "id": "alcohol.author",
    "defaultMessage": "Autor",
  },
  {
    "id": "alcohol.count",
    "defaultMessage": "Počet",
  },
  {
    "id": "alcohol.date",
    "defaultMessage": "Dátum",
  },
  {
    "id": "alcohol.description",
    "defaultMessage": "Popis",
  },
  {
    "id": "alcohol.ean",
    "defaultMessage": "EAN",
  },
  {
    "id": "alcohol.eanNotFound",
    "defaultMessage": "daný ean kód sa nenašiel",
  },
  {
    "id": "alcohol.errors.add.alcohol",
    "defaultMessage": "Error pri pridávaní alkoholu: ",
  },
  {
    "id": "alcohol.errors.delete.alcohol",
    "defaultMessage": "Error pri mazaní alkoholu: ",
  },
  {
    "id": "alcohol.errors.edit.alcohol",
    "defaultMessage": "Error pri editovaní alkoholu: ",
  },
  {
    "id": "alcohol.errors.get.alcohol",
    "defaultMessage": "Error pri získavní zoznamu alkoholu: ",
  },
  {
    "id": "alcohol.errors.get.ean",
    "defaultMessage": "Error pri získavaní eanov: ",
  },
  {
    "id": "alcohol.finding",
    "defaultMessage": "...hľadám...",
  },
  {
    "id": "alcohol.itemAdded",
    "defaultMessage": "Položka pridaná.",
  },
  {
    "id": "alcohol.itemEdited",
    "defaultMessage": "Položka upravená.",
  },
  {
    "id": "alcohol.sidebar.add",
    "defaultMessage": "Pridať",
  },
  {
    "id": "alcohol.sidebar.confirmMsgDeleteItems",
    "defaultMessage": "Označené položky budú odstránené, chcete ich naozaj vymazať?",
  },
  {
    "id": "alcohol.sidebar.delete",
    "defaultMessage": "Zmazať",
  },
  {
    "id": "alcohol.sidebar.deleteCancel",
    "defaultMessage": "Zrušiť",
  },
  {
    "id": "alcohol.sidebar.deleteItems",
    "defaultMessage": "Vymazanie položiek",
  },
  {
    "id": "alcohol.sidebar.deleteYes",
    "defaultMessage": "Áno",
  },
  {
    "id": "alcohol.sidebar.edit",
    "defaultMessage": "Upraviť",
  },
  {
    "id": "alcohol.sidebar.notification.deleted",
    "defaultMessage": "Položka vymazaná.",
  },
  {
    "id": "app.titles.admin",
    "defaultMessage": "Admin",
  },
  {
    "id": "app.titles.order",
    "defaultMessage": "Objednávka",
  },
  {
    "id": "app.titles.serviceAreas",
    "defaultMessage": "Miestnosti",
  },
  {
    "id": "app.titles.setup",
    "defaultMessage": "Nastavenia zariadenia",
  },
  {
    "id": "auth.form.loading",
    "defaultMessage": "Načítavajú sa údaje kasy",
  },
  {
    "id": "autoUpdates.installRestart",
    "defaultMessage": "Nainštalovať a reštartovať",
  },
  {
    "id": "autoUpdates.updateFound",
    "defaultMessage": "⚠️ Bola nájdená aktualizácia.",
  },
  {
    "id": "autoUpdates.updateNow",
    "defaultMessage": "Aktualizovať",
  },
  {
    "id": "closures.closeAllClosuresInfo",
    "defaultMessage": "Všetky otvorené účty budú zaplatené vybraným spôsobom platby bez zľavy",
  },
  {
    "id": "closures.closeAllClosuresTitle",
    "defaultMessage": "Naozaj chcete zatvoriť všetky účty?",
  },
  {
    "id": "closures.closeAllTablesInfo",
    "defaultMessage": "Zavrieť aj nepotvrdené (čierne) stoly, aj stoly s nepotvrdenými objednávkami (nepotvrdené položky budú vymazané).",
  },
  {
    "id": "closures.closeTable",
    "defaultMessage": "Úspešné uzatvorenie stola",
  },
  {
    "id": "closures.confirmTable",
    "defaultMessage": "Úspešné uzatvorenie stola, nepotvrdené položky ponechané.",
  },
  {
    "id": "closures.history.choosePrinter",
    "defaultMessage": "Vybrať tlačiareň",
  },
  {
    "id": "closures.history.closureCopyToPrint",
    "defaultMessage": "Kópia fiškálnej uzávierky bola odoslaná na tlač",
  },
  {
    "id": "closures.history.closureToPrint",
    "defaultMessage": "Uzávierka bola odoslaná na tlač",
  },
  {
    "id": "closures.history.copyClosure",
    "defaultMessage": "Kópia fiškál. uzávierky",
  },
  {
    "id": "closures.history.deadlineFromTo",
    "defaultMessage": "Uzávierka od – do",
  },
  {
    "id": "closures.history.print",
    "defaultMessage": "Vytlačiť",
  },
  {
    "id": "closures.history.regenerateClosure",
    "defaultMessage": "Pregenerovať uzávierku",
  },
  {
    "id": "closures.intermediate.closureToPrintInter",
    "defaultMessage": "Uzávierka bola odoslaná na tlač",
  },
  {
    "id": "closures.intermediate.confirmAndChoosePrinter",
    "defaultMessage": "Potvrdiť a vybrať tlačiareň",
  },
  {
    "id": "closures.intermediate.confirmAndPrint",
    "defaultMessage": "Potvrdiť a vytlačiť",
  },
  {
    "id": "closures.interval.confirmIntervalClosure",
    "defaultMessage": "Potvrdiť intervalovú uzávierku",
  },
  {
    "id": "closures.interval.createdIntervalClosure",
    "defaultMessage": "Uzávierka bola vytvorená",
  },
  {
    "id": "closures.interval.creatingIntervalClosure",
    "defaultMessage": "Vytvára sa intervalová uzávierka",
  },
  {
    "id": "closures.interval.intervalClosure",
    "defaultMessage": "Intervalová uzávierka",
  },
  {
    "id": "closures.interval.intervalFrom",
    "defaultMessage": "Od:",
  },
  {
    "id": "closures.interval.intervalTo",
    "defaultMessage": "Do:",
  },
  {
    "id": "closures.journal.journalPrint",
    "defaultMessage": "Vytlačiť",
  },
  {
    "id": "closures.new.closureChoosePrinter",
    "defaultMessage": "Vybrať tlačiareň",
  },
  {
    "id": "closures.new.closureConfirmNew",
    "defaultMessage": "Naozaj chcete spraviť novú uzávierku?",
  },
  {
    "id": "closures.new.closureCreatedNew",
    "defaultMessage": "Uzávierka bola vytvorená",
  },
  {
    "id": "closures.new.closureCreatingNew",
    "defaultMessage": "Vytvára sa nová uzávierka",
  },
  {
    "id": "closures.new.closureDepositBlockError",
    "defaultMessage": "Nastala chyba pri realizovaní vkladu:",
  },
  {
    "id": "closures.new.closureDepositCancel",
    "defaultMessage": "Zrušiť",
  },
  {
    "id": "closures.new.closureDepositConfirm",
    "defaultMessage": "Áno",
  },
  {
    "id": "closures.new.closureDepositDescription",
    "defaultMessage": "Pre platobnú metódu {name} bude vykonaný vklad v hodnote {sum} {currency}. Chcete pokračovať?",
  },
  {
    "id": "closures.new.closureDepositHeader",
    "defaultMessage": "Vykonať vklad pre platobnú metódu",
  },
  {
    "id": "closures.new.closurePrint",
    "defaultMessage": "Vytlačiť",
  },
  {
    "id": "closures.new.closurePrintInProgress",
    "defaultMessage": "Prebieha tlač",
  },
  {
    "id": "closures.new.closureSendToPrint",
    "defaultMessage": "Uzávierka bola odoslaná na tlač",
  },
  {
    "id": "closures.new.no",
    "defaultMessage": "Nie",
  },
  {
    "id": "closures.new.overFlorError",
    "defaultMessage": "Hodnota výberu nesmie prevyšovať súčet hodnôt platobnej metódy, alebo dosahovať zápornú hodnotu.",
  },
  {
    "id": "closures.new.table.deposit",
    "defaultMessage": "Vklad",
  },
  {
    "id": "closures.new.table.mediaName",
    "defaultMessage": "Metóda",
  },
  {
    "id": "closures.new.table.overall",
    "defaultMessage": "Spolu",
  },
  {
    "id": "closures.new.table.payment",
    "defaultMessage": "Úhrada pohľadávky",
  },
  {
    "id": "closures.new.table.prn",
    "defaultMessage": "Tlačiareň",
  },
  {
    "id": "closures.new.table.sale",
    "defaultMessage": "Tržba",
  },
  {
    "id": "closures.new.table.summary",
    "defaultMessage": "Sumár vkladov/výberov",
  },
  {
    "id": "closures.new.table.withdrawal",
    "defaultMessage": "Výber",
  },
  {
    "id": "closures.new.validation.negative",
    "defaultMessage": "Súčet hodnôt platobnej metódy nesmie dosahovať zápornú hodnotu.",
  },
  {
    "id": "closures.new.withdrawalError",
    "defaultMessage": "Nastala chyba pri realizovaní výberov:",
  },
  {
    "id": "closures.new.yes",
    "defaultMessage": "Áno",
  },
  {
    "id": "closures.no",
    "defaultMessage": "Nie",
  },
  {
    "id": "closures.printPreview.choosePrinter",
    "defaultMessage": "Vybrať tlačiareň",
  },
  {
    "id": "closures.printPreview.print",
    "defaultMessage": "Vytlačiť",
  },
  {
    "id": "closures.printPreview.printing",
    "defaultMessage": "Prebieha tlač",
  },
  {
    "id": "closures.printPreview.send",
    "defaultMessage": "Uzávierka bola odoslaná na tlač",
  },
  {
    "id": "closures.summary.confirm",
    "defaultMessage": "Potvrdiť sumárnu uzávierku",
  },
  {
    "id": "closures.summary.create",
    "defaultMessage": "Vytvára sa sumárna uzávierka",
  },
  {
    "id": "closures.summary.created",
    "defaultMessage": "Uzávierka bola vytvorená",
  },
  {
    "id": "closures.summary.from",
    "defaultMessage": "Od:",
  },
  {
    "id": "closures.summary.title",
    "defaultMessage": "Sumárna uzávierka",
  },
  {
    "id": "closures.summary.to",
    "defaultMessage": "Do:",
  },
  {
    "id": "closures.summaryMultiple.backToCashiers",
    "defaultMessage": "Späť na výber kás",
  },
  {
    "id": "closures.summaryMultiple.cashiers",
    "defaultMessage": "Sumárna uzávierka za viac kás",
  },
  {
    "id": "closures.summaryMultiple.cashiersNotFound",
    "defaultMessage": "Nenašli sa vyhovujúce kasy",
  },
  {
    "id": "closures.summaryMultiple.cashiersTitle",
    "defaultMessage": "Kasy",
  },
  {
    "id": "closures.summaryMultiple.chooseContinue",
    "defaultMessage": "Pokračovať na výber uzávierok",
  },
  {
    "id": "closures.summaryMultiple.closuresNotFound",
    "defaultMessage": "Nenašli sa vyhovujúce uzávierky",
  },
  {
    "id": "closures.summaryMultiple.ClosuresTitle",
    "defaultMessage": "Uzávierky",
  },
  {
    "id": "closures.summaryMultiple.confirmClosure",
    "defaultMessage": "Potvrdiť uzávierku",
  },
  {
    "id": "closures.summaryMultiple.created",
    "defaultMessage": "Uzávierka bola vytvorená",
  },
  {
    "id": "closures.summaryMultiple.creating",
    "defaultMessage": "Vytvára sa uzávierka",
  },
  {
    "id": "closures.yes",
    "defaultMessage": "Áno",
  },
  {
    "id": "common.cashier.options",
    "defaultMessage": "Možnosti pokladne",
  },
  {
    "id": "common.closures.bulkClose",
    "defaultMessage": "Hromadné uzavretie účtov",
  },
  {
    "id": "common.closures.history",
    "defaultMessage": "História uzávierok",
  },
  {
    "id": "common.closures.intermediate",
    "defaultMessage": "Medziuzávierka",
  },
  {
    "id": "common.closures.interval",
    "defaultMessage": "Intervalová uzávierka",
  },
  {
    "id": "common.closures.new",
    "defaultMessage": "Nová uzávierka",
  },
  {
    "id": "common.closures.summary",
    "defaultMessage": "Sumárna uzávierka",
  },
  {
    "id": "common.closures.summaryMultiple",
    "defaultMessage": "Sumárna uzávierka za viac kás",
  },
  {
    "id": "common.cook.orders",
    "defaultMessage": "Objednávky",
  },
  {
    "id": "common.cook.ordersList",
    "defaultMessage": "Objednávky - zoznam",
  },
  {
    "id": "common.cook.service",
    "defaultMessage": "Stoly",
  },
  {
    "id": "common.fiscal.confirm",
    "defaultMessage": "Potvrdiť výzvu",
  },
  {
    "id": "common.fiscal.module",
    "defaultMessage": "Fiškálny modul",
  },
  {
    "id": "common.fiscal.reset",
    "defaultMessage": "Resetovať fiškál",
  },
  {
    "id": "common.fiscal.time",
    "defaultMessage": "Nastaviť čas",
  },
  {
    "id": "common.foodCourse",
    "defaultMessage": "chod",
  },
  {
    "id": "common.kitchenReminderHeader",
    "defaultMessage": "Pripomienka do kuchyne",
  },
  {
    "id": "common.kitchenReminderText",
    "defaultMessage": "Naozaj si želáte poslať pripomienku do kuchyne",
  },
  {
    "id": "common.menu.alcohol",
    "defaultMessage": "Predaj alkoholu",
  },
  {
    "id": "common.menu.cashClose",
    "defaultMessage": "Uzávierka pokladne",
  },
  {
    "id": "common.menu.consumptionView",
    "defaultMessage": "Prezeranie spotreby",
  },
  {
    "id": "common.menu.cook",
    "defaultMessage": "Cook",
  },
  {
    "id": "common.menu.copyCancel",
    "defaultMessage": "Kópie a storná účtov",
  },
  {
    "id": "common.menu.fiscal",
    "defaultMessage": "Fiškál",
  },
  {
    "id": "common.menu.fullScreen",
    "defaultMessage": "Prepnúť režim na celú obrazovku",
  },
  {
    "id": "common.menu.kitchenMessage",
    "defaultMessage": "Správa do kuchyne",
  },
  {
    "id": "common.menu.mealsCount",
    "defaultMessage": "Počet porcií",
  },
  {
    "id": "common.menu.priceList",
    "defaultMessage": "Aktualizácia cenníka",
  },
  {
    "id": "common.network.applicationServerNotAvailable",
    "defaultMessage": "Aplikačný server je nedostupný",
  },
  {
    "id": "common.network.applicationSyncAvailable",
    "defaultMessage": "Sú dostupné nové dáta",
  },
  {
    "id": "common.no",
    "defaultMessage": "Nie",
  },
  {
    "id": "common.noItems",
    "defaultMessage": "Žiadne položky",
  },
  {
    "id": "common.order.addMenu",
    "defaultMessage": "Pridať chod",
  },
  {
    "id": "common.order.allGuests",
    "defaultMessage": "Všetci hostia",
  },
  {
    "id": "common.order.confirmingItems",
    "defaultMessage": "Potvrdzujú sa položky",
  },
  {
    "id": "common.order.guest",
    "defaultMessage": "hosť",
  },
  {
    "id": "common.order.pendingPayment",
    "defaultMessage": "Prebieha platobná operácia",
  },
  {
    "id": "common.order.showAll",
    "defaultMessage": "Zobraziť všetkých",
  },
  {
    "id": "common.table.empty",
    "defaultMessage": "Žiadne položky",
  },
  {
    "id": "common.table.loading",
    "defaultMessage": "Načítavajú sa údaje…",
  },
  {
    "id": "common.topBar.close",
    "defaultMessage": "Zatvoriť",
  },
  {
    "id": "common.yes",
    "defaultMessage": "Áno",
  },
  {
    "id": "components.closure.chooseLeft",
    "defaultMessage": "Vyberte uzávierku zo zoznamu vľavo",
  },
  {
    "id": "components.closure.noDate",
    "defaultMessage": "Uzávierka neobsahuje žiadne údaje",
  },
  {
    "id": "components.oneTimeTable",
    "defaultMessage": "Jednoraz. stôl",
  },
  {
    "id": "components.orderLineDetailTime.base",
    "defaultMessage": "základ",
  },
  {
    "id": "components.orderLineDetailTime.time",
    "defaultMessage": "čas",
  },
  {
    "id": "components.orderLineSheet.add",
    "defaultMessage": "Pridať chod.",
  },
  {
    "id": "components.paymentSheet.bonusIncr",
    "defaultMessage": "Navýšenie bonusu",
  },
  {
    "id": "components.paymentSheet.creditIncr",
    "defaultMessage": "Navýšenie kreditu",
  },
  {
    "id": "components.paymentSheet.desc",
    "defaultMessage": "Pozn.",
  },
  {
    "id": "components.paymentSheet.discount",
    "defaultMessage": "Zľava",
  },
  {
    "id": "components.paymentSheet.overpay",
    "defaultMessage": "preplatok",
  },
  {
    "id": "components.paymentSheet.prepaid",
    "defaultMessage": "Predplatené prod.",
  },
  {
    "id": "components.paymentSheet.tip",
    "defaultMessage": "Zadať TIP",
  },
  {
    "id": "components.paymentSheet.tipSimple",
    "defaultMessage": "tip",
  },
  {
    "id": "components.sheetRenderers.for",
    "defaultMessage": "za",
  },
  {
    "id": "components.sheetRenderers.noItems",
    "defaultMessage": "Žiadne položky",
  },
  {
    "id": "components.tableArea.newTable",
    "defaultMessage": "Nový stôl",
  },
  {
    "id": "device.form.apiUrl",
    "defaultMessage": "Adresa API",
  },
  {
    "id": "device.form.apiUrl",
    "defaultMessage": "Adresa API",
  },
  {
    "id": "device.form.continue",
    "defaultMessage": "Pokračovať",
  },
  {
    "id": "device.form.error",
    "defaultMessage": "Nepodarilo sa spojiť s API",
  },
  {
    "id": "device.form.error",
    "defaultMessage": "Nepodarilo sa spojiť s API",
  },
  {
    "id": "device.form.machineId",
    "defaultMessage": "ID kasy",
  },
  {
    "id": "device.form.machineId",
    "defaultMessage": "ID kasy",
  },
  {
    "id": "device.form.machinePassword",
    "defaultMessage": "Heslo",
  },
  {
    "id": "device.form.machinePassword",
    "defaultMessage": "Heslo",
  },
  {
    "id": "device.form.setup",
    "defaultMessage": "Nastavenia zariadenia",
  },
  {
    "id": "fiscal.confirm.header",
    "defaultMessage": "Potvrdzovanie výzvy",
  },
  {
    "id": "fiscal.confirm.info",
    "defaultMessage": "Výzva bola potvrdená",
  },
  {
    "id": "fiscal.form.afsVersion",
    "defaultMessage": "AFS",
  },
  {
    "id": "fiscal.form.commSpeed",
    "defaultMessage": "Rýchlosť portu",
  },
  {
    "id": "fiscal.form.databit",
    "defaultMessage": "Databit",
  },
  {
    "id": "fiscal.form.deadline",
    "defaultMessage": "Hraničný čas uzávierky",
  },
  {
    "id": "fiscal.form.dic",
    "defaultMessage": "DIČ",
  },
  {
    "id": "fiscal.form.dkp",
    "defaultMessage": "DKP",
  },
  {
    "id": "fiscal.form.dph",
    "defaultMessage": "Sadzba DPH",
  },
  {
    "id": "fiscal.form.firmwareVersion",
    "defaultMessage": "Firmware",
  },
  {
    "id": "fiscal.form.fiscalDay",
    "defaultMessage": "Fiškál. deň",
  },
  {
    "id": "fiscal.form.fiscalPort",
    "defaultMessage": "Port fiškálu",
  },
  {
    "id": "fiscal.form.fiscalType",
    "defaultMessage": "Typ",
  },
  {
    "id": "fiscal.form.flowManagement",
    "defaultMessage": "Flow mng",
  },
  {
    "id": "fiscal.form.headers",
    "defaultMessage": "Hlavičky",
  },
  {
    "id": "fiscal.form.icdph",
    "defaultMessage": "IČ DPH",
  },
  {
    "id": "fiscal.form.ico",
    "defaultMessage": "IČO",
  },
  {
    "id": "fiscal.form.loadingData",
    "defaultMessage": "Načítavajú sa údaje…",
  },
  {
    "id": "fiscal.form.moduleData",
    "defaultMessage": "Údaje modulu",
  },
  {
    "id": "fiscal.form.notprint",
    "defaultMessage": "Netlačiť",
  },
  {
    "id": "fiscal.form.parity",
    "defaultMessage": "Parita",
  },
  {
    "id": "fiscal.form.portType",
    "defaultMessage": "Typ portu",
  },
  {
    "id": "fiscal.form.print",
    "defaultMessage": "Tlačiť",
  },
  {
    "id": "fiscal.form.printLogo",
    "defaultMessage": "Tlačiť logo",
  },
  {
    "id": "fiscal.form.sendChanges",
    "defaultMessage": "Odoslať zmeny",
  },
  {
    "id": "fiscal.form.statistics",
    "defaultMessage": "Štatistiky",
  },
  {
    "id": "fiscal.form.stopbit",
    "defaultMessage": "Stopbit",
  },
  {
    "id": "fiscal.form.updated",
    "defaultMessage": "Fiškál aktualizovaný",
  },
  {
    "id": "fiscal.form.versions",
    "defaultMessage": "Verzie",
  },
  {
    "id": "fiscal.module.confirmTime",
    "defaultMessage": "Potvrdiť čas",
  },
  {
    "id": "fiscal.module.data",
    "defaultMessage": "Údaje fiškálneho modulu",
  },
  {
    "id": "fiscal.module.deadline",
    "defaultMessage": "Hraničný čas uzávierky",
  },
  {
    "id": "fiscal.module.editDph",
    "defaultMessage": "Úprava DPH (v %)",
  },
  {
    "id": "fiscal.reset.info",
    "defaultMessage": "Fiškál bol resetovaný",
  },
  {
    "id": "fiscal.reset.title",
    "defaultMessage": "Reset fiškálu",
  },
  {
    "id": "fiscal.time.info",
    "defaultMessage": "Čas bol nastavený",
  },
  {
    "id": "fiscal.time.title",
    "defaultMessage": "Nastavovanie času",
  },
  {
    "id": "keyboard.buttons.dot",
    "defaultMessage": ",",
  },
  {
    "id": "loyalman.cancel",
    "defaultMessage": "Zrušiť",
  },
  {
    "id": "loyalman.confirmUser",
    "defaultMessage": "Potvrdiť používateľa",
  },
  {
    "id": "loyalman.getCard",
    "defaultMessage": "Vydať novú kartu (pre vybraného)",
  },
  {
    "id": "loyalman.operationInProgress",
    "defaultMessage": "Prebieha operácia s účtami",
  },
  {
    "id": "loyalman.user.blocked",
    "defaultMessage": "Blokovaná",
  },
  {
    "id": "loyalman.user.bonus",
    "defaultMessage": "Bonus",
  },
  {
    "id": "loyalman.user.card",
    "defaultMessage": "Karta",
  },
  {
    "id": "loyalman.user.cards",
    "defaultMessage": "Karty",
  },
  {
    "id": "loyalman.user.credit",
    "defaultMessage": "Kredit",
  },
  {
    "id": "loyalman.user.detailCard",
    "defaultMessage": "Pre zobrazenie detailu je nutné zadať správne číslo karty.",
  },
  {
    "id": "loyalman.user.discountPackages",
    "defaultMessage": "Balíky",
  },
  {
    "id": "loyalman.user.email",
    "defaultMessage": "Email",
  },
  {
    "id": "loyalman.user.exp",
    "defaultMessage": "Exp",
  },
  {
    "id": "loyalman.user.expire",
    "defaultMessage": "expiruje",
  },
  {
    "id": "loyalman.user.name",
    "defaultMessage": "Meno",
  },
  {
    "id": "loyalman.user.phone",
    "defaultMessage": "Telefón",
  },
  {
    "id": "loyalman.user.prepaidProducts",
    "defaultMessage": "Predplatené produkty",
  },
  {
    "id": "loyalman.user.transactions",
    "defaultMessage": "Transakcie",
  },
  {
    "id": "loyalman.user.validTo",
    "defaultMessage": "Používateľ platný do",
  },
  {
    "id": "mealsCounts.find",
    "defaultMessage": "Hľadať",
  },
  {
    "id": "mealsCounts.goodsAvailability",
    "defaultMessage": "Dostupnosť tovaru",
  },
  {
    "id": "messages.kitchenCreate",
    "defaultMessage": "Správa do kuchyne - zadanie správy.",
  },
  {
    "id": "messages.kitchenPrinter",
    "defaultMessage": "Správa do kuchyne - výber tlačiarne",
  },
  {
    "id": "messages.kitchenReminderSend",
    "defaultMessage": "Pripomienka do kuchyne bola odoslaná",
  },
  {
    "id": "messages.kitchenReminderSendError",
    "defaultMessage": "Chyba pri posielaní pripomienky do kuchyne: ",
  },
  {
    "id": "messages.kitchenSend",
    "defaultMessage": "Správa do kuchyne odoslaná",
  },
  {
    "id": "messages.kitchenSendError",
    "defaultMessage": "Chyba pri posielaní správy do kuchyne: ",
  },
  {
    "id": "native.addOwn",
    "defaultMessage": "PRIDAŤ VLASTNÉ",
  },
  {
    "id": "native.availabilityBlocker.availability",
    "defaultMessage": "Dostupnosť",
  },
  {
    "id": "native.availabilityBlocker.back",
    "defaultMessage": "Späť na objednávku",
  },
  {
    "id": "native.availabilityBlocker.cancel",
    "defaultMessage": "Zrušiť",
  },
  {
    "id": "native.availabilityBlocker.confirm",
    "defaultMessage": "Potvrdiť množstvo",
  },
  {
    "id": "native.availabilityBlocker.confirmOrChange",
    "defaultMessage": "Potvrďte alebo zmenťe počet.",
  },
  {
    "id": "native.availabilityBlocker.notAvailable",
    "defaultMessage": "Tovar nie je dostupný",
  },
  {
    "id": "native.back",
    "defaultMessage": "Späť",
  },
  {
    "id": "native.bottomBar.cancel",
    "defaultMessage": "Zrušiť",
  },
  {
    "id": "native.bottomBar.description",
    "defaultMessage": "Poznámka",
  },
  {
    "id": "native.bottomBar.message",
    "defaultMessage": "Označené položky budú odstránené, chcete ich naozaj vymazať?",
  },
  {
    "id": "native.bottomBar.title",
    "defaultMessage": "Vymazanie položiek",
  },
  {
    "id": "native.bottomBar.yes",
    "defaultMessage": "Áno",
  },
  {
    "id": "native.cashier.loadingData",
    "defaultMessage": "Načítavam dáta kasy",
  },
  {
    "id": "native.customText",
    "defaultMessage": "Vlastný text",
  },
  {
    "id": "native.deviceSetup",
    "defaultMessage": "Nastavenia zariadenia",
  },
  {
    "id": "native.deviceSetupContinue",
    "defaultMessage": "Pokračovať",
  },
  {
    "id": "native.fastFoodMenu.back",
    "defaultMessage": "Späť na objednávku",
  },
  {
    "id": "native.number",
    "defaultMessage": "Číslo",
  },
  {
    "id": "native.ok",
    "defaultMessage": "OK",
  },
  {
    "id": "native.pleaseWait",
    "defaultMessage": "Prosím čakajte",
  },
  {
    "id": "order.all",
    "defaultMessage": "Všetci",
  },
  {
    "id": "order.availabilityBlocker.availability",
    "defaultMessage": "Dostupnosť",
  },
  {
    "id": "order.availabilityBlocker.confirmChange",
    "defaultMessage": "Potvrďte alebo zmenťe počet.",
  },
  {
    "id": "order.availabilityBlocker.header",
    "defaultMessage": "Tovar nie je dostupný",
  },
  {
    "id": "order.back",
    "defaultMessage": "Vrátiť sa",
  },
  {
    "id": "order.chosenItems",
    "defaultMessage": "Vybrané položky",
  },
  {
    "id": "order.confirmingItems",
    "defaultMessage": "Potvrdzujú sa položky",
  },
  {
    "id": "order.contextMenus.addMultipleTimes",
    "defaultMessage": "Podržte pre nastavenie počtu",
  },
  {
    "id": "order.contextMenus.addMultipleTimes",
    "defaultMessage": "Podržte pre nastavenie počtu",
  },
  {
    "id": "order.contextMenus.addNote",
    "defaultMessage": "Poznámka",
  },
  {
    "id": "order.contextMenus.addNote",
    "defaultMessage": "Poznámka",
  },
  {
    "id": "order.contextMenus.addOne",
    "defaultMessage": "Pridať",
  },
  {
    "id": "order.contextMenus.addOne",
    "defaultMessage": "Pridať",
  },
  {
    "id": "order.contextMenus.addPortion",
    "defaultMessage": "Pridať porciu",
  },
  {
    "id": "order.contextMenus.addPortion",
    "defaultMessage": "Pridať porciu",
  },
  {
    "id": "order.contextMenus.changeItemSize",
    "defaultMessage": "Zmeniť veľkosť porcie",
  },
  {
    "id": "order.contextMenus.changeItemSize",
    "defaultMessage": "Zmeniť veľkosť porcie",
  },
  {
    "id": "order.contextMenus.changeItemSize",
    "defaultMessage": "Zmeniť veľkosť porcie",
  },
  {
    "id": "order.contextMenus.changePriceLevel",
    "defaultMessage": "Zmeniť cenovú hladinu",
  },
  {
    "id": "order.contextMenus.changePriceLevel",
    "defaultMessage": "Zmeniť cenovú hladinu",
  },
  {
    "id": "order.contextMenus.choosePortion",
    "defaultMessage": "Vyberte veľkosť porcie",
  },
  {
    "id": "order.contextMenus.choosePortion",
    "defaultMessage": "Vyberte veľkosť porcie",
  },
  {
    "id": "order.contextMenus.choosePortion",
    "defaultMessage": "Vyberte veľkosť porcie",
  },
  {
    "id": "order.contextMenus.choosePrice",
    "defaultMessage": "Zadajte cenu",
  },
  {
    "id": "order.contextMenus.choosePrice",
    "defaultMessage": "Zadajte cenu",
  },
  {
    "id": "order.contextMenus.choosePriceLevel",
    "defaultMessage": "Vyberte cenovú hladinu",
  },
  {
    "id": "order.contextMenus.choosePriceLevel",
    "defaultMessage": "Vyberte cenovú hladinu",
  },
  {
    "id": "order.contextMenus.chooseQuantity",
    "defaultMessage": "Zadajte množstvo",
  },
  {
    "id": "order.contextMenus.chooseQuantity",
    "defaultMessage": "Zadajte množstvo",
  },
  {
    "id": "order.contextMenus.chooseWeight",
    "defaultMessage": "Zadajte váhu",
  },
  {
    "id": "order.contextMenus.chooseWeight",
    "defaultMessage": "Zadajte váhu",
  },
  {
    "id": "order.contextMenus.closeGroup",
    "defaultMessage": "Zavrieť skupinu",
  },
  {
    "id": "order.contextMenus.closeGroup",
    "defaultMessage": "Zavrieť skupinu",
  },
  {
    "id": "order.contextMenus.closeMenu",
    "defaultMessage": "Zavrieť menu",
  },
  {
    "id": "order.contextMenus.closeMenu",
    "defaultMessage": "Zavrieť menu",
  },
  {
    "id": "order.contextMenus.deleteSelected",
    "defaultMessage": "Zmazať vybrané",
  },
  {
    "id": "order.contextMenus.deleteSelected",
    "defaultMessage": "Zmazať vybrané",
  },
  {
    "id": "order.contextMenus.deleteSelectedMultipleTimes",
    "defaultMessage": "Podržte pre nastavenie počtu",
  },
  {
    "id": "order.contextMenus.deleteSelectedMultipleTimes",
    "defaultMessage": "Podržte pre nastavenie počtu",
  },
  {
    "id": "order.contextMenus.divideGroup",
    "defaultMessage": "Rozdeliť",
  },
  {
    "id": "order.contextMenus.divideGroup",
    "defaultMessage": "Rozdeliť",
  },
  {
    "id": "order.contextMenus.itemPortion1",
    "defaultMessage": "Celá porcia",
  },
  {
    "id": "order.contextMenus.itemPortion1",
    "defaultMessage": "Celá porcia",
  },
  {
    "id": "order.contextMenus.itemPortion1",
    "defaultMessage": "Celá porcia",
  },
  {
    "id": "order.contextMenus.itemPortion2",
    "defaultMessage": "½ porcia",
  },
  {
    "id": "order.contextMenus.itemPortion2",
    "defaultMessage": "½ porcia",
  },
  {
    "id": "order.contextMenus.itemPortion2",
    "defaultMessage": "½ porcia",
  },
  {
    "id": "order.contextMenus.itemPortion3",
    "defaultMessage": "⅓ porcia",
  },
  {
    "id": "order.contextMenus.itemPortion3",
    "defaultMessage": "⅓ porcia",
  },
  {
    "id": "order.contextMenus.itemPortion3",
    "defaultMessage": "⅓ porcia",
  },
  {
    "id": "order.contextMenus.itemPortion4",
    "defaultMessage": "¼ porcia",
  },
  {
    "id": "order.contextMenus.itemPortion4",
    "defaultMessage": "¼ porcia",
  },
  {
    "id": "order.contextMenus.itemPortion4",
    "defaultMessage": "¼ porcia",
  },
  {
    "id": "order.contextMenus.openGroup",
    "defaultMessage": "Otvoriť skupinu",
  },
  {
    "id": "order.contextMenus.openGroup",
    "defaultMessage": "Otvoriť skupinu",
  },
  {
    "id": "order.contextMenus.quantityUnit",
    "defaultMessage": "{quantity} ks",
  },
  {
    "id": "order.contextMenus.quantityUnit",
    "defaultMessage": "{quantity} ks",
  },
  {
    "id": "order.contextMenus.relocateToFoodCourse",
    "defaultMessage": "Presunúť na chod",
  },
  {
    "id": "order.contextMenus.relocateToFoodCourse",
    "defaultMessage": "Presunúť na chod",
  },
  {
    "id": "order.contextMenus.relocateToOpenTable",
    "defaultMessage": "Presunúť na stôl",
  },
  {
    "id": "order.contextMenus.relocateToOpenTable",
    "defaultMessage": "Presunúť na stôl",
  },
  {
    "id": "order.contextMenus.relocateToOpenTableStop",
    "defaultMessage": "Zrušiť presúvanie",
  },
  {
    "id": "order.contextMenus.relocateToOpenTableStop",
    "defaultMessage": "Zrušiť presúvanie",
  },
  {
    "id": "order.contextMenus.relocateToSubTable",
    "defaultMessage": "Presunúť na hosťa",
  },
  {
    "id": "order.contextMenus.relocateToSubTable",
    "defaultMessage": "Presunúť na hosťa",
  },
  {
    "id": "order.contextMenus.selectCount",
    "defaultMessage": "Vybrať počet",
  },
  {
    "id": "order.contextMenus.selectCount",
    "defaultMessage": "Vybrať počet",
  },
  {
    "id": "order.fastFood.dailyMenu",
    "defaultMessage": "Denné menu",
  },
  {
    "id": "order.fastFood.menuItem",
    "defaultMessage": "menu položka",
  },
  {
    "id": "order.fastFood.noItems",
    "defaultMessage": "Menu nemá žiadne položky",
  },
  {
    "id": "order.fastFood.notAvailable",
    "defaultMessage": "Nedostupné",
  },
  {
    "id": "order.fastFood.wrongConfiguration",
    "defaultMessage": "Produkt je zle nakonfigurovaný",
  },
  {
    "id": "order.generic.cancelOrder.cancel",
    "defaultMessage": "Zostať",
  },
  {
    "id": "order.generic.cancelOrder.confirm",
    "defaultMessage": "Áno",
  },
  {
    "id": "order.generic.cancelOrder.description",
    "defaultMessage": "Objednávka nebude uložená, naozaj chcete ísť späť?",
  },
  {
    "id": "order.generic.cancelOrder.header",
    "defaultMessage": "Zrušenie objednávky",
  },
  {
    "id": "order.generic.closeTable",
    "defaultMessage": "Zavrieť stôl",
  },
  {
    "id": "order.generic.localItems.remove.cancel",
    "defaultMessage": "Nie",
  },
  {
    "id": "order.generic.localItems.remove.confirm",
    "defaultMessage": "Áno",
  },
  {
    "id": "order.generic.localItems.remove.description",
    "defaultMessage": "Vymazať lokálne položky?",
  },
  {
    "id": "order.generic.localItems.remove.header",
    "defaultMessage": "Vymazanie lokálnych položiek",
  },
  {
    "id": "order.menuItems.mainCategory",
    "defaultMessage": "Hlavná",
  },
  {
    "id": "order.newGuest",
    "defaultMessage": "Nový hosť",
  },
  {
    "id": "order.noSelectedItems",
    "defaultMessage": "Žiadne položky pre vybraný chod",
  },
  {
    "id": "order.notifications.chooseFoodCourse",
    "defaultMessage": "Vyberte chod, na ktorý chcete presunúť položky",
  },
  {
    "id": "order.notifications.chooseFoodCourse",
    "defaultMessage": "Vyberte chod, na ktorý chcete presunúť položky",
  },
  {
    "id": "order.notifications.chooseSubTable",
    "defaultMessage": "Vyberte hosťa, na ktorého chcete presunúť položky",
  },
  {
    "id": "order.notifications.chooseSubTable",
    "defaultMessage": "Vyberte hosťa, na ktorého chcete presunúť položky",
  },
  {
    "id": "order.notifications.chooseTable",
    "defaultMessage": "Vyberte stôl, na ktorý chcete presunúť položky",
  },
  {
    "id": "order.notifications.chooseTable",
    "defaultMessage": "Vyberte stôl, na ktorý chcete presunúť položky",
  },
  {
    "id": "order.orderPage.noAccess",
    "defaultMessage": "Nemáte právo na prístup k objednávkam",
  },
  {
    "id": "order.page.choosePriceLevel",
    "defaultMessage": "Vyberte cenovú hladinu",
  },
  {
    "id": "order.page.choosePriceLevel",
    "defaultMessage": "Vyberte cenovú hladinu",
  },
  {
    "id": "order.page.close",
    "defaultMessage": "Zavrieť",
  },
  {
    "id": "order.page.close",
    "defaultMessage": "Zavrieť",
  },
  {
    "id": "order.page.count",
    "defaultMessage": "Počet v ks",
  },
  {
    "id": "order.page.error",
    "defaultMessage": "Chyba bločku - dokončiť bloček",
  },
  {
    "id": "order.popupTextForm.number",
    "defaultMessage": "Zadajte číslo faktúry",
  },
  {
    "id": "order.popupTextForm.ok",
    "defaultMessage": "OK",
  },
  {
    "id": "order.popupWeightForm.actualPrice",
    "defaultMessage": "Aktuálna cena",
  },
  {
    "id": "order.popupWeightForm.availability",
    "defaultMessage": "Dostupnosť",
  },
  {
    "id": "order.popupWeightForm.kg",
    "defaultMessage": "kg",
  },
  {
    "id": "order.popupWeightForm.notAvailable",
    "defaultMessage": "Požadované množstvo nie je dostupné",
  },
  {
    "id": "order.popupWeightForm.plate",
    "defaultMessage": "Tanier",
  },
  {
    "id": "order.popupWeightForm.unitPrice",
    "defaultMessage": "Jednotková cena",
  },
  {
    "id": "order.popupWeightForm.weightItem",
    "defaultMessage": "Váženie tovaru",
  },
  {
    "id": "order.popupWeightForm.withoutPlate",
    "defaultMessage": "Bez taniera",
  },
  {
    "id": "order.priceOrQuantity.amount",
    "defaultMessage": "Zadajte množstvo",
  },
  {
    "id": "order.priceOrQuantity.count",
    "defaultMessage": "Zadajte počet",
  },
  {
    "id": "order.priceOrQuantity.ks",
    "defaultMessage": "ks",
  },
  {
    "id": "order.priceOrQuantity.maxDelete",
    "defaultMessage": "max. na vymazanie",
  },
  {
    "id": "order.priceOrQuantity.maxSelected",
    "defaultMessage": "max. na označenie",
  },
  {
    "id": "order.priceOrQuantity.price",
    "defaultMessage": "Zadajte cenu",
  },
  {
    "id": "order.relocating.cancel",
    "defaultMessage": "Zrušiť presun",
  },
  {
    "id": "order.relocating.chooseRoom",
    "defaultMessage": "Vyberte miestnosť",
  },
  {
    "id": "order.relocating.rooms",
    "defaultMessage": "Miestnosti",
  },
  {
    "id": "order.sidebar.cancel",
    "defaultMessage": "Zrušiť",
  },
  {
    "id": "order.sidebar.caption.confirm",
    "defaultMessage": "Potvrdiť",
  },
  {
    "id": "order.sidebar.confirm",
    "defaultMessage": "Áno",
  },
  {
    "id": "order.sidebar.description",
    "defaultMessage": "Označené položky budú odstránené, chcete ich naozaj vymazať?",
  },
  {
    "id": "order.sidebar.from",
    "defaultMessage": "z",
  },
  {
    "id": "order.sidebar.header",
    "defaultMessage": "Vymazanie položiek",
  },
  {
    "id": "order.sidebar.payAll",
    "defaultMessage": "Zaplatiť všetko",
  },
  {
    "id": "order.sidebar.payCash",
    "defaultMessage": "Zaplatiť v hotovosti",
  },
  {
    "id": "order.sidebar.paySelected",
    "defaultMessage": "Zaplatiť vybrané",
  },
  {
    "id": "order.subTables.guests",
    "defaultMessage": "Všetci hostia",
  },
  {
    "id": "order.weightForm.confirm",
    "defaultMessage": "Potvrdiť váhu",
  },
  {
    "id": "payment.accountStateCredit",
    "defaultMessage": "stav účtu (v kreditoch)",
  },
  {
    "id": "payment.backToHotel",
    "defaultMessage": "Späť na výber hotela",
  },
  {
    "id": "payment.backToPayment",
    "defaultMessage": "Späť na platbu",
  },
  {
    "id": "payment.backToPayments",
    "defaultMessage": "Späť na platby",
  },
  {
    "id": "payment.backToUsers",
    "defaultMessage": "Späť na používateľov",
  },
  {
    "id": "payment.bankPartner",
    "defaultMessage": "Úverový partner",
  },
  {
    "id": "payment.chooseCard",
    "defaultMessage": "Vyberte kartu.",
  },
  {
    "id": "payment.choosePackage",
    "defaultMessage": "NÁKUP - Vyberte balík",
  },
  {
    "id": "payment.chooseWallet",
    "defaultMessage": "VÝBER - Vyberte peňaženku",
  },
  {
    "id": "payment.codeLoaded",
    "defaultMessage": "Kód načítaný.",
  },
  {
    "id": "payment.confirm",
    "defaultMessage": "Potvrdiť",
  },
  {
    "id": "payment.confirmCouponCode",
    "defaultMessage": "Potvrdiť kód kupónu",
  },
  {
    "id": "payment.contextMenus.absoluteDiscount",
    "defaultMessage": "Absolútna",
  },
  {
    "id": "payment.contextMenus.absoluteDiscount",
    "defaultMessage": "Absolútna",
  },
  {
    "id": "payment.contextMenus.discount10Percent",
    "defaultMessage": "Zľava 10%",
  },
  {
    "id": "payment.contextMenus.discount10Percent",
    "defaultMessage": "Zľava 10%",
  },
  {
    "id": "payment.contextMenus.discount15Percent",
    "defaultMessage": "Zľava 15%",
  },
  {
    "id": "payment.contextMenus.discount15Percent",
    "defaultMessage": "Zľava 15%",
  },
  {
    "id": "payment.contextMenus.discount20Percent",
    "defaultMessage": "Zľava 20%",
  },
  {
    "id": "payment.contextMenus.discount20Percent",
    "defaultMessage": "Zľava 20%",
  },
  {
    "id": "payment.contextMenus.discount25Percent",
    "defaultMessage": "Zľava 25%",
  },
  {
    "id": "payment.contextMenus.discount25Percent",
    "defaultMessage": "Zľava 25%",
  },
  {
    "id": "payment.contextMenus.discount5Percent",
    "defaultMessage": "Zľava 5%",
  },
  {
    "id": "payment.contextMenus.discount5Percent",
    "defaultMessage": "Zľava 5%",
  },
  {
    "id": "payment.contextMenus.percentageDiscount",
    "defaultMessage": "Percentuálna",
  },
  {
    "id": "payment.contextMenus.percentageDiscount",
    "defaultMessage": "Percentuálna",
  },
  {
    "id": "payment.contextMenus.relativeDiscount",
    "defaultMessage": "Percentuálna",
  },
  {
    "id": "payment.contextMenus.roomService",
    "defaultMessage": "Prirážka room service",
  },
  {
    "id": "payment.contextMenus.roomService",
    "defaultMessage": "Prirážka room service",
  },
  {
    "id": "payment.couponCode",
    "defaultMessage": "couponCode",
  },
  {
    "id": "payment.creatingCard",
    "defaultMessage": "vytváram kartu ...",
  },
  {
    "id": "payment.creatingUser",
    "defaultMessage": "vytváram užívateľa ...",
  },
  {
    "id": "payment.description",
    "defaultMessage": "Poznámka k platbe",
  },
  {
    "id": "payment.editPayment",
    "defaultMessage": "Editácia Platby",
  },
  {
    "id": "payment.editPaymentConfirmCount",
    "defaultMessage": "Potvrdiť počty",
  },
  {
    "id": "payment.editPaymentError",
    "defaultMessage": "Error pri editovaní platby: ",
  },
  {
    "id": "payment.editPaymentPrice",
    "defaultMessage": "Cena",
  },
  {
    "id": "payment.editPaymentRecord.error",
    "defaultMessage": "Error pri editovaní platby: ",
  },
  {
    "id": "payment.editPaymentRecord.price",
    "defaultMessage": "Cena",
  },
  {
    "id": "payment.editPaymentRecord.tip",
    "defaultMessage": "Tip",
  },
  {
    "id": "payment.editPaymentTip",
    "defaultMessage": "Tip",
  },
  {
    "id": "payment.email",
    "defaultMessage": "Email",
  },
  {
    "id": "payment.error.cancelPayment",
    "defaultMessage": "Zrušiť platbu",
  },
  {
    "id": "payment.error.finish.button",
    "defaultMessage": "Dokončiť bloček",
  },
  {
    "id": "payment.error.finish.header",
    "defaultMessage": "Chyba platby - dokončenie bločku",
  },
  {
    "id": "payment.error.reprint",
    "defaultMessage": "Znovu vytlačiť bloček",
  },
  {
    "id": "payment.expiration",
    "defaultMessage": "expirácia",
  },
  {
    "id": "payment.expirationDays",
    "defaultMessage": "expirácia (v dňoch)",
  },
  {
    "id": "payment.goBack",
    "defaultMessage": "Vrátiť sa",
  },
  {
    "id": "payment.guestCardRead",
    "defaultMessage": "Načítať hosťovskú kartu",
  },
  {
    "id": "payment.hores.chooseGuest",
    "defaultMessage": "Výber hosťa",
  },
  {
    "id": "payment.hores.chooseHotel",
    "defaultMessage": "Výber hotela",
  },
  {
    "id": "payment.hores.chooseRoom",
    "defaultMessage": "Výber izby",
  },
  {
    "id": "payment.hores.roomNumber",
    "defaultMessage": "Číslo izby",
  },
  {
    "id": "payment.hores.roomNumberis",
    "defaultMessage": "izba č.",
  },
  {
    "id": "payment.hores.skipChooseGuest",
    "defaultMessage": "Preskočiť výber hosťa",
  },
  {
    "id": "payment.itemWithoutName",
    "defaultMessage": "položka bez mena",
  },
  {
    "id": "payment.loadingSelectedUser",
    "defaultMessage": "Vybraný používateľ sa načítava",
  },
  {
    "id": "payment.loyalmanUsers",
    "defaultMessage": "LoyalMAN používatelia",
  },
  {
    "id": "payment.newCardPopup.address",
    "defaultMessage": "Adresa",
  },
  {
    "id": "payment.newCardPopup.email",
    "defaultMessage": "Email",
  },
  {
    "id": "payment.newCardPopup.name",
    "defaultMessage": "Meno",
  },
  {
    "id": "payment.newCardPopup.phone",
    "defaultMessage": "Telefón",
  },
  {
    "id": "payment.newCardPopup.surname",
    "defaultMessage": "Priezvisko",
  },
  {
    "id": "payment.newCardPopup.work",
    "defaultMessage": "Zamestnávateľ",
  },
  {
    "id": "payment.newPartner",
    "defaultMessage": "Nový partner",
  },
  {
    "id": "payment.noItems",
    "defaultMessage": "Žiadne položky",
  },
  {
    "id": "payment.number",
    "defaultMessage": "Číslo",
  },
  {
    "id": "payment.numberOfCopies.header",
    "defaultMessage": "Zadajte počet kópií",
  },
  {
    "id": "payment.numberOfCopies.paymentNote.header",
    "defaultMessage": "Poznámky k platbe",
  },
  {
    "id": "payment.ok",
    "defaultMessage": "OK",
  },
  {
    "id": "payment.okp",
    "defaultMessage": "okp",
  },
  {
    "id": "payment.page.absolute",
    "defaultMessage": "EUR",
  },
  {
    "id": "payment.page.absolute",
    "defaultMessage": "EUR",
  },
  {
    "id": "payment.page.absoluteDiscount",
    "defaultMessage": "Absolútna",
  },
  {
    "id": "payment.page.address1",
    "defaultMessage": "Ulica",
  },
  {
    "id": "payment.page.address1",
    "defaultMessage": "Ulica",
  },
  {
    "id": "payment.page.address2",
    "defaultMessage": "Mesto, PSČ",
  },
  {
    "id": "payment.page.address2",
    "defaultMessage": "Mesto, PSČ",
  },
  {
    "id": "payment.page.address3",
    "defaultMessage": "Krajina",
  },
  {
    "id": "payment.page.address3",
    "defaultMessage": "Krajina",
  },
  {
    "id": "payment.page.assignToUser",
    "defaultMessage": "Priradiť k používateľovi ",
  },
  {
    "id": "payment.page.backPayment",
    "defaultMessage": "Späť na platbu",
  },
  {
    "id": "payment.page.backToPayment",
    "defaultMessage": "Naspäť na platbu",
  },
  {
    "id": "payment.page.backToPayment",
    "defaultMessage": "Naspäť na platbu",
  },
  {
    "id": "payment.page.backToTable",
    "defaultMessage": "Naspäť na stôl",
  },
  {
    "id": "payment.page.backToTable",
    "defaultMessage": "Naspäť na stôl",
  },
  {
    "id": "payment.page.bankCard",
    "defaultMessage": "Banková karta",
  },
  {
    "id": "payment.page.bankCard",
    "defaultMessage": "Banková karta",
  },
  {
    "id": "payment.page.cardCode",
    "defaultMessage": "ID karty",
  },
  {
    "id": "payment.page.cardExpiration",
    "defaultMessage": "Expirácia karty",
  },
  {
    "id": "payment.page.cardExpiration",
    "defaultMessage": "Expirácia karty",
  },
  {
    "id": "payment.page.cardNotInactive",
    "defaultMessage": "Zadaná karta je už obsadená",
  },
  {
    "id": "payment.page.cardNotRegistered",
    "defaultMessage": "Zadaná karta nie je registrovaná",
  },
  {
    "id": "payment.page.cash",
    "defaultMessage": "V hotovosti",
  },
  {
    "id": "payment.page.cash",
    "defaultMessage": "V hotovosti",
  },
  {
    "id": "payment.page.chooseCard",
    "defaultMessage": "Vyberte kartu",
  },
  {
    "id": "payment.page.closeDiscountMenu",
    "defaultMessage": "Zavrieť výber zľavy",
  },
  {
    "id": "payment.page.closeDiscountMenu",
    "defaultMessage": "Zavrieť výber zľavy",
  },
  {
    "id": "payment.page.closePayment",
    "defaultMessage": "Zavrieť platbu",
  },
  {
    "id": "payment.page.closePayment",
    "defaultMessage": "Zavrieť platbu",
  },
  {
    "id": "payment.page.company",
    "defaultMessage": "Firma",
  },
  {
    "id": "payment.page.company",
    "defaultMessage": "Firma",
  },
  {
    "id": "payment.page.copies",
    "defaultMessage": "Kópií",
  },
  {
    "id": "payment.page.copies",
    "defaultMessage": "Kópií",
  },
  {
    "id": "payment.page.createNewCard",
    "defaultMessage": "Vytvoriť novú kartu",
  },
  {
    "id": "payment.page.createNewCard",
    "defaultMessage": "Vytvoriť novú kartu",
  },
  {
    "id": "payment.page.createNewUser",
    "defaultMessage": "Pridať používateľa",
  },
  {
    "id": "payment.page.descShort",
    "defaultMessage": "Pozn.",
  },
  {
    "id": "payment.page.dic",
    "defaultMessage": "DIČ",
  },
  {
    "id": "payment.page.dic",
    "defaultMessage": "DIČ",
  },
  {
    "id": "payment.page.discount",
    "defaultMessage": "Zľava",
  },
  {
    "id": "payment.page.discount",
    "defaultMessage": "Zľava",
  },
  {
    "id": "payment.page.discountReason",
    "defaultMessage": "Dôvod zľavy",
  },
  {
    "id": "payment.page.discountReason",
    "defaultMessage": "Dôvod zľavy",
  },
  {
    "id": "payment.page.dot",
    "defaultMessage": ",",
  },
  {
    "id": "payment.page.dot",
    "defaultMessage": ",",
  },
  {
    "id": "payment.page.enterCardManualy",
    "defaultMessage": "Zadať ID manuálne",
  },
  {
    "id": "payment.page.finishAndPrintReceipt",
    "defaultMessage": "Uzavrieť účet a tlačiť blok",
  },
  {
    "id": "payment.page.finishAndPrintReceipt",
    "defaultMessage": "Uzavrieť účet a tlačiť blok",
  },
  {
    "id": "payment.page.giveTip",
    "defaultMessage": "Zadať TIP",
  },
  {
    "id": "payment.page.icdph",
    "defaultMessage": "IČ DPH",
  },
  {
    "id": "payment.page.icdph",
    "defaultMessage": "IČ DPH",
  },
  {
    "id": "payment.page.ico",
    "defaultMessage": "IČO",
  },
  {
    "id": "payment.page.ico",
    "defaultMessage": "IČO",
  },
  {
    "id": "payment.page.leftToPay",
    "defaultMessage": "Zostáva doplatiť",
  },
  {
    "id": "payment.page.leftToPay",
    "defaultMessage": "Zostáva doplatiť",
  },
  {
    "id": "payment.page.leftToPayExact",
    "defaultMessage": "Zaplatené presne",
  },
  {
    "id": "payment.page.leftToPayExact",
    "defaultMessage": "Zaplatené presne",
  },
  {
    "id": "payment.page.leftToPaySurplus",
    "defaultMessage": "Preplatok",
  },
  {
    "id": "payment.page.leftToPaySurplus",
    "defaultMessage": "Preplatok",
  },
  {
    "id": "payment.page.leftToPayTip",
    "defaultMessage": "Preplatok (TIP)",
  },
  {
    "id": "payment.page.leftToPayTip",
    "defaultMessage": "Preplatok (TIP)",
  },
  {
    "id": "payment.page.loyalMAN",
    "defaultMessage": "LoyalMAN",
  },
  {
    "id": "payment.page.mealVouchers",
    "defaultMessage": "Stravné lístky",
  },
  {
    "id": "payment.page.mealVouchers",
    "defaultMessage": "Stravné lístky",
  },
  {
    "id": "payment.page.name",
    "defaultMessage": "Meno",
  },
  {
    "id": "payment.page.name",
    "defaultMessage": "Meno",
  },
  {
    "id": "payment.page.newCardPackages",
    "defaultMessage": "Balíčky pre novú kartu",
  },
  {
    "id": "payment.page.newCardPackages",
    "defaultMessage": "Balíčky pre novú kartu",
  },
  {
    "id": "payment.page.newCustomer",
    "defaultMessage": "Nový zákazník",
  },
  {
    "id": "payment.page.overallSum",
    "defaultMessage": "Celková suma",
  },
  {
    "id": "payment.page.overallSum",
    "defaultMessage": "Celková suma",
  },
  {
    "id": "payment.page.overallSumAfterDiscount",
    "defaultMessage": "Suma po zľave",
  },
  {
    "id": "payment.page.overallSumAfterDiscount",
    "defaultMessage": "Suma po zľave",
  },
  {
    "id": "payment.page.overPayment",
    "defaultMessage": "preplatok",
  },
  {
    "id": "payment.page.pay",
    "defaultMessage": "Platiť",
  },
  {
    "id": "payment.page.pay",
    "defaultMessage": "Platiť",
  },
  {
    "id": "payment.page.printer",
    "defaultMessage": "Tlačiareň",
  },
  {
    "id": "payment.page.printer",
    "defaultMessage": "Tlačiareň",
  },
  {
    "id": "payment.page.printPrereceipt",
    "defaultMessage": "Tlačiť predúčet",
  },
  {
    "id": "payment.page.printPrereceipt",
    "defaultMessage": "Tlačiť predúčet",
  },
  {
    "id": "payment.page.registerNewUser",
    "defaultMessage": "Registrovať nového používateľa",
  },
  {
    "id": "payment.page.registerParagon",
    "defaultMessage": "Registrovať paragon",
  },
  {
    "id": "payment.page.registerParagon",
    "defaultMessage": "Registrovať paragon",
  },
  {
    "id": "payment.page.registerSeparately",
    "defaultMessage": "Zákazník sa sám registruje cez web",
  },
  {
    "id": "payment.page.relative",
    "defaultMessage": "%",
  },
  {
    "id": "payment.page.relative",
    "defaultMessage": "%",
  },
  {
    "id": "payment.page.relativeDiscount",
    "defaultMessage": "Percentuálna",
  },
  {
    "id": "payment.page.remaind",
    "defaultMessage": "zostatok",
  },
  {
    "id": "payment.page.remaind",
    "defaultMessage": "zostatok",
  },
  {
    "id": "payment.page.schvalil",
    "defaultMessage": "Schválil",
  },
  {
    "id": "payment.page.schvalil",
    "defaultMessage": "Schválil",
  },
  {
    "id": "payment.page.sendReceiptByEmail",
    "defaultMessage": "Poslať bloček mailom",
  },
  {
    "id": "payment.page.sendReceiptByEmail",
    "defaultMessage": "Poslať bloček mailom",
  },
  {
    "id": "payment.page.setDiscount",
    "defaultMessage": "Zadať zľavu",
  },
  {
    "id": "payment.page.setDiscount",
    "defaultMessage": "Zadať zľavu",
  },
  {
    "id": "payment.page.subtotal",
    "defaultMessage": "Medzivýsledky",
  },
  {
    "id": "payment.page.subtotal",
    "defaultMessage": "Medzivýsledky",
  },
  {
    "id": "payment.page.tip",
    "defaultMessage": "TIP",
  },
  {
    "id": "payment.page.tip",
    "defaultMessage": "TIP",
  },
  {
    "id": "payment.page.title",
    "defaultMessage": "Platba",
  },
  {
    "id": "payment.page.title",
    "defaultMessage": "Platba",
  },
  {
    "id": "payment.page.touchTheCardReader",
    "defaultMessage": "Priložte kartu k čítačke",
  },
  {
    "id": "payment.page.txt",
    "defaultMessage": "Poznámka",
  },
  {
    "id": "payment.page.txt",
    "defaultMessage": "Poznámka",
  },
  {
    "id": "payment.partnersList",
    "defaultMessage": "Zoznam partnerov",
  },
  {
    "id": "payment.paymentInProgress",
    "defaultMessage": "Prebieha platobná operácia",
  },
  {
    "id": "payment.paymentPage.unfinishedBill",
    "defaultMessage": "Chyba bločku - dokončiť bloček",
  },
  {
    "id": "payment.pgrDotazy.addPartner",
    "defaultMessage": "Pridať nového partnera",
  },
  {
    "id": "payment.pgrDotazy.chooseGuest",
    "defaultMessage": "Výber hosťa",
  },
  {
    "id": "payment.pgrDotazy.chooseOption",
    "defaultMessage": "Výber z možností",
  },
  {
    "id": "payment.pgrDotazy.guestName",
    "defaultMessage": "Meno hosťa",
  },
  {
    "id": "payment.pgrDotazy.newPartner",
    "defaultMessage": "Nový úverový partner",
  },
  {
    "id": "payment.pgrDotazy.number",
    "defaultMessage": "Číslo",
  },
  {
    "id": "payment.pgrDotazy.paymentApproved",
    "defaultMessage": "Platbu schválil…",
  },
  {
    "id": "payment.pgrDotazy.readGuestCard",
    "defaultMessage": "Načítať hosťovskú kartu",
  },
  {
    "id": "payment.pgrDotazy.roomNumber",
    "defaultMessage": "Číslo izby",
  },
  {
    "id": "payment.pgrDotazy.saveName",
    "defaultMessage": "Uložiť meno",
  },
  {
    "id": "payment.pgrDotazy.setRoomNumber",
    "defaultMessage": "Zadať názov izby",
  },
  {
    "id": "payment.pgrDotazy.skipChooseGuest",
    "defaultMessage": "Preskočiť výber hosťa",
  },
  {
    "id": "payment.printerSelect",
    "defaultMessage": "Vyberte tlačiareň",
  },
  {
    "id": "payment.registerParagon.date",
    "defaultMessage": "Dátum",
  },
  {
    "id": "payment.registerParagon.id",
    "defaultMessage": "Paragon Id",
  },
  {
    "id": "payment.registerParagon.time",
    "defaultMessage": "Čas",
  },
  {
    "id": "payment.roomName",
    "defaultMessage": "Zadať názov izby",
  },
  {
    "id": "payment.selectOptions",
    "defaultMessage": "Výber z možností",
  },
  {
    "id": "payment.sendByMail",
    "defaultMessage": "Poslať bloček emailom",
  },
  {
    "id": "payment.sendReceiptByEmail",
    "defaultMessage": "e-mail",
  },
  {
    "id": "payment.serverError",
    "defaultMessage": "Chyba servera.",
  },
  {
    "id": "payment.serviceCoupon.code",
    "defaultMessage": "Kód kupónu",
  },
  {
    "id": "payment.transactionAborted",
    "defaultMessage": "Platobná transakcia bola prerušená",
  },
  {
    "id": "payment.transactionError.back",
    "defaultMessage": "Vrátiť sa",
  },
  {
    "id": "payment.transactionError.cancelAndCreate",
    "defaultMessage": "Zrušiť pôvodnú a vytvoriť novú platbu",
  },
  {
    "id": "payment.transactionError.header",
    "defaultMessage": "Už existuje platobná transakcia pre vybrané položky",
  },
  {
    "id": "payment.transactionError.items",
    "defaultMessage": "Položky",
  },
  {
    "id": "payment.transactionError.waiter",
    "defaultMessage": "Čašník",
  },
  {
    "id": "priceList.unknownError",
    "defaultMessage": "Unknown error",
  },
  {
    "id": "priceList.updatedInfo",
    "defaultMessage": "Cenník bol aktualizovaný",
  },
  {
    "id": "priceList.updateHeader",
    "defaultMessage": "Aktualizácia cenníka",
  },
  {
    "id": "priceList.updateNow",
    "defaultMessage": "Aktualizovať teraz",
  },
  {
    "id": "priceList.updatingInfo",
    "defaultMessage": "Prebieha aktualizácia…",
  },
  {
    "id": "receipts.actual",
    "defaultMessage": "Účty v aktuál. uzávierke",
  },
  {
    "id": "receipts.add.tip",
    "defaultMessage": "Pridať TIP",
  },
  {
    "id": "receipts.cancel",
    "defaultMessage": "Zrušiť",
  },
  {
    "id": "receipts.cancel.chosen",
    "defaultMessage": "Stornovať vybrané",
  },
  {
    "id": "receipts.cancel.move",
    "defaultMessage": "Zrušiť presun na stôl",
  },
  {
    "id": "receipts.cancel.moveTable",
    "defaultMessage": "Storno a presun na stôl",
  },
  {
    "id": "receipts.cancel.receipt",
    "defaultMessage": "Stornovať účet",
  },
  {
    "id": "receipts.cancelPayment",
    "defaultMessage": "Zrušiť platbu",
  },
  {
    "id": "receipts.choosePayment",
    "defaultMessage": "Výber platby",
  },
  {
    "id": "receipts.enterTip",
    "defaultMessage": "Zadajte TIP",
  },
  {
    "id": "receipts.finish",
    "defaultMessage": "Dokončiť bloček",
  },
  {
    "id": "receipts.finishBill",
    "defaultMessage": "Dokončenie bločku",
  },
  {
    "id": "receipts.item.storno",
    "defaultMessage": "Položka účtenky stornovaná",
  },
  {
    "id": "receipts.noData",
    "defaultMessage": "žiaden záznam",
  },
  {
    "id": "receipts.okp",
    "defaultMessage": "okp",
  },
  {
    "id": "receipts.operationInProgress",
    "defaultMessage": "Prebieha operácia s účtami",
  },
  {
    "id": "receipts.payment.change",
    "defaultMessage": "Zmeniť platby",
  },
  {
    "id": "receipts.previous",
    "defaultMessage": "Účty z min. uzávierok",
  },
  {
    "id": "receipts.print.copy",
    "defaultMessage": "Tlač kópie účtu",
  },
  {
    "id": "receipts.printBillRepeat",
    "defaultMessage": "Znovu vytlačiť bloček",
  },
  {
    "id": "receipts.send.printer",
    "defaultMessage": "Účtenka bola odoslaná na tlač",
  },
  {
    "id": "receipts.sheetItemsError",
    "defaultMessage": "Error pri vypísaní položiek účtenky: ",
  },
  {
    "id": "receipts.sheetNoItems",
    "defaultMessage": "Žiadne položky",
  },
  {
    "id": "receipts.storno",
    "defaultMessage": "Účtenka stornovaná",
  },
  {
    "id": "receipts.table.date",
    "defaultMessage": "Dátum",
  },
  {
    "id": "receipts.table.id",
    "defaultMessage": "Id",
  },
  {
    "id": "receipts.table.payments",
    "defaultMessage": "Platby",
  },
  {
    "id": "receipts.table.table",
    "defaultMessage": "Stôl",
  },
  {
    "id": "receipts.table.user",
    "defaultMessage": "Užívateľ",
  },
  {
    "id": "receipts.tip",
    "defaultMessage": "tip",
  },
  {
    "id": "receipts.tipAdded",
    "defaultMessage": "TIP bol pridaný",
  },
  {
    "id": "receipts.uid",
    "defaultMessage": "uid",
  },
  {
    "id": "serviceAreas.deposit",
    "defaultMessage": "Vklad",
  },
  {
    "id": "serviceAreas.depositCompleted",
    "defaultMessage": "Vklad prebehol úspešne",
  },
  {
    "id": "serviceAreas.editables.operationInProgress",
    "defaultMessage": "Prebieha operácia",
  },
  {
    "id": "serviceAreas.editables.tableName",
    "defaultMessage": "Názov stolu",
  },
  {
    "id": "serviceAreas.noRoomsError",
    "defaultMessage": "Nie sú definované žiadne miestnosti.",
  },
  {
    "id": "serviceAreas.onetimeTable.name",
    "defaultMessage": "Názov stola",
  },
  {
    "id": "serviceAreas.openedTables",
    "defaultMessage": "Otvorené stoly",
  },
  {
    "id": "serviceAreas.options.areas",
    "defaultMessage": "Miestnosti",
  },
  {
    "id": "serviceAreas.options.bar",
    "defaultMessage": "Bar",
  },
  {
    "id": "serviceAreas.options.barInCash",
    "defaultMessage": "Bar v hotovosti",
  },
  {
    "id": "serviceAreas.options.deposit",
    "defaultMessage": "Vklad",
  },
  {
    "id": "serviceAreas.options.edit",
    "defaultMessage": "Editovať",
  },
  {
    "id": "serviceAreas.options.editDone",
    "defaultMessage": "Editácia",
  },
  {
    "id": "serviceAreas.options.noMap",
    "defaultMessage": "Jednorázové stoly",
  },
  {
    "id": "serviceAreas.options.noServiceArea",
    "defaultMessage": "Neznáma miestnosť",
  },
  {
    "id": "serviceAreas.options.oneTimeTable",
    "defaultMessage": "Jednoraz. stôl",
  },
  {
    "id": "serviceAreas.options.openTables",
    "defaultMessage": "Otvorené stoly",
  },
  {
    "id": "serviceAreas.options.options",
    "defaultMessage": "Možnosti",
  },
  {
    "id": "serviceAreas.options.sendUnsent",
    "defaultMessage": "Poslať bločky",
  },
  {
    "id": "serviceAreas.options.suflik",
    "defaultMessage": "Šuflík",
  },
  {
    "id": "serviceAreas.options.withdrawal",
    "defaultMessage": "Výber",
  },
  {
    "id": "serviceAreas.paymentType",
    "defaultMessage": "Spôsob platby",
  },
  {
    "id": "serviceAreas.printerSelection",
    "defaultMessage": "Výber tlačiarne",
  },
  {
    "id": "serviceAreas.reverted",
    "defaultMessage": "Položka účtenky stornovaná a navrátená na stôl",
  },
  {
    "id": "serviceAreas.revertError",
    "defaultMessage": "Error pri storne účtenky a návrate na stôl:",
  },
  {
    "id": "serviceAreas.selectServiceArea",
    "defaultMessage": "Vyberte miestnosť",
  },
  {
    "id": "serviceAreas.tablesGrid.accessDenied",
    "defaultMessage": "Nemáte právo na čítanie stolov",
  },
  {
    "id": "serviceAreas.withdrawal",
    "defaultMessage": "Výber",
  },
  {
    "id": "serviceAreas.withdrawalCompleted",
    "defaultMessage": "Výber prebehol úspešne",
  },
  {
    "id": "tables.contextMenus.cancel",
    "defaultMessage": "Zrušiť",
  },
  {
    "id": "tables.contextMenus.changePriceLevel",
    "defaultMessage": "Zmeniť cenovú hladinu",
  },
  {
    "id": "tables.contextMenus.close",
    "defaultMessage": "Zavrieť stôl",
  },
  {
    "id": "tables.contextMenus.edit",
    "defaultMessage": "Premenovať",
  },
  {
    "id": "tables.contextMenus.join",
    "defaultMessage": "Spojiť podstoly",
  },
  {
    "id": "tables.contextMenus.newSubtable",
    "defaultMessage": "Nový hosť",
  },
  {
    "id": "tables.contextMenus.pay",
    "defaultMessage": "Zaplatiť",
  },
  {
    "id": "tables.contextMenus.split",
    "defaultMessage": "Vytvoriť podstôl",
  },
  {
    "id": "tables.states.unopened",
    "defaultMessage": "Neotvorený",
  },
  {
    "id": "usage.actual",
    "defaultMessage": "Aktuálne",
  },
  {
    "id": "usage.cancel",
    "defaultMessage": "Zrušiť",
  },
  {
    "id": "usage.printStatistics",
    "defaultMessage": "Vytlačiť štatistiku",
  },
  {
    "id": "usage.range",
    "defaultMessage": "Za obdobie",
  },
  {
    "id": "usage.sendPrintStatistics",
    "defaultMessage": "Štatistika bola odoslaná na tlač",
  },
  {
    "id": "usage.table.category",
    "defaultMessage": "Kategória",
  },
  {
    "id": "usage.table.soldAmount",
    "defaultMessage": "Predané množstvo",
  },
];
