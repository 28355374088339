// @flow
import React from 'react';
import Button from '../../common/components/Button';
import { map, zipObj } from 'rambda';

type CornerElProps = {
  onPress?: Function => Promise,
  corner?: string,
  size?: number,
  inside?: boolean,
  children?: any
}

const Corner = ({
  corner = 'top-right',
  onPress = () => {},
  size = 1,
  children,
  inside,
  ...restProps
}: CornerElProps) => {
  const cornerSplitted = corner.split('-');
  const cornerSizes = () => size * 0.75 * (inside ? 0 : -0.5);
  const cornerProps = zipObj(cornerSplitted, map(cornerSizes, cornerSplitted));

  return (
    <Button
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      onPress={onPress}
      marginBottom={0}
      width={size * 0.75}
      height={size * 0.75}
      {...cornerProps}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default Corner;
