// @flow
import React from 'react';
import SubTableMenuButton from './SubTableMenuButton';
import connect from 'react-redux/es/connect/connect';
import type { Id, State, SubTable } from '../../types';
import {
  activeOpenTableIdSelector,
  subTablesForActiveOpenTableSelector
} from '../../tables/selectors';
import { filter, find, last, pluck, propEq } from 'rambda';
import { activateSubTable, deleteSubTable } from '../../tables/actions';
import {
  clearSelectedOrderLines,
  relocateToSubTable,
  toggleDeletingSubTable,
  toggleRelocatingToSubTable, toggleSubTableMenu
} from '../../order/actions';
import { createSelector } from 'reselect';

class SubTablesList extends React.PureComponent {

  onSelectSubTable = (subTableId: Id) => {
    const {
      isRelocatingToSubTable,
      isDeletingSubTable,
      orderLines,
      selectedOrderLines,
      dispatch,
    } = this.props;

    const actions = [];

    actions.push(activateSubTable(subTableId));

    const movingToSubTable = isDeletingSubTable || isRelocatingToSubTable;

    if (movingToSubTable) {
      if (selectedOrderLines.length) {
        actions.push(relocateToSubTable(selectedOrderLines, subTableId));
      } else {
        const migratingOrderLines = filter(
          ol => ol.subTableId === movingToSubTable,
          orderLines
        );
        actions.push(relocateToSubTable(pluck('id', migratingOrderLines), subTableId));
        if (subTableId !== movingToSubTable) {
          actions.push(deleteSubTable(movingToSubTable));
        }
      }

      actions.push(toggleRelocatingToSubTable(false));
      actions.push(toggleDeletingSubTable(false));
    }
    actions.push(clearSelectedOrderLines());
    actions.push(toggleSubTableMenu(false));

    dispatch(actions);
  };

  onRemoveSubTable = (subTableId: Id) => {
    const {
      dispatch,
      orderLines,
      subTables,
    } = this.props;

    const subTableHasOrderLines = find(propEq('subTableId', subTableId), orderLines);

    if (subTableHasOrderLines) {
      dispatch([
        clearSelectedOrderLines(),
        toggleDeletingSubTable(subTableId)
      ]);
    } else {
      const lastOfRemainingSubTables = last(
        filter(st => st.id !== subTableId, subTables)
      );

      dispatch([
        deleteSubTable(subTableId),
        activateSubTable(lastOfRemainingSubTables ? lastOfRemainingSubTables.id : 'all'),
      ]);
    }
  };

  render() {
    const {
      subTables,
      activeSubTableId,
      isRelocatingToSubTable,
      isDeletingSubTable,
    } = this.props;

    return (
      <React.Fragment>
        {subTables.map(({ name, id }, index) => {
          const disabled =
            (isRelocatingToSubTable && ((activeSubTableId === id) || (name === 'all')))
            || (isDeletingSubTable && ((isDeletingSubTable === id) || (name === 'all')));

          return (
            <SubTableMenuButton
              key={id}
              id={id}
              Number={index}
              Text={name}
              onlyOne={subTables.length === 2} // 2 because the first element is 'all'
              isToBeDeleted={id === isDeletingSubTable}
              FirstFunction={this.onSelectSubTable}
              SecondFunction={!isRelocatingToSubTable ? this.onRemoveSubTable : null}
              disabled={disabled}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

export const subTablesWithPseudoAllSelector = createSelector(
  subTablesForActiveOpenTableSelector,
  activeOpenTableIdSelector,
  (subTables: SubTable[], activeOpenTableId: Id) => {
    const subTablesWithAll = [
      { id: 'all', openTableId: activeOpenTableId, name: 'all' },
      ...subTables
    ];
    return subTablesWithAll;
  }
);

export default connect((state: State) => ({
  subTables: subTablesWithPseudoAllSelector(state),
  activeSubTableId: state.tables.active.subTableId,
  isRelocatingToSubTable: state.orders.active.isRelocatingToSubTable,
  isDeletingSubTable: state.orders.active.isDeletingSubTable,
  orderLines: state.orders.orderLines,
  selectedOrderLines: state.orders.selectedOrderLines,
}))(SubTablesList);
