// @flow
import fetch from '../fetch';
import { apiEndpoint, getApiUrl } from './index';
import type { Id } from '../../types';

export const getUsage = (
  showActualUsage: boolean,
  dateFrom: string,
  dateTo: string,
  filter: string
): Promise => {
  const params = showActualUsage
    ? {
      actual: 1,
      filter
    }
    : {
      date_from: dateFrom,
      date_to: dateTo,
      filter
    };

  return fetch(apiEndpoint('v1/usage/categories/'), {
    baseURL: getApiUrl(),
    params
  });
};

export const getUsageItems = (
  showActualUsage: boolean,
  dateFrom: string,
  dateTo: string,
  categories: string[],
  print: boolean
): Promise => {
  const params = showActualUsage
    ? {
      actual: 1,
      categories,
      print: print ? 1 : undefined,
    }
    : {
      date_from: dateFrom,
      date_to: dateTo,
      categories,
      print: print ? 1 : undefined,
    };

  return fetch(apiEndpoint('v1/usage/items/'), {
    baseURL: getApiUrl(),
    params
  });
};

export const resetUsageItems = (
  itemId: Id,
  availability: number
): Promise =>
  fetch(apiEndpoint(`v1/usage/reset/${itemId}/`), {
    baseURL: getApiUrl(),
    method: 'POST',
    body: {
      params: {
        new_value: availability
      },
      name: 'ResetUsageItems',
      id: '',
      session_id: '',
      device_id: ''
    }
  });
