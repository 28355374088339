// @flow
import type { State } from '../../../common/types';
import React from 'react';
import { connect } from 'react-redux';
import Title from '../../components/Title';
import Box from '../../../common/components/Box';
import Sidebar from './Sidebar';
import UsersList from './UsersList';
import titlesMessages from '../../../common/app/messages/titles';
import TopBar from './TopBar';
import SidebarSpace from '../../components/Sidebar';
import { clearError as clearOrderError } from '../../../common/order/actions';
import { clearAdminError } from '../../../common/admin/general/actions';
import NotificationPlace from '../../order/NotificationPlace';
import { getAllErrors } from '../../../common/admin/receipts/selectors';
import AdminNotification from '../../admin/AdminNotification';
import InactiveBgWithTextSpinner from '../../components/InactiveBgWithTextSpinner';
import { compose } from 'rambda';
import { activateLoyalmanUser } from '../../../common/loyalman/users/actions';
import withApolloProvider from '../../../common/loyalman/hoc/withApolloProvider';
import { findParamValue } from '../../../common/parameters/utils';
import { injectIntl } from 'react-intl';
import loyalmanMessages from '../../../common/messages/loyalman';

class LoyalmanUsersPage extends React.PureComponent {
  state = {
    loyalmanFilter: ''
  };

  _clearError = () => {
    const { dispatch } = this.props;

    dispatch([
      clearOrderError(),
      clearAdminError()
    ]);
  };

  storeLoyalmanFilter = (filter) => {
    this.setState({ loyalmanFilter: filter });
  };

  componentWillMount() {
    const { dispatch, selectedOrderLines } = this.props;

    if (selectedOrderLines) {
      dispatch([
        clearOrderError(),
        clearAdminError(),
        activateLoyalmanUser(null)
      ]);
    }
  }

  fullLoyalmanContent = (allErrors, storeLoyalmanFilter, loyalmanFilter) => (
    <Box flexDirection="row" zIndex={1} flex={1} height="100%">
      <Box flex={1} id="receipts" width="100%">

        <UsersList storeLoyalmanFilter={storeLoyalmanFilter} />

        <NotificationPlace />

        <AdminNotification
          errors={allErrors}
          clearError={this._clearError}
        />
      </Box>

      <SidebarSpace>
        <Sidebar parentFilter={loyalmanFilter} />
      </SidebarSpace>
    </Box>
  );

  restrictedLoyalmanContent = allErrors => (
    <Box flexDirection="row" zIndex={1} flex={1} height="100%" backgroundColor="appBg" justifyContent="center">
      <Box width="400px" justifyContent="center">
        {/* parentFilter={loyalmanFilter} */}
        <Sidebar />
      </Box>
      <AdminNotification
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        errors={allErrors}
        clearError={this._clearError}
      />
    </Box>
  );

  render() {
    const { allErrors, loadingScreen, parameters, intl } = this.props;
    const { loyalmanFilter } = this.state;
    const loyalmanFullUsersInfo = findParamValue('K32.loyalman_full_users_info', parameters) || false;

    return (
      <Box flex={1} height="100%">
        <Title message={titlesMessages.admin} />

        <TopBar />

        {loyalmanFullUsersInfo
          ? this.fullLoyalmanContent(allErrors, this.storeLoyalmanFilter, loyalmanFilter)
          : this.restrictedLoyalmanContent(allErrors)}

        {loadingScreen &&
          <InactiveBgWithTextSpinner text={intl.formatMessage(loyalmanMessages.loyalmanOperationInProgress)} />}
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    parameters: state.parameters.parameters,
    allErrors: getAllErrors(state),
    loadingScreen: state.admin.general.loadingScreen,
    isLoyalManOn: state.loyalman.users.isLoyalManOn,
    selectedOrderLines: state.loyalman.users.selectedOrderLines,
    loyalmanConnection: state.loyalman.general
  })),
  withApolloProvider,
  injectIntl
)(LoyalmanUsersPage);
