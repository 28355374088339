// @flow
import type { KeyboardButtonsProps } from '../index';
import React from 'react';
import Box from '../../Box';
import KbButton from '../StandardButton';
import Icon from '../../../../browser/components/Icon';

const Buttons = ({ onPress }: KeyboardButtonsProps) => (
  <Box>
    <Box flexDirection="row">
      <KbButton char={7} onPress={onPress} borderRight borderTop />
      <KbButton char={8} onPress={onPress} borderRight borderTop />
      <KbButton char={9} onPress={onPress} borderTop />
    </Box>
    <Box flexDirection="row">
      <KbButton char={4} onPress={onPress} borderRight borderTop />
      <KbButton char={5} onPress={onPress} borderRight borderTop />
      <KbButton char={6} onPress={onPress} borderTop />
    </Box>
    <Box flexDirection="row">
      <KbButton char={1} onPress={onPress} borderRight borderTop />
      <KbButton char={2} onPress={onPress} borderRight borderTop />
      <KbButton char={3} onPress={onPress} borderTop />
    </Box>
    <Box flexDirection="row">
      <KbButton char={0} onPress={onPress} borderRight borderTop />
      <KbButton char="∞" onPress={onPress} borderRight borderTop sendChar={Infinity} />
      <KbButton char="ok" onPress={onPress} borderTop primary>
        <Icon name="check" scale={4} color="white" />
      </KbButton>
    </Box>
  </Box>

);

export default Buttons;
