// @flow
import type { KeyboardButtonsProps } from '../index';
import React from 'react';
import Box from '../../Box';
import KbButton from '../TypewriterButton';

class Buttons extends React.Component {
  props: KeyboardButtonsProps;

  constructor(props) {
    super(props);

    this.state = {
      shift: false,
      numerical: false
    };

    this._pressShift = this._pressShift.bind(this);
    this._pressNumerical = this._pressNumerical.bind(this);
  }

  _pressShift() {
    this.setState({
      shift: !this.state.shift,
      numerical: false
    });
  }
  _pressNumerical() {
    this.setState({
      numerical: !this.state.numerical,
      shift: false
    });
  }

  render() {
    const { onPress, activeInput, intlDot } = this.props;
    const { shift } = this.state;
    const { numerical } = this.state;

    return (
      <Box>
        {activeInput === 0
        ? <Box flexDirection="row" justifyContent="flex-end" marginTop={0.75} flexShrink={0}>
            <KbButton char={'1'} onPress={onPress} />
            <KbButton char={'2'} onPress={onPress} />
            <KbButton char={'3'} onPress={onPress} />
            <KbButton char={'4'} onPress={onPress} />
            <KbButton char={'5'} onPress={onPress} />
            <KbButton char={'6'} onPress={onPress} />
            <KbButton char={'7'} onPress={onPress} />
            <KbButton char={'8'} onPress={onPress} />
            <KbButton char={'9'} onPress={onPress} />
            <KbButton char={'0'} onPress={onPress} />
            <KbButton char="del" onPress={onPress} operator last />
          </Box>
        : <Box flexDirection="row" justifyContent="flex-end" marginTop={0.75} flexShrink={0}>
            <KbButton char={shift ? 'Q' : numerical ? '1' : 'q'} onPress={onPress} />
            <KbButton char={shift ? 'W' : numerical ? '2' : 'w'} onPress={onPress} />
            <KbButton char={shift ? 'E' : numerical ? '3' : 'e'} onPress={onPress} />
            <KbButton char={shift ? 'R' : numerical ? '4' : 'r'} onPress={onPress} />
            <KbButton char={shift ? 'T' : numerical ? '5' : 't'} onPress={onPress} />
            <KbButton char={shift ? 'Y' : numerical ? '6' : 'y'} onPress={onPress} />
            <KbButton char={shift ? 'U' : numerical ? '7' : 'u'} onPress={onPress} />
            <KbButton char={shift ? 'I' : numerical ? '8' : 'i'} onPress={onPress} />
            <KbButton char={shift ? 'O' : numerical ? '9' : 'o'} onPress={onPress} />
            <KbButton char={shift ? 'P' : numerical ? '0' : 'p'} onPress={onPress} />
            <KbButton char="del" onPress={onPress} operator last />
          </Box>}

        {activeInput !== 0
          ? <Box>
              <Box flexDirection="row" marginLeft={1.5} marginTop={0.75} justifyContent="flex-end" flexShrink={0}>
                <KbButton char={shift ? 'A' : numerical ? '-' : 'a'} onPress={onPress} />
                <KbButton char={shift ? 'S' : numerical ? '/' : 's'} onPress={onPress} />
                <KbButton char={shift ? 'D' : numerical ? ':' : 'd'} onPress={onPress} />
                <KbButton char={shift ? 'F' : numerical ? ';' : 'f'} onPress={onPress} />
                <KbButton char={shift ? 'G' : numerical ? '(' : 'g'} onPress={onPress} />
                <KbButton char={shift ? 'H' : numerical ? ')' : 'h'} onPress={onPress} />
                <KbButton char={shift ? 'J' : numerical ? '€' : 'j'} onPress={onPress} />
                <KbButton char={shift ? 'K' : numerical ? '&' : 'k'} onPress={onPress} />
                <KbButton char={shift ? 'L' : numerical ? '@' : 'l'} onPress={onPress} />
                <KbButton char="enter" onPress={onPress} operator last size={2} />
              </Box>
              <Box flexDirection="row" justifyContent="flex-end" marginTop={0.75} flexShrink={0}>
                <KbButton char="s" onPress={this._pressShift} operator />
                <KbButton char={shift ? 'Z' : numerical ? '.' : 'z'} onPress={onPress} />
                <KbButton char={shift ? 'X' : numerical ? ',' : 'x'} onPress={onPress} />
                <KbButton char={shift ? 'C' : numerical ? '?' : 'c'} onPress={onPress} />
                <KbButton char={shift ? 'V' : numerical ? '!' : 'v'} onPress={onPress} />
                <KbButton char={shift ? 'B' : numerical ? '+' : 'b'} onPress={onPress} />
                <KbButton char={shift ? 'N' : numerical ? '-' : 'n'} onPress={onPress} />
                <KbButton char={shift ? 'M' : numerical ? '*' : 'm'} onPress={onPress} />
                <KbButton char={shift ? '.' : numerical ? '<' : '.'} onPress={onPress} />
                <KbButton char={shift ? ',' : numerical ? '>' : ','} onPress={onPress} />
                <KbButton char="s" onPress={this._pressShift} operator last />
              </Box>
              <Box flexDirection="row" justifyContent="flex-end" marginVertical={0.75} flexShrink={0}>
                <KbButton char=".?123" onPress={this._pressNumerical} operator size={2} />
                <KbButton char=" " onPress={onPress} flexGrow={1} />
                <KbButton char="OK" sendChar="send" onPress={onPress} primary last size={2} />
              </Box>
            </Box>
          : <Box>
              <Box
                flexDirection="row"
                marginLeft={1.5}
                marginTop={0.75}
                justifyContent="flex-end"
                flexShrink={0}
                style={theme => ({ height: theme.typography.lineHeight(3) * 1.75 })}
              >
                <KbButton char={intlDot || ','} onPress={onPress} />
              </Box>
              <Box
                flexDirection="row"
                marginTop={0.75}
                flexShrink={0}
                style={theme => ({ height: theme.typography.lineHeight(3) * 1.75 })}
              />
              <Box flexDirection="row" justifyContent="flex-end" marginVertical={0.75} flexShrink={0}>
                <KbButton char="OK" sendChar="send" onPress={onPress} primary last size={2} />
              </Box>
            </Box>}
      </Box>
    );
  }
}

export default Buttons;
