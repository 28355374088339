// @flow
import React from 'react';
import Box from '../Box';
import Text from '../Text';
import Button from '../Button';
import Label from '../Label';
import { isReactNative } from '../../app/detectPlatform';
import Icon from '../Icon';
import { formatPriceToFixed } from '../../order/utils';
import theme from '../../themes/theme';
import moment from 'moment';
import { displayEllipsis } from '../../../browser/themes/utils';
import OrderLineDetail from '../OrderLineDetail';
import componentsMessages from '../../messages/components';
import { compose } from 'rambda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getCurrencySymbol } from '../../parameters/service';

type OrderLineRowProps = {
  name: string,
  price: number,
  unitPrice: number,
  unit: string,
  quantity: number | string,
  count: number,
  updatedAt: number | string | typeof moment,
  priceLevelName: string,
  priceLevelId: string,
  isGroupOpened: boolean,
  selected: ?boolean,
  numberOfSelected: number,
  confirmed: ?boolean,
  orderText: string,
  isChild: ?boolean,
  isFastMenuHeader: boolean,
  fastMenuSubItems: Object[],
  isAdmin: ?boolean,
  isInPaymentTransaction: ?boolean
};

class OrderLineRow extends React.PureComponent<OrderLineRowProps> {
  render() {
    const {
      id,
      name,
      price = 0,
      unitPrice,
      unit,
      quantity,
      count,
      updatedAt,
      priceLevelName,
      isGroupOpened,
      selected,
      numberOfSelected,
      confirmed,
      orderText,
      isChild,
      isFastMenuHeader,
      fastMenuSubItems,
      isAdmin,
      isCanceled,
      isInPaymentTransaction,
      unconfirmedCount,
      onCountPress = () => {},
      allowCountSelection = count > 1,
      intl
    } = this.props;
    const adminColors = (price < 0 || isCanceled) ? 'red' : 'blue';
    let updatedFormatted;

    if (updatedAt) {
      const updatedAtMoment = moment(updatedAt);
      const sameDay = updatedAtMoment.isSame(new Date(), 'day');
      updatedFormatted = sameDay
        ? updatedAtMoment.format('LT')
        : updatedAtMoment.format('D.M. LT');
    }

    const countPart = () => isChild
      ? <Box position="relative" justifyContent="center" height="100%">
          <Label rounded size={0.4} backgroundColor={isAdmin ? adminColors : confirmed ? 'teal' : 'black'} color={isAdmin ? adminColors : 'teal'} />
          <Box
            position="absolute"
            top={0}
            left={0.15}
            width={0.1}
            height="100%"
            backgroundColor={isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray'}
          />
        </Box>
      : numberOfSelected && count > 1
        ? <Box style={isReactNative ? {} : { pointerEvents: 'none' }}>
            <Box
              width={1}
              height={0.85}
              alignItems="center"
              justifyContent="flex-end"
              borderWidth={1}
              borderStyle="solid"
              borderColor={isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray'}
              backgroundColor="white"
              borderTopLeftRadius={isReactNative ? +theme.typography.lineHeight() : `${theme.typography.lineHeight()}px`}
              borderTopRightRadius={isReactNative ? +theme.typography.lineHeight() : `${theme.typography.lineHeight()}px`}
              borderBottomLeftRadius={0}
              borderBottomRightRadius={0}
            >
              <Text color={isAdmin ? adminColors : confirmed ? 'teal' : 'orderLineLabel'} alignItems="flex-end" scale={-3} bold>
                {numberOfSelected}
              </Text>
            </Box>
            <Box
              width={1}
              height={0.85}
              alignItems="center"
              justifyContent="flex-start"
              backgroundColor={isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray'}
            >
              <Text color="white" scale={-3} bold>{count}</Text>
            </Box>
          </Box>
        : <Box
            width={1}
            height={1}
            alignItems="center"
            justifyContent="center"
            borderWidth={1}
            borderStyle="solid"
            borderColor={isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray'}
            backgroundColor={selected ? 'white' : (isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray')}
            borderRadius={theme.typography.lineHeight()}
            style={isReactNative ? {} : { pointerEvents: 'none' }}
          >
            {isFastMenuHeader
              ? <Icon color={selected ? (isAdmin ? adminColors : confirmed ? 'teal' : 'black') : 'white'} name="food" scale={-2} />
              : <Text color={selected ? (isAdmin ? adminColors : confirmed ? 'teal' : 'orderLineLabel') : 'white'} scale={-3} bold condensed>
                  {count}
                </Text>}
          </Box>;

    return (
      <Box
        id={`food-item-${id}`}
        width="100%"
        flexDirection="row"
        justifyContent="flex-start"
        cursor={isReactNative ? undefined : 'pointer'}
      >
        {isGroupOpened &&
          <Box
            position="absolute"
            backgroundColor="white"
            height="100%"
            width="100%"
            zIndex={110}
            flexDirection="row"
            justifyContent="flex-start"
            color="white"
          >
            <Box
              marginRight={0.1}
              flexGrow={1}
              minWidth={1.75}
              maxWidth={1.75}
              height="100%"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              backgroundColor={isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray'}
            >
              <Box
                width={1}
                height={0.85}
                alignItems="center"
                justifyContent="flex-end"
                borderWidth={1}
                borderStyle="solid"
                borderColor={isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray'}
                backgroundColor="white"
                borderRadius={isReactNative
                  ? theme.typography.lineHeight()
                  : `${theme.typography.lineHeight()}px ${theme.typography.lineHeight()}px 0 0`
                }
                borderTopLeftRadius={isReactNative ? +theme.typography.lineHeight() : `${theme.typography.lineHeight()}px`}
                borderTopRightRadius={isReactNative ? +theme.typography.lineHeight() : `${theme.typography.lineHeight()}px`}
                borderBottomLeftRadius={0}
                borderBottomRightRadius={0}
              >
                <Text color={isAdmin ? adminColors : confirmed ? 'teal' : 'orderLineLabel'} alignItems="flex-end" scale={-3} bold>
                  {numberOfSelected}
                </Text>
              </Box>
              <Box
                width={1}
                height={0.85}
                alignItems="center"
                justifyContent="flex-start"
                backgroundColor={isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray'}
              >
                <Text color="white" scale={-3} bold>{count}</Text>
              </Box>
            </Box>
            <Box
              flexDirection="row"
              paddingLeft={0.6}
              flexGrow={1}
              height="100%"
              backgroundColor={isAdmin ? 'lightBlue' : confirmed ? 'lightTeal' : 'darkGray'}
              opacity={0.8}
            >
              <Box height="100%" flex={1} justifyContent="center">
                <Text bold color="white">
                  {`Zobrazenie všetkých položiek ${unconfirmedCount ? `(+${unconfirmedCount})` : ''}`}
                </Text>
                {count === 1 ?
                  <Text color="white" scale={-1}>
                    {`${unit} za ${formatPriceToFixed(unitPrice)} ${getCurrencySymbol()}`}
                  </Text> : null}
              </Box>
              <Box
                height="100%"
                paddingHorizontal={0.5}
                justifyContent="center"
                backgroundColor={isAdmin ? adminColors : confirmed ? 'teal' : 'black'}
                borderLeftWidth={2}
                borderStyle="solid"
                borderColor="white"
              >
                <Text bold color="white" fontFamily="Inconsolata">{formatPriceToFixed(price)} {getCurrencySymbol()}</Text>
              </Box>
            </Box>
          </Box>}

        {allowCountSelection
        ? <Button
            borderColor="lightGray"
            borderStyle="solid"
            borderTopWidth={isChild ? 0 : 1}
            borderRightWidth={isChild ? 0 : 1}
            backgroundColor={isChild ? 'white' : (selected ? (isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray') : 'white')}
            marginRight={0.1}
            flexGrow={1}
            minWidth={1.75}
            maxWidth={1.75}
            height={isFastMenuHeader ? undefined : 2.5}
            paddingTop={isFastMenuHeader ? 0.5 : 0}
            flexDirection="column"
            justifyContent={isFastMenuHeader ? 'flex-start' : 'center'}
            alignItems="center"
            onPress={onCountPress}
          >
            {countPart()}
          </Button>
        : <Box
            borderColor="lightGray"
            borderStyle="solid"
            borderTopWidth={isChild ? 0 : 1}
            borderRightWidth={isChild ? 0 : 1}
            backgroundColor={isChild ? 'white' : (selected ? (isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray') : 'white')}
            marginRight={0.1}
            flexGrow={1}
            minWidth={1.75}
            maxWidth={1.75}
            height={isFastMenuHeader ? undefined : 2.5}
            paddingTop={isFastMenuHeader ? 0.5 : 0}
            flexDirection="column"
            justifyContent={isFastMenuHeader ? 'flex-start' : 'center'}
            alignItems="center"
          >
            {countPart()}
          </Box>}

        <Box
          paddingHorizontal={0.5}
          paddingRight={0.5}
          borderColor="lightGray"
          borderStyle="solid"
          borderTopWidth={1}
          borderLeftWidth={isChild ? 0 : 1}
          backgroundColor={selected ? (isAdmin ? adminColors : confirmed ? 'teal' : 'darkGray') : 'white'}
          flexGrow={4}
          height={isFastMenuHeader ? undefined : 2.5}
          width={0}
          justifyContent="center"
        >
          <Box flexDirection="row" justifyContent="flex-start">
            <Box flexDirection="row" alignItems="flex-start" width={0} flexGrow={1} flexShrink={1} flexBasis="100%">
              {isInPaymentTransaction
                ? <Icon color={selected ? 'white' : 'teal'} name="pay" scale={1} />
                : null
              }
              <Text
                marginLeft={isInPaymentTransaction ? 0.25 : 0}
                bold
                color={isGroupOpened ? 'gray' : selected ? 'white' : 'orderLineLabel'}
                marginRight={1}
                fontFamily="Inconsolata"
                lineHeight={16} /* bc of temp ignoring lineheight in Text */
                {...(isReactNative ? { numberOfLines: 1 } : { style: displayEllipsis })}
              >
                {name}
              </Text>
              {unconfirmedCount && !isAdmin
                ? <Box
                    backgroundColor={selected ? 'white' : 'darkGray'}
                    marginRight={0.5}
                    paddingHorizontal={0.2}
                    paddingVertical={0.12}
                    style={{ borderRadius: 3 }}
                  >
                    <Text
                      color={selected ? 'darkGray' : 'white'}
                      scale={-3}
                      {...(isReactNative ? { numberOfLines: 1 } : { style: displayEllipsis })}
                    >
                      {`+${unconfirmedCount}`}
                    </Text>
                  </Box>
                : null}
            </Box>
            {orderText
              ? <Box marginRight={0.25}><Icon color={isGroupOpened ? 'gray' : selected ? 'white' : 'gray'} name="notes" scale={1} /></Box>
              : null}
            <Text marginLeft="auto" bold color={isGroupOpened ? 'white' : selected ? 'white' : 'orderLineLabel'} flexGrow={1} flexShrink={0} flexBasis="auto" fontFamily="Inconsolata" lineHeight={16}>
              {formatPriceToFixed(price)} {getCurrencySymbol()}
            </Text>
          </Box>

          <OrderLineDetail
            time={updatedFormatted}
            priceLevel={priceLevelName}
            quantity={isAdmin ? '' : quantity}
            isFastMenuHeader={isFastMenuHeader}
            unit={unit}
            unitPrice={unitPrice}
            color={isGroupOpened ? 'gray' : selected ? 'white' : 'orderLineLabel'}
          />
          {isFastMenuHeader && fastMenuSubItems && fastMenuSubItems.map(item =>
            <Box
              key={`fastFoodMenu-${item.id}`}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Text scale={-1} lineHeight={20} color={isGroupOpened ? 'gray' : selected ? 'white' : 'orderLineLabel'}>
                {item.receiptName}
              </Text>
              {count === 1 &&
                <Text scale={-1} lineHeight={20} color={isGroupOpened ? 'white' : selected ? 'white' : 'orderLineLabel'}>
                  {`${intl.formatMessage(componentsMessages.sheetRenderersFor)} ${formatPriceToFixed(item.singlePrice)} ${getCurrencySymbol()}`}
                </Text>}
            </Box>)}
        </Box>
      </Box>
    );
  }
}

export default compose(
  connect(),
  injectIntl
)(OrderLineRow);
