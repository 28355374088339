// @flow
import React from 'react';
import { connect } from 'react-redux';
import ComposedButton from '../../../../common/components/ComposedButton';
import Box from '../../../../common/components/Box';
import { BottomDecoration } from '../../../../common/components/PaymentSheet';
import Closure from '../../../components/Closure';
import { compose } from 'rambda';
import type { State } from '../../../../common/types';
import { injectIntl } from 'react-intl';
import messages from '../../../../common/messages/closures';

class Sidebar extends React.PureComponent {
  render() {
    const {
 closureData, journalId, isLoading, intl
} = this.props;

    return (
      <Box backgroundColor="appBg" height="100%" flexDirection="column" justifyContent="space-between" padding={1} paddingTop={0} flexBasis={22.2} flexShrink={0}>
        <Box flex={1} marginHorizontal="auto" marginVertical={1}>
          <Closure
            data={closureData}
            backgroundColor="white"
            paddingVertical={2}
            overflow="auto"
            flex={1}
            isLoading={isLoading}
            isSelected={!!journalId}
          />
          {BottomDecoration}
        </Box>

        <ComposedButton
          caption={intl.formatMessage(messages.journalPrint)}
          backgroundColor="blue"
          shadowColor="darkBlue"
          icon="printer"
          flexShrink={0}
          onClick={() => console.log('button click - Tlačiť')}
        />
      </Box>
    );
  }
}

export default compose(
  connect((state: State) => ({
    closureData: state.admin.closures.journal.journalData,
    isLoading: state.admin.closures.journal.isLoading,
    journalId: state.admin.closures.journal.selectedJournalId,
  })),
  injectIntl
)(Sidebar);
