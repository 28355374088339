// @flow
import { defineMessages } from 'react-intl';

export default defineMessages({
  mealsCountGoodsAvailability: {
    defaultMessage: 'Dostupnosť tovaru',
    id: 'mealsCounts.goodsAvailability'
  },
  mealsCountFind: {
    defaultMessage: 'Hľadať',
    id: 'mealsCounts.find'
  },
});
