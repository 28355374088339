// @flow
import type { Action, ItemsState } from '../types';
import { path, assoc, mergeWith } from 'ramda';
import { map, filter, dissoc, merge, pipe } from 'rambda';
import latinize from 'latinize';
import { isReactNative } from '../app/detectPlatform';
import { clearFsStore } from '../lib/utils';

const initialState = {
  items: [],
  fastMenuItems: [],
  itemPrices: [],
  priceLevels: [],
  activePriceLevelId: 1,
  availability: {},
  availabilityLocalDiff: {}
};

const reducer = (state: ItemsState = initialState, action: Action): ItemsState => {
  switch (action.type) {
    case 'REHYDRATE_FS_COMMIT': {
      return {
        ...state,
        ...action.payload.items
      };
    }
    case 'SYNC_SERVER_DATA_COMMIT': {
      const {
        payload: {
          Item: items,
          FastMenuItem: fastMenuItems,
          ItemPrice: itemPrices,
          PriceLevel: priceLevels,
          Availability: availability,
          Messages: messages
        }
      } = action;

      const deaccentedItems = map(
        item => ({ ...item, menuNameDeaccented: latinize(item.menuName) }),
        items
      );

      if (isReactNative) {
        const Store = require('react-native-fs-store').default;

        clearFsStore('items').then(() => {
          new Store('items').setItem('items', {
            items: deaccentedItems,
            fastMenuItems,
            itemPrices,
            priceLevels,
            availability,
            messages
          });
        });
      }

      // TODO save prices and price levels. Write tests.
      return {
        ...state,
        items: deaccentedItems,
        fastMenuItems,
        itemPrices,
        priceLevels,
        availability,
        messages
      };
    }

    case 'BLITZ_SYNC_SERVER_DATA_COMMIT': {
      const {
        payload: {
          Availability: availability
        }
      } = action;

      return {
        ...state,
        availability,
      };
    }

    case 'PRICE_LEVEL_ACTIVATE': {
      const { activePriceLevelId } = action.payload;
      return { ...state, activePriceLevelId };
    }

    case 'ITEMS_UPDATE_ITEM_AVAILABILITY_LOCAL_DIFF': {
      const { diffs } = action.payload;

      const localDiffToMerge = pipe(
        filter((_, itemId) => path([itemId, 'availability'], state.availability) !== undefined),
        map((diff, itemId) => (state.availabilityLocalDiff[itemId] || 0) + diff)
      )(diffs);

      const availabilityLocalDiff = merge(state.availabilityLocalDiff, localDiffToMerge);

      return { ...state, availabilityLocalDiff };
    }

    case 'ITEMS_UPDATE_ITEM_AVAILABILITY_CLEAR_LOCAL_DIFF': {
      const { itemId } = action.payload;
      const availabilityLocalDiff = dissoc(itemId, state.availabilityLocalDiff);

      return { ...state, availabilityLocalDiff };
    }

    case 'ITEMS_INCREMENT_ITEM_AVAILABILITY': {
      const { diffs } = action.payload;

      const availabilityToMerge = pipe(
        filter((_, itemId) => path([itemId, 'availability'], state.availability) !== undefined),
        map((diff, itemId) => ({
          availability: path([itemId, 'availability'], state.availability) + diff
        }))
      )(diffs);

      const availability = mergeWith(
        (a, { availability }) => ({ ...a, availability }),
        state.availability,
        availabilityToMerge
    );

      return { ...state, availability };
    }

    case 'ITEMS_RESET_ITEM_AVAILABILITY': {
      const { itemId, resetAt, availability: value } = action.payload;

      const availability = assoc(
        itemId,
        { availability: value, reset_at: resetAt },
        state.availability
      );
      return { ...state, availability };
    }

    default:
      return state;
  }
};

export default reducer;
