// @flow
import { ApolloProvider } from 'react-apollo';
import React from 'react';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { ApiError } from '../../lib/fetch/errors';
import { addError } from '../../order/actions';
import { map } from 'rambda';


const setupClient = (loyalmanConnection, dispatch) => new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        map(
          ({ statusCode, message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,);
            dispatch(addError(new ApiError(statusCode, message)));
          },
          graphQLErrors
        );
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        dispatch(addError(new ApiError(networkError.statusCode, networkError.message)));
      }
    }),
    new HttpLink({
      uri: `${loyalmanConnection.url}/graphql/`,
      headers: {
        Authorization: `JWT ${loyalmanConnection.token}`
      },
      credentials: 'same-origin',
      fetchPolicy: 'no-cache'
    })
  ]),
  cache: new InMemoryCache()
});

const withApolloProvider = BaseComponent => props => {
  const { loyalmanConnection, dispatch } = props;

  return (
    <ApolloProvider client={setupClient(loyalmanConnection, dispatch)}>
      <BaseComponent {...props} />
    </ApolloProvider>
  );
};

export default withApolloProvider;
