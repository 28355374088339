// @flow
import { isReactNative } from './app/detectPlatform';

const paths = [
  ['device']
];

// Novoserializovane paths iba ak React Native
const pathsRn = [
  ['auth'],
];

const transforms = [];
const whitelist = [];

// Paths always override the initialState, because upcoming service workers.
// Paths are explicit, because upcoming migration.
paths.forEach(([feature, props]) => {
  whitelist.push(feature);
  // if (!props) return;
  // const inOut = state => pick(props, state);
  //   transforms.push(
  //     createTransform(inOut, inOut, { whitelist: [feature] })
  //   );
});

if (isReactNative) {
  pathsRn.forEach(([feature, props]) => {
    whitelist.push(feature);
  });
}

const configureStorage = (appName: string) => ({
  debounce: 100,
  keyPrefix: `${appName}:`,
  version: 1,
  // transforms,
  whitelist,
});

export default configureStorage;
