// @flow
import { map, pathOr, prop, reverse, sortBy, filter } from 'rambda';
import moment from 'moment';

/**
 * @memberof common/loyalman/users/utils
 * @param data
 */
export const processTransactions = (data: Object) => {
  const transactions = pathOr([], 'edges', data);

  const translateType = type =>
    type === 'CREDIT'
      ? 'kredit'
      : type === 'BONUS'
        ? 'bonus'
        : 'zľava';

  const translateTypePascalCase = type =>
    type === 'CREDIT'
      ? 'Kredit'
      : type === 'BONUS'
      ? 'Bonus'
      : 'Zľava';

  return reverse(
    sortBy(
      prop('wallet'),
      map(
        transaction => transaction.node
          ? ({
            amount: transaction.node.amount,
            company: pathOr('', 'company.name', transaction.node),
            name: `${pathOr('', 'company.name', transaction.node)}${transaction.node.transactionType ? `, ${translateType(transaction.node.transactionType)}` : ''}`,
            currency: pathOr('', 'currency.code', transaction.node),
            createdAt: transaction.node.createdAt ? moment(transaction.node.createdAt) : null,
            walletName: pathOr('', 'userWallet.wallet.name', transaction.node),
            transactionType: transaction.node.transactionType ? translateTypePascalCase(transaction.node.transactionType) : ''
          })
          : ({}),
        transactions
      )
    )
  );
};

/**
 * @memberof common/loyalman/users/utils
 * @param data
 */
export const processDiscountPackages = (data: Object) => reverse(
  sortBy(
    prop('name'),
    map(
      userDiscountPackage => userDiscountPackage.package
        ? userDiscountPackage.package.name
        : '',
      data
    )
  )
);

/**
 * @memberof common/loyalman/users/utils
 * @param data
 */
export const processCards = (data: Object) => {
  const cards = pathOr([], 'edges', data);

  return reverse(
    sortBy(
      prop('code'),
      map(
        card => card.node
          ? ({
            code: card.node.code,
            status: card.node.status,
            expiresAt: card.node.expiresAt ? moment(card.node.expiresAt) : null,
            holderFullName: card.node.holder ? `${card.node.holder.name} ${card.node.holder.surname}`.trim() : null
          })
          : ({}),
        cards
      )
    )
  );
};

/**
 * @memberof common/loyalman/users/utils
 * @param data
 */
export const processWallets = (data: Object) => {
  const wallets = pathOr([], 'edges', data);

  return reverse(
    sortBy(
      prop('name'),
      map(
        wallet => wallet.node
          ? ({
            amount: wallet.node.balance,
            type: pathOr('', 'wallet.walletType', wallet.node),
            name: pathOr('', 'wallet.name', wallet.node),
            currency: pathOr('', 'wallet.currency.code', wallet.node)
          })
          : ({}),
        wallets
      )
    )
  );
};

/**
 * @memberof common/loyalman/users/utils
 * @param data
 */
export const processPrepaidProducts = (data: Object) => {
  const prepaidProducts = data || [];

  return reverse(
    sortBy(
      prop('name'),
      map(
        product => product
          ? ({
            balance: product.balance,
            expiresAt: pathOr('', 'expiresAt', product),
            name: pathOr('', 'productPrepayment.name', product)
          })
          : ({}),
        prepaidProducts
      )
    )
  );
};

/**
 * @memberof common/loyalman/users/utils
 * @param data
 */
export const processReceivedUsers = (data: Object) => {
  const users = pathOr([], 'users.edges', data);

  const fillUser = user => {
    const processedWallets = processWallets(user.node.userWallets);

    return ({
      cursor: user.cursor,
      id: user.node.id,
      fullname: `${user.node.name} ${user.node.surname}`,
      name: user.node.name,
      surname: user.node.surname,
      phone: user.node.phoneNumber,
      email: user.node.email,
      status: user.node.status,
      validTill: user.node.validTill ? moment(user.node.validTill) : null,
      discountPackages: processDiscountPackages(user.node.userDiscountPackages),
      wallets: processedWallets,
      credit: filter(pw => pw.type === 'CREDIT', processedWallets),
      bonus: filter(pw => pw.type === 'BONUS', processedWallets),
      transactions: processTransactions(user.node.myTransactions),
      prepaidProducts: processPrepaidProducts(user.node.productPrepaymentsUsers),
      cards: processCards(user.node.myCards)
    });
  };

  return sortBy(
      prop('name'),
      map(
        user => user.node
          ? fillUser(user)
          : ({
            id: user.cursor,
            name: user.cursor
          }),
        users
      )
    );
};
