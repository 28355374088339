// @flow
import type { Action, EmployeesState } from '../types';
import { isReactNative } from '../app/detectPlatform';
import { clearFsStore } from '../lib/utils';

const initialState = {
  employees: []
};

const reducer = (state: EmployeesState = initialState, action: Action): EmployeesState => {
  switch (action.type) {
    case 'REHYDRATE_FS_COMMIT': {
      return {
        ...state,
        employees: action.payload.employees
      };
    }
    case 'SYNC_SERVER_DATA_COMMIT': {
      const { payload: { Employee: employees } } = action;

      if (isReactNative) {
        const Store = require('react-native-fs-store').default;

        clearFsStore('employees').then(() => {
          new Store('employees').setItem('employees', employees);
        });
      }

      return { ...state, employees };
    }

    default:
      return state;
  }
};

export default reducer;
