// @flow
import type { Id, OpenTable, State, TableDefinition, TableGeometry } from '../../common/types';
import React from 'react';
import { DragSource } from 'react-dnd';
import Resizable from 'react-resizable-box';
import { connect } from 'react-redux';
import { compose, find, propEq, pathOr } from 'rambda';
import { mergeDeepRight } from 'ramda';
import TableCommon from '../../common/components/Table';
import {
  updateTableGeometry, focusOnTable, activateOpenTable,
  deleteTableDefinition, addOpenTable
} from '../../common/tables/actions';
import Box from '../../common/components/Box';
import { VelocityComponent } from 'velocity-react';
import {
  findUsedOpenTables, generateOpenTableName,
  startRelocatingOrderLinesToOpenTableOrSubTable,
  startCancelingReceiptAndReturningToSubTable
} from '../../common/tables/utils';
import InactiveBg from './InactiveBg';
import { replace as navigateTo } from 'connected-react-router';
import { undeletedOpenTablesSelector } from '../../common/tables/selectors';
import ReactDOM from 'react-dom';
import domToImage from 'dom-to-image';
import { undeletedOrderLinesSelector } from '../../common/order/selectors';
import checkPermission from '../../common/permissions/service';
import uuid from 'uuid';

type TableWithContextType = {
  table: TableDefinition,
  openTables: OpenTable[],
  ContextMenu: React.Element,
  ContextMenuBoundaries: React.Element,
  isEditing: boolean,
  isDragging: boolean,
  dispatch: Function,
  animate: boolean,
  isRelocatingToTable: boolean
}

type DraggableTableProps = TableWithContextType & {
  connectDragSource: Function,
  connectDragPreview: Function,
  isDragging: boolean,
  isEditing: boolean,
  children: Function
};

type ResizableDraggableTableProps = DraggableTableProps;

export type DraggableTableItem = {
  id: Id | null,
  geometry: TableGeometry
}

const ResizableComponentWrapper = (BaseComponent) => connect((state: State) => ({
  scaleFactor: state.serviceAreas.scale,
  tables: state.tables.tableDefinitions,
}))(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newWidth: props.table.geometry.width,
      newHeight: props.table.geometry.height
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props !== nextProps)
      || (this.state.newWidth !== nextState.newWidth)
      || (this.state.newHeight !== nextState.newHeight);
  }

  componentWillReceiveProps({ table: { geometry: { width, height } } }) {
    this.setState({
      newWidth: width,
      newHeight: height
    });
  }

  _saveGeometry() {
    const { scaleFactor, table: { id }, tables, dispatch } = this.props;
    // use original table geometry
    const tableDefinition = find(propEq('id', id), tables);

    const updateTable = mergeDeepRight(tableDefinition, {
      geometry: {
        width: this.state.newWidth / scaleFactor,
        height: this.state.newHeight / scaleFactor
      }
    });

    dispatch(updateTableGeometry(updateTable));
  }

  render() {
    const { isEditing: enable, table, scaleFactor, tables, ...restProps } = this.props;
    const { newWidth, newHeight, isResizing } = this.state;

    const tableWithNewGeometry = { ...table };
    tableWithNewGeometry.geometry.width = newWidth;
    tableWithNewGeometry.geometry.height = newHeight;

    return (
      <Resizable
        width={newWidth}
        height={newHeight}
        onResizeStart={() => this.setState({ isResizing: true })}
        onResizeStop={() => {
          this.setState({ isResizing: false }, () => {
            this._saveGeometry();
          });
        }}
        onResize={(e, dir, refToElement) => {
          const { width, height } = refToElement.getBoundingClientRect();
          this.setState({ newWidth: width, newHeight: height });
        }}
        enable={enable ? {
          right: enable,
          bottom: enable,
          bottomRight: enable,
          top: false,
          left: false,
          topRight: false,
          bottomLeft: false,
          topLeft: false
        } : false}
      >
        <BaseComponent
          table={tableWithNewGeometry}
          isResizing={isResizing}
          {...restProps}
          isEditing={enable}
        />
      </Resizable>
    );
  }
});

const spec = {
  canDrag({ isResizing }: DraggableTableProps) {
    return !isResizing;
  },

  beginDrag({ table }: DraggableTableProps) {
    const item: DraggableTableItem = {
      id: table.id,
      geometry: table.geometry
    };

    return item;
  }
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

export const draggableTable = Symbol('Table');

const DraggableComponentWrapper = (BaseComponent) => compose(
  connect((state: State) => ({
    openTables: undeletedOpenTablesSelector(state),
  })),
  DragSource(draggableTable, spec, collect),
)(class extends React.PureComponent {
    props: DraggableTableProps;

    // this '100%' also magically solves drag n drop issue
    // when table didn't dropped where its preview was
    _style = { height: '100%', display: 'block' };

    componentDidMount() {
      setTimeout(() => {
        this._screenshotDragPreview();
      }, 300);
    }

    componentDidUpdate(prevProps) {
      const { table: { geometry: oldGeometry } } = prevProps;
      const { table: { geometry } } = this.props;

      if ((oldGeometry.x !== geometry.x)
        || (oldGeometry.y !== geometry.y)
        || (oldGeometry.rounded !== geometry.rounded)
        || (oldGeometry.width !== geometry.width)
        || (oldGeometry.height !== geometry.height)) {
        this._screenshotDragPreview();
      }
    }

    _screenshotDragPreview() {
      if (!this.dragPreviewCmp) return;

      // eslint-disable-next-line react/no-find-dom-node
      const previewCmp = ReactDOM.findDOMNode(this.dragPreviewCmp);

      domToImage.toSvg(previewCmp)
        .then(dataUrl => {
          const img = new Image();
          img.src = dataUrl;

          img.onload = () => {
            this.props.connectDragPreview(img);
          };
        });
    }

    render() {
      const {
        connectDragSource,
        isDragging,
        isEditing,
        openTables,
        ...restProps
      } = this.props;

      const tableWoCoords = {
        ...restProps.table,
        geometry: {
          ...restProps.table.geometry,
          x: 0,
          y: 0
        }
      };

      const foundOpenTables = findUsedOpenTables(restProps.table.id, openTables);

      const tableEl = (
        <div style={this._style}>
          <BaseComponent isDragging={isDragging} isEditing={isEditing} {...restProps} />
          {/* invisible drag preview: */}
          <TableCommon
            ref={c => { this.dragPreviewCmp = c; }}
            table={tableWoCoords}
            openTable={foundOpenTables[0]}
            openTable2={foundOpenTables[1]}
            position="relative"
            zIndex={-1}
            forceBorder
          />
        </div>
      );

      // dnd requires native element
      return isEditing
        ? connectDragSource(tableEl)
        : tableEl;
    }
  });

// eslint-disable-next-line react/no-multi-comp
class TableWithContextMenu_ extends React.PureComponent<TableWithContextType> {
  _deleteTable = e => {
    e.stopPropagation();

    const { dispatch, table: { id } } = this.props;
    dispatch([
      deleteTableDefinition(id),
      focusOnTable(null)
    ]);
  };

  _foundNewOpenTable = (openTableId: Id) => {
    const { dispatch } = this.props;

    dispatch([
      navigateTo('/order'),
      activateOpenTable(openTableId),
    ]);
  };

  _toggleContextMenu = (toggle, openTableId = null) => {
    const {
      dispatch,
      focusOnTableId,
      table: { id },
      isResizing,
      isDragging,
      ContextMenu
    } = this.props;

    if (isResizing || isDragging || !ContextMenu) return;

    if (toggle === undefined) {
      toggle = focusOnTableId === null;
    }

    dispatch(focusOnTable(toggle ? id : null, toggle ? openTableId : null));
  };

  _animatable = children => {
    const {
      animate: {
        animation,
        duration,
        delay,
        runOnMount
      } = {}
    } = this.props;

    return animation
      ? (
        <VelocityComponent animation={animation} duration={duration} delay={delay} runOnMount={runOnMount}>
          <Box opacity={0}> {/* will be overwritten by velocity */}
            {children}
          </Box>
        </VelocityComponent>
      )
      : children;
  };

  _onPress = (e, { split, openTable = {} }) => {
    const {
      isEditing,
      isResizing,
      isDragging,
      focusOnTableId,
      isRelocatingToTable,
      isCancelingReceiptAndReturningToTable,
      table,
      openTables,
      orderLines,
      selectedOrderLines,
      tableDefinitions,
      subTables,
      dispatch
    } = this.props;

    if (isRelocatingToTable) {
      const isLimit = pathOr(false, 'limitId', find(propEq('id', openTable.tableDefinitionId), tableDefinitions));

      if (openTable.id && !isLimit) {
        this._toggleContextMenu(true, openTable.id);
      } else {
        const newOpenTableId = isLimit ? openTable.id : null;
        const newSubTableId = isLimit
          ? pathOr(null, 'id', find(propEq('openTableId', openTable.id), subTables))
          : null;

        const actions = startRelocatingOrderLinesToOpenTableOrSubTable(newOpenTableId, newSubTableId,
          selectedOrderLines, table, orderLines, openTables, subTables);
        dispatch(actions);
      }
    } else if (isCancelingReceiptAndReturningToTable) {
      const isLimit = pathOr(false, 'limitId', find(propEq('id', openTable.tableDefinitionId), tableDefinitions));

      if (openTable.id && !isLimit) {
        this._toggleContextMenu(true, openTable.id);
      } else {
        const newOpenTableId = isLimit ? openTable.id : null;
        const newSubTableId = isLimit
          ? pathOr(null, 'id', find(propEq('openTableId', openTable.id), subTables))
          : null;

        const actions = startCancelingReceiptAndReturningToSubTable(newOpenTableId, newSubTableId,
          table, openTables, isCancelingReceiptAndReturningToTable);
        dispatch(actions);
      }
    } else if (split) {
      dispatch(addOpenTable({
        tableDefinitionId: table.id,
        serviceAreaId: table.serviceAreaId,
        name: generateOpenTableName(table, openTables)
      }));
    } else if (!isEditing && !focusOnTableId) {
      if (openTable.id) {
        this._foundNewOpenTable(openTable.id);
      } else if (checkPermission('opentable.add')) {
        const id = uuid.v4();
        dispatch(addOpenTable({
          id,
          tableDefinitionId: table.id,
          serviceAreaId: table.serviceAreaId,
          name: generateOpenTableName(table, openTables)
        }));
        this._foundNewOpenTable(id);
      }
    } else if (isEditing && !isResizing && !isDragging) {
      this._toggleContextMenu(true);
    }
  };

  _onLongPress = (e, { openTable = {} } = {}) => {
    const { isRelocatingToTable, isResizing, isDragging } = this.props;

    if (!isRelocatingToTable && !isResizing && !isDragging) {
      this._toggleContextMenu(undefined, openTable.id);
    }
  };

  render() {
    const {
      isEditing,
      isDragging,
      table,
      ContextMenu,
      ContextMenuBoundaries,
      focusOnTableId,
      openTables,
    } = this.props;

    const tableWoCoords = { ...table, geometry: { ...table.geometry, x: 0, y: 0 } };

    const assocOpenTables = findUsedOpenTables(table.id, openTables);

    return (
      <div style={{ position: 'relative', alignSelf: 'flex-start', opacity: isDragging ? 0 : 1 }}>
        {(ContextMenu && focusOnTableId === table.id) &&
          <InactiveBg
            backgroundColor="transparent"
            zIndex={1}
            onClose={() => this._toggleContextMenu(false)}
          />
        }
        {this._animatable(
          <TableCommon
            table={tableWoCoords}
            openTable={assocOpenTables[0]}
            openTable2={assocOpenTables[1]}
            position="relative"
            isEditing={isEditing}
            onDelete={this._deleteTable}
            onLongPress={this._onLongPress}
            onPress={this._onPress}
            opacity={focusOnTableId === null ? 1 : (focusOnTableId === table.id ? 1 : 0.1)}
          />
        )}
        {(ContextMenu && focusOnTableId === table.id) ?
          <Box position="absolute" top={0} width="1000%" zIndex={2}>
            <ContextMenu belongsTo={this} side="right" boundaries={ContextMenuBoundaries} table={table} />
          </Box>
          : null}
      </div>
    );
  }
}

export const TableWithContextMenu = connect((state: State) => ({
  focusOnTableId: state.tables.focusOnTable,
  openTables: undeletedOpenTablesSelector(state),
  orderLines: undeletedOrderLinesSelector(state),
  selectedOrderLines: state.orders.selectedOrderLines,
  tableDefinitions: state.tables.tableDefinitions,
  subTables: state.tables.subTables,
  isRelocatingToTable: state.orders.active.isRelocatingToTable,
  isCancelingReceiptAndReturningToTable: state.admin.receipts.isCancelingReceiptAndReturningToTable
}))(TableWithContextMenu_);

const ResizableDraggableTableWithContextMenu = compose(
  ResizableComponentWrapper,
  DraggableComponentWrapper
)(TableWithContextMenu);

export default class extends React.PureComponent {
  props: ResizableDraggableTableProps;

  constructor(props) {
    super(props)

    this.state = {
      style: {
        position: 'absolute',
        top: props.table.geometry.y,
        left: props.table.geometry.x,
        cursor: props.isEditing ? 'pointer' : 'default',
        display: 'block'
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.table) return;

    if (nextProps.table !== this.props.table || nextProps.isEditing !== this.props.isEditing) {
      this.setState({
        style: {
          position: 'absolute',
          top: nextProps.table.geometry.y,
          left: nextProps.table.geometry.x,
          cursor: nextProps.isEditing ? 'pointer' : 'default'
        }
      });
    }
  }

  render() {
    const { table, isEditing, ContextMenuBoundaries, ContextMenu, animate } = this.props;
    const { style } = this.state;

    const canChange = checkPermission('tabledefinition.change');
    // if i have no permissions then UI is disabled completely
    // const isEditing = canChange ? this.props.isEditing : false;

    return (
      <div style={style}>
        {(isEditing && canChange)
          ? <ResizableDraggableTableWithContextMenu
              table={table}
              isEditing={isEditing}
              ContextMenuBoundaries={ContextMenuBoundaries}
              ContextMenu={ContextMenu}
              animate={animate}
            />
          : <TableWithContextMenu
            table={table}
            isEditing={isEditing}
            ContextMenuBoundaries={ContextMenuBoundaries}
            ContextMenu={isEditing && !canChange ? null : ContextMenu}
            animate={animate}
          />
        }
      </div>
    );
  }
}
